import {
  CurrencyApi,
  GetBaseCurrencyRatesResponse,
} from '@aminsights/contract';
import { DEFAULT_CURRENCY_CODE } from '@aminsights/shared';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

const LOCAL_STORAGE_BASE_CURRENCY = 'base-currency';
const CURRENCY_RATES = 'currency-rates';

export type UseGetCurrencyRatesResponse = {
  currencyCode: string;
  rates?: GetBaseCurrencyRatesResponse['rates'];
};
type UseGetCurrencyRatesError = unknown; // If API had error types place here
type UseGetCurrencyRatesOptions = Omit<
  UseQueryOptions<UseGetCurrencyRatesResponse, UseGetCurrencyRatesError>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const getCachedCurrency = () => {
  const localStorageCurrencyData = localStorage.getItem(
    LOCAL_STORAGE_BASE_CURRENCY,
  );
  try {
    const parsedLocalStorageCurrencyData = localStorageCurrencyData
      ? JSON.parse(localStorageCurrencyData)
      : undefined;

    if (
      parsedLocalStorageCurrencyData &&
      parsedLocalStorageCurrencyData.ttl > Date.now()
    ) {
      return {
        rates: parsedLocalStorageCurrencyData.rates,
        currencyCode: parsedLocalStorageCurrencyData.baseCurrencyCode,
      };
    }
    return;
  } catch (e) {
    console.error(e);
    return;
  }
};

const getBaseCurrencyCode = () => {
  const cachedCurrency = getCachedCurrency();
  let baseCurrencyCode = cachedCurrency?.currencyCode;

  // If no currency in localStorage and in parameter then GBP is default
  if (!baseCurrencyCode) {
    baseCurrencyCode = DEFAULT_CURRENCY_CODE;
  }

  return baseCurrencyCode;
};

const useGetCurrencyRates = (options?: UseGetCurrencyRatesOptions) => {
  const currencyApi = new CurrencyApi(openApiConfig());
  return useQuery<UseGetCurrencyRatesResponse, UseGetCurrencyRatesError>(
    [CURRENCY_RATES],
    async () => {
      const cachedCurrency = getCachedCurrency();
      const baseCurrencyCode = getBaseCurrencyCode();
      if (cachedCurrency) {
        return cachedCurrency;
      }
      const response = await currencyApi.getCurrencyRatesByCode(
        baseCurrencyCode,
      );
      const rates = response.data.rates;
      if (rates) {
        localStorage.setItem(
          LOCAL_STORAGE_BASE_CURRENCY,
          JSON.stringify({
            rates,
            baseCurrencyCode,
            ttl: new Date().getTime() + 3600000, // 1hr expiration
          }),
        );
      }
      return { rates, currencyCode: baseCurrencyCode };
    },
    options,
  );
};

export default useGetCurrencyRates;
