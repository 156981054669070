import { DataSourceType, Fund, GeographyItem } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  getFundOrInvestmentTrustTitle,
  isFixedIncomeBroadAssetClass,
} from '@aminsights/shared';

import { IDataTablePortfolioColumns } from '../types';

const getColumns = (
  geographyName: string,
  isInvestmentTrust: boolean,
  shouldShowEquityColumns: boolean,
): IDataTablePortfolioColumns[] => [
  {
    title: geographyName,
    render: (item: GeographyItem) => (
      <span className="font-medium text-neutral">{item.name}</span>
    ),
    renderType: 'text',
    isColumnFixed: true,
  },
  {
    title: getFundOrInvestmentTrustTitle(isInvestmentTrust),
    render: (item: GeographyItem) => {
      return (
        <span className="text-neutral">
          {Number.isNaN(item.value) || item.value === undefined
            ? EMPTY_DATA_POINT
            : `${item.value.toFixed(1)}%`}
        </span>
      );
    },
    renderType: 'number',
    width: 150,
    align: 'right',
  },
  ...(shouldShowEquityColumns
    ? [
        {
          title: 'Index',
          render: (item: GeographyItem) => (
            <span className="text-neutral">
              {Number.isNaN(item.index) || item.index === undefined
                ? EMPTY_DATA_POINT
                : `${item.index.toFixed(1)}%`}
            </span>
          ),
          renderType: 'number' as const,
          width: 150,
          align: 'right',
          isVisible: (
            fund: Pick<Fund, 'broadAssetClass' | 'portfolioDataSource'>,
          ) =>
            !isFixedIncomeBroadAssetClass(fund.broadAssetClass) &&
            fund.portfolioDataSource !== DataSourceType.Padi,
        },
        {
          title: 'Relative',
          render: (item: GeographyItem) => (
            <span className="text-neutral">
              {Number.isNaN(item.relative) || item.relative === undefined
                ? EMPTY_DATA_POINT
                : `${item.relative.toFixed(1)}%`}
            </span>
          ),
          renderType: 'number' as const,
          width: 150,
          align: 'right',
          isVisible: (
            fund: Pick<Fund, 'broadAssetClass' | 'portfolioDataSource'>,
          ) =>
            !isFixedIncomeBroadAssetClass(fund.broadAssetClass) &&
            fund.portfolioDataSource !== DataSourceType.Padi,
        },
      ]
    : []),
];

export default getColumns;
