import { Tag } from 'antd';
import cx from 'classnames';
import React from 'react';

import { CheckboxProps, ICheckboxData } from '../types';
import style from './style.module.less';

const { CheckableTag: Checkbox } = Tag;

const ButtonCheckbox: React.FCWithChild<CheckboxProps> = ({
  data,
  value,
  onChange,
  direction,
}) => {
  const isChecked = (itemValue: string | number) => {
    const index =
      value?.findIndex((idx: ICheckboxData) => idx.value === itemValue) ?? -1;
    return index > -1;
  };

  const handleCheck = (item: ICheckboxData, checked: boolean) => {
    onChange((prevValues: Array<ICheckboxData>) => {
      const newValues: Array<ICheckboxData> = prevValues || [];
      if (checked) {
        return [...newValues, item];
      }
      return newValues.filter(i => i.value !== item.value);
    });
  };

  return (
    <div className={cx(style['custom-checkbox-btn'], `${direction}`)}>
      {data?.map(item => (
        <Checkbox
          key={item.value}
          checked={isChecked(item.value)}
          onChange={(checked: boolean) => handleCheck(item, checked)}
        >
          {item.label}
        </Checkbox>
      ))}
    </div>
  );
};

export default ButtonCheckbox;
