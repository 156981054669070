import {
  ChartsV2Api,
  PortfolioScatterPlotResponse,
} from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

import { openApiConfig } from '@/utils';

import {
  getErrorCodeFromAxiosError,
  getErrorMessageFromResponse,
} from '../errorHelpers';
import {
  CHARTS,
  CHARTS_PORTFOLIO_RISK_RETURN,
} from '../watchlist-hooks/query-keys';

export interface ChartParams {
  portfolioId?: string;
  period: string;
}

const useGetPortfolioRiskReturnPlotData = (
  params: ChartParams,
  options?: Omit<
    UseQueryOptions<
      PortfolioScatterPlotResponse | null,
      unknown,
      PortfolioScatterPlotResponse
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const { portfolioId, period } = params;
  const chartApi = new ChartsV2Api(openApiConfig());

  return useQuery<
    PortfolioScatterPlotResponse | null,
    unknown,
    PortfolioScatterPlotResponse
  >(
    [CHARTS, CHARTS_PORTFOLIO_RISK_RETURN, params],
    async () => {
      if (!portfolioId) return null;

      const response = await chartApi.getPortfolioRiskReturnScatterPlotData(
        portfolioId,
        period,
      );

      return response?.data;
    },
    {
      retry: (failureCount, error) => {
        // Retry except for specific errors
        if (isAxiosError(error) && error.code === 'ERR_BAD_REQUEST') {
          return false;
        }
        return failureCount < 3; // Retry 3 times for other errors
      },
      onError: (error: unknown) => {
        const errorCode = getErrorCodeFromAxiosError(error);
        const message = getErrorMessageFromResponse(error);
        if (errorCode === 'ERR_BAD_REQUEST') {
          // We can show an error toast here
          console.error(message);
        }
      },
      ...options,
    },
  );
};

export default useGetPortfolioRiskReturnPlotData;
