// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e7Y0g2t8jM5fKWNIBCiO {\n  min-width: 70px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/FundAndInvestmentTrust/components/CompareTool/CompareTables/style.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":[".fund-title {\n  min-width: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fund-title": "e7Y0g2t8jM5fKWNIBCiO"
};
export default ___CSS_LOADER_EXPORT___;
