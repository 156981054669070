/* eslint-disable @typescript-eslint/no-unused-vars */
import { ExploreFilterParameters, shortenLongNumber } from '@aminsights/shared';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, HorizontalScroll, SearchDropdown } from '@/components';
import SelectedDropdownFilter from '@/components/SearchSelectedFilterIndicator';
import { SLIDER_MAX } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import useGetCurrencyRates from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';
import useSearch from '@/hooks/query-hooks/search-hooks/useSearch';

import { useExploreDefaultFiltersQuery } from '../context';
import useProvideExplore from '../useProvideExplore';
import { countSelectedFilter } from '../utils/countSelectedFilter';
import { showSelectedCachedKey } from '../utils/showSelectedCachedValue';
import { BondsFilterModal } from './BondsFilterModal';
import { EquitiesFilterModal } from './EquitiesFilterModal';
import { EsgFilterModal } from './EsgFilterModal';
import { MoreFiltersModal } from './MoreFiltersModal';
import RangeSliderDropdown from './RangeSliderDropdown';
import { RiskFilterModal } from './RiskFilterModal';

const ocfRangeMarks: Record<number, string> = {
  0: '0',
  16: '0.25%',
  32: '0.5%',
  48: '0.75%',
  64: '1%',
  80: '1.25%',
  96: '1.5%',
  112: '1.75%>',
};

const OCF_SLIDER_MAX = 112;

const parseOcfValue = (val: string) => parseFloat(val.split('%')[0]);

const ExploreFilters: React.FCWithChild = () => {
  const {
    state,
    setFilterParameters,
    anyFiltersSelected,
    clearSearchParameters,
  } = useProvideExplore();
  const { setFullTerm } = useSearch();
  const history = useHistory();
  const [isESGOpen, setIsESGOpen] = useState<boolean>(false);
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState<boolean>(false);
  const [isRiskFiltersOpen, setIsRiskFiltersOpen] = useState<boolean>(false);
  const [isEquitiesFiltersOpen, setIsEquitiesFiltersOpen] =
    useState<boolean>(false);
  const [isBondsFiltersOpen, setIsBondsFiltersOpen] = useState<boolean>(false);
  const { data: currencyData } = useGetCurrencyRates();
  const { data: defaultfiltersOptions } = useExploreDefaultFiltersQuery();

  const handleESGOpen = () => {
    setIsESGOpen(prev => !prev);
  };

  const handleMoreFiltersOpen = () => {
    setIsMoreFiltersOpen(prev => !prev);
  };

  const handleRiskFiltersOpen = () => {
    setIsRiskFiltersOpen(prev => !prev);
  };

  const handleEquitiesFiltersOpen = () => {
    setIsEquitiesFiltersOpen(prev => !prev);
  };

  const handleBondsFiltersOpen = () => {
    setIsBondsFiltersOpen(prev => !prev);
  };

  const categories = useMemo(
    () =>
      defaultfiltersOptions.categories?.map((i, idx) => ({
        label: i.label,
        checked: Boolean(state.filterParameters.categories?.includes(`${idx}`)),
        value: `${idx}`,
      })),
    [defaultfiltersOptions.categories, state.filterParameters.categories],
  );

  const iaSectors = useMemo(
    () =>
      defaultfiltersOptions.iaSectors?.map((i, idx) => ({
        label: i.label,
        checked: Boolean(state.filterParameters.iaSectors?.includes(`${idx}`)),
        value: `${idx}`,
      })),
    [defaultfiltersOptions.iaSectors, state.filterParameters.iaSectors],
  );

  useEffect(() => {
    if (state.filterParameters?.categories) {
      state.filterParameters.filterByOldestShareClassOnly =
        state.filterParameters.categories.length > 0;
    }
  }, [state.filterParameters?.categories]);

  const marksFundSize = useMemo(() => {
    const symbol = currencyData?.currencyCode
      ? getSymbolFromCurrency(currencyData.currencyCode)
      : '£';
    return {
      0: `${symbol}0m`,
      16: `${symbol}50m`,
      32: `${symbol}100m`,
      48: `${symbol}250m`,
      64: `${symbol}500m`,
      80: `${symbol}1bn`,
      96: `${symbol}1.5bn>`,
    };
  }, [currencyData]);

  interface fundSizeMap {
    [key: number]: number;
  }
  const convertFundSize: fundSizeMap = {
    0: 0,
    16: 50000000,
    32: 100000000,
    48: 250000000,
    64: 500000000,
    80: 1000000000,
    96: Number.MAX_SAFE_INTEGER,
  };

  const clearState = () => {
    history.replace({ search: '' });
    clearSearchParameters();
    setFullTerm('');
  };

  const handleOCFChange = (ocfValues: number[]) => {
    const [ocfFrom, ocfTo] = ocfValues;

    const getValues = () => {
      if (ocfTo === 0 && ocfFrom === 0) {
        return {
          ocf: undefined,
        };
      }
      return {
        ocf: [
          parseOcfValue(ocfRangeMarks[ocfFrom]),
          parseOcfValue(ocfRangeMarks[ocfTo]) === 1.75
            ? Number.MAX_SAFE_INTEGER
            : parseOcfValue(ocfRangeMarks[ocfTo]),
        ],
      };
    };
    setFilterParameters(getValues());
  };

  const checkFilterLength = (
    filter: string | boolean | number[] | string[],
  ) => {
    if (typeof filter === 'string' || typeof filter === 'boolean') {
      return filter ? true : false;
    } else {
      return filter && filter.length > 0;
    }
  };

  type FilterKeys = Array<keyof ExploreFilterParameters>;

  const useCountSelectedFilter = (
    state: { filterParameters: ExploreFilterParameters },
    filterKeys: FilterKeys,
  ) => {
    const filters = state.filterParameters;
    return useMemo(
      () => {
        return countSelectedFilter(
          filterKeys.map(key => checkFilterLength(filters[key] || [])),
        );
      },
      filterKeys.map(key => filters[key]),
    );
  };

  const countSelectedRiskFilter = useCountSelectedFilter(state, [
    'betaRange',
    'upsideRange',
    'downsideRange',
    'trackingErrorRange',
    'stdDeviationRange',
    'maximumDrawdownRange',
    'sortinoRatioRange',
  ]);

  const countSelectedEquitiesFilter = useCountSelectedFilter(state, [
    'equityStyle',
    'roicRange',
    'netMarginRange',
  ]);

  const countSelectedBondsFilter = useCountSelectedFilter(state, [
    'bondStyle',
    'modifiedDurationRange',
    'yieldMaturityRange',
    'avgCreditQualityRange',
  ]);

  const countSelectedESGFilter = useCountSelectedFilter(state, [
    'sfdr',
    'unpriDates',
    'tcfdDates',
    'shareActionRatings',
    'stewardshipCode',
    'netZeroAssetManager',
    'financeForBiodiversityDates',
    'actDates',
  ]);

  const countSelectedMoreFilter = useCountSelectedFilter(state, [
    'managementGroup',
    'domiciles',
    'currencies',
    'platforms',
    'yieldRange',
    'noOfHoldingsRange',
    'broadAssetClasses',
    'assetClassCodes',
    'legalStructure',
    'filterByOldestShareClassOnly',
    'indexFund',
    'genders',
    'currencyHedgedType',
  ]);

  const renderClearFilters = () => {
    return (
      <div
        data-test-id={'clearFilters'}
        className="flex items-center clear-filters min-h-8"
        onClick={clearState}
        aria-hidden="true"
        id="clear-filter"
      >
        <div className="w-20">
          <p className="font-normal">Clear filters</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <HorizontalScroll hideScrollbars>
        <div className="flex section-explore-head__filters flex-col 2xl:flex-row">
          <div className="flex justify-between w-full">
            <div className="pr-2">
              <SearchDropdown
                items={categories}
                placeholder="M* Category"
                placement="bottomLeft"
                onChange={(value: string[]) => {
                  setFilterParameters({
                    categories: value,
                    filterByOldestShareClassOnly: value.length > 0,
                  });
                }}
                selectedFilter={state.filterParameters.categories?.length}
                dropdownNote={
                  <p>
                    <span>Note:</span> Returns all share classes.
                  </p>
                }
                showSearch
                showSelectAll
              />
            </div>
            <div className="pr-2">
              <SearchDropdown
                items={iaSectors}
                placeholder="IA Sector"
                placement="bottomLeft"
                onChange={(value: string[]) => {
                  setFilterParameters({ iaSectors: value });
                }}
                selectedFilter={state.filterParameters.iaSectors?.length}
                dropdownNote={
                  <p>
                    <span>Note:</span> Returns primary share classes only.
                  </p>
                }
                showSearch
                showSelectAll
              />
            </div>
            <div className="pr-2">
              <RangeSliderDropdown
                marks={marksFundSize}
                placeholder="Fund Size"
                placement="bottomLeft"
                step={16}
                max={SLIDER_MAX - 4} //-4 to account for odd number of options
                from={
                  showSelectedCachedKey(
                    state.filterParameters.fundSize &&
                      state.filterParameters.fundSize.length > 0
                      ? [
                          shortenLongNumber(state.filterParameters.fundSize[0]),
                          shortenLongNumber(state.filterParameters.fundSize[1]),
                        ]
                      : [],
                    marksFundSize,
                  ).from || 0
                }
                to={
                  showSelectedCachedKey(
                    state.filterParameters.fundSize &&
                      state.filterParameters.fundSize.length > 0
                      ? [
                          shortenLongNumber(state.filterParameters.fundSize[0]),
                          state.filterParameters.fundSize[1] ===
                          Number.MAX_SAFE_INTEGER
                            ? state.filterParameters.fundSize[1]
                            : shortenLongNumber(
                                state.filterParameters.fundSize[1],
                              ),
                        ]
                      : [],
                    marksFundSize,
                  ).to || 0
                }
                isClear={!anyFiltersSelected()}
                onChange={(value: number[]) => {
                  const [from, to] = value;

                  if (from === 0 && to === 0) {
                    setFilterParameters({
                      fundSize: undefined,
                    });
                  } else {
                    setFilterParameters({
                      fundSize: [convertFundSize[from], convertFundSize[to]],
                    });
                  }
                }}
                isFundSize={true}
              />
            </div>
            <div className="pr-2">
              <RangeSliderDropdown
                marks={ocfRangeMarks}
                placeholder="OCF"
                placement="bottomLeft"
                step={16}
                max={OCF_SLIDER_MAX}
                from={
                  showSelectedCachedKey(
                    state.filterParameters.ocf || [],
                    ocfRangeMarks,
                  )?.from || 0
                }
                to={
                  showSelectedCachedKey(
                    state.filterParameters.ocf || [],
                    ocfRangeMarks,
                  ).to || 0
                }
                isClear={!anyFiltersSelected()}
                onChange={handleOCFChange}
              />
            </div>
            <div className="pr-2">
              <Button
                className={`${
                  countSelectedRiskFilter > 0 && 'with-selected'
                } modal-btn`}
                type="default"
                htmlType="button"
                onClick={handleRiskFiltersOpen}
                data-test-id={'risk'}
              >
                <div className="flex items-center">
                  <SelectedDropdownFilter
                    placeholder="Risk"
                    noOfSelectedFilter={countSelectedRiskFilter}
                    className="select-dropdown-filter-wrapper"
                  />
                </div>
              </Button>
            </div>
            <div className="pr-2">
              <Button
                className={`${
                  countSelectedEquitiesFilter > 0 && 'with-selected'
                } modal-btn`}
                type="default"
                htmlType="button"
                onClick={handleEquitiesFiltersOpen}
                data-test-id={'equities'}
              >
                <div className="flex items-center">
                  <SelectedDropdownFilter
                    placeholder="Equities"
                    noOfSelectedFilter={countSelectedEquitiesFilter}
                    className="select-dropdown-filter-wrapper"
                  />
                </div>
              </Button>
            </div>
            <div className="pr-2">
              <Button
                className={`${
                  countSelectedBondsFilter > 0 && 'with-selected'
                } modal-btn`}
                type="default"
                htmlType="button"
                onClick={handleBondsFiltersOpen}
                data-test-id={'bonds'}
              >
                <div className="flex items-center">
                  <SelectedDropdownFilter
                    placeholder="Bonds"
                    noOfSelectedFilter={countSelectedBondsFilter}
                    className="select-dropdown-filter-wrapper"
                  />
                </div>
              </Button>
            </div>
            <div className="pr-2">
              <Button
                className={`${
                  countSelectedESGFilter > 0 && 'with-selected'
                } modal-btn`}
                type="default"
                htmlType="button"
                onClick={handleESGOpen}
                data-test-id={'ESG'}
              >
                <div className="flex items-center">
                  <SelectedDropdownFilter
                    placeholder="ESG"
                    noOfSelectedFilter={countSelectedESGFilter}
                    className="select-dropdown-filter-wrapper"
                  />
                </div>
              </Button>
            </div>
            <div className="pr-2">
              <Button
                className={`${
                  countSelectedMoreFilter > 0 && 'with-selected'
                } modal-btn`}
                type="default"
                htmlType="button"
                onClick={handleMoreFiltersOpen}
                data-test-id={'morefilters'}
              >
                <div className="flex items-center">
                  <SelectedDropdownFilter
                    placeholder="More filters"
                    noOfSelectedFilter={countSelectedMoreFilter}
                    className="select-dropdown-filter-wrapper"
                  />
                </div>
              </Button>
            </div>
          </div>
        </div>
        {(state.searchParameters.term || anyFiltersSelected()) && (
          <div className="hidden 2xl:flex w-full pl-2 pr-2">
            {renderClearFilters()}
          </div>
        )}
      </HorizontalScroll>
      {(state.searchParameters.term || anyFiltersSelected()) && (
        <div className="2xl:hidden flex w-full pl-2 pr-2 justify-end mt-4">
          {renderClearFilters()}
        </div>
      )}
      <EsgFilterModal isOpen={isESGOpen} onClose={handleESGOpen} />
      <MoreFiltersModal
        isOpen={isMoreFiltersOpen}
        onClose={() => {
          setIsMoreFiltersOpen(false);
        }}
      />
      <RiskFilterModal
        isOpen={isRiskFiltersOpen}
        onClose={() => {
          setIsRiskFiltersOpen(false);
        }}
      />
      <EquitiesFilterModal
        isOpen={isEquitiesFiltersOpen}
        onClose={() => {
          setIsEquitiesFiltersOpen(false);
        }}
      />
      <BondsFilterModal
        isOpen={isBondsFiltersOpen}
        onClose={() => {
          setIsBondsFiltersOpen(false);
        }}
      />
    </>
  );
};

export default ExploreFilters;
