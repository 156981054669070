import {
  BroadAssetClassColor,
  FUND_BOX_X_AND_Y_LABEL_VALUE,
} from '@aminsights/shared';
import { getBackgroundStyleboxColor } from '@aminsights/shared';
import { getHighlightStyleboxColor } from '@aminsights/shared';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import style from './style.module.less';

const RenderStyleBox: React.FCWithChild<{
  styleBoxValue?: number;
  isFixedIncome?: boolean;
  isBoxClickable?: boolean;
  isClear?: boolean;
  onSelectedBox?: (value: number[]) => void;
  selectedBox?: number[];
}> = ({
  styleBoxValue,
  isFixedIncome,
  isBoxClickable,
  onSelectedBox,
  selectedBox,
  isClear,
}) => {
  const { data: state } = useFundByIsInParam();
  const [selectedBoxes, setSelectedBoxes] = useState<number[]>([]);

  const STYLE_BOX_COUNT = 9;

  const getYandXLabel = (labelData: string[]) => {
    return labelData.map(value => (
      <div className={style['per-box-text']}>{value}</div>
    ));
  };

  const handleBoxClick = (index: number) => {
    if (onSelectedBox) {
      if (selectedBoxes.includes(index)) {
        const updatedSelectedBoxes = selectedBoxes.filter(
          boxIndex => boxIndex !== index,
        );
        setSelectedBoxes(updatedSelectedBoxes);
        onSelectedBox(updatedSelectedBoxes);
      } else {
        const updatedSelectedBoxes = [...selectedBoxes, index];
        setSelectedBoxes(updatedSelectedBoxes);
        onSelectedBox(updatedSelectedBoxes);
      }
    }
  };

  useEffect(() => {
    if (isClear) {
      setSelectedBoxes([]);
    }
  }, [isClear]);

  useEffect(() => {
    if (selectedBox && selectedBox.length > 0) {
      setSelectedBoxes(selectedBox);
    }
  }, [selectedBox]);

  const boxBackgroundColor = (index: number): BroadAssetClassColor => {
    if (styleBoxValue === index || selectedBoxes.includes(index)) {
      return getBackgroundStyleboxColor(isFixedIncome ?? false);
    } else {
      return getHighlightStyleboxColor(isFixedIncome ?? false);
    }
  };

  const renderBox = () => {
    const boxes = [];
    for (let i = 0; i < STYLE_BOX_COUNT; i += 3) {
      boxes.push(
        <div className="flex" key={i}>
          {[i + 1, i + 2, i + 3].map(index => (
            <div
              data-test-id={`${isFixedIncome ? 'bond' : 'equity'}Index${index}`}
              key={index}
              onClick={() => handleBoxClick(index)}
              className={cx(style['box'], isBoxClickable && style['box-hover'])}
              style={{
                backgroundColor: boxBackgroundColor(index),
              }}
            >
              {/* {styleBoxValue} */}
            </div>
          ))}
        </div>,
      );
    }
    return boxes;
  };

  return (
    <div data-test-id="fundDetailsPortfolioTabStyleBox">
      <div className={style['style-wrapper']}>
        <div className={style['title']}>{state?.fund?.broadAssetClass}</div>
        <div
          className={cx(
            style['box-container'],
            !isBoxClickable && style['box-container-fund-details'],
          )}
        >
          <div className="flex flex-col">
            {getYandXLabel(
              isFixedIncome
                ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI.yName
                : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ.yName,
            )}
          </div>
          <div>{renderBox()}</div>
        </div>
        <div className="flex text-center">
          <div className={style['per-box-text']} />
          {getYandXLabel(
            isFixedIncome
              ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI.xName
              : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ.xName,
          )}
        </div>
      </div>
      {!isBoxClickable && (
        <div className="w-full px-2 py-4">
          <p className="text-xs text-neutral">Only captures long positions</p>
        </div>
      )}
    </div>
  );
};

export default RenderStyleBox;
