import { Input } from 'antd';
import cx from 'classnames';
import React from 'react';

import styles from './style.module.less';

type TextAreaProps = {
  id: string;
  label?: string;
  name: string;
  dataTestId?: string;
  style?: React.CSSProperties;
  maxLength?: number;
  onChange?: React.ChangeEventHandler;
  onBlur?: React.FocusEventHandler;
  error?: string;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  className?: string;
  rows?: number;
};

const CustomTextArea: React.FCWithChild<TextAreaProps> = ({
  id,
  label,
  placeholder,
  name,
  style,
  maxLength,
  onChange,
  onBlur,
  error,
  disabled,
  value,
  className,
  rows,
}) => {
  const { TextArea } = Input;

  return (
    <div
      className={cx(
        styles['custom-textarea'],
        `${error && styles['custom-textarea__error']}`,
      )}
    >
      <TextArea
        id={id}
        name={name}
        style={style}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows}
      />
      {label && <label htmlFor={id}>{label}</label>}
      {error && (
        <div className="flex items-center">
          <p className="text-xs mt-1 text-red-500">{error}</p>
        </div>
      )}
    </div>
  );
};

CustomTextArea.defaultProps = {
  style: {},
  maxLength: 2500,
  onChange: () => undefined,
  onBlur: () => undefined,
  error: '',
  disabled: false,
};

export default CustomTextArea;
