import { Fund } from '@aminsights/contract';
import {
  CreditQualityBreakdownTypeCodes,
  EMPTY_DATA_POINT,
} from '@aminsights/shared';
import { FC } from 'react';

import { DataTable } from '@/components';
import { COMPARE_TOOL_DECIMALS } from '@/constants';
import mapCreditQuality from '@/utils/data-mappers/mapCreditQuality';

import { getFundTitle } from '../utils/shared';
import { extractFundTitle } from './extractFundTitle';

interface CompareCreditQualityProps {
  loading?: boolean;
  funds?: Fund[];
}

const CompareCreditQuality: FC<CompareCreditQualityProps> = ({
  funds,
  loading = true,
}) => {
  const allCredits = funds
    ?.map(f => mapCreditQuality(f))
    .reduce((prev, curr) => {
      if (!prev.length) {
        return curr;
      }
      const currCredit = [...curr].sort((a, b) => b.value - a.value);
      let result = [...prev];
      currCredit.forEach(sc => {
        if (!result.find(i => i.creditQuality === sc.creditQuality)) {
          result = [...result, sc];
        }
      });
      return result;
    }, [])
    .sort((a, b) => b.value - a.value);

  return (
    <DataTable
      loading={loading}
      enableHeaderWrap
      uniqueKey="creditQuality"
      className="md:table-fixed"
      columns={[
        {
          title: 'Credit Quality',
          render: ({ creditQuality }) => {
            const credit = (allCredits || []).find(
              i => i.creditQuality === creditQuality,
            )?.creditQuality;
            return <div className="font-semibold">{credit}</div>;
          },
          renderType: 'text',
          idSubText: 'credit',
          isColumnFixed: true,
          width: 100,
        },
        ...(funds || []).map((f, idx) => ({
          title: getFundTitle({
            isin: f.shareClassDetails.isin,
            fallBackTitle: `Fund ${idx + 1}`,
            loading,
            fundName: extractFundTitle(f),
          }),
          render: ({ creditQuality }: { creditQuality: string }) => {
            const credits = mapCreditQuality(f) || [];
            const item = credits.find(i => i.creditQuality === creditQuality);
            const value = item?.value.toFixed(COMPARE_TOOL_DECIMALS);
            return <div>{value ? `${value}%` : EMPTY_DATA_POINT}</div>;
          },
          renderType: 'number' as const,
          idSubText: `fund-${idx + 1}`,
        })),
      ]}
      // We'll use the data as keys since this table different data types per row
      data={
        (allCredits || []).length !== 0
          ? CreditQualityBreakdownTypeCodes.filter(t => t.typeCode !== '1').map(
              h => ({ creditQuality: h.definition }),
            )
          : []
      }
    />
  );
};

export default CompareCreditQuality;
