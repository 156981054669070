import { Radio, RadioChangeEvent } from 'antd';
import cx from 'classnames';
import React from 'react';

import style from './style.module.less';

type CustomRadioProps = {
  emitRadioValue: (value: string) => void;
  data: Array<RadioDataProps>;
  className?: string;
  isHorizontal?: boolean;
  value?: string;
};

type RadioDataProps = {
  label: string | number;
  value: string | number;
  count?: number;
};

const CustomRadio: React.FCWithChild<CustomRadioProps> = ({
  data,
  emitRadioValue,
  className,
  isHorizontal,
  value,
}) => {
  const onChange = (e: RadioChangeEvent) => {
    emitRadioValue(e.target.value);
  };

  return (
    <Radio.Group onChange={onChange} value={value} className="w-full">
      <div
        className={cx(
          'flex',
          isHorizontal ? 'justify-start' : 'flex-col w-full',
        )}
      >
        {data?.map(item => (
          <Radio
            key={item.value}
            defaultChecked
            value={item.value}
            className={cx(className, style.radio)}
          >
            {item.label} {item.count && `(${item.count})`}
          </Radio>
        ))}
      </div>
    </Radio.Group>
  );
};

export default CustomRadio;
