import { Dropdown, Menu, Slider } from 'antd';
import cx from 'classnames';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import Button from '@/components/Button';
import SelectedDropdownFilter from '@/components/SearchSelectedFilterIndicator';
import { SLIDER_MIN } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { toCamelCase } from '@/utils/toCamelCase';

import { adjustMarksToAvoidDuplicates } from '../../utils/adjustMarksToAvoidDuplicates';
import { getChangedMarkValues } from '../../utils/getChangedMarkValues';
import { getRangeSliderClassName } from '../../utils/getRangeSliderClassName';
import style from './style.module.less';

type RangeSliderDropdownProps = {
  marks: Record<number, string>;
  placeholder: string;
  placement?:
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter'
    | 'topRight';
  defaultFrom?: number;
  defaultTo?: number;
  step: number;
  max: number;
  onChange?: (value: number[]) => void;
  to?: number | undefined;
  from?: number | undefined;
  isClear: boolean;
  isFundSize?: boolean;
};

const RangeSliderDropdown: React.FCWithChild<RangeSliderDropdownProps> = ({
  marks,
  placement,
  placeholder,
  onChange,
  step,
  max,
  from,
  to,
  isClear,
  isFundSize = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedRange, setSelectedRange] = useState<number[]>([
    SLIDER_MIN,
    max,
  ]);
  const [isClearButtonDisabled, setIsClearButtonDisabled] = useState(true);
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);
  const [withRange, setWithRange] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedRangeMin, selectedRangeMax] = useMemo(
    () => selectedRange,
    [selectedRange],
  );

  const onChangeData = (value: number | number[]) => {
    setSelectedRange(adjustMarksToAvoidDuplicates(value, marks));
    setIsClearButtonDisabled(false);
    setIsApplyButtonDisabled(false);
  };

  const unSelectAll = () => {
    setSelectedRange([SLIDER_MIN, max]);
    setIsClearButtonDisabled(true);
    setWithRange(false);
  };

  useEffect(() => {
    if (isClearButtonDisabled) {
      setWithRange(false);
      return;
    }
    setWithRange(true);
  }, [isClearButtonDisabled]);

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (onChange) {
      onChange(selectedRange);
    }
    if (onChange && isClearButtonDisabled) {
      onChange([0, 0]);
    }

    const hasRange = selectedRange.some(Boolean);
    setWithRange(hasRange && !isClearButtonDisabled);
    setIsVisible(false);
  };

  useEffect(() => {
    if (from !== undefined && to) {
      onChangeData([from, to]);
      setIsSubmitting(true);
    }
  }, [from, to]);

  useEffect(() => {
    if (isClear) {
      unSelectAll();
    }
  }, [isClear]);

  const { app } = useAppContext();
  const isMobile = app.windowWidth < 655;

  const mobileStyles = isMobile
    ? {
        left: '0',
        width: '100vw',
      }
    : {};

  const menu = (
    <form className={style['button-search-dropdown-form']}>
      <Menu className={style['button-search-dropdown-menu']}>
        <div className={style['button-search-dropdown-menu-items']}>
          <div>
            <div className="flex flex-row justify-between ">
              <div className="flex-column">
                <p>{placeholder}</p>
              </div>
              <div className="flex-column">
                <p className="font-semibold">
                  {getChangedMarkValues(
                    !isClearButtonDisabled,
                    selectedRange,
                    marks,
                  )}
                </p>
              </div>
            </div>

            <div
              data-test-id={toCamelCase(`${placeholder}`)}
              className={
                isFundSize
                  ? classNames(
                      style['fund-size-range-slider'],
                      style['range-slider'],
                    )
                  : style['range-slider']
              }
            >
              <Slider
                className={
                  style[getRangeSliderClassName(!isClearButtonDisabled)]
                }
                tooltip={{ open: false }}
                range
                step={step}
                marks={marks}
                onChange={onChangeData}
                value={[selectedRangeMin, selectedRangeMax]}
                max={max}
              />
            </div>
          </div>
        </div>
      </Menu>
      <div className={style['button-search-dropdown-form-footer']}>
        <Button
          data-test-id={toCamelCase(`clear${placeholder}`)}
          size="large"
          type="link"
          htmlType="button"
          className="font-semibold"
          onClick={unSelectAll}
          disabled={isClearButtonDisabled}
        >
          Clear
        </Button>
        <Button
          data-test-id={toCamelCase(`apply${placeholder}`)}
          size="large"
          type="primary"
          htmlType="submit"
          className="font-semibold"
          onClick={onSubmit}
          disabled={isApplyButtonDisabled}
        >
          Apply
        </Button>
      </div>
    </form>
  );
  const checkSelectedRange = (withRange: boolean): number => {
    return withRange ? 1 : 0;
  };
  return (
    <>
      <div className={style['button-search-dropdown']}>
        <Dropdown
          overlay={menu}
          overlayClassName={style['test']}
          overlayStyle={mobileStyles}
          trigger={['click']}
          placement={placement || 'bottomRight'}
          open={isVisible}
        >
          <Button
            className={style['button-search-dropdown-wrapper']}
            onClick={() => setIsVisible(prev => !prev)}
            data-test-id={toCamelCase(placeholder || '')}
          >
            <div
              className={cx(style['button-search-dropdown-label'], {
                [style['with-selected']]: isSubmitting
                  ? checkSelectedRange(withRange)
                  : 0,
              })}
            >
              <SelectedDropdownFilter
                placeholder={placeholder}
                noOfSelectedFilter={
                  isSubmitting ? checkSelectedRange(withRange) : 0
                }
                className={style['select-dropdown-filter-wrapper']}
              />
            </div>
            <span className={style['icon-arrow-wrapper']}>
              <NextArrow
                className={cx('icon', style['button-search-dropdown-caret'])}
              />
            </span>
          </Button>
        </Dropdown>
      </div>
      {isVisible && (
        <div
          className={style['button-search-dropdown-overlay']}
          onClick={() => setIsVisible(false)}
          onKeyUp={() => setIsVisible(false)}
          role="button"
          tabIndex={0}
        >
          {' '}
        </div>
      )}
    </>
  );
};

export default RangeSliderDropdown;
