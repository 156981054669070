import { FundBroadAssetClass } from '@aminsights/contract';

export const notFIAndNotEquityBroadAssetClasses = [
  FundBroadAssetClass.Allocation,
  FundBroadAssetClass.Alternative,
  FundBroadAssetClass.Commodities,
  FundBroadAssetClass.Convertibles,
  FundBroadAssetClass.Miscellaneous,
  FundBroadAssetClass.MoneyMarket,
  FundBroadAssetClass.Property,
];
