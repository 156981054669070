import './style.less';

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import Tabs from '@/components/Tabs';
import AnnouncementRouter from '@/router/Announcements';

const Announcements: React.FCWithChild = () => {
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    const { pathname } = location;
    const currentTab = ['my-list', 'all'].find(word => pathname.includes(word));
    setActiveTab(currentTab);
  }, []);

  const onNavigateTab = (tab?: string) => {
    let currentTab = tab;
    if (!tab) currentTab = 'my-list';
    setActiveTab(currentTab);
    history.push(`${path}/${currentTab}`);
  };

  return (
    <section className="section-announcements">
      <h2 className="font-bold text-xl">Announcements</h2>

      <div className="flex flex-row mt-4">
        <div className="announcements-wrapper pt-1">
          <Tabs
            activeKey={activeTab}
            onChange={onNavigateTab}
            items={[
              {
                label: 'My List',
                key: 'my-list',
              },
            ]}
          />
          <div className="section-watchlist-inner">
            <AnnouncementRouter />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Announcements;
