import { ExclamationCircleFilled } from '@ant-design/icons';
import { FC } from 'react';

import { Button } from '@/components';

const CorrelationMatrixError: FC<{ onRetry: () => void }> = ({ onRetry }) => {
  return (
    <div className="text-center min-h-60 flex flex-col items-center justify-center text-destructive-600">
      <ExclamationCircleFilled className="mb-3" style={{ fontSize: 38 }} />
      <h2 className="text-lg font-semibold">
        There was an error loading the data
      </h2>
      <p className="text-sm text-neutral-400">
        Please try again or{' '}
        <a
          href="https://changing-digital.atlassian.net/servicedesk/customer/portal/3/"
          className="text-primary underline"
        >
          contact us
        </a>{' '}
        if the problem persists
      </p>
      <Button type="primary" className="mt-4" onClick={onRetry}>
        Try again
      </Button>
    </div>
  );
};

export default CorrelationMatrixError;
