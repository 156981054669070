// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TNpsizxwslMOADeKLCrc {\n    margin-bottom: 1rem\n}\n.TNpsizxwslMOADeKLCrc .ant-radio-checked .ant-radio-inner {\n    --tw-border-opacity: 1;\n    border-color: rgb(0 127 255 / var(--tw-border-opacity));\n    --tw-bg-opacity: 1;\n    background-color: rgb(0 127 255 / var(--tw-bg-opacity))\n}\n.TNpsizxwslMOADeKLCrc .ant-radio-inner::after {\n    --tw-bg-opacity: 1;\n    background-color: rgb(255 255 255 / var(--tw-bg-opacity))\n}\n", "",{"version":3,"sources":["webpack://./src/components/Radio/style.module.less"],"names":[],"mappings":"AACE;IAAA;AAAA;AAEE;IAAA,sBAAA;IAAA,uDAAA;IAAA,kBAAA;IAAA;AAAA;AAGA;IAAA,kBAAA;IAAA;AAAA","sourcesContent":[".radio {\n  @apply mb-4;\n  :global .ant-radio-checked .ant-radio-inner {\n    @apply bg-primary border-primary;\n  }\n  :global .ant-radio-inner::after {\n    @apply bg-white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": "TNpsizxwslMOADeKLCrc"
};
export default ___CSS_LOADER_EXPORT___;
