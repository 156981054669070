import { WatchlistApi } from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { WATCHLIST_TABS_TO_SHOW } from './query-keys';

const useTabsToShowOnWatchlist = (
  bucketId?: string,
  options?: Omit<UseQueryOptions<string[], unknown>, 'queryKey' | 'queryFn'>,
) => {
  const watchlistApi = new WatchlistApi(openApiConfig());

  return useQuery<string[], unknown>(
    [WATCHLIST_TABS_TO_SHOW, { bucketId }],
    async () => {
      if (!bucketId) {
        return [];
      }
      const response = await watchlistApi.getWatchlistTabsToShow(bucketId);
      return response.data;
    },
    options,
  );
};

export default useTabsToShowOnWatchlist;
