import { buildFundDetailsPath } from '@aminsights/shared';
import { Link } from 'react-router-dom';

export const getFundTitle = ({
  isin,
  fallBackTitle,
  loading,
  fundName,
}: {
  isin?: string;
  fallBackTitle: string;
  loading: boolean;
  fundName: string;
}) => {
  if (loading) {
    return fallBackTitle;
  }
  if (isin) {
    return <Link to={buildFundDetailsPath(isin)}>{fundName}</Link>;
  }
  return 'Fund';
};
