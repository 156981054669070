import { WatchlistApi, WatchlistCurrencyResponse } from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { CURRENCY, WATCHLIST } from './query-keys';
import { WatchlistParams } from './watchlistParams';

const useWatchlistCurrencyQuery = (
  params?: WatchlistParams,
  options?: Omit<
    UseQueryOptions<WatchlistCurrencyResponse>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { sortKey, sortDirection, bucketId } = params ?? {};
  const watchlistApi = new WatchlistApi(openApiConfig());

  return useQuery<WatchlistCurrencyResponse>(
    [WATCHLIST, CURRENCY, sortKey, sortDirection, bucketId],
    async () => {
      const response = await watchlistApi.getWatchlistCurrency(
        {
          sortBy: sortKey,
          sortDirection: sortDirection,
        },
        bucketId,
      );
      return response.data;
    },
    options,
  );
};

export default useWatchlistCurrencyQuery;
