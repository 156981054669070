import { OrganizationApi } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

const organizationApi = new OrganizationApi(openApiConfig());

const useOrganizationUsers = (tenantId?: string) => {
  return useQuery(
    ['org-users', tenantId],
    async () => {
      if (tenantId) {
        const res = await organizationApi.fetchUsers();
        return res.data;
      }
      return [];
    },
    { staleTime: 1000 * 60 * 5 },
  );
};

export default useOrganizationUsers;
