// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O29eCU7Nlh61Vj5FkU7F {\n  display: flex;\n  justify-content: space-between;\n  border-bottom-width: 1px;\n  --tw-border-opacity: 1;\n  border-color: rgb(234 234 234 / var(--tw-border-opacity));\n}\n.O29eCU7Nlh61Vj5FkU7F .ejeNqCAxWeBtWjN99d_S {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  --tw-text-opacity: 1;\n  color: rgb(49 51 65 / var(--tw-text-opacity));\n  font-family: Inter;\n  line-height: 18px;\n}\n.O29eCU7Nlh61Vj5FkU7F .ant-switch-handle {\n  top: 3px;\n  left: 3px;\n}\n.O29eCU7Nlh61Vj5FkU7F .ant-switch-checked .ant-switch-handle {\n  left: calc(100% - 18px - 3px);\n}\n.O29eCU7Nlh61Vj5FkU7F,\n.X_HTGfh8ojs4pC87x7V4 {\n  padding: 0.75rem;\n}\n.O29eCU7Nlh61Vj5FkU7F button,\n.X_HTGfh8ojs4pC87x7V4 button {\n  height: 1.5rem;\n  min-width: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Switch/style.module.less"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA,8BAAA;EAAA,wBAAA;EAAA,sBAAA;EAAA;AAAA;AAGE;EAAA,mBAAA;EAAA,oBAAA;EAAA,oBAAA;EAAA,6CAAA;EACA,kBAAA;EACA;AAFA;AAJJ;EASI,QAAA;EACA,SAAA;AACJ;AAXA;EAcI,6BAAA;AAAJ;AAME;;EAAA;AAAA;AAGE;;EAAA,cAAA;EACA;AADA","sourcesContent":[".switch {\n  @apply flex justify-between border-b ~'border-midLight';\n\n  .switch-label {\n    @apply text-sm text-darkest;\n    font-family: Inter;\n    line-height: 18px;\n  }\n  :global .ant-switch-handle {\n    top: 3px;\n    left: 3px;\n  }\n\n  :global .ant-switch-checked .ant-switch-handle {\n    left: calc(100% - 18px - 3px);\n  }\n}\n\n.switch,\n.switch-plain {\n  @apply p-3;\n\n  button {\n    @apply h-6;\n    min-width: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": "O29eCU7Nlh61Vj5FkU7F",
	"switch-label": "ejeNqCAxWeBtWjN99d_S",
	"switch-plain": "X_HTGfh8ojs4pC87x7V4"
};
export default ___CSS_LOADER_EXPORT___;
