import cx from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as IconEmptyBucket } from '@/assets/svg/icons/icon-empty-bucket.svg';
import Button from '@/components/Button';
import { STATIC_DATA_ADD_BUCKET_MODAL } from '@/constants/modals-static-data';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import { useCreateNewBucket } from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import AddEntityWithBenchmarkModal from '@/partials/Modal/AddEntityWithBenchmarkModal';

import style from './style.module.less';

const EmptyBucketState: React.FCWithChild = () => {
  const currentWatchlist = useCurrentWatchlist();

  const createNewBucket = useCreateNewBucket();

  const [isAddNewBucketModalOpen, setIsAddNewBucketModalOpen] = useState(false);

  const handleAddNewBucketModalOpen = () => {
    setIsAddNewBucketModalOpen(prev => !prev);
  };
  const { data: benchmarkOptionsResponse } = useBenchmarkOptions();

  const onSaveClick = async (name: string, benchmarkId?: string) => {
    const benchmarkName =
      benchmarkOptionsResponse?.find(b => b.secId === benchmarkId)?.name ?? '';
    await createNewBucket.mutateAsync({
      funds: [],
      name,
      index: benchmarkName,
      indexSecId: benchmarkId || '',
    });
  };

  return (
    <div className={cx(style.container)}>
      <IconEmptyBucket />
      {
        <>
          <h3 className={cx(style['container-title'], 'mt-7')}>
            You haven't created any buckets yet.
          </h3>
          <p className={style['container-message']}>
            Add your first bucket to start building your watchlist and
            personalise your dashboard.
          </p>
          <Button
            htmlType="button"
            type="primary"
            className={style['btn-add']}
            icon={<AddIcon className={style['icon-add']} />}
            onClick={() => {
              setIsAddNewBucketModalOpen(true);
            }}
          >
            Add Bucket
          </Button>
        </>
      }
      <AddEntityWithBenchmarkModal
        entities={currentWatchlist.data?.buckets ?? []}
        isVisible={isAddNewBucketModalOpen}
        toggleModal={handleAddNewBucketModalOpen}
        onSaveClick={onSaveClick}
        staticModalData={STATIC_DATA_ADD_BUCKET_MODAL}
      />
    </div>
  );
};

export default EmptyBucketState;
