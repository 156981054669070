import { LIMIT_FUNDS_PER_BUCKET } from '@aminsights/shared';
import { useEffect, useState } from 'react';

export type UseOnCheckedRowReturn<T> = ReturnType<typeof useOnCheckedRow<T>>;
const useOnCheckedRow = <T>(
  total: number,
  limit: number = LIMIT_FUNDS_PER_BUCKET,
) => {
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const [isAllCheckboxChecked, setIsAllCheckboxChecked] = useState(false);
  const [checkedData, setCheckedData] = useState<T[]>([]);

  const onCheckedRow = (isins: string[]) => {
    if (!isins.length) {
      setCheckedRows([]);
      setIsAllCheckboxChecked(false);
      setCheckedData(prev => prev.map(data => ({ ...data, isChecked: false })));
    } else {
      setCheckedRows(prevCheckedRows => {
        const newCheckedRows = [...prevCheckedRows];
        isins.forEach(value => {
          const index = newCheckedRows.indexOf(value);
          if (index !== -1) {
            newCheckedRows.splice(index, 1);
          } else {
            newCheckedRows.push(value);
          }
        });
        return newCheckedRows;
      });
    }
  };

  useEffect(() => {
    if (total && limit) {
      setIsAllCheckboxChecked(
        [limit, total].some(n => n === checkedRows.length),
      );
    }
  }, [checkedRows, limit, total]);

  return {
    checkedRows,
    setCheckedRows,
    isAllCheckboxChecked,
    setIsAllCheckboxChecked,
    onCheckedRow,
    checkedData,
    setCheckedData,
  };
};

export default useOnCheckedRow;
