/* eslint-disable no-param-reassign */
import { EDateFilterValues } from '@aminsights/shared';
import { Slider } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import Accordion, { Panel } from '@/components/Accordion';
import { SLIDER_MAX, SLIDER_MIN } from '@/constants';
import useProvideExplore from '@/pages/app/Explore/useProvideExplore';
import { useDatePickerContext } from '@/partials/DatePicker';
import ExploreFilterModal from '@/partials/ExploreFilterModal';

import { ExploreModalProps } from '../types';
import { adjustMarksToAvoidDuplicates } from '../utils/adjustMarksToAvoidDuplicates';
import { countSelectedFilter } from '../utils/countSelectedFilter';
import { getChangedMarkValues } from '../utils/getChangedMarkValues';
import { getRangeSliderClassName } from '../utils/getRangeSliderClassName';
import { getValueFromMarks } from '../utils/getValueFromMarks';
import { updateRangeAfterSubmit } from '../utils/updateRangeAfterSubmit';
import style from './style.module.less';

const betaMarks: Record<number, string> = {
  0: '0',
  25: '0.5',
  50: '1',
  75: '1.5',
  100: '2>',
};

const upsideAndDownSideMarks: Record<number, string> = {
  0: '0%',
  25: '50%',
  50: '100%',
  75: '150%',
  100: '200%>',
};

const trackingErrorMarks: Record<number, string> = {
  0: '0%',
  25: '5%',
  50: '10%',
  75: '15%',
  100: '20%>',
};

const stdDeviationMarks: Record<number, string> = {
  0: '0%',
  20: '10%',
  40: '20%',
  60: '30%',
  80: '40%',
  100: '50%>',
};

const maximumDrawdownMarks: Record<number, string> = {
  0: '<-40%',
  25: '-30%',
  50: '-20%',
  75: '-10%',
  100: '0',
};

const sortinoRatioMarks: Record<number, string> = {
  0: '<-2',
  25: '-1',
  50: '0',
  75: '1',
  100: '2>',
};

export const RiskFilterModal: React.FCWithChild<ExploreModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { state, setFilterParameters, setSearchParameters } =
    useProvideExplore();
  const { value: datePickerValue } = useDatePickerContext();

  const [betaRange, setBetaRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX,
  ]);
  const [upsideRange, setUpsideRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX,
  ]);
  const [downsideRange, setDownsideRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX,
  ]);
  const [trackingErrorRange, setTrackingErrorRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX,
  ]);
  const [stdDeviationRange, setStdDeviationRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX,
  ]);
  const [maximumDrawdownRange, setMaximumDrawdownRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX,
  ]);
  const [sortinoRatioRange, setSortinoRatioRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX,
  ]);

  const [betaChange, setBetaChange] = useState<boolean>(false);
  const [upsideChange, setUpsideChange] = useState<boolean>(false);
  const [downsideChange, setDownsideChange] = useState<boolean>(false);
  const [trackingErrorChange, setTrackingErrorChange] =
    useState<boolean>(false);
  const [stdDeviationChange, setStdDeviationChange] = useState<boolean>(false);
  const [maximumDrawdownChange, setMaximumDrawdownChange] =
    useState<boolean>(false);
  const [sortinoRatioChange, setSortinoRatioChange] = useState<boolean>(false);

  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [disabled, setDisabled] = useState(true);

  const [betaRangeMin, betaRangeMax] = useMemo(() => betaRange, [betaRange]);
  const [upsideRangeMin, upsideRangeMax] = useMemo(
    () => upsideRange,
    [upsideRange],
  );
  const [downsideRangeMin, downsideRangeMax] = useMemo(
    () => downsideRange,
    [downsideRange],
  );
  const [trackingErrorRangeMin, trackingErrorRangeMax] = useMemo(
    () => trackingErrorRange,
    [trackingErrorRange],
  );

  const [stdDeviationRangeMin, stdDeviationRangeMax] = useMemo(
    () => stdDeviationRange,
    [stdDeviationRange],
  );
  const [maximumDrawdownRangeMin, maximumDrawdownRangeMax] = useMemo(
    () => maximumDrawdownRange,
    [maximumDrawdownRange],
  );
  const [sortinoRatioRangeMin, sortinoRatioRangeMax] = useMemo(
    () => sortinoRatioRange,
    [sortinoRatioRange],
  );

  const handleOnClear = () => {
    setBetaRange([SLIDER_MIN, SLIDER_MAX]);
    setBetaChange(false);
    setUpsideRange([SLIDER_MIN, SLIDER_MAX]);
    setUpsideChange(false);
    setDownsideRange([SLIDER_MIN, SLIDER_MAX]);
    setDownsideChange(false);
    setTrackingErrorRange([SLIDER_MIN, SLIDER_MAX]);
    setTrackingErrorChange(false);
    setStdDeviationRange([SLIDER_MIN, SLIDER_MAX]);
    setStdDeviationChange(false);
    setMaximumDrawdownRange([SLIDER_MIN, SLIDER_MAX]);
    setMaximumDrawdownChange(false);
    setSortinoRatioRange([SLIDER_MIN, SLIDER_MAX]);
    setSortinoRatioChange(false);
  };

  useEffect(() => {
    if (state.clearState) {
      handleOnClear();
    }
  }, [state.clearState]);

  const handleOnApply = () => {
    const [betaMin, betaMax] = getValueFromMarks(betaRange, betaMarks);

    const [upsideMin, upsideMax] = getValueFromMarks(
      upsideRange,
      upsideAndDownSideMarks,
    );

    const [downsideMin, downsideMax] = getValueFromMarks(
      downsideRange,
      upsideAndDownSideMarks,
    );

    const [trackingErrorMin, trackingErrorMax] = getValueFromMarks(
      trackingErrorRange,
      trackingErrorMarks,
    );

    const [stdDeviationMin, stdDeviationMax] = getValueFromMarks(
      stdDeviationRange,
      stdDeviationMarks,
    );

    const [maximumDrawdownMin, maximumDrawdownMax] = getValueFromMarks(
      maximumDrawdownRange,
      maximumDrawdownMarks,
    );

    const [sortinoRatioMin, sortinoRatioMax] = getValueFromMarks(
      sortinoRatioRange,
      sortinoRatioMarks,
    );

    if (datePickerValue) {
      if (datePickerValue.mode === EDateFilterValues['5YR']) {
        setSearchParameters({
          yearDropDown: 'M60',
        });
      } else if (datePickerValue.mode === EDateFilterValues['3YR']) {
        setSearchParameters({
          yearDropDown: 'M36',
        });
      } else {
        setSearchParameters({
          yearDropDown: 'M12',
        });
      }
    }

    if (betaChange) {
      setFilterParameters({
        betaRange: [betaMin, betaMax === 2 ? Number.MAX_SAFE_INTEGER : betaMax],
      });
    }

    if (upsideChange) {
      setFilterParameters({
        upsideRange: [
          upsideMin,
          upsideMax === 200 ? Number.MAX_SAFE_INTEGER : upsideMax,
        ],
      });
    }

    if (downsideChange) {
      setFilterParameters({
        downsideRange: [
          downsideMin,
          downsideMax === 200 ? Number.MAX_SAFE_INTEGER : downsideMax,
        ],
      });
    }

    if (trackingErrorChange) {
      setFilterParameters({
        trackingErrorRange: [
          trackingErrorMin,
          trackingErrorMax === 20 ? Number.MAX_SAFE_INTEGER : trackingErrorMax,
        ],
      });
    }

    if (stdDeviationChange) {
      setFilterParameters({
        stdDeviationRange: [
          stdDeviationMin,
          stdDeviationMax === 50 ? Number.MAX_SAFE_INTEGER : stdDeviationMax,
        ],
      });
    }

    if (maximumDrawdownChange) {
      setFilterParameters({
        maximumDrawdownRange: [
          maximumDrawdownMin === -40
            ? Number.MAX_SAFE_INTEGER * -1
            : maximumDrawdownMin,
          maximumDrawdownMax,
        ],
      });
    }

    if (sortinoRatioChange) {
      setFilterParameters({
        sortinoRatioRange: [
          sortinoRatioMin === -2
            ? Number.MAX_SAFE_INTEGER * -1
            : sortinoRatioMin,
          sortinoRatioMax === 2 ? Number.MAX_SAFE_INTEGER : sortinoRatioMax,
        ],
      });
    }

    if (selectedFilter === 0) {
      setFilterParameters({
        betaRange: [],
        upsideRange: [],
        downsideRange: [],
        trackingErrorRange: [],
        stdDeviationRange: [],
        maximumDrawdownRange: [],
        sortinoRatioRange: [],
      });
    }
    onClose();
  };

  useEffect(() => {
    const count = countSelectedFilter([
      betaChange,
      upsideChange,
      downsideChange,
      trackingErrorChange,
      stdDeviationChange,
      maximumDrawdownChange,
      sortinoRatioChange,
    ]);
    setSelectedFilter(count);
  }, [
    betaChange,
    upsideChange,
    downsideChange,
    trackingErrorChange,
    stdDeviationChange,
    maximumDrawdownChange,
    sortinoRatioChange,
  ]);

  useEffect(() => {
    const filters = state.filterParameters;

    setDisabled(
      ![
        filters.betaRange,
        filters.upsideRange,
        filters.downsideRange,
        filters.trackingErrorRange,
        filters.stdDeviationRange,
        filters.maximumDrawdownRange,
        filters.sortinoRatioRange,
      ].some(i => (Array.isArray(i) ? !!i.length : !!i)),
    );

    updateRangeAfterSubmit(
      filters.betaRange || [],
      setBetaRange,
      setBetaChange,
      betaMarks,
    );
    updateRangeAfterSubmit(
      filters.upsideRange || [],
      setUpsideRange,
      setUpsideChange,
      upsideAndDownSideMarks,
    );
    updateRangeAfterSubmit(
      filters.downsideRange || [],
      setDownsideRange,
      setDownsideChange,
      upsideAndDownSideMarks,
    );
    updateRangeAfterSubmit(
      filters.trackingErrorRange || [],
      setTrackingErrorRange,
      setTrackingErrorChange,
      trackingErrorMarks,
    );
    updateRangeAfterSubmit(
      filters.stdDeviationRange || [],
      setStdDeviationRange,
      setStdDeviationChange,
      stdDeviationMarks,
    );
    updateRangeAfterSubmit(
      filters.maximumDrawdownRange || [],
      setMaximumDrawdownRange,
      setMaximumDrawdownChange,
      maximumDrawdownMarks,
    );
    updateRangeAfterSubmit(
      filters.sortinoRatioRange || [],
      setSortinoRatioRange,
      setSortinoRatioChange,
      sortinoRatioMarks,
    );
  }, [
    state.filterParameters.betaRange,
    state.filterParameters.upsideRange,
    state.filterParameters.downsideRange,
    state.filterParameters.trackingErrorRange,
    state.filterParameters.stdDeviationRange,
    state.filterParameters.maximumDrawdownRange,
    state.filterParameters.sortinoRatioRange,
  ]);

  return (
    <ExploreFilterModal
      title="Risk"
      description="Vs. category index"
      showDropdown={true}
      isVisible={isOpen}
      isDisableApply={disabled && !selectedFilter}
      isDisableClear={!selectedFilter}
      toggleModal={onClose}
      onClear={handleOnClear}
      onApply={handleOnApply}
    >
      <div>
        <Accordion defaultActiveKey="1">
          <Panel
            header="Beta"
            dataTestId={'riskLabelBeta'}
            key="1"
            extra={
              <span>
                {getChangedMarkValues(betaChange, betaRange, betaMarks)}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'riskSliderBeta'}
            >
              <Slider
                className={style[getRangeSliderClassName(betaChange)]}
                tooltip={{ open: false }}
                range
                step={25}
                max={SLIDER_MAX}
                marks={betaMarks}
                value={[betaRangeMin, betaRangeMax]}
                onChange={value => {
                  setBetaRange(adjustMarksToAvoidDuplicates(value, betaMarks));
                  setBetaChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Upside"
            dataTestId={'riskLabelUpside'}
            key="2"
            extra={
              <span>
                {getChangedMarkValues(
                  upsideChange,
                  upsideRange,
                  upsideAndDownSideMarks,
                )}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'riskSliderUpside'}
            >
              <Slider
                className={style[getRangeSliderClassName(upsideChange)]}
                tooltip={{ open: false }}
                range
                step={25}
                max={SLIDER_MAX}
                marks={upsideAndDownSideMarks}
                value={[upsideRangeMin, upsideRangeMax]}
                onChange={value => {
                  setUpsideRange(
                    adjustMarksToAvoidDuplicates(value, upsideAndDownSideMarks),
                  );
                  setUpsideChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Downside"
            dataTestId={'riskLabelDownside'}
            key="3"
            extra={
              <span>
                {getChangedMarkValues(
                  downsideChange,
                  downsideRange,
                  upsideAndDownSideMarks,
                )}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'riskSliderDownside'}
            >
              <Slider
                className={style[getRangeSliderClassName(downsideChange)]}
                tooltip={{ open: false }}
                range
                step={25}
                max={SLIDER_MAX}
                marks={upsideAndDownSideMarks}
                value={[downsideRangeMin, downsideRangeMax]}
                onChange={value => {
                  setDownsideRange(
                    adjustMarksToAvoidDuplicates(value, upsideAndDownSideMarks),
                  );
                  setDownsideChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Tracking Error"
            dataTestId={'riskLabelTrackingError'}
            key="4"
            extra={
              <span>
                {getChangedMarkValues(
                  trackingErrorChange,
                  trackingErrorRange,
                  trackingErrorMarks,
                )}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'riskSliderTrackingError'}
            >
              <Slider
                className={style[getRangeSliderClassName(trackingErrorChange)]}
                tooltip={{ open: false }}
                range
                step={25}
                max={SLIDER_MAX}
                marks={trackingErrorMarks}
                value={[trackingErrorRangeMin, trackingErrorRangeMax]}
                onChange={value => {
                  setTrackingErrorRange(
                    adjustMarksToAvoidDuplicates(value, trackingErrorMarks),
                  );
                  setTrackingErrorChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Std. Deviation"
            dataTestId={'riskLabelStdDeviation'}
            key="5"
            extra={
              <span>
                {getChangedMarkValues(
                  stdDeviationChange,
                  stdDeviationRange,
                  stdDeviationMarks,
                )}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'riskSliderStdDeviation'}
            >
              <Slider
                className={style[getRangeSliderClassName(stdDeviationChange)]}
                tooltip={{ open: false }}
                range
                step={20}
                max={SLIDER_MAX}
                marks={stdDeviationMarks}
                value={[stdDeviationRangeMin, stdDeviationRangeMax]}
                onChange={value => {
                  setStdDeviationRange(
                    adjustMarksToAvoidDuplicates(value, stdDeviationMarks),
                  );
                  setStdDeviationChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Max. Drawdown"
            dataTestId={'riskLabelMaxDrawdown'}
            key="6"
            extra={
              <span>
                {getChangedMarkValues(
                  maximumDrawdownChange,
                  maximumDrawdownRange,
                  maximumDrawdownMarks,
                )}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'riskSliderMaxDrawdown'}
            >
              <Slider
                className={
                  style[getRangeSliderClassName(maximumDrawdownChange)]
                }
                tooltip={{ open: false }}
                range
                step={25}
                max={SLIDER_MAX}
                marks={maximumDrawdownMarks}
                value={[maximumDrawdownRangeMin, maximumDrawdownRangeMax]}
                onChange={value => {
                  setMaximumDrawdownRange(
                    adjustMarksToAvoidDuplicates(value, maximumDrawdownMarks),
                  );
                  setMaximumDrawdownChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Sortino Ratio"
            dataTestId={'riskLabelSortinoRatio'}
            key="7"
            extra={
              <span>
                {getChangedMarkValues(
                  sortinoRatioChange,
                  sortinoRatioRange,
                  sortinoRatioMarks,
                )}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'riskSliderSortinoRatio'}
            >
              <Slider
                className={style[getRangeSliderClassName(sortinoRatioChange)]}
                tooltip={{ open: false }}
                range
                step={25}
                max={SLIDER_MAX}
                marks={sortinoRatioMarks}
                value={[sortinoRatioRangeMin, sortinoRatioRangeMax]}
                onChange={value => {
                  setSortinoRatioRange(
                    adjustMarksToAvoidDuplicates(value, sortinoRatioMarks),
                  );
                  setSortinoRatioChange(true);
                }}
              />
            </div>
          </Panel>
        </Accordion>
      </div>
    </ExploreFilterModal>
  );
};
