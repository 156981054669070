// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kfSkbx7lRQ_PNJb30QSa .ifiNz2my5vWD8HaZ1FYu {\n  font-size: 1rem;\n  line-height: 1.5rem;\n  font-weight: 700\n}\n.kfSkbx7lRQ_PNJb30QSa .wbblhiDfVyOOUb4hr2HY {\n  margin-top: 0.75rem;\n  --tw-bg-opacity: 1;\n  background-color: rgb(255 255 255 / var(--tw-bg-opacity));\n  padding: 1.25rem;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 400;\n  --tw-text-opacity: 1;\n  color: rgb(84 85 118 / var(--tw-text-opacity));\n  white-space: pre-line\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/FundAndInvestmentTrust/components/InfoAndStats/Summary/style.module.less"],"names":[],"mappings":"AAII;EAAA,eAAA;EAAA,mBAAA;EAAA;AAAA;AAIA;EAAA,mBAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,gBAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA,8CAAA;EACA;AADA","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.summary {\n  .summary__title {\n    @apply text-base font-bold;\n  }\n\n  .summary__content {\n    @apply bg-white p-5 text-sm font-normal text-neutral mt-3;\n    white-space: pre-line;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary": "kfSkbx7lRQ_PNJb30QSa",
	"summary__title": "ifiNz2my5vWD8HaZ1FYu",
	"summary__content": "wbblhiDfVyOOUb4hr2HY"
};
export default ___CSS_LOADER_EXPORT___;
