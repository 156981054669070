import { Collapse } from 'antd';

import { ReactComponent as ArrowIcon } from '@/assets/svg/icons/icon-arrow-down-stroke.svg';

import style from './style.module.less';

const FUND_BASICS_KEY = 'fundBasics';

export const CompareCollapse: React.FC<{
  children: React.ReactNode;
  activeKey: string[];
  onChange: (keys: string[] | string) => void;
}> = ({ children, activeKey, onChange }) => {
  return (
    <Collapse
      key={'mobile'}
      className={style['compare-tool-accordion']}
      ghost
      activeKey={activeKey}
      onChange={onChange}
      defaultActiveKey={FUND_BASICS_KEY}
      collapsible={'header'}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <ArrowIcon
          className="icon"
          style={{
            transform: isActive ? 'rotate(180deg)' : '',
          }}
        />
      )}
    >
      {children}
    </Collapse>
  );
};
