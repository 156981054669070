//TODO: Perhaps we should consider to use enum
// Also, do not touch the order of items in this array
// Compare tool relies on this order ->
// If you need to extand or modify it, consult with the team
export const CreditQualityBreakdownTypeCodes = [
  {
    typeCode: '1',
    definition: 'Government',
  },
  {
    typeCode: '2',
    definition: 'AAA',
  },
  {
    typeCode: '3',
    definition: 'AA',
  },
  {
    typeCode: '4',
    definition: 'A',
  },
  {
    typeCode: '5',
    definition: 'BBB',
  },
  {
    typeCode: '6',
    definition: 'BB',
  },
  {
    typeCode: '7',
    definition: 'B',
  },
  {
    typeCode: '8',
    definition: 'Below B',
  },
  {
    typeCode: '9',
    definition: 'Not Rated',
  },
];
