import { Fund, FundPlatformsEnum } from '@aminsights/contract';
import {
  getFundOrInvestmentTrustTitle,
  isAllocationBroadAssetClass,
  isEquityBroadAssetClass,
} from '@aminsights/shared';
import { Tabs } from 'antd';
import cx from 'classnames';

import { Table } from '@/components/Table';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import EquityStats from '../InfoAndStats/EquityStats';
import Fees from '../InfoAndStats/Fees';
import FundAndTrustBasic from '../InfoAndStats/FundAndTrustBasic';
import Income from '../InfoAndStats/Income';
import Summary from '../InfoAndStats/Summary';
import style from './style.module.less';

enum TAB_KEYS {
  DETAILS = 'DETAILS',
  FEES = 'FEES',
  INCOME = 'INCOME',
  EQUITY_STATS = 'EQUITY_STATS',
  PLATFORMS = 'PLATFORMS',
}

const platformColumns = [
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Availability',
    dataIndex: 'availability',
  },
];

const getPlatformLabel = (
  type?: FundPlatformsEnum,
  platforms?: FundPlatformsEnum[],
) => {
  if (!type || !platforms?.length) return 'No';
  return platforms?.includes(type) ? 'Yes' : 'No';
};

const getPlatformsRow = (fund?: Fund) => [
  {
    type: '7IM',
    availability: getPlatformLabel(FundPlatformsEnum.SevenIm, fund?.platforms),
  },
  {
    type: 'abrdn Elevate',
    availability: getPlatformLabel(
      FundPlatformsEnum.AbrdnElevate,
      fund?.platforms,
    ),
  },
  {
    type: 'abrdn Wrap',
    availability: getPlatformLabel(
      FundPlatformsEnum.AbrdnWrap,
      fund?.platforms,
    ),
  },
  {
    type: 'Aegon Platform',
    availability: getPlatformLabel(
      FundPlatformsEnum.AegonPlatform,
      fund?.platforms,
    ),
  },
  {
    type: 'Aegon Retirement Choices',
    availability: getPlatformLabel(FundPlatformsEnum.Arc, fund?.platforms),
  },
  {
    type: 'Aviva',
    availability: getPlatformLabel(FundPlatformsEnum.Aviva, fund?.platforms),
  },
  {
    type: 'One Retirement',
    availability: getPlatformLabel(
      FundPlatformsEnum.OneRetirement,
      fund?.platforms,
    ),
  },
  {
    type: 'Parmenion',
    availability: getPlatformLabel(
      FundPlatformsEnum.Parmenion,
      fund?.platforms,
    ),
  },
  {
    type: 'Quilter',
    availability: getPlatformLabel(FundPlatformsEnum.Quilter, fund?.platforms),
  },
  {
    type: 'Scottish Widows',
    availability: getPlatformLabel(
      FundPlatformsEnum.ScottishWidows,
      fund?.platforms,
    ),
  },
  {
    type: 'Transact',
    availability: getPlatformLabel(FundPlatformsEnum.Transact, fund?.platforms),
  },
  {
    type: 'Wealthtime',
    availability: getPlatformLabel(
      FundPlatformsEnum.Wealthtime,
      fund?.platforms,
    ),
  },
];

const Overview = () => {
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const { data } = useFundByIsInParam();

  const broadAssetClass = data?.fund.broadAssetClass ?? '';
  const isEquity = isEquityBroadAssetClass(broadAssetClass);
  const isMultiAsset = isAllocationBroadAssetClass(broadAssetClass);

  return (
    <div className={style['sections-container']}>
      <Summary />
      <div
        className={cx(style['overview-section'], style['section'])}
        data-test-id="fundDetailsSummaryTabFundDetails"
      >
        <Tabs
          className={cx(
            '&_.ant-tabs-nav]:mb-0 max-w-[calc(100vw-40px)]',
            'sm:max-w-[unset]',
            '[&_.ant-table-cell]:w-1/2',
          )}
          items={[
            {
              key: TAB_KEYS.DETAILS,
              label: (
                <span data-test-id="detailsTab">
                  {getFundOrInvestmentTrustTitle(!!isInvestmentTrust)} Details
                </span>
              ),
              children: <FundAndTrustBasic />,
            },
            {
              key: TAB_KEYS.FEES,
              label: <span data-test-id="feesTab">Fees</span>,
              children: <Fees />,
            },
            {
              key: TAB_KEYS.INCOME,
              label: <span data-test-id="incomeTab">Income</span>,
              children: <Income />,
            },
            ...(isEquity || isMultiAsset
              ? [
                  {
                    key: TAB_KEYS.EQUITY_STATS,
                    label: (
                      <span data-test-id="equityStatsTab">Equity Stats</span>
                    ),
                    children: <EquityStats />,
                  },
                ]
              : []),
            {
              key: TAB_KEYS.PLATFORMS,
              label: <span data-test-id="platformsTab">Platforms</span>,
              children: (
                <div className={style['platforms-table']}>
                  <Table
                    columns={platformColumns}
                    data={getPlatformsRow(data?.fund)}
                    paginateTotalCount={0}
                    uniqueKey="type"
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Overview;
