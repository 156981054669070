import { SignificantChangesItem } from '@aminsights/contract';
import cx from 'classnames';
import React from 'react';

import { Avatar } from '@/components';
import { generateAvatarColor } from '@/utils/generate-color';

import style from './style.module.less';

interface TableFixedProps {
  data: SignificantChangesItem;
}

const SignificantChangesFirstColumn: React.FCWithChild<TableFixedProps> = ({
  data,
}) => {
  return (
    <div className={style['first-col-wrapper']}>
      <div
        className={cx({
          [style['category-name']]: data.assetClassCode,
          [style['category-name-none']]: !data.assetClassCode,
        })}
      >
        {data.assetClassCode && (
          <Avatar color={generateAvatarColor(data.assetClassCode)} size="small">
            {data.assetClassCode}
          </Avatar>
        )}
      </div>
      <div className={style['fund-details']}>
        <div className={style['main-title']}>
          <span
            className="text-sm cursor-pointer text-darkest hover:text-primary"
            aria-hidden="true"
          >
            {data.fundName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignificantChangesFirstColumn;
