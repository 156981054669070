export const RENDER_BOX_COLOR_EQUITY: { [key: number]: string } = {
  0: '#99CCFF',
  2: '#4DA5FF',
  4: '#007FFF',
  6: '#0072E6',
  8: '#005FBF',
  10: '#004C99',
};
export const RENDER_BOX_COLOR_FIXED_INCOME: { [key: number]: string } = {
  0: '#97D17D',
  2: '#46AD34',
  4: '#008A00',
  6: '#007D00',
  8: '#006600',
  10: '#005200',
};
