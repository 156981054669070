import React, { ReactNode } from 'react';

import style from './style.module.less';

type SubtitleProps = {
  children: string | ReactNode;
};

const Subtitle: React.FCWithChild<SubtitleProps> = ({ children }) => {
  return <p className={style['subtitle-font']}>{children}</p>;
};

export default Subtitle;
