// ES RIPS index stores values like this
export enum WEEKDAYS {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export const weekdayToNumber = {
  [WEEKDAYS.SUNDAY]: 0,
  [WEEKDAYS.MONDAY]: 1,
  [WEEKDAYS.TUESDAY]: 2,
  [WEEKDAYS.WEDNESDAY]: 3,
  [WEEKDAYS.THURSDAY]: 4,
  [WEEKDAYS.FRIDAY]: 5,
  [WEEKDAYS.SATURDAY]: 6,
};

// Used for presentation purposes
export enum WEEKDAY_LABELS {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}
