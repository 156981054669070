import { PORTFOLIO_REFETCH_KEY } from '@aminsights/shared';
import React, { useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { Dropdown, Tabs } from '@/components';
import ElementWithDataTestId from '@/components/ElementWithDataTestId';
import { BREAKPOINTS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { evaluatePortfolioSubRoute } from '@/utils';
import { stopPropogationOnRightClick } from '@/utils/StopPropogationOnRightClick';

import { EMPTY_PAGE_SLUG } from '../../types';
import { useProvidePortfolio } from '../../useProvidePortfolio';
import style from './style.module.less';

export const PortfoliosScrollTab: React.FCWithChild = () => {
  const { app } = useAppContext();
  const history = useHistory();
  const { state, setPortfolio, fetchPortfolios } = useProvidePortfolio();
  const { portfolioId, tab } = useParams<{
    portfolioId: string;
    tab: string;
  }>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  //Effect to redirect if smth is wrong with the route
  useEffect(() => {
    // If refetch key is present, we don't want to redirect unitl the portfolios are fetched
    if (searchParams.has(PORTFOLIO_REFETCH_KEY)) return;
    const portfolioIsPresent = state.portfolios.some(
      p => p._id === portfolioId,
    );
    history.replace(
      `../${
        portfolioIsPresent
          ? portfolioId
          : state.portfolios[0]?._id || EMPTY_PAGE_SLUG
      }/${evaluatePortfolioSubRoute(tab)}`,
    );
  }, [portfolioId, state.portfolios.length, state.arePortfoliosLoading]);

  useEffect(() => {
    if (searchParams.has(PORTFOLIO_REFETCH_KEY)) {
      searchParams.delete(PORTFOLIO_REFETCH_KEY);
      fetchPortfolios().then(() => {
        history.replace({
          search: searchParams.toString(),
        });
      });
    }
  }, [searchParams]);

  const onNavigateTab = (newPortfolioId: string) => {
    setPortfolio(newPortfolioId);
    history.push(`../${newPortfolioId}/${evaluatePortfolioSubRoute(tab)}`);
  };

  return (
    <>
      {(app?.windowWidth || 0) < BREAKPOINTS.SM ? (
        <Dropdown
          className={style['bucket-dropdown']}
          rounded
          defaultValue={portfolioId}
          label="All"
          onSelect={selectedValue => {
            onNavigateTab(selectedValue);
          }}
          items={[
            ...state?.portfolios?.map(portfolio => ({
              label:
                (
                  <Link
                    to={`/portfolios/${portfolio._id}/holdings`}
                    onClick={event => {
                      stopPropogationOnRightClick(event);
                    }}
                  >
                    <ElementWithDataTestId label={portfolio.name} />
                  </Link>
                ) || '',
              value: portfolio._id,
            })),
          ]}
          placement="bottom"
        />
      ) : (
        <Tabs
          activeKey={portfolioId}
          onChange={onNavigateTab}
          defaultActiveKey=""
          items={
            state.arePortfoliosLoading
              ? [
                  {
                    label: 'Loading...',
                    key: 'loading',
                    disabled: true,
                  },
                ]
              : state?.portfolios?.map(portfolio => ({
                  label: (
                    <Link
                      to={`/portfolios/${portfolio._id}/holdings`}
                      onClick={event => {
                        event.stopPropagation();
                      }}
                    >
                      <ElementWithDataTestId label={portfolio.name} />
                    </Link>
                  ),
                  key: portfolio._id,
                })) || []
          }
        />
      )}
    </>
  );
};

export default PortfoliosScrollTab;
