import { APP_ROUTE_WATCHLIST } from '@aminsights/shared';
import { AreaConfig } from '@ant-design/charts';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetAbsoluteChartDataWithPortfolio from '@/hooks/query-hooks/chart-hooks/useGetAbsoluteChartDataWithPortfolio';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { parseRangeValues, useDatePickerContext } from '@/partials/DatePicker';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';

import ChartEmptyState from '../ChartEmptyState';
import BaseChart, { BaseChartPropsV2 } from '../components/BaseChart';
import { useDropDownContext } from '../Context';
import { PerformanceCardDetailsWithPortfolio } from '../utils/chart-data';
import { calculateStrokeColor } from '../utils/colors';
import { useChartColorsWithIsinsAndPortfolio } from '../utils/useChartColors';

interface AbsoluteChartWithPortfolioProps
  extends Omit<BaseChartPropsV2, 'onRemoveFund'> {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  scrollable?: boolean;
  portfolios?: string[];
  onRemoveFund?: (
    id: string,
    isBenchmark?: boolean,
    isPortfolio?: boolean,
  ) => void;
}

const AbsoluteChartV2WithPortfolio: React.FCWithChild<
  AbsoluteChartWithPortfolioProps
> = ({
  showFundLinks = true,
  emptyState,
  onRemoveFund,
  isins,
  benchmarkId,
  featuredIsins,
  scrollable,
  portfolios,
  chartColors: chartColorsProps,
}) => {
  const { value: investmentTrustFilter } = useDropDownContext();
  const { value: datePickerValue } = useDatePickerContext();
  const [legendData, setLegendData] = useState<
    PerformanceCardDetailsWithPortfolio[]
  >([]);

  // Contemplating whether to move this out or not
  // eslint-disable-next-line
  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];

  const { data, isLoading } = useGetAbsoluteChartDataWithPortfolio({
    benchmarkId,
    isins,
    period,
    investmentTrustReturn: investmentTrustFilter.absolute,
    featuredIsins,
    portfolios,
  });
  const absoluteData = data?.data || [];

  const chartColorsHook = useChartColorsWithIsinsAndPortfolio({
    isins,
    legend: data?.legendData || [],
    portfolioLegendIds: portfolios,
  });
  const chartColors = chartColorsProps || chartColorsHook;

  useEffect(() => {
    if (data?.legendData?.length && chartColors) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.label || '',
          value: f.value || '',
          id: f.id || '',
          isBenchmark: f.isBenchmark || false,
          label: f.label || '',
          isFeatured: f.isFeatured || false,
          color: chartColors[f.id || ''] || '',
        })),
      );
    }
  }, [data, chartColors]);

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: absoluteData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return chartColors[id] || '';
      },
    }),
    [absoluteData, chartColors],
  );
  // if isins are not defined - show loader no matter what
  // this will mean that we are waiting for isins to be defined to be provided from the parent control

  if (!absoluteData.length && !isLoading) {
    return (
      <ChartEmptyState
        subtitle={
          emptyState?.subTitle || (
            <>
              {'Add funds to your bucket '}
              <Link
                style={{ color: '#1890ff' }}
                to={`/${APP_ROUTE_WATCHLIST}/manage-buckets`}
              >
                here
              </Link>
            </>
          )
        }
        title={emptyState?.title || 'No funds in this bucket'}
        isDashboardPage={true}
      />
    );
  }

  return (
    <div>
      {!isLoading && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          enableHoverEffects={
            !!featuredIsins?.length && legendData.some(d => d.isFeatured)
          }
          dataTestId="absoluteChartWithPortfolio"
          onUpdateLegend={setLegendData}
          scrollable={scrollable}
        />
      )}
      {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
      <div
        data-test-id="absoluteChartWithPortfolioLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .map(legendItem => {
              const id = !legendItem.isPortfolio
                ? legendItem.isin
                : legendItem.id.split('_')[0];
              return (
                <ChartsLegendCard
                  key={legendItem.id}
                  label={legendItem.label}
                  idForFundDetailsLink={
                    showFundLinks ? legendItem.isin : undefined
                  }
                  id={legendItem.id}
                  tooltip={legendItem.tooltip || legendItem.label}
                  isIndex={legendItem.isBenchmark}
                  color={legendItem.color}
                  value={`${legendItem.value}`}
                  isFeatured={
                    !!featuredIsins?.length ? legendItem.isFeatured : false
                  }
                  isActive={legendItem.isActive}
                  onRemove={() => {
                    onRemoveFund?.(
                      id || '',
                      legendItem.isBenchmark,
                      legendItem.isPortfolio,
                    );
                  }}
                  targetLink={`/portfolios/${id}/holdings`}
                  superLabel={legendItem.isPortfolio ? 'Portfolio' : ''}
                />
              );
            })}
        />
      </div>
    </div>
  );
};

export default AbsoluteChartV2WithPortfolio;
