import { BondStatisticsTypeCodes } from '../constants/bondStatisticsTypeCodes';
export const getAvgQualityDefintion = (averageCreditQuality: number) => {
  if (averageCreditQuality) {
    return BondStatisticsTypeCodes.find(v => {
      const typeCode = v.typeCode.split('-');
      if (
        (averageCreditQuality &&
          +typeCode[0] <= averageCreditQuality &&
          +typeCode[1] >= averageCreditQuality) ||
        typeCode.length === 1
      ) {
        return true;
      }
      return false;
    })?.definition;
  }
  return undefined;
};
