export enum FailedStatus {
  NONE = 'none',
  INTERNET_CONNECTION = 'internet-connection',
  FUND_LIMIT = 'fund-limit',
  ISINS_NOT_FOUND = 'isin-not-found',
  FILE_EMPTY = 'file-empty',
  OTHER = 'other',
}
export type UnavailableISINsItems = {
  rowNumber?: number;
  isin: string;
};

export type UploadModalInfoBase = {
  title: string;
  description: React.ReactNode | string;
};

export type UploadModalInfoItems = {
  unavailableISINsList: UnavailableISINsItems[];
  failedStatus?: FailedStatus;
} & UploadModalInfoBase;
