import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { TableCheckboxHeader } from '@/pages/app/Explore/components/TableCheckbox';

import FundNameCheckboxWrapper, {
  CheckboxParams,
} from './FundNameCheckboxWrapper';

const FundIncomeColumns = (
  checkboxParams: CheckboxParams,
  dataTableRenderedAt: DataTableRenderedAt,
  showPrimaryShareClassIndicator?: boolean,
): IDataTableColumns[] => [
  {
    headerCheckbox: (): React.ReactNode =>
      dataTableRenderedAt !== DataTableRenderedAt.Watchlist && (
        <TableCheckboxHeader
          onClick={checkboxParams.onCheckedRow}
          topISINs={checkboxParams.topISINs}
          checked={checkboxParams.isAllCheckboxChecked}
        />
      ),
    title: 'Fund/Investment Trust',
    render: (item: Fund) => (
      <FundNameCheckboxWrapper
        item={item}
        checkboxParams={checkboxParams}
        dataTableRenderedAt={dataTableRenderedAt}
        showPrimaryShareClassIndicator={showPrimaryShareClassIndicator ?? false}
      />
    ),
    renderType: 'text',
    sortKey: 'shareClassDetails.code',
    isColumnFixed: true,
    loader: (
      <Skeleton
        avatar={{ size: 'small' }}
        title={false}
        paragraph={{ rows: 2 }}
        active
      />
    ),
  },
  {
    title: 'Yield',
    render: (item: Fund) => {
      const { yield12Month } = item;
      return yield12Month?.value === 0 ||
        !Number.isNaN(parseFloat(`${yield12Month?.value}`))
        ? `${Number(yield12Month?.value).toFixed(2)}%`
        : EMPTY_DATA_POINT;
    },
    renderType: 'number',
    sortKey: 'yield12Month.value',
  },
  {
    title: 'Income Frequency',
    render: (item: Fund) => item.incomeFrequency || EMPTY_DATA_POINT,
    renderType: 'text',
    sortKey: 'incomeFrequency',
  },
  // Data not available
  // {
  //   title: 'Ex Dividend Date',
  //   render: (item: Fund) => item.exDividendDate || '-',
  //   sortKey: 'exDividendDate',
  // },
  // {
  //   title: '',
  //   render: (item: Fund): React.ReactNode => (
  //     <Dropdown
  //       overlay={() => <ContextMenu currentBucket={item.bucket} />}
  //       trigger={['click']}
  //       placement="bottomRight"
  //     >
  //       <div>
  //         <MeatballMenu className="icon icon-meatball" />
  //       </div>
  //     </Dropdown>
  //   ),
  //   type: 'action',
  //   width: 50,
  // },
];

export default FundIncomeColumns;
