// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NGW_DBEeOsdF170vVDNg {\n  background-color: #ffffff;\n}\n.NGW_DBEeOsdF170vVDNg tbody > tr > td:nth-child(2n + 2) {\n  --tw-bg-opacity: 1;\n  background-color: rgb(247 247 247 / var(--tw-bg-opacity));\n}\n.NGW_DBEeOsdF170vVDNg tbody > tr > td:first-child {\n  --tw-bg-opacity: 1;\n  background-color: rgb(255 255 255 / var(--tw-bg-opacity));\n  font-weight: 700;\n  --tw-text-opacity: 1;\n  color: rgb(49 51 65 / var(--tw-text-opacity));\n}\n.NGW_DBEeOsdF170vVDNg thead th p {\n  text-align: center;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  font-weight: 300;\n  --tw-text-opacity: 1;\n  color: rgb(84 85 118 / var(--tw-text-opacity));\n}\n.NGW_DBEeOsdF170vVDNg thead .wXJ0w8TUHp2fX5ocQGzH::before {\n  display: none;\n}\n.NGW_DBEeOsdF170vVDNg thead tr:last-child th {\n  --tw-bg-opacity: 1;\n  background-color: rgb(255 255 255 / var(--tw-bg-opacity));\n}\n.NGW_DBEeOsdF170vVDNg thead tr:first-child th {\n  border-bottom-width: 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/FundAndInvestmentTrust/components/InfoAndStats/GenderPayGap/style.module.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;AADF;AAIM;EAAA,kBAAA;EAAA;AAAA;AAGA;EAAA,kBAAA;EAAA,yDAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;AAPN;EAWI,kBAAA;EACA,kBAAA;EAAA,iBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA,8CAAA;AAFJ;AAMI;EAAA;AAAA;AAIA;EAAA,kBAAA;EAAA;AAAA;AAIA;EAAA;AAAA","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.info-stats-gpg {\n  background-color: @white;\n  tbody > tr > td {\n    &:nth-child(2n + 2) {\n      @apply bg-grey-lighter;\n    }\n    &:first-child {\n      @apply font-bold bg-white text-darkest;\n    }\n  }\n  thead th p {\n    text-align: center;\n    @apply text-xs font-light text-neutral;\n  }\n\n  thead .ant-table-cell::before {\n    @apply hidden;\n  }\n\n  thead tr:last-child th {\n    @apply bg-white;\n  }\n\n  thead tr:first-child th {\n    @apply border-b-0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-stats-gpg": "NGW_DBEeOsdF170vVDNg",
	"ant-table-cell": "wXJ0w8TUHp2fX5ocQGzH"
};
export default ___CSS_LOADER_EXPORT___;
