import {
  DashboardSummaryCardItem,
  SummaryCardType,
} from '@aminsights/contract';

export type SummaryCardProps = {
  cardItem?: DashboardSummaryCardItem;
  loading: boolean;
};

export type SummaryCardMapping = Map<
  SummaryCardType,
  {
    title: string;
    tooltipMessage?: string;
  }
>;

export const summaryCardToolTips: Record<SummaryCardType, string> = {
  [SummaryCardType.TopRelative]:
    'The fund/trust in your watchlist with the highest relative performance to its own index over ' +
    'the past 30 days.\n\nNote for investment trusts: this will be NAV performance',
  [SummaryCardType.BottomRelative]:
    'The fund/trust in your watchlist with the lowest relative performance to its own index over ' +
    'the past 30 days.\n\nNote for investment trusts: this will be in NAV performance',
  [SummaryCardType.BiggestChangeInPremiumDiscount]:
    'The investment trust in your watchlist with the biggest change in premium/discount over the past ' +
    '30 days.\n\nThe figure is the change not the premium/discount itself.',
  [SummaryCardType.TopAbsolute]:
    'The investment trust in your watchlist with the highest absolute performance over the past 30 days.' +
    '\n\nNote for investment trust: this will be in NAV performance.',
  [SummaryCardType.TopSharePriceMove]:
    'The investment trust in your watchlist with the highest share price performance over the past 30 days.',
  [SummaryCardType.BottomAbsolute]:
    'The investment trust in your watchlist with the lowest absolute performance over the past 30 days.' +
    '\n\nNote for investment trust: this will be in NAV performance.',
};

export const summaryCardMapping: SummaryCardMapping = new Map([
  [
    SummaryCardType.TopRelative,
    {
      title: 'Top Relative',
      tooltipMessage: summaryCardToolTips[SummaryCardType.TopRelative],
    },
  ],
  [
    SummaryCardType.BottomRelative,
    {
      title: 'Bottom Relative',
      tooltipMessage: summaryCardToolTips[SummaryCardType.BottomRelative],
    },
  ],
  [
    SummaryCardType.BiggestChangeInPremiumDiscount,
    {
      title: 'Biggest Move in Pre./Disc.',
      tooltipMessage:
        summaryCardToolTips[SummaryCardType.BiggestChangeInPremiumDiscount],
    },
  ],
  [
    SummaryCardType.TopAbsolute,
    {
      title: 'Top Absolute',
      tooltipMessage: summaryCardToolTips[SummaryCardType.TopAbsolute],
    },
  ],
  [
    SummaryCardType.BottomAbsolute,
    {
      title: 'Bottom Absolute',
      tooltipMessage: summaryCardToolTips[SummaryCardType.BottomAbsolute],
    },
  ],
  [
    SummaryCardType.TopSharePriceMove,
    {
      title: 'Top Share Price Move',
      tooltipMessage: summaryCardToolTips[SummaryCardType.TopSharePriceMove],
    },
  ],
]);
