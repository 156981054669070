import type { FC } from 'react';

import { toCamelCase } from '@/utils/toCamelCase';

interface ElementWIthDataTestIdProps {
  id?: string;
  label: string;
  className?: string;
}

const ElementWithDataTestId: FC<ElementWIthDataTestIdProps> = ({
  id,
  label,
  className,
}) => {
  return (
    <span className={className} data-test-id={toCamelCase(id ? id : label)}>
      {label}
    </span>
  );
};

export default ElementWithDataTestId;
