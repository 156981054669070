import { DATE_FORMAT_DASHED } from '@aminsights/shared';
import {
  autoUpdate,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import dayJsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { useState } from 'react';
import { createPortal } from 'react-dom';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { getLatestDateForNewPortfolioColumn } from '@/utils/date';

import style from './style.module.less';

const AntDatePicker = generatePicker<Dayjs>(dayJsGenerateConfig);

export interface PortfolioFundHeaderDateSelectProps {
  isValid: boolean;

  date: Dayjs;
  disabledDates: string[];
  onUpdateDate?: (oldDate: Dayjs, newDate: Dayjs) => boolean;
  disableEdit?: boolean;
}

const PortfolioFundHeaderDateSelect: React.FCWithChild<
  PortfolioFundHeaderDateSelectProps
> = ({ isValid, date, disabledDates, onUpdateDate, children, disableEdit }) => {
  const { dispatch: dispatchApp } = useAppContext();
  const [isOpen, setIsOpen] = useState(!date.isValid());
  const dateValue = getLatestDateForNewPortfolioColumn(disabledDates, date);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: newState => {
      // If the modal is closed with an invalid value, important because esc will close the popover
      if (!newState && !date.isValid() && onUpdateDate) {
        const calculateLatestDate = (dates: string[]) => {
          const lastDateConvertedToDayJS = dayjs(dates[0], DATE_FORMAT_DASHED);
          return lastDateConvertedToDayJS.isValid()
            ? lastDateConvertedToDayJS
            : dayjs();
        };
        const latestDate = calculateLatestDate(
          disabledDates.filter(d => d !== ''),
        );

        let dateToSet =
          disabledDates.filter(d => d !== '').length !== 0
            ? latestDate.add(1, 'day')
            : latestDate;
        if (dayjs().diff(latestDate, 'day') > 0) {
          dateToSet = dayjs();
        }
        if (!onUpdateDate(date, dateToSet)) {
          dispatchApp({
            type: APP_ACTIONS.SET_ERROR_MESSAGE,
            payload: `You are trying to select already selected date.`,
          });
        }
      }

      !disableEdit && setIsOpen(newState);
    },
    placement: 'bottom-start',
    middleware: [offset({ mainAxis: 24, crossAxis: -25 }), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const rootPortal = document.getElementById('root-portal');

  // pfh = PorfolioFundHeader
  return (
    <div
      className={cx(
        'pfh-date-select inline-block relative px-4',
        style['pfh-date-select'],
      )}
    >
      {/* INPUT */}
      <div
        ref={refs.setReference}
        style={{ color: !isValid ? '#E64236' : 'inherit' }}
        className={cx('flex flex-row text-sm cursor-pointer')}
        // TODO: Consider changing this element to a button
        onClick={e => {
          e.stopPropagation();
          !disableEdit && setIsOpen(true);
        }}
        {...getReferenceProps()}
      >
        <span className={isOpen ? 'text-primary' : undefined}>{children}</span>
      </div>

      {/* CALENDAR */}
      {rootPortal &&
        createPortal(
          isOpen ? (
            <div
              ref={refs.setFloating}
              className={style['pfh-date-calendar-wrapper']}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {/* AntdDatePicker comes with its own popover, but it has not been reliable in positioning within an element, hence this approach exists (and has existed in other parts of the code base) */}
              <AntDatePicker
                open
                value={dateValue}
                onChange={newDate => {
                  if (newDate && onUpdateDate) {
                    const successUpdatingDate = onUpdateDate(date, newDate);
                    if (!successUpdatingDate) {
                      dispatchApp({
                        type: APP_ACTIONS.SET_ERROR_MESSAGE,
                        payload: `You are trying to select already selected date.`,
                      });
                    }
                  }
                }}
                disabledDate={d =>
                  disabledDates.some(dD => d.format(DATE_FORMAT_DASHED) === dD)
                }
                placement="bottomLeft"
                getPopupContainer={trigger => {
                  if (trigger.parentElement) return trigger.parentElement;
                  return trigger;
                }}
              />
            </div>
          ) : (
            <></>
          ),
          rootPortal,
          'calendar-portal',
        )}
    </div>
  );
};

export default PortfolioFundHeaderDateSelect;
