import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { Radio } from '@/components';
import Button from '@/components/Button';

import { buckets } from './data';
import style from './style.module.less';

const AddToBucket = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedBucket, setSelectedBucket] = useState('');
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobileSm: boolean = width <= 575;

  return (
    <div className={style.wrapper}>
      <div className={style.content}>
        <div className="h-full">
          <div className={style['heading-wrapper']}>
            <h3 className={style.title}>Add to Bucket</h3>
            <p className={style.description}>
              Select the bucket to add these funds/trusts
            </p>
          </div>

          <div className={style['content-wrapper']}>
            <p className="mb-2 text-xxs text-neutral">
              1 funds/trusts selected
            </p>

            <hr />

            <p className="mt-2 text-xxs text-neutral">Buckets</p>

            <div className={style['bucket-list']}>
              <Radio
                data={buckets}
                emitRadioValue={(value: string) => setSelectedBucket(value)}
              />
            </div>

            <hr />

            <div className={style['btn-create--wrapper']}>
              <Button
                htmlType="button"
                type="link"
                className="font-semibold p-0"
                icon={<AddIcon className={cx('icon', style['icon-add'])} />}
              >
                Create New Bucket
              </Button>
            </div>
            {/* <div className={style.fields}>
              <Input
                type="text"
                id="bucketName"
                name="bucketName"
                label="Bucket Name"
                style={{ borderColor: '#BAC0D0' }}
              />
              <Dropdown items={buckets} label="Select Index" className={style['index-dropdown']}/>
            </div> */}

            <div className={style['btn-add-bucket']}>
              <Button
                disabled={!selectedBucket}
                htmlType="button"
                type="primary"
                className="font-semibold w-full h-12 sm:h-10"
                onClick={() => null}
              >
                {isMobileSm ? 'Next' : 'Add to Bucket'}
              </Button>
              {isMobileSm && (
                <Button
                  disabled={!selectedBucket}
                  htmlType="button"
                  type="link"
                  className="font-semibold w-full h-10 mt-4 sm:hidden"
                  onClick={() => null}
                >
                  Save as Draft
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToBucket;
