import { BucketsApi } from '@aminsights/contract';
import { BucketErrors } from '@aminsights/shared';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as BrandLogo } from '@/assets/svg/icons/icon-logo-without-text.svg';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import style from './style.module.less';

const ShareBucketVerification = () => {
  const { search } = useLocation();
  const history = useHistory();
  const bucketsApi = new BucketsApi(openApiConfig());
  const appContext = useAppContext();
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const errorRedirect = `/watchlist/manage-buckets?error=${BucketErrors.BUCKET_NONEXISTENT}`;
    const token = searchParams.get('token');
    const id = searchParams.get('id');
    if (token) {
      bucketsApi
        .acceptSharedBucketInvite(token)
        .then(async res => {
          if (id) {
            queryClient.invalidateQueries([WATCHLIST]);
            history.push(`/watchlist/manage-buckets?current=${id}`);
            if (res.data.status === 204) {
              appContext.dispatch({
                type: APP_ACTIONS.SET_REGULAR_MESSAGE,
                payload: {
                  shouldUseTimer: true,
                  showSnackbarIcon: true,
                  text: `${res.data.message}`,
                },
              });
            }
          }
        })
        .catch(() => {
          history.push(errorRedirect);
        });
    } else {
      history.push(errorRedirect);
    }
  }, [search]);
  return (
    <div className={style['loader-bg']}>
      <div className={style['loader-main']}>
        <div className={style['brand']}>
          <BrandLogo />
          <p className={style['brand-text']}>AM Insights</p>
        </div>
        <div className={style['loading-infinite']}>
          <div className={style['loading-infinite-loader']} />
        </div>
      </div>
    </div>
  );
};

export default ShareBucketVerification;
