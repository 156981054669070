import {
  AutocompleteResponseSuggestionItem,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  APP_ROUTE_EXPLORE,
  APP_ROUTE_SIGNIFICANT_CHANGES,
  buildFundDetailsPath,
} from '@aminsights/shared';
import { minifiedParamKeysMap } from '@aminsights/shared';
import { LoadingOutlined, StarFilled } from '@ant-design/icons';
import { AutoComplete } from 'antd';
import cx from 'classnames';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconClose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as IconEnter } from '@/assets/svg/icons/icon-enter.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { ReactComponent as IconSearch } from '@/assets/svg/icons/icon-search.svg';
import useSearch from '@/hooks/query-hooks/search-hooks/useSearch';
import useProvideExplore from '@/pages/app/Explore/useProvideExplore';

import style from './style.module.less';

type OnSelectFunc = (
  term: string,
  searchItems: AutocompleteResponseSuggestionItem[],
  onError: (error: string) => void,
) => Promise<void> | void;

type SearchProps = {
  navSearch?: boolean;
  onChange?: (e?: ChangeEvent<HTMLInputElement>, term?: string) => void;
  isDropDownItems?: boolean;
  searchClose?: () => void;
  resultRoute?: typeof APP_ROUTE_EXPLORE | typeof APP_ROUTE_SIGNIFICANT_CHANGES;
  searchClassName?: string;
  placeholder?: string;
  searchIconClassName?: string;
  isExplorePage?: boolean;
  defaultValue?: string;
  onKeyEnter?: OnSelectFunc;
  onSelect?: OnSelectFunc;
  dataTestId: string;
  disabled?: boolean;
  filterOptions?: (option: AutocompleteResponseSuggestionItem) => boolean;
  searchConfig?: {
    term?: string;
    setTerm: (term: string) => void;
    query?: ReturnType<typeof useSearch>['autoCompleteQuery'];
  };
  onClear?: () => void;
  showArrow?: boolean;
};

const getMatchScore = (source: string, target: string) => {
  const sourceSplit = source.split(' ');
  const targetSplit = target.split(' ').filter(Boolean);
  return sourceSplit.filter(s => {
    const srcIndex = targetSplit.findIndex(ts =>
      s.toLowerCase().includes(ts.toLowerCase()),
    );
    return srcIndex >= 0;
  })?.length;
};

function getHighlightedText(text: string, isin: string, highlight: string) {
  if (highlight === isin) {
    return <span className="font-bold">{text}</span>;
  }

  const toHighlight = highlight.split(' ').filter(Boolean);
  const target = text.split(' ');

  return (
    <span>
      {target.map(t => {
        const srcIndex = toHighlight.findIndex(th =>
          t.toLowerCase().includes(th.toLowerCase()),
        );
        if (srcIndex >= 0) {
          const startStr = t.indexOf(toHighlight[srcIndex]);
          const endStr = startStr + toHighlight[srcIndex].length;
          return (
            <Fragment key={t}>
              {[...t].map((char, i) => (
                <span
                  key={i + char}
                  style={
                    i >= startStr && i <= endStr ? { fontWeight: 'bold' } : {}
                  }
                >
                  {char}
                </span>
              ))}{' '}
            </Fragment>
          );
        }
        return `${t} `;
      })}
    </span>
  );
}

/**
 * We need to break this component into smaller reusable components.
 * It's getting too big.
 */
const ExploreSearch: React.FCWithChild<SearchProps> = ({
  navSearch,
  onChange,
  isDropDownItems,
  searchClose,
  resultRoute = APP_ROUTE_EXPLORE,
  searchClassName,
  placeholder,
  searchIconClassName,
  isExplorePage,
  defaultValue,
  onKeyEnter,
  onSelect,
  dataTestId,
  disabled,
  filterOptions,
  searchConfig,
  onClear,
  showArrow,
}) => {
  const { state: exploreState } = useProvideExplore();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const renderFooter = () => (
    <span className="ant-select--title">
      Press <b>Enter</b> or <IconEnter className="icon search-enter" /> to
      search and <b>ESC</b> or <b>X</b> to cancel from search
    </span>
  );

  const renderItem = ({
    title,
    isin,
    highlightTerm,
    primaryShareClass,
  }: {
    title: string;
    isin: string;
    highlightTerm: string;
    primaryShareClass?: boolean;
  }) => ({
    value: isin,
    fundName: title,
    label: (
      <div
        data-test-id={isin}
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '8px',
        }}
      >
        <div className="flex items-center justify-center">
          <StarFilled
            style={{
              color: '#0072E6',
              opacity: primaryShareClass ? '1' : '0',
              fontSize: '14px',
            }}
          />
        </div>
        {getHighlightedText(title, isin, highlightTerm)}
      </div>
    ),
    id: isin,
  });

  const useSearchResults = useSearch();

  const { term, setFullTerm, autoCompleteQuery } = (() => {
    return {
      autoCompleteQuery:
        searchConfig?.query || useSearchResults.autoCompleteQuery,
      term: searchConfig?.term || useSearchResults.fullTerm,
      setFullTerm: searchConfig?.setTerm || useSearchResults.setFullTerm,
    };
  })();

  useEffect(() => {
    setFullTerm(defaultValue ?? '');
  }, [defaultValue]);

  const { setSearchParameters } = useProvideExplore();

  const history = useHistory();

  const allSuggestions = (autoCompleteQuery.data ?? [])
    .sort((a, b) => {
      return getMatchScore(b.fundName, term) - getMatchScore(a.fundName, term);
    })
    .filter(i => {
      if (filterOptions) {
        return filterOptions(i);
      }
      return true;
    });

  const optMapper = (i: AutocompleteResponseSuggestionItem) =>
    renderItem({
      title: i.shareClassDetails.code,
      isin: i.shareClassDetails.isin,
      highlightTerm: term,
      primaryShareClass: i.primaryShareClass,
    });

  const options = [
    ...allSuggestions.map(optMapper),
    { label: renderFooter(), options: [], id: 'footer' },
  ];

  const handleOnSearchResults = (_: string = term) => {
    const results = autoCompleteQuery.data || [];
    const isFound = results.find(
      isin => isin.shareClassDetails.isin === term.toUpperCase(),
    );
    const hasSearchQuery = term !== '';

    if (!navSearch && !results?.length) {
      return;
    }
    if (isFound) {
      setSearchParameters({
        term: isFound.shareClassDetails.code,
        page: 1,
        sortKey: hasSearchQuery ? '_score' : 'fundSize.value',
        sortDirection: !hasSearchQuery
          ? PageQueryParametersSortDirectionEnum.Desc
          : exploreState.searchParameters.sortDirection,
      });
      history.push(buildFundDetailsPath(term));
    } else {
      const searchParams = new URLSearchParams();
      searchParams.set(minifiedParamKeysMap.term, term);
      searchParams.set(
        minifiedParamKeysMap.sortKey,
        hasSearchQuery ? '_score' : 'fundSize.value',
      );
      const sortDirection = !hasSearchQuery
        ? PageQueryParametersSortDirectionEnum.Desc
        : exploreState.searchParameters.sortDirection;
      if (sortDirection) {
        searchParams.set(minifiedParamKeysMap.sortDirection, sortDirection);
      }

      setSearchParameters({
        term,
        page: 1,
        sortKey: hasSearchQuery ? '_score' : 'fundSize.value',
        sortDirection: !hasSearchQuery
          ? PageQueryParametersSortDirectionEnum.Desc
          : exploreState.searchParameters.sortDirection,
      });

      history.push(`/${resultRoute}?${searchParams.toString()}`);
    }

    handleSearchClose();
    handleSearch('');
  };

  const handleSearchClose = () => {
    if (searchClose) {
      searchClose();
    }
  };

  const handleSearch = (value: string) => {
    const regexPattern = /[^a-zA-Z0-9\s\-,.&"]/gi;
    const removeSpecialCharTerm = value.replace(regexPattern, '');
    setFullTerm(removeSpecialCharTerm);
    onChange?.(undefined, value);
  };

  useEffect(() => {
    // this ensures the searchbox is focused when the page loads
    // requires a timeout to ensure the element is rendered
    setTimeout(() => {
      const input = document.querySelector(
        '.ant-select-selector input',
      ) as HTMLInputElement;
      input?.focus();
    }, 100);
  }, []);

  return (
    <div
      data-test-id="searchFundInput"
      className="flex flex-col w-full"
      onBlur={handleSearchClose}
    >
      <div
        className={cx(style['search-wrapper'], {
          [style['search-wrapper--nav']]: navSearch,
        })}
        data-test-id={dataTestId}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {isDropDownItems ? (
          <input
            className={cx(style['search-dropdown'], searchClassName)}
            type="search"
            onChange={onChange}
            placeholder={placeholder}
            id="search-dropdown"
            value={term}
            disabled={disabled}
          />
        ) : (
          <AutoComplete
            autoFocus
            disabled={disabled}
            placeholder="Search funds by ISIN or keyword"
            popupClassName={cx(
              style['search-dropdown'],
              isExplorePage
                ? style['search-dropdown--explore']
                : style['search-dropdown--nav'],
            )}
            dropdownMatchSelectWidth={500}
            options={options.length > 1 ? options : []}
            value={term}
            onKeyDown={async e => {
              if (e.key === 'Enter') {
                if (onKeyEnter) {
                  await onKeyEnter(term, allSuggestions, setErrorMessage);
                } else {
                  handleOnSearchResults(term);
                }
                return;
              }
              setErrorMessage(null);
            }}
            onChange={(newTerm, selectedValue) => {
              if (Array.isArray(selectedValue)) {
                console.error(
                  'Explore search filter does not allow for multiple selections',
                );
                return;
              }
              if ('value' in selectedValue && 'fundName' in selectedValue) {
                handleSearch(selectedValue.fundName); // If an item is selected the newTerm will be its label anyway
              } else {
                handleSearch(newTerm); // If an item is selected the newTerm will be its label anyway
              }
            }}
            onSelect={async (selectedValue: string) => {
              if (onSelect) {
                await onSelect(selectedValue, allSuggestions, setErrorMessage);
                return;
              }
              if (selectedValue) {
                history.push(buildFundDetailsPath(selectedValue));
              } else {
                const item = allSuggestions.find(
                  i => i.shareClassDetails.code === term,
                );
                handleOnSearchResults(item?.shareClassDetails.code ?? term);
              }
            }}
            // onFocus={() => {
            //   setFullTerm(searchTerm);
            // }}
            notFoundContent={(() => {
              if (autoCompleteQuery.isLoading) {
                return (
                  <div className="grid place-items-center">
                    <LoadingOutlined />
                  </div>
                );
              }
              if (term === '' || (term === ' ' && !options?.length)) {
                return undefined;
              }
              return (
                <div className="grid gap-1 place-items-center">
                  <span className="text-sm font-bold text-neutral-900">
                    No results found
                  </span>
                  <p className="text-xs text-center text-neutral-700">
                    You may want to try different keywords or check for any
                    possible typos.
                  </p>
                </div>
              );
            })()}
          />
        )}
        <IconSearch
          className={cx('icon', style['search-icon'], searchIconClassName)}
        />
        {(onClear || showArrow) && (
          <div className={cx(style['search-nav'], style['search-nav--navbar'])}>
            {onClear && term && (
              <button
                onClick={e => {
                  e.stopPropagation();
                  setFullTerm('');
                  onClear?.();
                }}
              >
                <IconClose className={cx(style['search-nav--clear'], 'icon')} />
              </button>
            )}
            {showArrow && (
              <NextArrow
                className={cx(
                  'icon',
                  style['dropdown-icon-caret'],
                  style['search-nav--arrow'],
                )}
              />
            )}
          </div>
        )}
        {navSearch && (
          <div className={cx(style['search-nav'], style['search-nav--navbar'])}>
            <button type="button" tabIndex={0} onClick={handleSearchClose}>
              <IconClose className={cx('icon', style['search-nav--close'])} />
            </button>
            <span className={style['search-nav--break']}>|</span>
            <button
              type="button"
              tabIndex={0}
              onMouseDown={() => {
                setFullTerm(term);
                handleOnSearchResults();
              }}
            >
              <IconEnter className={cx('icon', style['search-nav--accept'])} />
            </button>
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="pt-1 pl-1 text-xs font-medium text-danger">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default ExploreSearch;
