import { buildFundDetailsPath, getFundType } from '@aminsights/shared';
import { List } from 'antd';
import cx from 'classnames';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { Avatar, Tooltip } from '@/components';
import { useAppContext } from '@/context/AppContext';
import FundType from '@/enums/FundType';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import ShareClassModal from '@/partials/Modal/ShareClassModal';
import { generateAvatarColor } from '@/utils/generate-color';

import style from './style.module.less';

interface ColumnFixedData {
  id: string;
  name: string;
  shareClassStyle?: string;
  type?: string;
  assetClassCode?: string;
  legalStructure?: string;
  fundId?: string;
  hideShareClasses?: boolean;
}

interface TableFixedProps {
  data: ColumnFixedData;
  showBucketNames?: boolean;
  infoTooltip?: string;
}

const FundInvestmentTrustColumn: React.FCWithChild<TableFixedProps> = ({
  data,
  showBucketNames = true,
  infoTooltip,
}) => {
  const [isShareClassModalVisible, setIsShareClassModalVisible] =
    useState(false);
  const currentWatchlist = useCurrentWatchlist();

  const linkRef = useRef<HTMLAnchorElement>(null);
  const [addTooltip, setAddTooltip] = useState(false);
  const { app } = useAppContext();

  const bucketNames = useMemo(() => {
    if (currentWatchlist.data?.buckets) {
      return currentWatchlist.data.buckets
        .filter(b => b.funds.find(f => f.isin === data.id))
        .map(bucket => bucket.name);
    }
  }, [currentWatchlist.data?.buckets]);

  const fundType = useMemo(
    () => getFundType(data.legalStructure ?? ''),
    [data.legalStructure],
  );

  const handleOpenShareClassModal: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    setIsShareClassModalVisible(true);
    e.stopPropagation();
  };

  //this sets the tooltip to true only if the fund name has been shortened
  useLayoutEffect(() => {
    const linkElement = linkRef.current;
    if (linkElement) {
      setAddTooltip(linkElement.scrollWidth > linkElement.clientWidth);
    }
  }, [app.windowWidth]);

  const moreBucketNames = bucketNames?.slice(2) || [];

  return (
    <div className={style['first-col-wrapper']}>
      {/* eslint-disable-next-line */}
      <div onClick={e => e.stopPropagation()}>
        <ShareClassModal
          isin={data.id}
          fundId={data.fundId}
          visible={isShareClassModalVisible}
          onClose={() => setIsShareClassModalVisible(false)}
        />
      </div>
      <div
        className={cx({
          [style['category-name']]: data.assetClassCode,
          [style['category-name-none']]: !data.assetClassCode,
        })}
      >
        {data.assetClassCode && (
          <Avatar color={generateAvatarColor(data.assetClassCode)} size="small">
            {data.assetClassCode}
          </Avatar>
        )}
      </div>
      <div className={cx(style['fund-details'], 'flex-grow')}>
        <div className={style['main-title']}>
          {/* This anchor tag should be react-router-dom link */}
          {data?.type ? (
            <Tooltip
              placement="bottomRight"
              title={addTooltip ? data.name : ''}
              className="w-auto"
            >
              <Link
                ref={linkRef}
                className={style['main-title__link']}
                to={buildFundDetailsPath(data.id)}
                onClick={e => e.stopPropagation()}
              >
                {data.name}
              </Link>
            </Tooltip>
          ) : (
            <span
              className="text-sm font-medium cursor-pointer text-darkest"
              aria-hidden="true"
            >
              {data.name}
            </span>
          )}
          {fundType !== FundType.INVESTMENT_TRUST && !data.hideShareClasses && (
            <button
              type="button"
              className={cx('text-left', {
                [style['shared-class-plain']]: data.shareClassStyle,
                [style['shared-class']]: !data.shareClassStyle,
              })}
              onClick={handleOpenShareClassModal}
            >
              Share Classes
            </button>
          )}
        </div>

        <div className="flex flex-row flex-wrap items-center justify-start w-full md:flex-row">
          {bucketNames &&
            showBucketNames &&
            bucketNames.slice(0, 3).map(bn => (
              <div key={bn} className={style['sub-label']}>
                <span className={cx(style['tag'])}>{bn}</span>
              </div>
            ))}
          {bucketNames && bucketNames?.length > 3 && (
            <Tooltip
              color="#313341"
              title={
                <List
                  dataSource={moreBucketNames}
                  renderItem={item => {
                    return (
                      <div className="text-xs leading-5 text-white">{item}</div>
                    );
                  }}
                />
              }
            >
              <span className={cx(style['tag-count'])}>
                +{(bucketNames?.length ?? 0) - 3}
              </span>
            </Tooltip>
          )}
          {infoTooltip && (
            <Tooltip
              title={infoTooltip}
              placement="bottom"
              color="#3E414B"
              className="self-center ml-auto"
              align={{ offset: [0, -5] }}
            >
              <InfoIcon width={16} height={16} className="text-neutral-100" />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default FundInvestmentTrustColumn;
