import { Fund } from '@aminsights/contract';
import { MaturityRangeTypeCodes } from '@aminsights/shared';

export interface IMaturity {
  maturity: string;
  value: number;
}

const mapMaturity = (fund: Fund): IMaturity[] => {
  const result = [];
  result.push(
    ...(fund.bondMaturityRange
      ?.filter(v => v.salePosition === 'L')
      .map(maturity => {
        const definition = MaturityRangeTypeCodes.find(
          v => v.typeCode === maturity.type,
        )?.definition;
        return {
          maturity: definition || '',
          value: maturity.value,
        };
      }) || []),
  );

  result.sort((a, b) => (a.value < b.value ? 1 : -1));
  return result;
};

export default mapMaturity;
