import { ChartColorsWithBrightEffect } from '@aminsights/shared';

export type TChartColor = { transparent: string; bright: string };

export const TransparentBrightColorsMap = ChartColorsWithBrightEffect.reduce(
  (prev, curr) => ({
    ...prev,
    [curr.transparent]: curr.bright,
  }),
  {},
) as Record<string, string>;

export const CHART_COLOR_FOR_INDEX_LINE = '#0072E6';
export const CHART_COLOR_ZERO_LINE = '#BEC3D2';

export const STROKE_GREY = '#EAEAEA';
export const STROKE_WHITE = '#FFF';

export const calculateStrokeColor = (period: string) => {
  return period === '7D' || period === '30D' ? STROKE_GREY : STROKE_WHITE;
};
