// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bN7WnJDKyJFZMSajs0g1 .ant-modal-close .ant-modal-close-x {\n  width: initial;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/onboarding/components/WelcomeModal/style.module.less"],"names":[],"mappings":"AAAA;EAGM,cAAA;AADN","sourcesContent":[".modal {\n  :global {\n    .ant-modal-close .ant-modal-close-x {\n      width: initial;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "bN7WnJDKyJFZMSajs0g1"
};
export default ___CSS_LOADER_EXPORT___;
