// Do not touch the order of items in this array
// Compare tool relies on this order ->
// If you need to extand or modify it, consult with the team
export const MaturityRangeTypeCodes = [
  {
    typeCode: '9',
    definition: '1 to 7 days',
  },
  {
    typeCode: '10',
    definition: '8 to 30 days',
  },
  {
    typeCode: '11',
    definition: '31 to 90 days',
  },
  {
    typeCode: '12',
    definition: '91 to 182 days',
  },
  {
    typeCode: '13',
    definition: '183 to 364 days',
  },
  {
    typeCode: '1',
    definition: '1 to 3 years',
  },
  {
    typeCode: '2',
    definition: '3 to 5 years',
  },
  {
    typeCode: '3',
    definition: '5 to 7 years',
  },
  {
    typeCode: '4',
    definition: '7 to 10 years',
  },
  {
    typeCode: '5',
    definition: '10 to 15 years',
  },
  {
    typeCode: '6',
    definition: '15 to 20 years',
  },
  {
    typeCode: '7',
    definition: '20 to 30 years',
  },
  {
    typeCode: '8',
    definition: 'Over 30 years',
  },
];
