import { ResourceUserRoleEnum } from '@aminsights/contract';
import { Select } from 'antd';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as ArrowDown } from '@/assets/svg/icons/icon-full-arrow-rounded.svg';

import styles from './style.module.less';

interface IOption {
  label: string;
  value: ResourceUserRoleEnum;
}

export const roleOptions: IOption[] = [
  {
    label: 'Viewer',
    value: ResourceUserRoleEnum.Viewer,
  },
  {
    label: 'Editor',
    value: ResourceUserRoleEnum.Editor,
  },
];

interface Props<T = unknown> {
  value: ResourceUserRoleEnum;
  onChange: (value: T | ResourceUserRoleEnum) => void;
  options?: IOption[];
  customOptions?: boolean;
  className?: string;
  popupClassName?: string;
  bordered?: boolean;
  children?: React.ReactNode;
}

const RoleSelect = <T,>({
  onChange,
  value,
  options,
  children,
  customOptions,
  className,
  popupClassName,
  bordered,
}: Props<T>) => {
  return (
    <Select
      data-test-id="sharePortfolioRoleDropdown"
      className={cx(
        styles['role-select'],
        'min-w-[90px] w-full min-h-10 [&>.ant-select-selector]:rounded [&>.ant-select-selector]:border-neutral-50',
        className,
      )}
      popupMatchSelectWidth={false}
      options={customOptions ? undefined : options || roleOptions}
      value={value}
      onChange={onChange}
      popupClassName={cx([styles['role-select-options'], popupClassName])}
      bordered={bordered}
      suffixIcon={<ArrowDown height={8} width={10} fill="#72737E" />}
    >
      {children}
    </Select>
  );
};

export default RoleSelect;
