import { Fund } from '@aminsights/contract';
import { CreditQualityBreakdownTypeCodes } from '@aminsights/shared';

export interface ICreditQuality {
  creditQuality: string;
  value: number;
}

const mapCreditQuality = (fund: Fund): ICreditQuality[] => {
  const result = [];
  result.push(
    ...(fund.bondCreditQualityBreakdown?.map(creditQuality => {
      const definition = CreditQualityBreakdownTypeCodes.find(
        v => v.typeCode === creditQuality.type,
      )?.definition;
      return {
        creditQuality: definition || '',
        value: creditQuality.value,
      };
    }) || []),
  );

  result.sort((a, b) => (a.value < b.value ? 1 : -1));
  return result;
};

export default mapCreditQuality;
