import { BenchmarkSector } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { FC } from 'react';

import { DataTable } from '@/components';
import LegendColor from '@/components/SectorEquityLegend/LegendColor';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { BREAKPOINTS, COMPARE_TOOL_DECIMALS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { CompareToolWithIndexProps } from '@/types/compare';

import { SectorCompareData } from '../utils/CompareDataCalculations';
import { getFundTitle } from '../utils/shared';
import useGetColWidth from '../utils/useGetColWidth';
import { extractFundTitle } from './extractFundTitle';
import styles from './style.module.less';

type CompareSectorsProps = CompareToolWithIndexProps & {
  data?: SectorCompareData;
  benchmarkSectors?: BenchmarkSector[];
  isDataSourcePadi?: boolean;
  isEquity?: boolean;
};

const CompareSectors: FC<CompareSectorsProps> = ({
  funds,
  data,
  benchmarkSectors,
  showIndexColumn = false,
  loading = true,
  isDataSourcePadi,
  isEquity,
}) => {
  // Manage mobile state here
  const { app } = useAppContext();
  const { getColWidth } = useGetColWidth();
  const isDesktop = app.windowWidth >= BREAKPOINTS.LG;

  return (
    <DataTable
      loading={loading}
      enableHeaderWrap
      uniqueKey="name"
      className="md:table-fixed"
      columns={[
        {
          title: 'Sector',
          render: ({ name }) => {
            const sectorName = data?.flatSectors.find(
              i => i.name === name,
            )?.name;
            const sectorType = data?.flatSectors.find(
              i => i.name === name,
            )?.type;
            return (
              <div className="flex items-center justify-center gap-x-2">
                {!isDataSourcePadi && sectorType && isEquity && (
                  <div className="pr-2">
                    <LegendColor sectorType={sectorType} />
                  </div>
                )}
                <div className="font-semibold">{sectorName}</div>
              </div>
            );
          },
          renderType: 'text',
          idSubText: 'sector',
          width: isDesktop ? 200 : undefined,
          isColumnFixed: true,
        },
        ...(funds || []).map((f, idx) => ({
          title: (
            <div className={styles['fund-title']}>
              {getFundTitle({
                isin: f.shareClassDetails.isin,
                fallBackTitle: `Fund ${idx + 1}`,
                loading,
                fundName: extractFundTitle(f),
              })}
            </div>
          ),
          render: ({ name }: SectorCompareData['data'][number]) => {
            const sector = data?.sectors[idx].find(i => i.name === name);
            const value = sector?.value.toFixed(COMPARE_TOOL_DECIMALS);
            return <div>{value ? `${value}%` : EMPTY_DATA_POINT}</div>;
          },
          renderType: 'number' as const,
          idSubText: `fund-${idx}`,
        })),
        ...(showIndexColumn
          ? [
              {
                title: 'Index',
                render: ({ type }) => {
                  const totalWeight =
                    benchmarkSectors?.find(b => b.sector == type)?.weighting ??
                    0;
                  const value = totalWeight?.toFixed(COMPARE_TOOL_DECIMALS);
                  return (
                    <div>
                      {totalWeight !== 0 ? `${value}%` : EMPTY_DATA_POINT}
                    </div>
                  );
                },
                width: getColWidth(100, 80, 65),
                renderType: 'number',
                idSubText: 'index',
              } as IDataTableColumns,
            ]
          : []),
      ]}
      // We'll use the data as keys since this table different data types per row
      data={data?.data}
    />
  );
};

export default CompareSectors;
