//comming from CreditQualityBreakdownTypeCodes
export const CREDIT_QUALITY_TO_SHOW: string[] = [
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];
