import { isInvestmentTrust } from '@aminsights/shared';
import { uniq } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';

import { useMultipleFundsByIsins } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

const useChartTrustFundsFromSearch = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location?.search);
  const benchmark = queryParams.get('benchmark') || '';
  const isins = queryParams.getAll('isin');
  const portfolios = queryParams.getAll('portfolio');
  const { data: funds } = useMultipleFundsByIsins(isins);
  const orderedFunds =
    funds
      ?.sort((a, b) => a.fundName.localeCompare(b.fundName))
      .map(f => ({
        ...f,
        isin: f.shareClassDetails.isin,
        shareClassDetailsCode: f.shareClassDetails.code,
      })) || [];
  const hasInvestmentTrust = orderedFunds.some(f =>
    isInvestmentTrust(f.legalStructure),
  );

  const setState = (key: string, value: string | string[]) => {
    if (typeof value === 'string') {
      queryParams.set(key, value);
    } else {
      value.forEach((v, idx) => {
        if (idx === 0) {
          queryParams.set(key, v);
          return;
        }
        queryParams.append(key, v);
      });
      if (!value.length) {
        queryParams.delete(key);
      }
    }
    history.push({ search: queryParams.toString() });
  };

  const setIsins = (newIsinsParam: string[], remove?: boolean) => {
    const newIsins = remove
      ? isins.filter(i => !newIsinsParam.includes(i))
      : [...isins, ...newIsinsParam];
    setState('isin', uniq(newIsins));
  };
  const setBenchmark = (benchmark: string) => {
    setState('benchmark', benchmark);
  };
  const setPortfolios = (newPortfolioIdsParam: string[], remove?: boolean) => {
    const newPortfolioIds = remove
      ? portfolios.filter(i => !newPortfolioIdsParam.includes(i))
      : [...portfolios, ...newPortfolioIdsParam];
    setState('portfolio', uniq(newPortfolioIds));
  };

  return {
    funds,
    orderedFunds,
    isins,
    benchmark,
    setIsins,
    setBenchmark,
    hasInvestmentTrust,
    setPortfolios,
    portfolios,
  };
};

export default useChartTrustFundsFromSearch;
