// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X125POyX8hvDbDb3EFgQ .ant-tooltip-inner {\n  white-space: pre-wrap;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Tooltip/style.module.less"],"names":[],"mappings":"AAEA;EAGM,qBAAA;AAHN","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.tooltip {\n  :global {\n    .ant-tooltip-inner {\n      white-space: pre-wrap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "X125POyX8hvDbDb3EFgQ"
};
export default ___CSS_LOADER_EXPORT___;
