// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yt42ztSHOy57JxmadUk6 {\n    display: flex;\n    align-items: center\n}\n.Yt42ztSHOy57JxmadUk6 .SLa_v2kprBE4_2VSa9pE {\n    margin-left: 0.5rem;\n    margin-right: 0.5rem\n}\n", "",{"version":3,"sources":["webpack://./src/components/SearchSelectedFilterIndicator/style.module.less"],"names":[],"mappings":"AACE;IAAA,aAAA;IAAA;AAAA;AAEE;IAAA,mBAAA;IAAA;AAAA","sourcesContent":[".custom-filter-label {\n  @apply flex items-center;\n  .dot {\n    @apply mx-2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-filter-label": "Yt42ztSHOy57JxmadUk6",
	"dot": "SLa_v2kprBE4_2VSa9pE"
};
export default ___CSS_LOADER_EXPORT___;
