import { Dayjs } from 'dayjs';

import { MS_DATE_FORMAT } from '../../constants';
import { RipsDataLike } from './ripsDataHelpers';

export function generateCSVFromRipsData(
  data: Map<string, RipsDataLike[]>,
  dateRange: {
    from: Dayjs;
    to: Dayjs;
  },
  trimEmptyRows: boolean = false,
): string {
  const isins = data.keys();
  const performanceIds = new Set<string>();

  const secIds = new Set<string>();
  const dates = new Set<string>();

  // Collect unique secIds and dates
  for (const [, records] of data) {
    for (const record of records) {
      secIds.add(record.secId);
      performanceIds.add(record.performanceId);
      dates.add(record.date);
    }
  }

  // Generate an array of dates for the 3-year range
  const allDates: string[] = [];
  let currentDate = dateRange.from;
  while (
    currentDate.isBefore(dateRange.to) ||
    currentDate.isSame(dateRange.to)
  ) {
    allDates.push(currentDate.format(MS_DATE_FORMAT));
    currentDate = currentDate.add(1, 'day');
  }

  const isinHeaderOutput = ['ISINs', ...isins].join(',') + '\n';
  const performanceIdHeaderOutput =
    ['PerformanceIds', ...Array.from(performanceIds)].join(',') + '\n';
  const header = ['Date', ...secIds].join(',') + '\n';

  // Create the CSV rows
  const rows = allDates.map(date => {
    const rowData: Array<string | number> = [date];
    for (const secId of secIds) {
      let value: string | number = '';
      for (const [, records] of data) {
        const record = records.find(r => r.secId === secId && r.date === date);
        if (record) {
          value = record.unitGbp;
          break;
        }
      }
      rowData.push(value);
    }
    return rowData.join(',');
  });

  // Filter out empty rows if trimEmptyRows is true
  const trimmedRows = rows.filter(row => {
    // Split the row by comma
    const values = row.split(',');

    // Check if there's more than just the date (i.e., the second part is not an empty string)
    return values.length > 1 && values[1] !== '';
  });
  const filteredRows = trimEmptyRows ? trimmedRows : rows;

  // Combine the header and filtered rows to create the CSV content
  const csvContent =
    isinHeaderOutput +
    performanceIdHeaderOutput +
    header +
    filteredRows.join('\n');

  return csvContent;
}

export function convertMapToObject<K extends string | number | symbol, V>(
  map: Map<K, V>,
): Record<K, V> {
  const obj: Record<K, V> = {} as Record<K, V>;
  for (const [key, value] of map) {
    obj[key] = value;
  }
  return obj;
}
