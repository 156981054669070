import { BREAKPOINTS } from '@/constants';
import { useAppContext } from '@/context/AppContext';

const MOBILE_COLUMN_WIDTH = 55;
const DESKTOP_COLUMN_WIDTH = 150;
const TABLET_COLUMN_WIDTH = 170;

const useGetColWidth = () => {
  const { app } = useAppContext();
  const isDesktop = app.windowWidth >= BREAKPOINTS.LG;
  const isMobile = app.windowWidth <= BREAKPOINTS.SM;
  const isTablet =
    app.windowWidth < BREAKPOINTS.LG && app.windowWidth >= BREAKPOINTS.MD;
  const getColWidth = (
    desktopWidth?: number | null,
    tabWidth?: number | null,
    mobileWidth?: number | null,
  ) => {
    const isNull = (value?: number | null, defaultValue?: number) =>
      value === null ? undefined : value || defaultValue;
    if (isDesktop) return isNull(desktopWidth, DESKTOP_COLUMN_WIDTH);
    if (isTablet) return isNull(tabWidth, TABLET_COLUMN_WIDTH);
    return isNull(mobileWidth, MOBILE_COLUMN_WIDTH);
  };

  return { isMobile, getColWidth };
};

export default useGetColWidth;
