import cx from 'classnames';
import React from 'react';

import Tooltip from '@/components/Tooltip';

import Loader from '../Loader';
import style from './style.module.less';

interface SectionItemProps {
  title: string;
  content: string | React.ReactNode;
  loading?: boolean;
  tooltipTitle?: string;
  dataTestId: string;
}

const SectionItem: React.FCWithChild<SectionItemProps> = ({
  title,
  content,
  loading,
  tooltipTitle,
  dataTestId,
}) => {
  return (
    <div
      className={cx(style['secton-item'])}
      data-test-id={`fundDetailsHeader${dataTestId}`}
    >
      <Loader
        width="50px"
        loading={loading}
        component={<h5 className={style['section-title']}>{title}</h5>}
      />
      <Loader
        className="mt-2"
        loading={loading}
        component={
          <h1 className={style['section-value']}>
            <Tooltip
              title={tooltipTitle}
              placement="bottomLeft"
              color="#3E414B"
              overlayClassName={style.tooltip}
            >
              {content}
            </Tooltip>
          </h1>
        }
      />
    </div>
  );
};

export default SectionItem;
