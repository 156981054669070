import { Bucket, ResourceUserRoleEnum } from '@aminsights/contract';
import { LIMIT_FUNDS_PER_BUCKET } from '@aminsights/shared';
import { Modal } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';

import { ReactComponent as GraphicClose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as IconLeftArrow } from '@/assets/svg/icons/icon-line-arrow.svg';
import Button from '@/components/Button';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';

import BucketList from './BucketList';
import {
  useWatchlistModal,
  WatchlistModalContext,
  withWatchlistModal,
} from './context';
import style from './style.module.less';

type ModalProps = {
  isins: string[];
  fundName?: string;
  size?: number;
  isVisible: boolean;
  addToWatchlist?: (isWatched: boolean) => void;
  className?: string;
  toggleModal: () => void;
  closeIcon?: React.ReactNode;
  showBucketListOnly?: boolean;
  isSuccess?: (isWatched: boolean) => void;
};

interface ModalContentProps {
  onBucketSelect: (id: string, count: number) => void;
  selectedBucket: string;
  showBucketListOnly?: boolean;
  isins: string[];
  fundName?: string;
  canGoBack?: boolean;
  onBack?: () => void;
}

export const WatchlistModalContent = ({
  showBucketListOnly,
  isins,
  fundName,
  onBucketSelect,
  selectedBucket,
  canGoBack,
  onBack,
}: ModalContentProps) => {
  const { data } = useCurrentWatchlist();
  const bucketsWithCurrentFund = data?.buckets.filter(b =>
    isins.every(i => b.funds.map(f => f.isin).includes(i)),
  );
  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;

  const bucketsWithCurrentFundIds =
    bucketsWithCurrentFund?.map(({ id }) => id) ?? [];

  const isAlreadyInBucket = (bucket: Bucket) => {
    return bucketsWithCurrentFundIds.includes(bucket.id);
  };

  return (
    <>
      <div className={style['modal-title']}>
        {canGoBack && (
          <IconLeftArrow onClick={onBack} className="cursor-pointer" />
        )}
        {showBucketListOnly ? 'Buckets' : 'Add to Watchlist'}
      </div>
      <div className={style['modal-text']}>
        {!showBucketListOnly && isins.length < 0 ? (
          <div className={style['modal-text']}>
            Select the bucket to assign{' '}
            <span className="font-semibold">'{fundName}'</span>
          </div>
        ) : (
          isins.length > 0 && (
            <div className={style['modal-text']}>
              Select the bucket to assign{' '}
              <span className="font-semibold">{isins.length}</span> funds/trust
            </div>
          )
        )}
      </div>
      <div className={cx(style['modal-text-gray'], 'pt-1')}>
        {showBucketListOnly
          ? `Your existing buckets are listed below. If you want to add more, you may do so after selecting the funds and clicking 'Assign to Bucket'.`
          : 'To add a fund/trusts to your watchlist, you must first assign them to a bucket. You can assign a maximum of 11 funds/trusts per bucket.'}
      </div>
      <div className={style['bucket-wrapper']}>
        {data?.buckets.map(item => {
          const fundCount = item.funds ? item.funds?.length : 0;
          const isBucketFull =
            fundCount >= LIMIT_FUNDS_PER_BUCKET ||
            fundCount + isins.length > LIMIT_FUNDS_PER_BUCKET;

          const hasUserEditAccess = item.users?.some(
            u =>
              u.id === currentUser?.sub &&
              u.role === ResourceUserRoleEnum.Editor,
          );

          const tooltipTitle = isAlreadyInBucket(item)
            ? 'Fund is already in this bucket'
            : isBucketFull
            ? 'Max. 11 funds per bucket'
            : !hasUserEditAccess
            ? 'You have view only access to this bucket'
            : '';

          return (
            <BucketList
              key={`$bucket-list-${item.id}`}
              id={item.id}
              bucketName={item.name || ''}
              tooltipTitle={tooltipTitle}
              fundCount={item.funds ? item.funds?.length : 0}
              isBucketSelected={selectedBucket === item.id}
              isBucketDisabled={
                isAlreadyInBucket(item) || isBucketFull || !hasUserEditAccess
              }
              onBucketSelected={() => {
                onBucketSelect(
                  item.id || '',
                  item.funds ? item.funds?.length : 0,
                );
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export const generateWatchlistModalFooter = ({
  assignDisabled,
  onAssign,
  onAddNewBucket,
}: {
  onAddNewBucket: () => void;
  onAssign: () => void;
  assignDisabled?: boolean;
}) => [
  <Button
    size="large"
    type="link"
    key="secondary"
    className={style['add-new-bucket-btn']}
    onClick={onAddNewBucket}
  >
    + Add New Bucket
  </Button>,
  <Button
    size="large"
    type="primary"
    className={style['assign-btn']}
    onClick={onAssign}
    key="primary"
    disabled={assignDisabled ?? true}
  >
    Assign
  </Button>,
];

const WatchlistModal: React.FCWithChild<ModalProps> = ({
  isins,
  fundName,
  isVisible,
  className,
  closeIcon,
  toggleModal,
  showBucketListOnly,
  isSuccess,
}) => {
  const { openNewBucketModal } = useContext(WatchlistModalContext);
  const { assign, selectBucket, selectedBucket } = useWatchlistModal({
    onSuccess: isSuccess,
    isins,
    onClose: toggleModal,
  });
  return (
    <>
      <Modal
        width={720}
        open={isVisible}
        onCancel={toggleModal}
        className={cx(style.modal, className)}
        footer={
          showBucketListOnly
            ? []
            : generateWatchlistModalFooter({
                onAddNewBucket: openNewBucketModal,
                onAssign: assign,
                assignDisabled: !selectedBucket,
              })
        }
        closeIcon={closeIcon || <GraphicClose />}
      >
        <WatchlistModalContent
          isins={isins}
          onBucketSelect={selectBucket}
          selectedBucket={selectedBucket}
          fundName={fundName}
          showBucketListOnly={showBucketListOnly}
        />
      </Modal>
    </>
  );
};

export default withWatchlistModal(WatchlistModal);
