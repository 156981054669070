import { Dropdown, Menu } from 'antd';
import cx from 'classnames';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { useDatePickerContext } from '@/partials/DatePicker';
import RangeDatePicker from '@/partials/DatePicker/Kinds/RangeDatePicker';

import { InvestmentTrustReturnType, useDropDownContext } from '../Context';
import chartStyles from '../utils/chart.module.less';

interface AbsoluteChartTitleProps {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  padDropdownOnMobile?: boolean;
}

const AbsoluteChartTitle: React.FCWithChild<AbsoluteChartTitleProps> = ({
  showDropdown,
  hasInvestmentTrust,
  padDropdownOnMobile,
}) => {
  const { value: datePickerValue, handleChange: handleDatePickerChange } =
    useDatePickerContext();
  const { setValue: setInvestmentTrustFilter, valueLabel } =
    useDropDownContext();

  const chartFilterMenu = (
    <Menu
      triggerSubMenuAction="click"
      items={[
        {
          label: 'Investment Trust Return',
          key: 'InvestmentTrustReturn',
          disabled: !hasInvestmentTrust,
          children: [
            { label: 'NAV', value: InvestmentTrustReturnType.NAV },
            {
              label: 'Share Price',
              value: InvestmentTrustReturnType.SharePrice,
            },
          ].map(item => ({
            key: item.label,
            label: (
              <div
                className={cx({
                  [chartStyles['chart-filter-menu-item-active']]:
                    valueLabel === item.value,
                  [chartStyles['chart-filter-menu-item-disabled']]:
                    !hasInvestmentTrust,
                })}
              >
                {item.label}
              </div>
            ),
            onClick: () => {
              setInvestmentTrustFilter(item.value);
            },
          })),
        },
      ]}
    />
  );

  return (
    <div className={chartStyles.header}>
      <h5
        data-test-id="absoluteChartLabel"
        className="text-sm w-full font-bold text-darkest"
      >
        <Loader width="150px" component={<>Absolute</>} />
      </h5>
      <div
        className="relative-date-picker-wrapper col-span-2 sm:col-span-1"
        onClick={e => e.stopPropagation()}
      >
        <RangeDatePicker
          onChange={handleDatePickerChange}
          value={datePickerValue}
          btnClassName="justify-self-end w-full sm:w-auto"
          dataTestId="absoluteChartDropdown"
        />
      </div>
      {showDropdown && (
        <div
          className={cx(
            padDropdownOnMobile && chartStyles['pad-dropdown-on-mobile'],
            'relative-dropdown-wrapper row-start-1 col-start-2 sm:col-start-3',
          )}
          onClick={e => e.stopPropagation()}
        >
          <Dropdown
            className={cx(
              chartStyles['icon-meatball'],
              'row-start-1 col-start-2 sm:col-start-3',
            )}
            overlay={chartFilterMenu}
            placement="bottomRight"
            trigger={['click']}
            overlayClassName={chartStyles['chart-filter-dropdown']}
          >
            <MeatballMenu />
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default AbsoluteChartTitle;
