import { Fund, Performance } from '@aminsights/contract';
import {
  calculatePerformanceTableBackgroundColor,
  EMPTY_DATA_POINT,
  isFund,
  TotalReturnPeriod,
} from '@aminsights/shared';
import { CALENDAR_YEAR_PERFORMANCE_TO_SHOW } from '@aminsights/shared';
import { Skeleton } from 'antd';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { TableCheckboxHeader } from '@/pages/app/Explore/components/TableCheckbox';

import FundNameCheckboxWrapper, {
  CheckboxParams,
} from './FundNameCheckboxWrapper';

const CalendarYearPerformanceColumns = (
  checkboxParams: CheckboxParams,
  dataTableRenderedAt: DataTableRenderedAt,
  showPrimaryShareClassIndicator?: boolean,
): IDataTableColumns[] => [
  {
    headerCheckbox: (): React.ReactNode =>
      dataTableRenderedAt !== DataTableRenderedAt.Watchlist && (
        <TableCheckboxHeader
          onClick={checkboxParams.onCheckedRow}
          topISINs={checkboxParams.topISINs}
          checked={checkboxParams.isAllCheckboxChecked}
        />
      ),
    title: 'Fund/Investment Trust',
    sortKey: 'shareClassDetails.code',
    render: (item: Fund | Performance): React.ReactNode => {
      return isFund(item) ? (
        <FundNameCheckboxWrapper
          item={item}
          checkboxParams={checkboxParams}
          dataTableRenderedAt={dataTableRenderedAt}
          showPrimaryShareClassIndicator={
            showPrimaryShareClassIndicator ?? false
          }
        />
      ) : (
        // empty ant-avatar-sm is just for to mock avatar
        <div className="flex flex-row">
          <div className="ant-avatar-sm mr-4 hidden sm:block" />
          <div className="table-cell-value font-bold text-darkest">
            {item.name}
          </div>
        </div>
      );
    },
    renderType: 'text',
    isColumnFixed: true,
    loader: (
      <Skeleton
        avatar={{ size: 'small' }}
        title={false}
        paragraph={{ rows: 2 }}
        active
      />
    ),
  },
  {
    title: 'YTD',
    idSubText: 'ytd',
    renderType: 'custom',
    align: 'center',
    children: [
      {
        title: 'Return',
        sortKey: 'totalReturnYtdPct',
        render: (item: Fund | Performance): string => {
          const value = item?.totalReturnYtdPct;
          return !!value ? `${Number(value).toFixed(1)}%` : EMPTY_DATA_POINT;
        },
        renderType: 'number',
        width: 85,
      },
      {
        title: 'Pcl',
        sortKey: 'totalReturnYtdMorningstarCategoryPcl',
        render: (item: Fund | Performance): string => {
          const value = isFund(item)
            ? item?.calendarYearPerformancesPcl?.find(
                a => a.type === TotalReturnPeriod.YTD,
              )?.value
            : item?.totalReturnYtdPct;
          return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
        },
        renderType: 'number',
        width: 85,
        backgroundColor: (item: Fund) => {
          return isFund(item)
            ? calculatePerformanceTableBackgroundColor(
                item,
                'calendarYearPerformancesPcl',
                TotalReturnPeriod.YTD,
              )
            : '';
        },
      },
    ],
  },
  ...[1, 2, 3, 4, 5].map(yearsAgo => {
    const year = (new Date().getFullYear() - yearsAgo).toString();
    const keyReturn = `totalReturn${yearsAgo}calendarYearsAgoPct`;
    // totalReturn1calendarYearsAgoCategoryPcl, totalReturn2calendarYearsAgoCategoryPcl and others are used here for sorting to avoid complexity.
    const keyPcl = `totalReturn${yearsAgo}calendarYearsAgoCategoryPcl`;
    const returnPeriod = getTotalReturnPeriod(yearsAgo);
    return {
      title: year,
      idSubText: year,
      renderType: 'custom' as const,
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: keyReturn,
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? item?.[keyReturn as keyof Fund]
              : item?.[keyReturn as keyof Performance];
            return !!fundValue
              ? `${Number(fundValue).toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number' as const,
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: keyPcl,
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? item?.calendarYearPerformancesPcl?.find(
                  a => a.type === returnPeriod,
                )?.value
              : item?.[keyPcl as keyof Performance];
            return !!fundValue
              ? Number(fundValue).toFixed(0)
              : EMPTY_DATA_POINT;
          },
          renderType: 'number' as const,
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'calendarYearPerformancesPcl',
                  CALENDAR_YEAR_PERFORMANCE_TO_SHOW[yearsAgo].type,
                )
              : '';
          },
        },
      ],
    };
  }),
];

function getTotalReturnPeriod(year: number) {
  let result = '';
  switch (year) {
    case 1:
      result = TotalReturnPeriod.ONE_YEAR;
      break;
    case 2:
      result = TotalReturnPeriod.TWO_YEARS;
      break;
    case 3:
      result = TotalReturnPeriod.THREE_YEARS;
      break;
    case 4:
      result = TotalReturnPeriod.FOUR_YEARS;
      break;
    case 5:
      result = TotalReturnPeriod.FIVE_YEARS;
      break;
  }
  return result;
}

export default CalendarYearPerformanceColumns;
