// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XOmNU2ssD6CJCbz_Ag_1 {\n    padding-top: 1.25rem;\n    padding-left: 1.25rem;\n    padding-right: 1.25rem;\n    padding-bottom: 2.5rem;\n    display: flex;\n    flex-direction: row;\n    justify-content: center\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Settings/Profile/style.module.less"],"names":[],"mappings":"AAGE;IAAA,oBAAA;IAAA,qBAAA;IAAA,sBAAA;IAAA,sBAAA;IACA,aAAA;IAAA,mBAAA;IAAA;AADA","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.section-profile {\n  @apply pt-5 pl-5 pr-5 pb-10;\n  @apply flex flex-row justify-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-profile": "XOmNU2ssD6CJCbz_Ag_1"
};
export default ___CSS_LOADER_EXPORT___;
