import {
  PageQueryParametersSortDirectionEnum,
  SignificantChangesItem,
} from '@aminsights/contract';
import React from 'react';

export enum SIGNIFICANT_CHANGES_ACTIONS {
  SET_SIGNIFICANT_CHANGES_PARAMETERS = 'significant-changes:set-significant-changes-parameters',
  CLEAR_FILTER = 'significant-changes:clear-filter',
}

export type SignificantChangesActionTypes =
  | {
      type: SIGNIFICANT_CHANGES_ACTIONS.SET_SIGNIFICANT_CHANGES_PARAMETERS;
      payload: Partial<SignificantChangesParameters>;
    }
  | {
      type: SIGNIFICANT_CHANGES_ACTIONS.CLEAR_FILTER;
    };

export interface SignificantChangesParameters {
  size: number;
  page: number;
  term: string;
  sortKey: keyof SignificantChangesItem;
  sortDirection: PageQueryParametersSortDirectionEnum | undefined;
  changeType?: string[] | undefined;
  period?: string | undefined;
}

export interface SignificantChangeForModalState {
  visible: boolean;
  data?: SignificantChangesItem | null;
}

export type SignificantChangesState = {
  significantChangesParameters: SignificantChangesParameters;
  significantChangeForModal: SignificantChangeForModalState;
};

export type SignificantChangesContextProps = {
  state: SignificantChangesState;
  dispatch: React.Dispatch<SignificantChangesActionTypes>;
};
