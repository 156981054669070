import ChartEmptyState from '@/partials/Charts/ChartEmptyState';
import RiskReturnScatterPlotV2 from '@/partials/ScatterPlots/risk-return-scatter-plot/RiskReturnScatterPlotV2';

import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';
import { useChartingContext } from './ChartingContext';

const RiskReturnChartingToolPage = () => {
  const { isins, benchmark, setIsins, hasInvestmentTrust } =
    useChartTrustFundsFromSearch();

  const chartingCtx = useChartingContext();
  const legendData = chartingCtx.chartLegend;
  const scatterPlotColors = chartingCtx.chartColors;

  if (!isins.length) {
    const title = 'No added funds/trusts to chart';
    const subTitle = 'Search funds to chart';
    return (
      <ChartEmptyState
        title={title}
        subtitle={subTitle}
        isDashboardPage={true}
      />
    );
  }
  return (
    <RiskReturnScatterPlotV2
      isins={isins}
      benchmarkId={benchmark}
      onRemoveFund={isin => {
        setIsins([isin], true);
        const targetId = legendData.find(ld => ld.isin === isin)?.id || '';
        const targetColor = scatterPlotColors[targetId];
        targetColor && chartingCtx.setColorsRemoved(targetColor);
      }}
      showDropdown={true}
      hasInvestmentTrust={hasInvestmentTrust}
      scatterPlotColors={scatterPlotColors}
    />
  );
};

export default RiskReturnChartingToolPage;
