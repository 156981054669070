// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RCUTO3uYKvEjI_5P7PqZ .ant-table-tbody > tr > td {\n  min-height: 56px;\n}\n.RCUTO3uYKvEjI_5P7PqZ .ant-table-tbody > tr > td:first-child {\n  font-weight: 700;\n  --tw-text-opacity: 1;\n  color: rgb(49 51 65 / var(--tw-text-opacity));\n}\n.RCUTO3uYKvEjI_5P7PqZ .ant-table-thead th.ant-table-cell {\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  font-weight: 400;\n  --tw-text-opacity: 1;\n  color: rgb(84 85 118 / var(--tw-text-opacity));\n  background-color: #f7f7f7;\n}\n.RCUTO3uYKvEjI_5P7PqZ .ant-table-thead .ant-table-cell:first-child::before {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/FundAndInvestmentTrust/components/InfoAndStats/Fees/style.module.less"],"names":[],"mappings":"AAEA;EAEI,gBAAA;AAFJ;AAIM;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;AAIF;EAAA,mBAAA;EAAA,sBAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA,8CAAA;EACA;AADA;AAIA;EAAA;AAAA","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.info-stats-fees :global {\n  .ant-table-tbody > tr > td {\n    min-height: 56px;\n    &:first-child {\n      @apply font-bold text-darkest;\n    }\n  }\n  .ant-table-thead th.ant-table-cell {\n    @apply text-xs font-normal text-neutral py-2;\n    background-color: @bg;\n  }\n  .ant-table-thead .ant-table-cell:first-child::before {\n    @apply hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-stats-fees": "RCUTO3uYKvEjI_5P7PqZ"
};
export default ___CSS_LOADER_EXPORT___;
