import slugify from 'slugify';

import { PORTFOLIO_TAB } from '@/pages/app/Portfolio/types';

const slugOptions = {
  replacement: '-',
  lower: true,
  trim: true,
};

interface IConvertNameAndUuidToSlug {
  uuid?: string;
  name?: string;
}

export const convertNameAndUuidToSlug = (
  args: IConvertNameAndUuidToSlug,
): string => {
  const { uuid = '', name = '' } = args;
  return `${slugify(name, slugOptions)}-${uuid}`;
};

interface IConvertNameAndIDToSlug {
  id?: string;
  name?: string;
}

export const convertNameAndIDToSlug = (
  args: IConvertNameAndIDToSlug,
): string => {
  const { id = '', name = '' } = args;
  return `${slugify(name, slugOptions)}-${id}`;
};

export const evaluatePortfolioSubRoute = (tab?: string) => {
  return tab && Object.values(PORTFOLIO_TAB).some(p => p === tab)
    ? tab
    : PORTFOLIO_TAB.HOLDINGS;
};
