import cx from 'classnames';
import React, { forwardRef } from 'react';
import {
  DraggableProvided,
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';

import { IDataTableColumns } from '../DataTable';
import style from '../DataTable/style.module.less';
import TableRowContent from '../DataTable/TableRowContent';

interface RowProps<T> {
  item: T;
  columns: Array<IDataTableColumns>;
  loading?: boolean;
  uniqueKey: string;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  rowStyle?: React.CSSProperties;
  className?: string;
  id?: string;
  draggableProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isDragging?: boolean;
  provided?: DraggableProvided;
}

const DraggableTableRow = <T,>({
  item,
  columns,
  loading,
  onClick,
  uniqueKey,
  rowStyle,
  className,
  id,
  draggableProps,
  dragHandleProps,
  isDragging,
  provided,
}: RowProps<T>) => {
  if (!item && !loading) {
    return null;
  }

  return (
    <tr
      data-test-id={id}
      className={cx('relative', style['table-row'], className, {
        'bg-info-50': isDragging,
        'grid grid-cols-[1fr_1fr_1fr] border border-info': isDragging,
      })}
      style={rowStyle}
      onClick={onClick}
      ref={provided?.innerRef}
      {...draggableProps}
      {...dragHandleProps}
    >
      {columns?.map((column: IDataTableColumns) => (
        <>
          <TableRowContent
            key={
              !!column.idSubText
                ? `table-row-content-column-${uniqueKey}--${column.title}-${
                    column.idSubText ?? 'end'
                  }`
                : `table-row-content-column-${uniqueKey}--${column.title}`
            }
            loading={loading}
            item={item}
            column={column}
            hasAction={false}
          />
        </>
      ))}
    </tr>
  );
};

export default forwardRef(DraggableTableRow);
