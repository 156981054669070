import { NotificationsApi } from '@aminsights/contract';
import { DEFAULT_NOTIFICATIONS_PAGE_SIZE } from '@aminsights/shared';
import { useInfiniteQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { NOTIFICATIONS } from './query-keys';

const useGetNotifications = () => {
  const notificationsApi = new NotificationsApi(openApiConfig());
  return useInfiniteQuery(
    [NOTIFICATIONS],
    async ({ pageParam = 0 }) => {
      const response = await notificationsApi.getNotifications(pageParam);
      return response.data.notificationItems;
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      getNextPageParam: (_lastPage, allPages) => {
        const pageParam = allPages.flat().length;
        const hasMore = pageParam % DEFAULT_NOTIFICATIONS_PAGE_SIZE === 0;
        if (hasMore) {
          return pageParam;
        }
      },
    },
  );
};

export default useGetNotifications;
