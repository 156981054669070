import { Upload } from 'antd';
import React, { useState } from 'react';

import { ReactComponent as UploadIcon } from '@/assets/svg/icons/icon-upload-funds.svg';

import { FailedStatus } from '../types';
import style from './style.module.less';

const { Dragger } = Upload;

type Props = {
  onFundsUploadFailure: (failedStatus: FailedStatus) => void;
  onFundsUploadSuccess: (isins: string[], filenames: string[]) => void;
};

const UploadFunds: React.FCWithChild<Props> = ({
  onFundsUploadFailure,
  onFundsUploadSuccess,
}) => {
  const [filenames, setFileNames] = useState<string[] | undefined>();

  const handleUploadChange = (info: any) => {
    const fileList = info.fileList.slice(-1);
    setFileNames(fileList.map((file: any) => file.name));
    if (fileList.length === 0) {
      onFundsUploadSuccess([], []);
    }
  };

  const handleFileRemove = (file: any) => {
    const newFileNames = filenames?.filter(v => v !== file.name);
    setFileNames(newFileNames);
  };

  const handleBeforeUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = e => {
      if (e.target && e.target.result) {
        onFundsUploadSuccess(
          e.target.result
            .toString()
            .split('\n')
            .map((isin: string) => isin.trim())
            .filter(isin => isin),
          filenames || [],
        );
      } else {
        onFundsUploadFailure(FailedStatus.FILE_EMPTY);
      }
    };
    reader.readAsText(file);
    return false;
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    onChange: handleUploadChange,
    accept: '.csv',
    beforeUpload: handleBeforeUpload,
    onRemove: handleFileRemove,
  };

  return (
    <div className={style['upload-funds']}>
      <h2>Import funds/trusts</h2>
      <p>
        Add multiple funds/trust to your watchlist at the same time by uploading
        a csv file. You may also add a single fund/trust by searching from our
        database.
      </p>

      <Dragger {...uploadProps}>
        <div className={style['upload-funds-browse-files']}>
          <UploadIcon />
          <p>
            <span>Browse</span> or drag and drop files here
          </p>
        </div>
      </Dragger>

      <a href={'/assets/file/sample_template.csv'} download>
        View sample template
      </a>
    </div>
  );
};

export default UploadFunds;
