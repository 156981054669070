import { PortfolioFund, PortfolioWeighting } from '@aminsights/contract';
import {
  evaluatePortfolioFundsValidity,
  sortFundsByValueForLatestDate,
} from '@aminsights/shared';
import { isTotalSum100 } from '@aminsights/shared';

import { PortfolioState } from './types';

export function addFundToCurrentPortfolio(
  state: PortfolioState,
  payload: {
    isin: string;
    broadAssetClass: string;
    fundName: string;
    code: string;
  },
) {
  const newFund = {
    isin: payload.isin,
    weightings: [],
    fundName: payload.fundName,
    broadAssetClass: payload.broadAssetClass,
    shareClassDetails: {
      code: payload.code,
    },
  };

  return {
    ...state,
    portfolios: state.portfolios.map(portfolio => {
      if (portfolio._id === state.currentPortfolioId) {
        return {
          ...portfolio,
          funds: sortFundsByValueForLatestDate([
            ...portfolio.funds.filter(f => f?.isin !== ''),
            newFund,
          ]) as PortfolioFund[],
        };
      }
      return portfolio;
    }),
  };
}

export function removeFundFromCurrentPortfolio(state: PortfolioState) {
  return {
    ...state,
    markedFundsForDelete: [],
    portfolios: state.portfolios.map(portfolio => {
      if (portfolio._id === state.currentPortfolioId) {
        const fundsAfterRemove = portfolio.funds.filter(f =>
          state.markedFundsForDelete.every(mF => mF !== f?.isin),
        );
        const allWeightingsAfterRemoveFund = fundsAfterRemove.flatMap(
          f => f.weightings,
        );
        const allDatesAfterRemoveFundeFund = new Set<string>(
          allWeightingsAfterRemoveFund.map(aW => aW.date),
        );

        const funds = evaluatePortfolioFundsValidity(
          allDatesAfterRemoveFundeFund,
          allWeightingsAfterRemoveFund,
          fundsAfterRemove,
        );

        return {
          ...portfolio,
          funds: sortFundsByValueForLatestDate(funds) as PortfolioFund[],
        };
      }
      return portfolio;
    }),
  };
}

export function removeFundSearchboxToCurrentPortfolio(state: PortfolioState) {
  return {
    ...state,
    portfolios: state.portfolios.map(portfolio => {
      if (portfolio._id === state.currentPortfolioId) {
        return {
          ...portfolio,
          funds: portfolio.funds.filter(f => f?.isin !== ''),
        };
      }
      return portfolio;
    }),
  };
}

export function addFundSearchboxToCurrentPortfolio(state: PortfolioState) {
  const newEmptyFund = {
    isin: '',
    weightings: [],
    fundName: '',
    broadAssetCLass: '',
  };

  return {
    ...state,
    portfolios: state.portfolios.map(portfolio => {
      if (portfolio._id === state.currentPortfolioId) {
        return {
          ...portfolio,
          funds: [...portfolio.funds, newEmptyFund] as PortfolioFund[],
        };
      }
      return portfolio;
    }),
  };
}

export function identifyFundForDelete(state: PortfolioState, isin: string) {
  let isinsToRemove = state.markedFundsForDelete;
  if (!isinsToRemove.some(i => i === isin)) {
    isinsToRemove.push(isin);
  } else {
    isinsToRemove = isinsToRemove.filter(i => i !== isin);
  }
  return { ...state, markedFundsForDelete: isinsToRemove };
}

export function addDate(state: PortfolioState) {
  const emptyWeighting = {
    date: '',
    isValid: true,
  };

  return {
    ...state,
    portfolios: state.portfolios.map(portfolio => {
      if (portfolio._id === state.currentPortfolioId) {
        const funds = portfolio.funds.map(f => {
          return {
            ...f,
            weightings: [...(f.weightings ?? []), emptyWeighting],
          };
        });
        return {
          ...portfolio,
          funds,
        };
      }
      return portfolio;
    }),
  };
}

export function updateDate(
  state: PortfolioState,
  payload: { oldDate: string; newDate: string },
) {
  return {
    ...state,
    portfolios: state.portfolios.map(portfolio => {
      if (portfolio._id === state.currentPortfolioId) {
        const funds = portfolio.funds.map(f => {
          return {
            ...f,
            weightings: f.weightings.map(w => {
              if (w.date !== payload.oldDate) {
                return w;
              }
              return { ...w, date: payload.newDate };
            }),
          };
        });
        return {
          ...portfolio,
          funds: sortFundsByValueForLatestDate(funds) as PortfolioFund[],
        };
      }
      return portfolio;
    }),
  };
}

export function deleteDate(state: PortfolioState, payload: { date: string }) {
  return {
    ...state,
    portfolios: state.portfolios.map(portfolio => {
      if (portfolio._id === state.currentPortfolioId) {
        const funds = portfolio.funds.map(f => {
          return {
            ...f,
            weightings: f.weightings.filter(w => w.date !== payload.date),
          };
        });
        return {
          ...portfolio,
          funds: sortFundsByValueForLatestDate(funds) as PortfolioFund[],
        };
      }
      return portfolio;
    }),
  };
}

export function upsertWeighting(
  state: PortfolioState,
  payload: {
    isin: string;
    portfolioWeighting: PortfolioWeighting;
  },
) {
  return {
    ...state,
    portfolios: state.portfolios.map(portfolio => {
      if (portfolio._id === state.currentPortfolioId) {
        const funds = portfolio.funds.map(f => {
          if (f.isin !== payload.isin) {
            return { ...f, weightings: f.weightings.map(w => w) };
          }

          const weightings = [...(f.weightings ?? [])].map(w => {
            if (w.date === payload.portfolioWeighting.date) {
              return payload.portfolioWeighting;
            }
            return w;
          });

          if (
            !weightings.some(w => w.date === payload.portfolioWeighting.date)
          ) {
            weightings.push(payload.portfolioWeighting);
          }

          return {
            ...f,
            weightings,
          };
        });

        let totalSumForDay = 0;
        funds.forEach(f => {
          f.weightings.forEach(w => {
            if (w.date === payload.portfolioWeighting.date) {
              totalSumForDay += w.value ?? 0;
            }
          });
        });
        const isValidDateAfterUpdateWeighting = isTotalSum100(totalSumForDay);
        funds.forEach(f => {
          f.weightings.forEach(w => {
            if (w.date === payload.portfolioWeighting.date) {
              w.isValid = isValidDateAfterUpdateWeighting;
            }
          });
        });
        return {
          ...portfolio,
          funds: sortFundsByValueForLatestDate(funds) as PortfolioFund[],
        };
      }
      return portfolio;
    }),
  };
}
