import cx from 'classnames';
import React, { KeyboardEventHandler, useEffect, useRef } from 'react';

import { ReactComponent as IconSearch } from '@/assets/svg/icons/icon-search.svg';
import style from '@/partials/SearchBox/style.module.less';

interface ISearchProps {
  onChange: (value: string) => void;
  placeholder?: string;
  onKeyDown?: KeyboardEventHandler;
  key?: string;
  autoFocus?: boolean;
  visible?: boolean;
  input?: string;
  setSearchText?: (value: string) => void;
}

export const Search: React.FCWithChild<ISearchProps> = ({
  onChange,
  placeholder,
  onKeyDown,
  autoFocus,
  visible,
  input,
  setSearchText,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (autoFocus && inputRef.current && visible) {
        inputRef.current.focus();
      }
    }, 50);
    if (visible === false) {
      setSearchText?.('');
    }
  }, [autoFocus, visible]);
  return (
    <div className={cx(style['search-wrapper'])}>
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        placeholder={placeholder || 'Search'}
        className={cx(style['search-dropdown'])}
        type="search"
        onChange={event => onChange(event.target.value)}
        onKeyDown={onKeyDown}
        data-test-id="searchInput"
        value={input}
      />
      <IconSearch className={cx('icon', style['search-icon'])} />
    </div>
  );
};
