import {
  APP_ROUTE_CHARTS,
  APP_ROUTE_DASHBOARD,
  APP_ROUTE_EXPLORE,
  APP_ROUTE_PORTFOLIOS,
  APP_ROUTE_SETTINGS,
  APP_ROUTE_SIGNIFICANT_CHANGES,
  APP_ROUTE_WATCHLIST,
  APP_ROUTES,
} from '@aminsights/shared';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import cx from 'classnames';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as ChartingToolIcon } from '@/assets/svg/icons/charting-tool.svg';
import { ReactComponent as CogIcon } from '@/assets/svg/icons/icon-cog.svg';
import { ReactComponent as EyeIcon } from '@/assets/svg/icons/icon-eye-hide.svg';
import { ReactComponent as FlipIcon } from '@/assets/svg/icons/icon-flip.svg';
import { ReactComponent as HomeIcon } from '@/assets/svg/icons/icon-home.svg';
import { ReactComponent as PortfolioIcon } from '@/assets/svg/icons/icon-portfolio.svg';
import { ReactComponent as SearchIcon } from '@/assets/svg/icons/icon-search.svg';
import { LocalFeatureSwitch } from '@/components/LocalFeatureSwtich/LocalFeatureSwitch';
import Navbar from '@/components/Navbar';
import SidebarBadge from '@/components/SidebarBadge';
import {
  BREAKPOINTS,
  DASHBOARD_SIDER_COLLAPSED_WIDTH,
  DASHBOARD_SIDER_WIDTH,
  getConfigValue,
} from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { NOTIFICATIONS } from '@/hooks/query-hooks/notification-hooks/query-keys';
import useSearch from '@/hooks/query-hooks/search-hooks/useSearch';
import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import useProvideExplore from '@/pages/app/Explore/useProvideExplore';
import useProvideSignificantChanges from '@/pages/app/SignificantChanges/useProvideSignificantChanges';
import Disclaimer from '@/partials/Disclaimer';
import NotificationPermissionModal from '@/partials/NotificationPermissionModal';
import useProvideSCSearch from '@/partials/SearchBox/useProvideSCSearch';
import queryClient from '@/queryClient';

const { Content, Sider } = Layout;

type MenuItem = NonNullable<MenuProps['items']>[number] & {
  'data-test-id': string;
};

const DashboardLayout: React.FCWithChild = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>(APP_ROUTE_DASHBOARD);
  const location = useLocation();
  const { app } = useAppContext();
  const { clearSearchParameters } = useProvideExplore();
  const { setFullTerm } = useSearch();
  const { clearSearch: clearSCSearch } = useProvideSCSearch();
  const { clearFilter: clearSCFilters } = useProvideSignificantChanges();
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const history = useHistory();
  const { isPortfolioTreatmentEnabled } = featureSwitchState;

  useEffect(() => {
    const path = location.pathname?.split('/').filter(Boolean)?.[0];
    const currentPathname = APP_ROUTES.find(word => path?.includes(word));
    if (currentPathname) {
      setActiveKey(currentPathname);
    }
  }, [location]);

  useEffect(() => {
    // Force a refetch on notification and bucket watchlist every 5 mins
    const interval = setInterval(() => {
      queryClient.invalidateQueries([NOTIFICATIONS]);
      queryClient.invalidateQueries([WATCHLIST]);
    }, 300000); // 5 mins

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth > BREAKPOINTS.XL) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [app?.windowWidth]);

  const clearSearchState = () => {
    clearSCFilters();
    clearSCSearch();
    clearSearchParameters();
    setFullTerm('');
  };

  const onItemClick: MenuProps['onClick'] = ({ key }) => {
    if (getWidth() < BREAKPOINTS.SM) {
      setCollapsed(true);
    }
    clearSearchState();

    history.push(`/${key}`);
  };

  const onToggleMenu = () => {
    setCollapsed(!collapsed);
  };

  const getWidth = () => {
    return app?.windowWidth || window.innerWidth;
  };

  const isMobileView = useMemo(() => {
    return (app?.windowWidth || 0) < BREAKPOINTS.SM;
  }, [app?.windowWidth]);

  const getMenuItems = (): NonNullable<
    ComponentProps<typeof Menu>['items']
  > => {
    const menus: MenuItem[] = [];
    menus.push(
      {
        label: (
          <>
            Dashboard <SidebarBadge />
          </>
        ),
        key: APP_ROUTE_DASHBOARD,
        icon: <HomeIcon className="icon" />,
        'data-test-id': 'mainNavHome',
        title: isMobileView ? '' : 'Dashboard',
      },
      {
        label: (
          <>
            Watchlist <SidebarBadge />
          </>
        ),
        key: APP_ROUTE_WATCHLIST,
        icon: <EyeIcon className="icon" />,
        'data-test-id': 'mainNavWatchlist',
        title: isMobileView ? '' : 'Watchlist',
      },
    );
    isPortfolioTreatmentEnabled &&
      menus.push({
        label: <>Portfolio</>,
        key: APP_ROUTE_PORTFOLIOS,
        icon: <PortfolioIcon className="icon" />,
        disabled: !isPortfolioTreatmentEnabled,
        'data-test-id': 'mainNavPortfolio',
        title: isMobileView ? '' : 'Portfolio',
      });
    const otherMenus = [
      {
        label: (
          <>
            Significant Changes
            {/* <SidebarBadge count={7} /> */}
          </>
        ),
        key: APP_ROUTE_SIGNIFICANT_CHANGES,
        icon: <FlipIcon className="icon" />,
        'data-test-id': 'mainNavSignificantChanges',
        title: isMobileView ? '' : 'Significant Changes',
      },
      {
        label: (
          <>
            Explore <SidebarBadge />
          </>
        ),
        key: APP_ROUTE_EXPLORE,
        icon: <SearchIcon className="icon" />,
        'data-test-id': 'mainNavExplore',
        title: isMobileView ? '' : 'Explore',
      },
      {
        label: (
          <>
            Charting <SidebarBadge />
          </>
        ),
        key: APP_ROUTE_CHARTS,
        icon: <ChartingToolIcon className="icon" />,
        'data-test-id': 'mainNavCharts',
        title: isMobileView ? '' : 'Charting',
      },

      {
        label: (
          <>
            Settings <SidebarBadge />
          </>
        ),
        key: APP_ROUTE_SETTINGS,
        icon: <CogIcon className="icon" />,
        'data-test-id': 'mainNavSettings',
        title: isMobileView ? '' : 'Settings',
      },
    ];
    menus.push(...otherMenus);
    return menus;
  };
  return (
    <Layout>
      {createPortal(
        <NotificationPermissionModal />,
        document.getElementById('notification-permission-portal') as Element,
      )}
      <Navbar collapsed={collapsed} onToggleMenu={() => onToggleMenu()} />
      <Layout>
        <Sider
          theme="light"
          trigger={null}
          width={isMobileView ? '100vw' : DASHBOARD_SIDER_WIDTH + 1} // +1 to account for the border
          collapsible
          collapsed={collapsed}
          collapsedWidth={
            isMobileView ? 0 : DASHBOARD_SIDER_COLLAPSED_WIDTH + 1 // +1 to account for the border
          }
          className={cx(
            'bg-white fixed z-30 top-0 pt-14 h-full overflow-auto sm:overflow-visible sm:h-auto sm:relative sm:pt-0 [&>*]:pt-0',
            'border-r border-r-gray-200',
          )}
        >
          <Menu
            // Bare with this monstrosity, I honestly find it easier than bouncing around less files 😂 - but theming will make it easier some day
            className={cx(
              `bg-white flex flex-col`, // Base
              `sm:sticky w-full sm:h-[calc(100vh-48px)] top-14 sm:top-12 [&>li]:flex [&>li]:items-center [&>li]:justify-start [&>li>.icon]:shrink-0 [&>li]:rounded [&>li]:w-full [&>li>.icon]:duration-200`, // Positioning
              `px-4 py-2 pt-3 [&>li]:p-0 [&>li]:shrink-0 [&>li]:mb-3 [&>li]:mx-0 [&>li]:font-medium [&_svg]:size-6`, // Spacing & sizing
              `h-full max-h-[calc(100vh-48px)] overflow-auto`, //  Handle an edge case where a device is in landspace mode
              !collapsed && `[&>li>svg]:ml-4`,
              collapsed && `[&>li>svg]:mx-2`,
              `[&>li]:text-[#262626] [&>li.ant-menu-item-selected]:text-white [&>li.ant-menu-item-selected>svg]:text-white [&>li>svg]:text-neutral-100`, // Color
              '[&>li:hover:not(.ant-menu-item-selected)]:text-[#1890ff] [&>li:hover:not(.ant-menu-item-selected)>svg]:text-primary', // hover
              `[&>li:last-child]:mt-auto [&>li:last-child]:mb-6`,
            )}
            theme="dark" // We set it to dark because the active item is as the figma - and it is easier to adjust the colors
            selectedKeys={[activeKey]}
            onClick={onItemClick}
            items={getMenuItems()}
          />
        </Sider>
        <Content>
          {children}
          <Disclaimer />
        </Content>
      </Layout>
      {getConfigValue('REACT_APP_NODE_ENV') !== 'production' && (
        <LocalFeatureSwitch />
      )}
    </Layout>
  );
};

export default DashboardLayout;
