/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tabs, TabsProps } from 'antd';
import React, { memo } from 'react';

import style from './style.module.less';

export interface TabItemProps {
  key: string;
  label: React.ReactNode;
  children?: React.ReactNode;
  tabKey?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

type CustomTabProps = {
  tabBarExtraContent?: React.ReactNode;
  defaultActiveKey?: string;
  activeKey?: string;
  centered?: boolean;
  items: TabItemProps[];
  tabPosition?: 'top' | 'right' | 'bottom' | 'left' | undefined;
  className?: string;
  onChange?: (activeKey: string) => void;
  onTabClick?: (
    activeKey: string,
    e: React.KeyboardEvent | React.MouseEvent,
  ) => void;
  renderTabBar?: TabsProps['renderTabBar'];
};

const CustomTabs: React.FCWithChild<CustomTabProps> = ({
  tabBarExtraContent,
  defaultActiveKey,
  activeKey,
  centered,
  tabPosition,
  className,
  onChange,
  onTabClick,
  items,
  renderTabBar,
}) => {
  return (
    <div className={style['tabs-wrapper']}>
      <Tabs
        className={className}
        tabBarExtraContent={tabBarExtraContent}
        defaultActiveKey={defaultActiveKey}
        activeKey={activeKey}
        centered={centered}
        tabPosition={tabPosition}
        onChange={onChange}
        onTabClick={onTabClick}
        items={items}
        renderTabBar={renderTabBar}
      />
    </div>
  );
};

export default memo(CustomTabs);
