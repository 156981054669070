import { InternalApi, InternalResponse } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { INTERNAL } from '../watchlist-hooks/query-keys';

const internalApi = new InternalApi(openApiConfig());

type UseIntervalResponse = InternalResponse;
type UseIntervalError = unknown;

const useInternal = (fundId: string | null | undefined) => {
  return useQuery<UseIntervalResponse, UseIntervalError>(
    [INTERNAL, fundId],
    async () => {
      if (fundId) {
        const result = await internalApi.getInternal(fundId);

        return result.data;
      } else {
        throw new Error('No fundId provided');
      }
    },
  );
};

export default useInternal;
