// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DmLe4YF3PdX49iM0ECYQ {\n  padding: 15px;\n}\n.DmLe4YF3PdX49iM0ECYQ .DjFlKwgt9BHOcroKoR0i {\n  margin: 30px 0 30px;\n  border-bottom: 1px dashed #ccc;\n}\n.anYH7jDzvYtjOHdKKPnZ {\n  height: 2.5rem !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Watchlist/Watchlist/components/style.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAFA;EAII,mBAAA;EACA,8BAAA;AACJ;AAIE;EAAA;AAAA","sourcesContent":[".chartsTabContainer {\n  padding: 15px;\n\n  .divider {\n    margin: 30px 0 30px;\n    border-bottom: 1px dashed #ccc;\n  }\n}\n\n.bucket-dropdown {\n  @apply h-10 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartsTabContainer": "DmLe4YF3PdX49iM0ECYQ",
	"divider": "DjFlKwgt9BHOcroKoR0i",
	"bucket-dropdown": "anYH7jDzvYtjOHdKKPnZ"
};
export default ___CSS_LOADER_EXPORT___;
