import { Bucket, Portfolio } from '@aminsights/contract';
import { Input, Modal } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as GraphicClose } from '@/assets/svg/icons/icon-close.svg';
import Button from '@/components/Button';
import { APP_ACTIONS, MAX_ENTITY_NAME } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import BenchmarksDropdown from '@/partials/BenchmarksDropdown';
import { toCamelCase } from '@/utils/toCamelCase';

import LimitReachModal, { StaticDataForLimitModal } from '../LimitReachModal';
import { DefaultSelectedIndex } from '../types';
import style from './style.module.less';
export interface StaticDataForAddNewEntityModal
  extends StaticDataForLimitModal {
  limitMessage?: string;
}
interface ModalProps {
  staticModalData: StaticDataForAddNewEntityModal;
  entities: (Portfolio | Bucket)[];
  onSaveClick: (name: string, secId?: string) => Promise<any>;
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  closeIcon?: React.ReactNode;
  scrollOnBucketAdd?: () => void;
}
const AddEntityWithBenchmarkModal: React.FCWithChild<ModalProps> = ({
  entities,
  staticModalData,
  onSaveClick,
  isVisible,
  className,
  closeIcon,
  toggleModal,
  scrollOnBucketAdd,
}) => {
  const { dispatch: dispatchApp } = useAppContext();
  const [entityName, setEntityName] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(DefaultSelectedIndex);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
  const [isLimitReachModalOpen, setIsLimitReachModalOpen] = useState(false);
  const [isEntityNameExists, setIsEntityNameExists] = useState(false);
  const [isEntityNameLong, setIsEntityNamaLong] = useState(false);
  const limitEntities = staticModalData.limitForEntities;
  const {
    data: benchmarkOptionsResponse,
    isLoading: isBenchmarkOptionsLoading,
  } = useBenchmarkOptions();

  const history = useHistory();
  useEffect(() => {
    if (history.location.search.includes('current')) {
      scrollOnBucketAdd?.();
    }
  }, [history.location.search]);

  useEffect(() => {
    if (entityName && entities.length) {
      setIsEntityNameExists(entities.some(b => b.name === entityName));
    }
  }, [entityName, entities, isVisible]);

  useEffect(() => {
    const isEntityNameEmpty = entityName.trim() === '';
    const isAllSpaces = /^\s*$/.test(entityName);
    const isPortfolioNameValid = !isEntityNameExists && !isAllSpaces;
    if (!isEntityNameEmpty && isPortfolioNameValid) {
      setIsCreateButtonDisabled(false);
    } else {
      setIsCreateButtonDisabled(true);
    }
  }, [entityName, isEntityNameExists]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const isAllSpaces = /^\s*$/.test(entityName);
    if (e.target.value.length > MAX_ENTITY_NAME && !isAllSpaces) {
      setIsEntityNamaLong(true);
    } else {
      setIsEntityNamaLong(false);
    }
    setEntityName(e.target.value);
  };

  const onClick = () => {
    if (entities.length >= limitEntities) {
      setIsLimitReachModalOpen(true);
    } else {
      onSaveClick(entityName, selectedIndex.secId || '')
        .then(() => {
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: {
              text: `Successfully added a new ${staticModalData.entityName.toLowerCase()}`,
            },
          });
          scrollOnBucketAdd?.();
        })
        .catch(() => {
          dispatchApp({
            type: APP_ACTIONS.SET_ERROR_MESSAGE,
            payload: `${staticModalData.entityName} not added`,
          });
        })
        .finally(() => {
          setSelectedIndex(DefaultSelectedIndex);
        });
    }
    setIsEntityNameExists(false);
    setIsEntityNamaLong(false);
    setEntityName('');
    toggleModal();
  };
  const handleLimitReachModalOpen = () => {
    setIsLimitReachModalOpen(prev => !prev);
  };
  const entityNameText = staticModalData.entityName;
  const infoText = staticModalData.limitMessage ?? '';
  return (
    <>
      <Modal
        width={720}
        open={isVisible}
        onCancel={toggleModal}
        className={cx(style.modal, className)}
        footer={[
          <Button
            size="large"
            type="primary"
            className={style['modal-btn']}
            onClick={onClick}
            key="primary"
            disabled={isCreateButtonDisabled || isEntityNameExists}
            data-test-id="modalAddBucketSaveButton"
          >
            Create
          </Button>,
        ]}
        closeIcon={closeIcon || <GraphicClose />}
      >
        <div data-test-id={toCamelCase(`addNew${entityNameText}Modal`)}>
          <div className={style['modal-title']}>Add New {entityNameText}</div>
          <div className={cx(style['modal-text-gray'], 'pt-1')}>{infoText}</div>
          <div className="pt-6">
            <p className={style['modal-text-label']}>{entityNameText} Name</p>
          </div>
          <Input
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            placeholder={`Enter ${entityNameText} Name`}
            value={entityName}
            className={style['index-input']}
            required
            data-test-id="modalAddBucketNameInput"
          />
          {isEntityNameExists && (
            <div className="pt-1 pl-1 text-xs font-medium text-danger">
              {entityNameText} name already exists
            </div>
          )}
          {isEntityNameLong && (
            <div className="pt-1 pl-1 text-xs font-medium text-danger">
              We suggest you keep these as short as possible
            </div>
          )}
          <div>
            <div>
              <p className={cx(style['modal-text-label'], 'pt-4')}>Benchmark</p>
            </div>
            <div data-test-id="modalAddBucketBenchmarkInput">
              <BenchmarksDropdown
                dropdownKey={entityName}
                className={style['index-dropdown']}
                placeholder="Select benchmark"
                value={selectedIndex.secId}
                benchmarkOptions={
                  benchmarkOptionsResponse ? benchmarkOptionsResponse : []
                }
                onSelect={selectedValue => {
                  setSelectedIndex({
                    secId: selectedValue,
                    name:
                      benchmarkOptionsResponse?.find(
                        b => b.id === selectedValue,
                      )?.name || '',
                  });
                }}
                onClear={() => setSelectedIndex({ secId: '', name: '' })}
                isOptionsLoading={isBenchmarkOptionsLoading}
                hideCustomOptions
              />
            </div>
          </div>
        </div>
      </Modal>
      <LimitReachModal
        isFundTrust={false}
        staticData={staticModalData}
        isVisible={isLimitReachModalOpen}
        toggleModal={handleLimitReachModalOpen}
      />
    </>
  );
};
export default AddEntityWithBenchmarkModal;
