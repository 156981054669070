import React, { createContext, useContext, useReducer } from 'react';

import { onboardingReducer } from './reducers';
import { OnboardingContextProps, OnboardingState } from './types';

const initialState: OnboardingState = {
  isShowWelcomeModal: true,
  isShowSearchFundsSection: false,
  currentStep: 1,
};

const OnboardingContext = createContext<OnboardingContextProps>({
  state: initialState,
  dispatch: () => ({}),
});

const initializer = (state: OnboardingState) => state;

const OnboardingProvider: React.FCWithChild = ({ children }) => {
  const [state, dispatch] = useReducer(
    onboardingReducer,
    initialState,
    initializer,
  );

  return (
    <OnboardingContext.Provider value={{ state, dispatch }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export { OnboardingProvider };

export const useOnboardingContext = (): OnboardingContextProps => {
  return useContext(OnboardingContext);
};

export default OnboardingContext;
