import { useSearchSCContext } from './context';
import { SEARCH_SC_ACTIONS } from './types';

const useProvideSCSearch = () => {
  const { state, dispatch } = useSearchSCContext();

  const setSearchTerm = (payload?: string) => {
    dispatch({
      type: SEARCH_SC_ACTIONS.SET_SEARCH_TERM,
      payload,
    });
  };

  const clearSearch = async () => {
    dispatch({
      type: SEARCH_SC_ACTIONS.CLEAR,
    });
  };

  return {
    state,
    setSearchTerm,
    clearSearch,
  };
};

export default useProvideSCSearch;
