import { NotificationsApi } from '@aminsights/contract';
import { useMutation } from '@tanstack/react-query';

import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { NOTIFICATIONS, UNSEEN_NOTIFICATIONS } from './query-keys';

const notificationsApi = new NotificationsApi(openApiConfig());

const useMarkNotificationsAsSeen = () => {
  const mutation = useMutation(
    (ids: string[]) => notificationsApi.markAsSeen({ ids }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([NOTIFICATIONS]);
        queryClient.invalidateQueries([UNSEEN_NOTIFICATIONS]);
      },
    },
  );
  return mutation;
};

export default useMarkNotificationsAsSeen;
