import { TableLegend } from '@/components/Table/Legend';
import { EXTREMUM_PERFORMANCE_TABLE_COLORS_LEGEND } from '@/constants';

export const ExtremumsPerformanceLegend = () => (
  <TableLegend
    colors={EXTREMUM_PERFORMANCE_TABLE_COLORS_LEGEND}
    startText="Q1"
    endText="Q4"
  />
);
