import { Tooltip } from 'antd';
import React, { useCallback, useRef } from 'react';

import { useAppContext } from '@/context/AppContext';

interface TooltipRenderProps {
  shareClassDetailsCode: string;
}

const TooltipRender: React.FC<TooltipRenderProps> = ({
  shareClassDetailsCode,
}) => {
  const linkRef = useRef<HTMLParagraphElement>(null);
  const { app } = useAppContext();

  const tooltipMessage = useCallback(() => {
    const pEl = linkRef.current;
    if (pEl && pEl.scrollWidth > pEl.clientWidth) {
      return shareClassDetailsCode;
    }
    return '';
  }, [app.windowWidth, shareClassDetailsCode]);

  return (
    <Tooltip
      placement="bottomRight"
      title={tooltipMessage()}
      overlayClassName="md:max-w-none lg:whitespace-nowrap"
      overlayInnerStyle={{
        width: 'fit-content',
      }}
    >
      <p ref={linkRef} className="min-w-0 overflow-ellipsis w-full">
        {shareClassDetailsCode}
      </p>
    </Tooltip>
  );
};

export default TooltipRender;
