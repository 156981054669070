import { AreaConfig } from '@ant-design/charts';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetPremiumDiscountChartData from '@/hooks/query-hooks/chart-hooks/useGetPremiumDiscountChartData';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import RangeDatePicker, {
  parseRangeValues,
  useDatePickerContext,
} from '@/partials/DatePicker';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';

import ChartEmptyState from '../ChartEmptyState';
import BaseChart, { BaseChartPropsV2 } from '../components/BaseChart';
import chartStyles from '../utils/chart.module.less';
import { PerformanceCardDetails } from '../utils/chart-data';
import { calculateStrokeColor } from '../utils/colors';
import useChartColors from '../utils/useChartColors';

interface PremiumDiscountChartProps extends BaseChartPropsV2 {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  allIsins?: string[];
}

const PremiumDiscountChartV2: React.FCWithChild<PremiumDiscountChartProps> = ({
  chartOnly,
  showFundLinks = true,
  onRemoveFund,
  isins,
  allIsins,
  benchmarkId,
  featuredIsins,
  chartColors: chartColorsProps,
}) => {
  const { value: datePickerValue, handleChange: handleDatePickerChange } =
    useDatePickerContext();
  const [legendData, setLegendData] = useState<PerformanceCardDetails[]>([]);

  // Contemplating whether to move this out or not
  // eslint-disable-next-line
  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];

  const { data, isLoading, isFetching } = useGetPremiumDiscountChartData({
    benchmarkId,
    isins,
    period,
    featuredIsins,
  });
  const premiumDiscountData = data?.data || [];
  const chartColorsHook = useChartColors({
    isins: allIsins || isins,
    legend: data?.legendData || [],
  });
  const chartColors = chartColorsProps || chartColorsHook;

  useEffect(() => {
    if (data?.legendData?.length && chartColors) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.label || '',
          value: f.value || '',
          id: f.id || '',
          isBenchmark: f.isBenchmark || false,
          label: f.label || '',
          isFeatured: f.isFeatured || false,
          color: chartColors[f.id || ''] || '',
        })),
      );
    }
  }, [data, chartColors]);

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: premiumDiscountData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return chartColors[id] || '';
      },
    }),
    [premiumDiscountData, chartColors],
  );
  // if isins are not defined - show loader no matter what
  // this will mean that we are waiting for isins to be defined to be provided from the parent control

  if (!premiumDiscountData.length && !isLoading && !isFetching) {
    return (
      <ChartEmptyState
        title={'No data available for this fund'}
        isDashboardPage={true}
      />
    );
  }

  return (
    <div>
      {!chartOnly && (
        <div className={chartStyles.header}>
          <h5
            data-test-id="premiumDiscountChartLabel"
            className="text-sm font-bold text-darkest"
          >
            <Loader
              width="150px"
              loading={isLoading}
              component={<>Premium/Discount</>}
            />
          </h5>
          <RangeDatePicker
            disabled={isLoading}
            loading={isLoading}
            onChange={handleDatePickerChange}
            value={datePickerValue}
            btnClassName="justify-self-end col-span-2 sm:col-span-1 w-full sm:w-auto"
            dataTestId="premiumDiscountChartDropdown"
          />
        </div>
      )}
      {!isLoading && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          enableHoverEffects={
            !!featuredIsins?.length && legendData.some(d => d.isFeatured)
          }
          shouldYScaleBeChanged
          dataTestId="premiumDiscount"
          onUpdateLegend={setLegendData}
        />
      )}
      {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
      <div
        data-test-id="premiumDiscountChartLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .map(legendItem => (
              <ChartsLegendCard
                key={legendItem.id}
                label={legendItem.label}
                idForFundDetailsLink={
                  showFundLinks ? legendItem.isin : undefined
                }
                id={legendItem.id}
                tooltip={legendItem.tooltip || legendItem.label}
                isIndex={legendItem.isBenchmark}
                color={legendItem.color}
                value={`${legendItem.value}`}
                isFeatured={
                  !!featuredIsins?.length ? legendItem.isFeatured : false
                }
                isActive={legendItem.isActive}
                onRemove={onRemoveFund}
              />
            ))}
        />
      </div>
    </div>
  );
};

export default PremiumDiscountChartV2;
