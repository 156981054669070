import { COLOR_FUND_FEATURED, COLOR_FUND_OF_BUCKET } from '@/constants/colors';

export const switchTableRowColor = (
  isFeaturedRow: boolean,
  isBenchmarkRow: boolean,
  shouldBrightenFeaturedRow = true,
) => {
  let color;
  if (isBenchmarkRow) {
    color = COLOR_FUND_OF_BUCKET;
  } else if (isFeaturedRow) {
    color = COLOR_FUND_FEATURED(shouldBrightenFeaturedRow);
  }
  return color;
};

export const generateAvatarColor = (code: string) => {
  switch (code) {
    case 'MA':
      return 'sky';
    case 'Eq':
      return 'purple';
    case 'FI':
      return 'aqua';
    case 'Alt':
      return 'blue';
    case 'P':
      return 'green';
    case 'MM':
      return 'grey';
    default:
      throw Error(`Code '${code}' not supported`);
  }
};
