type Pages = {
  data: unknown[];
}[];
const calculateNextPageForInfiniteQuery = (total: number, pages: Pages) => {
  const currentNumberOfItems = pages
    .flat()
    .map(page => page.data.length)
    .reduce((acc, current) => acc + current, 0);

  if (total > currentNumberOfItems) {
    return pages.length + 1;
  }
  return undefined;
};

export default calculateNextPageForInfiniteQuery;
