import { Tabs } from 'antd';
import React from 'react';

import { useFundIsInvestmentTrust } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import { AnnualisedPerformanceTable } from './AnnualisedPerformanceTable';
import { CalendarPerformanceTable } from './CalendarPerformanceTable';
import { FundCharts } from './FundCharts';
import { InvestmentTrustCharts } from './InvestmentTrustCharts';

const Performance: React.FCWithChild = () => {
  const isInvestmentTrust = useFundIsInvestmentTrust();
  return (
    <div className="px-5">
      <div>
        {isInvestmentTrust && <InvestmentTrustCharts />}
        {!isInvestmentTrust && <FundCharts />}
        <div
          className="bg-white mt-4 rounded-lg"
          data-test-id="fundDetailsPerfRiskTabPerformance"
        >
          <h2 className="text-sm text-darkest font-bold p-4">Performance</h2>
          <Tabs
            items={[
              {
                label: (
                  <div data-test-id="fundDetailsPerfRiskTabPerformanceAnnualisedTab">
                    Ann. Perf
                  </div>
                ),
                key: 'summary',
                children: <AnnualisedPerformanceTable />,
              },
              {
                label: (
                  <div data-test-id="fundDetailsPerfRiskTabPerformanceCalendarTab">
                    Cal. Perf
                  </div>
                ),
                key: 'calendar',
                children: <CalendarPerformanceTable />,
              },
            ]}
          ></Tabs>
        </div>
      </div>
    </div>
  );
};

export default Performance;
