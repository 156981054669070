import { RiskDetailsTypeEnum } from '@aminsights/contract';
import { RipsReturnType } from '@aminsights/shared';
import { createContext, useContext, useState } from 'react';

interface RipsReturnTypeRecord {
  absolute: RipsReturnType;
  relative: RipsReturnType;
  risk: RiskDetailsTypeEnum;
}

export enum InvestmentTrustReturnType {
  SharePrice = 'SharePrice',
  NAV = 'NAV',
}

type ContextType = {
  value: RipsReturnTypeRecord;
  setValue: (newValue: InvestmentTrustReturnType) => void;
  valueLabel: InvestmentTrustReturnType;
};

const sharePrice = {
  absolute: RipsReturnType.SharePrice,
  relative: RipsReturnType.SharePrice,
  risk: RiskDetailsTypeEnum.INVESTMENT_TRUST,
};

const NAV = {
  absolute: RipsReturnType.TotalReturn,
  relative: RipsReturnType.TotalReturn,
  risk: RiskDetailsTypeEnum.FUND,
};

const investmentTrustReturnTypeMapping = {
  [InvestmentTrustReturnType.SharePrice]: sharePrice,
  [InvestmentTrustReturnType.NAV]: NAV,
};

const DEFAULT_STATE: ContextType = {
  value: investmentTrustReturnTypeMapping[InvestmentTrustReturnType.SharePrice],
  setValue: () => null,
  valueLabel: InvestmentTrustReturnType.SharePrice,
};

export const InvestmentTrustFilterDropDownContext =
  createContext(DEFAULT_STATE);

export const RipsReturnTypeProvider: React.FCWithChild = ({ children }) => {
  const [value, setValue] = useState(InvestmentTrustReturnType.SharePrice);
  const parsedValue = investmentTrustReturnTypeMapping[value];

  return (
    <InvestmentTrustFilterDropDownContext.Provider
      value={{ value: parsedValue, setValue, valueLabel: value }}
    >
      {children}
    </InvestmentTrustFilterDropDownContext.Provider>
  );
};

export function useDropDownContext() {
  return useContext(InvestmentTrustFilterDropDownContext);
}
