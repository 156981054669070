import { AutocompleteResponseSuggestionItem } from '@aminsights/contract';
import cx from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MAX_COMPARE_TOTAL } from '@/constants';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import BenchmarksDropdown from '@/partials/BenchmarksDropdown';
import Modal from '@/partials/Modal';

import CompareSearchBox from './CompareSearchBox';
import style from './style.module.less';

export interface CompareFundSelectModal {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  initialFundLeft?: InitialFundBase;
  initialFundRight?: InitialFundBase;
  initialFunds?: Array<InitialFundBase | undefined>;
  initialBenchmarkName?: string;
}

interface ISearchOption {
  name: string;
  isin: string;
}

export interface InitialFundBase {
  code: string;
  isin: string;
}

const CompareFundSelectModal: FC<CompareFundSelectModal> = ({
  isOpen,
  setIsOpen,
  initialFunds,
  initialBenchmarkName = '',
}) => {
  const history = useHistory();
  const {
    data: benchmarkOptionsResponse,
    isLoading: isBenchmarkOptionsLoading,
  } = useBenchmarkOptions();
  const [selectedFunds, setSelectedFunds] = useState<Array<ISearchOption>>(
    Array.from(new Array(MAX_COMPARE_TOTAL), () => ({
      name: '',
      isin: '',
    })),
  );
  const [searchValues, setSearchValues] = useState<Array<string>>(['']);

  useEffect(() => {
    if (initialFunds?.length && isOpen) {
      setSelectedFunds(prev => {
        const prevClone = [...prev];
        Array.from(new Array(MAX_COMPARE_TOTAL), (_, idx) => {
          prevClone[idx] = {
            name: initialFunds?.[idx]?.code || '',
            isin: initialFunds?.[idx]?.isin || '',
          };
        });
        return prevClone;
      });
      setSearchValues(initialFunds.map(i => i?.code || ''));
    }
  }, [JSON.stringify(initialFunds), isOpen]);
  const activeSearches = selectedFunds.filter(f =>
    searchValues.find(v => v === f.name && f.isin),
  );
  const handleSearchValues = (value: string, index: number) => {
    setSearchValues(p =>
      p.map((v, i) => {
        if (i === index) return value;
        return v;
      }),
    );
  };
  const handleSelectedFund = (
    value?: AutocompleteResponseSuggestionItem,
    index?: number,
  ) => {
    handleSearchValues(value?.shareClassDetails?.code || '', index || 0);
    setSelectedFunds(prev =>
      prev.map((v, i) => {
        if (i === index) {
          if (value) {
            return {
              name: value.shareClassDetails.code,
              isin: value.shareClassDetails.isin,
            };
          }
          return {
            name: '',
            isin: '',
          };
        }
        return v;
      }),
    );
  };
  const handleChange = (value: string, index: number) => {
    setSearchValues(prev => {
      const prevClone = [...prev];
      prevClone[index] = value;
      return prevClone;
    });
  };

  /**
   * Start of Benchmark
   */
  const [benchmark, setBenchmark] = useState(initialBenchmarkName);

  useEffect(() => {
    if (isOpen && selectedFunds.filter(f => !!f.isin)?.length < 4) {
      setTimeout(() => {
        const indexToFocus = selectedFunds.findIndex(f => !f.isin);
        const inputToFocus = document.querySelector<HTMLInputElement>(
          `[data-test-id="compare-fund-search-box-${
            indexToFocus >= 0 ? indexToFocus + 1 : 1
          }"] input`,
        );
        inputToFocus?.focus();
      }, 50);
    }
  }, [isOpen, selectedFunds]);

  return (
    <Modal
      className={cx(style['compare-fund-select-modal'])}
      title="Compare Tool"
      isVisible={isOpen}
      toggleModal={() => setIsOpen(!isOpen)}
      footerConfig={{
        applyText: 'Compare',
        disableApplyButton: activeSearches.length < 2,
      }}
      onApply={() => {
        if (activeSearches?.length < 2) {
          // This error message should never show, but better safe than sorry
          console.error(
            'Matching fund left, right or benchmark is not defined. It is also possible that both items are not of the same asset class.',
          );
        } else {
          setIsOpen(false);
          const benchmarkSecId = benchmark ?? '';
          const fundsParams = activeSearches
            .map((s, idx) => `fund${idx + 1}=${s.isin}`)
            .join('&');
          history.push({
            pathname: '/fund/compare',
            search: `?${fundsParams}&benchmarkSecId=${benchmarkSecId}`,
          });
        }
      }}
    >
      <div className="flex flex-col gap-4">
        {Array.from(new Array(MAX_COMPARE_TOTAL), (_, index) => (
          <div key={`compare-fund-search-box-${index}`}>
            <CompareSearchBox
              dataTestId={`compare-fund-search-box-${index + 1}`}
              onClose={() => null}
              onChange={term => handleChange(term, index)}
              excludedIsins={
                selectedFunds
                  .filter((_, idx) => idx !== index)
                  ?.map(i => i.isin) || []
              }
              onAdd={selectedFund => handleSelectedFund(selectedFund, index)}
              onClear={() => handleSelectedFund(undefined, index)}
              defaultValue={selectedFunds[index]?.name || ''}
            />
          </div>
        ))}
        <div className="flex flex-col">
          <label className={style['compare-modal-label']}>Benchmark</label>
          <BenchmarksDropdown
            className={style['index-dropdown']}
            placeholder="Select benchmark"
            value={benchmark}
            benchmarkOptions={benchmarkOptionsResponse || []}
            onSelect={selectedValue => setBenchmark(selectedValue)}
            onClear={() => setBenchmark('')}
            isOptionsLoading={isBenchmarkOptionsLoading}
            hideCustomOptions
          />
        </div>
      </div>
    </Modal>
  );
};

export default CompareFundSelectModal;
