import { InternalApi, MaterialBody } from '@aminsights/contract';
import { useMutation } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { INTERNAL } from '../watchlist-hooks/query-keys';

const internalApi = new InternalApi(openApiConfig());

const invalidateInternal = () => {
  queryClient.invalidateQueries({ queryKey: [INTERNAL] });
};

export const useCreateMaterial = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; body: MaterialBody }) =>
      internalApi.createMaterial(input.fundId, input.body),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Link has been added' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error adding link',
        });
      },
    },
  );
  return mutation;
};

export const useUpdateMaterial = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; materialId: string; body: MaterialBody }) =>
      internalApi.updateMaterial(input.fundId, input.materialId, input.body),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Link has been updated' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error updating link',
        });
      },
    },
  );
  return mutation;
};

export const useDeleteMaterial = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundId: string; materialId: string }) =>
      internalApi.deleteMaterial(input.fundId, input.materialId),
    {
      onSettled: () => invalidateInternal(),
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Link has been deleted' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Error deleting link',
        });
      },
    },
  );
  return mutation;
};
