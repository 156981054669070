import cx from 'classnames';
import React, { ReactNode } from 'react';

import style from './style.module.less';

type SelectedDropdownFilterProps = {
  placeholder?: string;
  noOfSelectedFilter: number;
  className?: string;
};

const selectedDropdownFilter = (selectedFilter: number): ReactNode => {
  return (
    <>
      <div className={style.dot}>&bull;</div> {selectedFilter}
    </>
  );
};

const SelectedDropdownFilter: React.FCWithChild<
  SelectedDropdownFilterProps
> = ({ placeholder, noOfSelectedFilter, className }) => {
  return (
    <div className={cx(style['custom-filter-label'], className)}>
      {placeholder}{' '}
      {noOfSelectedFilter > 0 && selectedDropdownFilter(noOfSelectedFilter)}
    </div>
  );
};

export default SelectedDropdownFilter;
