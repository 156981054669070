import './style.less';

import React from 'react';

import { PageResults } from '@/components';
import useSignificantChangesInfiniteQuery from '@/hooks/query-hooks/significant-changes-hooks/useSignificantChangesInfiniteQuery';
import useProvideSignificantChanges from '@/pages/app/SignificantChanges/useProvideSignificantChanges';
import SignificantChangesFilter from '@/partials/SignificantChanges/SignificantChangesFilter';
import SignificantChangesTable from '@/partials/SignificantChanges/SignificantTable';

interface SignificantChangesProps {
  isins: string[];
}

const SignificantChanges: React.FCWithChild<SignificantChangesProps> = ({
  isins,
}) => {
  const {
    state,
    clearFilter,
    anyFiltersSelected,
    setSignificantChangeParameters,
  } = useProvideSignificantChanges();

  const significantChangesResponse = useSignificantChangesInfiniteQuery(isins);

  const totalNumberOfItems =
    significantChangesResponse?.data?.pages?.[0]?.total ?? 0;

  return (
    <section className="section-significant">
      <div className="flex flex-row">
        <div className="pt-1 significant-wrapper">
          <div className="section-significant-inner">
            <div className="section-significant-wrapper">
              <div className="flex flex-col justify-between search-option md:flex-row">
                <SignificantChangesFilter
                  onClear={clearFilter}
                  anyFiltersSelected={anyFiltersSelected()}
                  onParamsChange={params => {
                    setSignificantChangeParameters({ ...params, page: 0 });
                  }}
                  state={state}
                />

                <PageResults totalCount={totalNumberOfItems} className="w-40" />
              </div>
              <div className="announcement-list">
                <SignificantChangesTable isins={isins} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignificantChanges;
