import { Skeleton, Tooltip } from 'antd';
import cx from 'classnames';
import React, { forwardRef } from 'react';

import style from './style.module.less';

export interface HeaderProps {
  title: string | React.ReactElement;
  width?: number;
  align?: string;
  colSpan: number;
  isFixed?: boolean;
  type?: string;
  isEndLeft: boolean;
  hasAction: boolean;
  tooltipText?: React.ReactNode;
  loading?: boolean;
}

const TableFooter = forwardRef<HTMLTableCellElement, HeaderProps>(
  ({ title, width, align, colSpan, tooltipText, loading, isFixed }, ref) => {
    return (
      <th
        ref={ref}
        key={title + 'footer'}
        className={cx(style['table-footer'], {
          [style['table-sub-header__fixed']]: isFixed,
        })}
        style={{ width }}
        colSpan={colSpan}
      >
        <div
          className={cx('flex items-center w-full py-2 px-3 sm:px-4', {
            'justify-end': align === 'right',
            'justify-start': align === 'left',
            'justify-center': align === 'center',
          })}
          style={{ width }}
        >
          {!loading && (
            <Tooltip
              placement="bottom"
              title={tooltipText}
              overlayClassName="max-w-60 md:max-w-none"
              overlayInnerStyle={{ borderRadius: 4 }}
              arrowPointAtCenter
            >
              <p className="text-sm text-neutral font-medium whitespace-nowrap">
                {title}
              </p>
            </Tooltip>
          )}
          {loading && title && (
            <Skeleton
              title={false}
              paragraph={{ rows: 1, width: '70px' }}
              active
            />
          )}
        </div>
      </th>
    );
  },
);

export default TableFooter;
