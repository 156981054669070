import {
  EMPTY_DATA_POINT,
  getPerformanceTableReturnColumnName,
} from '@aminsights/shared';
import { buildFundDetailsPath } from '@aminsights/shared';
import { Link } from 'react-router-dom';

import { IDataTableColumns } from '@/components/Table/DataTable';

import {
  IAnnualisedPerformanceData,
  ICalendarYearPerformanceData,
} from './types';

export const AnnualisedPerformanceColumns = (
  isInvestmentTrust: boolean,
  isTitleColumnClickable?: boolean,
): IDataTableColumns[] => {
  const navOrReturn = getPerformanceTableReturnColumnName(isInvestmentTrust);
  return [
    {
      title: isInvestmentTrust ? 'Investment Trust' : 'Fund',
      render: (item: IAnnualisedPerformanceData) =>
        isTitleColumnClickable && item.key !== 'benchmark' ? (
          <Link
            to={buildFundDetailsPath(item.key)}
            className="font-bold text-darkest"
          >
            {item.name}
          </Link>
        ) : (
          <div className="font-bold text-darkest">{item.name}</div>
        ),
      renderType: 'text',
      sortKey: 'name',
      width: 200,
      isColumnFixed: true,
      idSubText: 'name',
    },
    {
      title: 'YTD',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.ytdSP ? `${item.ytdSP.toFixed(1)}%` : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'ytdSP',
          idSubText: 'ytdSP',
        },
        {
          title: navOrReturn,
          sortKey: 'ytdReturn',
          idSubText: 'ytdReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.ytdReturn
                ? `${item.ytdReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Percentile',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.ytdPercentile
                ? `${item.ytdPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'ytdPercentile',
          idSubText: 'ytdPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    {
      title: '3 mos',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'threeMonthsSP',
          idSubText: 'threeMonthsSP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.threeMonthsSP
                ? `${item.threeMonthsSP.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'threeMonthsReturn',
          idSubText: 'threeMonthsReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.threeMonthsReturn
                ? `${item.threeMonthsReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Percentile',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.threeMonthsPercentile
                ? `${item.threeMonthsPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'threeMonthsPercentile',
          idSubText: 'threeMonthsPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    {
      title: '6 mos',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'sixMonthsSP',
          idSubText: 'sixMonthsSP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.sixMonthsSP
                ? `${item.sixMonthsSP.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'sixMonthsReturn',
          idSubText: 'sixMonthsReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.sixMonthsReturn
                ? `${item.sixMonthsReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Percentile',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.sixMonthsPercentile
                ? `${item.sixMonthsPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'sixMonthsPercentile',
          idSubText: 'sixMonthsPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    {
      title: '1 yr',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'oneYearSP',
          idSubText: 'oneYearSP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.oneYearSP
                ? `${item.oneYearSP.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'oneYearReturn',
          idSubText: 'oneYearReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.oneYearReturn
                ? `${item.oneYearReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Percentile',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.oneYearPercentile
                ? `${item.oneYearPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'oneYearPercentile',
          idSubText: 'oneYearPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    {
      title: '3 yr',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'threeYearsSP',
          idSubText: 'threeYearsSP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.threeYearsSP
                ? `${item.threeYearsSP.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'threeYearsReturn',
          idSubText: 'threeYearsReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.threeYearsReturn
                ? `${item.threeYearsReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Percentile',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.threeYearsPercentile
                ? `${item.threeYearsPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'threeYearsPercentile',
          idSubText: 'threeYearsPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    {
      title: '5 yr',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'fiveYearsSP',
          idSubText: 'fiveYearsSP',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.fiveYearsSP
                ? `${item.fiveYearsSP.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'fiveYearsReturn',
          idSubText: 'fiveYearsReturn',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.fiveYearsReturn
                ? `${item.fiveYearsReturn.toFixed(1)}%`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
        },
        {
          title: 'Percentile',
          render: (item: IAnnualisedPerformanceData) => (
            <div className="table-cell-value">
              {item.fiveYearsPercentile
                ? `${item.fiveYearsPercentile.toFixed(0)}`
                : EMPTY_DATA_POINT}
            </div>
          ),
          renderType: 'number',
          sortKey: 'fiveYearsPercentile',
          idSubText: 'fiveYearsPercentile',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
  ];
};

export const CalendarYearPerformanceColumns = (
  isInvestmentTrust: boolean,
  isTitleColumnClickable?: boolean,
): IDataTableColumns[] => {
  const navOrReturn = getPerformanceTableReturnColumnName(isInvestmentTrust);
  return [
    {
      title: isInvestmentTrust ? 'Investment Trust' : 'Fund',
      sortKey: 'name',
      idSubText: 'name',
      render: (item: ICalendarYearPerformanceData): React.ReactNode => {
        return isTitleColumnClickable && item.key !== 'benchmark' ? (
          <Link
            to={buildFundDetailsPath(item.key)}
            className="font-bold text-darkest"
          >
            {item.name}
          </Link>
        ) : (
          <div className="font-bold text-darkest">{item.name}</div>
        );
      },
      renderType: 'text',
      width: 200,
      isColumnFixed: true,
    },
    {
      title: 'YTD',
      align: 'center',
      renderType: 'custom',
      children: [
        isInvestmentTrust && {
          title: 'SP',
          sortKey: 'sharePriceReturnYtdPct',
          idSubText: 'sharePriceReturnYtdPct',
          render: (item: ICalendarYearPerformanceData): string => {
            return item.sharePriceReturnYtdPct !== undefined
              ? `${Number(item.sharePriceReturnYtdPct).toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
        },
        {
          title: navOrReturn,
          sortKey: 'totalReturnYtdPct',
          idSubText: 'totalReturnYtdPct',
          render: (item: ICalendarYearPerformanceData): string => {
            return item.totalReturnYtdPct !== undefined
              ? `${Number(item.totalReturnYtdPct).toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
        },
        {
          title: 'Percentile',
          render: (item: ICalendarYearPerformanceData): string => {
            return item?.totalReturnYtdMorningstarCategoryPcl !== undefined
              ? `${Number(item?.totalReturnYtdMorningstarCategoryPcl).toFixed(
                  0,
                )}`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          sortKey: 'totalReturnYtdMorningstarCategoryPcl',
          idSubText: 'totalReturnYtdMorningstarCategoryPcl',
        },
      ].filter(Boolean) as IDataTableColumns[],
    },
    ...([1, 2, 3, 4, 5].map(yearsAgo => {
      const year = new Date().getFullYear() - yearsAgo;
      const pctKey = `totalReturn${yearsAgo}calendarYearsAgoPct`;
      const pclKey = `totalReturn${yearsAgo}calendarYearsAgoCategoryPcl`;
      const spKey = `sharePriceReturn${yearsAgo}calendarYearsAgoPct`;
      return {
        title: year.toString(),
        align: 'center',
        renderType: 'custom',
        children: [
          isInvestmentTrust && {
            title: 'SP',
            render: (item: ICalendarYearPerformanceData): string => {
              const fundValue =
                item?.[spKey as keyof ICalendarYearPerformanceData];
              return fundValue !== undefined
                ? `${Number(fundValue).toFixed(1)}%`
                : EMPTY_DATA_POINT;
            },
            renderType: 'number',
            sortKey: spKey,
            idSubText: spKey,
          },
          {
            title: navOrReturn,
            sortKey: pctKey,
            idSubText: pctKey,
            render: (item: ICalendarYearPerformanceData): string => {
              const fundValue =
                item?.[pctKey as keyof ICalendarYearPerformanceData];
              return fundValue !== undefined
                ? `${Number(fundValue).toFixed(1)}%`
                : EMPTY_DATA_POINT;
            },
            renderType: 'number',
          },
          {
            title: 'Percentile',
            render: (item: ICalendarYearPerformanceData): string => {
              const fundValue =
                item?.[pclKey as keyof ICalendarYearPerformanceData];
              return fundValue !== undefined
                ? `${Number(fundValue).toFixed(0)}`
                : EMPTY_DATA_POINT;
            },
            renderType: 'number',
            sortKey: pclKey,
            idSubText: pclKey,
          },
        ].filter(Boolean) as IDataTableColumns[],
      };
    }) as IDataTableColumns[]),
  ];
};
