import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as NoConnectionIcon } from '@/assets/svg/icons/icon-no-connection.svg';
import Button from '@/components/Button';

import style from './style.module.less';

const NotFoundPage: React.FCWithChild = () => {
  const history = useHistory();
  return (
    <div className={style.container}>
      <div className={style['inner-container']}>
        <NoConnectionIcon className={style.icon} />
        <div className={style.title}>
          <h1>404</h1>
          <p>Page not found</p>
        </div>
        <p className={style.description}>
          We can&lsquo;t seem to find the page you&lsquo;re looking for
        </p>
        <Button type="primary" onClick={() => history.replace('/')}>
          Go back to dashboard
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
