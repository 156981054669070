import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { ReactComponent as BGLogo } from '@/assets/svg/am-insights-bg-logo.svg';
import { Button } from '@/components';
import Modal from '@/partials/Modal';

import { useOnboardingContext } from '../../context';
import { ONBOARDING_ACTIONS } from '../../types';
import { ISlide, slides } from './slides';
import style from './style.module.less';

interface ModalNavigationProps {
  currentStep: number;
  onClickStep: (step: number) => void;
}

const ModalNavigation: React.FCWithChild<ModalNavigationProps> = ({
  currentStep,
  onClickStep,
}) => {
  return (
    <>
      {slides.map((slide: ISlide) => (
        <button
          key={slide.step}
          className={cx(
            'mx-1 md:m-1 rounded-full border border-solid border-light w-2 h-2 md:w-3 md:h-3 transition-all',
            {
              'bg-neutral border-neutral': currentStep === slide.step,
            },
          )}
          type="button"
          aria-label="step"
          onClick={() => onClickStep(slide.step)}
        />
      ))}
    </>
  );
};

const ModalOnboarding: React.FCWithChild = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [currentSlide, setCurrentSlide] = useState<ISlide | undefined>();
  const { state, dispatch } = useOnboardingContext();

  useEffect(() => {
    const item = slides.find(({ step }) => step === currentStep);
    if (item) {
      setCurrentSlide(item);
    } else {
      setCurrentSlide(undefined);
    }
  }, [currentStep]);

  const handleClickStep = (step: number) => {
    setCurrentStep(step);
  };

  const handleBackButton = () => {
    const previousStep = currentStep - 1;
    if (previousStep >= 1) {
      setCurrentStep(previousStep);
    }
  };

  const handleNextButton = () => {
    const nextStep = currentStep + 1;
    if (nextStep <= slides.length) {
      setCurrentStep(nextStep);
    } else {
      closeModal();
    }
  };

  const closeModal = () => {
    dispatch({
      type: ONBOARDING_ACTIONS.TOGGLE_WELCOME_MODAL,
      payload: false,
    });
  };

  return (
    <Modal
      size={704}
      className={style.modal}
      isVisible={state.isShowWelcomeModal}
      toggleModal={closeModal}
      closeIcon={
        currentStep < slides.length ? (
          <p className="text-xs text-primary">Skip</p>
        ) : (
          <div />
        )
      }
    >
      <div className="pt-10 pb-38 md:pb-16 flex justify-center">
        <div>
          {currentSlide ? (
            <>
              <div className="text-center relative z-1">
                <div className="w-full mb-4">
                  <img
                    className="max-w-128 w-full m-auto"
                    src={currentSlide.banner}
                    alt={`Banner Step ${currentSlide.step}`}
                  />
                </div>
                <h3 className="text-xl font-bold text-darkest mb-4">
                  {currentSlide.title}
                </h3>
                <p className="text-sm mb-6 text-neutral font-normal">
                  {currentSlide.description}
                </p>
              </div>
            </>
          ) : (
            ''
          )}

          <div className="absolute bottom-20 md:bottom-0 -right-7">
            <BGLogo className="h-120 w-120 absolute bottom-0 -right-7" />
          </div>
          <div className="bg-white w-full absolute left-0 bottom-0 h-38 md:h-16 border-t border-solid border-grey-light-50">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between h-full px-6">
              <div className="w-full md:w-32 order-3 md:order-none">
                {currentStep > 1 ? (
                  <Button
                    size="large"
                    type="link"
                    className="font-semibold w-full md:w-auto"
                    onClick={handleBackButton}
                  >
                    Back
                  </Button>
                ) : (
                  ''
                )}
              </div>
              <div className="order-1 text-center pt-4 md:pt-0 mb-6 md:mb-0 md:order-none">
                <ModalNavigation
                  currentStep={currentStep}
                  onClickStep={handleClickStep}
                />
              </div>
              <div className="w-full md:w-32 order-2 md:order-none">
                <Button
                  size="large"
                  type="primary"
                  className="font-semibold w-full md:w-auto md:ml-auto"
                  onClick={handleNextButton}
                >
                  {currentStep === slides.length ? 'Get Started' : 'Next'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { slides };

export default ModalOnboarding;
