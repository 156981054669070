import { PortfolioFundForUpdate } from '@aminsights/contract';
import React from 'react';

import { ReactComponent as UploadIcon } from '@/assets/svg/icons/icon-upload-funds.svg';
import { UploadModalInfoBase } from '@/pages/app/Watchlist/Buckets/components/ImportFunds/types';

import CsvFundsDropzone from '../CsvFundsDropzone';
import style from './style.module.less';

type Props = {
  onFundsUploadFailure: (
    failedStatus: UploadModalInfoBase,
    filenames: string[],
  ) => void;
  onFundsUploadSuccess: (
    portfolioFunds: PortfolioFundForUpdate[],
    filenames: string[],
    dates: string[],
  ) => void;
};

const UploadFunds: React.FCWithChild<Props> = ({
  onFundsUploadFailure,
  onFundsUploadSuccess,
}) => {
  return (
    <div className={style['upload-funds']}>
      <h2>Import Portfolio</h2>
      <p>
        Add 1 portfolio at a time by uploading a csv file with columns for the
        ISINs & dates, along with weights. View the sample template to ensure
        you are using the correct formats.
      </p>

      <CsvFundsDropzone
        onFundsUploadFailure={onFundsUploadFailure}
        onFundsUploadSuccess={onFundsUploadSuccess}
      >
        <div className={style['upload-funds-browse-files']}>
          <UploadIcon />
          <p>
            <span>Browse</span> or drag and drop files here
          </p>
        </div>
      </CsvFundsDropzone>

      <a href={'/assets/file/sample_portfolio_template.csv'} download>
        View sample template
      </a>
    </div>
  );
};

export default UploadFunds;
