export const buckets = [
  {
    order: 1,
    name: 'UK Equity',
    index: 'Morningstar UK Index',
    funds: [
      {
        id: 1,
        isin: 'LU1852330817',
        name: 'Blackrock Global European Value',
      },
      {
        id: 2,
        isin: 'LU1852330817',
        name: 'Blackrock Global European Value',
      },
      {
        id: 3,
        isin: 'LU1852330817',
        name: 'Blackrock Global European Value',
      },
      {
        id: 4,
        isin: 'LU1852330817',
        name: 'Blackrock Global European Value',
      },
      {
        id: 5,
        isin: 'LU1852330817',
        name: 'Blackrock Global European Value',
      },
      {
        id: 6,
        isin: 'LU1852330817',
        name: 'Blackrock Global European Value',
      },
    ],
  },
  {
    order: 2,
    name: 'US Equity',
    index: 'Morningstar UK Index',
    funds: [],
  },
  {
    order: 3,
    name: 'Alternatives',
    index: 'Morningstar UK Index',
    funds: [
      {
        id: 1,
        isin: 'LU1852330817',
        name: 'Blackrock Global European Value',
      },
      {
        id: 2,
        isin: 'LU1852330817',
        name: 'Blackrock Global European Value',
      },
      {
        id: 3,
        isin: 'LU1852330817',
        name: 'Blackrock Global European Value',
      },
    ],
  },
  {
    order: 4,
    name: 'Fixed Income',
    index: 'Morningstar UK Index',
    funds: [
      {
        id: 1,
        isin: 'LU1852330817',
        name: 'Blackrock Global European Value',
      },
    ],
  },
];
