// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GG_rS7NBqk8Dod2A0720 {\n    display: block;\n    height: 2.5rem;\n    width: 2.5rem;\n    border-radius: 9999px\n}\n", "",{"version":3,"sources":["webpack://./src/partials/ESG/style.module.less"],"names":[],"mappings":"AACE;IAAA,cAAA;IAAA,cAAA;IAAA,aAAA;IAAA;AAAA","sourcesContent":[".badge {\n  @apply h-10 w-10 block rounded-full;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "GG_rS7NBqk8Dod2A0720"
};
export default ___CSS_LOADER_EXPORT___;
