import { Checkbox } from 'antd';
import cx from 'classnames';
import React from 'react';

import { CheckboxProps } from '../types';
import style from './style.module.less';

const CustomCheckbox: React.FCWithChild<CheckboxProps> = ({
  data,
  value,
  onChange,
  type,
  single,
  direction = 'col',
  className,
  children,
  checked,
  isRadio,
}) => {
  const renderCheckbox = () => {
    if (!single) {
      const handleChange = (val: string[]) => {
        onChange(isRadio ? val.filter(v => v !== value)[0] : val);
      };
      const parsedValue = isRadio ? [value] : value;
      return (
        <Checkbox.Group
          options={data}
          value={parsedValue}
          onChange={(value: any) => {
            handleChange(value);
          }}
        />
      );
    }
    return (
      <Checkbox checked={checked} onChange={e => onChange(e.target.value)}>
        {children}
      </Checkbox>
    );
  };
  return (
    <div
      className={cx(
        style['custom-checkbox'],
        { [style['custom-checkbox--list']]: type === 'list' },
        style[direction],
        className,
      )}
    >
      {renderCheckbox()}
    </div>
  );
};

export default CustomCheckbox;
