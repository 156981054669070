export function getIsPwaInstalled(): boolean {
  try {
    // Ensure we are running in a browser environment
    if (typeof window === 'undefined') {
      return false; // Return false or a default value when not in a browser environment
    }
    // Check if running in standalone mode or on iOS Safari as a standalone app
    return (
      window.matchMedia('(display-mode: standalone)').matches ||
      ('standalone' in window.navigator &&
        (window.navigator as any)['standalone'])
    );
  } catch (e) {
    console.error('[ERR] could not detect PWA installation status', e);
    return false;
  }
}
