import { Bucket, Portfolio } from '@aminsights/contract';
import { Modal } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as GraphicClose } from '@/assets/svg/icons/icon-close.svg';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { UpsertPortfolioForm } from '@/partials/Forms/Portfolio';
import { UpsertPortfolioFields } from '@/partials/Forms/Portfolio/type';
import { emptyPortfolioForm } from '@/partials/Forms/Portfolio/UpsertPortfolioForm';

import LimitReachModal, { StaticDataForLimitModal } from '../LimitReachModal';
import style from './style.module.less';

export interface StaticDataForAddNewEntityModal
  extends StaticDataForLimitModal {
  limitMessage?: string;
}
interface ModalProps {
  staticModalData: StaticDataForAddNewEntityModal;
  entities: (Portfolio | Bucket)[];
  onSaveClick: (payload: UpsertPortfolioFields) => Promise<any>;
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  closeIcon?: React.ReactNode;
}
const AddPortfolioModal: React.FCWithChild<ModalProps> = ({
  entities,
  staticModalData,
  onSaveClick,
  isVisible,
  className,
  closeIcon,
  toggleModal,
}) => {
  const { dispatch: dispatchApp } = useAppContext();
  const [isLimitReachModalOpen, setIsLimitReachModalOpen] = useState(false);
  const limitEntities = staticModalData.limitForEntities;

  const onSubmit = (values: UpsertPortfolioFields) => {
    if (entities.length >= limitEntities) {
      setIsLimitReachModalOpen(true);
    } else {
      onSaveClick(values)
        .then(() => {
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: {
              text: `Successfully added a new ${staticModalData.entityName.toLowerCase()}`,
            },
          });
        })
        .catch(() => {
          dispatchApp({
            type: APP_ACTIONS.SET_ERROR_MESSAGE,
            payload: `${staticModalData.entityName} not added`,
          });
        })
        .finally(() => {
          toggleModal();
        });
    }
  };
  const handleLimitReachModalOpen = () => {
    setIsLimitReachModalOpen(prev => !prev);
  };
  const entityNameText = staticModalData.entityName;
  const infoText = staticModalData.limitMessage ?? '';
  return (
    <>
      <Modal
        width={720}
        open={isVisible}
        onCancel={toggleModal}
        className={cx(style.modal, className)}
        footer={[]}
        closeIcon={closeIcon || <GraphicClose />}
      >
        <div className={style['wrapper']}>
          <div className={style['modal-title']}>Add New {entityNameText}</div>
          <div className={cx(style['modal-text-gray'], 'pt-1')}>{infoText}</div>

          <UpsertPortfolioForm
            portfolio={emptyPortfolioForm}
            submitting={false}
            onSubmit={onSubmit}
            error={null}
          />
        </div>
      </Modal>
      <LimitReachModal
        isFundTrust={false}
        staticData={staticModalData}
        isVisible={isLimitReachModalOpen}
        toggleModal={handleLimitReachModalOpen}
      />
    </>
  );
};
export default AddPortfolioModal;
