import React from 'react';

import ESGTable from '../InfoAndStats/ESG';
import GenderPayGapTable from '../InfoAndStats/GenderPayGap';
import style from './style.module.less';

const ESG = () => {
  return (
    <div className={style['esg']}>
      <div
        className={style['section']}
        data-test-id="fundDetailsPortfolioESGTable"
      >
        <h4 className={style['section-title']}>ESG</h4>
        <ESGTable />
      </div>
      <div
        className={style['section']}
        data-test-id="fundDetailsPortfolioESGGenderPayGap"
      >
        <h4 className={style['section-title']}>Gender Pay Gap</h4>
        <GenderPayGapTable />
      </div>
    </div>
  );
};

export default ESG;
