import { Dropdown, Layout } from 'antd';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as BurgerMenu } from '@/assets/svg/graphic-burger-menu.svg';
import { ReactComponent as IconSearch } from '@/assets/svg/icons/icon-search.svg';
import { ReactComponent as Logo } from '@/assets/svg/logo.svg';
import Avatar from '@/components/Avatar';
import { BREAKPOINTS } from '@/constants';
import { useProvideProfile } from '@/pages/app/Settings/Profile/useProvideProfile';

import NavbarProfileMenu from './NavbarProfileMenu';
import NavSearchBox from './NavSearchbox';
import NotificationsWrapper from './Notifications';
import style from './style.module.less';

const { Header } = Layout;
interface NavbarProps {
  collapsed: boolean;
  onToggleMenu: () => void;
}

const Navbar: React.FCWithChild<NavbarProps> = ({
  collapsed,
  onToggleMenu,
}) => {
  const [isMenuToggle, setIsMenuToggle] = useState<boolean>(false);
  const [isToggleSearch, setIsToggleSearch] = useState<boolean>(false);
  const { user } = useProvideProfile();

  const handleToggle = () => {
    onToggleMenu();
    setIsMenuToggle(!isMenuToggle);
  };

  const handleToggleHome = () => {
    if (!isNotMobileResolution()) {
      onToggleMenu();
    }
  };

  useEffect(() => {
    setIsMenuToggle(collapsed);
  }, [collapsed]);

  const getInitials = (givenName: string, familyName: string) => {
    return `${givenName.charAt(0)}${familyName ? familyName.charAt(0) : ''}`;
  };

  const handleUserInitials = useMemo(() => {
    let nameInitiials;
    if (user && user.given_name && user.family_name) {
      nameInitiials = getInitials(user.given_name, user.family_name);
    }
    return nameInitiials;
  }, [user]);

  const isNotMobileResolution = () => {
    return (
      window.innerWidth > BREAKPOINTS.SM && window.innerHeight > BREAKPOINTS.SM
    );
  };

  return (
    <Header
      className={cx(
        style.navbar,
        'sticky',
        `${
          (!isMenuToggle || isNotMobileResolution()) && style['navbar-active']
        }`,
      )}
    >
      <div className="flex items-center justify-start">
        <button
          className={style['navbar-menu']}
          type="button"
          tabIndex={0}
          onClick={handleToggle}
          data-test-id="navBurgerMenu"
        >
          <BurgerMenu />
        </button>
        <Link
          to="/dashboard"
          className={style['navbar-logo']}
          onClick={() => handleToggleHome()}
          data-test-id="navAMILogo"
        >
          <Logo />
        </Link>
      </div>
      <div
        className={cx(style['icons-wrapper'], {
          [style['icons-wrapper-hidden']]: !collapsed,
        })}
      >
        {isToggleSearch ? (
          <div className={style['navbar-search']} data-test-id="navSearch">
            <NavSearchBox onClose={() => setIsToggleSearch(false)} />
          </div>
        ) : (
          <button
            className={style['icon-search']}
            type="button"
            tabIndex={0}
            onClick={e => {
              setIsToggleSearch(!isToggleSearch);
              e.stopPropagation();
            }}
            data-test-id="navSearchButton"
          >
            <IconSearch className={cx('icon', style['icon-search'])} />
          </button>
        )}

        <NotificationsWrapper />

        <Dropdown
          overlay={() => <NavbarProfileMenu />}
          trigger={['click']}
          placement="bottomRight"
          className={style['navbar-profile']}
        >
          <div data-test-id="navUserIcon">
            <Avatar type="user-avatar" color="sky-blue">
              {handleUserInitials}
            </Avatar>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default Navbar;
