export enum EquityStatsLabel {
  THREE_YEAR_EARNINGS_GROWTH = '3 Year Earnings Growth',
  ROE = 'ROE',
  ROIC = 'ROIC',
  NET_MARGIN = 'Net Margin',
}

export enum EquityStatsSortKey {
  Three_Year_Earnings_Growth = 'past3YearEarningsGrowth',
  Roe = 'roe',
  Roic = 'roic',
  Net_Margin = 'netMargin',
}

export const EQUITY_STATS_TO_SHOW: {
  title: EquityStatsLabel;
  key: EquityStatsSortKey;
}[] = [
  {
    title: EquityStatsLabel.THREE_YEAR_EARNINGS_GROWTH,
    key: EquityStatsSortKey.Three_Year_Earnings_Growth,
  },
  {
    title: EquityStatsLabel.ROE,
    key: EquityStatsSortKey.Roe,
  },
  {
    title: EquityStatsLabel.ROIC,
    key: EquityStatsSortKey.Roic,
  },
  {
    title: EquityStatsLabel.NET_MARGIN,
    key: EquityStatsSortKey.Net_Margin,
  },
];
