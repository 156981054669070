import cx from 'classnames';
import { FC } from 'react';

import style from './style.module.less';

export interface TableLegendProps {
  title?: string;
  startText?: string;
  endText?: string;
  colors: string[];
}

export const TableLegend: FC<TableLegendProps> = ({
  title,
  startText,
  endText,
  colors,
}) => (
  <div className="block md:flex p-4 text-xs rounded-full">
    {title && <span className="md:mr-9">{title}</span>}
    <div className="flex">
      <span>{startText ?? 'High'}</span>
      <div
        className={cx('flex items-center px-3', style['legend-item-border'])}
      >
        {colors.map(backgroundColor => (
          <span
            key={`background-color-${backgroundColor}`}
            className="h-2 w-6 border-box"
            style={{ backgroundColor }}
          />
        ))}
      </div>
      <span>{endText ?? 'Low'}</span>
    </div>
  </div>
);
