import { Fund } from '@aminsights/contract';
import { FC } from 'react';

import useInternal from '@/hooks/query-hooks/internal-hooks/useInternal';
import Commentary from '@/partials/Internal/Commentary';
import Materials from '@/partials/Internal/Materials';

interface InternalProps {
  fund?: Fund | undefined;
}

const Internal: FC<InternalProps> = ({ fund }) => {
  const { fundId, fundName } = fund || {};

  const { data, isLoading } = useInternal(fundId);

  return (
    <div className="flex flex-col py-2 px-4 gap-y-4">
      <Materials
        materials={data?.materials}
        fundId={fundId}
        isLoading={isLoading}
      />
      <Commentary
        comments={data?.comments}
        fundId={fundId}
        fundName={fundName}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Internal;
