import { FundPlatformsEnum } from '@aminsights/contract';

export const PLATFORMS_NAME_MAP = {
  '7IM': FundPlatformsEnum.SevenIm,
  'abrdn Wrap': FundPlatformsEnum.AbrdnWrap,
  'abrdn Elevate': FundPlatformsEnum.AbrdnElevate,
  Aegon: FundPlatformsEnum.AegonPlatform,
  ARC: FundPlatformsEnum.Arc,
  Aviva: FundPlatformsEnum.Aviva,
  'One Retirement': FundPlatformsEnum.OneRetirement,
  Parmenion: FundPlatformsEnum.Parmenion,
  Quilter: FundPlatformsEnum.Quilter,
  'Scottish Widows': FundPlatformsEnum.ScottishWidows,
  Transact: FundPlatformsEnum.Transact,
  Wealthtime: FundPlatformsEnum.Wealthtime,
};
