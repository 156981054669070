export type SignificantChangesProps = {
  key: string;
  fundInvestment: string;
  type: string;
  change: string;
  date: string;
};

export const dateDropDownData = [
  {
    value: '7D',
    label: 'Last 7 days',
  },
  {
    value: '30D',
    label: 'Last 30 days',
  },
  {
    value: '1YR',
    label: 'Last year',
  },
];
