/* tslint:disable */
/* eslint-disable */
/**
 * AMI Webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface AnnualisedPerformancesPcl
 */
export interface AnnualisedPerformancesPcl {
  /**
   *
   * @type {Array<PerformanceBase>}
   * @memberof AnnualisedPerformancesPcl
   */
  annualisedPerformancesPcl?: Array<PerformanceBase>;
}
/**
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaLongPctEquity?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaLongPctBond?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaLongPctPreferred?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaLongPctConvertible?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaLongPctCash?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaLongPctOther?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaShortPctBond?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaShortPctCash?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaShortPctConvertible?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaShortPctEquity?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaShortPctOther?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  aaShortPctPreferred?: number;
}
/**
 *
 * @export
 * @interface AssetAllocationItem
 */
export interface AssetAllocationItem {
  /**
   *
   * @type {string}
   * @memberof AssetAllocationItem
   */
  type?: AssetAllocationItemTypeEnum;
  /**
   *
   * @type {number}
   * @memberof AssetAllocationItem
   */
  value?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum AssetAllocationItemTypeEnum {
  Equities = 'Equities',
  FixedIncome = 'Fixed Income',
  Cash = 'Cash',
  Others = 'Others',
  Convertibles = 'Convertibles',
  Preferred = 'Preferred',
}

/**
 *
 * @export
 * @interface AssetAllocationList
 */
export interface AssetAllocationList {
  /**
   *
   * @type {number}
   * @memberof AssetAllocationList
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof AssetAllocationList
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum AssetClassCode {
  Eq = 'Eq',
  Ma = 'MA',
  Fi = 'FI',
  Mm = 'MM',
  Alt = 'Alt',
  P = 'P',
}

/**
 *
 * @export
 * @interface AssignFocusFundBody
 */
export interface AssignFocusFundBody {
  /**
   *
   * @type {string}
   * @memberof AssignFocusFundBody
   */
  bucketId: string;
  /**
   *
   * @type {string}
   * @memberof AssignFocusFundBody
   */
  isin: string;
  /**
   *
   * @type {boolean}
   * @memberof AssignFocusFundBody
   */
  isFeatured: boolean;
}
/**
 *
 * @export
 * @interface AuthUser
 */
export interface AuthUser {
  /**
   *
   * @type {string}
   * @memberof AuthUser
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AuthUser
   */
  given_name: string;
  /**
   *
   * @type {string}
   * @memberof AuthUser
   */
  family_name: string;
  /**
   *
   * @type {string}
   * @memberof AuthUser
   */
  user_id: string;
  /**
   *
   * @type {boolean}
   * @memberof AuthUser
   */
  email_verified?: boolean;
  /**
   *
   * @type {string}
   * @memberof AuthUser
   */
  email: string;
  /**
   *
   * @type {AuthUserMetadata}
   * @memberof AuthUser
   */
  user_metadata?: AuthUserMetadata;
}
/**
 *
 * @export
 * @interface AuthUserMetadata
 */
export interface AuthUserMetadata {
  /**
   *
   * @type {string}
   * @memberof AuthUserMetadata
   */
  tenant?: string;
  /**
   *
   * @type {string}
   * @memberof AuthUserMetadata
   */
  company?: string;
}
/**
 *
 * @export
 * @interface AutocompleteResponseSuggestionItem
 */
export interface AutocompleteResponseSuggestionItem {
  /**
   *
   * @type {SearchAutocompleteWithPortfoliosShareClassDetails}
   * @memberof AutocompleteResponseSuggestionItem
   */
  shareClassDetails: SearchAutocompleteWithPortfoliosShareClassDetails;
  /**
   *
   * @type {string}
   * @memberof AutocompleteResponseSuggestionItem
   */
  fundName: string;
  /**
   *
   * @type {FundBroadAssetClass}
   * @memberof AutocompleteResponseSuggestionItem
   */
  broadAssetClass: FundBroadAssetClass;
  /**
   *
   * @type {boolean}
   * @memberof AutocompleteResponseSuggestionItem
   */
  primaryShareClass?: boolean;
}
/**
 *
 * @export
 * @interface AutocompleteResponseSuggestionItemWithPortfolio
 */
export interface AutocompleteResponseSuggestionItemWithPortfolio {
  /**
   *
   * @type {string}
   * @memberof AutocompleteResponseSuggestionItemWithPortfolio
   */
  id: string;
  /**
   *
   * @type {SearchAutocompleteWithPortfoliosShareClassDetails}
   * @memberof AutocompleteResponseSuggestionItemWithPortfolio
   */
  shareClassDetails?: SearchAutocompleteWithPortfoliosShareClassDetails;
  /**
   *
   * @type {string}
   * @memberof AutocompleteResponseSuggestionItemWithPortfolio
   */
  label: string;
  /**
   *
   * @type {FundBroadAssetClass}
   * @memberof AutocompleteResponseSuggestionItemWithPortfolio
   */
  broadAssetClass?: FundBroadAssetClass;
  /**
   *
   * @type {boolean}
   * @memberof AutocompleteResponseSuggestionItemWithPortfolio
   */
  primaryShareClass?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AutocompleteResponseSuggestionItemWithPortfolio
   */
  isPortfolio?: boolean;
}
/**
 *
 * @export
 * @interface BenchmarkCountry
 */
export interface BenchmarkCountry {
  /**
   *
   * @type {string}
   * @memberof BenchmarkCountry
   */
  countryId: string;
  /**
   *
   * @type {number}
   * @memberof BenchmarkCountry
   */
  weighting: number;
}
/**
 *
 * @export
 * @interface BenchmarkDataSeries
 */
export interface BenchmarkDataSeries {
  /**
   *
   * @type {string}
   * @memberof BenchmarkDataSeries
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof BenchmarkDataSeries
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof BenchmarkDataSeries
   */
  secId?: string;
  /**
   *
   * @type {string}
   * @memberof BenchmarkDataSeries
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BenchmarkDataSeries
   */
  baseCurrency?: string;
  /**
   *
   * @type {number}
   * @memberof BenchmarkDataSeries
   */
  closePrice?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum BenchmarkGroup {
  Equity = 'Equity',
  Cash = 'Cash',
  Bonds = 'Bonds',
  Custom = 'Custom',
}

/**
 *
 * @export
 * @interface BenchmarkHolding
 */
export interface BenchmarkHolding {
  /**
   *
   * @type {string}
   * @memberof BenchmarkHolding
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof BenchmarkHolding
   */
  isin?: string;
  /**
   *
   * @type {string}
   * @memberof BenchmarkHolding
   */
  fundShareClassId: string;
  /**
   *
   * @type {number}
   * @memberof BenchmarkHolding
   */
  sector?: number;
  /**
   *
   * @type {string}
   * @memberof BenchmarkHolding
   */
  securityName: string;
  /**
   *
   * @type {number}
   * @memberof BenchmarkHolding
   */
  weighting?: number;
}
/**
 *
 * @export
 * @interface BenchmarkHoldingByTypeResponse
 */
export interface BenchmarkHoldingByTypeResponse {
  /**
   *
   * @type {Array<BenchmarkHoldingPartial>}
   * @memberof BenchmarkHoldingByTypeResponse
   */
  holdings: Array<BenchmarkHoldingPartial>;
  /**
   *
   * @type {Array<BenchmarkSector>}
   * @memberof BenchmarkHoldingByTypeResponse
   */
  sectors: Array<BenchmarkSector>;
  /**
   *
   * @type {Array<BenchmarkCountry>}
   * @memberof BenchmarkHoldingByTypeResponse
   */
  countries: Array<BenchmarkCountry>;
}
/**
 *
 * @export
 * @interface BenchmarkHoldingPartial
 */
export interface BenchmarkHoldingPartial {
  /**
   *
   * @type {string}
   * @memberof BenchmarkHoldingPartial
   */
  isin: string;
  /**
   *
   * @type {number}
   * @memberof BenchmarkHoldingPartial
   */
  weighting: number;
}
/**
 *
 * @export
 * @interface BenchmarkOption
 */
export interface BenchmarkOption {
  /**
   *
   * @type {string}
   * @memberof BenchmarkOption
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BenchmarkOption
   */
  secId?: string;
  /**
   *
   * @type {string}
   * @memberof BenchmarkOption
   */
  name: string;
  /**
   *
   * @type {BenchmarkSource}
   * @memberof BenchmarkOption
   */
  source: BenchmarkSource;
  /**
   *
   * @type {BenchmarkGroup}
   * @memberof BenchmarkOption
   */
  group: BenchmarkGroup;
  /**
   *
   * @type {boolean}
   * @memberof BenchmarkOption
   */
  shared?: boolean;
  /**
   *
   * @type {string}
   * @memberof BenchmarkOption
   */
  createdBy?: string;
}
/**
 *
 * @export
 * @interface BenchmarkOptionsResponse
 */
export interface BenchmarkOptionsResponse {
  /**
   *
   * @type {Array<BenchmarkOption>}
   * @memberof BenchmarkOptionsResponse
   */
  benchmarkOptions: Array<BenchmarkOption>;
}
/**
 *
 * @export
 * @interface BenchmarkSector
 */
export interface BenchmarkSector {
  /**
   *
   * @type {number}
   * @memberof BenchmarkSector
   */
  sector: number;
  /**
   *
   * @type {number}
   * @memberof BenchmarkSector
   */
  weighting: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum BenchmarkSource {
  Ami = 'AMI',
  Msci = 'MSCI',
  Morningstar = 'Morningstar',
}

/**
 *
 * @export
 * @interface BoardDiversity
 */
export interface BoardDiversity {
  /**
   *
   * @type {string}
   * @memberof BoardDiversity
   */
  independence?: string;
  /**
   *
   * @type {BoardDiversityAllOfGendersAmount}
   * @memberof BoardDiversity
   */
  gendersAmount?: BoardDiversityAllOfGendersAmount;
  /**
   *
   * @type {BoardDiversityAllOfEthnicitiesAmount}
   * @memberof BoardDiversity
   */
  ethnicitiesAmount?: BoardDiversityAllOfEthnicitiesAmount;
  /**
   *
   * @type {string}
   * @memberof BoardDiversity
   */
  independenceAsOfDate?: string;
  /**
   *
   * @type {string}
   * @memberof BoardDiversity
   */
  genderAsOfDate?: string;
  /**
   *
   * @type {string}
   * @memberof BoardDiversity
   */
  ethnicityAsOfDate?: string;
}
/**
 *
 * @export
 * @interface BoardDiversityAllOf
 */
export interface BoardDiversityAllOf {
  /**
   *
   * @type {string}
   * @memberof BoardDiversityAllOf
   */
  independence?: string;
  /**
   *
   * @type {BoardDiversityAllOfGendersAmount}
   * @memberof BoardDiversityAllOf
   */
  gendersAmount?: BoardDiversityAllOfGendersAmount;
  /**
   *
   * @type {BoardDiversityAllOfEthnicitiesAmount}
   * @memberof BoardDiversityAllOf
   */
  ethnicitiesAmount?: BoardDiversityAllOfEthnicitiesAmount;
  /**
   *
   * @type {string}
   * @memberof BoardDiversityAllOf
   */
  independenceAsOfDate?: string;
  /**
   *
   * @type {string}
   * @memberof BoardDiversityAllOf
   */
  genderAsOfDate?: string;
  /**
   *
   * @type {string}
   * @memberof BoardDiversityAllOf
   */
  ethnicityAsOfDate?: string;
}
/**
 *
 * @export
 * @interface BoardDiversityAllOfEthnicitiesAmount
 */
export interface BoardDiversityAllOfEthnicitiesAmount {
  /**
   *
   * @type {number}
   * @memberof BoardDiversityAllOfEthnicitiesAmount
   */
  white?: number;
  /**
   *
   * @type {number}
   * @memberof BoardDiversityAllOfEthnicitiesAmount
   */
  asian?: number;
  /**
   *
   * @type {number}
   * @memberof BoardDiversityAllOfEthnicitiesAmount
   */
  black?: number;
  /**
   *
   * @type {number}
   * @memberof BoardDiversityAllOfEthnicitiesAmount
   */
  mixed?: number;
  /**
   *
   * @type {number}
   * @memberof BoardDiversityAllOfEthnicitiesAmount
   */
  others?: number;
  /**
   *
   * @type {number}
   * @memberof BoardDiversityAllOfEthnicitiesAmount
   */
  preferNotToSay?: number;
}
/**
 *
 * @export
 * @interface BoardDiversityAllOfGendersAmount
 */
export interface BoardDiversityAllOfGendersAmount {
  /**
   *
   * @type {number}
   * @memberof BoardDiversityAllOfGendersAmount
   */
  male?: number;
  /**
   *
   * @type {number}
   * @memberof BoardDiversityAllOfGendersAmount
   */
  female?: number;
  /**
   *
   * @type {number}
   * @memberof BoardDiversityAllOfGendersAmount
   */
  nonBinary?: number;
}
/**
 *
 * @export
 * @interface BondMaturityRangeDetail
 */
export interface BondMaturityRangeDetail {
  /**
   *
   * @type {string}
   * @memberof BondMaturityRangeDetail
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof BondMaturityRangeDetail
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof BondMaturityRangeDetail
   */
  salePosition: string;
}
/**
 *
 * @export
 * @interface BondMaturityRangeDetail1
 */
export interface BondMaturityRangeDetail1 {
  /**
   *
   * @type {string}
   * @memberof BondMaturityRangeDetail1
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof BondMaturityRangeDetail1
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof BondMaturityRangeDetail1
   */
  salePosition: string;
  /**
   *
   * @type {string}
   * @memberof BondMaturityRangeDetail1
   */
  date: string;
}
/**
 *
 * @export
 * @interface BondStatisticsValues
 */
export interface BondStatisticsValues {
  /**
   *
   * @type {number}
   * @memberof BondStatisticsValues
   */
  styleBox?: number;
}
/**
 *
 * @export
 * @interface BondsBroadAssetClassValues
 */
export interface BondsBroadAssetClassValues {
  /**
   *
   * @type {number}
   * @memberof BondsBroadAssetClassValues
   */
  modifiedDuration?: number;
  /**
   *
   * @type {number}
   * @memberof BondsBroadAssetClassValues
   */
  yieldToMaturity?: number;
  /**
   *
   * @type {number}
   * @memberof BondsBroadAssetClassValues
   */
  averageCreditQuality?: number;
  /**
   *
   * @type {string}
   * @memberof BondsBroadAssetClassValues
   */
  averageCreditQualityDefinition?: string;
}
/**
 *
 * @export
 * @interface BroadAssetClassValues
 */
export interface BroadAssetClassValues {
  /**
   *
   * @type {BondsBroadAssetClassValues}
   * @memberof BroadAssetClassValues
   */
  bonds?: BondsBroadAssetClassValues;
  /**
   *
   * @type {number}
   * @memberof BroadAssetClassValues
   */
  styleBox?: number;
}
/**
 *
 * @export
 * @interface Bucket
 */
export interface Bucket {
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof Bucket
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof Bucket
   */
  updatedAt?: number;
  /**
   *
   * @type {Array<ResourceUser>}
   * @memberof Bucket
   */
  users?: Array<ResourceUser>;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  index: string;
  /**
   *
   * @type {Array<BucketRequestBodyFunds>}
   * @memberof Bucket
   */
  funds: Array<BucketRequestBodyFunds>;
  /**
   *
   * @type {string}
   * @memberof Bucket
   */
  indexSecId?: string;
}
/**
 *
 * @export
 * @interface BucketBase
 */
export interface BucketBase {
  /**
   *
   * @type {string}
   * @memberof BucketBase
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof BucketBase
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof BucketBase
   */
  updatedAt?: number;
  /**
   *
   * @type {Array<ResourceUser>}
   * @memberof BucketBase
   */
  users?: Array<ResourceUser>;
}
/**
 *
 * @export
 * @interface BucketRequestBody
 */
export interface BucketRequestBody {
  /**
   *
   * @type {string}
   * @memberof BucketRequestBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BucketRequestBody
   */
  index: string;
  /**
   *
   * @type {Array<BucketRequestBodyFunds>}
   * @memberof BucketRequestBody
   */
  funds: Array<BucketRequestBodyFunds>;
  /**
   *
   * @type {string}
   * @memberof BucketRequestBody
   */
  indexSecId?: string;
}
/**
 *
 * @export
 * @interface BucketRequestBodyFunds
 */
export interface BucketRequestBodyFunds {
  /**
   *
   * @type {string}
   * @memberof BucketRequestBodyFunds
   */
  isin: string;
  /**
   *
   * @type {string}
   * @memberof BucketRequestBodyFunds
   */
  fundName: string;
  /**
   *
   * @type {string}
   * @memberof BucketRequestBodyFunds
   */
  shareClassDetailsCode: string;
  /**
   *
   * @type {string}
   * @memberof BucketRequestBodyFunds
   */
  fundId: string;
  /**
   *
   * @type {string}
   * @memberof BucketRequestBodyFunds
   */
  msBenchmarkId?: string;
  /**
   *
   * @type {boolean}
   * @memberof BucketRequestBodyFunds
   */
  isFeatured?: boolean;
  /**
   *
   * @type {string}
   * @memberof BucketRequestBodyFunds
   */
  broadAssetClass: string;
  /**
   *
   * @type {string}
   * @memberof BucketRequestBodyFunds
   */
  legalStructure: string;
}
/**
 *
 * @export
 * @interface BucketUpdateRequestBody
 */
export interface BucketUpdateRequestBody {
  /**
   *
   * @type {string}
   * @memberof BucketUpdateRequestBody
   */
  name?: string;
  /**
   *
   * @type {Array<BucketUpdateRequestBodyFunds>}
   * @memberof BucketUpdateRequestBody
   */
  funds?: Array<BucketUpdateRequestBodyFunds>;
  /**
   *
   * @type {string}
   * @memberof BucketUpdateRequestBody
   */
  indexSecId?: string;
}
/**
 *
 * @export
 * @interface BucketUpdateRequestBodyFunds
 */
export interface BucketUpdateRequestBodyFunds {
  /**
   *
   * @type {string}
   * @memberof BucketUpdateRequestBodyFunds
   */
  isin: string;
  /**
   *
   * @type {string}
   * @memberof BucketUpdateRequestBodyFunds
   */
  fundName: string;
  /**
   *
   * @type {string}
   * @memberof BucketUpdateRequestBodyFunds
   */
  msBenchmarkId?: string;
  /**
   *
   * @type {boolean}
   * @memberof BucketUpdateRequestBodyFunds
   */
  isFeatured?: boolean;
}
/**
 *
 * @export
 * @interface CalendarYearPerformancesPcl
 */
export interface CalendarYearPerformancesPcl {
  /**
   *
   * @type {Array<PerformanceBase>}
   * @memberof CalendarYearPerformancesPcl
   */
  calendarYearPerformancesPcl?: Array<PerformanceBase>;
}
/**
 *
 * @export
 * @interface Categories
 */
export interface Categories {
  /**
   *
   * @type {string}
   * @memberof Categories
   */
  label: string;
}
/**
 *
 * @export
 * @interface CategoryMetadata
 */
export interface CategoryMetadata {
  /**
   *
   * @type {number}
   * @memberof CategoryMetadata
   */
  categoryReturnOpenEndedM0?: number;
  /**
   *
   * @type {number}
   * @memberof CategoryMetadata
   */
  categoryReturnOpenEndedM3?: number;
  /**
   *
   * @type {number}
   * @memberof CategoryMetadata
   */
  categoryReturnOpenEndedM6?: number;
  /**
   *
   * @type {number}
   * @memberof CategoryMetadata
   */
  categoryReturnOpenEndedM12?: number;
  /**
   *
   * @type {number}
   * @memberof CategoryMetadata
   */
  categoryReturnOpenEndedM36?: number;
  /**
   *
   * @type {number}
   * @memberof CategoryMetadata
   */
  categoryReturnOpenEndedM60?: number;
}
/**
 *
 * @export
 * @interface ChangeBucketOrderPayload
 */
export interface ChangeBucketOrderPayload {
  /**
   *
   * @type {Array<string>}
   * @memberof ChangeBucketOrderPayload
   */
  bucketIds: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ChangeBucketOrderPayload
   */
  watchlistId: string;
}
/**
 *
 * @export
 * @interface ChartDataItem
 */
export interface ChartDataItem {
  /**
   * id - unique identifier
   * @type {string}
   * @memberof ChartDataItem
   */
  i?: string;
  /**
   * label - matching label by the  \'i\' property
   * @type {string}
   * @memberof ChartDataItem
   */
  l?: string;
  /**
   * tooltip - matching tooltip by the \'i\' property
   * @type {string}
   * @memberof ChartDataItem
   */
  t?: string;
  /**
   * featured - true/false if the fund is featured
   * @type {boolean}
   * @memberof ChartDataItem
   */
  f?: boolean;
  /**
   * benchmark - true/false if the fund is a benchmark
   * @type {boolean}
   * @memberof ChartDataItem
   */
  b?: boolean;
  /**
   * points - array of points which is a value and date pair
   * @type {Array<ChartDataItemPoint>}
   * @memberof ChartDataItem
   */
  p?: Array<ChartDataItemPoint>;
}
/**
 *
 * @export
 * @interface ChartDataItemPoint
 */
export interface ChartDataItemPoint {
  /**
   * date - iso string
   * @type {string}
   * @memberof ChartDataItemPoint
   */
  d: string;
  /**
   * value - number/float
   * @type {number}
   * @memberof ChartDataItemPoint
   */
  v: number;
}
/**
 *
 * @export
 * @interface ChartDataSeries
 */
export interface ChartDataSeries {
  /**
   *
   * @type {string}
   * @memberof ChartDataSeries
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof ChartDataSeries
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof ChartDataSeries
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof ChartDataSeries
   */
  tooltip?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChartDataSeries
   */
  isBenchmark?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChartDataSeries
   */
  isFeatured?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChartDataSeries
   */
  isin?: string;
  /**
   *
   * @type {string}
   * @memberof ChartDataSeries
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ChartDataSeriesWithPortfolio
 */
export interface ChartDataSeriesWithPortfolio {
  /**
   *
   * @type {string}
   * @memberof ChartDataSeriesWithPortfolio
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof ChartDataSeriesWithPortfolio
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof ChartDataSeriesWithPortfolio
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof ChartDataSeriesWithPortfolio
   */
  tooltip?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChartDataSeriesWithPortfolio
   */
  isBenchmark?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChartDataSeriesWithPortfolio
   */
  isFeatured?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChartDataSeriesWithPortfolio
   */
  isin?: string;
  /**
   *
   * @type {string}
   * @memberof ChartDataSeriesWithPortfolio
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChartDataSeriesWithPortfolio
   */
  isPortfolio?: boolean;
}
/**
 *
 * @export
 * @interface ChartV2DataResponse
 */
export interface ChartV2DataResponse {
  /**
   *
   * @type {Array<ChartDataItem>}
   * @memberof ChartV2DataResponse
   */
  data: Array<ChartDataItem>;
  /**
   *
   * @type {Array<ChartV2DataResponseLegendData>}
   * @memberof ChartV2DataResponse
   */
  legendData?: Array<ChartV2DataResponseLegendData>;
}
/**
 *
 * @export
 * @interface ChartV2DataResponseLegendData
 */
export interface ChartV2DataResponseLegendData {
  /**
   *
   * @type {string}
   * @memberof ChartV2DataResponseLegendData
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ChartV2DataResponseLegendData
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof ChartV2DataResponseLegendData
   */
  value?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChartV2DataResponseLegendData
   */
  isBenchmark?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChartV2DataResponseLegendData
   */
  isFeatured?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChartV2DataResponseLegendData
   */
  isin?: string;
}
/**
 *
 * @export
 * @interface ChartV2DataResponseWithPortfolio
 */
export interface ChartV2DataResponseWithPortfolio {
  /**
   *
   * @type {Array<ChartDataItem>}
   * @memberof ChartV2DataResponseWithPortfolio
   */
  data: Array<ChartDataItem>;
  /**
   *
   * @type {Array<ChartV2DataResponseWithPortfolioLegendData>}
   * @memberof ChartV2DataResponseWithPortfolio
   */
  legendData?: Array<ChartV2DataResponseWithPortfolioLegendData>;
}
/**
 *
 * @export
 * @interface ChartV2DataResponseWithPortfolioLegendData
 */
export interface ChartV2DataResponseWithPortfolioLegendData {
  /**
   *
   * @type {string}
   * @memberof ChartV2DataResponseWithPortfolioLegendData
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ChartV2DataResponseWithPortfolioLegendData
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof ChartV2DataResponseWithPortfolioLegendData
   */
  value?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChartV2DataResponseWithPortfolioLegendData
   */
  isBenchmark?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChartV2DataResponseWithPortfolioLegendData
   */
  isFeatured?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChartV2DataResponseWithPortfolioLegendData
   */
  isin?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChartV2DataResponseWithPortfolioLegendData
   */
  isPortfolio?: boolean;
}
/**
 *
 * @export
 * @interface ClosePrice
 */
export interface ClosePrice {
  /**
   *
   * @type {number}
   * @memberof ClosePrice
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof ClosePrice
   */
  currencyId: string;
  /**
   *
   * @type {string}
   * @memberof ClosePrice
   */
  date: string;
}
/**
 *
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  _id: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  fundId: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  updatedBy?: string;
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  _createdAt: number;
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  _updatedAt: number;
}
/**
 *
 * @export
 * @interface CommentBody
 */
export interface CommentBody {
  /**
   *
   * @type {string}
   * @memberof CommentBody
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof CommentBody
   */
  fundName: string;
}
/**
 *
 * @export
 * @interface CompositeBenchmark
 */
export interface CompositeBenchmark {
  /**
   *
   * @type {string}
   * @memberof CompositeBenchmark
   */
  _id: string;
  /**
   *
   * @type {string}
   * @memberof CompositeBenchmark
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CompositeBenchmark
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof CompositeBenchmark
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof CompositeBenchmark
   */
  updatedBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof CompositeBenchmark
   */
  shared: boolean;
  /**
   *
   * @type {Array<CompositeBenchmarkItem>}
   * @memberof CompositeBenchmark
   */
  benchmarkItems: Array<CompositeBenchmarkItem>;
}
/**
 *
 * @export
 * @interface CompositeBenchmarkBody
 */
export interface CompositeBenchmarkBody {
  /**
   *
   * @type {string}
   * @memberof CompositeBenchmarkBody
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof CompositeBenchmarkBody
   */
  shared?: boolean;
  /**
   *
   * @type {Array<CompositeBenchmarkItem>}
   * @memberof CompositeBenchmarkBody
   */
  benchmarkItems: Array<CompositeBenchmarkItem>;
}
/**
 *
 * @export
 * @interface CompositeBenchmarkItem
 */
export interface CompositeBenchmarkItem {
  /**
   *
   * @type {string}
   * @memberof CompositeBenchmarkItem
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CompositeBenchmarkItem
   */
  secId: string;
  /**
   *
   * @type {number}
   * @memberof CompositeBenchmarkItem
   */
  weighting?: number;
}
/**
 *
 * @export
 * @interface CompositeBenchmarksResponse
 */
export interface CompositeBenchmarksResponse {
  /**
   *
   * @type {Array<CompositeBenchmark>}
   * @memberof CompositeBenchmarksResponse
   */
  compositeBenchmarks: Array<CompositeBenchmark>;
}
/**
 *
 * @export
 * @interface CorrelationMatrixResponse
 */
export interface CorrelationMatrixResponse {
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof CorrelationMatrixResponse
   */
  rawMatrix?: Array<Array<string>>;
  /**
   *
   * @type {Array<string>}
   * @memberof CorrelationMatrixResponse
   */
  rawIsinByIndex?: Array<string>;
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof CorrelationMatrixResponse
   */
  matrix?: Array<Array<number>>;
  /**
   *
   * @type {Array<string>}
   * @memberof CorrelationMatrixResponse
   */
  emptyIsins?: Array<string>;
  /**
   *
   * @type {CorrelationMatrixResponseQueriedDateRange}
   * @memberof CorrelationMatrixResponse
   */
  queriedDateRange?: CorrelationMatrixResponseQueriedDateRange;
}
/**
 *
 * @export
 * @interface CorrelationMatrixResponseQueriedDateRange
 */
export interface CorrelationMatrixResponseQueriedDateRange {
  /**
   *
   * @type {string}
   * @memberof CorrelationMatrixResponseQueriedDateRange
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof CorrelationMatrixResponseQueriedDateRange
   */
  to?: string;
}
/**
 *
 * @export
 * @interface CountUnseenNotificationsResponse
 */
export interface CountUnseenNotificationsResponse {
  /**
   *
   * @type {number}
   * @memberof CountUnseenNotificationsResponse
   */
  count: number;
}
/**
 *
 * @export
 * @interface Currencies
 */
export interface Currencies {
  /**
   *
   * @type {string}
   * @memberof Currencies
   */
  label: string;
}
/**
 *
 * @export
 * @interface CurrencyExposure
 */
export interface CurrencyExposure {
  /**
   *
   * @type {string}
   * @memberof CurrencyExposure
   */
  currencyId: string;
  /**
   *
   * @type {number}
   * @memberof CurrencyExposure
   */
  value: number;
}
/**
 *
 * @export
 * @interface DashboardSummaryCardItem
 */
export interface DashboardSummaryCardItem {
  /**
   *
   * @type {SummaryCardType}
   * @memberof DashboardSummaryCardItem
   */
  cardType: SummaryCardType;
  /**
   *
   * @type {string}
   * @memberof DashboardSummaryCardItem
   */
  fundName: string;
  /**
   *
   * @type {number}
   * @memberof DashboardSummaryCardItem
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof DashboardSummaryCardItem
   */
  fundIsin: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum DataSourceType {
  Padi = 'PADI',
  Morningstar = 'Morningstar',
}

/**
 *
 * @export
 * @interface Domiciles
 */
export interface Domiciles {
  /**
   *
   * @type {string}
   * @memberof Domiciles
   */
  label: string;
}
/**
 *
 * @export
 * @interface EquityStatsDetail
 */
export interface EquityStatsDetail {
  /**
   *
   * @type {number}
   * @memberof EquityStatsDetail
   */
  dividendYield?: number;
  /**
   *
   * @type {number}
   * @memberof EquityStatsDetail
   */
  netMargin?: number;
  /**
   *
   * @type {number}
   * @memberof EquityStatsDetail
   */
  past3YearEarningsGrowth?: number;
  /**
   *
   * @type {number}
   * @memberof EquityStatsDetail
   */
  roe?: number;
  /**
   *
   * @type {number}
   * @memberof EquityStatsDetail
   */
  roic?: number;
}
/**
 *
 * @export
 * @interface ExploreFiltersItemsSchema
 */
export interface ExploreFiltersItemsSchema {
  /**
   *
   * @type {Array<SfdrFilterItemSchema>}
   * @memberof ExploreFiltersItemsSchema
   */
  sfdr: Array<SfdrFilterItemSchema>;
  /**
   *
   * @type {Array<ShareActionSurveyRatingSchema>}
   * @memberof ExploreFiltersItemsSchema
   */
  shareActionSurveyRatings: Array<ShareActionSurveyRatingSchema>;
  /**
   *
   * @type {Array<Categories>}
   * @memberof ExploreFiltersItemsSchema
   */
  categories: Array<Categories>;
  /**
   *
   * @type {Array<IaSectors>}
   * @memberof ExploreFiltersItemsSchema
   */
  iaSectors: Array<IaSectors>;
  /**
   *
   * @type {Array<ProviderNames>}
   * @memberof ExploreFiltersItemsSchema
   */
  providerNames?: Array<ProviderNames>;
  /**
   *
   * @type {Array<Domiciles>}
   * @memberof ExploreFiltersItemsSchema
   */
  domiciles: Array<Domiciles>;
  /**
   *
   * @type {Array<Currencies>}
   * @memberof ExploreFiltersItemsSchema
   */
  currencies: Array<Currencies>;
  /**
   *
   * @type {Array<Platforms>}
   * @memberof ExploreFiltersItemsSchema
   */
  platforms: Array<Platforms>;
}
/**
 *
 * @export
 * @interface ExploreSearchQueryBody
 */
export interface ExploreSearchQueryBody {
  /**
   * The current page number
   * @type {number}
   * @memberof ExploreSearchQueryBody
   */
  page?: number;
  /**
   * The number of items to return
   * @type {number}
   * @memberof ExploreSearchQueryBody
   */
  size?: number;
  /**
   * Search term for partial match
   * @type {string}
   * @memberof ExploreSearchQueryBody
   */
  term?: string;
  /**
   * Key that the respose will be sorted by
   * @type {string}
   * @memberof ExploreSearchQueryBody
   */
  sortKey?: string;
  /**
   * Order direction
   * @type {string}
   * @memberof ExploreSearchQueryBody
   */
  sortDirection?: string;
  /**
   * Fund Size range
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  fundSize?: Array<number>;
  /**
   * Sfdr filter condition
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  sfdr?: Array<string>;
  /**
   * Sector filter condition
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  categories?: Array<string>;
  /**
   * IA Sectors filter condition
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  iaSectors?: Array<string>;
  /**
   * Unpri Dates, passed in pairs of years, e.g. 2019-2020
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  unpriDates?: Array<string>;
  /**
   * ACT Dates, passed in pairs of years, e.g. 2019-2020
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  actDates?: Array<string>;
  /**
   * TCFD Dates, passed in pairs of years, e.g. 2019-2020
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  tcfdDates?: Array<string>;
  /**
   * Share action ratings
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  shareActionRatings?: Array<string>;
  /**
   * yieldRange range 0 - 6%
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  yieldRange?: Array<number>;
  /**
   * Management Group Name
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  managementGroup?: Array<string>;
  /**
   * noOfHoldings range 0 - 150+
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  noOfHoldingsRange?: Array<number>;
  /**
   * Broad asset classes
   * @type {Array<FundBroadAssetClass>}
   * @memberof ExploreSearchQueryBody
   */
  broadAssetClasses?: Array<FundBroadAssetClass>;
  /**
   * Asset class codes
   * @type {Array<AssetClassCode>}
   * @memberof ExploreSearchQueryBody
   */
  assetClassCode?: Array<AssetClassCode>;
  /**
   *
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  legalStructure?: Array<string>;
  /**
   * OCF range
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  ocf?: Array<number>;
  /**
   *
   * @type {YesNoOption}
   * @memberof ExploreSearchQueryBody
   */
  stewardshipCode?: YesNoOption;
  /**
   *
   * @type {YesNoOption}
   * @memberof ExploreSearchQueryBody
   */
  netZeroAssetManager?: YesNoOption;
  /**
   * F4B Dates, passed in pairs of years, e.g. 2019-2020
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  financeForBiodiversityDates?: Array<string>;
  /**
   * relativeRiskMeasuresDetail beta property
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  betaRange?: Array<number>;
  /**
   * relativeRiskMeasuresDetail captureRatioTypeOne property
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  upsideRange?: Array<number>;
  /**
   * relativeRiskMeasuresDetail captureRatioTypeTwo property
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  downsideRange?: Array<number>;
  /**
   * relativeRiskMeasuresDetail trackingError property
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  trackingErrorRange?: Array<number>;
  /**
   * riskMeasuresDetail standardDeviation property
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  stdDeviationRange?: Array<number>;
  /**
   * riskMeasuresDetail maximumDrawdown property
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  maximumDrawdownRange?: Array<number>;
  /**
   * riskMeasuresDetail sortinoRatio property
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  sortinoRatioRange?: Array<number>;
  /**
   * Yes or No - filters if fund has oldest share class only
   * @type {boolean}
   * @memberof ExploreSearchQueryBody
   */
  filterByOldestShareClassOnly?: boolean;
  /**
   * Index Fund checks if fund is active or passive
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  indexFund?: Array<string>;
  /**
   * Equity Style Box
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  equityStyle?: Array<number>;
  /**
   * ROIC Range
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  roicRange?: Array<number>;
  /**
   * Net Margin Range
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  netMarginRange?: Array<number>;
  /**
   * Bond Style Box
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  bondStyle?: Array<number>;
  /**
   * Modified Duration Range
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  modifiedDurationRange?: Array<number>;
  /**
   * Yield Maturity Range
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  yieldMaturityRange?: Array<number>;
  /**
   * Average Credit Quality Range
   * @type {Array<number>}
   * @memberof ExploreSearchQueryBody
   */
  avgCreditQualityRange?: Array<number>;
  /**
   * Domicile
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  domiciles?: Array<string>;
  /**
   * Currency
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  currencies?: Array<string>;
  /**
   * Platform
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  platforms?: Array<string>;
  /**
   * Manager Gender
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  genders?: Array<string>;
  /**
   * Currency Hedged Type
   * @type {Array<string>}
   * @memberof ExploreSearchQueryBody
   */
  currencyHedgedType?: Array<string>;
  /**
   * Year Drop Down
   * @type {string}
   * @memberof ExploreSearchQueryBody
   */
  yearDropDown?: string;
}
/**
 *
 * @export
 * @interface ExploreSearchResponse
 */
export interface ExploreSearchResponse {
  /**
   *
   * @type {number}
   * @memberof ExploreSearchResponse
   */
  totalCount?: number;
  /**
   *
   * @type {Array<FundExploreItem>}
   * @memberof ExploreSearchResponse
   */
  data?: Array<FundExploreItem>;
}
/**
 *
 * @export
 * @interface ExploreSearchResponseShareActionSurvey
 */
export interface ExploreSearchResponseShareActionSurvey {
  /**
   *
   * @type {number}
   * @memberof ExploreSearchResponseShareActionSurvey
   */
  ranking?: number;
  /**
   *
   * @type {string}
   * @memberof ExploreSearchResponseShareActionSurvey
   */
  rating?: string;
  /**
   *
   * @type {string}
   * @memberof ExploreSearchResponseShareActionSurvey
   */
  responsibleInvestmentGovernance?: string;
  /**
   *
   * @type {string}
   * @memberof ExploreSearchResponseShareActionSurvey
   */
  climateChange?: string;
  /**
   *
   * @type {string}
   * @memberof ExploreSearchResponseShareActionSurvey
   */
  stewardship?: string;
  /**
   *
   * @type {string}
   * @memberof ExploreSearchResponseShareActionSurvey
   */
  biodiversity?: string;
}
/**
 *
 * @export
 * @interface ExploreSearchResponseYield12Month
 */
export interface ExploreSearchResponseYield12Month {
  /**
   *
   * @type {number}
   * @memberof ExploreSearchResponseYield12Month
   */
  value?: number;
  /**
   *
   * @type {string}
   * @memberof ExploreSearchResponseYield12Month
   */
  date?: string;
}
/**
 *
 * @export
 * @interface Fund
 */
export interface Fund {
  /**
   *
   * @type {FundBaseShareClassDetails}
   * @memberof Fund
   */
  shareClassDetails: FundBaseShareClassDetails;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  fundId: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  fundName: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  aiGeneratedDescription?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  legalStructure: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  msCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  msGlobalCategoryId?: string;
  /**
   *
   * @type {Array<FundBasePerformanceIdDetails>}
   * @memberof Fund
   */
  performanceIdDetails: Array<FundBasePerformanceIdDetails>;
  /**
   *
   * @type {Array<ItemsSectorDetail>}
   * @memberof Fund
   */
  sectors: Array<ItemsSectorDetail>;
  /**
   *
   * @type {Array<SectorItem>}
   * @memberof Fund
   */
  sectorsEquity?: Array<SectorItem>;
  /**
   *
   * @type {Array<SectorItem>}
   * @memberof Fund
   */
  sectorsFixedIncome?: Array<SectorItem>;
  /**
   *
   * @type {Array<MarketCapBase>}
   * @memberof Fund
   */
  marketCapsLong?: Array<MarketCapBase>;
  /**
   *
   * @type {Array<PerformanceBase>}
   * @memberof Fund
   */
  annualisedPerformancesPcl?: Array<PerformanceBase>;
  /**
   *
   * @type {Array<PerformanceBase>}
   * @memberof Fund
   */
  calendarYearPerformancesPcl?: Array<PerformanceBase>;
  /**
   *
   * @type {Array<GeographyItem>}
   * @memberof Fund
   */
  countriesEquity?: Array<GeographyItem>;
  /**
   *
   * @type {Array<GeographyItem>}
   * @memberof Fund
   */
  countriesFixedIncome?: Array<GeographyItem>;
  /**
   *
   * @type {Array<GeographyItem>}
   * @memberof Fund
   */
  geographyPortfolio?: Array<GeographyItem>;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  assetClassCode: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  msBenchmarkId?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  shareClassMsId?: string;
  /**
   *
   * @type {FundBroadAssetClass}
   * @memberof Fund
   */
  broadAssetClass: FundBroadAssetClass;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  msCategoryDefinition: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  iaSector?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  performanceFeeCharged?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  financialYearEnd?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  domicile?: string;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturnYtdMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn3MonthMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn6MonthMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn1YearMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn3YearMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn5YearMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturnYtdPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturnYtdPct: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn1MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn3MonthPct: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn6MonthPct: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn1YearPct: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn3YearPct: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn5YearPct: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn1MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn3MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn6MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn1YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn3YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn5YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn1calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn2calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn3calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn4calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  sharePriceReturn5calendarYearsAgoPct?: number;
  /**
   *
   * @type {Array<FundPlatformsEnum>}
   * @memberof Fund
   */
  platforms?: Array<FundPlatformsEnum>;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  portfolioUpdateDateMs: string;
  /**
   *
   * @type {boolean}
   * @memberof Fund
   */
  primaryShareClass?: boolean;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  noOfHoldingsDate?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  portfolioHoldingsListUpdateDate?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  portfolioSectorUpdateDate?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  portfolioGeographyUpdateDate?: string;
  /**
   *
   * @type {DataSourceType}
   * @memberof Fund
   */
  portfolioDataSource?: DataSourceType;
  /**
   *
   * @type {BoardDiversity}
   * @memberof Fund
   */
  boardDiversity?: BoardDiversity;
  /**
   *
   * @type {Array<FundPrimaryBenchmark>}
   * @memberof Fund
   */
  primaryBenchmark?: Array<FundPrimaryBenchmark>;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  primaryBenchmarkFormatted?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  providerName?: string;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  navCumFair?: number;
  /**
   *
   * @type {Array<FundManagementFee>}
   * @memberof Fund
   */
  managementFee: Array<FundManagementFee>;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  performanceFee?: number;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  currencyCode?: string;
  /**
   *
   * @type {Array<FundCutOffPoint>}
   * @memberof Fund
   */
  cutOffPoint: Array<FundCutOffPoint>;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  netGearingCum?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  actualManagementFee?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  fundSizeUSD: number;
  /**
   *
   * @type {Array<TrustBoardDirectors>}
   * @memberof Fund
   */
  boardDirectors?: Array<TrustBoardDirectors>;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn1calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn2calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn3calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn4calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn5calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn1calendarYearsAgoCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn2calendarYearsAgoCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn3calendarYearsAgoCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn4calendarYearsAgoCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  totalReturn5calendarYearsAgoCategoryPcl?: number;
  /**
   *
   * @type {Array<FundRiskMeasuresDetail>}
   * @memberof Fund
   */
  riskMeasuresDetail?: Array<FundRiskMeasuresDetail>;
  /**
   *
   * @type {Array<FundRelativeRiskMeasuresDetail>}
   * @memberof Fund
   */
  relativeRiskMeasuresDetail?: Array<FundRelativeRiskMeasuresDetail>;
  /**
   *
   * @type {Array<ScatterPointRiskMeasuresDetails>}
   * @memberof Fund
   */
  scatterPointRiskMeasuresDetails?: Array<ScatterPointRiskMeasuresDetails>;
  /**
   *
   * @type {FundBaseBondStatistics}
   * @memberof Fund
   */
  bondStatistics?: FundBaseBondStatistics;
  /**
   *
   * @type {Array<BondMaturityRangeDetail>}
   * @memberof Fund
   */
  bondMaturityRange?: Array<BondMaturityRangeDetail>;
  /**
   *
   * @type {Array<BondMaturityRangeDetail1>}
   * @memberof Fund
   */
  bondCreditQualityBreakdown?: Array<BondMaturityRangeDetail1>;
  /**
   *
   * @type {Array<CurrencyExposure>}
   * @memberof Fund
   */
  currencyExposures?: Array<CurrencyExposure>;
  /**
   *
   * @type {EquityStatsDetail}
   * @memberof Fund
   */
  equityStats?: EquityStatsDetail;
  /**
   *
   * @type {BroadAssetClassValues}
   * @memberof Fund
   */
  broadAssetClassValues?: BroadAssetClassValues;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  equityStyleBox?: number;
  /**
   *
   * @type {Array<AssetAllocationItem>}
   * @memberof Fund
   */
  assetAllocation?: Array<AssetAllocationItem>;
  /**
   *
   * @type {FundFees}
   * @memberof Fund
   */
  fundFees?: FundFees;
  /**
   *
   * @type {Array<ManagerItem>}
   * @memberof Fund
   */
  managerList: Array<ManagerItem>;
  /**
   *
   * @type {ClosePrice}
   * @memberof Fund
   */
  closePrice: ClosePrice;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  noOfHoldings: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  shareClassFundTurnoverRatio?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  ocfPct: number;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  ocfDate: string;
  /**
   *
   * @type {Array<HoldingDetail>}
   * @memberof Fund
   */
  holdingDetail?: Array<HoldingDetail>;
  /**
   *
   * @type {FundSize}
   * @memberof Fund
   */
  fundSize?: FundSize;
  /**
   *
   * @type {PremiumDiscount}
   * @memberof Fund
   */
  premiumDiscount?: PremiumDiscount;
  /**
   *
   * @type {FundAllOf2Yield12Month}
   * @memberof Fund
   */
  yield12Month?: FundAllOf2Yield12Month;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  incomeFrequency?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  exDividendDate?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  financeForBiodiversityDate?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  stewardshipCode?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  netZeroAssetManagers?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  sfdrClass?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  tcfdDate?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  unpriDate?: string;
  /**
   *
   * @type {string}
   * @memberof Fund
   */
  actDate?: string;
  /**
   *
   * @type {ShareActionSurvey}
   * @memberof Fund
   */
  shareActionSurvey?: ShareActionSurvey;
  /**
   *
   * @type {GenderPayGap}
   * @memberof Fund
   */
  genderPayGap?: GenderPayGap;
  /**
   *
   * @type {GenderPayGapStats}
   * @memberof Fund
   */
  genderPayGapStats?: GenderPayGapStats;
  /**
   *
   * @type {Array<FundGeographyCountry>}
   * @memberof Fund
   */
  countriesLongPctEquity: Array<FundGeographyCountry>;
  /**
   *
   * @type {Array<FundGeographyCountry>}
   * @memberof Fund
   */
  countriesLongPctFixedIncome: Array<FundGeographyCountry>;
  /**
   *
   * @type {Array<FundGeographyCountry>}
   * @memberof Fund
   */
  countriesShortPctEquity: Array<FundGeographyCountry>;
  /**
   *
   * @type {Array<FundGeographyCountry>}
   * @memberof Fund
   */
  countriesShortPctFixedIncome: Array<FundGeographyCountry>;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaLongPctEquity?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaLongPctBond?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaLongPctPreferred?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaLongPctConvertible?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaLongPctCash?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaLongPctOther?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaShortPctBond?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaShortPctCash?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaShortPctConvertible?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaShortPctEquity?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaShortPctOther?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  aaShortPctPreferred?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  marketCapLongPctGiant?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  marketCapLongPctLarge?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  marketCapLongPctMedium?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  marketCapLongPctSmall?: number;
  /**
   *
   * @type {number}
   * @memberof Fund
   */
  marketCapLongPctMicro?: number;
}
/**
 *
 * @export
 * @interface FundAllOf
 */
export interface FundAllOf {
  /**
   *
   * @type {Array<ManagerItem>}
   * @memberof FundAllOf
   */
  managerList: Array<ManagerItem>;
  /**
   *
   * @type {ClosePrice}
   * @memberof FundAllOf
   */
  closePrice: ClosePrice;
  /**
   *
   * @type {number}
   * @memberof FundAllOf
   */
  noOfHoldings: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf
   */
  shareClassFundTurnoverRatio?: number;
  /**
   *
   * @type {string}
   * @memberof FundAllOf
   */
  portfolioUpdateDateMs: string;
  /**
   *
   * @type {number}
   * @memberof FundAllOf
   */
  ocfPct: number;
  /**
   *
   * @type {string}
   * @memberof FundAllOf
   */
  ocfDate: string;
  /**
   *
   * @type {Array<HoldingDetail>}
   * @memberof FundAllOf
   */
  holdingDetail?: Array<HoldingDetail>;
  /**
   *
   * @type {FundSize}
   * @memberof FundAllOf
   */
  fundSize?: FundSize;
  /**
   *
   * @type {PremiumDiscount}
   * @memberof FundAllOf
   */
  premiumDiscount?: PremiumDiscount;
}
/**
 *
 * @export
 * @interface FundAllOf1
 */
export interface FundAllOf1 {
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturnYtdPct: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn1MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn3MonthPct: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn6MonthPct: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn1YearPct: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn3YearPct: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn5YearPct: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturnYtdMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn3MonthMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn6MonthMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn1YearMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn3YearMorningstarCategoryPcl: number;
  /**
   *
   * @type {number}
   * @memberof FundAllOf1
   */
  totalReturn5YearMorningstarCategoryPcl: number;
}
/**
 *
 * @export
 * @interface FundAllOf2
 */
export interface FundAllOf2 {
  /**
   *
   * @type {FundAllOf2Yield12Month}
   * @memberof FundAllOf2
   */
  yield12Month?: FundAllOf2Yield12Month;
  /**
   *
   * @type {string}
   * @memberof FundAllOf2
   */
  incomeFrequency?: string;
  /**
   *
   * @type {string}
   * @memberof FundAllOf2
   */
  exDividendDate?: string;
}
/**
 *
 * @export
 * @interface FundAllOf2Yield12Month
 */
export interface FundAllOf2Yield12Month {
  /**
   *
   * @type {number}
   * @memberof FundAllOf2Yield12Month
   */
  value?: number;
  /**
   *
   * @type {string}
   * @memberof FundAllOf2Yield12Month
   */
  date?: string;
}
/**
 *
 * @export
 * @interface FundAllOf3
 */
export interface FundAllOf3 {
  /**
   *
   * @type {string}
   * @memberof FundAllOf3
   */
  financeForBiodiversityDate?: string;
  /**
   *
   * @type {string}
   * @memberof FundAllOf3
   */
  stewardshipCode?: string;
  /**
   *
   * @type {string}
   * @memberof FundAllOf3
   */
  netZeroAssetManagers?: string;
  /**
   *
   * @type {string}
   * @memberof FundAllOf3
   */
  sfdrClass?: string;
  /**
   *
   * @type {string}
   * @memberof FundAllOf3
   */
  tcfdDate?: string;
  /**
   *
   * @type {string}
   * @memberof FundAllOf3
   */
  unpriDate?: string;
  /**
   *
   * @type {string}
   * @memberof FundAllOf3
   */
  actDate?: string;
  /**
   *
   * @type {ShareActionSurvey}
   * @memberof FundAllOf3
   */
  shareActionSurvey?: ShareActionSurvey;
}
/**
 *
 * @export
 * @interface FundAllOf4
 */
export interface FundAllOf4 {
  /**
   *
   * @type {GenderPayGap}
   * @memberof FundAllOf4
   */
  genderPayGap?: GenderPayGap;
  /**
   *
   * @type {GenderPayGapStats}
   * @memberof FundAllOf4
   */
  genderPayGapStats?: GenderPayGapStats;
}
/**
 *
 * @export
 * @interface FundAllOf5
 */
export interface FundAllOf5 {
  /**
   *
   * @type {Array<ItemsSectorDetail>}
   * @memberof FundAllOf5
   */
  sectors: Array<ItemsSectorDetail>;
}
/**
 *
 * @export
 * @interface FundAllOf6
 */
export interface FundAllOf6 {
  /**
   *
   * @type {Array<FundGeographyCountry>}
   * @memberof FundAllOf6
   */
  countriesLongPctEquity: Array<FundGeographyCountry>;
  /**
   *
   * @type {Array<FundGeographyCountry>}
   * @memberof FundAllOf6
   */
  countriesLongPctFixedIncome: Array<FundGeographyCountry>;
  /**
   *
   * @type {Array<FundGeographyCountry>}
   * @memberof FundAllOf6
   */
  countriesShortPctEquity: Array<FundGeographyCountry>;
  /**
   *
   * @type {Array<FundGeographyCountry>}
   * @memberof FundAllOf6
   */
  countriesShortPctFixedIncome: Array<FundGeographyCountry>;
}
/**
 *
 * @export
 * @interface FundBase
 */
export interface FundBase {
  /**
   *
   * @type {FundBaseShareClassDetails}
   * @memberof FundBase
   */
  shareClassDetails: FundBaseShareClassDetails;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  fundId: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  fundName: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  aiGeneratedDescription?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  legalStructure: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  msCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  msGlobalCategoryId?: string;
  /**
   *
   * @type {Array<FundBasePerformanceIdDetails>}
   * @memberof FundBase
   */
  performanceIdDetails: Array<FundBasePerformanceIdDetails>;
  /**
   *
   * @type {Array<SectorItem>}
   * @memberof FundBase
   */
  sectors?: Array<SectorItem>;
  /**
   *
   * @type {Array<SectorItem>}
   * @memberof FundBase
   */
  sectorsEquity?: Array<SectorItem>;
  /**
   *
   * @type {Array<SectorItem>}
   * @memberof FundBase
   */
  sectorsFixedIncome?: Array<SectorItem>;
  /**
   *
   * @type {Array<MarketCap>}
   * @memberof FundBase
   */
  marketCapsLong?: Array<MarketCap>;
  /**
   *
   * @type {Array<PerformanceBase>}
   * @memberof FundBase
   */
  annualisedPerformancesPcl?: Array<PerformanceBase>;
  /**
   *
   * @type {Array<PerformanceBase>}
   * @memberof FundBase
   */
  calendarYearPerformancesPcl?: Array<PerformanceBase>;
  /**
   *
   * @type {Array<GeographyItem>}
   * @memberof FundBase
   */
  countriesEquity?: Array<GeographyItem>;
  /**
   *
   * @type {Array<GeographyItem>}
   * @memberof FundBase
   */
  countriesFixedIncome?: Array<GeographyItem>;
  /**
   *
   * @type {Array<GeographyItem>}
   * @memberof FundBase
   */
  geographyPortfolio?: Array<GeographyItem>;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  assetClassCode: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  msBenchmarkId?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  shareClassMsId?: string;
  /**
   *
   * @type {FundBroadAssetClass}
   * @memberof FundBase
   */
  broadAssetClass: FundBroadAssetClass;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  msCategoryDefinition: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  iaSector?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  performanceFeeCharged?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  financialYearEnd?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  domicile?: string;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturnYtdMorningstarCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn3MonthMorningstarCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn6MonthMorningstarCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn1YearMorningstarCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn3YearMorningstarCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn5YearMorningstarCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturnYtdPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturnYtdPct?: number;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  totalReturn1MonthPct?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  totalReturn3MonthPct?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  totalReturn6MonthPct?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  totalReturn1YearPct?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  totalReturn3YearPct?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  totalReturn5YearPct?: string;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn1MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn3MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn6MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn1YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn3YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn5YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn1calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn2calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn3calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn4calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  sharePriceReturn5calendarYearsAgoPct?: number;
  /**
   *
   * @type {Array<FundPlatformsEnum>}
   * @memberof FundBase
   */
  platforms?: Array<FundPlatformsEnum>;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  portfolioUpdateDateMs?: string;
  /**
   *
   * @type {boolean}
   * @memberof FundBase
   */
  primaryShareClass?: boolean;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  noOfHoldingsDate?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  portfolioHoldingsListUpdateDate?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  portfolioSectorUpdateDate?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  portfolioGeographyUpdateDate?: string;
  /**
   *
   * @type {DataSourceType}
   * @memberof FundBase
   */
  portfolioDataSource?: DataSourceType;
  /**
   *
   * @type {BoardDiversity}
   * @memberof FundBase
   */
  boardDiversity?: BoardDiversity;
  /**
   *
   * @type {Array<FundPrimaryBenchmark>}
   * @memberof FundBase
   */
  primaryBenchmark?: Array<FundPrimaryBenchmark>;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  primaryBenchmarkFormatted?: string;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  providerName?: string;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  navCumFair?: number;
  /**
   *
   * @type {Array<FundManagementFee>}
   * @memberof FundBase
   */
  managementFee: Array<FundManagementFee>;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  performanceFee?: number;
  /**
   *
   * @type {string}
   * @memberof FundBase
   */
  currencyCode?: string;
  /**
   *
   * @type {Array<FundCutOffPoint>}
   * @memberof FundBase
   */
  cutOffPoint: Array<FundCutOffPoint>;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  netGearingCum?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  actualManagementFee?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  fundSizeUSD: number;
  /**
   *
   * @type {Array<TrustBoardDirectors>}
   * @memberof FundBase
   */
  boardDirectors?: Array<TrustBoardDirectors>;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn1calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn2calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn3calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn4calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn5calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn1calendarYearsAgoCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn2calendarYearsAgoCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn3calendarYearsAgoCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn4calendarYearsAgoCategoryPcl?: number;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  totalReturn5calendarYearsAgoCategoryPcl?: number;
  /**
   *
   * @type {Array<FundRiskMeasuresDetail>}
   * @memberof FundBase
   */
  riskMeasuresDetail?: Array<FundRiskMeasuresDetail>;
  /**
   *
   * @type {Array<FundRelativeRiskMeasuresDetail>}
   * @memberof FundBase
   */
  relativeRiskMeasuresDetail?: Array<FundRelativeRiskMeasuresDetail>;
  /**
   *
   * @type {Array<ScatterPointRiskMeasuresDetails>}
   * @memberof FundBase
   */
  scatterPointRiskMeasuresDetails?: Array<ScatterPointRiskMeasuresDetails>;
  /**
   *
   * @type {FundBaseBondStatistics}
   * @memberof FundBase
   */
  bondStatistics?: FundBaseBondStatistics;
  /**
   *
   * @type {Array<BondMaturityRangeDetail>}
   * @memberof FundBase
   */
  bondMaturityRange?: Array<BondMaturityRangeDetail>;
  /**
   *
   * @type {Array<BondMaturityRangeDetail1>}
   * @memberof FundBase
   */
  bondCreditQualityBreakdown?: Array<BondMaturityRangeDetail1>;
  /**
   *
   * @type {Array<CurrencyExposure>}
   * @memberof FundBase
   */
  currencyExposures?: Array<CurrencyExposure>;
  /**
   *
   * @type {EquityStatsDetail}
   * @memberof FundBase
   */
  equityStats?: EquityStatsDetail;
  /**
   *
   * @type {BroadAssetClassValues}
   * @memberof FundBase
   */
  broadAssetClassValues?: BroadAssetClassValues;
  /**
   *
   * @type {number}
   * @memberof FundBase
   */
  equityStyleBox?: number;
  /**
   *
   * @type {Array<AssetAllocationItem>}
   * @memberof FundBase
   */
  assetAllocation?: Array<AssetAllocationItem>;
  /**
   *
   * @type {FundFees}
   * @memberof FundBase
   */
  fundFees?: FundFees;
}
/**
 *
 * @export
 * @interface FundBaseBondStatistics
 */
export interface FundBaseBondStatistics {
  /**
   *
   * @type {number}
   * @memberof FundBaseBondStatistics
   */
  effectiveDuration?: number;
  /**
   *
   * @type {number}
   * @memberof FundBaseBondStatistics
   */
  averageCoupon?: number;
  /**
   *
   * @type {number}
   * @memberof FundBaseBondStatistics
   */
  currentYield?: number;
  /**
   *
   * @type {number}
   * @memberof FundBaseBondStatistics
   */
  styleBox?: number;
}
/**
 *
 * @export
 * @interface FundBasePerformanceIdDetails
 */
export interface FundBasePerformanceIdDetails {
  /**
   *
   * @type {string}
   * @memberof FundBasePerformanceIdDetails
   */
  performanceId: string;
  /**
   *
   * @type {boolean}
   * @memberof FundBasePerformanceIdDetails
   */
  isBaseCurrency: boolean;
}
/**
 *
 * @export
 * @interface FundBaseShareClassDetails
 */
export interface FundBaseShareClassDetails {
  /**
   *
   * @type {string}
   * @memberof FundBaseShareClassDetails
   */
  isin: string;
  /**
   *
   * @type {string}
   * @memberof FundBaseShareClassDetails
   */
  inceptionDate?: string;
  /**
   *
   * @type {string}
   * @memberof FundBaseShareClassDetails
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof FundBaseShareClassDetails
   */
  classCategoryBenchmarkName?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum FundBroadAssetClass {
  Equity = 'Equity',
  FixedIncome = 'Fixed Income',
  Allocation = 'Allocation',
  Alternative = 'Alternative',
  MoneyMarket = 'Money Market',
  Convertibles = 'Convertibles',
  Property = 'Property',
  Miscellaneous = 'Miscellaneous',
  Commodities = 'Commodities',
}

/**
 *
 * @export
 * @interface FundCutOffPoint
 */
export interface FundCutOffPoint {
  /**
   *
   * @type {string}
   * @memberof FundCutOffPoint
   */
  countryId: string;
  /**
   *
   * @type {string}
   * @memberof FundCutOffPoint
   */
  cutOffTime: string;
  /**
   *
   * @type {string}
   * @memberof FundCutOffPoint
   */
  dealingType: string;
}
/**
 *
 * @export
 * @interface FundDetailsForSignificantChanges
 */
export interface FundDetailsForSignificantChanges {
  /**
   *
   * @type {string}
   * @memberof FundDetailsForSignificantChanges
   */
  legalStructure?: string;
}
/**
 *
 * @export
 * @interface FundExploreItem
 */
export interface FundExploreItem {
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  financeForBiodiversityDate?: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  stewardshipCode?: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  netZeroAssetManagers?: string;
  /**
   *
   * @type {ExploreSearchResponseShareActionSurvey}
   * @memberof FundExploreItem
   */
  shareActionSurvey?: ExploreSearchResponseShareActionSurvey;
  /**
   *
   * @type {FundExploreItemShareDetails}
   * @memberof FundExploreItem
   */
  shareClassDetails: FundExploreItemShareDetails;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  fundName: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  fundId?: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  tcfdDate: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  unpriDate: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  actDate: string;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  sfdrClass?: number;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  legalStructure: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  msCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  msGlobalCategoryId?: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  assetClassCode: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  msCategoryDefinition: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  iaSector: string;
  /**
   *
   * @type {Array<FundExploreItemManager>}
   * @memberof FundExploreItem
   */
  managerList: Array<FundExploreItemManager>;
  /**
   *
   * @type {FundExploreItemFundSize}
   * @memberof FundExploreItem
   */
  fundSize: FundExploreItemFundSize;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  noOfHoldings: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  ocfPct: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturnYtdPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturn3MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  fundSizeUSD: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturn6MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturn1YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturn3YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturn5YearPct?: number;
  /**
   *
   * @type {GenderPayGap}
   * @memberof FundExploreItem
   */
  genderPayGap?: GenderPayGap;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  incomeFrequency?: string;
  /**
   *
   * @type {ExploreSearchResponseYield12Month}
   * @memberof FundExploreItem
   */
  yield12Month?: ExploreSearchResponseYield12Month;
  /**
   *
   * @type {string}
   * @memberof FundExploreItem
   */
  providerName: string;
  /**
   *
   * @type {Array<FundRiskMeasuresDetail>}
   * @memberof FundExploreItem
   */
  riskMeasuresDetail?: Array<FundRiskMeasuresDetail>;
  /**
   *
   * @type {Array<FundRelativeRiskMeasuresDetail>}
   * @memberof FundExploreItem
   */
  relativeRiskMeasuresDetail?: Array<FundRelativeRiskMeasuresDetail>;
  /**
   *
   * @type {EquityStatsDetail}
   * @memberof FundExploreItem
   */
  equityStats?: EquityStatsDetail;
  /**
   *
   * @type {BroadAssetClassValues}
   * @memberof FundExploreItem
   */
  broadAssetClassValues?: BroadAssetClassValues;
  /**
   *
   * @type {boolean}
   * @memberof FundExploreItem
   */
  indexFund?: boolean;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturn1calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturn2calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturn3calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturn4calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof FundExploreItem
   */
  totalReturn5calendarYearsAgoPct?: number;
  /**
   *
   * @type {boolean}
   * @memberof FundExploreItem
   */
  primaryShareClass?: boolean;
}
/**
 *
 * @export
 * @interface FundExploreItemFundSize
 */
export interface FundExploreItemFundSize {
  /**
   *
   * @type {number}
   * @memberof FundExploreItemFundSize
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof FundExploreItemFundSize
   */
  currencyId: string;
}
/**
 *
 * @export
 * @interface FundExploreItemManager
 */
export interface FundExploreItemManager {
  /**
   *
   * @type {string}
   * @memberof FundExploreItemManager
   */
  givenName?: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItemManager
   */
  familyName?: string;
}
/**
 *
 * @export
 * @interface FundExploreItemShareDetails
 */
export interface FundExploreItemShareDetails {
  /**
   *
   * @type {string}
   * @memberof FundExploreItemShareDetails
   */
  isin: string;
  /**
   *
   * @type {string}
   * @memberof FundExploreItemShareDetails
   */
  code?: string;
}
/**
 *
 * @export
 * @interface FundFees
 */
export interface FundFees {
  /**
   *
   * @type {number}
   * @memberof FundFees
   */
  transactionCosts?: number;
  /**
   *
   * @type {string}
   * @memberof FundFees
   */
  withoutRetrocession?: string;
  /**
   *
   * @type {string}
   * @memberof FundFees
   */
  assessmentOfValueColl?: string;
  /**
   *
   * @type {string}
   * @memberof FundFees
   */
  assessmentOfValuePrin?: string;
}
/**
 *
 * @export
 * @interface FundGeographyCountry
 */
export interface FundGeographyCountry {
  /**
   *
   * @type {string}
   * @memberof FundGeographyCountry
   */
  countryName: string;
  /**
   *
   * @type {number}
   * @memberof FundGeographyCountry
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof FundGeographyCountry
   */
  countryId: string;
}
/**
 *
 * @export
 * @interface FundManagementFee
 */
export interface FundManagementFee {
  /**
   *
   * @type {number}
   * @memberof FundManagementFee
   */
  lowBreakpoint?: number;
  /**
   *
   * @type {number}
   * @memberof FundManagementFee
   */
  highBreakpoint?: number;
  /**
   *
   * @type {number}
   * @memberof FundManagementFee
   */
  value?: number;
  /**
   *
   * @type {number}
   * @memberof FundManagementFee
   */
  breakpointUnit?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum FundPlatformsEnum {
  AegonPlatform = 'AEGON_PLATFORM',
  Arc = 'ARC',
  Aviva = 'AVIVA',
  OneRetirement = 'ONE_RETIREMENT',
  ScottishWidows = 'SCOTTISH_WIDOWS',
  Wealthtime = 'WEALTHTIME',
  Quilter = 'QUILTER',
  AbrdnWrap = 'ABRDN_WRAP',
  AbrdnElevate = 'ABRDN_ELEVATE',
  Transact = 'TRANSACT',
  Parmenion = 'PARMENION',
  SevenIm = 'SEVEN_IM',
}

/**
 *
 * @export
 * @interface FundPrimaryBenchmark
 */
export interface FundPrimaryBenchmark {
  /**
   *
   * @type {string}
   * @memberof FundPrimaryBenchmark
   */
  secId: string;
  /**
   *
   * @type {string}
   * @memberof FundPrimaryBenchmark
   */
  securityName: string;
  /**
   *
   * @type {number}
   * @memberof FundPrimaryBenchmark
   */
  weighting: number;
}
/**
 *
 * @export
 * @interface FundRelativeRiskMeasuresDetail
 */
export interface FundRelativeRiskMeasuresDetail {
  /**
   *
   * @type {string}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  timePeriod: string;
  /**
   *
   * @type {string}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  benchmarkType: string;
  /**
   *
   * @type {number}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  beta: number;
  /**
   *
   * @type {number}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  alpha: number;
  /**
   *
   * @type {number}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  correlation: number;
  /**
   *
   * @type {number}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  trackingError: number;
  /**
   *
   * @type {number}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  informationRatio: number;
  /**
   *
   * @type {number}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  captureRatioTypeOne: number;
  /**
   *
   * @type {number}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  captureRatioTypeTwo: number;
  /**
   *
   * @type {number}
   * @memberof FundRelativeRiskMeasuresDetail
   */
  battingAverage: number;
}
/**
 *
 * @export
 * @interface FundRiskMeasuresDetail
 */
export interface FundRiskMeasuresDetail {
  /**
   *
   * @type {string}
   * @memberof FundRiskMeasuresDetail
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof FundRiskMeasuresDetail
   */
  timePeriod: string;
  /**
   *
   * @type {number}
   * @memberof FundRiskMeasuresDetail
   */
  standardDeviation: number;
  /**
   *
   * @type {number}
   * @memberof FundRiskMeasuresDetail
   */
  maximumDrawdown: number;
  /**
   *
   * @type {number}
   * @memberof FundRiskMeasuresDetail
   */
  sharpeRatio: number;
  /**
   *
   * @type {number}
   * @memberof FundRiskMeasuresDetail
   */
  sortinoRatio: number;
}
/**
 *
 * @export
 * @interface FundSize
 */
export interface FundSize {
  /**
   *
   * @type {number}
   * @memberof FundSize
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof FundSize
   */
  currencyId: string;
  /**
   *
   * @type {string}
   * @memberof FundSize
   */
  date?: string;
}
/**
 *
 * @export
 * @interface GenderPayGap
 */
export interface GenderPayGap {
  /**
   *
   * @type {Array<GenderPayGapHourlyWageGap>}
   * @memberof GenderPayGap
   */
  hourlyWageGap?: Array<GenderPayGapHourlyWageGap>;
  /**
   *
   * @type {Array<GenderPayGapHourlyWageGap>}
   * @memberof GenderPayGap
   */
  bonusPayGap?: Array<GenderPayGapHourlyWageGap>;
  /**
   *
   * @type {Array<GenderPayGapHourlyWageGap>}
   * @memberof GenderPayGap
   */
  womenHighestPaidJobs?: Array<GenderPayGapHourlyWageGap>;
  /**
   *
   * @type {Array<GenderPayGapHourlyWageGap>}
   * @memberof GenderPayGap
   */
  womenLowestPaidJobs?: Array<GenderPayGapHourlyWageGap>;
}
/**
 *
 * @export
 * @interface GenderPayGapHourlyWageGap
 */
export interface GenderPayGapHourlyWageGap {
  /**
   *
   * @type {number}
   * @memberof GenderPayGapHourlyWageGap
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof GenderPayGapHourlyWageGap
   */
  percentage?: number;
}
/**
 *
 * @export
 * @interface GenderPayGapStats
 */
export interface GenderPayGapStats {
  /**
   *
   * @type {Array<GenderPayGapStatsHourlyWageGapStats>}
   * @memberof GenderPayGapStats
   */
  hourlyWageGapStats?: Array<GenderPayGapStatsHourlyWageGapStats>;
  /**
   *
   * @type {Array<GenderPayGapStatsHourlyWageGapStats>}
   * @memberof GenderPayGapStats
   */
  bonusPayGapStats?: Array<GenderPayGapStatsHourlyWageGapStats>;
  /**
   *
   * @type {Array<GenderPayGapStatsHourlyWageGapStats>}
   * @memberof GenderPayGapStats
   */
  womenHighestPaidJobsStats?: Array<GenderPayGapStatsHourlyWageGapStats>;
  /**
   *
   * @type {Array<GenderPayGapStatsHourlyWageGapStats>}
   * @memberof GenderPayGapStats
   */
  womenLowestPaidJobsStats?: Array<GenderPayGapStatsHourlyWageGapStats>;
}
/**
 *
 * @export
 * @interface GenderPayGapStatsHourlyWageGapStats
 */
export interface GenderPayGapStatsHourlyWageGapStats {
  /**
   *
   * @type {number}
   * @memberof GenderPayGapStatsHourlyWageGapStats
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof GenderPayGapStatsHourlyWageGapStats
   */
  average?: number;
}
/**
 *
 * @export
 * @interface GeographyItem
 */
export interface GeographyItem {
  /**
   *
   * @type {string}
   * @memberof GeographyItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GeographyItem
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof GeographyItem
   */
  index?: number;
  /**
   *
   * @type {number}
   * @memberof GeographyItem
   */
  relative?: number;
  /**
   *
   * @type {number}
   * @memberof GeographyItem
   */
  value: number;
}
/**
 *
 * @export
 * @interface GetBaseCurrencyRatesResponse
 */
export interface GetBaseCurrencyRatesResponse {
  /**
   *
   * @type {string}
   * @memberof GetBaseCurrencyRatesResponse
   */
  base?: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GetBaseCurrencyRatesResponse
   */
  rates: { [key: string]: number };
}
/**
 *
 * @export
 * @interface GetFundByIsinResponse
 */
export interface GetFundByIsinResponse {
  /**
   *
   * @type {Array<GetFundByIsinResponseSharedClass>}
   * @memberof GetFundByIsinResponse
   */
  sharedClass: Array<GetFundByIsinResponseSharedClass>;
  /**
   *
   * @type {Fund}
   * @memberof GetFundByIsinResponse
   */
  fund: Fund;
  /**
   *
   * @type {Performance}
   * @memberof GetFundByIsinResponse
   */
  benchmarkPerformance?: Performance;
  /**
   *
   * @type {Performance}
   * @memberof GetFundByIsinResponse
   */
  categoryPerformance?: Performance;
}
/**
 *
 * @export
 * @interface GetFundByIsinResponseSharedClass
 */
export interface GetFundByIsinResponseSharedClass {
  /**
   *
   * @type {ShareClassDetails}
   * @memberof GetFundByIsinResponseSharedClass
   */
  shareClassDetails: ShareClassDetails;
  /**
   *
   * @type {number}
   * @memberof GetFundByIsinResponseSharedClass
   */
  ocfPct: number;
}
/**
 *
 * @export
 * @interface GetNotificationsResponse
 */
export interface GetNotificationsResponse {
  /**
   *
   * @type {Array<NotificationItem>}
   * @memberof GetNotificationsResponse
   */
  notificationItems: Array<NotificationItem>;
}
/**
 *
 * @export
 * @interface GetPortfoliosResponse
 */
export interface GetPortfoliosResponse {
  /**
   *
   * @type {Array<Portfolio>}
   * @memberof GetPortfoliosResponse
   */
  portfolios: Array<Portfolio>;
}
/**
 *
 * @export
 * @interface GetUserProfileResponse
 */
export interface GetUserProfileResponse {
  /**
   *
   * @type {string}
   * @memberof GetUserProfileResponse
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof GetUserProfileResponse
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof GetUserProfileResponse
   */
  given_name: string;
  /**
   *
   * @type {string}
   * @memberof GetUserProfileResponse
   */
  family_name: string;
  /**
   *
   * @type {string}
   * @memberof GetUserProfileResponse
   */
  company?: string;
}
/**
 *
 * @export
 * @interface HoldingDetail
 */
export interface HoldingDetail {
  /**
   *
   * @type {number}
   * @memberof HoldingDetail
   */
  weighting?: number;
  /**
   *
   * @type {number}
   * @memberof HoldingDetail
   */
  index?: number;
  /**
   *
   * @type {number}
   * @memberof HoldingDetail
   */
  relative?: number;
  /**
   *
   * @type {string}
   * @memberof HoldingDetail
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof HoldingDetail
   */
  externalName?: string;
  /**
   *
   * @type {string}
   * @memberof HoldingDetail
   */
  firstBoughtDate?: string;
  /**
   *
   * @type {string}
   * @memberof HoldingDetail
   */
  sector?: string;
  /**
   *
   * @type {string}
   * @memberof HoldingDetail
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof HoldingDetail
   */
  isin?: string;
}
/**
 *
 * @export
 * @interface IaSectors
 */
export interface IaSectors {
  /**
   *
   * @type {string}
   * @memberof IaSectors
   */
  label: string;
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
  /**
   *
   * @type {string}
   * @memberof InlineObject1
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof InlineObject1
   */
  value?: number;
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  newDate: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  oldDate: string;
}
/**
 *
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
  /**
   *
   * @type {string}
   * @memberof InlineObject3
   */
  date: string;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof InlineResponse200
   */
  success?: boolean;
  /**
   *
   * @type {number}
   * @memberof InlineResponse200
   */
  status?: number;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001
   */
  warningMessage: string;
}
/**
 *
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse201
   */
  portfolioId: string;
}
/**
 *
 * @export
 * @interface InternalResponse
 */
export interface InternalResponse {
  /**
   *
   * @type {Array<Material>}
   * @memberof InternalResponse
   */
  materials: Array<Material>;
  /**
   *
   * @type {Array<Comment>}
   * @memberof InternalResponse
   */
  comments: Array<Comment>;
}
/**
 *
 * @export
 * @interface Investor
 */
export interface Investor {
  /**
   *
   * @type {string}
   * @memberof Investor
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Investor
   */
  shareClassLink: string;
  /**
   *
   * @type {string}
   * @memberof Investor
   */
  fundId: string;
  /**
   *
   * @type {number}
   * @memberof Investor
   */
  weight?: number;
  /**
   *
   * @type {number}
   * @memberof Investor
   */
  allocation?: number;
  /**
   *
   * @type {string}
   * @memberof Investor
   */
  providerName?: string;
}
/**
 *
 * @export
 * @interface IsinForPortfolio
 */
export interface IsinForPortfolio {
  /**
   *
   * @type {string}
   * @memberof IsinForPortfolio
   */
  isin: string;
}
/**
 *
 * @export
 * @interface ItemsSectorDetail
 */
export interface ItemsSectorDetail {
  /**
   *
   * @type {string}
   * @memberof ItemsSectorDetail
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ItemsSectorDetail
   */
  type: number;
  /**
   *
   * @type {number}
   * @memberof ItemsSectorDetail
   */
  value: number;
  /**
   *
   * @type {number}
   * @memberof ItemsSectorDetail
   */
  index?: number;
  /**
   *
   * @type {number}
   * @memberof ItemsSectorDetail
   */
  relative?: number;
}
/**
 *
 * @export
 * @interface ManageWatchlistResource
 */
export interface ManageWatchlistResource {
  /**
   *
   * @type {Array<string>}
   * @memberof ManageWatchlistResource
   */
  isins: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ManageWatchlistResource
   */
  bucketId: string;
  /**
   *
   * @type {string}
   * @memberof ManageWatchlistResource
   */
  previousBucketId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ManageWatchlistResource
   */
  isFeatured?: boolean;
}
/**
 *
 * @export
 * @interface ManagerItem
 */
export interface ManagerItem {
  /**
   *
   * @type {string}
   * @memberof ManagerItem
   */
  givenName?: string;
  /**
   *
   * @type {string}
   * @memberof ManagerItem
   */
  familyName?: string;
  /**
   *
   * @type {string}
   * @memberof ManagerItem
   */
  managerProvidedBiography?: string;
  /**
   *
   * @type {string}
   * @memberof ManagerItem
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof ManagerItem
   */
  gender?: string;
}
/**
 *
 * @export
 * @interface MarkNotificationsAsReadRequest
 */
export interface MarkNotificationsAsReadRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof MarkNotificationsAsReadRequest
   */
  ids: Array<string>;
}
/**
 *
 * @export
 * @interface MarkNotificationsAsSeenRequest
 */
export interface MarkNotificationsAsSeenRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof MarkNotificationsAsSeenRequest
   */
  ids: Array<string>;
}
/**
 *
 * @export
 * @interface MarketCap
 */
export interface MarketCap {
  /**
   *
   * @type {string}
   * @memberof MarketCap
   */
  assetClassCode?: string;
  /**
   *
   * @type {FundBroadAssetClass}
   * @memberof MarketCap
   */
  broadAssetClass?: FundBroadAssetClass;
  /**
   *
   * @type {number}
   * @memberof MarketCap
   */
  marketCapLongPctGiant?: number;
  /**
   *
   * @type {number}
   * @memberof MarketCap
   */
  marketCapLongPctLarge?: number;
  /**
   *
   * @type {number}
   * @memberof MarketCap
   */
  marketCapLongPctMedium?: number;
  /**
   *
   * @type {number}
   * @memberof MarketCap
   */
  marketCapLongPctSmall?: number;
  /**
   *
   * @type {number}
   * @memberof MarketCap
   */
  marketCapLongPctMicro?: number;
  /**
   *
   * @type {Array<MarketCapBase>}
   * @memberof MarketCap
   */
  marketCapsLong?: Array<MarketCapBase>;
}
/**
 *
 * @export
 * @interface MarketCapBase
 */
export interface MarketCapBase {
  /**
   *
   * @type {string}
   * @memberof MarketCapBase
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof MarketCapBase
   */
  value: number | null;
}
/**
 *
 * @export
 * @interface MarketCapsLong
 */
export interface MarketCapsLong {
  /**
   *
   * @type {Array<MarketCapBase>}
   * @memberof MarketCapsLong
   */
  marketCapsLong?: Array<MarketCapBase>;
}
/**
 *
 * @export
 * @interface MarketData
 */
export interface MarketData {
  /**
   *
   * @type {string}
   * @memberof MarketData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MarketData
   */
  tooltipTitle: string;
  /**
   *
   * @type {number}
   * @memberof MarketData
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof MarketData
   */
  secId: string;
}
/**
 *
 * @export
 * @interface MarketDataResponse
 */
export interface MarketDataResponse {
  /**
   *
   * @type {Array<MarketData>}
   * @memberof MarketDataResponse
   */
  data: Array<MarketData>;
}
/**
 *
 * @export
 * @interface Material
 */
export interface Material {
  /**
   *
   * @type {string}
   * @memberof Material
   */
  _id: string;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  orgId: string;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  fundId: string;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  link: string;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof Material
   */
  updatedBy?: string;
  /**
   *
   * @type {number}
   * @memberof Material
   */
  _createdAt: number;
  /**
   *
   * @type {number}
   * @memberof Material
   */
  _updatedAt: number;
}
/**
 *
 * @export
 * @interface MaterialBody
 */
export interface MaterialBody {
  /**
   *
   * @type {string}
   * @memberof MaterialBody
   */
  link: string;
  /**
   *
   * @type {string}
   * @memberof MaterialBody
   */
  text: string;
}
/**
 *
 * @export
 * @interface MaturityList
 */
export interface MaturityList {
  /**
   *
   * @type {number}
   * @memberof MaturityList
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof MaturityList
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message: string;
}
/**
 *
 * @export
 * @interface NotificationItem
 */
export interface NotificationItem {
  /**
   *
   * @type {string}
   * @memberof NotificationItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NotificationItem
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof NotificationItem
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof NotificationItem
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof NotificationItem
   */
  navigationLink?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationItem
   */
  subtitle?: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationItem
   */
  isRead: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationItem
   */
  isSeen: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationItem
   */
  dateCreated: string;
  /**
   *
   * @type {string}
   * @memberof NotificationItem
   */
  esUpdatedDate: string;
  /**
   *
   * @type {number}
   * @memberof NotificationItem
   */
  totalUnreadCount?: number;
  /**
   *
   * @type {number}
   * @memberof NotificationItem
   */
  totalUnseenCount?: number;
}
/**
 *
 * @export
 * @interface NotificationsSettings
 */
export interface NotificationsSettings {
  /**
   *
   * @type {NotificationsSettingsForSignificantChanges}
   * @memberof NotificationsSettings
   */
  significantChanges: NotificationsSettingsForSignificantChanges;
  /**
   *
   * @type {NotificationsSettingsForCommentary}
   * @memberof NotificationsSettings
   */
  commentary: NotificationsSettingsForCommentary;
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettings
   */
  shouldSendWeeklyUpdate: boolean;
}
/**
 *
 * @export
 * @interface NotificationsSettingsForCommentary
 */
export interface NotificationsSettingsForCommentary {
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettingsForCommentary
   */
  inApp: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettingsForCommentary
   */
  email: boolean;
}
/**
 *
 * @export
 * @interface NotificationsSettingsForSignificantChanges
 */
export interface NotificationsSettingsForSignificantChanges {
  /**
   *
   * @type {NotificationsSettingsForSignificantChangesInApp}
   * @memberof NotificationsSettingsForSignificantChanges
   */
  inApp: NotificationsSettingsForSignificantChangesInApp;
  /**
   *
   * @type {NotificationsSettingsForSignificantChangesInApp}
   * @memberof NotificationsSettingsForSignificantChanges
   */
  email: NotificationsSettingsForSignificantChangesInApp;
}
/**
 *
 * @export
 * @interface NotificationsSettingsForSignificantChangesInApp
 */
export interface NotificationsSettingsForSignificantChangesInApp {
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettingsForSignificantChangesInApp
   */
  fundSize: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettingsForSignificantChangesInApp
   */
  holdings: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettingsForSignificantChangesInApp
   */
  managerChange: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettingsForSignificantChangesInApp
   */
  ocf: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettingsForSignificantChangesInApp
   */
  premiumDiscount: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettingsForSignificantChangesInApp
   */
  yield: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettingsForSignificantChangesInApp
   */
  styleDrift: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationsSettingsForSignificantChangesInApp
   */
  modifiedDuration: boolean;
}
/**
 *
 * @export
 * @interface PageQueryParameters
 */
export interface PageQueryParameters {
  /**
   *
   * @type {number}
   * @memberof PageQueryParameters
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof PageQueryParameters
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof PageQueryParameters
   */
  skip?: number;
  /**
   *
   * @type {string}
   * @memberof PageQueryParameters
   */
  sortBy?: string;
  /**
   *
   * @type {string}
   * @memberof PageQueryParameters
   */
  sortDirection?: PageQueryParametersSortDirectionEnum;
  /**
   *
   * @type {boolean}
   * @memberof PageQueryParameters
   */
  sortByFirstColumnWithValue?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum PageQueryParametersSortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/**
 *
 * @export
 * @interface Performance
 */
export interface Performance {
  /**
   *
   * @type {string}
   * @memberof Performance
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturnYtdPct?: number;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturn1YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturn3YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturn5YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturn3MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturn6MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturn1calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturn2calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturn3calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturn4calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof Performance
   */
  totalReturn5calendarYearsAgoPct?: number;
}
/**
 *
 * @export
 * @interface PerformanceBase
 */
export interface PerformanceBase {
  /**
   *
   * @type {string}
   * @memberof PerformanceBase
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof PerformanceBase
   */
  value: number;
}
/**
 *
 * @export
 * @interface Platforms
 */
export interface Platforms {
  /**
   *
   * @type {string}
   * @memberof Platforms
   */
  value: string;
}
/**
 *
 * @export
 * @interface Portfolio
 */
export interface Portfolio {
  /**
   *
   * @type {string}
   * @memberof Portfolio
   */
  _id: string;
  /**
   *
   * @type {Array<ResourceUser>}
   * @memberof Portfolio
   */
  users?: Array<ResourceUser>;
  /**
   *
   * @type {Array<ResourceUser>}
   * @memberof Portfolio
   */
  invitedUsers?: Array<ResourceUser>;
  /**
   *
   * @type {string}
   * @memberof Portfolio
   */
  name: string;
  /**
   *
   * @type {PortfolioBenchmarkSetting}
   * @memberof Portfolio
   */
  benchmarkSetting: PortfolioBenchmarkSetting;
  /**
   *
   * @type {Array<PortfolioBenchmark>}
   * @memberof Portfolio
   */
  benchmarks: Array<PortfolioBenchmark>;
  /**
   *
   * @type {Array<PortfolioFund>}
   * @memberof Portfolio
   */
  funds: Array<PortfolioFund>;
  /**
   *
   * @type {number}
   * @memberof Portfolio
   */
  totalFunds?: number;
}
/**
 *
 * @export
 * @interface PortfolioAllOf
 */
export interface PortfolioAllOf {
  /**
   *
   * @type {number}
   * @memberof PortfolioAllOf
   */
  totalFunds?: number;
}
/**
 *
 * @export
 * @interface PortfolioBase
 */
export interface PortfolioBase {
  /**
   *
   * @type {string}
   * @memberof PortfolioBase
   */
  _id: string;
  /**
   *
   * @type {Array<ResourceUser>}
   * @memberof PortfolioBase
   */
  users?: Array<ResourceUser>;
  /**
   *
   * @type {Array<ResourceUser>}
   * @memberof PortfolioBase
   */
  invitedUsers?: Array<ResourceUser>;
}
/**
 *
 * @export
 * @interface PortfolioBenchmark
 */
export interface PortfolioBenchmark {
  /**
   *
   * @type {string}
   * @memberof PortfolioBenchmark
   */
  secId: string;
  /**
   *
   * @type {number}
   * @memberof PortfolioBenchmark
   */
  weighting: number;
  /**
   *
   * @type {string}
   * @memberof PortfolioBenchmark
   */
  name: string;
}
/**
 *
 * @export
 * @interface PortfolioBenchmarkAllOf
 */
export interface PortfolioBenchmarkAllOf {
  /**
   *
   * @type {string}
   * @memberof PortfolioBenchmarkAllOf
   */
  name: string;
}
/**
 *
 * @export
 * @interface PortfolioBenchmarkBase
 */
export interface PortfolioBenchmarkBase {
  /**
   *
   * @type {string}
   * @memberof PortfolioBenchmarkBase
   */
  secId: string;
  /**
   *
   * @type {number}
   * @memberof PortfolioBenchmarkBase
   */
  weighting: number;
}
/**
 *
 * @export
 * @interface PortfolioBenchmarkSetting
 */
export interface PortfolioBenchmarkSetting {
  /**
   *
   * @type {string}
   * @memberof PortfolioBenchmarkSetting
   */
  compositeBenchmarkId?: string;
  /**
   *
   * @type {string}
   * @memberof PortfolioBenchmarkSetting
   */
  secId?: string;
  /**
   *
   * @type {string}
   * @memberof PortfolioBenchmarkSetting
   */
  type: PortfolioBenchmarkSettingTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PortfolioBenchmarkSettingTypeEnum {
  Composite = 'COMPOSITE',
  Regular = 'REGULAR',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PortfolioBenchmarkType {
  Composite = 'COMPOSITE',
  Regular = 'REGULAR',
}

/**
 *
 * @export
 * @interface PortfolioDetails
 */
export interface PortfolioDetails {
  /**
   *
   * @type {string}
   * @memberof PortfolioDetails
   */
  _id: string;
  /**
   *
   * @type {string}
   * @memberof PortfolioDetails
   */
  name: string;
  /**
   *
   * @type {PortfolioBenchmarkSetting}
   * @memberof PortfolioDetails
   */
  benchmarkSetting: PortfolioBenchmarkSetting;
  /**
   *
   * @type {Array<PortfolioBenchmark>}
   * @memberof PortfolioDetails
   */
  benchmarks: Array<PortfolioBenchmark>;
  /**
   *
   * @type {Array<PortfolioFund>}
   * @memberof PortfolioDetails
   */
  funds: Array<PortfolioFund>;
}
/**
 *
 * @export
 * @interface PortfolioFund
 */
export interface PortfolioFund {
  /**
   *
   * @type {string}
   * @memberof PortfolioFund
   */
  isin: string;
  /**
   *
   * @type {Array<PortfolioWeighting>}
   * @memberof PortfolioFund
   */
  weightings: Array<PortfolioWeighting>;
  /**
   *
   * @type {string}
   * @memberof PortfolioFund
   */
  broadAssetClass?: string;
  /**
   *
   * @type {string}
   * @memberof PortfolioFund
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PortfolioFund
   */
  fundName?: string;
  /**
   *
   * @type {PortfolioFundAllOfShareClassDetails}
   * @memberof PortfolioFund
   */
  shareClassDetails?: PortfolioFundAllOfShareClassDetails;
  /**
   *
   * @type {number}
   * @memberof PortfolioFund
   */
  totalReturnYtdPct?: number;
  /**
   *
   * @type {number}
   * @memberof PortfolioFund
   */
  totalReturn3MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof PortfolioFund
   */
  totalReturn6MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof PortfolioFund
   */
  totalReturn1YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof PortfolioFund
   */
  totalReturn3YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof PortfolioFund
   */
  totalReturn5YearPct?: number;
  /**
   *
   * @type {Array<FundPlatformsEnum>}
   * @memberof PortfolioFund
   */
  platforms?: Array<FundPlatformsEnum>;
}
/**
 *
 * @export
 * @interface PortfolioFundAllOf
 */
export interface PortfolioFundAllOf {
  /**
   *
   * @type {string}
   * @memberof PortfolioFundAllOf
   */
  broadAssetClass?: string;
  /**
   *
   * @type {string}
   * @memberof PortfolioFundAllOf
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PortfolioFundAllOf
   */
  fundName?: string;
  /**
   *
   * @type {PortfolioFundAllOfShareClassDetails}
   * @memberof PortfolioFundAllOf
   */
  shareClassDetails?: PortfolioFundAllOfShareClassDetails;
  /**
   *
   * @type {number}
   * @memberof PortfolioFundAllOf
   */
  totalReturnYtdPct?: number;
  /**
   *
   * @type {number}
   * @memberof PortfolioFundAllOf
   */
  totalReturn3MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof PortfolioFundAllOf
   */
  totalReturn6MonthPct?: number;
  /**
   *
   * @type {number}
   * @memberof PortfolioFundAllOf
   */
  totalReturn1YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof PortfolioFundAllOf
   */
  totalReturn3YearPct?: number;
  /**
   *
   * @type {number}
   * @memberof PortfolioFundAllOf
   */
  totalReturn5YearPct?: number;
  /**
   *
   * @type {Array<FundPlatformsEnum>}
   * @memberof PortfolioFundAllOf
   */
  platforms?: Array<FundPlatformsEnum>;
}
/**
 *
 * @export
 * @interface PortfolioFundAllOfShareClassDetails
 */
export interface PortfolioFundAllOfShareClassDetails {
  /**
   *
   * @type {string}
   * @memberof PortfolioFundAllOfShareClassDetails
   */
  code?: string;
}
/**
 *
 * @export
 * @interface PortfolioFundForUpdate
 */
export interface PortfolioFundForUpdate {
  /**
   *
   * @type {string}
   * @memberof PortfolioFundForUpdate
   */
  isin: string;
  /**
   *
   * @type {Array<PortfolioWeighting>}
   * @memberof PortfolioFundForUpdate
   */
  weightings: Array<PortfolioWeighting>;
}
/**
 *
 * @export
 * @interface PortfolioInviteDetailResponse
 */
export interface PortfolioInviteDetailResponse {
  /**
   *
   * @type {PortfolioInviteStatusEnum}
   * @memberof PortfolioInviteDetailResponse
   */
  status: PortfolioInviteStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PortfolioInviteDetailResponse
   */
  portfolioName?: string;
  /**
   *
   * @type {string}
   * @memberof PortfolioInviteDetailResponse
   */
  userRole?: PortfolioInviteDetailResponseUserRoleEnum;
  /**
   *
   * @type {string}
   * @memberof PortfolioInviteDetailResponse
   */
  message?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PortfolioInviteDetailResponseUserRoleEnum {
  Editor = 'editor',
  Viewer = 'viewer',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PortfolioInviteStatusEnum {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Deleted = 'DELETED',
}

/**
 *
 * @export
 * @interface PortfolioPayload
 */
export interface PortfolioPayload {
  /**
   *
   * @type {string}
   * @memberof PortfolioPayload
   */
  _id?: string;
  /**
   *
   * @type {string}
   * @memberof PortfolioPayload
   */
  name: string;
  /**
   *
   * @type {PortfolioBenchmarkSetting}
   * @memberof PortfolioPayload
   */
  benchmarkSetting: PortfolioBenchmarkSetting;
  /**
   *
   * @type {Array<PortfolioFundForUpdate>}
   * @memberof PortfolioPayload
   */
  funds: Array<PortfolioFundForUpdate>;
}
/**
 *
 * @export
 * @interface PortfolioPlatforms
 */
export interface PortfolioPlatforms {
  /**
   *
   * @type {Array<PortfolioFund>}
   * @memberof PortfolioPlatforms
   */
  portfolios?: Array<PortfolioFund>;
}
/**
 *
 * @export
 * @interface PortfolioScatterPlotResponse
 */
export interface PortfolioScatterPlotResponse {
  /**
   *
   * @type {boolean}
   * @memberof PortfolioScatterPlotResponse
   */
  isValid?: boolean;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof PortfolioScatterPlotResponse
   */
  issues?: { [key: string]: Array<string> };
  /**
   *
   * @type {CorrelationMatrixResponseQueriedDateRange}
   * @memberof PortfolioScatterPlotResponse
   */
  queriedDateRange: CorrelationMatrixResponseQueriedDateRange;
  /**
   *
   * @type {PortfolioScatterPlotResponseChartData}
   * @memberof PortfolioScatterPlotResponse
   */
  chartData: PortfolioScatterPlotResponseChartData;
}
/**
 *
 * @export
 * @interface PortfolioScatterPlotResponseChartData
 */
export interface PortfolioScatterPlotResponseChartData {
  /**
   *
   * @type {Array<ScatterPlotData>}
   * @memberof PortfolioScatterPlotResponseChartData
   */
  data?: Array<ScatterPlotData>;
  /**
   *
   * @type {Array<ScatterPlotResponseV2LegendData>}
   * @memberof PortfolioScatterPlotResponseChartData
   */
  legendData?: Array<ScatterPlotResponseV2LegendData>;
}
/**
 *
 * @export
 * @interface PortfolioWeighting
 */
export interface PortfolioWeighting {
  /**
   *
   * @type {string}
   * @memberof PortfolioWeighting
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof PortfolioWeighting
   */
  value?: number;
  /**
   *
   * @type {boolean}
   * @memberof PortfolioWeighting
   */
  isValid?: boolean;
}
/**
 *
 * @export
 * @interface PremiumDiscount
 */
export interface PremiumDiscount {
  /**
   *
   * @type {number}
   * @memberof PremiumDiscount
   */
  thirtyDaysAgo?: number;
  /**
   *
   * @type {string}
   * @memberof PremiumDiscount
   */
  currentDate?: string;
  /**
   *
   * @type {number}
   * @memberof PremiumDiscount
   */
  current: number;
}
/**
 *
 * @export
 * @interface PremiumDiscountPerformance
 */
export interface PremiumDiscountPerformance {
  /**
   *
   * @type {number}
   * @memberof PremiumDiscountPerformance
   */
  maximum: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDiscountPerformance
   */
  minimum: number;
  /**
   *
   * @type {number}
   * @memberof PremiumDiscountPerformance
   */
  current: number;
  /**
   *
   * @type {string}
   * @memberof PremiumDiscountPerformance
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof PremiumDiscountPerformance
   */
  to?: string;
}
/**
 *
 * @export
 * @interface ProviderNames
 */
export interface ProviderNames {
  /**
   *
   * @type {string}
   * @memberof ProviderNames
   */
  label: string;
}
/**
 *
 * @export
 * @interface ResourceUser
 */
export interface ResourceUser {
  /**
   *
   * @type {string}
   * @memberof ResourceUser
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ResourceUser
   */
  role: ResourceUserRoleEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ResourceUserRoleEnum {
  Editor = 'editor',
  Viewer = 'viewer',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum RiskDetailsTypeEnum {
  INVESTMENT_TRUST = '81',
  FUND = '61',
}

/**
 *
 * @export
 * @interface RiskList
 */
export interface RiskList {
  /**
   *
   * @type {number}
   * @memberof RiskList
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof RiskList
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @interface ScatterPlotData
 */
export interface ScatterPlotData {
  /**
   *
   * @type {number}
   * @memberof ScatterPlotData
   */
  totalReturnOverPeriod?: number;
  /**
   *
   * @type {number}
   * @memberof ScatterPlotData
   */
  standardDeviation?: number;
  /**
   *
   * @type {string}
   * @memberof ScatterPlotData
   */
  color?: string;
  /**
   *
   * @type {string}
   * @memberof ScatterPlotData
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof ScatterPlotData
   */
  tooltip?: string;
  /**
   *
   * @type {boolean}
   * @memberof ScatterPlotData
   */
  isBenchmark?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ScatterPlotData
   */
  isFeatured?: boolean;
  /**
   *
   * @type {string}
   * @memberof ScatterPlotData
   */
  id: string;
}
/**
 *
 * @export
 * @interface ScatterPlotResponseV2
 */
export interface ScatterPlotResponseV2 {
  /**
   *
   * @type {Array<ScatterPlotData>}
   * @memberof ScatterPlotResponseV2
   */
  data: Array<ScatterPlotData>;
  /**
   *
   * @type {Array<ScatterPlotResponseV2LegendData>}
   * @memberof ScatterPlotResponseV2
   */
  legendData?: Array<ScatterPlotResponseV2LegendData>;
}
/**
 *
 * @export
 * @interface ScatterPlotResponseV2LegendData
 */
export interface ScatterPlotResponseV2LegendData {
  /**
   *
   * @type {string}
   * @memberof ScatterPlotResponseV2LegendData
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ScatterPlotResponseV2LegendData
   */
  label?: string;
  /**
   *
   * @type {number}
   * @memberof ScatterPlotResponseV2LegendData
   */
  xValue?: number;
  /**
   *
   * @type {number}
   * @memberof ScatterPlotResponseV2LegendData
   */
  yValue?: number;
  /**
   *
   * @type {boolean}
   * @memberof ScatterPlotResponseV2LegendData
   */
  isBenchmark?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ScatterPlotResponseV2LegendData
   */
  isFeatured?: boolean;
  /**
   *
   * @type {string}
   * @memberof ScatterPlotResponseV2LegendData
   */
  isin?: string;
}
/**
 *
 * @export
 * @interface ScatterPointRiskMeasuresDetails
 */
export interface ScatterPointRiskMeasuresDetails {
  /**
   *
   * @type {string}
   * @memberof ScatterPointRiskMeasuresDetails
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ScatterPointRiskMeasuresDetails
   */
  timePeriod: string;
  /**
   *
   * @type {number}
   * @memberof ScatterPointRiskMeasuresDetails
   */
  standardDeviation: number;
  /**
   *
   * @type {number}
   * @memberof ScatterPointRiskMeasuresDetails
   */
  maximumDrawdown: number;
  /**
   *
   * @type {number}
   * @memberof ScatterPointRiskMeasuresDetails
   */
  sharpeRatio: number;
  /**
   *
   * @type {number}
   * @memberof ScatterPointRiskMeasuresDetails
   */
  sortinoRatio: number;
}
/**
 *
 * @export
 * @interface SearchAutocompleteWithPortfoliosShareClassDetails
 */
export interface SearchAutocompleteWithPortfoliosShareClassDetails {
  /**
   *
   * @type {string}
   * @memberof SearchAutocompleteWithPortfoliosShareClassDetails
   */
  isin: string;
  /**
   *
   * @type {string}
   * @memberof SearchAutocompleteWithPortfoliosShareClassDetails
   */
  code: string;
}
/**
 *
 * @export
 * @interface SectorItem
 */
export interface SectorItem {
  /**
   *
   * @type {number}
   * @memberof SectorItem
   */
  type: number;
  /**
   *
   * @type {number}
   * @memberof SectorItem
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof SectorItem
   */
  name: string;
}
/**
 *
 * @export
 * @interface SectorsList
 */
export interface SectorsList {
  /**
   *
   * @type {number}
   * @memberof SectorsList
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof SectorsList
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @interface SfdrFilterItemSchema
 */
export interface SfdrFilterItemSchema {
  /**
   *
   * @type {string}
   * @memberof SfdrFilterItemSchema
   */
  label: string;
}
/**
 *
 * @export
 * @interface ShareActionSurvey
 */
export interface ShareActionSurvey {
  /**
   *
   * @type {number}
   * @memberof ShareActionSurvey
   */
  ranking?: number;
  /**
   *
   * @type {string}
   * @memberof ShareActionSurvey
   */
  rating?: string;
  /**
   *
   * @type {string}
   * @memberof ShareActionSurvey
   */
  responsibleInvestmentGovernance?: string;
  /**
   *
   * @type {string}
   * @memberof ShareActionSurvey
   */
  climateChange?: string;
  /**
   *
   * @type {string}
   * @memberof ShareActionSurvey
   */
  stewardship?: string;
  /**
   *
   * @type {string}
   * @memberof ShareActionSurvey
   */
  biodiversity?: string;
  /**
   *
   * @type {string}
   * @memberof ShareActionSurvey
   */
  social?: string;
}
/**
 *
 * @export
 * @interface ShareActionSurveyRatingSchema
 */
export interface ShareActionSurveyRatingSchema {
  /**
   *
   * @type {string}
   * @memberof ShareActionSurveyRatingSchema
   */
  label: string;
}
/**
 *
 * @export
 * @interface ShareBucketRequestBody
 */
export interface ShareBucketRequestBody {
  /**
   *
   * @type {string}
   * @memberof ShareBucketRequestBody
   */
  bucketId: string;
  /**
   *
   * @type {Array<ResourceUser>}
   * @memberof ShareBucketRequestBody
   */
  users: Array<ResourceUser>;
}
/**
 *
 * @export
 * @interface ShareClassDetails
 */
export interface ShareClassDetails {
  /**
   *
   * @type {string}
   * @memberof ShareClassDetails
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof ShareClassDetails
   */
  inceptionDate: string;
  /**
   *
   * @type {string}
   * @memberof ShareClassDetails
   */
  classCategoryBenchmarkName: string;
  /**
   *
   * @type {string}
   * @memberof ShareClassDetails
   */
  isin: string;
}
/**
 *
 * @export
 * @interface ShareClassDetailsResponse
 */
export interface ShareClassDetailsResponse {
  /**
   *
   * @type {Array<ShareClassDetailsResponseData>}
   * @memberof ShareClassDetailsResponse
   */
  data?: Array<ShareClassDetailsResponseData>;
}
/**
 *
 * @export
 * @interface ShareClassDetailsResponseData
 */
export interface ShareClassDetailsResponseData {
  /**
   *
   * @type {ShareClassDetails}
   * @memberof ShareClassDetailsResponseData
   */
  shareClassDetails?: ShareClassDetails;
  /**
   *
   * @type {number}
   * @memberof ShareClassDetailsResponseData
   */
  ocfPct?: number;
  /**
   *
   * @type {boolean}
   * @memberof ShareClassDetailsResponseData
   */
  primaryShareClass?: boolean;
}
/**
 *
 * @export
 * @interface SharePortfolioRequestBody
 */
export interface SharePortfolioRequestBody {
  /**
   *
   * @type {string}
   * @memberof SharePortfolioRequestBody
   */
  portfolioId: string;
  /**
   *
   * @type {Array<ResourceUser>}
   * @memberof SharePortfolioRequestBody
   */
  users: Array<ResourceUser>;
}
/**
 *
 * @export
 * @interface SignificantChangePerformanceResponse
 */
export interface SignificantChangePerformanceResponse {
  /**
   *
   * @type {number}
   * @memberof SignificantChangePerformanceResponse
   */
  oneMonthPerformanceChange?: number;
  /**
   *
   * @type {number}
   * @memberof SignificantChangePerformanceResponse
   */
  YTDPerformanceChange?: number;
}
/**
 *
 * @export
 * @interface SignificantChangesItem
 */
export interface SignificantChangesItem {
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  _id: string;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  isin: string;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  fundName: string;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  msCategoryDefinition?: string;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  currencyCode?: string;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  assetClassCode?: string;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  changeType: string;
  /**
   *
   * @type {number}
   * @memberof SignificantChangesItem
   */
  oldValue?: number;
  /**
   *
   * @type {number}
   * @memberof SignificantChangesItem
   */
  newValue?: number;
  /**
   *
   * @type {Array<SignificantChangesManagerList>}
   * @memberof SignificantChangesItem
   */
  oldManagerList?: Array<SignificantChangesManagerList>;
  /**
   *
   * @type {Array<SignificantChangesManagerList>}
   * @memberof SignificantChangesItem
   */
  newManagerList?: Array<SignificantChangesManagerList>;
  /**
   *
   * @type {number}
   * @memberof SignificantChangesItem
   */
  pctChange?: number;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  esUpdatedDate: string;
  /**
   *
   * @type {number}
   * @memberof SignificantChangesItem
   */
  pctChangeAbsolute?: number;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  updatedDate?: string;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  trend: string;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  broadAssetClass?: string;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesItem
   */
  previousDate?: string;
}
/**
 *
 * @export
 * @interface SignificantChangesList
 */
export interface SignificantChangesList {
  /**
   *
   * @type {number}
   * @memberof SignificantChangesList
   */
  total: number;
  /**
   *
   * @type {Array<SignificantChangesItem>}
   * @memberof SignificantChangesList
   */
  data: Array<SignificantChangesItem>;
}
/**
 *
 * @export
 * @interface SignificantChangesManagerList
 */
export interface SignificantChangesManagerList {
  /**
   *
   * @type {string}
   * @memberof SignificantChangesManagerList
   */
  givenName: string;
  /**
   *
   * @type {string}
   * @memberof SignificantChangesManagerList
   */
  familyName: string;
}
/**
 *
 * @export
 * @interface SignificantChangesModalData
 */
export interface SignificantChangesModalData {
  /**
   *
   * @type {Array<SignificantChangesItem>}
   * @memberof SignificantChangesModalData
   */
  significantChangesHistory?: Array<SignificantChangesItem>;
  /**
   *
   * @type {FundDetailsForSignificantChanges}
   * @memberof SignificantChangesModalData
   */
  fundDetails: FundDetailsForSignificantChanges;
}
/**
 *
 * @export
 * @interface SignificantChangesThreshold
 */
export interface SignificantChangesThreshold {
  /**
   *
   * @type {string}
   * @memberof SignificantChangesThreshold
   */
  userId: string;
  /**
   *
   * @type {number}
   * @memberof SignificantChangesThreshold
   */
  fundSizeThreshold: number;
  /**
   *
   * @type {number}
   * @memberof SignificantChangesThreshold
   */
  holdingsThreshold: number;
  /**
   *
   * @type {number}
   * @memberof SignificantChangesThreshold
   */
  ocfThreshold: number;
  /**
   *
   * @type {number}
   * @memberof SignificantChangesThreshold
   */
  yieldThreshold: number;
  /**
   *
   * @type {number}
   * @memberof SignificantChangesThreshold
   */
  premiumDiscountThreshold: number;
  /**
   *
   * @type {number}
   * @memberof SignificantChangesThreshold
   */
  modifiedDurationThreshold: number;
}
/**
 *
 * @export
 * @interface StyleBoxList
 */
export interface StyleBoxList {
  /**
   *
   * @type {Array<Stylebox>}
   * @memberof StyleBoxList
   */
  data: Array<Stylebox>;
}
/**
 *
 * @export
 * @interface Stylebox
 */
export interface Stylebox {
  /**
   *
   * @type {string}
   * @memberof Stylebox
   */
  fundId: string;
  /**
   *
   * @type {string}
   * @memberof Stylebox
   */
  fundName: string;
  /**
   *
   * @type {FundBroadAssetClass}
   * @memberof Stylebox
   */
  broadAssetClass: FundBroadAssetClass;
  /**
   *
   * @type {BroadAssetClassValues}
   * @memberof Stylebox
   */
  broadAssetClassValues: BroadAssetClassValues;
  /**
   *
   * @type {BondStatisticsValues}
   * @memberof Stylebox
   */
  bondStatistics?: BondStatisticsValues;
  /**
   *
   * @type {number}
   * @memberof Stylebox
   */
  equityStyleBox?: number;
  /**
   *
   * @type {StyleboxShareClassDetails}
   * @memberof Stylebox
   */
  shareClassDetails: StyleboxShareClassDetails;
}
/**
 *
 * @export
 * @interface StyleboxShareClassDetails
 */
export interface StyleboxShareClassDetails {
  /**
   *
   * @type {string}
   * @memberof StyleboxShareClassDetails
   */
  isin: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum SummaryCardType {
  TopRelative = 'TopRelative',
  BottomRelative = 'BottomRelative',
  BiggestChangeInPremiumDiscount = 'BiggestChangeInPremiumDiscount',
  TopAbsolute = 'TopAbsolute',
  BottomAbsolute = 'BottomAbsolute',
  TopSharePriceMove = 'TopSharePriceMove',
}

/**
 *
 * @export
 * @interface TrustBoardDirectors
 */
export interface TrustBoardDirectors {
  /**
   *
   * @type {string}
   * @memberof TrustBoardDirectors
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof TrustBoardDirectors
   */
  yearStarted: string;
}
/**
 *
 * @export
 * @interface UnavailableIsinsEmailPayload
 */
export interface UnavailableIsinsEmailPayload {
  /**
   *
   * @type {Array<string>}
   * @memberof UnavailableIsinsEmailPayload
   */
  isins: Array<string>;
}
/**
 *
 * @export
 * @interface UnavailableIsinsEmailResponse
 */
export interface UnavailableIsinsEmailResponse {
  /**
   *
   * @type {number}
   * @memberof UnavailableIsinsEmailResponse
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof UnavailableIsinsEmailResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface UnboxedChartDataResponse
 */
export interface UnboxedChartDataResponse {
  /**
   *
   * @type {Array<ChartDataSeries>}
   * @memberof UnboxedChartDataResponse
   */
  data: Array<ChartDataSeries>;
  /**
   *
   * @type {Array<ChartV2DataResponseLegendData>}
   * @memberof UnboxedChartDataResponse
   */
  legendData?: Array<ChartV2DataResponseLegendData>;
}
/**
 *
 * @export
 * @interface UpdateNotificationsResponse
 */
export interface UpdateNotificationsResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateNotificationsResponse
   */
  updatedNotificationsIds: Array<string>;
}
/**
 *
 * @export
 * @interface UserChangePassword
 */
export interface UserChangePassword {
  /**
   *
   * @type {string}
   * @memberof UserChangePassword
   */
  currentPassword?: string;
  /**
   *
   * @type {string}
   * @memberof UserChangePassword
   */
  newPassword?: string;
}
/**
 *
 * @export
 * @interface UserChangePasswordResponse
 */
export interface UserChangePasswordResponse {
  /**
   *
   * @type {string}
   * @memberof UserChangePasswordResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof UserChangePasswordResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  tenant_id?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  given_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  family_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  company?: string;
}
/**
 *
 * @export
 * @interface UserUpdateProfileResponse
 */
export interface UserUpdateProfileResponse {
  /**
   *
   * @type {string}
   * @memberof UserUpdateProfileResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateProfileResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface Watchlist
 */
export interface Watchlist {
  /**
   *
   * @type {string}
   * @memberof Watchlist
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Watchlist
   */
  _id: string;
  /**
   *
   * @type {Array<WatchlistBucket>}
   * @memberof Watchlist
   */
  buckets: Array<WatchlistBucket>;
}
/**
 *
 * @export
 * @interface WatchlistAndBuckets
 */
export interface WatchlistAndBuckets {
  /**
   *
   * @type {Array<Bucket>}
   * @memberof WatchlistAndBuckets
   */
  buckets: Array<Bucket>;
  /**
   *
   * @type {Watchlist}
   * @memberof WatchlistAndBuckets
   */
  watchlist: Watchlist;
}
/**
 *
 * @export
 * @interface WatchlistAnnualisedPerformanceResponse
 */
export interface WatchlistAnnualisedPerformanceResponse {
  /**
   *
   * @type {number}
   * @memberof WatchlistAnnualisedPerformanceResponse
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof WatchlistAnnualisedPerformanceResponse
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @interface WatchlistBenchmarkResponse
 */
export interface WatchlistBenchmarkResponse {
  /**
   *
   * @type {Performance}
   * @memberof WatchlistBenchmarkResponse
   */
  benchmark?: Performance;
}
/**
 *
 * @export
 * @interface WatchlistBucket
 */
export interface WatchlistBucket {
  /**
   *
   * @type {string}
   * @memberof WatchlistBucket
   */
  _id: string;
}
/**
 *
 * @export
 * @interface WatchlistCalendarYearPerformanceResponse
 */
export interface WatchlistCalendarYearPerformanceResponse {
  /**
   *
   * @type {number}
   * @memberof WatchlistCalendarYearPerformanceResponse
   */
  total: number;
  /**
   *
   * @type {Array<WatchlistCalendarYearPerformanceResponseData>}
   * @memberof WatchlistCalendarYearPerformanceResponse
   */
  data: Array<WatchlistCalendarYearPerformanceResponseData>;
}
/**
 *
 * @export
 * @interface WatchlistCalendarYearPerformanceResponseData
 */
export interface WatchlistCalendarYearPerformanceResponseData {
  /**
   *
   * @type {string}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  fundId: string;
  /**
   *
   * @type {string}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  fundName: string;
  /**
   *
   * @type {ShareClassDetails}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  shareClassDetails: ShareClassDetails;
  /**
   *
   * @type {string}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  legalStructure: string;
  /**
   *
   * @type {string}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  assetClassCode: string;
  /**
   *
   * @type {number}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  totalReturnYtdPct?: number;
  /**
   *
   * @type {number}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  totalReturn1calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  totalReturn2calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  totalReturn3calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  totalReturn4calendarYearsAgoPct?: number;
  /**
   *
   * @type {number}
   * @memberof WatchlistCalendarYearPerformanceResponseData
   */
  totalReturn5calendarYearsAgoPct?: number;
}
/**
 *
 * @export
 * @interface WatchlistCurrencyResponse
 */
export interface WatchlistCurrencyResponse {
  /**
   *
   * @type {number}
   * @memberof WatchlistCurrencyResponse
   */
  total: number;
  /**
   *
   * @type {Array<WatchlistCurrencyResponseData>}
   * @memberof WatchlistCurrencyResponse
   */
  data: Array<WatchlistCurrencyResponseData>;
}
/**
 *
 * @export
 * @interface WatchlistCurrencyResponseData
 */
export interface WatchlistCurrencyResponseData {
  /**
   *
   * @type {string}
   * @memberof WatchlistCurrencyResponseData
   */
  fundId: string;
  /**
   *
   * @type {string}
   * @memberof WatchlistCurrencyResponseData
   */
  fundName: string;
  /**
   *
   * @type {ShareClassDetails}
   * @memberof WatchlistCurrencyResponseData
   */
  shareClassDetails: ShareClassDetails;
  /**
   *
   * @type {string}
   * @memberof WatchlistCurrencyResponseData
   */
  legalStructure: string;
  /**
   *
   * @type {string}
   * @memberof WatchlistCurrencyResponseData
   */
  assetClassCode: string;
  /**
   *
   * @type {Array<CurrencyExposure>}
   * @memberof WatchlistCurrencyResponseData
   */
  currencyExposures?: Array<CurrencyExposure>;
}
/**
 *
 * @export
 * @interface WatchlistESGResponse
 */
export interface WatchlistESGResponse {
  /**
   *
   * @type {number}
   * @memberof WatchlistESGResponse
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof WatchlistESGResponse
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @interface WatchlistEquityStatsResponse
 */
export interface WatchlistEquityStatsResponse {
  /**
   *
   * @type {number}
   * @memberof WatchlistEquityStatsResponse
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof WatchlistEquityStatsResponse
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @interface WatchlistFundsWithTotalResponse
 */
export interface WatchlistFundsWithTotalResponse {
  /**
   *
   * @type {number}
   * @memberof WatchlistFundsWithTotalResponse
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof WatchlistFundsWithTotalResponse
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @interface WatchlistGenderPayGapResponse
 */
export interface WatchlistGenderPayGapResponse {
  /**
   *
   * @type {number}
   * @memberof WatchlistGenderPayGapResponse
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof WatchlistGenderPayGapResponse
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @interface WatchlistIncomeResponse
 */
export interface WatchlistIncomeResponse {
  /**
   *
   * @type {number}
   * @memberof WatchlistIncomeResponse
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof WatchlistIncomeResponse
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @interface WatchlistQueryParameters
 */
export interface WatchlistQueryParameters {
  /**
   *
   * @type {number}
   * @memberof WatchlistQueryParameters
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof WatchlistQueryParameters
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof WatchlistQueryParameters
   */
  skip?: number;
  /**
   *
   * @type {string}
   * @memberof WatchlistQueryParameters
   */
  sortBy?: string;
  /**
   *
   * @type {string}
   * @memberof WatchlistQueryParameters
   */
  sortDirection?: WatchlistQueryParametersSortDirectionEnum;
  /**
   *
   * @type {boolean}
   * @memberof WatchlistQueryParameters
   */
  sortByFirstColumnWithValue?: boolean;
  /**
   *
   * @type {string}
   * @memberof WatchlistQueryParameters
   */
  watchlistId?: string;
  /**
   *
   * @type {string}
   * @memberof WatchlistQueryParameters
   */
  bucketId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum WatchlistQueryParametersSortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/**
 *
 * @export
 * @interface WatchlistQueryParametersAllOf
 */
export interface WatchlistQueryParametersAllOf {
  /**
   *
   * @type {string}
   * @memberof WatchlistQueryParametersAllOf
   */
  watchlistId?: string;
  /**
   *
   * @type {string}
   * @memberof WatchlistQueryParametersAllOf
   */
  bucketId?: string;
}
/**
 *
 * @export
 * @interface WatchlistSummaryResponse
 */
export interface WatchlistSummaryResponse {
  /**
   *
   * @type {number}
   * @memberof WatchlistSummaryResponse
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof WatchlistSummaryResponse
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @interface WatchlistTabResponse
 */
export interface WatchlistTabResponse {
  /**
   *
   * @type {number}
   * @memberof WatchlistTabResponse
   */
  total: number;
  /**
   *
   * @type {Array<Fund>}
   * @memberof WatchlistTabResponse
   */
  data: Array<Fund>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum YesNoOption {
  Yes = 'Yes',
  No = 'No',
}

/**
 * AbsoluteChartApi - axios parameter creator
 * @export
 */
export const AbsoluteChartApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns Absolute Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAbsoluteChartData: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/absolute`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAbsoluteChartDataWithPortfolio: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/absolute-with-portfolio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      if (portfolios) {
        localVarQueryParameter['portfolios'] = portfolios;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioAbsoluteChartData: async (
      period?: Array<string>,
      portfolioId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/portfolio-absolute`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Risk/Return Scatter Plot Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {string} [period]
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {RiskDetailsTypeEnum} [riskDetailsType] Investment trust or fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskReturnScatterPlotData: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: string,
      retainSortOrder?: boolean,
      featuredIsins?: Array<string>,
      riskDetailsType?: RiskDetailsTypeEnum,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/risk-return`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period !== undefined) {
        localVarQueryParameter['period'] = period;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (riskDetailsType !== undefined) {
        localVarQueryParameter['riskDetailsType'] = riskDetailsType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AbsoluteChartApi - functional programming interface
 * @export
 */
export const AbsoluteChartApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AbsoluteChartApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns Absolute Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAbsoluteChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAbsoluteChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAbsoluteChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponseWithPortfolio>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAbsoluteChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortfolioAbsoluteChartData(
      period?: Array<string>,
      portfolioId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPortfolioAbsoluteChartData(
          period,
          portfolioId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Risk/Return Scatter Plot Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {string} [period]
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {RiskDetailsTypeEnum} [riskDetailsType] Investment trust or fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRiskReturnScatterPlotData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: string,
      retainSortOrder?: boolean,
      featuredIsins?: Array<string>,
      riskDetailsType?: RiskDetailsTypeEnum,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ScatterPlotResponseV2>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRiskReturnScatterPlotData(
          benchmarkId,
          isins,
          period,
          retainSortOrder,
          featuredIsins,
          riskDetailsType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AbsoluteChartApi - factory interface
 * @export
 */
export const AbsoluteChartApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AbsoluteChartApiFp(configuration);
  return {
    /**
     *
     * @summary Returns Absolute Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAbsoluteChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getAbsoluteChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAbsoluteChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): AxiosPromise<ChartV2DataResponseWithPortfolio> {
      return localVarFp
        .getAbsoluteChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioAbsoluteChartData(
      period?: Array<string>,
      portfolioId?: string,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getPortfolioAbsoluteChartData(period, portfolioId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Risk/Return Scatter Plot Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {string} [period]
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {RiskDetailsTypeEnum} [riskDetailsType] Investment trust or fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskReturnScatterPlotData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: string,
      retainSortOrder?: boolean,
      featuredIsins?: Array<string>,
      riskDetailsType?: RiskDetailsTypeEnum,
      options?: any,
    ): AxiosPromise<ScatterPlotResponseV2> {
      return localVarFp
        .getRiskReturnScatterPlotData(
          benchmarkId,
          isins,
          period,
          retainSortOrder,
          featuredIsins,
          riskDetailsType,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * AbsoluteChartApi - object-oriented interface
 * @export
 * @class AbsoluteChartApi
 * @extends {BaseAPI}
 */
export class AbsoluteChartApi extends BaseAPI {
  /**
   *
   * @summary Returns Absolute Chart Data by Period and ISINs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AbsoluteChartApi
   */
  public getAbsoluteChartData(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    options?: any,
  ) {
    return AbsoluteChartApiFp(this.configuration)
      .getAbsoluteChartData(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {Array<string>} [portfolios] Portfolio IDs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AbsoluteChartApi
   */
  public getAbsoluteChartDataWithPortfolio(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    portfolios?: Array<string>,
    options?: any,
  ) {
    return AbsoluteChartApiFp(this.configuration)
      .getAbsoluteChartDataWithPortfolio(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        portfolios,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
   * @param {Array<string>} [period] Period
   * @param {string} [portfolioId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AbsoluteChartApi
   */
  public getPortfolioAbsoluteChartData(
    period?: Array<string>,
    portfolioId?: string,
    options?: any,
  ) {
    return AbsoluteChartApiFp(this.configuration)
      .getPortfolioAbsoluteChartData(period, portfolioId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Risk/Return Scatter Plot Data by Period and ISINs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {string} [period]
   * @param {boolean} [retainSortOrder]
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {RiskDetailsTypeEnum} [riskDetailsType] Investment trust or fund
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AbsoluteChartApi
   */
  public getRiskReturnScatterPlotData(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: string,
    retainSortOrder?: boolean,
    featuredIsins?: Array<string>,
    riskDetailsType?: RiskDetailsTypeEnum,
    options?: any,
  ) {
    return AbsoluteChartApiFp(this.configuration)
      .getRiskReturnScatterPlotData(
        benchmarkId,
        isins,
        period,
        retainSortOrder,
        featuredIsins,
        riskDetailsType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Post logging information to backend
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLog: async (body: object, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('postLog', 'body', body);
      const localVarPath = `/log`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Post logging information to backend
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postLog(
      body: object,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postLog(
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AppApiFp(configuration);
  return {
    /**
     *
     * @summary Post logging information to backend
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLog(body: object, options?: any): AxiosPromise<void> {
      return localVarFp
        .postLog(body, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
  /**
   *
   * @summary Post logging information to backend
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppApi
   */
  public postLog(body: object, options?: any) {
    return AppApiFp(this.configuration)
      .postLog(body, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BenchmarksApi - axios parameter creator
 * @export
 */
export const BenchmarksApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create composite benchmark
     * @param {CompositeBenchmarkBody} compositeBenchmarkBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCompositeBenchmark: async (
      compositeBenchmarkBody: CompositeBenchmarkBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'compositeBenchmarkBody' is not null or undefined
      assertParamExists(
        'createCompositeBenchmark',
        'compositeBenchmarkBody',
        compositeBenchmarkBody,
      );
      const localVarPath = `/benchmarks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        compositeBenchmarkBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete composite benchmark
     * @param {string} id id for which composite benchmark is to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCompositeBenchmark: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCompositeBenchmark', 'id', id);
      const localVarPath = `/benchmarks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get benchmark options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBenchmarkOptions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/benchmarks/options`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get composite benchmarks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompositeBenchmarks: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/benchmarks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get latest benchmark holdings by fundShareClassId
     * @param {string} fundShareClassId fundShareClassId of benchmark holdings, it can be msBenchmarkId of the fund or secId of benchmark
     * @param {Array<number>} [sectors] array of benchmark holding sector types
     * @param {Array<string>} [holdings] array of benchmark holding isins
     * @param {Array<string>} [countries] array of benchmark holding countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestBenchmarkHoldingsByParams: async (
      fundShareClassId: string,
      sectors?: Array<number>,
      holdings?: Array<string>,
      countries?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fundShareClassId' is not null or undefined
      assertParamExists(
        'getLatestBenchmarkHoldingsByParams',
        'fundShareClassId',
        fundShareClassId,
      );
      const localVarPath =
        `/benchmarks/latest-benchmark-holdings-by-params/{fundShareClassId}`.replace(
          `{${'fundShareClassId'}}`,
          encodeURIComponent(String(fundShareClassId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (sectors) {
        localVarQueryParameter['sectors'] = sectors;
      }

      if (holdings) {
        localVarQueryParameter['holdings'] = holdings;
      }

      if (countries) {
        localVarQueryParameter['countries'] = countries;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update composite benchmark
     * @param {string} id benchmark id for which composite benchmark is to be updated
     * @param {CompositeBenchmarkBody} compositeBenchmarkBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCompositeBenchmark: async (
      id: string,
      compositeBenchmarkBody: CompositeBenchmarkBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateCompositeBenchmark', 'id', id);
      // verify required parameter 'compositeBenchmarkBody' is not null or undefined
      assertParamExists(
        'updateCompositeBenchmark',
        'compositeBenchmarkBody',
        compositeBenchmarkBody,
      );
      const localVarPath = `/benchmarks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        compositeBenchmarkBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BenchmarksApi - functional programming interface
 * @export
 */
export const BenchmarksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    BenchmarksApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create composite benchmark
     * @param {CompositeBenchmarkBody} compositeBenchmarkBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCompositeBenchmark(
      compositeBenchmarkBody: CompositeBenchmarkBody,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompositeBenchmark>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createCompositeBenchmark(
          compositeBenchmarkBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete composite benchmark
     * @param {string} id id for which composite benchmark is to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCompositeBenchmark(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteCompositeBenchmark(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get benchmark options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBenchmarkOptions(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BenchmarkOptionsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBenchmarkOptions(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get composite benchmarks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompositeBenchmarks(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompositeBenchmarksResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCompositeBenchmarks(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get latest benchmark holdings by fundShareClassId
     * @param {string} fundShareClassId fundShareClassId of benchmark holdings, it can be msBenchmarkId of the fund or secId of benchmark
     * @param {Array<number>} [sectors] array of benchmark holding sector types
     * @param {Array<string>} [holdings] array of benchmark holding isins
     * @param {Array<string>} [countries] array of benchmark holding countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLatestBenchmarkHoldingsByParams(
      fundShareClassId: string,
      sectors?: Array<number>,
      holdings?: Array<string>,
      countries?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<BenchmarkHoldingByTypeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLatestBenchmarkHoldingsByParams(
          fundShareClassId,
          sectors,
          holdings,
          countries,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update composite benchmark
     * @param {string} id benchmark id for which composite benchmark is to be updated
     * @param {CompositeBenchmarkBody} compositeBenchmarkBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCompositeBenchmark(
      id: string,
      compositeBenchmarkBody: CompositeBenchmarkBody,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompositeBenchmark>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateCompositeBenchmark(
          id,
          compositeBenchmarkBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * BenchmarksApi - factory interface
 * @export
 */
export const BenchmarksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BenchmarksApiFp(configuration);
  return {
    /**
     *
     * @summary Create composite benchmark
     * @param {CompositeBenchmarkBody} compositeBenchmarkBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCompositeBenchmark(
      compositeBenchmarkBody: CompositeBenchmarkBody,
      options?: any,
    ): AxiosPromise<CompositeBenchmark> {
      return localVarFp
        .createCompositeBenchmark(compositeBenchmarkBody, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete composite benchmark
     * @param {string} id id for which composite benchmark is to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCompositeBenchmark(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteCompositeBenchmark(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get benchmark options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBenchmarkOptions(options?: any): AxiosPromise<BenchmarkOptionsResponse> {
      return localVarFp
        .getBenchmarkOptions(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get composite benchmarks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompositeBenchmarks(
      options?: any,
    ): AxiosPromise<CompositeBenchmarksResponse> {
      return localVarFp
        .getCompositeBenchmarks(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get latest benchmark holdings by fundShareClassId
     * @param {string} fundShareClassId fundShareClassId of benchmark holdings, it can be msBenchmarkId of the fund or secId of benchmark
     * @param {Array<number>} [sectors] array of benchmark holding sector types
     * @param {Array<string>} [holdings] array of benchmark holding isins
     * @param {Array<string>} [countries] array of benchmark holding countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestBenchmarkHoldingsByParams(
      fundShareClassId: string,
      sectors?: Array<number>,
      holdings?: Array<string>,
      countries?: Array<string>,
      options?: any,
    ): AxiosPromise<BenchmarkHoldingByTypeResponse> {
      return localVarFp
        .getLatestBenchmarkHoldingsByParams(
          fundShareClassId,
          sectors,
          holdings,
          countries,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update composite benchmark
     * @param {string} id benchmark id for which composite benchmark is to be updated
     * @param {CompositeBenchmarkBody} compositeBenchmarkBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCompositeBenchmark(
      id: string,
      compositeBenchmarkBody: CompositeBenchmarkBody,
      options?: any,
    ): AxiosPromise<CompositeBenchmark> {
      return localVarFp
        .updateCompositeBenchmark(id, compositeBenchmarkBody, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * BenchmarksApi - object-oriented interface
 * @export
 * @class BenchmarksApi
 * @extends {BaseAPI}
 */
export class BenchmarksApi extends BaseAPI {
  /**
   *
   * @summary Create composite benchmark
   * @param {CompositeBenchmarkBody} compositeBenchmarkBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchmarksApi
   */
  public createCompositeBenchmark(
    compositeBenchmarkBody: CompositeBenchmarkBody,
    options?: any,
  ) {
    return BenchmarksApiFp(this.configuration)
      .createCompositeBenchmark(compositeBenchmarkBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete composite benchmark
   * @param {string} id id for which composite benchmark is to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchmarksApi
   */
  public deleteCompositeBenchmark(id: string, options?: any) {
    return BenchmarksApiFp(this.configuration)
      .deleteCompositeBenchmark(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get benchmark options
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchmarksApi
   */
  public getBenchmarkOptions(options?: any) {
    return BenchmarksApiFp(this.configuration)
      .getBenchmarkOptions(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get composite benchmarks
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchmarksApi
   */
  public getCompositeBenchmarks(options?: any) {
    return BenchmarksApiFp(this.configuration)
      .getCompositeBenchmarks(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get latest benchmark holdings by fundShareClassId
   * @param {string} fundShareClassId fundShareClassId of benchmark holdings, it can be msBenchmarkId of the fund or secId of benchmark
   * @param {Array<number>} [sectors] array of benchmark holding sector types
   * @param {Array<string>} [holdings] array of benchmark holding isins
   * @param {Array<string>} [countries] array of benchmark holding countries
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchmarksApi
   */
  public getLatestBenchmarkHoldingsByParams(
    fundShareClassId: string,
    sectors?: Array<number>,
    holdings?: Array<string>,
    countries?: Array<string>,
    options?: any,
  ) {
    return BenchmarksApiFp(this.configuration)
      .getLatestBenchmarkHoldingsByParams(
        fundShareClassId,
        sectors,
        holdings,
        countries,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update composite benchmark
   * @param {string} id benchmark id for which composite benchmark is to be updated
   * @param {CompositeBenchmarkBody} compositeBenchmarkBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchmarksApi
   */
  public updateCompositeBenchmark(
    id: string,
    compositeBenchmarkBody: CompositeBenchmarkBody,
    options?: any,
  ) {
    return BenchmarksApiFp(this.configuration)
      .updateCompositeBenchmark(id, compositeBenchmarkBody, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BucketsApi - axios parameter creator
 * @export
 */
export const BucketsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Accepts the invitation of another user to a bucket.
     * @param {string} [token] Token used to validate the invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptSharedBucketInvite: async (
      token?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/buckets/accept-invitation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add funds to chosen bucket
     * @param {ManageWatchlistResource} manageWatchlistResource Add funds to bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFundsToBucket: async (
      manageWatchlistResource: ManageWatchlistResource,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'manageWatchlistResource' is not null or undefined
      assertParamExists(
        'addFundsToBucket',
        'manageWatchlistResource',
        manageWatchlistResource,
      );
      const localVarPath = `/buckets/add-funds`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        manageWatchlistResource,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Assign fund as focus fund in the bucket
     * @param {Array<AssignFocusFundBody>} assignFocusFundBody Assign focus fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignFocusFund: async (
      assignFocusFundBody: Array<AssignFocusFundBody>,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assignFocusFundBody' is not null or undefined
      assertParamExists(
        'assignFocusFund',
        'assignFocusFundBody',
        assignFocusFundBody,
      );
      const localVarPath = `/watchlist/assign-focus-fund`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        assignFocusFundBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Change funds bucket
     * @param {ManageWatchlistResource} manageWatchlistResource Change funds bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeFundsBucket: async (
      manageWatchlistResource: ManageWatchlistResource,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'manageWatchlistResource' is not null or undefined
      assertParamExists(
        'changeFundsBucket',
        'manageWatchlistResource',
        manageWatchlistResource,
      );
      const localVarPath = `/buckets/change-funds-bucket`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        manageWatchlistResource,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create new bucket
     * @param {BucketRequestBody} bucketRequestBody Add new bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewBucket: async (
      bucketRequestBody: BucketRequestBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bucketRequestBody' is not null or undefined
      assertParamExists(
        'createNewBucket',
        'bucketRequestBody',
        bucketRequestBody,
      );
      const localVarPath = `/buckets/create-bucket`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bucketRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete bucket
     * @param {string} id The id of the bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBucket: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteBucket', 'id', id);
      const localVarPath = `/buckets/delete-bucket/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove funds from bucket
     * @param {ManageWatchlistResource} manageWatchlistResource Remove funds from bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFundsFromBucket: async (
      manageWatchlistResource: ManageWatchlistResource,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'manageWatchlistResource' is not null or undefined
      assertParamExists(
        'removeFundsFromBucket',
        'manageWatchlistResource',
        manageWatchlistResource,
      );
      const localVarPath = `/buckets/remove-funds`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        manageWatchlistResource,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove shared bucket
     * @param {string} id The id of the bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeSharedBucket: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('removeSharedBucket', 'id', id);
      const localVarPath = `/buckets/remove-shared-bucket/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Share bucket with users
     * @param {ShareBucketRequestBody} shareBucketRequestBody Array of users to be given access to a bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareBucket: async (
      shareBucketRequestBody: ShareBucketRequestBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'shareBucketRequestBody' is not null or undefined
      assertParamExists(
        'shareBucket',
        'shareBucketRequestBody',
        shareBucketRequestBody,
      );
      const localVarPath = `/buckets/share`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shareBucketRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch bucket property
     * @param {string} id The id of the bucket
     * @param {BucketUpdateRequestBody} bucketUpdateRequestBody Patch bucket property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBucket: async (
      id: string,
      bucketUpdateRequestBody: BucketUpdateRequestBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateBucket', 'id', id);
      // verify required parameter 'bucketUpdateRequestBody' is not null or undefined
      assertParamExists(
        'updateBucket',
        'bucketUpdateRequestBody',
        bucketUpdateRequestBody,
      );
      const localVarPath = `/buckets/update-bucket/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bucketUpdateRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BucketsApi - functional programming interface
 * @export
 */
export const BucketsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BucketsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Accepts the invitation of another user to a bucket.
     * @param {string} [token] Token used to validate the invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async acceptSharedBucketInvite(
      token?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse200>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.acceptSharedBucketInvite(
          token,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add funds to chosen bucket
     * @param {ManageWatchlistResource} manageWatchlistResource Add funds to bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addFundsToBucket(
      manageWatchlistResource: ManageWatchlistResource,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addFundsToBucket(
          manageWatchlistResource,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Assign fund as focus fund in the bucket
     * @param {Array<AssignFocusFundBody>} assignFocusFundBody Assign focus fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignFocusFund(
      assignFocusFundBody: Array<AssignFocusFundBody>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assignFocusFund(
        assignFocusFundBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Change funds bucket
     * @param {ManageWatchlistResource} manageWatchlistResource Change funds bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeFundsBucket(
      manageWatchlistResource: ManageWatchlistResource,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.changeFundsBucket(
          manageWatchlistResource,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create new bucket
     * @param {BucketRequestBody} bucketRequestBody Add new bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNewBucket(
      bucketRequestBody: BucketRequestBody,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createNewBucket(
        bucketRequestBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete bucket
     * @param {string} id The id of the bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBucket(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBucket(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Remove funds from bucket
     * @param {ManageWatchlistResource} manageWatchlistResource Remove funds from bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeFundsFromBucket(
      manageWatchlistResource: ManageWatchlistResource,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeFundsFromBucket(
          manageWatchlistResource,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Remove shared bucket
     * @param {string} id The id of the bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeSharedBucket(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeSharedBucket(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Share bucket with users
     * @param {ShareBucketRequestBody} shareBucketRequestBody Array of users to be given access to a bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shareBucket(
      shareBucketRequestBody: ShareBucketRequestBody,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shareBucket(
        shareBucketRequestBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Patch bucket property
     * @param {string} id The id of the bucket
     * @param {BucketUpdateRequestBody} bucketUpdateRequestBody Patch bucket property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBucket(
      id: string,
      bucketUpdateRequestBody: BucketUpdateRequestBody,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBucket(
        id,
        bucketUpdateRequestBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * BucketsApi - factory interface
 * @export
 */
export const BucketsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BucketsApiFp(configuration);
  return {
    /**
     *
     * @summary Accepts the invitation of another user to a bucket.
     * @param {string} [token] Token used to validate the invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptSharedBucketInvite(
      token?: string,
      options?: any,
    ): AxiosPromise<InlineResponse200> {
      return localVarFp
        .acceptSharedBucketInvite(token, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Add funds to chosen bucket
     * @param {ManageWatchlistResource} manageWatchlistResource Add funds to bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFundsToBucket(
      manageWatchlistResource: ManageWatchlistResource,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .addFundsToBucket(manageWatchlistResource, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Assign fund as focus fund in the bucket
     * @param {Array<AssignFocusFundBody>} assignFocusFundBody Assign focus fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignFocusFund(
      assignFocusFundBody: Array<AssignFocusFundBody>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .assignFocusFund(assignFocusFundBody, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Change funds bucket
     * @param {ManageWatchlistResource} manageWatchlistResource Change funds bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeFundsBucket(
      manageWatchlistResource: ManageWatchlistResource,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .changeFundsBucket(manageWatchlistResource, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create new bucket
     * @param {BucketRequestBody} bucketRequestBody Add new bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewBucket(
      bucketRequestBody: BucketRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createNewBucket(bucketRequestBody, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete bucket
     * @param {string} id The id of the bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBucket(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteBucket(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Remove funds from bucket
     * @param {ManageWatchlistResource} manageWatchlistResource Remove funds from bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFundsFromBucket(
      manageWatchlistResource: ManageWatchlistResource,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .removeFundsFromBucket(manageWatchlistResource, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Remove shared bucket
     * @param {string} id The id of the bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeSharedBucket(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeSharedBucket(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Share bucket with users
     * @param {ShareBucketRequestBody} shareBucketRequestBody Array of users to be given access to a bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shareBucket(
      shareBucketRequestBody: ShareBucketRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .shareBucket(shareBucketRequestBody, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Patch bucket property
     * @param {string} id The id of the bucket
     * @param {BucketUpdateRequestBody} bucketUpdateRequestBody Patch bucket property
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBucket(
      id: string,
      bucketUpdateRequestBody: BucketUpdateRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateBucket(id, bucketUpdateRequestBody, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * BucketsApi - object-oriented interface
 * @export
 * @class BucketsApi
 * @extends {BaseAPI}
 */
export class BucketsApi extends BaseAPI {
  /**
   *
   * @summary Accepts the invitation of another user to a bucket.
   * @param {string} [token] Token used to validate the invitation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketsApi
   */
  public acceptSharedBucketInvite(token?: string, options?: any) {
    return BucketsApiFp(this.configuration)
      .acceptSharedBucketInvite(token, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add funds to chosen bucket
   * @param {ManageWatchlistResource} manageWatchlistResource Add funds to bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketsApi
   */
  public addFundsToBucket(
    manageWatchlistResource: ManageWatchlistResource,
    options?: any,
  ) {
    return BucketsApiFp(this.configuration)
      .addFundsToBucket(manageWatchlistResource, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Assign fund as focus fund in the bucket
   * @param {Array<AssignFocusFundBody>} assignFocusFundBody Assign focus fund
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketsApi
   */
  public assignFocusFund(
    assignFocusFundBody: Array<AssignFocusFundBody>,
    options?: any,
  ) {
    return BucketsApiFp(this.configuration)
      .assignFocusFund(assignFocusFundBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Change funds bucket
   * @param {ManageWatchlistResource} manageWatchlistResource Change funds bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketsApi
   */
  public changeFundsBucket(
    manageWatchlistResource: ManageWatchlistResource,
    options?: any,
  ) {
    return BucketsApiFp(this.configuration)
      .changeFundsBucket(manageWatchlistResource, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create new bucket
   * @param {BucketRequestBody} bucketRequestBody Add new bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketsApi
   */
  public createNewBucket(bucketRequestBody: BucketRequestBody, options?: any) {
    return BucketsApiFp(this.configuration)
      .createNewBucket(bucketRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete bucket
   * @param {string} id The id of the bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketsApi
   */
  public deleteBucket(id: string, options?: any) {
    return BucketsApiFp(this.configuration)
      .deleteBucket(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove funds from bucket
   * @param {ManageWatchlistResource} manageWatchlistResource Remove funds from bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketsApi
   */
  public removeFundsFromBucket(
    manageWatchlistResource: ManageWatchlistResource,
    options?: any,
  ) {
    return BucketsApiFp(this.configuration)
      .removeFundsFromBucket(manageWatchlistResource, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove shared bucket
   * @param {string} id The id of the bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketsApi
   */
  public removeSharedBucket(id: string, options?: any) {
    return BucketsApiFp(this.configuration)
      .removeSharedBucket(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Share bucket with users
   * @param {ShareBucketRequestBody} shareBucketRequestBody Array of users to be given access to a bucket
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketsApi
   */
  public shareBucket(
    shareBucketRequestBody: ShareBucketRequestBody,
    options?: any,
  ) {
    return BucketsApiFp(this.configuration)
      .shareBucket(shareBucketRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch bucket property
   * @param {string} id The id of the bucket
   * @param {BucketUpdateRequestBody} bucketUpdateRequestBody Patch bucket property
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BucketsApi
   */
  public updateBucket(
    id: string,
    bucketUpdateRequestBody: BucketUpdateRequestBody,
    options?: any,
  ) {
    return BucketsApiFp(this.configuration)
      .updateBucket(id, bucketUpdateRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ChartsApi - axios parameter creator
 * @export
 */
export const ChartsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Fetches the correlation matrix by isins
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [dateRange] Chart Range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCorrelationMatrix: async (
      isins?: Array<string>,
      dateRange?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/correlation-matrix`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (dateRange) {
        localVarQueryParameter['dateRange'] = dateRange;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Market Data by Date Range
     * @param {Array<string>} [dateRange] Chart Range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarketDataByRange: async (
      dateRange?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/market-data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (dateRange) {
        localVarQueryParameter['dateRange'] = dateRange;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChartsApi - functional programming interface
 * @export
 */
export const ChartsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ChartsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Fetches the correlation matrix by isins
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [dateRange] Chart Range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCorrelationMatrix(
      isins?: Array<string>,
      dateRange?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CorrelationMatrixResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCorrelationMatrix(
          isins,
          dateRange,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Market Data by Date Range
     * @param {Array<string>} [dateRange] Chart Range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMarketDataByRange(
      dateRange?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MarketDataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMarketDataByRange(
          dateRange,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ChartsApi - factory interface
 * @export
 */
export const ChartsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ChartsApiFp(configuration);
  return {
    /**
     *
     * @summary Fetches the correlation matrix by isins
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [dateRange] Chart Range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCorrelationMatrix(
      isins?: Array<string>,
      dateRange?: Array<string>,
      options?: any,
    ): AxiosPromise<CorrelationMatrixResponse> {
      return localVarFp
        .getCorrelationMatrix(isins, dateRange, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Market Data by Date Range
     * @param {Array<string>} [dateRange] Chart Range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarketDataByRange(
      dateRange?: Array<string>,
      options?: any,
    ): AxiosPromise<MarketDataResponse> {
      return localVarFp
        .getMarketDataByRange(dateRange, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ChartsApi - object-oriented interface
 * @export
 * @class ChartsApi
 * @extends {BaseAPI}
 */
export class ChartsApi extends BaseAPI {
  /**
   *
   * @summary Fetches the correlation matrix by isins
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [dateRange] Chart Range
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsApi
   */
  public getCorrelationMatrix(
    isins?: Array<string>,
    dateRange?: Array<string>,
    options?: any,
  ) {
    return ChartsApiFp(this.configuration)
      .getCorrelationMatrix(isins, dateRange, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Market Data by Date Range
   * @param {Array<string>} [dateRange] Chart Range
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsApi
   */
  public getMarketDataByRange(dateRange?: Array<string>, options?: any) {
    return ChartsApiFp(this.configuration)
      .getMarketDataByRange(dateRange, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ChartsV2Api - axios parameter creator
 * @export
 */
export const ChartsV2ApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns Absolute Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAbsoluteChartData: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/absolute`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAbsoluteChartDataWithPortfolio: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/absolute-with-portfolio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      if (portfolios) {
        localVarQueryParameter['portfolios'] = portfolios;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioAbsoluteChartData: async (
      period?: Array<string>,
      portfolioId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/portfolio-absolute`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Portfolio Relative Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioRelativeChartData: async (
      period?: Array<string>,
      portfolioId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/portfolio-relative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Portfolio Risk Return Scatter Plot Data by Period
     * @param {string} [portfolioId]
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioRiskReturnScatterPlotData: async (
      portfolioId?: string,
      period?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/portfolio-risk-return`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (period !== undefined) {
        localVarQueryParameter['period'] = period;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Premium Discount Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPremiumDiscountChartData: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/premium-discount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelativeChartData: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/relative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelativeChartDataWithPortfolio: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/relative-with-portfolio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      if (portfolios) {
        localVarQueryParameter['portfolios'] = portfolios;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Risk/Return Scatter Plot Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {string} [period]
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {RiskDetailsTypeEnum} [riskDetailsType] Investment trust or fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskReturnScatterPlotData: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: string,
      retainSortOrder?: boolean,
      featuredIsins?: Array<string>,
      riskDetailsType?: RiskDetailsTypeEnum,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/risk-return`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period !== undefined) {
        localVarQueryParameter['period'] = period;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (riskDetailsType !== undefined) {
        localVarQueryParameter['riskDetailsType'] = riskDetailsType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns SharePrice vs NAV Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<number>} [investmentTrustReturn] Multiple Rips Return Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSharePriceVsNavChartData: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      investmentTrustReturn?: Array<number>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/share-price-vs-nav`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      if (investmentTrustReturn) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChartsV2Api - functional programming interface
 * @export
 */
export const ChartsV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ChartsV2ApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns Absolute Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAbsoluteChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAbsoluteChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAbsoluteChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponseWithPortfolio>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAbsoluteChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortfolioAbsoluteChartData(
      period?: Array<string>,
      portfolioId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPortfolioAbsoluteChartData(
          period,
          portfolioId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Portfolio Relative Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortfolioRelativeChartData(
      period?: Array<string>,
      portfolioId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPortfolioRelativeChartData(
          period,
          portfolioId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Portfolio Risk Return Scatter Plot Data by Period
     * @param {string} [portfolioId]
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortfolioRiskReturnScatterPlotData(
      portfolioId?: string,
      period?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PortfolioScatterPlotResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPortfolioRiskReturnScatterPlotData(
          portfolioId,
          period,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Premium Discount Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPremiumDiscountChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPremiumDiscountChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelativeChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRelativeChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelativeChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponseWithPortfolio>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRelativeChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Risk/Return Scatter Plot Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {string} [period]
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {RiskDetailsTypeEnum} [riskDetailsType] Investment trust or fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRiskReturnScatterPlotData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: string,
      retainSortOrder?: boolean,
      featuredIsins?: Array<string>,
      riskDetailsType?: RiskDetailsTypeEnum,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ScatterPlotResponseV2>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRiskReturnScatterPlotData(
          benchmarkId,
          isins,
          period,
          retainSortOrder,
          featuredIsins,
          riskDetailsType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns SharePrice vs NAV Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<number>} [investmentTrustReturn] Multiple Rips Return Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSharePriceVsNavChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      investmentTrustReturn?: Array<number>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSharePriceVsNavChartData(
          benchmarkId,
          isins,
          period,
          featuredIsins,
          retainSortOrder,
          investmentTrustReturn,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ChartsV2Api - factory interface
 * @export
 */
export const ChartsV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ChartsV2ApiFp(configuration);
  return {
    /**
     *
     * @summary Returns Absolute Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAbsoluteChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getAbsoluteChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAbsoluteChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): AxiosPromise<ChartV2DataResponseWithPortfolio> {
      return localVarFp
        .getAbsoluteChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioAbsoluteChartData(
      period?: Array<string>,
      portfolioId?: string,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getPortfolioAbsoluteChartData(period, portfolioId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Portfolio Relative Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioRelativeChartData(
      period?: Array<string>,
      portfolioId?: string,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getPortfolioRelativeChartData(period, portfolioId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Portfolio Risk Return Scatter Plot Data by Period
     * @param {string} [portfolioId]
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioRiskReturnScatterPlotData(
      portfolioId?: string,
      period?: string,
      options?: any,
    ): AxiosPromise<PortfolioScatterPlotResponse> {
      return localVarFp
        .getPortfolioRiskReturnScatterPlotData(portfolioId, period, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Premium Discount Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPremiumDiscountChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getPremiumDiscountChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelativeChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getRelativeChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelativeChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): AxiosPromise<ChartV2DataResponseWithPortfolio> {
      return localVarFp
        .getRelativeChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Risk/Return Scatter Plot Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {string} [period]
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {RiskDetailsTypeEnum} [riskDetailsType] Investment trust or fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskReturnScatterPlotData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: string,
      retainSortOrder?: boolean,
      featuredIsins?: Array<string>,
      riskDetailsType?: RiskDetailsTypeEnum,
      options?: any,
    ): AxiosPromise<ScatterPlotResponseV2> {
      return localVarFp
        .getRiskReturnScatterPlotData(
          benchmarkId,
          isins,
          period,
          retainSortOrder,
          featuredIsins,
          riskDetailsType,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns SharePrice vs NAV Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<number>} [investmentTrustReturn] Multiple Rips Return Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSharePriceVsNavChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      investmentTrustReturn?: Array<number>,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getSharePriceVsNavChartData(
          benchmarkId,
          isins,
          period,
          featuredIsins,
          retainSortOrder,
          investmentTrustReturn,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ChartsV2Api - object-oriented interface
 * @export
 * @class ChartsV2Api
 * @extends {BaseAPI}
 */
export class ChartsV2Api extends BaseAPI {
  /**
   *
   * @summary Returns Absolute Chart Data by Period and ISINs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsV2Api
   */
  public getAbsoluteChartData(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    options?: any,
  ) {
    return ChartsV2ApiFp(this.configuration)
      .getAbsoluteChartData(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {Array<string>} [portfolios] Portfolio IDs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsV2Api
   */
  public getAbsoluteChartDataWithPortfolio(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    portfolios?: Array<string>,
    options?: any,
  ) {
    return ChartsV2ApiFp(this.configuration)
      .getAbsoluteChartDataWithPortfolio(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        portfolios,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
   * @param {Array<string>} [period] Period
   * @param {string} [portfolioId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsV2Api
   */
  public getPortfolioAbsoluteChartData(
    period?: Array<string>,
    portfolioId?: string,
    options?: any,
  ) {
    return ChartsV2ApiFp(this.configuration)
      .getPortfolioAbsoluteChartData(period, portfolioId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Portfolio Relative Chart Data by Period and ISINs
   * @param {Array<string>} [period] Period
   * @param {string} [portfolioId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsV2Api
   */
  public getPortfolioRelativeChartData(
    period?: Array<string>,
    portfolioId?: string,
    options?: any,
  ) {
    return ChartsV2ApiFp(this.configuration)
      .getPortfolioRelativeChartData(period, portfolioId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Portfolio Risk Return Scatter Plot Data by Period
   * @param {string} [portfolioId]
   * @param {string} [period]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsV2Api
   */
  public getPortfolioRiskReturnScatterPlotData(
    portfolioId?: string,
    period?: string,
    options?: any,
  ) {
    return ChartsV2ApiFp(this.configuration)
      .getPortfolioRiskReturnScatterPlotData(portfolioId, period, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Premium Discount Chart Data by Period and ISINs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsV2Api
   */
  public getPremiumDiscountChartData(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    options?: any,
  ) {
    return ChartsV2ApiFp(this.configuration)
      .getPremiumDiscountChartData(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Relative Chart Data by Period and ISINs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsV2Api
   */
  public getRelativeChartData(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    options?: any,
  ) {
    return ChartsV2ApiFp(this.configuration)
      .getRelativeChartData(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {Array<string>} [portfolios] Portfolio IDs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsV2Api
   */
  public getRelativeChartDataWithPortfolio(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    portfolios?: Array<string>,
    options?: any,
  ) {
    return ChartsV2ApiFp(this.configuration)
      .getRelativeChartDataWithPortfolio(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        portfolios,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Risk/Return Scatter Plot Data by Period and ISINs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {string} [period]
   * @param {boolean} [retainSortOrder]
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {RiskDetailsTypeEnum} [riskDetailsType] Investment trust or fund
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsV2Api
   */
  public getRiskReturnScatterPlotData(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: string,
    retainSortOrder?: boolean,
    featuredIsins?: Array<string>,
    riskDetailsType?: RiskDetailsTypeEnum,
    options?: any,
  ) {
    return ChartsV2ApiFp(this.configuration)
      .getRiskReturnScatterPlotData(
        benchmarkId,
        isins,
        period,
        retainSortOrder,
        featuredIsins,
        riskDetailsType,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns SharePrice vs NAV Chart Data by Period and ISINs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {Array<number>} [investmentTrustReturn] Multiple Rips Return Type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChartsV2Api
   */
  public getSharePriceVsNavChartData(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    investmentTrustReturn?: Array<number>,
    options?: any,
  ) {
    return ChartsV2ApiFp(this.configuration)
      .getSharePriceVsNavChartData(
        benchmarkId,
        isins,
        period,
        featuredIsins,
        retainSortOrder,
        investmentTrustReturn,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CurrencyApi - axios parameter creator
 * @export
 */
export const CurrencyApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Fetch rates for base currency
     * @param {string} code ISO 4217 alpha code for base currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrencyRatesByCode: async (
      code: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('getCurrencyRatesByCode', 'code', code);
      const localVarPath = `/currency/{code}/rates`.replace(
        `{${'code'}}`,
        encodeURIComponent(String(code)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CurrencyApi - functional programming interface
 * @export
 */
export const CurrencyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CurrencyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Fetch rates for base currency
     * @param {string} code ISO 4217 alpha code for base currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrencyRatesByCode(
      code: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetBaseCurrencyRatesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrencyRatesByCode(code, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CurrencyApi - factory interface
 * @export
 */
export const CurrencyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CurrencyApiFp(configuration);
  return {
    /**
     *
     * @summary Fetch rates for base currency
     * @param {string} code ISO 4217 alpha code for base currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrencyRatesByCode(
      code: string,
      options?: any,
    ): AxiosPromise<GetBaseCurrencyRatesResponse> {
      return localVarFp
        .getCurrencyRatesByCode(code, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CurrencyApi - object-oriented interface
 * @export
 * @class CurrencyApi
 * @extends {BaseAPI}
 */
export class CurrencyApi extends BaseAPI {
  /**
   *
   * @summary Fetch rates for base currency
   * @param {string} code ISO 4217 alpha code for base currency
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CurrencyApi
   */
  public getCurrencyRatesByCode(code: string, options?: any) {
    return CurrencyApiFp(this.configuration)
      .getCurrencyRatesByCode(code, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns all significant changes relevant to the users watchlist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboardSignificantChanges: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/significant-changes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all performance cards displayed on the dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboardSummaryCards: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dashboard/summary-cards`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DashboardApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns all significant changes relevant to the users watchlist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDashboardSignificantChanges(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SignificantChangesList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDashboardSignificantChanges(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all performance cards displayed on the dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDashboardSummaryCards(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DashboardSummaryCardItem>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDashboardSummaryCards(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DashboardApiFp(configuration);
  return {
    /**
     *
     * @summary Returns all significant changes relevant to the users watchlist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboardSignificantChanges(
      options?: any,
    ): AxiosPromise<SignificantChangesList> {
      return localVarFp
        .getDashboardSignificantChanges(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all performance cards displayed on the dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboardSummaryCards(
      options?: any,
    ): AxiosPromise<Array<DashboardSummaryCardItem>> {
      return localVarFp
        .getDashboardSummaryCards(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
  /**
   *
   * @summary Returns all significant changes relevant to the users watchlist
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApi
   */
  public getDashboardSignificantChanges(options?: any) {
    return DashboardApiFp(this.configuration)
      .getDashboardSignificantChanges(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all performance cards displayed on the dashboard
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardApi
   */
  public getDashboardSummaryCards(options?: any) {
    return DashboardApiFp(this.configuration)
      .getDashboardSummaryCards(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ExploreApi - axios parameter creator
 * @export
 */
export const ExploreApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Performs search for funds on explore page
     * @param {ExploreSearchQueryBody} exploreSearchQueryBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exploreSearch: async (
      exploreSearchQueryBody: ExploreSearchQueryBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'exploreSearchQueryBody' is not null or undefined
      assertParamExists(
        'exploreSearch',
        'exploreSearchQueryBody',
        exploreSearchQueryBody,
      );
      const localVarPath = `/explore/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        exploreSearchQueryBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetches all initial values to populate fitlers on explore page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExploreFilters: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/explore/default-filters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExploreApi - functional programming interface
 * @export
 */
export const ExploreApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExploreApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Performs search for funds on explore page
     * @param {ExploreSearchQueryBody} exploreSearchQueryBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exploreSearch(
      exploreSearchQueryBody: ExploreSearchQueryBody,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ExploreSearchResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exploreSearch(
        exploreSearchQueryBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Fetches all initial values to populate fitlers on explore page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExploreFilters(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ExploreFiltersItemsSchema>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getExploreFilters(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ExploreApi - factory interface
 * @export
 */
export const ExploreApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExploreApiFp(configuration);
  return {
    /**
     *
     * @summary Performs search for funds on explore page
     * @param {ExploreSearchQueryBody} exploreSearchQueryBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exploreSearch(
      exploreSearchQueryBody: ExploreSearchQueryBody,
      options?: any,
    ): AxiosPromise<ExploreSearchResponse> {
      return localVarFp
        .exploreSearch(exploreSearchQueryBody, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetches all initial values to populate fitlers on explore page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExploreFilters(options?: any): AxiosPromise<ExploreFiltersItemsSchema> {
      return localVarFp
        .getExploreFilters(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ExploreApi - object-oriented interface
 * @export
 * @class ExploreApi
 * @extends {BaseAPI}
 */
export class ExploreApi extends BaseAPI {
  /**
   *
   * @summary Performs search for funds on explore page
   * @param {ExploreSearchQueryBody} exploreSearchQueryBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExploreApi
   */
  public exploreSearch(
    exploreSearchQueryBody: ExploreSearchQueryBody,
    options?: any,
  ) {
    return ExploreApiFp(this.configuration)
      .exploreSearch(exploreSearchQueryBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetches all initial values to populate fitlers on explore page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExploreApi
   */
  public getExploreFilters(options?: any) {
    return ExploreApiFp(this.configuration)
      .getExploreFilters(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FundsApi - axios parameter creator
 * @export
 */
export const FundsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns pdf report for fund
     * @param {string} isin Isin of the fund
     * @param {string} currencyCode ISO 4217 alpha code for currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateReport: async (
      isin: string,
      currencyCode: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'isin' is not null or undefined
      assertParamExists('generateReport', 'isin', isin);
      // verify required parameter 'currencyCode' is not null or undefined
      assertParamExists('generateReport', 'currencyCode', currencyCode);
      const localVarPath = `/funds/{isin}/report`.replace(
        `{${'isin'}}`,
        encodeURIComponent(String(isin)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (currencyCode !== undefined) {
        localVarQueryParameter['currencyCode'] = currencyCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns fund by isin
     * @param {string} isin Isin of the fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFundByIsin: async (
      isin: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'isin' is not null or undefined
      assertParamExists('getFundByIsin', 'isin', isin);
      const localVarPath = `/funds/{isin}`.replace(
        `{${'isin'}}`,
        encodeURIComponent(String(isin)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns multiple funds to compare
     * @param {Array<string>} [isins] Fund ISINs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFundsToCompare: async (
      isins?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/funds/get-funds-to-compare`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns investors for share class
     * @param {Array<string>} [isins] Isins of the target share classes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvestorsForShareClasses: async (
      isins?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/funds/share-classes/investors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns multiple funds from array of isins
     * @param {Array<string>} [isins] Fund ISINs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMultipleFunds: async (
      isins?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/funds`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns ocf and shareclassdetails of funds that have the same fund id
     * @param {string} fundId Fund Id to be used for querying
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShareClassesByFundId: async (
      fundId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fundId' is not null or undefined
      assertParamExists('getShareClassesByFundId', 'fundId', fundId);
      const localVarPath = `/funds/share-class-details/{fundId}`.replace(
        `{${'fundId'}}`,
        encodeURIComponent(String(fundId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FundsApi - functional programming interface
 * @export
 */
export const FundsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FundsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns pdf report for fund
     * @param {string} isin Isin of the fund
     * @param {string} currencyCode ISO 4217 alpha code for currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateReport(
      isin: string,
      currencyCode: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateReport(
        isin,
        currencyCode,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns fund by isin
     * @param {string} isin Isin of the fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFundByIsin(
      isin: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetFundByIsinResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFundByIsin(
        isin,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns multiple funds to compare
     * @param {Array<string>} [isins] Fund ISINs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFundsToCompare(
      isins?: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Fund>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFundsToCompare(isins, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns investors for share class
     * @param {Array<string>} [isins] Isins of the target share classes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInvestorsForShareClasses(
      isins?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<Investor>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInvestorsForShareClasses(
          isins,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns multiple funds from array of isins
     * @param {Array<string>} [isins] Fund ISINs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMultipleFunds(
      isins?: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Fund>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMultipleFunds(isins, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns ocf and shareclassdetails of funds that have the same fund id
     * @param {string} fundId Fund Id to be used for querying
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getShareClassesByFundId(
      fundId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ShareClassDetailsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getShareClassesByFundId(
          fundId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FundsApi - factory interface
 * @export
 */
export const FundsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FundsApiFp(configuration);
  return {
    /**
     *
     * @summary Returns pdf report for fund
     * @param {string} isin Isin of the fund
     * @param {string} currencyCode ISO 4217 alpha code for currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateReport(
      isin: string,
      currencyCode: string,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .generateReport(isin, currencyCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns fund by isin
     * @param {string} isin Isin of the fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFundByIsin(
      isin: string,
      options?: any,
    ): AxiosPromise<GetFundByIsinResponse> {
      return localVarFp
        .getFundByIsin(isin, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns multiple funds to compare
     * @param {Array<string>} [isins] Fund ISINs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFundsToCompare(
      isins?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<Fund>> {
      return localVarFp
        .getFundsToCompare(isins, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns investors for share class
     * @param {Array<string>} [isins] Isins of the target share classes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvestorsForShareClasses(
      isins?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<Investor>> {
      return localVarFp
        .getInvestorsForShareClasses(isins, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns multiple funds from array of isins
     * @param {Array<string>} [isins] Fund ISINs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMultipleFunds(
      isins?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<Fund>> {
      return localVarFp
        .getMultipleFunds(isins, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns ocf and shareclassdetails of funds that have the same fund id
     * @param {string} fundId Fund Id to be used for querying
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShareClassesByFundId(
      fundId: string,
      options?: any,
    ): AxiosPromise<ShareClassDetailsResponse> {
      return localVarFp
        .getShareClassesByFundId(fundId, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * FundsApi - object-oriented interface
 * @export
 * @class FundsApi
 * @extends {BaseAPI}
 */
export class FundsApi extends BaseAPI {
  /**
   *
   * @summary Returns pdf report for fund
   * @param {string} isin Isin of the fund
   * @param {string} currencyCode ISO 4217 alpha code for currency
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FundsApi
   */
  public generateReport(isin: string, currencyCode: string, options?: any) {
    return FundsApiFp(this.configuration)
      .generateReport(isin, currencyCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns fund by isin
   * @param {string} isin Isin of the fund
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FundsApi
   */
  public getFundByIsin(isin: string, options?: any) {
    return FundsApiFp(this.configuration)
      .getFundByIsin(isin, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns multiple funds to compare
   * @param {Array<string>} [isins] Fund ISINs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FundsApi
   */
  public getFundsToCompare(isins?: Array<string>, options?: any) {
    return FundsApiFp(this.configuration)
      .getFundsToCompare(isins, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns investors for share class
   * @param {Array<string>} [isins] Isins of the target share classes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FundsApi
   */
  public getInvestorsForShareClasses(isins?: Array<string>, options?: any) {
    return FundsApiFp(this.configuration)
      .getInvestorsForShareClasses(isins, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns multiple funds from array of isins
   * @param {Array<string>} [isins] Fund ISINs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FundsApi
   */
  public getMultipleFunds(isins?: Array<string>, options?: any) {
    return FundsApiFp(this.configuration)
      .getMultipleFunds(isins, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns ocf and shareclassdetails of funds that have the same fund id
   * @param {string} fundId Fund Id to be used for querying
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FundsApi
   */
  public getShareClassesByFundId(fundId: string, options?: any) {
    return FundsApiFp(this.configuration)
      .getShareClassesByFundId(fundId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HelpSupportApi - axios parameter creator
 * @export
 */
export const HelpSupportApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Send email in Help Support about Unavailable ISINs
     * @param {UnavailableIsinsEmailPayload} unavailableIsinsEmailPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendUnavailableIsinsEmail: async (
      unavailableIsinsEmailPayload: UnavailableIsinsEmailPayload,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'unavailableIsinsEmailPayload' is not null or undefined
      assertParamExists(
        'sendUnavailableIsinsEmail',
        'unavailableIsinsEmailPayload',
        unavailableIsinsEmailPayload,
      );
      const localVarPath = `/help-support/send-unavailable-isins-email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        unavailableIsinsEmailPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HelpSupportApi - functional programming interface
 * @export
 */
export const HelpSupportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    HelpSupportApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Send email in Help Support about Unavailable ISINs
     * @param {UnavailableIsinsEmailPayload} unavailableIsinsEmailPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendUnavailableIsinsEmail(
      unavailableIsinsEmailPayload: UnavailableIsinsEmailPayload,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UnavailableIsinsEmailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sendUnavailableIsinsEmail(
          unavailableIsinsEmailPayload,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * HelpSupportApi - factory interface
 * @export
 */
export const HelpSupportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HelpSupportApiFp(configuration);
  return {
    /**
     *
     * @summary Send email in Help Support about Unavailable ISINs
     * @param {UnavailableIsinsEmailPayload} unavailableIsinsEmailPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendUnavailableIsinsEmail(
      unavailableIsinsEmailPayload: UnavailableIsinsEmailPayload,
      options?: any,
    ): AxiosPromise<UnavailableIsinsEmailResponse> {
      return localVarFp
        .sendUnavailableIsinsEmail(unavailableIsinsEmailPayload, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * HelpSupportApi - object-oriented interface
 * @export
 * @class HelpSupportApi
 * @extends {BaseAPI}
 */
export class HelpSupportApi extends BaseAPI {
  /**
   *
   * @summary Send email in Help Support about Unavailable ISINs
   * @param {UnavailableIsinsEmailPayload} unavailableIsinsEmailPayload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HelpSupportApi
   */
  public sendUnavailableIsinsEmail(
    unavailableIsinsEmailPayload: UnavailableIsinsEmailPayload,
    options?: any,
  ) {
    return HelpSupportApiFp(this.configuration)
      .sendUnavailableIsinsEmail(unavailableIsinsEmailPayload, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create comment
     * @param {string} fundId fundId for which creating comment
     * @param {CommentBody} commentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComment: async (
      fundId: string,
      commentBody: CommentBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fundId' is not null or undefined
      assertParamExists('createComment', 'fundId', fundId);
      // verify required parameter 'commentBody' is not null or undefined
      assertParamExists('createComment', 'commentBody', commentBody);
      const localVarPath = `/internal/{fundId}/comments`.replace(
        `{${'fundId'}}`,
        encodeURIComponent(String(fundId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        commentBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create material
     * @param {string} fundId fundId for which creating material
     * @param {MaterialBody} materialBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterial: async (
      fundId: string,
      materialBody: MaterialBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fundId' is not null or undefined
      assertParamExists('createMaterial', 'fundId', fundId);
      // verify required parameter 'materialBody' is not null or undefined
      assertParamExists('createMaterial', 'materialBody', materialBody);
      const localVarPath = `/internal/{fundId}/materials`.replace(
        `{${'fundId'}}`,
        encodeURIComponent(String(fundId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        materialBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete comment
     * @param {string} fundId fundId for which deleting comment
     * @param {string} commentId commentId for which comment is to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteComment: async (
      fundId: string,
      commentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fundId' is not null or undefined
      assertParamExists('deleteComment', 'fundId', fundId);
      // verify required parameter 'commentId' is not null or undefined
      assertParamExists('deleteComment', 'commentId', commentId);
      const localVarPath = `/internal/{fundId}/comments/{commentId}`
        .replace(`{${'fundId'}}`, encodeURIComponent(String(fundId)))
        .replace(`{${'commentId'}}`, encodeURIComponent(String(commentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete material
     * @param {string} fundId fundId for which deleting material
     * @param {string} materialId materialId for which material is to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMaterial: async (
      fundId: string,
      materialId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fundId' is not null or undefined
      assertParamExists('deleteMaterial', 'fundId', fundId);
      // verify required parameter 'materialId' is not null or undefined
      assertParamExists('deleteMaterial', 'materialId', materialId);
      const localVarPath = `/internal/{fundId}/materials/{materialId}`
        .replace(`{${'fundId'}}`, encodeURIComponent(String(fundId)))
        .replace(`{${'materialId'}}`, encodeURIComponent(String(materialId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get internal data
     * @param {string} fundId fundId for which internal data is requested
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInternal: async (
      fundId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fundId' is not null or undefined
      assertParamExists('getInternal', 'fundId', fundId);
      const localVarPath = `/internal/{fundId}`.replace(
        `{${'fundId'}}`,
        encodeURIComponent(String(fundId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update material
     * @param {string} fundId fundId for which updating material
     * @param {string} updateMaterialId material id for which material is to be updated
     * @param {MaterialBody} materialBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterial: async (
      fundId: string,
      updateMaterialId: string,
      materialBody: MaterialBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fundId' is not null or undefined
      assertParamExists('updateMaterial', 'fundId', fundId);
      // verify required parameter 'updateMaterialId' is not null or undefined
      assertParamExists('updateMaterial', 'updateMaterialId', updateMaterialId);
      // verify required parameter 'materialBody' is not null or undefined
      assertParamExists('updateMaterial', 'materialBody', materialBody);
      const localVarPath = `/internal/{fundId}/materials/{updateMaterialId}`
        .replace(`{${'fundId'}}`, encodeURIComponent(String(fundId)))
        .replace(
          `{${'updateMaterialId'}}`,
          encodeURIComponent(String(updateMaterialId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        materialBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create comment
     * @param {string} fundId fundId for which creating comment
     * @param {CommentBody} commentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createComment(
      fundId: string,
      commentBody: CommentBody,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(
        fundId,
        commentBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create material
     * @param {string} fundId fundId for which creating material
     * @param {MaterialBody} materialBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMaterial(
      fundId: string,
      materialBody: MaterialBody,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Material>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMaterial(
        fundId,
        materialBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete comment
     * @param {string} fundId fundId for which deleting comment
     * @param {string} commentId commentId for which comment is to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteComment(
      fundId: string,
      commentId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(
        fundId,
        commentId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete material
     * @param {string} fundId fundId for which deleting material
     * @param {string} materialId materialId for which material is to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMaterial(
      fundId: string,
      materialId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMaterial(
        fundId,
        materialId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get internal data
     * @param {string} fundId fundId for which internal data is requested
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInternal(
      fundId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InternalResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInternal(
        fundId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update material
     * @param {string} fundId fundId for which updating material
     * @param {string} updateMaterialId material id for which material is to be updated
     * @param {MaterialBody} materialBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMaterial(
      fundId: string,
      updateMaterialId: string,
      materialBody: MaterialBody,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Material>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMaterial(
        fundId,
        updateMaterialId,
        materialBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InternalApiFp(configuration);
  return {
    /**
     *
     * @summary Create comment
     * @param {string} fundId fundId for which creating comment
     * @param {CommentBody} commentBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComment(
      fundId: string,
      commentBody: CommentBody,
      options?: any,
    ): AxiosPromise<Comment> {
      return localVarFp
        .createComment(fundId, commentBody, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create material
     * @param {string} fundId fundId for which creating material
     * @param {MaterialBody} materialBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMaterial(
      fundId: string,
      materialBody: MaterialBody,
      options?: any,
    ): AxiosPromise<Material> {
      return localVarFp
        .createMaterial(fundId, materialBody, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete comment
     * @param {string} fundId fundId for which deleting comment
     * @param {string} commentId commentId for which comment is to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteComment(
      fundId: string,
      commentId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteComment(fundId, commentId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete material
     * @param {string} fundId fundId for which deleting material
     * @param {string} materialId materialId for which material is to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMaterial(
      fundId: string,
      materialId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteMaterial(fundId, materialId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get internal data
     * @param {string} fundId fundId for which internal data is requested
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInternal(fundId: string, options?: any): AxiosPromise<InternalResponse> {
      return localVarFp
        .getInternal(fundId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update material
     * @param {string} fundId fundId for which updating material
     * @param {string} updateMaterialId material id for which material is to be updated
     * @param {MaterialBody} materialBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMaterial(
      fundId: string,
      updateMaterialId: string,
      materialBody: MaterialBody,
      options?: any,
    ): AxiosPromise<Material> {
      return localVarFp
        .updateMaterial(fundId, updateMaterialId, materialBody, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
  /**
   *
   * @summary Create comment
   * @param {string} fundId fundId for which creating comment
   * @param {CommentBody} commentBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApi
   */
  public createComment(
    fundId: string,
    commentBody: CommentBody,
    options?: any,
  ) {
    return InternalApiFp(this.configuration)
      .createComment(fundId, commentBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create material
   * @param {string} fundId fundId for which creating material
   * @param {MaterialBody} materialBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApi
   */
  public createMaterial(
    fundId: string,
    materialBody: MaterialBody,
    options?: any,
  ) {
    return InternalApiFp(this.configuration)
      .createMaterial(fundId, materialBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete comment
   * @param {string} fundId fundId for which deleting comment
   * @param {string} commentId commentId for which comment is to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApi
   */
  public deleteComment(fundId: string, commentId: string, options?: any) {
    return InternalApiFp(this.configuration)
      .deleteComment(fundId, commentId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete material
   * @param {string} fundId fundId for which deleting material
   * @param {string} materialId materialId for which material is to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApi
   */
  public deleteMaterial(fundId: string, materialId: string, options?: any) {
    return InternalApiFp(this.configuration)
      .deleteMaterial(fundId, materialId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get internal data
   * @param {string} fundId fundId for which internal data is requested
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApi
   */
  public getInternal(fundId: string, options?: any) {
    return InternalApiFp(this.configuration)
      .getInternal(fundId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update material
   * @param {string} fundId fundId for which updating material
   * @param {string} updateMaterialId material id for which material is to be updated
   * @param {MaterialBody} materialBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApi
   */
  public updateMaterial(
    fundId: string,
    updateMaterialId: string,
    materialBody: MaterialBody,
    options?: any,
  ) {
    return InternalApiFp(this.configuration)
      .updateMaterial(fundId, updateMaterialId, materialBody, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Count unseen notifications for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countUnseenNotifications: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications/count-unseen-for-user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get notifications
     * @param {number} [from] Amount of already fetched items to skip in es query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications: async (
      from?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications/all-for-user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Mark all notification for user as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAllAsRead: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/notifications/mark-all-as-read`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Mark notification as read
     * @param {MarkNotificationsAsReadRequest} markNotificationsAsReadRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAsRead: async (
      markNotificationsAsReadRequest: MarkNotificationsAsReadRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'markNotificationsAsReadRequest' is not null or undefined
      assertParamExists(
        'markAsRead',
        'markNotificationsAsReadRequest',
        markNotificationsAsReadRequest,
      );
      const localVarPath = `/notifications/mark-as-read`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        markNotificationsAsReadRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Mark notification as seen
     * @param {MarkNotificationsAsSeenRequest} markNotificationsAsSeenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAsSeen: async (
      markNotificationsAsSeenRequest: MarkNotificationsAsSeenRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'markNotificationsAsSeenRequest' is not null or undefined
      assertParamExists(
        'markAsSeen',
        'markNotificationsAsSeenRequest',
        markNotificationsAsSeenRequest,
      );
      const localVarPath = `/notifications/mark-as-seen`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        markNotificationsAsSeenRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotificationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Count unseen notifications for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countUnseenNotifications(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CountUnseenNotificationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.countUnseenNotifications(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get notifications
     * @param {number} [from] Amount of already fetched items to skip in es query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotifications(
      from?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetNotificationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNotifications(from, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Mark all notification for user as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markAllAsRead(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateNotificationsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markAllAsRead(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Mark notification as read
     * @param {MarkNotificationsAsReadRequest} markNotificationsAsReadRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markAsRead(
      markNotificationsAsReadRequest: MarkNotificationsAsReadRequest,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateNotificationsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markAsRead(
        markNotificationsAsReadRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Mark notification as seen
     * @param {MarkNotificationsAsSeenRequest} markNotificationsAsSeenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markAsSeen(
      markNotificationsAsSeenRequest: MarkNotificationsAsSeenRequest,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateNotificationsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markAsSeen(
        markNotificationsAsSeenRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotificationsApiFp(configuration);
  return {
    /**
     *
     * @summary Count unseen notifications for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countUnseenNotifications(
      options?: any,
    ): AxiosPromise<CountUnseenNotificationsResponse> {
      return localVarFp
        .countUnseenNotifications(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get notifications
     * @param {number} [from] Amount of already fetched items to skip in es query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications(
      from?: number,
      options?: any,
    ): AxiosPromise<GetNotificationsResponse> {
      return localVarFp
        .getNotifications(from, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Mark all notification for user as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAllAsRead(options?: any): AxiosPromise<UpdateNotificationsResponse> {
      return localVarFp
        .markAllAsRead(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Mark notification as read
     * @param {MarkNotificationsAsReadRequest} markNotificationsAsReadRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAsRead(
      markNotificationsAsReadRequest: MarkNotificationsAsReadRequest,
      options?: any,
    ): AxiosPromise<UpdateNotificationsResponse> {
      return localVarFp
        .markAsRead(markNotificationsAsReadRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Mark notification as seen
     * @param {MarkNotificationsAsSeenRequest} markNotificationsAsSeenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markAsSeen(
      markNotificationsAsSeenRequest: MarkNotificationsAsSeenRequest,
      options?: any,
    ): AxiosPromise<UpdateNotificationsResponse> {
      return localVarFp
        .markAsSeen(markNotificationsAsSeenRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
  /**
   *
   * @summary Count unseen notifications for user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public countUnseenNotifications(options?: any) {
    return NotificationsApiFp(this.configuration)
      .countUnseenNotifications(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get notifications
   * @param {number} [from] Amount of already fetched items to skip in es query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public getNotifications(from?: number, options?: any) {
    return NotificationsApiFp(this.configuration)
      .getNotifications(from, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Mark all notification for user as read
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public markAllAsRead(options?: any) {
    return NotificationsApiFp(this.configuration)
      .markAllAsRead(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Mark notification as read
   * @param {MarkNotificationsAsReadRequest} markNotificationsAsReadRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public markAsRead(
    markNotificationsAsReadRequest: MarkNotificationsAsReadRequest,
    options?: any,
  ) {
    return NotificationsApiFp(this.configuration)
      .markAsRead(markNotificationsAsReadRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Mark notification as seen
   * @param {MarkNotificationsAsSeenRequest} markNotificationsAsSeenRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public markAsSeen(
    markNotificationsAsSeenRequest: MarkNotificationsAsSeenRequest,
    options?: any,
  ) {
    return NotificationsApiFp(this.configuration)
      .markAsSeen(markNotificationsAsSeenRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns users from an organization using tenantId
     * @param {number} [size] The number of items to return
     * @param {string} [name] Filter by name of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUsers: async (
      size?: number,
      name?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/organization/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OrganizationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns users from an organization using tenantId
     * @param {number} [size] The number of items to return
     * @param {string} [name] Filter by name of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchUsers(
      size?: number,
      name?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AuthUser>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchUsers(
        size,
        name,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrganizationApiFp(configuration);
  return {
    /**
     *
     * @summary Returns users from an organization using tenantId
     * @param {number} [size] The number of items to return
     * @param {string} [name] Filter by name of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUsers(
      size?: number,
      name?: string,
      options?: any,
    ): AxiosPromise<Array<AuthUser>> {
      return localVarFp
        .fetchUsers(size, name, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
  /**
   *
   * @summary Returns users from an organization using tenantId
   * @param {number} [size] The number of items to return
   * @param {string} [name] Filter by name of users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public fetchUsers(size?: number, name?: string, options?: any) {
    return OrganizationApiFp(this.configuration)
      .fetchUsers(size, name, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PortfolioApi - axios parameter creator
 * @export
 */
export const PortfolioApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAbsoluteChartDataWithPortfolio: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/absolute-with-portfolio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      if (portfolios) {
        localVarQueryParameter['portfolios'] = portfolios;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioAbsoluteChartData: async (
      period?: Array<string>,
      portfolioId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/portfolio-absolute`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Portfolio Risk Return Scatter Plot Data by Period
     * @param {string} [portfolioId]
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioRiskReturnScatterPlotData: async (
      portfolioId?: string,
      period?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/portfolio-risk-return`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (period !== undefined) {
        localVarQueryParameter['period'] = period;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelativeChartDataWithPortfolio: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/relative-with-portfolio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      if (portfolios) {
        localVarQueryParameter['portfolios'] = portfolios;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortfolioApi - functional programming interface
 * @export
 */
export const PortfolioApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PortfolioApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAbsoluteChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponseWithPortfolio>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAbsoluteChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortfolioAbsoluteChartData(
      period?: Array<string>,
      portfolioId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPortfolioAbsoluteChartData(
          period,
          portfolioId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Portfolio Risk Return Scatter Plot Data by Period
     * @param {string} [portfolioId]
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortfolioRiskReturnScatterPlotData(
      portfolioId?: string,
      period?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PortfolioScatterPlotResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPortfolioRiskReturnScatterPlotData(
          portfolioId,
          period,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelativeChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponseWithPortfolio>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRelativeChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PortfolioApi - factory interface
 * @export
 */
export const PortfolioApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PortfolioApiFp(configuration);
  return {
    /**
     *
     * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAbsoluteChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): AxiosPromise<ChartV2DataResponseWithPortfolio> {
      return localVarFp
        .getAbsoluteChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioAbsoluteChartData(
      period?: Array<string>,
      portfolioId?: string,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getPortfolioAbsoluteChartData(period, portfolioId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Portfolio Risk Return Scatter Plot Data by Period
     * @param {string} [portfolioId]
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioRiskReturnScatterPlotData(
      portfolioId?: string,
      period?: string,
      options?: any,
    ): AxiosPromise<PortfolioScatterPlotResponse> {
      return localVarFp
        .getPortfolioRiskReturnScatterPlotData(portfolioId, period, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelativeChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): AxiosPromise<ChartV2DataResponseWithPortfolio> {
      return localVarFp
        .getRelativeChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * PortfolioApi - object-oriented interface
 * @export
 * @class PortfolioApi
 * @extends {BaseAPI}
 */
export class PortfolioApi extends BaseAPI {
  /**
   *
   * @summary Returns Absolute Chart Data by Period, ISINs and Portfolio IDs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {Array<string>} [portfolios] Portfolio IDs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfolioApi
   */
  public getAbsoluteChartDataWithPortfolio(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    portfolios?: Array<string>,
    options?: any,
  ) {
    return PortfolioApiFp(this.configuration)
      .getAbsoluteChartDataWithPortfolio(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        portfolios,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Portfolio Absolute Chart Data by Period and ISINs
   * @param {Array<string>} [period] Period
   * @param {string} [portfolioId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfolioApi
   */
  public getPortfolioAbsoluteChartData(
    period?: Array<string>,
    portfolioId?: string,
    options?: any,
  ) {
    return PortfolioApiFp(this.configuration)
      .getPortfolioAbsoluteChartData(period, portfolioId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Portfolio Risk Return Scatter Plot Data by Period
   * @param {string} [portfolioId]
   * @param {string} [period]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfolioApi
   */
  public getPortfolioRiskReturnScatterPlotData(
    portfolioId?: string,
    period?: string,
    options?: any,
  ) {
    return PortfolioApiFp(this.configuration)
      .getPortfolioRiskReturnScatterPlotData(portfolioId, period, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {Array<string>} [portfolios] Portfolio IDs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfolioApi
   */
  public getRelativeChartDataWithPortfolio(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    portfolios?: Array<string>,
    options?: any,
  ) {
    return PortfolioApiFp(this.configuration)
      .getRelativeChartDataWithPortfolio(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        portfolios,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PortfoliosApi - axios parameter creator
 * @export
 */
export const PortfoliosApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Accepts the invitation of another user to a portfolio.
     * @param {string} [token] Token used to validate the invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptSharedPortfolioInvite: async (
      token?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/portfolios/accept-invitation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add a fund to portfolio
     * @param {string} id The id of the portfolio
     * @param {IsinForPortfolio} isinForPortfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFundToPortfolio: async (
      id: string,
      isinForPortfolio: IsinForPortfolio,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('addFundToPortfolio', 'id', id);
      // verify required parameter 'isinForPortfolio' is not null or undefined
      assertParamExists(
        'addFundToPortfolio',
        'isinForPortfolio',
        isinForPortfolio,
      );
      const localVarPath = `/portfolios/{id}/add-fund-to-portfolio`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        isinForPortfolio,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new portfolio
     * @param {PortfolioPayload} portfolioPayload Portfolio details object with minimum required properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewPortfolio: async (
      portfolioPayload: PortfolioPayload,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portfolioPayload' is not null or undefined
      assertParamExists(
        'createNewPortfolio',
        'portfolioPayload',
        portfolioPayload,
      );
      const localVarPath = `/portfolios/create-portfolio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portfolioPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Declines the invitation of another user to a portfolio.
     * @param {string} [token] Token used to validate the invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    declineSharedPortfolioInvite: async (
      token?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/portfolios/decline-invitation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete portfolio date
     * @param {string} id The id of the portfolio
     * @param {InlineObject3} inlineObject3
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDateFromPortfolio: async (
      id: string,
      inlineObject3: InlineObject3,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteDateFromPortfolio', 'id', id);
      // verify required parameter 'inlineObject3' is not null or undefined
      assertParamExists(
        'deleteDateFromPortfolio',
        'inlineObject3',
        inlineObject3,
      );
      const localVarPath =
        `/portfolios/{id}/delete-date-from-portfolio`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject3,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete fund from portfolio
     * @param {string} id The id of the portfolio
     * @param {Array<string>} requestBody Funds to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFundsFromPortfolio: async (
      id: string,
      requestBody: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteFundsFromPortfolio', 'id', id);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists('deleteFundsFromPortfolio', 'requestBody', requestBody);
      const localVarPath =
        `/portfolios/{id}/delete-fund-from-portfolio`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete user portfolio
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePortfolio: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deletePortfolio', 'id', id);
      const localVarPath = `/portfolios/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get start and end dates of funds in portfolio
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEdgeDatesByPortfolioId: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getEdgeDatesByPortfolioId', 'id', id);
      const localVarPath =
        `/portfolios/{id}/get-edge-dates-by-portfolio-id`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get portfolio invite detail
     * @param {string} [token] Token used to validate the invite.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioInviteDetail: async (
      token?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/portfolios/invitation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return platforms for portfolio
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioPlatforms: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getPortfolioPlatforms', 'id', id);
      const localVarPath = `/portfolios/{id}/platforms`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Return all portfolios
     * @param {number} [page] The current page number
     * @param {number} [size] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolios: async (
      page?: number,
      size?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/portfolios`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import a new portfolio
     * @param {PortfolioPayload} portfolioPayload Portfolio details object with minimum required properties to import
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importPortfolio: async (
      portfolioPayload: PortfolioPayload,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portfolioPayload' is not null or undefined
      assertParamExists(
        'importPortfolio',
        'portfolioPayload',
        portfolioPayload,
      );
      const localVarPath = `/portfolios/import-portfolio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portfolioPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Revoke portfolio user access
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokePortfolioAccess: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('revokePortfolioAccess', 'id', id);
      const localVarPath = `/portfolios/{id}/revoke-access`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Share portfolio with users
     * @param {SharePortfolioRequestBody} sharePortfolioRequestBody Array of users to be given access to a portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sharePortfolio: async (
      sharePortfolioRequestBody: SharePortfolioRequestBody,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sharePortfolioRequestBody' is not null or undefined
      assertParamExists(
        'sharePortfolio',
        'sharePortfolioRequestBody',
        sharePortfolioRequestBody,
      );
      const localVarPath = `/portfolios/share`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sharePortfolioRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update benchmark in portfolio
     * @param {string} id The id of the portfolio
     * @param {string} body Update becnhmark in portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBenchmarkInPortfolio: async (
      id: string,
      body: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateBenchmarkInPortfolio', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('updateBenchmarkInPortfolio', 'body', body);
      const localVarPath =
        `/portfolios/{id}/update-benchmark-in-portfolio`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit portfolio date
     * @param {string} id The id of the portfolio
     * @param {InlineObject2} inlineObject2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDateForPortfolio: async (
      id: string,
      inlineObject2: InlineObject2,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateDateForPortfolio', 'id', id);
      // verify required parameter 'inlineObject2' is not null or undefined
      assertParamExists(
        'updateDateForPortfolio',
        'inlineObject2',
        inlineObject2,
      );
      const localVarPath = `/portfolios/{id}/update-date-for-portfolio`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject2,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch portfolio fund
     * @param {string} id The id of the portfolio
     * @param {PortfolioFundForUpdate} portfolioFundForUpdate Patch portfolio fund to be updated without fundName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFundInPortfolio: async (
      id: string,
      portfolioFundForUpdate: PortfolioFundForUpdate,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateFundInPortfolio', 'id', id);
      // verify required parameter 'portfolioFundForUpdate' is not null or undefined
      assertParamExists(
        'updateFundInPortfolio',
        'portfolioFundForUpdate',
        portfolioFundForUpdate,
      );
      const localVarPath = `/portfolios/{id}/update-fund-in-portfolio`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portfolioFundForUpdate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch portfolio name and benchmark
     * @param {PortfolioPayload} portfolioPayload Patch portfolio data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePortfolio: async (
      portfolioPayload: PortfolioPayload,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'portfolioPayload' is not null or undefined
      assertParamExists(
        'updatePortfolio',
        'portfolioPayload',
        portfolioPayload,
      );
      const localVarPath = `/portfolios/update-portfolio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        portfolioPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch portfolio fund
     * @param {string} isin Isin of fund
     * @param {string} id The id of the portfolio
     * @param {InlineObject1} inlineObject1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWeightingForFundInPortfolio: async (
      isin: string,
      id: string,
      inlineObject1: InlineObject1,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'isin' is not null or undefined
      assertParamExists('updateWeightingForFundInPortfolio', 'isin', isin);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateWeightingForFundInPortfolio', 'id', id);
      // verify required parameter 'inlineObject1' is not null or undefined
      assertParamExists(
        'updateWeightingForFundInPortfolio',
        'inlineObject1',
        inlineObject1,
      );
      const localVarPath =
        `/portfolios/{id}/update-fund-in-portfolio/{isin}/update-weighting-for-fund-in-portfolio`
          .replace(`{${'isin'}}`, encodeURIComponent(String(isin)))
          .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject1,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PortfoliosApi - functional programming interface
 * @export
 */
export const PortfoliosApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PortfoliosApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Accepts the invitation of another user to a portfolio.
     * @param {string} [token] Token used to validate the invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async acceptSharedPortfolioInvite(
      token?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse200>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.acceptSharedPortfolioInvite(
          token,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add a fund to portfolio
     * @param {string} id The id of the portfolio
     * @param {IsinForPortfolio} isinForPortfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addFundToPortfolio(
      id: string,
      isinForPortfolio: IsinForPortfolio,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addFundToPortfolio(
          id,
          isinForPortfolio,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create a new portfolio
     * @param {PortfolioPayload} portfolioPayload Portfolio details object with minimum required properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNewPortfolio(
      portfolioPayload: PortfolioPayload,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse201>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createNewPortfolio(
          portfolioPayload,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Declines the invitation of another user to a portfolio.
     * @param {string} [token] Token used to validate the invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async declineSharedPortfolioInvite(
      token?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse200>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.declineSharedPortfolioInvite(
          token,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete portfolio date
     * @param {string} id The id of the portfolio
     * @param {InlineObject3} inlineObject3
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDateFromPortfolio(
      id: string,
      inlineObject3: InlineObject3,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteDateFromPortfolio(
          id,
          inlineObject3,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete fund from portfolio
     * @param {string} id The id of the portfolio
     * @param {Array<string>} requestBody Funds to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFundsFromPortfolio(
      id: string,
      requestBody: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteFundsFromPortfolio(
          id,
          requestBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete user portfolio
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePortfolio(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePortfolio(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get start and end dates of funds in portfolio
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEdgeDatesByPortfolioId(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: object }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEdgeDatesByPortfolioId(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get portfolio invite detail
     * @param {string} [token] Token used to validate the invite.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortfolioInviteDetail(
      token?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PortfolioInviteDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPortfolioInviteDetail(
          token,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Return platforms for portfolio
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortfolioPlatforms(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PortfolioPlatforms>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPortfolioPlatforms(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Return all portfolios
     * @param {number} [page] The current page number
     * @param {number} [size] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortfolios(
      page?: number,
      size?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPortfoliosResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPortfolios(
        page,
        size,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Import a new portfolio
     * @param {PortfolioPayload} portfolioPayload Portfolio details object with minimum required properties to import
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importPortfolio(
      portfolioPayload: PortfolioPayload,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse201>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importPortfolio(
        portfolioPayload,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Revoke portfolio user access
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async revokePortfolioAccess(
      id: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.revokePortfolioAccess(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Share portfolio with users
     * @param {SharePortfolioRequestBody} sharePortfolioRequestBody Array of users to be given access to a portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sharePortfolio(
      sharePortfolioRequestBody: SharePortfolioRequestBody,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sharePortfolio(
        sharePortfolioRequestBody,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update benchmark in portfolio
     * @param {string} id The id of the portfolio
     * @param {string} body Update becnhmark in portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBenchmarkInPortfolio(
      id: string,
      body: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateBenchmarkInPortfolio(
          id,
          body,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Edit portfolio date
     * @param {string} id The id of the portfolio
     * @param {InlineObject2} inlineObject2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDateForPortfolio(
      id: string,
      inlineObject2: InlineObject2,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDateForPortfolio(
          id,
          inlineObject2,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Patch portfolio fund
     * @param {string} id The id of the portfolio
     * @param {PortfolioFundForUpdate} portfolioFundForUpdate Patch portfolio fund to be updated without fundName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFundInPortfolio(
      id: string,
      portfolioFundForUpdate: PortfolioFundForUpdate,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateFundInPortfolio(
          id,
          portfolioFundForUpdate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Patch portfolio name and benchmark
     * @param {PortfolioPayload} portfolioPayload Patch portfolio data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePortfolio(
      portfolioPayload: PortfolioPayload,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePortfolio(
        portfolioPayload,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Patch portfolio fund
     * @param {string} isin Isin of fund
     * @param {string} id The id of the portfolio
     * @param {InlineObject1} inlineObject1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateWeightingForFundInPortfolio(
      isin: string,
      id: string,
      inlineObject1: InlineObject1,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse2001>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateWeightingForFundInPortfolio(
          isin,
          id,
          inlineObject1,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PortfoliosApi - factory interface
 * @export
 */
export const PortfoliosApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PortfoliosApiFp(configuration);
  return {
    /**
     *
     * @summary Accepts the invitation of another user to a portfolio.
     * @param {string} [token] Token used to validate the invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptSharedPortfolioInvite(
      token?: string,
      options?: any,
    ): AxiosPromise<InlineResponse200> {
      return localVarFp
        .acceptSharedPortfolioInvite(token, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Add a fund to portfolio
     * @param {string} id The id of the portfolio
     * @param {IsinForPortfolio} isinForPortfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFundToPortfolio(
      id: string,
      isinForPortfolio: IsinForPortfolio,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .addFundToPortfolio(id, isinForPortfolio, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create a new portfolio
     * @param {PortfolioPayload} portfolioPayload Portfolio details object with minimum required properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewPortfolio(
      portfolioPayload: PortfolioPayload,
      options?: any,
    ): AxiosPromise<InlineResponse201> {
      return localVarFp
        .createNewPortfolio(portfolioPayload, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Declines the invitation of another user to a portfolio.
     * @param {string} [token] Token used to validate the invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    declineSharedPortfolioInvite(
      token?: string,
      options?: any,
    ): AxiosPromise<InlineResponse200> {
      return localVarFp
        .declineSharedPortfolioInvite(token, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete portfolio date
     * @param {string} id The id of the portfolio
     * @param {InlineObject3} inlineObject3
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDateFromPortfolio(
      id: string,
      inlineObject3: InlineObject3,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteDateFromPortfolio(id, inlineObject3, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete fund from portfolio
     * @param {string} id The id of the portfolio
     * @param {Array<string>} requestBody Funds to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFundsFromPortfolio(
      id: string,
      requestBody: Array<string>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteFundsFromPortfolio(id, requestBody, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete user portfolio
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePortfolio(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deletePortfolio(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get start and end dates of funds in portfolio
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEdgeDatesByPortfolioId(
      id: string,
      options?: any,
    ): AxiosPromise<{ [key: string]: object }> {
      return localVarFp
        .getEdgeDatesByPortfolioId(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get portfolio invite detail
     * @param {string} [token] Token used to validate the invite.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioInviteDetail(
      token?: string,
      options?: any,
    ): AxiosPromise<PortfolioInviteDetailResponse> {
      return localVarFp
        .getPortfolioInviteDetail(token, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Return platforms for portfolio
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioPlatforms(
      id: string,
      options?: any,
    ): AxiosPromise<PortfolioPlatforms> {
      return localVarFp
        .getPortfolioPlatforms(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Return all portfolios
     * @param {number} [page] The current page number
     * @param {number} [size] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolios(
      page?: number,
      size?: number,
      options?: any,
    ): AxiosPromise<GetPortfoliosResponse> {
      return localVarFp
        .getPortfolios(page, size, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Import a new portfolio
     * @param {PortfolioPayload} portfolioPayload Portfolio details object with minimum required properties to import
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importPortfolio(
      portfolioPayload: PortfolioPayload,
      options?: any,
    ): AxiosPromise<InlineResponse201> {
      return localVarFp
        .importPortfolio(portfolioPayload, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Revoke portfolio user access
     * @param {string} id The id of the portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokePortfolioAccess(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .revokePortfolioAccess(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Share portfolio with users
     * @param {SharePortfolioRequestBody} sharePortfolioRequestBody Array of users to be given access to a portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sharePortfolio(
      sharePortfolioRequestBody: SharePortfolioRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .sharePortfolio(sharePortfolioRequestBody, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update benchmark in portfolio
     * @param {string} id The id of the portfolio
     * @param {string} body Update becnhmark in portfolio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBenchmarkInPortfolio(
      id: string,
      body: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateBenchmarkInPortfolio(id, body, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Edit portfolio date
     * @param {string} id The id of the portfolio
     * @param {InlineObject2} inlineObject2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDateForPortfolio(
      id: string,
      inlineObject2: InlineObject2,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateDateForPortfolio(id, inlineObject2, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Patch portfolio fund
     * @param {string} id The id of the portfolio
     * @param {PortfolioFundForUpdate} portfolioFundForUpdate Patch portfolio fund to be updated without fundName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFundInPortfolio(
      id: string,
      portfolioFundForUpdate: PortfolioFundForUpdate,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateFundInPortfolio(id, portfolioFundForUpdate, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Patch portfolio name and benchmark
     * @param {PortfolioPayload} portfolioPayload Patch portfolio data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePortfolio(
      portfolioPayload: PortfolioPayload,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updatePortfolio(portfolioPayload, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Patch portfolio fund
     * @param {string} isin Isin of fund
     * @param {string} id The id of the portfolio
     * @param {InlineObject1} inlineObject1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWeightingForFundInPortfolio(
      isin: string,
      id: string,
      inlineObject1: InlineObject1,
      options?: any,
    ): AxiosPromise<InlineResponse2001> {
      return localVarFp
        .updateWeightingForFundInPortfolio(isin, id, inlineObject1, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * PortfoliosApi - object-oriented interface
 * @export
 * @class PortfoliosApi
 * @extends {BaseAPI}
 */
export class PortfoliosApi extends BaseAPI {
  /**
   *
   * @summary Accepts the invitation of another user to a portfolio.
   * @param {string} [token] Token used to validate the invitation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public acceptSharedPortfolioInvite(token?: string, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .acceptSharedPortfolioInvite(token, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add a fund to portfolio
   * @param {string} id The id of the portfolio
   * @param {IsinForPortfolio} isinForPortfolio
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public addFundToPortfolio(
    id: string,
    isinForPortfolio: IsinForPortfolio,
    options?: any,
  ) {
    return PortfoliosApiFp(this.configuration)
      .addFundToPortfolio(id, isinForPortfolio, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a new portfolio
   * @param {PortfolioPayload} portfolioPayload Portfolio details object with minimum required properties
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public createNewPortfolio(portfolioPayload: PortfolioPayload, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .createNewPortfolio(portfolioPayload, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Declines the invitation of another user to a portfolio.
   * @param {string} [token] Token used to validate the invitation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public declineSharedPortfolioInvite(token?: string, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .declineSharedPortfolioInvite(token, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete portfolio date
   * @param {string} id The id of the portfolio
   * @param {InlineObject3} inlineObject3
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public deleteDateFromPortfolio(
    id: string,
    inlineObject3: InlineObject3,
    options?: any,
  ) {
    return PortfoliosApiFp(this.configuration)
      .deleteDateFromPortfolio(id, inlineObject3, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete fund from portfolio
   * @param {string} id The id of the portfolio
   * @param {Array<string>} requestBody Funds to remove
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public deleteFundsFromPortfolio(
    id: string,
    requestBody: Array<string>,
    options?: any,
  ) {
    return PortfoliosApiFp(this.configuration)
      .deleteFundsFromPortfolio(id, requestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete user portfolio
   * @param {string} id The id of the portfolio
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public deletePortfolio(id: string, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .deletePortfolio(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get start and end dates of funds in portfolio
   * @param {string} id The id of the portfolio
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public getEdgeDatesByPortfolioId(id: string, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .getEdgeDatesByPortfolioId(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get portfolio invite detail
   * @param {string} [token] Token used to validate the invite.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public getPortfolioInviteDetail(token?: string, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .getPortfolioInviteDetail(token, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Return platforms for portfolio
   * @param {string} id The id of the portfolio
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public getPortfolioPlatforms(id: string, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .getPortfolioPlatforms(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Return all portfolios
   * @param {number} [page] The current page number
   * @param {number} [size] The number of items to return
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public getPortfolios(page?: number, size?: number, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .getPortfolios(page, size, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import a new portfolio
   * @param {PortfolioPayload} portfolioPayload Portfolio details object with minimum required properties to import
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public importPortfolio(portfolioPayload: PortfolioPayload, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .importPortfolio(portfolioPayload, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Revoke portfolio user access
   * @param {string} id The id of the portfolio
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public revokePortfolioAccess(id: string, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .revokePortfolioAccess(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Share portfolio with users
   * @param {SharePortfolioRequestBody} sharePortfolioRequestBody Array of users to be given access to a portfolio
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public sharePortfolio(
    sharePortfolioRequestBody: SharePortfolioRequestBody,
    options?: any,
  ) {
    return PortfoliosApiFp(this.configuration)
      .sharePortfolio(sharePortfolioRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update benchmark in portfolio
   * @param {string} id The id of the portfolio
   * @param {string} body Update becnhmark in portfolio
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public updateBenchmarkInPortfolio(id: string, body: string, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .updateBenchmarkInPortfolio(id, body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Edit portfolio date
   * @param {string} id The id of the portfolio
   * @param {InlineObject2} inlineObject2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public updateDateForPortfolio(
    id: string,
    inlineObject2: InlineObject2,
    options?: any,
  ) {
    return PortfoliosApiFp(this.configuration)
      .updateDateForPortfolio(id, inlineObject2, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch portfolio fund
   * @param {string} id The id of the portfolio
   * @param {PortfolioFundForUpdate} portfolioFundForUpdate Patch portfolio fund to be updated without fundName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public updateFundInPortfolio(
    id: string,
    portfolioFundForUpdate: PortfolioFundForUpdate,
    options?: any,
  ) {
    return PortfoliosApiFp(this.configuration)
      .updateFundInPortfolio(id, portfolioFundForUpdate, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch portfolio name and benchmark
   * @param {PortfolioPayload} portfolioPayload Patch portfolio data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public updatePortfolio(portfolioPayload: PortfolioPayload, options?: any) {
    return PortfoliosApiFp(this.configuration)
      .updatePortfolio(portfolioPayload, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch portfolio fund
   * @param {string} isin Isin of fund
   * @param {string} id The id of the portfolio
   * @param {InlineObject1} inlineObject1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortfoliosApi
   */
  public updateWeightingForFundInPortfolio(
    isin: string,
    id: string,
    inlineObject1: InlineObject1,
    options?: any,
  ) {
    return PortfoliosApiFp(this.configuration)
      .updateWeightingForFundInPortfolio(isin, id, inlineObject1, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PremiumDiscountApi - axios parameter creator
 * @export
 */
export const PremiumDiscountApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns data for premium/discount bar
     * @param {string} isin Isin of the fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPremiumDiscountBar: async (
      isin: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'isin' is not null or undefined
      assertParamExists('getPremiumDiscountBar', 'isin', isin);
      const localVarPath = `/funds/get-premium-discount-bar/{isin}`.replace(
        `{${'isin'}}`,
        encodeURIComponent(String(isin)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Premium Discount Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPremiumDiscountChartData: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/premium-discount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PremiumDiscountApi - functional programming interface
 * @export
 */
export const PremiumDiscountApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PremiumDiscountApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns data for premium/discount bar
     * @param {string} isin Isin of the fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPremiumDiscountBar(
      isin: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PremiumDiscountPerformance>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPremiumDiscountBar(isin, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Premium Discount Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPremiumDiscountChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPremiumDiscountChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PremiumDiscountApi - factory interface
 * @export
 */
export const PremiumDiscountApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PremiumDiscountApiFp(configuration);
  return {
    /**
     *
     * @summary Returns data for premium/discount bar
     * @param {string} isin Isin of the fund
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPremiumDiscountBar(
      isin: string,
      options?: any,
    ): AxiosPromise<PremiumDiscountPerformance> {
      return localVarFp
        .getPremiumDiscountBar(isin, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Premium Discount Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPremiumDiscountChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getPremiumDiscountChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * PremiumDiscountApi - object-oriented interface
 * @export
 * @class PremiumDiscountApi
 * @extends {BaseAPI}
 */
export class PremiumDiscountApi extends BaseAPI {
  /**
   *
   * @summary Returns data for premium/discount bar
   * @param {string} isin Isin of the fund
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PremiumDiscountApi
   */
  public getPremiumDiscountBar(isin: string, options?: any) {
    return PremiumDiscountApiFp(this.configuration)
      .getPremiumDiscountBar(isin, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Premium Discount Chart Data by Period and ISINs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PremiumDiscountApi
   */
  public getPremiumDiscountChartData(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    options?: any,
  ) {
    return PremiumDiscountApiFp(this.configuration)
      .getPremiumDiscountChartData(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * RelativeChartApi - axios parameter creator
 * @export
 */
export const RelativeChartApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns Portfolio Relative Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioRelativeChartData: async (
      period?: Array<string>,
      portfolioId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/portfolio-relative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelativeChartData: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/relative`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelativeChartDataWithPortfolio: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/relative-with-portfolio`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (investmentTrustReturn !== undefined) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      if (portfolios) {
        localVarQueryParameter['portfolios'] = portfolios;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RelativeChartApi - functional programming interface
 * @export
 */
export const RelativeChartApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    RelativeChartApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns Portfolio Relative Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortfolioRelativeChartData(
      period?: Array<string>,
      portfolioId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPortfolioRelativeChartData(
          period,
          portfolioId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelativeChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRelativeChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelativeChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponseWithPortfolio>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRelativeChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * RelativeChartApi - factory interface
 * @export
 */
export const RelativeChartApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RelativeChartApiFp(configuration);
  return {
    /**
     *
     * @summary Returns Portfolio Relative Chart Data by Period and ISINs
     * @param {Array<string>} [period] Period
     * @param {string} [portfolioId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortfolioRelativeChartData(
      period?: Array<string>,
      portfolioId?: string,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getPortfolioRelativeChartData(period, portfolioId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelativeChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getRelativeChartData(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {number} [investmentTrustReturn] Rips Return Type
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<string>} [portfolios] Portfolio IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelativeChartDataWithPortfolio(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      investmentTrustReturn?: number,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      portfolios?: Array<string>,
      options?: any,
    ): AxiosPromise<ChartV2DataResponseWithPortfolio> {
      return localVarFp
        .getRelativeChartDataWithPortfolio(
          benchmarkId,
          isins,
          period,
          investmentTrustReturn,
          featuredIsins,
          retainSortOrder,
          portfolios,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * RelativeChartApi - object-oriented interface
 * @export
 * @class RelativeChartApi
 * @extends {BaseAPI}
 */
export class RelativeChartApi extends BaseAPI {
  /**
   *
   * @summary Returns Portfolio Relative Chart Data by Period and ISINs
   * @param {Array<string>} [period] Period
   * @param {string} [portfolioId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeChartApi
   */
  public getPortfolioRelativeChartData(
    period?: Array<string>,
    portfolioId?: string,
    options?: any,
  ) {
    return RelativeChartApiFp(this.configuration)
      .getPortfolioRelativeChartData(period, portfolioId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Relative Chart Data by Period and ISINs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeChartApi
   */
  public getRelativeChartData(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    options?: any,
  ) {
    return RelativeChartApiFp(this.configuration)
      .getRelativeChartData(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns Relative Chart Data by Period, ISINs and Portfolio IDs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {number} [investmentTrustReturn] Rips Return Type
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {Array<string>} [portfolios] Portfolio IDs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RelativeChartApi
   */
  public getRelativeChartDataWithPortfolio(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    investmentTrustReturn?: number,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    portfolios?: Array<string>,
    options?: any,
  ) {
    return RelativeChartApiFp(this.configuration)
      .getRelativeChartDataWithPortfolio(
        benchmarkId,
        isins,
        period,
        investmentTrustReturn,
        featuredIsins,
        retainSortOrder,
        portfolios,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Fetch autocomplete for fund search
     * @param {string} term Search term for partial match
     * @param {Array<FundBroadAssetClass>} [broadAssetClass] Types to filter the results by
     * @param {number} [sizePerAggregation] Size per suggestions aggregation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchAutocomplete: async (
      term: string,
      broadAssetClass?: Array<FundBroadAssetClass>,
      sizePerAggregation?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'term' is not null or undefined
      assertParamExists('getSearchAutocomplete', 'term', term);
      const localVarPath = `/search/autocomplete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (term !== undefined) {
        localVarQueryParameter['term'] = term;
      }

      if (broadAssetClass) {
        localVarQueryParameter['broadAssetClass'] = broadAssetClass;
      }

      if (sizePerAggregation !== undefined) {
        localVarQueryParameter['sizePerAggregation'] = sizePerAggregation;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetch autocomplete for fund search and portfolios
     * @param {string} term Search term for partial match
     * @param {Array<FundBroadAssetClass>} [broadAssetClass] Types to filter the results by
     * @param {number} [sizePerAggregation] Size per suggestions aggregation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchAutocompleteWithPortfolios: async (
      term: string,
      broadAssetClass?: Array<FundBroadAssetClass>,
      sizePerAggregation?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'term' is not null or undefined
      assertParamExists('getSearchAutocompleteWithPortfolios', 'term', term);
      const localVarPath = `/search/autocomplete-with-portfolios`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (term !== undefined) {
        localVarQueryParameter['term'] = term;
      }

      if (broadAssetClass) {
        localVarQueryParameter['broadAssetClass'] = broadAssetClass;
      }

      if (sizePerAggregation !== undefined) {
        localVarQueryParameter['sizePerAggregation'] = sizePerAggregation;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Fetch autocomplete for fund search
     * @param {string} term Search term for partial match
     * @param {Array<FundBroadAssetClass>} [broadAssetClass] Types to filter the results by
     * @param {number} [sizePerAggregation] Size per suggestions aggregation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSearchAutocomplete(
      term: string,
      broadAssetClass?: Array<FundBroadAssetClass>,
      sizePerAggregation?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AutocompleteResponseSuggestionItem>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSearchAutocomplete(
          term,
          broadAssetClass,
          sizePerAggregation,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Fetch autocomplete for fund search and portfolios
     * @param {string} term Search term for partial match
     * @param {Array<FundBroadAssetClass>} [broadAssetClass] Types to filter the results by
     * @param {number} [sizePerAggregation] Size per suggestions aggregation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSearchAutocompleteWithPortfolios(
      term: string,
      broadAssetClass?: Array<FundBroadAssetClass>,
      sizePerAggregation?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AutocompleteResponseSuggestionItemWithPortfolio>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSearchAutocompleteWithPortfolios(
          term,
          broadAssetClass,
          sizePerAggregation,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SearchApiFp(configuration);
  return {
    /**
     *
     * @summary Fetch autocomplete for fund search
     * @param {string} term Search term for partial match
     * @param {Array<FundBroadAssetClass>} [broadAssetClass] Types to filter the results by
     * @param {number} [sizePerAggregation] Size per suggestions aggregation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchAutocomplete(
      term: string,
      broadAssetClass?: Array<FundBroadAssetClass>,
      sizePerAggregation?: number,
      options?: any,
    ): AxiosPromise<Array<AutocompleteResponseSuggestionItem>> {
      return localVarFp
        .getSearchAutocomplete(
          term,
          broadAssetClass,
          sizePerAggregation,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch autocomplete for fund search and portfolios
     * @param {string} term Search term for partial match
     * @param {Array<FundBroadAssetClass>} [broadAssetClass] Types to filter the results by
     * @param {number} [sizePerAggregation] Size per suggestions aggregation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchAutocompleteWithPortfolios(
      term: string,
      broadAssetClass?: Array<FundBroadAssetClass>,
      sizePerAggregation?: number,
      options?: any,
    ): AxiosPromise<Array<AutocompleteResponseSuggestionItemWithPortfolio>> {
      return localVarFp
        .getSearchAutocompleteWithPortfolios(
          term,
          broadAssetClass,
          sizePerAggregation,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   *
   * @summary Fetch autocomplete for fund search
   * @param {string} term Search term for partial match
   * @param {Array<FundBroadAssetClass>} [broadAssetClass] Types to filter the results by
   * @param {number} [sizePerAggregation] Size per suggestions aggregation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public getSearchAutocomplete(
    term: string,
    broadAssetClass?: Array<FundBroadAssetClass>,
    sizePerAggregation?: number,
    options?: any,
  ) {
    return SearchApiFp(this.configuration)
      .getSearchAutocomplete(term, broadAssetClass, sizePerAggregation, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch autocomplete for fund search and portfolios
   * @param {string} term Search term for partial match
   * @param {Array<FundBroadAssetClass>} [broadAssetClass] Types to filter the results by
   * @param {number} [sizePerAggregation] Size per suggestions aggregation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public getSearchAutocompleteWithPortfolios(
    term: string,
    broadAssetClass?: Array<FundBroadAssetClass>,
    sizePerAggregation?: number,
    options?: any,
  ) {
    return SearchApiFp(this.configuration)
      .getSearchAutocompleteWithPortfolios(
        term,
        broadAssetClass,
        sizePerAggregation,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary User change/update password
     * @param {UserChangePassword} userChangePassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeUserPassword: async (
      userChangePassword: UserChangePassword,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userChangePassword' is not null or undefined
      assertParamExists(
        'changeUserPassword',
        'userChangePassword',
        userChangePassword,
      );
      const localVarPath = `/settings/change-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userChangePassword,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete all significant changes settings for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSignificantChangesSettingsForUser: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/settings/significant-changes-threshold`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get notifications settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationsSettings: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/settings/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get significant changes settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignificantChangesThresholds: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/settings/significant-changes-threshold`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/settings/user-profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save notifications settings for user
     * @param {NotificationsSettings} notificationsSettings Save notifications settings for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveNotificationsSettings: async (
      notificationsSettings: NotificationsSettings,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notificationsSettings' is not null or undefined
      assertParamExists(
        'saveNotificationsSettings',
        'notificationsSettings',
        notificationsSettings,
      );
      const localVarPath = `/settings/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationsSettings,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save significant changes settings
     * @param {SignificantChangesThreshold} significantChangesThreshold Save significant changes settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSignificantChangesThreshold: async (
      significantChangesThreshold: SignificantChangesThreshold,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'significantChangesThreshold' is not null or undefined
      assertParamExists(
        'saveSignificantChangesThreshold',
        'significantChangesThreshold',
        significantChangesThreshold,
      );
      const localVarPath = `/settings/significant-changes-threshold`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        significantChangesThreshold,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user profile
     * @param {UserProfile} userProfile Update profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile: async (
      userProfile: UserProfile,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userProfile' is not null or undefined
      assertParamExists('updateUserProfile', 'userProfile', userProfile);
      const localVarPath = `/settings/update-profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userProfile,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary User change/update password
     * @param {UserChangePassword} userChangePassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeUserPassword(
      userChangePassword: UserChangePassword,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserChangePasswordResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.changeUserPassword(
          userChangePassword,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete all significant changes settings for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSignificantChangesSettingsForUser(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SignificantChangesThreshold>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSignificantChangesSettingsForUser(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get notifications settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotificationsSettings(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NotificationsSettings>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNotificationsSettings(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get significant changes settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSignificantChangesThresholds(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SignificantChangesThreshold>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSignificantChangesThresholds(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserProfile(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUserProfileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Save notifications settings for user
     * @param {NotificationsSettings} notificationsSettings Save notifications settings for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveNotificationsSettings(
      notificationsSettings: NotificationsSettings,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NotificationsSettings>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.saveNotificationsSettings(
          notificationsSettings,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Save significant changes settings
     * @param {SignificantChangesThreshold} significantChangesThreshold Save significant changes settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveSignificantChangesThreshold(
      significantChangesThreshold: SignificantChangesThreshold,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SignificantChangesThreshold>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.saveSignificantChangesThreshold(
          significantChangesThreshold,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update user profile
     * @param {UserProfile} userProfile Update profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserProfile(
      userProfile: UserProfile,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserUpdateProfileResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserProfile(userProfile, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SettingsApiFp(configuration);
  return {
    /**
     *
     * @summary User change/update password
     * @param {UserChangePassword} userChangePassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeUserPassword(
      userChangePassword: UserChangePassword,
      options?: any,
    ): AxiosPromise<UserChangePasswordResponse> {
      return localVarFp
        .changeUserPassword(userChangePassword, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete all significant changes settings for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSignificantChangesSettingsForUser(
      options?: any,
    ): AxiosPromise<SignificantChangesThreshold> {
      return localVarFp
        .deleteSignificantChangesSettingsForUser(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get notifications settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationsSettings(
      options?: any,
    ): AxiosPromise<NotificationsSettings> {
      return localVarFp
        .getNotificationsSettings(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get significant changes settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignificantChangesThresholds(
      options?: any,
    ): AxiosPromise<SignificantChangesThreshold> {
      return localVarFp
        .getSignificantChangesThresholds(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(options?: any): AxiosPromise<GetUserProfileResponse> {
      return localVarFp
        .getUserProfile(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save notifications settings for user
     * @param {NotificationsSettings} notificationsSettings Save notifications settings for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveNotificationsSettings(
      notificationsSettings: NotificationsSettings,
      options?: any,
    ): AxiosPromise<NotificationsSettings> {
      return localVarFp
        .saveNotificationsSettings(notificationsSettings, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Save significant changes settings
     * @param {SignificantChangesThreshold} significantChangesThreshold Save significant changes settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSignificantChangesThreshold(
      significantChangesThreshold: SignificantChangesThreshold,
      options?: any,
    ): AxiosPromise<SignificantChangesThreshold> {
      return localVarFp
        .saveSignificantChangesThreshold(significantChangesThreshold, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update user profile
     * @param {UserProfile} userProfile Update profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile(
      userProfile: UserProfile,
      options?: any,
    ): AxiosPromise<UserUpdateProfileResponse> {
      return localVarFp
        .updateUserProfile(userProfile, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
  /**
   *
   * @summary User change/update password
   * @param {UserChangePassword} userChangePassword
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public changeUserPassword(
    userChangePassword: UserChangePassword,
    options?: any,
  ) {
    return SettingsApiFp(this.configuration)
      .changeUserPassword(userChangePassword, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete all significant changes settings for user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public deleteSignificantChangesSettingsForUser(options?: any) {
    return SettingsApiFp(this.configuration)
      .deleteSignificantChangesSettingsForUser(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get notifications settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public getNotificationsSettings(options?: any) {
    return SettingsApiFp(this.configuration)
      .getNotificationsSettings(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get significant changes settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public getSignificantChangesThresholds(options?: any) {
    return SettingsApiFp(this.configuration)
      .getSignificantChangesThresholds(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public getUserProfile(options?: any) {
    return SettingsApiFp(this.configuration)
      .getUserProfile(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save notifications settings for user
   * @param {NotificationsSettings} notificationsSettings Save notifications settings for user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public saveNotificationsSettings(
    notificationsSettings: NotificationsSettings,
    options?: any,
  ) {
    return SettingsApiFp(this.configuration)
      .saveNotificationsSettings(notificationsSettings, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Save significant changes settings
   * @param {SignificantChangesThreshold} significantChangesThreshold Save significant changes settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public saveSignificantChangesThreshold(
    significantChangesThreshold: SignificantChangesThreshold,
    options?: any,
  ) {
    return SettingsApiFp(this.configuration)
      .saveSignificantChangesThreshold(significantChangesThreshold, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update user profile
   * @param {UserProfile} userProfile Update profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public updateUserProfile(userProfile: UserProfile, options?: any) {
    return SettingsApiFp(this.configuration)
      .updateUserProfile(userProfile, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SharePriceVsNavApi - axios parameter creator
 * @export
 */
export const SharePriceVsNavApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns SharePrice vs NAV Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<number>} [investmentTrustReturn] Multiple Rips Return Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSharePriceVsNavChartData: async (
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      investmentTrustReturn?: Array<number>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/charts/v2/share-price-vs-nav`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (benchmarkId !== undefined) {
        localVarQueryParameter['benchmarkId'] = benchmarkId;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (period) {
        localVarQueryParameter['period'] = period;
      }

      if (featuredIsins) {
        localVarQueryParameter['featuredIsins'] = featuredIsins;
      }

      if (retainSortOrder !== undefined) {
        localVarQueryParameter['retainSortOrder'] = retainSortOrder;
      }

      if (investmentTrustReturn) {
        localVarQueryParameter['investmentTrustReturn'] = investmentTrustReturn;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SharePriceVsNavApi - functional programming interface
 * @export
 */
export const SharePriceVsNavApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SharePriceVsNavApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns SharePrice vs NAV Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<number>} [investmentTrustReturn] Multiple Rips Return Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSharePriceVsNavChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      investmentTrustReturn?: Array<number>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ChartV2DataResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSharePriceVsNavChartData(
          benchmarkId,
          isins,
          period,
          featuredIsins,
          retainSortOrder,
          investmentTrustReturn,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SharePriceVsNavApi - factory interface
 * @export
 */
export const SharePriceVsNavApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SharePriceVsNavApiFp(configuration);
  return {
    /**
     *
     * @summary Returns SharePrice vs NAV Chart Data by Period and ISINs
     * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [period] Period
     * @param {Array<string>} [featuredIsins] Featured ISINs
     * @param {boolean} [retainSortOrder]
     * @param {Array<number>} [investmentTrustReturn] Multiple Rips Return Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSharePriceVsNavChartData(
      benchmarkId?: string,
      isins?: Array<string>,
      period?: Array<string>,
      featuredIsins?: Array<string>,
      retainSortOrder?: boolean,
      investmentTrustReturn?: Array<number>,
      options?: any,
    ): AxiosPromise<ChartV2DataResponse> {
      return localVarFp
        .getSharePriceVsNavChartData(
          benchmarkId,
          isins,
          period,
          featuredIsins,
          retainSortOrder,
          investmentTrustReturn,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SharePriceVsNavApi - object-oriented interface
 * @export
 * @class SharePriceVsNavApi
 * @extends {BaseAPI}
 */
export class SharePriceVsNavApi extends BaseAPI {
  /**
   *
   * @summary Returns SharePrice vs NAV Chart Data by Period and ISINs
   * @param {string} [benchmarkId] Benchmark id for the bucket, coming from google sheet
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [period] Period
   * @param {Array<string>} [featuredIsins] Featured ISINs
   * @param {boolean} [retainSortOrder]
   * @param {Array<number>} [investmentTrustReturn] Multiple Rips Return Type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SharePriceVsNavApi
   */
  public getSharePriceVsNavChartData(
    benchmarkId?: string,
    isins?: Array<string>,
    period?: Array<string>,
    featuredIsins?: Array<string>,
    retainSortOrder?: boolean,
    investmentTrustReturn?: Array<number>,
    options?: any,
  ) {
    return SharePriceVsNavApiFp(this.configuration)
      .getSharePriceVsNavChartData(
        benchmarkId,
        isins,
        period,
        featuredIsins,
        retainSortOrder,
        investmentTrustReturn,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SignificantChangesApi - axios parameter creator
 * @export
 */
export const SignificantChangesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get significant change data by id
     * @param {string} [id] SC id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignificantChange: async (
      id?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/significant-changes/get-significant-change`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns significant change and fund details for modal
     * @param {string} id id of the significant change
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignificantChangeForModal: async (
      id: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSignificantChangeForModal', 'id', id);
      const localVarPath = `/significant-changes/{id}/modal`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get significant changes data
     * @param {number} [page] The current page number
     * @param {number} [size] The number of items to return
     * @param {string} [term] Search term for partial match
     * @param {string} [sortBy] Key that the respose will be sorted by
     * @param {string} [sortDirection] Order direction
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [changeType] filter changeType for significant changes
     * @param {string} [period] Date Period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignificantChanges: async (
      page?: number,
      size?: number,
      term?: string,
      sortBy?: string,
      sortDirection?: string,
      isins?: Array<string>,
      changeType?: Array<string>,
      period?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/significant-changes/get-significant-changes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (term !== undefined) {
        localVarQueryParameter['term'] = term;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sortBy'] = sortBy;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter['sortDirection'] = sortDirection;
      }

      if (isins) {
        localVarQueryParameter['isins'] = isins;
      }

      if (changeType) {
        localVarQueryParameter['changeType'] = changeType;
      }

      if (period !== undefined) {
        localVarQueryParameter['period'] = period;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Fetched the performance data for the significant changes
     * @param {string} [isin] Search isin for significant changes
     * @param {Array<string>} [dateRange] Chart Range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignificantChangesPerformanceRipsData: async (
      isin?: string,
      dateRange?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/significant-changes/significant-changes-performance`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isin !== undefined) {
        localVarQueryParameter['isin'] = isin;
      }

      if (dateRange) {
        localVarQueryParameter['dateRange'] = dateRange;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SignificantChangesApi - functional programming interface
 * @export
 */
export const SignificantChangesApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    SignificantChangesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get significant change data by id
     * @param {string} [id] SC id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSignificantChange(
      id?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SignificantChangesItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSignificantChange(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns significant change and fund details for modal
     * @param {string} id id of the significant change
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSignificantChangeForModal(
      id: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SignificantChangesModalData>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSignificantChangeForModal(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get significant changes data
     * @param {number} [page] The current page number
     * @param {number} [size] The number of items to return
     * @param {string} [term] Search term for partial match
     * @param {string} [sortBy] Key that the respose will be sorted by
     * @param {string} [sortDirection] Order direction
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [changeType] filter changeType for significant changes
     * @param {string} [period] Date Period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSignificantChanges(
      page?: number,
      size?: number,
      term?: string,
      sortBy?: string,
      sortDirection?: string,
      isins?: Array<string>,
      changeType?: Array<string>,
      period?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SignificantChangesList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSignificantChanges(
          page,
          size,
          term,
          sortBy,
          sortDirection,
          isins,
          changeType,
          period,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Fetched the performance data for the significant changes
     * @param {string} [isin] Search isin for significant changes
     * @param {Array<string>} [dateRange] Chart Range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSignificantChangesPerformanceRipsData(
      isin?: string,
      dateRange?: Array<string>,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SignificantChangePerformanceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSignificantChangesPerformanceRipsData(
          isin,
          dateRange,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SignificantChangesApi - factory interface
 * @export
 */
export const SignificantChangesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SignificantChangesApiFp(configuration);
  return {
    /**
     *
     * @summary Get significant change data by id
     * @param {string} [id] SC id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignificantChange(
      id?: string,
      options?: any,
    ): AxiosPromise<SignificantChangesItem> {
      return localVarFp
        .getSignificantChange(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns significant change and fund details for modal
     * @param {string} id id of the significant change
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignificantChangeForModal(
      id: string,
      options?: any,
    ): AxiosPromise<SignificantChangesModalData> {
      return localVarFp
        .getSignificantChangeForModal(id, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get significant changes data
     * @param {number} [page] The current page number
     * @param {number} [size] The number of items to return
     * @param {string} [term] Search term for partial match
     * @param {string} [sortBy] Key that the respose will be sorted by
     * @param {string} [sortDirection] Order direction
     * @param {Array<string>} [isins] ISINs
     * @param {Array<string>} [changeType] filter changeType for significant changes
     * @param {string} [period] Date Period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignificantChanges(
      page?: number,
      size?: number,
      term?: string,
      sortBy?: string,
      sortDirection?: string,
      isins?: Array<string>,
      changeType?: Array<string>,
      period?: string,
      options?: any,
    ): AxiosPromise<SignificantChangesList> {
      return localVarFp
        .getSignificantChanges(
          page,
          size,
          term,
          sortBy,
          sortDirection,
          isins,
          changeType,
          period,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetched the performance data for the significant changes
     * @param {string} [isin] Search isin for significant changes
     * @param {Array<string>} [dateRange] Chart Range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignificantChangesPerformanceRipsData(
      isin?: string,
      dateRange?: Array<string>,
      options?: any,
    ): AxiosPromise<SignificantChangePerformanceResponse> {
      return localVarFp
        .getSignificantChangesPerformanceRipsData(isin, dateRange, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SignificantChangesApi - object-oriented interface
 * @export
 * @class SignificantChangesApi
 * @extends {BaseAPI}
 */
export class SignificantChangesApi extends BaseAPI {
  /**
   *
   * @summary Get significant change data by id
   * @param {string} [id] SC id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SignificantChangesApi
   */
  public getSignificantChange(id?: string, options?: any) {
    return SignificantChangesApiFp(this.configuration)
      .getSignificantChange(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns significant change and fund details for modal
   * @param {string} id id of the significant change
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SignificantChangesApi
   */
  public getSignificantChangeForModal(id: string, options?: any) {
    return SignificantChangesApiFp(this.configuration)
      .getSignificantChangeForModal(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get significant changes data
   * @param {number} [page] The current page number
   * @param {number} [size] The number of items to return
   * @param {string} [term] Search term for partial match
   * @param {string} [sortBy] Key that the respose will be sorted by
   * @param {string} [sortDirection] Order direction
   * @param {Array<string>} [isins] ISINs
   * @param {Array<string>} [changeType] filter changeType for significant changes
   * @param {string} [period] Date Period
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SignificantChangesApi
   */
  public getSignificantChanges(
    page?: number,
    size?: number,
    term?: string,
    sortBy?: string,
    sortDirection?: string,
    isins?: Array<string>,
    changeType?: Array<string>,
    period?: string,
    options?: any,
  ) {
    return SignificantChangesApiFp(this.configuration)
      .getSignificantChanges(
        page,
        size,
        term,
        sortBy,
        sortDirection,
        isins,
        changeType,
        period,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetched the performance data for the significant changes
   * @param {string} [isin] Search isin for significant changes
   * @param {Array<string>} [dateRange] Chart Range
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SignificantChangesApi
   */
  public getSignificantChangesPerformanceRipsData(
    isin?: string,
    dateRange?: Array<string>,
    options?: any,
  ) {
    return SignificantChangesApiFp(this.configuration)
      .getSignificantChangesPerformanceRipsData(isin, dateRange, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns users from an organization using tenantId
     * @param {number} [size] The number of items to return
     * @param {string} [name] Filter by name of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUsers: async (
      size?: number,
      name?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/organization/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns users from an organization using tenantId
     * @param {number} [size] The number of items to return
     * @param {string} [name] Filter by name of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchUsers(
      size?: number,
      name?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AuthUser>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchUsers(
        size,
        name,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TenantApiFp(configuration);
  return {
    /**
     *
     * @summary Returns users from an organization using tenantId
     * @param {number} [size] The number of items to return
     * @param {string} [name] Filter by name of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUsers(
      size?: number,
      name?: string,
      options?: any,
    ): AxiosPromise<Array<AuthUser>> {
      return localVarFp
        .fetchUsers(size, name, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
  /**
   *
   * @summary Returns users from an organization using tenantId
   * @param {number} [size] The number of items to return
   * @param {string} [name] Filter by name of users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantApi
   */
  public fetchUsers(size?: number, name?: string, options?: any) {
    return TenantApiFp(this.configuration)
      .fetchUsers(size, name, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Returns users from an organization using tenantId
     * @param {number} [size] The number of items to return
     * @param {string} [name] Filter by name of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUsers: async (
      size?: number,
      name?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/organization/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Returns users from an organization using tenantId
     * @param {number} [size] The number of items to return
     * @param {string} [name] Filter by name of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchUsers(
      size?: number,
      name?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AuthUser>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchUsers(
        size,
        name,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @summary Returns users from an organization using tenantId
     * @param {number} [size] The number of items to return
     * @param {string} [name] Filter by name of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUsers(
      size?: number,
      name?: string,
      options?: any,
    ): AxiosPromise<Array<AuthUser>> {
      return localVarFp
        .fetchUsers(size, name, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary Returns users from an organization using tenantId
   * @param {number} [size] The number of items to return
   * @param {string} [name] Filter by name of users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public fetchUsers(size?: number, name?: string, options?: any) {
    return UserApiFp(this.configuration)
      .fetchUsers(size, name, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * WatchlistApi - axios parameter creator
 * @export
 */
export const WatchlistApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Changes order of the selected bucket
     * @param {ChangeBucketOrderPayload} changeBucketOrderPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeBucketsOrder: async (
      changeBucketOrderPayload: ChangeBucketOrderPayload,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'changeBucketOrderPayload' is not null or undefined
      assertParamExists(
        'changeBucketsOrder',
        'changeBucketOrderPayload',
        changeBucketOrderPayload,
      );
      const localVarPath = `/watchlist/change-bucket-order`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changeBucketOrderPayload,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Loads all watchlists from database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllWatchlists: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/watchlist`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns benchmark pricing by msBenchmarkId|secId
     * @param {string} msBenchmarkId msBenchmarkId to be used for querying
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketBenchmark: async (
      msBenchmarkId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'msBenchmarkId' is not null or undefined
      assertParamExists('getBucketBenchmark', 'msBenchmarkId', msBenchmarkId);
      const localVarPath =
        `/watchlist/bucket-benchmark/{msBenchmarkId}`.replace(
          `{${'msBenchmarkId'}}`,
          encodeURIComponent(String(msBenchmarkId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Loads currently selected watchlist from database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentWatchlist: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/watchlist/current`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Annualised Performance tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistAnnualisedPerformance: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistAnnualisedPerformance',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/annualised-performance`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Asset Allocation tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistAssetAllocation: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistAssetAllocation',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/asset-allocation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Calendar Year Performance tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistCalendarYearPerformance: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistCalendarYearPerformance',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/calendar-year-performance`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds for Credit Quality tab in the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistCreditQuality: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistCreditQuality',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/credit-quality`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds for Currency tab in the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistCurrency: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistCurrency',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/currency`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the ESG tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistESG: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistESG',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/esg`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Equity stats tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistEquityStats: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistEquityStats',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/equity-stats`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Gender Pay Gap tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistGenderPayGap: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistGenderPayGap',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/gender-pay-gap`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in Geography tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistGeography: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistGeography',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/geography`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Income tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistIncome: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistIncome',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/income`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Market cap tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistMarketCap: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistMarketCap',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/market-cap`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Maturity tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistMaturity: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistMaturity',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/maturity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Risk tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistRisk: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistRisk',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/risk`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Sectors tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistSectors: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistSectors',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/sectors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Style tab for the specified bucket
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistStyleBox: async (
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/watchlist/style-box`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns all funds in the Summary tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistSummary: async (
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageQueryParameters' is not null or undefined
      assertParamExists(
        'getWatchlistSummary',
        'pageQueryParameters',
        pageQueryParameters,
      );
      const localVarPath = `/watchlist/summary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pageQueryParameters,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a list of tabs to show for the specified bucket
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistTabsToShow: async (
      bucketId?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/watchlist/tabs-to-show`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (bucketId !== undefined) {
        localVarQueryParameter['bucketId'] = bucketId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload a CSV file of ISINs and search database
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadsWatchlistCSV: async (
      file?: any,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/watchlist/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WatchlistApi - functional programming interface
 * @export
 */
export const WatchlistApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    WatchlistApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Changes order of the selected bucket
     * @param {ChangeBucketOrderPayload} changeBucketOrderPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeBucketsOrder(
      changeBucketOrderPayload: ChangeBucketOrderPayload,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.changeBucketsOrder(
          changeBucketOrderPayload,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Loads all watchlists from database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllWatchlists(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<WatchlistAndBuckets>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllWatchlists(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns benchmark pricing by msBenchmarkId|secId
     * @param {string} msBenchmarkId msBenchmarkId to be used for querying
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBucketBenchmark(
      msBenchmarkId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistBenchmarkResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBucketBenchmark(
          msBenchmarkId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Loads currently selected watchlist from database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentWatchlist(
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistAndBuckets>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCurrentWatchlist(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Annualised Performance tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistAnnualisedPerformance(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistAnnualisedPerformanceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistAnnualisedPerformance(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Asset Allocation tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistAssetAllocation(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssetAllocationList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistAssetAllocation(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Calendar Year Performance tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistCalendarYearPerformance(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistCalendarYearPerformanceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistCalendarYearPerformance(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds for Credit Quality tab in the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistCreditQuality(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistFundsWithTotalResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistCreditQuality(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds for Currency tab in the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistCurrency(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistCurrencyResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistCurrency(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the ESG tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistESG(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistESGResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWatchlistESG(
        pageQueryParameters,
        bucketId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Equity stats tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistEquityStats(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistEquityStatsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistEquityStats(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Gender Pay Gap tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistGenderPayGap(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistGenderPayGapResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistGenderPayGap(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in Geography tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistGeography(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistFundsWithTotalResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistGeography(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Income tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistIncome(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistIncomeResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistIncome(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Market cap tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistMarketCap(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistFundsWithTotalResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistMarketCap(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Maturity tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistMaturity(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaturityList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistMaturity(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Risk tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistRisk(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistRisk(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Sectors tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistSectors(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectorsList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistSectors(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Style tab for the specified bucket
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistStyleBox(
      bucketId?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StyleBoxList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistStyleBox(bucketId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns all funds in the Summary tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistSummary(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<WatchlistSummaryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistSummary(
          pageQueryParameters,
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns a list of tabs to show for the specified bucket
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlistTabsToShow(
      bucketId?: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getWatchlistTabsToShow(
          bucketId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upload a CSV file of ISINs and search database
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadsWatchlistCSV(
      file?: any,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadsWatchlistCSV(file, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * WatchlistApi - factory interface
 * @export
 */
export const WatchlistApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WatchlistApiFp(configuration);
  return {
    /**
     *
     * @summary Changes order of the selected bucket
     * @param {ChangeBucketOrderPayload} changeBucketOrderPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeBucketsOrder(
      changeBucketOrderPayload: ChangeBucketOrderPayload,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .changeBucketsOrder(changeBucketOrderPayload, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Loads all watchlists from database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllWatchlists(options?: any): AxiosPromise<Array<WatchlistAndBuckets>> {
      return localVarFp
        .getAllWatchlists(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns benchmark pricing by msBenchmarkId|secId
     * @param {string} msBenchmarkId msBenchmarkId to be used for querying
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBucketBenchmark(
      msBenchmarkId: string,
      options?: any,
    ): AxiosPromise<WatchlistBenchmarkResponse> {
      return localVarFp
        .getBucketBenchmark(msBenchmarkId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Loads currently selected watchlist from database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentWatchlist(options?: any): AxiosPromise<WatchlistAndBuckets> {
      return localVarFp
        .getCurrentWatchlist(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Annualised Performance tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistAnnualisedPerformance(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistAnnualisedPerformanceResponse> {
      return localVarFp
        .getWatchlistAnnualisedPerformance(
          pageQueryParameters,
          bucketId,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Asset Allocation tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistAssetAllocation(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<AssetAllocationList> {
      return localVarFp
        .getWatchlistAssetAllocation(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Calendar Year Performance tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistCalendarYearPerformance(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistCalendarYearPerformanceResponse> {
      return localVarFp
        .getWatchlistCalendarYearPerformance(
          pageQueryParameters,
          bucketId,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds for Credit Quality tab in the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistCreditQuality(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistFundsWithTotalResponse> {
      return localVarFp
        .getWatchlistCreditQuality(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds for Currency tab in the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistCurrency(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistCurrencyResponse> {
      return localVarFp
        .getWatchlistCurrency(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the ESG tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistESG(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistESGResponse> {
      return localVarFp
        .getWatchlistESG(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Equity stats tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistEquityStats(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistEquityStatsResponse> {
      return localVarFp
        .getWatchlistEquityStats(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Gender Pay Gap tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistGenderPayGap(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistGenderPayGapResponse> {
      return localVarFp
        .getWatchlistGenderPayGap(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in Geography tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistGeography(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistFundsWithTotalResponse> {
      return localVarFp
        .getWatchlistGeography(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Income tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistIncome(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistIncomeResponse> {
      return localVarFp
        .getWatchlistIncome(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Market cap tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistMarketCap(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistFundsWithTotalResponse> {
      return localVarFp
        .getWatchlistMarketCap(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Maturity tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistMaturity(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<MaturityList> {
      return localVarFp
        .getWatchlistMaturity(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Risk tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistRisk(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<RiskList> {
      return localVarFp
        .getWatchlistRisk(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Sectors tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistSectors(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<SectorsList> {
      return localVarFp
        .getWatchlistSectors(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Style tab for the specified bucket
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistStyleBox(
      bucketId?: string,
      options?: any,
    ): AxiosPromise<StyleBoxList> {
      return localVarFp
        .getWatchlistStyleBox(bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns all funds in the Summary tab for the specified bucket
     * @param {PageQueryParameters} pageQueryParameters
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistSummary(
      pageQueryParameters: PageQueryParameters,
      bucketId?: string,
      options?: any,
    ): AxiosPromise<WatchlistSummaryResponse> {
      return localVarFp
        .getWatchlistSummary(pageQueryParameters, bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns a list of tabs to show for the specified bucket
     * @param {string} [bucketId] The bucket id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlistTabsToShow(
      bucketId?: string,
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getWatchlistTabsToShow(bucketId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Upload a CSV file of ISINs and search database
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadsWatchlistCSV(file?: any, options?: any): AxiosPromise<void> {
      return localVarFp
        .uploadsWatchlistCSV(file, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * WatchlistApi - object-oriented interface
 * @export
 * @class WatchlistApi
 * @extends {BaseAPI}
 */
export class WatchlistApi extends BaseAPI {
  /**
   *
   * @summary Changes order of the selected bucket
   * @param {ChangeBucketOrderPayload} changeBucketOrderPayload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public changeBucketsOrder(
    changeBucketOrderPayload: ChangeBucketOrderPayload,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .changeBucketsOrder(changeBucketOrderPayload, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Loads all watchlists from database
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getAllWatchlists(options?: any) {
    return WatchlistApiFp(this.configuration)
      .getAllWatchlists(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns benchmark pricing by msBenchmarkId|secId
   * @param {string} msBenchmarkId msBenchmarkId to be used for querying
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getBucketBenchmark(msBenchmarkId: string, options?: any) {
    return WatchlistApiFp(this.configuration)
      .getBucketBenchmark(msBenchmarkId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Loads currently selected watchlist from database
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getCurrentWatchlist(options?: any) {
    return WatchlistApiFp(this.configuration)
      .getCurrentWatchlist(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Annualised Performance tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistAnnualisedPerformance(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistAnnualisedPerformance(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Asset Allocation tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistAssetAllocation(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistAssetAllocation(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Calendar Year Performance tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistCalendarYearPerformance(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistCalendarYearPerformance(
        pageQueryParameters,
        bucketId,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds for Credit Quality tab in the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistCreditQuality(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistCreditQuality(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds for Currency tab in the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistCurrency(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistCurrency(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the ESG tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistESG(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistESG(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Equity stats tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistEquityStats(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistEquityStats(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Gender Pay Gap tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistGenderPayGap(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistGenderPayGap(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in Geography tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistGeography(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistGeography(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Income tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistIncome(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistIncome(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Market cap tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistMarketCap(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistMarketCap(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Maturity tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistMaturity(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistMaturity(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Risk tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistRisk(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistRisk(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Sectors tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistSectors(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistSectors(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Style tab for the specified bucket
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistStyleBox(bucketId?: string, options?: any) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistStyleBox(bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns all funds in the Summary tab for the specified bucket
   * @param {PageQueryParameters} pageQueryParameters
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistSummary(
    pageQueryParameters: PageQueryParameters,
    bucketId?: string,
    options?: any,
  ) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistSummary(pageQueryParameters, bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns a list of tabs to show for the specified bucket
   * @param {string} [bucketId] The bucket id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public getWatchlistTabsToShow(bucketId?: string, options?: any) {
    return WatchlistApiFp(this.configuration)
      .getWatchlistTabsToShow(bucketId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload a CSV file of ISINs and search database
   * @param {any} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WatchlistApi
   */
  public uploadsWatchlistCSV(file?: any, options?: any) {
    return WatchlistApiFp(this.configuration)
      .uploadsWatchlistCSV(file, options)
      .then(request => request(this.axios, this.basePath));
  }
}
