import { SettingsApi } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import {
  SC_SETTINGS,
  SIGNIFICANT_CHANGES,
} from '../watchlist-hooks/query-keys';

const useGetSignificantChangesThreshold = () => {
  const settingsApi = new SettingsApi(openApiConfig());

  return useQuery([SIGNIFICANT_CHANGES, SC_SETTINGS], async () => {
    const response = await settingsApi.getSignificantChangesThresholds();
    return response.data;
  });
};

export default useGetSignificantChangesThreshold;
