export const significantValuesDefault = [
  {
    value: 5,
    label: '+/-5%',
  },
  {
    value: 10,
    label: '+/-10%',
  },
  {
    value: 25,
    label: '+/-25%',
  },
  {
    value: 50,
    label: '+/-50%',
  },
];

export const significantValuesOCF = [
  {
    value: 0.1,
    label: '+/-0.1%',
  },
  {
    value: 0.25,
    label: '+/-0.25%',
  },
  {
    value: 0.5,
    label: '+/-0.5%',
  },
  {
    value: 1,
    label: '+/-1%',
  },
];

export const significantValuesYield = [
  {
    value: 1,
    label: '+/-1%',
  },
  {
    value: 2,
    label: '+/-2%',
  },
  {
    value: 3,
    label: '+/-3%',
  },
  {
    value: 4,
    label: '+/-4%',
  },
];

export const significantValuesPremiumDiscount = [
  {
    value: 2,
    label: '+/-2%',
  },
  {
    value: 4,
    label: '+/-4%',
  },
  {
    value: 6,
    label: '+/-6%',
  },
  {
    value: 8,
    label: '+/-8%',
  },
];

const significantValues: Record<
  string,
  Array<{ value: number; label: string }>
> = {
  default: significantValuesDefault,
  ocf: significantValuesOCF,
  yield: significantValuesYield,
  premiumDiscount: significantValuesPremiumDiscount,
};

export default significantValues;
