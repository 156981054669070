import { Tabs } from 'antd';
import cx from 'classnames';
import { useState } from 'react';

import ESG from './ESG';
import ManagerTable from './ManagerTable';
import Overview from './Overview';
import style from './style.module.less';

const Summary = () => {
  const [activeTab, setActiveTab] = useState('overview');
  return (
    <div
      className={cx(
        style['root'],
        activeTab === 'esg' && style['root-esg-active'],
      )}
    >
      <Tabs
        items={[
          {
            label: 'Overview',
            key: 'overview',
            children: <Overview />,
          },
          {
            label: 'ESG',
            key: 'esg',
            children: <ESG />,
          },
        ]}
        className={style['tabs-container']}
        onChange={setActiveTab}
        activeKey={activeTab}
        data-test-id="fundDetailsOverviewESGTabs"
      />
      <div className={style['sections-container']}>
        <ManagerTable />
      </div>
    </div>
  );
};

export default Summary;
