import { BenchmarkHoldingPartial } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { Tooltip } from 'antd';
import { FC } from 'react';

import { DataTable } from '@/components';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { COMPARE_TOOL_DECIMALS } from '@/constants';
import { CompareToolWithIndexProps } from '@/types/compare';

import { HoldingsCompareData } from '../utils/CompareDataCalculations';
import { getFundTitle } from '../utils/shared';
import useGetColWidth from '../utils/useGetColWidth';
import { extractFundTitle } from './extractFundTitle';
import styles from './style.module.less';

type CompareHoldingsProps = CompareToolWithIndexProps & {
  data?: HoldingsCompareData;
  benchmarkHoldings?: BenchmarkHoldingPartial[];
};

const LIMIT_NAME_CHARACTERS = 27;
const MOBILE_LIMIT_NAME_CHARACTERS = 12;

const CompareHoldings: FC<CompareHoldingsProps> = ({
  data,
  benchmarkHoldings,
  showIndexColumn = false,
  loading = true,
  funds,
}) => {
  // Manage mobile state here
  const { getColWidth, isMobile } = useGetColWidth();

  return (
    <DataTable
      loading={loading}
      enableHeaderWrap
      uniqueKey="id"
      className="md:table-fixed"
      columns={[
        {
          title: 'Investment',
          render: ({ id }) => {
            const externalName =
              data?.holdings.find(i => i.id === id)?.externalName ??
              EMPTY_DATA_POINT;
            // LIMIT (27) was asked by Jane and Lucy on 25.10.23
            let result = externalName;
            const lengthLimit = isMobile
              ? MOBILE_LIMIT_NAME_CHARACTERS
              : LIMIT_NAME_CHARACTERS;
            const isOverNameLenghtLimit = externalName.length > lengthLimit;
            if (isOverNameLenghtLimit) {
              result = externalName?.slice(0, lengthLimit) + '...';
            }
            return isOverNameLenghtLimit ? (
              <Tooltip
                placement="bottom"
                title={externalName}
                overlayClassName="md:max-w-none whitespace-pre-wrap"
                overlayInnerStyle={{
                  borderRadius: 4,
                  backgroundColor: 'rgba(0, 0, 0, 0.9)',
                }}
                arrowPointAtCenter
              >
                <div className="font-semibold truncate-xs-only">{result}</div>
              </Tooltip>
            ) : (
              <div className="font-semibold truncate-xs-only">{result}</div>
            );
          },
          renderType: 'text',
          width: getColWidth(200, null, null),
          idSubText: 'name',
          isColumnFixed: true,
        },
        ...(funds || []).map((f, idx) => ({
          title: (
            <div className={styles['fund-title']}>
              {getFundTitle({
                isin: f.shareClassDetails.isin,
                fallBackTitle: `Fund ${idx + 1}`,
                loading,
                fundName: extractFundTitle(f),
              })}
            </div>
          ),
          render: ({ id }: { id: string }) => {
            const sector = data?.fundHoldings[idx].find(i => i.id === id);
            const value = sector?.weighting?.toFixed(COMPARE_TOOL_DECIMALS);
            return <div>{value ? `${value}%` : EMPTY_DATA_POINT}</div>;
          },
          renderType: 'number' as const,
          idSubText: `fund-${idx + 1}`,
        })),
        ...(showIndexColumn
          ? [
              {
                title: 'Index',
                width: getColWidth(100, 80, 65),
                render: ({ isin }) => {
                  //There must be  "?? 0"
                  const totalWeight =
                    benchmarkHoldings?.find(b => b.isin === isin)?.weighting ??
                    0;
                  const value = totalWeight?.toFixed(COMPARE_TOOL_DECIMALS);
                  return (
                    <div>
                      {totalWeight !== 0 ? `${value}%` : EMPTY_DATA_POINT}
                    </div>
                  );
                },
                renderType: 'number',
                idSubText: 'index',
              } as IDataTableColumns,
            ]
          : []),
      ]}
      // We'll use the data as keys since this table different data types per row
      data={data?.data}
    />
  );
};

export default CompareHoldings;
