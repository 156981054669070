import './style.less';

import React from 'react';

import { ReactComponent as GraphicSearch } from '@/assets/svg/graphic-search.svg';
import { Subtitle } from '@/components';

export const EmptySearch: React.FCWithChild = () => {
  return (
    <div className="empty-search flex items-center justify-center text-center px-5">
      <GraphicSearch />
      <h1> Looking for a fund or investment trust? </h1>
      <Subtitle> Use the search bar or apply filters.</Subtitle>
    </div>
  );
};
