import { setupOnErrorInterceptor } from '@aminsights/contract';
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import ErrorToaster from '@/components/Toast/ErrorToast';
import RegularToaster from '@/components/Toast/RegularToast';
import { APP_ACTIONS, MESSAGE_STATE, MessageState } from '@/constants/app';

interface IMessageState {
  actionName?: string;
  onClick?: () => void;
  message: string;
  showSnackbarIcon?: boolean;
  shouldUseTimer: boolean;
  messageState: MessageState;
}

export type AppState = {
  windowWidth: number;
  messageState: IMessageState | null;
};

const initialState: AppState = {
  windowWidth: window.innerWidth,
  messageState: null,
};

type SetWidth = {
  type: typeof APP_ACTIONS.SET_WIDTH;
  payload: number;
};

type UnsetMessage = {
  type: typeof APP_ACTIONS.UNSET_MESSAGE;
};

type SetErrorMessage = {
  icon?: ReactElement;
  actionName?: string;
  onClick?: () => void;
  type: typeof APP_ACTIONS.SET_ERROR_MESSAGE;
  payload: string;
};

type MessagePayload = {
  text: string;
  shouldUseTimer?: boolean;
  showSnackbarIcon?: boolean;
};
type SetRegularMessage = {
  icon?: ReactElement;
  type: typeof APP_ACTIONS.SET_REGULAR_MESSAGE;
  payload: MessagePayload;
};

type SetSuccessMessage = {
  type: typeof APP_ACTIONS.SET_SUCCESS_MESSAGE;
  payload: MessagePayload;
};

type DropMessage = {
  type: typeof APP_ACTIONS.DROP_MESSAGE;
};

type AppActionTypes =
  | SetWidth
  | SetErrorMessage
  | UnsetMessage
  | DropMessage
  | SetRegularMessage
  | SetSuccessMessage;

type AppContextProps = {
  app: AppState;
  dispatch: React.Dispatch<AppActionTypes>;
};

const AppContext = createContext<AppContextProps>({
  app: {
    windowWidth: 0,
    messageState: {
      messageState: MESSAGE_STATE.NONE,
      message: '',
      shouldUseTimer: true,
      showSnackbarIcon: false,
    },
  },
  dispatch: () => ({}),
});

const reducer = (state: AppState, action: AppActionTypes): AppState => {
  switch (action.type) {
    case APP_ACTIONS.SET_WIDTH:
      if (action.payload !== state.windowWidth)
        return { ...state, windowWidth: action.payload };
      return state;
    case APP_ACTIONS.UNSET_MESSAGE:
      return {
        ...state,
        messageState: {
          messageState: MESSAGE_STATE.NONE,
          message: '',
          shouldUseTimer: false,
          showSnackbarIcon: false,
        },
      };
    case APP_ACTIONS.SET_ERROR_MESSAGE:
      return {
        ...state,
        messageState: {
          messageState: MESSAGE_STATE.ERROR,
          actionName: action.actionName,
          onClick: action.onClick,
          message: action.payload,
          shouldUseTimer: true,
          showSnackbarIcon: false,
        },
      };
    case APP_ACTIONS.SET_REGULAR_MESSAGE:
      return {
        ...state,
        messageState: {
          messageState: MESSAGE_STATE.REGULAR,
          message: action.payload.text,
          shouldUseTimer: action.payload.shouldUseTimer ?? true,
          showSnackbarIcon: action.payload.showSnackbarIcon,
        },
      };
    case APP_ACTIONS.SET_SUCCESS_MESSAGE:
      return {
        ...state,
        messageState: {
          messageState: MESSAGE_STATE.SUCCESS,
          message: action.payload.text,
          shouldUseTimer: action.payload.shouldUseTimer ?? true,
          showSnackbarIcon: true,
        },
      };
    case APP_ACTIONS.DROP_MESSAGE:
      return { ...state, messageState: null };
    default:
      return state;
  }
};

export const initializer = (initialValue = initialState): AppState =>
  initialValue;

const AppProvider: React.FCWithChild = ({ children }) => {
  const [app, dispatch] = useReducer(reducer, initialState, initializer);

  const handleResize = useCallback(() => {
    dispatch({ type: APP_ACTIONS.SET_WIDTH, payload: window.innerWidth });
  }, [window.innerWidth]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onError = (
    statusCode: string,
    statusText: string,
    correlationId: string,
    endpoint: string,
  ) => {
    if (statusCode || correlationId || endpoint) {
      dispatch({
        type: APP_ACTIONS.SET_ERROR_MESSAGE,
        payload: [
          statusCode,
          statusText ?? 'Unknown status text',
          correlationId,
          endpoint,
        ].join(', '),
      });
    }
  };

  useEffect(() => {
    return setupOnErrorInterceptor(onError);
  }, []);

  return (
    <AppContext.Provider value={{ app, dispatch }}>
      <ErrorToaster />
      <RegularToaster />
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export const useAppContext = (): AppContextProps => {
  return useContext(AppContext);
};

export default AppContext;
