import React from 'react';

import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as NoPortfolioIcon } from '@/assets/svg/icons/icon-no-portfolio.svg';
import Button from '@/components/Button';

import style from './style.module.less';

interface NoPortfolioPageProps {
  setIsAddNewPortfolioModalOpen: (value: React.SetStateAction<boolean>) => void;
}

const NoPortfolioPage: React.FCWithChild<NoPortfolioPageProps> = ({
  setIsAddNewPortfolioModalOpen,
}) => {
  return (
    <div className={style['no-portfolio-table']}>
      <div className={style['no-portfolio-body']}>
        <div className="w-full h-full p-5 flex flex-col items-center justify-center gap-y-4">
          <NoPortfolioIcon />
          <b className="text-lg font-black text-center">No portfolio added</b>
          <span className="text-sm font-normal text-center">
            Create your portfolio and start adding funds
          </span>
          <Button
            className={`flex items-center justify-center mt-4 rounded ${style['portfolio-btn']} ${style['add-fund-btn']}`}
            type="primary"
            onClick={() => {
              setIsAddNewPortfolioModalOpen(true);
            }}
            data-test-id="portfolioAddButton"
          >
            <AddIcon className="icon text-md" />
            <p className="ml-2 text-sm font-medium md:block">Add Portfolio</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoPortfolioPage;
