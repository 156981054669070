import { Component, ReactNode } from 'react';

import CustomError from '@/pages/error/CustomError';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: string;
}

class TopErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: '',
    };
  }

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  public render() {
    if (this.state.hasError) {
      return <CustomError error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default TopErrorBoundary;
