// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OkR7xvkSCJH9rRquHGAT {\n  background-color: #313341;\n  font-size: 0.8rem;\n  line-height: 1rem;\n  color: #ffffff;\n  margin-top: -0.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/ScatterPlots/components/style.module.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;EAEA,mBAAA;AAFF","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.tooltip {\n  background-color: @darkest;\n  font-size: 0.8rem;\n  line-height: 1rem;\n  color: @white;\n  // Marging manipulations because antd sets padding to be !important\n  margin-top: -0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "OkR7xvkSCJH9rRquHGAT"
};
export default ___CSS_LOADER_EXPORT___;
