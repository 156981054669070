import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared';
import React from 'react';

import { SubRouter } from './index';
import { PORTFOLIO_ROUTES } from './routes';

const PortfolioManagementRouter: React.FCWithChild = () => {
  return (
    <SubRouter routes={PORTFOLIO_ROUTES} rootPath={APP_ROUTE_PORTFOLIOS} />
  );
};

export default PortfolioManagementRouter;
