import { FundBroadAssetClass, Stylebox } from '@aminsights/contract';
import {
  BroadAssetClassColor,
  FUND_BOX_X_AND_Y_LABEL_VALUE,
  WATCHLIST_TAB_KEY,
} from '@aminsights/shared';
import { Skeleton } from 'antd';
import cx from 'classnames';
import React, { memo } from 'react';
import { useContext } from 'react';

import { useCurrentBucketId } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import useWatchlistStyleBox from '@/hooks/query-hooks/watchlist-hooks/useWatchlistStyleBox';

import { TabContext } from '../../Watchlist/WatchlistTabWrapper';
import StyleBoxWrapper from './components/StyleBoxWrapper';
import {
  RENDER_BOX_COLOR_EQUITY,
  RENDER_BOX_COLOR_FIXED_INCOME,
} from './constants';

const RenderStyleBox: React.FCWithChild = () => {
  const { activeTab } = useContext(TabContext);
  const currentBucketId = useCurrentBucketId();
  const { data, isLoading } = useWatchlistStyleBox(currentBucketId);

  const equityStyleBoxData = data?.filter(
    (data: Stylebox) => data?.equityStyleBox,
  );

  const fixedIncomeStyleBoxData = data?.filter(
    (data: Stylebox) => data?.bondStatistics?.styleBox,
  );

  return (
    <div className={cx('pr-5')}>
      {isLoading && (
        <div className={cx('p-4 w-full')}>
          <Skeleton />
        </div>
      )}
      {!isLoading && (
        <>
          {activeTab === WATCHLIST_TAB_KEY.STYLE_EQ &&
            equityStyleBoxData &&
            equityStyleBoxData.length > 0 && (
              <StyleBoxWrapper
                fundTypeTitle={FundBroadAssetClass.Equity}
                fundBoxBGcolor={RENDER_BOX_COLOR_EQUITY}
                boxBackgroundColor={BroadAssetClassColor.EQ_BG}
                XandYLabels={FUND_BOX_X_AND_Y_LABEL_VALUE.EQ}
                styleBoxData={equityStyleBoxData}
                key={1}
              />
            )}
          {activeTab === WATCHLIST_TAB_KEY.STYLE_FI &&
            fixedIncomeStyleBoxData &&
            fixedIncomeStyleBoxData?.length > 0 && (
              <StyleBoxWrapper
                fundTypeTitle={FundBroadAssetClass.FixedIncome}
                fundBoxBGcolor={RENDER_BOX_COLOR_FIXED_INCOME}
                boxBackgroundColor={BroadAssetClassColor.FI_BG}
                XandYLabels={FUND_BOX_X_AND_Y_LABEL_VALUE.FI}
                styleBoxData={fixedIncomeStyleBoxData}
                key={2}
              />
            )}
        </>
      )}
    </div>
  );
};

export default memo(RenderStyleBox);
