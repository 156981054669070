function notEmpty<TValue>(
  value: TValue | undefined | null,
): value is NonNullable<TValue> {
  return value !== null && value !== undefined;
}

export function notFalsy<TValue>(
  value: TValue | undefined | null | false,
): value is NonNullable<TValue> {
  return value !== null && value !== undefined && value !== false;
}

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

export function isTruthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export default notEmpty;
