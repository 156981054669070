import { SearchApi } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import useDebounce from '@/hooks/useDebounce';
import { openApiConfig } from '@/utils';

const useAutocompleteQueryWithPortfolios = (term: string) => {
  const api = new SearchApi(openApiConfig());
  const query = useQuery(
    ['searchAutocompleteWithPortfolios', term],
    async () => {
      const response = await api.getSearchAutocompleteWithPortfolios(term);
      return response.data;
    },
    {
      keepPreviousData: true,
    },
  );
  return query;
};

const useSearchWithPortfolios = () => {
  const [fullTerm, setFullTerm] = useState<string>('');
  const debouncedValue = useDebounce(fullTerm, 300);
  const autoCompleteQuery = useAutocompleteQueryWithPortfolios(
    debouncedValue ?? '',
  );

  return {
    autoCompleteQuery: {
      ...autoCompleteQuery,
      data: autoCompleteQuery.data || [],
      isLoading: fullTerm !== debouncedValue || autoCompleteQuery.isLoading,
    },
    fullTerm,
    setFullTerm,
  };
};

export type UseSearchWithPortfoliosReturn = ReturnType<
  typeof useSearchWithPortfolios
>;
export type AutocompleteWithPortfoliosQuery =
  UseSearchWithPortfoliosReturn['autoCompleteQuery'];
export default useSearchWithPortfolios;
