import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared/dist';
import React from 'react';

import { SubRouter } from './index';
import { PORTFOLIO_SUB_ROUTES } from './routes';

const PortfolioSubRouter: React.FCWithChild = () => (
  <SubRouter routes={PORTFOLIO_SUB_ROUTES} rootPath={APP_ROUTE_PORTFOLIOS} />
);

export default PortfolioSubRouter;
