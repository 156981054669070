export const ALL_WATCHLISTS = 'all_watchlists';
export const ASSET_ALLOCATION = 'asset_allocation';
export const WATCHLIST = 'watchlists';
export const WATCHLIST_TABS_TO_SHOW = 'watchlists_tabs_to_show';
export const SUMMARY = 'summary';
export const STYLEBOX = 'stylebox';
export const SECTORS = 'sectors';
export const GEOGRAPHY = 'geography';
export const MARKET_CAP = 'market_cap';
export const RISK = 'risk';
export const INCOME = 'income';
export const GENDER_PAY_GAP = 'gender_pay_gap';
export const ESG = 'esg';
export const CALENDAR = 'calendar';
export const ANNUALISED = 'annualised';
export const BENCHMARK = 'benchmark';
export const SIGNIFICANT_CHANGES = 'significantChanges';
export const SC_SETTINGS = 'significant_changes_settings';
export const SC_DASHBOARD = 'significant_changes_dashboard';
export const MATURITY = 'maturity';
export const CHARTS = 'charts';
export const INTERNAL = 'internal';
export const EQUITY_STATS = 'equity_stats';
export const CORRELATION_MATRIX = 'correlation_matrix';
export const CREDIT_QUALITY = 'credit_quality';
export const CURRENCY = 'currency';

// Charts-v2 query keys
export const CHARTS_V2_RELATIVE = 'relative-v2';
export const CHARTS_V2_ABSOLUTE = 'absolute-v2';
export const CHARTS_V2_ABSOLUTE_WITH_PORTFOLIO = 'absolute-v2-with-portfolio';
export const CHARTS_V2_RELATIVE_WITH_PORTFOLIO = 'relative-v2-with-portfolio';
export const CHARTS_V2_PREMIUM_DISCOUNT = 'premium-discount-v2';
export const CHARTS_V2_RISK_RETURN = 'risk-return-scatter-plot-v2';
export const CHARTS_V2_PORTFOLIO_RELATIVE = 'portfolio-relative-v2';
export const CHARTS_V2_PORTFOLIO_ABSOLUTE = 'portfolio-absolute-v2';
export const CHARTS_PORTFOLIO_RISK_RETURN = 'portfolio-risk-return';
export const CHARTS_V2_SHARE_PRICE_VS_NAV = 'share-price-vs-nav-v2';
