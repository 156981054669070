import cx from 'classnames';
import React, { forwardRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

type HorizontalScrollDragProps = {
  className?: string;
  hideScrollbars?: boolean;
  vertical?: boolean;
  horizontal?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const HorizontalScroll = forwardRef<HTMLDivElement, HorizontalScrollDragProps>(
  (
    { children, className, hideScrollbars, vertical, horizontal, style },
    ref,
  ) => {
    return (
      <ScrollContainer
        className={cx('w-full flex overflow-auto h-auto', className)}
        hideScrollbars={hideScrollbars}
        vertical={vertical}
        horizontal={horizontal}
        style={style}
        innerRef={ref}
      >
        {children}
      </ScrollContainer>
    );
  },
);

HorizontalScroll.defaultProps = {
  hideScrollbars: false,
  vertical: false,
  horizontal: true,
};
export default HorizontalScroll;
