import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT, formatNumberDecimals } from '@aminsights/shared';

export const infoStatsFeesColumns = [
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Info',
    dataIndex: 'info',
  },
];

export const tansformInfoStatsFeesData = (fund: Fund) => {
  return [
    {
      type: 'Annual management charge',
      info: fund.actualManagementFee
        ? `${formatNumberDecimals(fund.actualManagementFee)}%`
        : EMPTY_DATA_POINT,
    },
    {
      type: 'Performance fee',
      info: fund.performanceFee ? `${fund.performanceFee}%` : EMPTY_DATA_POINT,
    },
    {
      type: 'Transaction Costs',
      info: fund.fundFees?.transactionCosts
        ? `${formatNumberDecimals(fund.fundFees.transactionCosts)}%`
        : EMPTY_DATA_POINT,
    },
    {
      type: 'Without Retrocession',
      info: fund.fundFees?.withoutRetrocession
        ? fund.fundFees.withoutRetrocession
        : EMPTY_DATA_POINT,
    },
    {
      type: 'Assessment of Value (COLL)',
      info: fund.fundFees?.assessmentOfValueColl
        ? fund.fundFees.assessmentOfValueColl
        : EMPTY_DATA_POINT,
    },
    {
      type: 'Assessment of Value (PRIN)',
      info: fund.fundFees?.assessmentOfValuePrin
        ? fund.fundFees.assessmentOfValuePrin
        : EMPTY_DATA_POINT,
    },
  ];
};
