import { Modal } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as GraphicClose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as InfoWarningRed } from '@/assets/svg/icons/icon-warning-red.svg';
import Button from '@/components/Button';

import style from './style.module.less';

type ModalProps = {
  isVisible: boolean;
  toggleModal: () => void;
};

const ShareBenchmarkModal: React.FCWithChild<ModalProps> = ({
  isVisible,
  toggleModal,
}) => {
  return (
    <Modal
      width={400}
      open={isVisible}
      onCancel={toggleModal}
      className={style['share-benchmark-modal']}
      footer={[
        <Button
          className={style['share-benchmark-modal__close']}
          type="link"
          key="secondary"
          onClick={toggleModal}
        >
          Close
        </Button>,
      ]}
      closeIcon={<GraphicClose />}
    >
      <div className={style['share-benchmark-modal__container']}>
        <div>
          <InfoWarningRed
            className={style['share-benchmark-modal__icon']}
            width={28}
            height={28}
          />
        </div>
        <div className="flex-1 flex flex-col gap-y-4">
          <h3 className={style['share-benchmark-modal__title']}>
            Custom benchmark not shared
          </h3>
          <div className={style['share-benchmark-modal__description']}>
            The selected benchmark is only visible to you. Update this in{' '}
            <Link
              to="/settings/custom-benchmarks"
              className={style['share-benchmark-modal__link']}
            >
              settings
            </Link>{' '}
            before sharing this portfolio.
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareBenchmarkModal;
