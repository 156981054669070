import { WatchlistAndBuckets, WatchlistApi } from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { convertNameAndIDToSlug, openApiConfig } from '@/utils';

import { ALL_WATCHLISTS, WATCHLIST } from './query-keys';

const useWatchlists = (
  options?: Omit<
    UseQueryOptions<WatchlistAndBuckets[], unknown>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const watchlistApi = new WatchlistApi(openApiConfig());
  return useQuery<WatchlistAndBuckets[], unknown>(
    [ALL_WATCHLISTS],
    async () => {
      const { data } = await watchlistApi.getAllWatchlists();
      return data;
    },
    options,
  );
};

export const useCurrentBucketId = () => {
  const { slug } = useParams<{ slug: string }>();
  const currentWatchlist = useCurrentWatchlist();

  if (slug) {
    const bucket = currentWatchlist.data?.buckets?.find(
      data => convertNameAndIDToSlug(data) === slug,
    );

    return bucket?.id;
  }
};

export const useCurrentWatchlist = (
  options?: Omit<
    UseQueryOptions<WatchlistAndBuckets, unknown>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const watchlistApi = new WatchlistApi(openApiConfig());

  return useQuery<WatchlistAndBuckets, unknown>(
    [WATCHLIST],
    async () => {
      const { data } = await watchlistApi.getCurrentWatchlist();

      // Always sort the current buckets based on watchlistBucket
      const buckets = new Map(data.buckets.map(b => [b.id, b]));
      const sortedBuckets: typeof data.buckets = [];
      data.watchlist.buckets.forEach(watchlistBucket => {
        const bucket = buckets.get(watchlistBucket._id);
        bucket && sortedBuckets.push(bucket);
      });

      return {
        buckets: sortedBuckets,
        watchlist: data.watchlist,
      };
    },

    {
      refetchOnWindowFocus: true,
      keepPreviousData: true, // This prevents other hooks from re-fetching unless finished because without this data is reset to empty array
      ...options,
    },
  );
};

export const useCurrentBucket = () => {
  const currentWatchlist = useCurrentWatchlist();
  const currentBucketId = useCurrentBucketId();

  const currentBucket = currentWatchlist.data?.buckets.find(
    b => b.id === currentBucketId,
  );
  return currentBucket;
};

export default useWatchlists;
