import { ChartsApi } from '@aminsights/contract';
import { MS_DATE_FORMAT } from '@aminsights/shared';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import {
  CORRELATION_MATRIX,
  WATCHLIST,
} from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { openApiConfig } from '@/utils';

const chartsApi = new ChartsApi(openApiConfig());

const useCorrelationMatrixQuery = (isins?: string[]) => {
  const query = useQuery(
    [WATCHLIST, CORRELATION_MATRIX, isins],
    async () => {
      /**
       * Ideally we can query any date range but for now
       * we only concern ourselves with the to date. In the
       * backend we have a function that finds the closestPastFriday
       * and will subtract 3 years from that date with some extra logic.
       */
      const to = dayjs();
      const from = to.subtract(3, 'year');
      const response = await chartsApi.getCorrelationMatrix(isins, [
        from.format(MS_DATE_FORMAT),
        to.format(MS_DATE_FORMAT),
      ]);
      return response.data;
    },
    {
      enabled: !!isins,
    },
  );

  return query;
};

export default useCorrelationMatrixQuery;
