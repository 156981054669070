import {
  PageQueryParametersSortDirectionEnum,
  Performance,
} from '@aminsights/contract';
import React, { useEffect, useState } from 'react';

import { DataTable } from '@/components';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { AnnualisedPerformanceColumns } from '@/pages/app/FundAndInvestmentTrust/components/Performance/columns';
import { sortArray } from '@/utils/array';

import { annualisedRowMapper } from './rowMapper';
import style from './style.module.less';
import { IAnnualisedPerformanceData } from './types';

export const AnnualisedPerformanceTable: React.FCWithChild = () => {
  const [performanceData, setPerformanceData] = useState<
    IAnnualisedPerformanceData[]
  >([]);
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();
  useEffect(() => {
    const performanceData: IAnnualisedPerformanceData[] = [];
    const composedData = composePerformanceData();
    performanceData.push(...composedData);
    setPerformanceData(performanceData);
  }, [state]);

  const composePerformanceData = (): IAnnualisedPerformanceData[] => {
    const { fund, benchmarkPerformance, categoryPerformance } = state || {};

    const performanceData: IAnnualisedPerformanceData[] = [];
    fund && performanceData.push(annualisedRowMapper(fund.id, fund));

    if (benchmarkPerformance) {
      const benchmarkPerformanceRow = composeDataRow(
        benchmarkPerformance,
        'benchmarkPerformanceRow',
      );
      performanceData.push(benchmarkPerformanceRow);
    }

    if (categoryPerformance) {
      const categoryPerformanceRow = composeDataRow(
        categoryPerformance,
        'categoryPerformanceRow',
      );
      performanceData.push(categoryPerformanceRow);
    }

    return performanceData;
  };

  const handleOnSort = (
    key: keyof IAnnualisedPerformanceData,
    direction: PageQueryParametersSortDirectionEnum,
  ) => {
    const sorted = sortArray(performanceData, key, direction);
    setPerformanceData(sorted);
  };

  const composeDataRow = (performance: Performance, key: string) => {
    const dataFundRow: IAnnualisedPerformanceData = {
      key,
      name: performance?.name,
      ytdReturn: performance?.totalReturnYtdPct,
      threeMonthsReturn: performance?.totalReturn3MonthPct,
      sixMonthsReturn: performance?.totalReturn6MonthPct,
      oneYearReturn: performance?.totalReturn1YearPct,
      threeYearsReturn: performance?.totalReturn3YearPct,
      fiveYearsReturn: performance?.totalReturn5YearPct,
    };

    return dataFundRow;
  };

  return (
    <div data-test-id="fundDetailsPerfRiskTabPerformanceAnnualised">
      <DataTable
        uniqueKey="key"
        className={style['performance-table']}
        columns={AnnualisedPerformanceColumns(!!isInvestmentTrust)}
        data={performanceData}
        onSort={(
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => handleOnSort(key as keyof IAnnualisedPerformanceData, direction)}
      />
    </div>
  );
};
