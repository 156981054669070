import { RangePair, RangePairSerializer } from '@aminsights/contract';
import { YesNoOption } from '@aminsights/contract';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonCheckbox, Checkbox } from '@/components';
import { ICheckboxData } from '@/components/Checkbox/types';
import ExploreFilterModal from '@/partials/ExploreFilterModal';

import { useExploreContext, useExploreDefaultFiltersQuery } from '../context';
import { ExploreModalProps } from '../types';
import useProvideExplore from '../useProvideExplore';
import { countSelectedFilter } from '../utils/countSelectedFilter';

type CheckboxValueType = any;

const SELECT_ALL = 'select-all';

export const EsgFilterModal: React.FCWithChild<ExploreModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [datesUNPRI, setDatesUNPRI] = useState<Array<CheckboxValueType>>([]);
  const [datesTCFD, setDatesTCFD] = useState<Array<CheckboxValueType>>([]);
  const [checkedSFDR, setCheckedSFDR] = useState<string[]>([]);
  const [netZeroAssetManager, setNetZeroAssetManager] = useState<
    YesNoOption | undefined
  >();
  const [stewardshipCode, setStewardshipCode] = useState<
    YesNoOption | undefined
  >();
  const [financeForBiodiversityDates, setFinanceForBiodiversityDates] =
    useState<Array<CheckboxValueType>>([]);
  const [actDates, setActDates] = useState<Array<CheckboxValueType>>([]);
  const [shareActionRatings, setShareActionRatings] = useState<ICheckboxData[]>(
    [],
  );
  const { data: defaultfiltersOptions } = useExploreDefaultFiltersQuery();

  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [disabled, setDisabled] = useState(true);

  const checkboxSelectAllData = [
    {
      label: 'Select all',
      value: SELECT_ALL,
    },
  ];
  const { state } = useExploreContext();
  const { setFilterParameters } = useProvideExplore();
  const filterDates = useMemo(() => {
    const numberOfYearsInThePast = 3;
    const currentYear = new Date().getFullYear();
    const result = [];
    let map: Record<string, CheckboxValueType> = {};
    for (let i = 0; i <= numberOfYearsInThePast; i += 1) {
      let rawValue: RangePair = {
        to: `${currentYear - i + 1}`,
        from: `${currentYear - i}`,
      };
      let label = `${currentYear - i}`;
      if (i === numberOfYearsInThePast) {
        label = `Prior ${currentYear - i + 1}`;
        rawValue = {
          to: `${currentYear - i + 1}`,
          from: `${currentYear - 100}`,
        };
      }
      const value = JSON.stringify(rawValue);
      result.push({
        // this is ugly hack to generate filter conditions
        value,
        label,
      });
      map = {
        ...map,
        [RangePairSerializer.serialize(rawValue)]: value,
      };
    }
    return {
      options: result,
      map,
    };
  }, []);

  const filterDatesACT = useMemo(() => {
    //we have this variable hard-coded because ACT dates started in 2022
    const startOfActDates = 2022;
    const currentYear = new Date().getFullYear();
    const result = [];
    let map: Record<string, CheckboxValueType> = {};
    for (let i = currentYear; i >= startOfActDates; i -= 1) {
      const rawValue: RangePair = {
        to: `${i + 1}`,
        from: `${i}`,
      };
      const label = `${i}`;
      const value = JSON.stringify(rawValue);
      result.push({
        value,
        label,
      });
      map = {
        ...map,
        [RangePairSerializer.serialize(rawValue)]: value,
      };
    }
    return {
      options: result,
      map,
    };
  }, []);

  const sortRatings = (a: string, b: string) => {
    if (a.includes(b) || b.includes(a)) return b.length - a.length;
    return a.localeCompare(b);
  };

  const handleSFDRCheck = (list: string[]) => {
    setCheckedSFDR(list);
  };

  const handleUNPRICheck = (list: Array<CheckboxValueType>) => {
    setDatesUNPRI(list);
  };

  const handleTCFDCheck = (list: Array<CheckboxValueType>) => {
    setDatesTCFD(list);
  };

  const handleF4BCheck = (list: Array<CheckboxValueType>) => {
    setFinanceForBiodiversityDates(list);
  };

  const handleACTCheck = (list: Array<CheckboxValueType>) => {
    setActDates(list);
  };

  const handleApply = () => {
    setFilterParameters({
      unpriDates: datesUNPRI
        .filter(value => value !== SELECT_ALL)
        .map(mapDatePairs),
      tcfdDates: datesTCFD
        .filter(value => value !== SELECT_ALL)
        .map(mapDatePairs),
      sfdr: checkedSFDR,
      shareActionRatings: shareActionRatings.map(i => i.value),
      financeForBiodiversityDates: financeForBiodiversityDates
        .filter(value => value !== SELECT_ALL)
        .map(mapDatePairs),
      actDates: actDates
        .filter(value => value !== SELECT_ALL)
        .map(mapDatePairs),
      netZeroAssetManager,
      stewardshipCode,
    });
    onClose();
  };

  const onClear = () => {
    setCheckedSFDR([]);
    setDatesUNPRI([]);
    setDatesTCFD([]);
    setShareActionRatings([]);
    setStewardshipCode(undefined);
    setFinanceForBiodiversityDates([]);
    setActDates([]);
    setNetZeroAssetManager(undefined);
  };

  useEffect(() => {
    const count = countSelectedFilter([
      checkedSFDR.length > 0,
      datesUNPRI.length > 0,
      datesTCFD.length > 0,
      shareActionRatings.length > 0,
      stewardshipCode ? true : false,
      financeForBiodiversityDates.length > 0,
      actDates.length > 0,
      netZeroAssetManager ? true : false,
    ]);

    setSelectedFilter(count);
  }, [
    checkedSFDR,
    datesUNPRI,
    datesTCFD,
    shareActionRatings,
    stewardshipCode,
    netZeroAssetManager,
    financeForBiodiversityDates,
    actDates,
  ]);

  useEffect(() => {
    const filters = state.filterParameters;

    setDisabled(
      ![
        filters.sfdr,
        filters.unpriDates,
        filters.tcfdDates,
        filters.shareActionRatings,
        filters.stewardshipCode,
        filters.netZeroAssetManager,
        filters.financeForBiodiversityDates,
        filters.actDates,
      ].some(i => (Array.isArray(i) ? !!i.length : !!i)),
    );

    if (filters?.sfdr) {
      setCheckedSFDR(filters.sfdr);
    }
    if (filters?.unpriDates) {
      setDatesUNPRI(filters.unpriDates.map(item => filterDates.map[item]));
    }
    if (filters?.tcfdDates) {
      setDatesTCFD(filters.tcfdDates.map(item => filterDates.map[item]));
    }
    if (filters?.shareActionRatings) {
      setShareActionRatings(
        filters.shareActionRatings.map(sar => ({ label: sar, value: sar })),
      );
    }
    if (filters?.stewardshipCode) {
      setStewardshipCode(filters.stewardshipCode);
    }
    if (filters?.netZeroAssetManager) {
      setNetZeroAssetManager(filters.netZeroAssetManager);
    }
    if (filters?.financeForBiodiversityDates) {
      setFinanceForBiodiversityDates(
        filters.financeForBiodiversityDates.map(item => filterDates.map[item]),
      );
    }
    if (filters?.actDates) {
      setActDates(filters.actDates.map(item => filterDates.map[item]));
    }
  }, [
    state.filterParameters.sfdr,
    state.filterParameters.unpriDates,
    state.filterParameters.tcfdDates,
    state.filterParameters.shareActionRatings,
    state.filterParameters.stewardshipCode,
    state.filterParameters.netZeroAssetManager,
    state.filterParameters.financeForBiodiversityDates,
    state.filterParameters.actDates,
  ]);

  const sfdr = useMemo(
    () =>
      defaultfiltersOptions.sfdr?.map(i => ({
        label: i.label,
        value: i.label,
      })),
    [defaultfiltersOptions.sfdr, state.filterParameters.sfdr],
  );

  const shareActionSurveyRatings = useMemo(
    () => (
      <ButtonCheckbox
        data={defaultfiltersOptions.shareActionSurveyRatings
          .map(i => ({
            label: i.label,
            value: i.label,
          }))
          .sort((a, b) => sortRatings(a.label, b.label))}
        onChange={(
          onChangeHandler: (prevValues: Array<ICheckboxData>) => never[],
        ) => {
          const changedValues = onChangeHandler(shareActionRatings);
          setShareActionRatings(changedValues);
        }}
        value={shareActionRatings}
      />
    ),
    [defaultfiltersOptions.shareActionSurveyRatings, shareActionRatings],
  );
  const yesNoOptions = (
    handler: (list: YesNoOption) => void,
    value?: YesNoOption,
  ) => (
    <Checkbox
      data={Object.values(YesNoOption).map(i => ({ label: i, value: i }))}
      direction="row"
      onChange={handler}
      value={value}
      isRadio
    />
  );

  const mapDatePairs = useCallback((serialisedDate: CheckboxValueType) => {
    const parsedPair = JSON.parse(serialisedDate.toString()) as RangePair;
    return RangePairSerializer.serialize(parsedPair);
  }, []);

  return (
    <ExploreFilterModal
      title="ESG"
      isVisible={isOpen}
      isDisableApply={disabled && !selectedFilter}
      isDisableClear={!selectedFilter}
      toggleModal={onClose}
      onClear={onClear}
      onApply={handleApply}
    >
      <div className="modal-esg-provider">
        <div className="modal-esg-provider__item" data-test-id={'ESGLabelSFDR'}>
          <h6>SFDR (Fund Level)</h6>
          <Checkbox
            data={sfdr}
            direction="row"
            onChange={handleSFDRCheck}
            value={checkedSFDR}
          />
        </div>
        <div className="modal-esg-provider__item" data-test-id={'ESGLabelTCFD'}>
          <h6>Task Force on Climate-Related Financial Disclosures (TCFD)</h6>
          <p>Signed up in:</p>
          <div className="flex">
            <Checkbox
              data={checkboxSelectAllData}
              direction="row"
              onChange={(list: Array<CheckboxValueType>) => {
                if (list.includes(SELECT_ALL)) {
                  setDatesTCFD([
                    ...filterDates.options.map(option => option.value),
                    ...list,
                  ]);
                } else {
                  setDatesTCFD([]);
                }
              }}
              value={
                datesTCFD.length >= filterDates.options.length
                  ? [SELECT_ALL]
                  : []
              }
            />
            <Checkbox
              data={filterDates.options}
              direction="row"
              onChange={handleTCFDCheck}
              value={datesTCFD}
            />
          </div>
        </div>
        <div
          className="modal-esg-provider__item"
          data-test-id={'ESGLabelUNPRI'}
        >
          <h6>UN Principles for Responsible Investment (UN PRI)</h6>
          <p>Signed up in:</p>
          <div className="flex">
            <Checkbox
              data={checkboxSelectAllData}
              direction="row"
              onChange={(list: Array<CheckboxValueType>) => {
                if (list.includes(SELECT_ALL)) {
                  setDatesUNPRI([
                    ...filterDates.options.map(option => option.value),
                    ...list,
                  ]);
                } else {
                  setDatesUNPRI([]);
                }
              }}
              value={
                datesUNPRI.length >= filterDates.options.length
                  ? [SELECT_ALL]
                  : []
              }
            />
            <Checkbox
              data={filterDates.options}
              direction="row"
              onChange={handleUNPRICheck}
              value={datesUNPRI}
            />
          </div>
        </div>
        <div
          className="modal-esg-provider__item"
          data-test-id={'ESGLabelNetZero'}
        >
          <h6>Net Zero Asset Managers (Net Zero)</h6>
          {yesNoOptions(setNetZeroAssetManager, netZeroAssetManager)}
        </div>
        <div className="modal-esg-provider__item" data-test-id={'ESGLabelUKSC'}>
          <h6>Stewardship Code (UK SC)</h6>
          {yesNoOptions(setStewardshipCode, stewardshipCode)}
        </div>
        <div className="modal-esg-provider__item" data-test-id={'ESGLabelF4B'}>
          <h6>Finance for Biodiversity (F4B)</h6>
          <div className="flex">
            <Checkbox
              data={checkboxSelectAllData}
              direction="row"
              onChange={(list: Array<CheckboxValueType>) => {
                if (list.includes(SELECT_ALL)) {
                  setFinanceForBiodiversityDates([
                    ...filterDates.options.map(option => option.value),
                    ...list,
                  ]);
                } else {
                  setFinanceForBiodiversityDates([]);
                }
              }}
              value={
                financeForBiodiversityDates.length >= filterDates.options.length
                  ? [SELECT_ALL]
                  : []
              }
            />
            <Checkbox
              data={filterDates.options}
              direction="row"
              onChange={handleF4BCheck}
              value={financeForBiodiversityDates}
            />
          </div>
        </div>
        <div className="modal-esg-provider__item" data-test-id={'ESGLabelACT'}>
          <h6>ACT</h6>
          <div className="flex">
            <Checkbox
              data={checkboxSelectAllData}
              direction="row"
              onChange={(list: Array<CheckboxValueType>) => {
                if (list.includes(SELECT_ALL)) {
                  setActDates([
                    ...filterDatesACT.options.map(option => option.value),
                    ...list,
                  ]);
                } else {
                  setActDates([]);
                }
              }}
              value={
                actDates.length >= filterDatesACT.options.length
                  ? [SELECT_ALL]
                  : []
              }
            />
            <Checkbox
              data={filterDatesACT.options}
              direction="row"
              onChange={handleACTCheck}
              value={actDates}
            />
          </div>
        </div>
        <div
          className="modal-esg-provider__item"
          data-test-id={'ESGLabelShareActionRatings'}
        >
          <h6>Share Action Ratings</h6>
          {shareActionSurveyRatings}
        </div>
      </div>
    </ExploreFilterModal>
  );
};
