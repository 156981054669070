import { Modal } from 'antd';
import cx from 'classnames';
import React from 'react';

import Button from '@/components/Button';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';

import style from './style.module.less';

type ModalProps = {
  modalInfo: {
    title: string;
    description: string | React.ReactNode;
    btnName: string;
    succesMessage: string;
    errorMessage: string;
  };
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  onClickAction: () => Promise<any | void>;
  closeIcon?: React.ReactNode;
  loading?: boolean;
  children?: React.ReactNode;
  width?: number;
  isUserSelectBucket: boolean;
};

const WrapperMultiSelectBucketModal: React.FCWithChild<ModalProps> = ({
  modalInfo,
  isVisible,
  className,
  loading,
  toggleModal,
  onClickAction,
  children,
  width = 400,
  isUserSelectBucket,
}) => {
  const { dispatch: dispatchApp } = useAppContext();

  const onClick = () => {
    onClickAction()
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: modalInfo.succesMessage || '' },
        });
      })
      .catch(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: modalInfo.errorMessage || '',
        });
      });
    toggleModal();
  };
  return (
    <Modal
      width={width}
      open={isVisible}
      onCancel={toggleModal}
      maskClosable={false}
      className={cx(style.modal, className)}
      footer={[
        <Button
          className={style['cancel-btn']}
          size="large"
          type="link"
          key="secondary"
          disabled={loading}
          onClick={toggleModal}
        >
          Cancel
        </Button>,
        <Button
          className={style['okay-btn']}
          type="primary"
          size="large"
          onClick={onClick}
          key="primary"
          loading={loading}
          disabled={isUserSelectBucket}
        >
          {modalInfo.btnName}
        </Button>,
      ]}
    >
      <div className="flex">
        <div className={style['modal-title']}>{modalInfo.title}</div>
      </div>
      <div className={style['modal-info']}>{modalInfo.description}</div>
      {children}
    </Modal>
  );
};

export default WrapperMultiSelectBucketModal;
