import { LIMIT_FUNDS_PER_BUCKET } from '@aminsights/shared';

import { LIMIT_BUCKETS } from './misc';

export const BUCKET_LIMIT_TEXT = `You can add up to ${LIMIT_BUCKETS} buckets and assign a maximum of ${LIMIT_FUNDS_PER_BUCKET} funds/trusts per bucket.`;

export const ERROR_ISIN_INVALID = 'ISIN is not valid';
export const ERROR_ISIN_ALREADY_EXISTS_IN_BUCKETS =
  'ISIN already being used in this bucket. You can use an alternative ISIN for this fund';
export const ERROR_ISIN_ALREADY_EXISTS_IN_THE_POTRFOLIO =
  'ISIN already being used in this portfolio. You can use an alternative ISIN for this fund';

export const UPDATE_FUND_FOR_PORTFOLIO_SUCCESS =
  'Successfully updated fund for portfolio';
export const UPDATE_FUND_FOR_PORTFOLIO_FAIL = 'Fund not updated for portfolio';

export const UPLOAD_FAILED = 'Upload Failed';
