import './style.less';

import {
  NotificationsSettingsForCommentary,
  NotificationsSettingsForSignificantChanges,
  SettingsApi,
} from '@aminsights/contract';
import { ChangeTypeEnum } from '@aminsights/shared';
import { Checkbox, Switch } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as IconArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Button } from '@/components';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import Modal from '@/partials/Modal';
import NotificationModalChecklist, {
  NotificationType,
} from '@/partials/Modal/ModalChecklist';
import { openApiConfig } from '@/utils';

const Profile: React.FCWithChild = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaveChangesEnabled, setIsSaveChangesEnabled] = useState(false);
  const { dispatch: dispatchApp } = useAppContext();
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const { isWeeklyUpdateTreatementEnabled } = featureSwitchState;

  const settingsAPI = new SettingsApi(openApiConfig());
  const [
    significantChangesNotificationSettings,
    setSignificantChangesNotificationSettings,
  ] = useState<NotificationsSettingsForSignificantChanges>();
  const [commentaryNotificationSettings, setCommentaryNotificationSettings] =
    useState<NotificationsSettingsForCommentary>({
      email: false,
      inApp: false,
    });

  const [shouldSendWeeklyUpdate, setShouldSendWeeklyUpdate] =
    useState<boolean>();

  useEffect(() => {
    settingsAPI.getNotificationsSettings().then(notificationSettings => {
      setCommentaryNotificationSettings(notificationSettings.data.commentary);
      setSignificantChangesNotificationSettings(
        notificationSettings.data.significantChanges,
      );
      setShouldSendWeeklyUpdate(
        notificationSettings.data.shouldSendWeeklyUpdate,
      );
    });
  }, []);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const setCheckedAll = (type: NotificationType, value: boolean) => {
    if (significantChangesNotificationSettings) {
      const updatedNotificationSettings = {
        ...significantChangesNotificationSettings[type],
      };
      Object.keys(updatedNotificationSettings).forEach((key: string) => {
        updatedNotificationSettings[key as ChangeTypeEnum] = value;
      });
      setSignificantChangesNotificationSettings({
        ...significantChangesNotificationSettings,
        [type]: updatedNotificationSettings,
      });
    }
    setIsDirty(true);
  };

  const setChecked = (
    key: ChangeTypeEnum,
    type: NotificationType,
    value?: boolean,
  ) => {
    if (significantChangesNotificationSettings) {
      const subProperty = significantChangesNotificationSettings[type];
      setSignificantChangesNotificationSettings({
        ...significantChangesNotificationSettings,
        [type]: {
          ...subProperty,
          [key]: value === undefined ? !subProperty[key] : value,
        },
      });
    }
    setIsDirty(true);
  };

  const handleCommentaryNotificationChanged = useCallback(
    (key: 'inApp' | 'email') => {
      const changedProperty = !commentaryNotificationSettings[key];
      const commentaryNotifications = {
        ...commentaryNotificationSettings,
        ...{ [key]: changedProperty },
      };
      if (
        significantChangesNotificationSettings &&
        shouldSendWeeklyUpdate !== undefined
      ) {
        settingsAPI.saveNotificationsSettings({
          significantChanges: significantChangesNotificationSettings,
          commentary: commentaryNotifications,
          shouldSendWeeklyUpdate: shouldSendWeeklyUpdate,
        });
      }
      setCommentaryNotificationSettings(commentaryNotifications);
    },
    [
      commentaryNotificationSettings,
      significantChangesNotificationSettings,
      shouldSendWeeklyUpdate,
    ],
  );

  return (
    <div className="flex flex-row justify-center">
      <Modal
        title="Significant Changes"
        size={704}
        className="modal"
        isVisible={isModalVisible}
        toggleModal={() => toggleModal()}
        onApply={async () => {
          if (
            significantChangesNotificationSettings &&
            shouldSendWeeklyUpdate !== undefined
          )
            await settingsAPI.saveNotificationsSettings({
              significantChanges: significantChangesNotificationSettings,
              commentary: commentaryNotificationSettings,
              shouldSendWeeklyUpdate: shouldSendWeeklyUpdate,
            });
          setIsDirty(false);
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: { text: 'Notifications saved.' },
          });
          toggleModal();
        }}
        onCancel={() => {
          setIsDirty(false);
          settingsAPI.getNotificationsSettings().then(notificationSettings => {
            setSignificantChangesNotificationSettings(
              notificationSettings.data.significantChanges,
            );
          });
        }}
        footerConfig={{
          cancelText: '',
          applyText: 'Save',
          disableApplyButton: !isDirty,
        }}
        dataTestId="notificationsModal"
      >
        {significantChangesNotificationSettings && (
          <NotificationModalChecklist
            notificationSettings={significantChangesNotificationSettings}
            setChecked={setChecked}
            setAllChecked={setCheckedAll}
          />
        )}
      </Modal>
      <div className="section-notifications">
        <p className="section-notifications--heading">
          Manage your notifications across your watchlist
        </p>
        <div className="section-notifications__tabs">
          <div className="section-notifications--significant">
            <div className="section-notifications--significant-header">
              <h3 className="title" data-test-id="noticationsSectionScTitle">
                Significant Changes
              </h3>
            </div>
            <div
              role="button"
              className="section-notifications__button"
              onClick={() => toggleModal()}
              onKeyDown={() => toggleModal()}
              tabIndex={0}
              data-test-id="manageNotificationsButton"
            >
              <div className="section-notifications__button--content">
                <h3 className="button-title">Manage Notifications</h3>
                <p className="button-description">
                  Choose which notifications you want and how you receive them.
                </p>
              </div>
              <IconArrow className="icon icon-right" />
            </div>
          </div>

          <div className="section-notifications--commentary">
            <div className="section-notifications--commentary-header">
              <h3 className="title" data-test-id="noticationsSectionScTitle">
                Commentary
              </h3>
            </div>
            <p className="description">
              Choose how you want to receive notifications for newly added
              commentaries
            </p>
            <div className="section-notifications--commentary-form">
              <div className="section-notifications--commentary-field">
                <Checkbox
                  checked={commentaryNotificationSettings.inApp}
                  onChange={() => handleCommentaryNotificationChanged('inApp')}
                  id="commentaryNotificationSettingsInApp"
                  data-test-id="commentaryNotificationSettingsInApp"
                />
                <label
                  className="cursor-pointer text-neutral-200"
                  htmlFor="commentaryNotificationSettingsInApp"
                >
                  In-app
                </label>
              </div>
              <div className="section-notifications--commentary-field">
                <Checkbox
                  checked={commentaryNotificationSettings.email}
                  onChange={() => handleCommentaryNotificationChanged('email')}
                  id="commentaryNotificationSettingsEmail"
                  data-test-id="commentaryNotificationSettingsEmail"
                />
                <label
                  className="cursor-pointer text-neutral-200"
                  htmlFor="commentaryNotificationSettingsEmail"
                >
                  Email
                </label>
              </div>
            </div>
          </div>

          {isWeeklyUpdateTreatementEnabled && (
            <>
              <div className="section-notifications--weekly-update">
                <div className="section-notifications--weekly-update-header">
                  <h3
                    className="title"
                    data-test-id="noticationsSectionScTitle"
                  >
                    Weekly Update
                  </h3>
                </div>
                <div className="section-notifications--weekly-update-body pr-4 flex items-center">
                  <p className="description">Receive weekly update emails</p>
                  <Switch
                    className="section-notifications--weekly-update-switch"
                    onChange={checked => {
                      setIsSaveChangesEnabled(true);
                      setShouldSendWeeklyUpdate(checked);
                    }}
                    checked={shouldSendWeeklyUpdate}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-end mt-3 mr-3">
                <Button
                  disabled={!isSaveChangesEnabled}
                  className="submit-btn"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  data-test-id="saveNotificationSettings"
                  // loading={submitting}
                  onClick={async () => {
                    if (
                      !!significantChangesNotificationSettings &&
                      shouldSendWeeklyUpdate !== undefined
                    )
                      await settingsAPI.saveNotificationsSettings({
                        significantChanges:
                          significantChangesNotificationSettings,
                        commentary: commentaryNotificationSettings,
                        shouldSendWeeklyUpdate: shouldSendWeeklyUpdate,
                      });
                    setIsSaveChangesEnabled(true);
                    dispatchApp({
                      type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                      payload: { text: 'Notifications saved.' },
                    });
                  }}
                >
                  Save Changes
                </Button>
              </div>
            </>
          )}
          {/* <div className="section-notifications--announcement"> */}
          {/*   <div className="section-notifications--announcement-header"> */}
          {/*     <h3 className="title gray disabled">Announcements</h3> */}
          {/*   </div> */}
          {/*   <div */}
          {/*     className="section-notifications__button disabled" */}
          {/*     data-test-id="manageAnnouncementsButton" */}
          {/*     // onClick={() => toggleModal('announcements')} */}
          {/*   > */}
          {/*     <div className="section-notifications__button--content"> */}
          {/*       <p className="button-title">Manage Notifications</p> */}
          {/*       <p className="button-description"> */}
          {/*         Choose which notifications you want and how you receive them. */}
          {/*       </p> */}
          {/*     </div> */}
          {/*     <IconArrow className="icon icon-right" /> */}
          {/*   </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Profile;
