import { Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';

import { DropdownItem, DropdownMenu, DropdownProps } from '../shared';
import style from '../style.module.less';

const InlineDropdown: React.FCWithChild<DropdownProps> = ({
  items,
  children,
  className,
  onSelect,
}) => {
  const [activeOption, setActiveOption] = useState<DropdownItem>();
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    setActiveOption(items && items.length ? items[0] : undefined);
  }, []);

  return (
    <>
      <Dropdown
        open={isMenuVisible}
        overlay={() => (
          <DropdownMenu
            items={items}
            state={activeOption}
            setState={setActiveOption}
            onSelect={onSelect}
            emitIsItemSelected={value => setIsMenuVisible(!value)}
          />
        )}
        trigger={['click']}
      >
        <button
          type="button"
          onClick={() => setIsMenuVisible(!isMenuVisible)}
          className={className || style['custom-inline-dropdown']}
        >
          {children}
        </button>
      </Dropdown>

      {isMenuVisible && (
        <div
          className={style['dropdown-overlay']}
          onClick={() => setIsMenuVisible(false)}
          onKeyUp={() => setIsMenuVisible(false)}
          role="button"
          tabIndex={0}
        >
          {' '}
        </div>
      )}
    </>
  );
};

export default InlineDropdown;
