import './style.less';

import React from 'react';

import { Dropdown } from '@/components/Dropdown';
import Pagination from '@/components/Pagination';
import { SearchDropdown } from '@/components/Search';
import ExploreSearchV2, {
  renderOptions,
} from '@/partials/ExploreSearchBox/ExploreSearchBoxV2';
import { useSearchBox } from '@/partials/ExploreSearchBox/useExploreSearchbox';

import AnnouncementsTable from '../components/AnnouncementsTable';
import { announcements, announcementsData, dateDropDownData } from '../data';

const MyList: React.FCWithChild = () => {
  const searchBoxOpts = useSearchBox();
  const handlePagination = (_pageNumber: number, _itemsPerPage: number) => {
    // API call here with page number
    // e.g. https://api.instantwebtools.net/v1/passenger?page={pageNumber}&size={itemsPerPage}
    // save data from api to state management and display data
  };

  return (
    <div className="section-my-list">
      <div className="pagination-nav">
        <Pagination totalCount={198} onPageChange={handlePagination} />
      </div>
      <div className="flex search-option flex-col md:flex-row">
        <div className="xl:w-8/12 lg:w-7/12 md:w-full w-full">
          <div className="search-input">
            <ExploreSearchV2
              dataTestId="myListSearch"
              options={searchBoxOpts.allSuggestions.map(opt =>
                renderOptions({
                  value: opt.shareClassDetails.isin,
                  title: opt.shareClassDetails.code,
                  highlightTerm: searchBoxOpts.searchValue,
                  primaryShareClass: opt.primaryShareClass,
                }),
              )}
              value={searchBoxOpts.searchValue}
              onSelect={searchBoxOpts.onSearchResults}
              onChange={searchBoxOpts.onChange}
              onSubmit={searchBoxOpts.onSearchResults}
              loading={searchBoxOpts.isLoading}
            />
          </div>
        </div>
        <div className="xl:w-2/12 lg:w-3/12 md:w-full w-full">
          <div className="search-announcement-dropdown">
            <SearchDropdown items={announcements} placeholder="Announcement" />
          </div>
        </div>
        <div className="xl:w-2/12 lg:w-2/12 md:w-full w-full">
          <div className="search-date-dropdown">
            <Dropdown items={dateDropDownData} defaultValue="" />
          </div>
        </div>
      </div>
      <div className="announcement-list">
        <AnnouncementsTable data={announcementsData} />
      </div>
    </div>
  );
};

export default MyList;
