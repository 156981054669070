import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import AbsoluteChartTitle from '@/partials/Charts/absolute/AbsoluteChartTitle';
import AbsoluteChartV2 from '@/partials/Charts/absolute/AbsoluteChartV2';
import AbsoluteChartV2WithPortfolio from '@/partials/Charts/absolute/AbsoluteChartV2WithPortfolio';
import ChartEmptyState from '@/partials/Charts/ChartEmptyState';

import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';
import { useChartingContext } from './ChartingContext';

const AbsoluteChartingToolPage = () => {
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const {
    isins,
    benchmark,
    setIsins,
    setBenchmark,
    hasInvestmentTrust,
    portfolios,
    setPortfolios,
  } = useChartTrustFundsFromSearch();

  const chartingCtx = useChartingContext();
  const legendData = chartingCtx.chartLegend;
  const chartColors = chartingCtx.chartColors;

  const ChartsV2 = featureSwitchState.portfolioInChartingPageEnabled ? (
    <AbsoluteChartV2WithPortfolio
      onRemoveFund={(id, isBenchmark, isPortfolio) => {
        if (isBenchmark) {
          setBenchmark('');
          return;
        }
        if (isPortfolio) {
          setPortfolios([id], true);
          const targetId = legendData.find(ld => ld.id?.includes(id))?.id || '';
          const targetColor = chartColors[targetId];
          targetColor && chartingCtx.setColorsRemoved(targetColor);
          return;
        }
        const targetId = legendData.find(ld => ld.isin === id)?.id || '';
        const targetColor = chartColors[targetId];
        targetColor && chartingCtx.setColorsRemoved(targetColor);
        setIsins([id], true);
      }}
      showDropdown={true}
      isins={isins}
      benchmarkId={benchmark}
      hasInvestmentTrust={hasInvestmentTrust}
      resetInvReturnFilterOnChange={false}
      portfolios={portfolios}
      chartColors={chartColors}
    />
  ) : (
    <AbsoluteChartV2
      emptyState={{
        title: 'No added funds/trusts to chart',
        subTitle: 'Search funds to chart',
      }}
      onRemoveFund={(isin, isBenchmark) => {
        isBenchmark ? setBenchmark('') : setIsins([isin], true);
      }}
      showDropdown={true}
      isins={isins}
      benchmarkId={benchmark}
      hasInvestmentTrust={hasInvestmentTrust}
      resetInvReturnFilterOnChange={false}
    />
  );
  if (!(isins.length || portfolios.length)) {
    const title = featureSwitchState.portfolioInChartingPageEnabled
      ? 'No added funds/trusts/portfolios to chart'
      : 'No added funds/trusts to chart';
    const subTitle = featureSwitchState.portfolioInChartingPageEnabled
      ? 'Search funds/portfolios to chart'
      : 'Search funds to chart';
    return (
      <ChartEmptyState
        title={title}
        subtitle={subTitle}
        isDashboardPage={true}
      />
    );
  }
  return (
    <>
      <AbsoluteChartTitle
        hasInvestmentTrust={hasInvestmentTrust}
        showDropdown={true}
      />
      {ChartsV2}
    </>
  );
};

export default AbsoluteChartingToolPage;
