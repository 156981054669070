import { QueryClient } from '@tanstack/react-query';

import { getIsPwaInstalled } from './utils/getIsPwaInstalled';

export const staleTime1Hour = 1000 * 60 * 60;
export const staleTime5Minutes = 1000 * 60 * 5;

const isPwaInstalled = getIsPwaInstalled();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: isPwaInstalled ? 0 : staleTime5Minutes,
      retry: 3, // Maximum of 3 retries
      retryDelay: attempt => Math.min(1000 * 2 ** attempt, 30000) + 5000, // Exponential delay with a base of 5 seconds
      refetchOnWindowFocus: query => {
        // Disable refetch on window focus if there is an error
        return query.state.error ? false : true;
      },
    },
  },
});

export default queryClient;
