import { APP_ROUTE_DASHBOARD } from '@aminsights/shared';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { ReactComponent as IconError } from '@/assets/svg/icons/icon-remove.svg';
import { Button } from '@/components';
import { openJiraForm } from '@/utils/jiraForm';

const AccessDenied = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      window.location.replace(`/${APP_ROUTE_DASHBOARD}`);
    }
  }, [isAuthenticated]);

  return (
    <div className="w-screen h-screen p-6 flex justify-center items-center">
      <div className="w-116 flex flex-col gap-4 text-center">
        <IconError className="icon text-5xl text-danger mx-auto" />
        <div className="flex flex-col gap-2">
          <h2 className="text-neutral-900 font-semibold text-xl">
            Access denied
          </h2>
          <p className="text-neutral text-sm">
            The email you entered doesn’t appear to belong to an organization.
          </p>
        </div>
        <p className="text-neutral text-sm">
          If you continue to encounter this error{' '}
          <a
            className="text-primary underline hover:text-info"
            onClick={() => {
              openJiraForm();
            }}
          >
            get in touch
          </a>
          .
        </p>
        <div className="flex justify-center mt-6">
          <Button
            type="primary"
            className="w-28 py-2.5"
            onClick={() => {
              loginWithRedirect();
            }}
          >
            Try Again
          </Button>
        </div>
        <div className="flex justify-center">
          <button
            className="font-medium text-primary hover:text-info"
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
