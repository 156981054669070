import { Modal } from 'antd';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as GraphicClose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as InfoWarningRed } from '@/assets/svg/icons/icon-warning-red.svg';
import Button from '@/components/Button';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';

import style from './style.module.less';

type ModalProps = {
  modalInfo: {
    title: string;
    description: string | React.ReactNode;
    btnName: string;
    succesMessage: string;
    errorMessage: string;
  };
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  onDeleteClick: () => Promise<any | void>;
  closeIcon?: React.ReactNode;
  loading?: boolean;
  children?: React.ReactNode;
  width?: number;
  withWarningIcon?: boolean;
};

const DeleteModal: React.FCWithChild<ModalProps> = ({
  modalInfo,
  isVisible,
  className,
  closeIcon,
  loading,
  toggleModal,
  onDeleteClick,
  children,
  width = 400,
  withWarningIcon = true,
}) => {
  const { dispatch: dispatchApp } = useAppContext();

  const onClick = () => {
    onDeleteClick()
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: modalInfo.succesMessage || '' },
        });
      })
      .catch(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: modalInfo.errorMessage || '',
        });
      });
    toggleModal();
  };
  return (
    <Modal
      width={width}
      open={isVisible}
      onCancel={toggleModal}
      className={cx(style.modal, className)}
      footer={[
        <Button
          className={style['cancel-btn']}
          size="large"
          type="link"
          key="secondary"
          disabled={loading}
          onClick={toggleModal}
          data-test-id="modalDeleteBucketCancelButton"
        >
          Cancel
        </Button>,
        <Button
          danger
          size="large"
          onClick={onClick}
          key="primary"
          loading={loading}
          disabled={loading}
          data-test-id="modalDeleteBucketConfirmButton"
        >
          {modalInfo.btnName}
        </Button>,
      ]}
      closeIcon={closeIcon || <GraphicClose />}
    >
      <div className="flex">
        {withWarningIcon && (
          <InfoWarningRed
            className={style['modal-icon']}
            width={30}
            height={30}
          />
        )}
        <div className={style['modal-title']}>{modalInfo.title}</div>
      </div>
      <div className={style['modal-info']}>{modalInfo.description}</div>
      {children}
    </Modal>
  );
};

export default DeleteModal;
