import { Slider } from 'antd';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import Accordion, { Panel } from '@/components/Accordion';
import { SLIDER_MAX, SLIDER_MIN } from '@/constants';
import useProvideExplore from '@/pages/app/Explore/useProvideExplore';
import ExploreFilterModal from '@/partials/ExploreFilterModal';
import RenderStyleBox from '@/partials/RenderStyleBox';

import { ExploreModalProps } from '../types';
import { adjustMarksToAvoidDuplicates } from '../utils/adjustMarksToAvoidDuplicates';
import { countSelectedFilter } from '../utils/countSelectedFilter';
import { getChangedMarkValues } from '../utils/getChangedMarkValues';
import { getRangeSliderClassName } from '../utils/getRangeSliderClassName';
import { getValueFromMarks } from '../utils/getValueFromMarks';
import { updateRangeAfterSubmit } from '../utils/updateRangeAfterSubmit';
import style from './style.module.less';

const SLIDER_STEPS = 25;

const marks: Record<number, string> = {
  0: '0',
  25: '5%',
  50: '10%',
  75: '15%',
  100: '20%>',
};

export const EquitiesFilterModal: React.FCWithChild<ExploreModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { state, setFilterParameters } = useProvideExplore();
  const [selectedStyle, setSelectedStyle] = useState<number[]>([]);

  const [roicRange, setRoicRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX,
  ]);

  const [netMarginRange, setNetMarginRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX,
  ]);

  const [roicChange, setRoicChange] = useState<boolean>(false);
  const [netMarginChange, setNetMarginChange] = useState<boolean>(false);

  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [disabled, setDisabled] = useState(true);

  const [roicRangeMin, roicRangeMax] = useMemo(() => roicRange, [roicRange]);
  const [netMarginRangeMin, netMarginRangeMax] = useMemo(
    () => netMarginRange,
    [netMarginRange],
  );

  const handleOnClear = () => {
    setSelectedStyle([]);
    setRoicRange([SLIDER_MIN, SLIDER_MAX]);
    setRoicChange(false);
    setNetMarginRange([SLIDER_MIN, SLIDER_MAX]);
    setNetMarginChange(false);
  };

  useEffect(() => {
    if (state.clearState) {
      handleOnClear();
    }
    state.clearState = undefined;
  }, [state.clearState]);

  const handleOnApply = () => {
    const [roicMin, roicMax] = getValueFromMarks(roicRange, marks);

    const [netMarginMin, netMarginMax] = getValueFromMarks(
      netMarginRange,
      marks,
    );

    if (selectedStyle && selectedStyle.length) {
      setFilterParameters({
        equityStyle: selectedStyle,
      });
    }

    if (roicChange) {
      setFilterParameters({
        roicRange: [
          roicMin,
          roicMax === 20 ? Number.MAX_SAFE_INTEGER : roicMax,
        ],
      });
    }

    if (netMarginChange) {
      setFilterParameters({
        netMarginRange: [
          netMarginMin,
          netMarginMax === 20 ? Number.MAX_SAFE_INTEGER : netMarginMax,
        ],
      });
    }

    if (selectedFilter === 0) {
      setFilterParameters({
        equityStyle: [],
        roicRange: [],
        netMarginRange: [],
      });
    }
    onClose();
  };

  useEffect(() => {
    const count = countSelectedFilter([
      selectedStyle.length > 0,
      roicChange,
      netMarginChange,
    ]);
    setSelectedFilter(count);
  }, [selectedStyle, roicChange, netMarginChange]);

  useMemo(() => {
    const filters = state.filterParameters;

    setDisabled(
      ![filters.equityStyle, filters.roicRange, filters.netMarginRange].some(
        i => (Array.isArray(i) ? !!i.length : !!i),
      ),
    );
    updateRangeAfterSubmit(filters.equityStyle || [], setSelectedStyle);

    updateRangeAfterSubmit(
      filters.roicRange || [],
      setRoicRange,
      setRoicChange,
      marks,
    );
    updateRangeAfterSubmit(
      filters.netMarginRange || [],
      setNetMarginRange,
      setNetMarginChange,
      marks,
    );
  }, [
    state.filterParameters.equityStyle,
    state.filterParameters.roicRange,
    state.filterParameters.netMarginRange,
  ]);

  return (
    <ExploreFilterModal
      title="Equities"
      isVisible={isOpen}
      isDisableApply={disabled && !selectedFilter}
      isDisableClear={!selectedFilter}
      toggleModal={onClose}
      onClear={handleOnClear}
      onApply={handleOnApply}
    >
      <div>
        <Accordion defaultActiveKey="1">
          <Panel header="Style" dataTestId={'equitiesLabelStyle'} key="1">
            <>
              <p
                className={cx('font-semibold text-xs', style['y-style-label'])}
              >
                Size
              </p>
              <div className="flex">
                <RenderStyleBox
                  isBoxClickable
                  onSelectedBox={(selected: number[]) =>
                    setSelectedStyle(selected)
                  }
                  isClear={selectedStyle && !selectedStyle.length}
                  selectedBox={selectedStyle}
                />
              </div>
              <p className="text-xs font-semibold text-center">Style</p>
            </>
          </Panel>
          <Panel
            header="ROIC"
            dataTestId={'equitiesLabelROIC'}
            key="2"
            extra={
              <span>{getChangedMarkValues(roicChange, roicRange, marks)}</span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'equitiesSliderROIC'}
            >
              <Slider
                className={style[getRangeSliderClassName(roicChange)]}
                tooltip={{ open: false }}
                range
                step={SLIDER_STEPS}
                max={SLIDER_MAX}
                marks={marks}
                value={[roicRangeMin, roicRangeMax]}
                onChange={value => {
                  setRoicRange(adjustMarksToAvoidDuplicates(value, marks));
                  setRoicChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Net Margin"
            dataTestId={'equitiesLabelNetMargin'}
            key="3"
            extra={
              <span>
                {getChangedMarkValues(netMarginChange, netMarginRange, marks)}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'equitiesSliderNetMargin'}
            >
              <Slider
                className={style[getRangeSliderClassName(netMarginChange)]}
                tooltip={{ open: false }}
                range
                step={SLIDER_STEPS}
                max={SLIDER_MAX}
                marks={marks}
                value={[netMarginRangeMin, netMarginRangeMax]}
                onChange={value => {
                  setNetMarginRange(adjustMarksToAvoidDuplicates(value, marks));
                  setNetMarginChange(true);
                }}
              />
            </div>
          </Panel>
        </Accordion>
      </div>
    </ExploreFilterModal>
  );
};
