import { Modal } from 'antd';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as GraphicClose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as IconExclamation } from '@/assets/svg/icons/icon-error-v2.svg';
import Button from '@/components/Button';

import style from './style.module.less';

export interface StaticDataForLimitModal {
  entityName: string;
  limitForEntities: number;
  limitForFundsOrTrusts: number;
}

type ModalProps = {
  isVisible: boolean;
  onApply?: () => void;
  toggleModal: () => void;
};

const LeavePageModal: React.FCWithChild<ModalProps> = ({
  isVisible,
  onApply,
  toggleModal,
}) => {
  return (
    <Modal
      width={440}
      open={isVisible}
      onCancel={toggleModal}
      className={cx(style.modal)}
      footer={[]}
      closeIcon={<GraphicClose />}
    >
      <div className="text-center">
        <div className={cx('flex', style['modal-title'])}>
          <IconExclamation className="mr-1" width={32} height={32} />
          <p className="text-left">You have unsaved changes</p>
        </div>
        <p className={cx('text-justify', style['modal-info'])}>
          Changes you made will be discarded if you leave this page.
        </p>
      </div>
      <div
        className={cx('flex flex-row justify-end mt-6', style['submit-div'])}
      >
        <Button
          size="large"
          type="link"
          key="secondary"
          className={style['cancel-btn']}
          onClick={() => {
            toggleModal();
          }}
        >
          Continue editing
        </Button>
        <Button
          size="large"
          type="primary"
          className="font-semibold"
          onClick={() => {
            onApply?.();
          }}
          key="primary"
        >
          Leave this page
        </Button>
      </div>
    </Modal>
  );
};

export default LeavePageModal;
