import { DATE_PERIOD_FILTER, EDateFilterValues } from '@aminsights/shared';
import { ScatterConfig } from '@ant-design/charts';
import { Dropdown as AntDropdown, Menu } from 'antd';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import { Dropdown } from '@/components';
import useGetRiskReturnScatterPlotData from '@/hooks/query-hooks/chart-hooks/useGetRiskReturnScatterPlotData';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import {
  InvestmentTrustReturnType,
  useDropDownContext,
} from '@/partials/Charts/Context';
import chartStyles from '@/partials/Charts/utils/chart.module.less';
import useChartColors from '@/partials/Charts/utils/useChartColors';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import ScatterPlotLegendCard from '@/partials/LegendCards/ScatterPlotLegendCard';

import { STROKE_GREY } from '../../Charts/utils/colors';
import BaseScatterPlot from '../components/BaseScatterPlot';
import { ScatterPlotCardDetails } from '../utils/scatter-plot-data';
import style from './style.module.less';

interface RiskReturnScatterPlotProps {
  isins: string[];
  benchmarkId?: string;
  chartOnly?: boolean;
  hasInvestmentTrust?: boolean;
  featuredIsins?: string[];
  showDropdown?: boolean;
  onRemoveFund?: (isin: string) => void;
  retainSortOrder?: boolean;
  scatterPlotColors?: Record<string, string>;
}

export const allowedPeriodValuesForRiskReturn = [
  EDateFilterValues['1YR'],
  EDateFilterValues['3YR'],
  EDateFilterValues['5YR'],
];

const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
  allowedPeriodValuesForRiskReturn.some(apv => apv === p.value),
);

const RiskReturnScatterPlotV2: React.FCWithChild<
  RiskReturnScatterPlotProps
> = ({
  isins,
  benchmarkId,
  chartOnly,
  hasInvestmentTrust = false,
  showDropdown,
  onRemoveFund,
  featuredIsins,
  retainSortOrder,
  scatterPlotColors: scatterPlotColorsProps,
}) => {
  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();
  const [legendData, setLegendData] = useState<ScatterPlotCardDetails[]>([]);

  const {
    value: investmentTrustFilter,
    setValue: setInvestmentTrustFilter,
    valueLabel,
  } = useDropDownContext();

  const period = useMemo(() => {
    if (allowedPeriodValuesForRiskReturn.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const { data, isLoading } = useGetRiskReturnScatterPlotData({
    period,
    isins,
    benchmarkId,
    riskDetailsType: investmentTrustFilter.risk,
    featuredIsins,
    retainSortOrder,
  });

  const scatterPlotColorsHook = useChartColors({
    isins,
    legend: data?.legendData || [],
  });
  const scatterPlotColors = scatterPlotColorsProps || scatterPlotColorsHook;
  const scatterPlotData = useMemo(
    () =>
      data?.data?.map(s => ({
        ...s,
        color: scatterPlotColors[s.id],
      })) || [],
    [data, scatterPlotColors],
  );

  useEffect(() => {
    if (data?.legendData?.length && scatterPlotColors) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.label || '',
          standardDeviation: f.xValue || 0,
          totalReturnOverPeriod: f.yValue || 0,
          id: f.id || '',
          isBenchmark: f.isBenchmark || false,
          label: f.label || '',
          isFeatured: f.isFeatured || false,
          color: scatterPlotColors[f.id || ''] || '',
        })),
      );
    }
  }, [data, scatterPlotColors]);

  const config: ScatterConfig = useMemo(
    () => ({
      className: 'custom-antd-scatter-plot',
      xField: '',
      yField: '',
      data: scatterPlotData,
      colorField: 'color',
      color: data => {
        // Based on the colorField it is configured
        return data.color;
      },
    }),
    [scatterPlotData, scatterPlotColors],
  );

  const chartFilterMenu = (
    <Menu
      triggerSubMenuAction="click"
      items={[
        {
          label: 'Investment Trust Return',
          key: 'InvestmentTrustReturn',
          disabled: !hasInvestmentTrust,
          children: (
            [
              { label: 'NAV', value: InvestmentTrustReturnType.NAV },
              {
                label: 'Share Price',
                value: InvestmentTrustReturnType.SharePrice,
              },
            ] as const
          ).map(item => ({
            key: item.label,
            label: (
              <div
                className={cx({
                  [chartStyles['chart-filter-menu-item-active']]:
                    valueLabel === item.value,
                  [chartStyles['chart-filter-menu-item-disabled']]:
                    !hasInvestmentTrust,
                })}
              >
                {item.label}
              </div>
            ),
            onClick: () => {
              setInvestmentTrustFilter(item.value);
            },
          })),
        },
      ]}
    />
  );

  return (
    <div>
      <div>
        {!chartOnly && (
          <div className={chartStyles.header}>
            <h5
              data-test-id="riskReturnChartLabel"
              className="text-sm font-bold text-darkest"
            >
              <Loader
                width="150px"
                loading={isLoading}
                component={<>Risk/Return</>}
              />
            </h5>
            <div className="w-full col-span-2 justify-self-end sm:col-span-1 sm:w-auto">
              <Dropdown
                rounded
                label="Select value"
                dataTestId="riskReturnChartDropdown"
                value={period}
                onSelect={(selectedValue: EDateFilterValues) => {
                  setPeriod(parseDateValue(selectedValue), selectedValue);
                }}
                disabled={isLoading}
                items={allowedPeriodsForDropdown}
                defaultValue={period}
              />
            </div>
            {showDropdown && (
              <AntDropdown
                className={cx(
                  chartStyles['icon-meatball'],
                  'row-start-1 col-start-2 sm:col-start-3',
                )}
                overlay={chartFilterMenu}
                placement="bottomRight"
                trigger={['click']}
                overlayClassName={chartStyles['chart-filter-dropdown']}
              >
                <MeatballMenu />
              </AntDropdown>
            )}
          </div>
        )}
        {!isLoading && (
          <div data-test-id="riskReturnChart">
            <BaseScatterPlot
              config={config}
              strokeColor={STROKE_GREY}
              onUpdateLegend={setLegendData}
            />
          </div>
        )}
        {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
        <div
          data-test-id="riskReturnChartLegend"
          className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
        >
          <Loader
            row={2}
            width="150px"
            loading={isLoading}
            component={legendData
              .filter(f => f.label)
              .map(legendItem => (
                <ScatterPlotLegendCard
                  key={legendItem.id}
                  label={legendItem.label}
                  tooltip={legendItem.tooltip || legendItem.label}
                  isIndex={legendItem.isBenchmark}
                  color={legendItem.color}
                  isFeatured={
                    featuredIsins?.length ? legendItem.isFeatured : false
                  }
                  idForFundDetailsLink={legendItem.isin}
                  isActive={legendItem.isActive}
                  totalReturnOverPeriod={legendItem.totalReturnOverPeriod}
                  standardDeviation={legendItem.standardDeviation}
                  onRemove={onRemoveFund}
                />
              ))}
          />
        </div>
      </div>
      {/* Devider is here because it is only on charts page */}
      <div className={style.divider} />
    </div>
  );
};

export default RiskReturnScatterPlotV2;
