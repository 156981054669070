import { buildFundDetailsPath } from '@aminsights/shared';
import { useHistory } from 'react-router-dom';

// @deprecated - Given an id you should be able to navigate with just history and `buildFundDetailsPath`
const handleOnRow = () => {
  const history = useHistory();

  const clickRow = (
    id: string,
    event: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    event.stopPropagation();

    const checkbox = event.target as HTMLElement;
    const hasCheckbox = checkbox.closest('input[type="checkbox"]') !== null;

    if (!hasCheckbox) {
      if (id) {
        const selection = window.getSelection()?.toString();
        if (!selection) {
          const link = buildFundDetailsPath(id);
          history.push(link);
        }
      }
    }
  };
  return clickRow;
};

export default handleOnRow;
