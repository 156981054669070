import { List } from 'antd';
import cx from 'classnames';

import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';

import { ISignificantChanges } from '../PerSectionModalData';
import style from './style.module.less';

export const TwoColumnTableSection = ({
  sectionInfo,
  isLoading,
}: {
  sectionInfo: ISignificantChanges[] | undefined;
  isLoading: boolean;
}) => {
  const EMPTY_DATA_STRING = '';
  return (
    <div className="border rounded">
      <List
        //we need to pass empty string values to avoid antd adding the no data message
        dataSource={
          !isLoading
            ? sectionInfo
            : [
                {
                  label: EMPTY_DATA_STRING,
                  value: EMPTY_DATA_STRING,
                  key: EMPTY_DATA_STRING,
                },
              ]
        }
        renderItem={item => (
          <List.Item key={item.key} className="p-3 flex">
            <Loader
              loading={isLoading}
              component={
                <div
                  className={cx(
                    style['significant-changes-modal-container__label'],
                    'w-1/2',
                  )}
                >
                  {item.label}
                </div>
              }
            />
            <Loader
              loading={isLoading}
              component={
                <div
                  className={cx(
                    style['significant-changes-modal-container__value'],
                    'text-right',
                  )}
                >
                  {item.value}
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};
