import React from 'react';

import LegendCard, { LegendCardProps } from '..';

interface ScatterPlotLegendCardProps extends LegendCardProps {
  label: string;
  totalReturnOverPeriod: number;
  standardDeviation: number;
}

const ScatterPlotLegendCard: React.FCWithChild<ScatterPlotLegendCardProps> = ({
  color,
  label,
  tooltip,
  isIndex,
  isFeatured,
  isActive,
  idForFundDetailsLink,
  totalReturnOverPeriod,
  standardDeviation,
  onRemove,
}) => {
  return (
    <LegendCard
      label={label}
      isFeatured={isFeatured}
      color={color}
      tooltip={tooltip}
      isActive={isActive}
      isIndex={isIndex}
      idForFundDetailsLink={idForFundDetailsLink}
      onRemove={onRemove}
    >
      <div className="flex flex-row gap-2">
        <div className="text-xs text-darkest font-bold">{`${standardDeviation.toFixed(
          1,
        )}%`}</div>
        <span className="text-xs font-normal text-neutral-50">|</span>
        <div className="text-xs text-darkest font-bold">{`${totalReturnOverPeriod.toFixed(
          1,
        )}%`}</div>
      </div>
    </LegendCard>
  );
};

export default ScatterPlotLegendCard;
