import { Progress, Steps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { NavbarSimple } from '@/components/Navbar';

import WelcomeModal from './components/WelcomeModal';
import { OnboardingProvider, useOnboardingContext } from './context';
import CreateBuckets from './CreateBuckets';
import CreateWatchlist from './CreateWatchlist';
import Done from './Done';
import SignificantChanges from './SignificantChanges';
import style from './style.module.less';

const Onboarding: React.FCWithChild = () => {
  const { state } = useOnboardingContext();

  const [isLoadingData, setLoadingData] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingData(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isLoadingData]);

  const progressPercentage = useMemo(() => {
    return (state.currentStep / 4) * 100;
  }, [state.currentStep]);

  const { Step } = Steps;

  const steps = [
    {
      title: 'Create watchlist',
      content: <CreateWatchlist />,
    },
    {
      title: 'Create buckets',
      content: (
        <CreateBuckets
          handleBucketLoading={(value: boolean) => setLoadingData(value)}
        />
      ),
    },
    {
      title: 'Significant changes',
      content: <SignificantChanges />,
    },
    {
      title: 'Done',
      content: <Done />,
    },
  ];

  return (
    <>
      <NavbarSimple />
      <div className={style.onboarding}>
        <div className="pt-14 md:pt-12 min-h-screen h-full bg-white md:bg-transparent flex flex-col flex-grow">
          <div className="hidden md:block mb-6 pt-6">
            <h2 className="text-xl text-center font-bold mb-4">Onboarding</h2>
            <Steps
              className={style['onboarding-steps']}
              current={state.currentStep - 1}
            >
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
          <div className={style['onboarding-steps__mobile']}>
            <div className={style['onboarding-steps__mobile-heading']}>
              <div>
                <p className="text-xs text-neutral-100 font-bold">
                  <span className="text-darkest">{state.currentStep}</span> of{' '}
                  {steps.length}
                </p>
                <h1 className="text-xl font-bold text-darkest leading-none">
                  {steps[state.currentStep - 1].title}
                </h1>
              </div>
              <div>
                {state.currentStep !== 4 && (
                  <button
                    className="text-primary p-2 font-semibold"
                    type="button"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
            <Progress
              className={style['progress-bar']}
              percent={progressPercentage}
              showInfo={false}
              strokeColor="#007FFF"
              trailColor="#EAEAEA"
            />
          </div>
          <div className="w-full mx-auto md:w-11/12 h-full flex flex-col flex-grow">
            {steps[state.currentStep - 1].content}
          </div>
          <div className="w-11/12 mx-auto text-right mt-10 pb-3 hidden md:block">
            <span className="text-xs text-neutral text-right">
              2021 © AM Insights
            </span>
          </div>
        </div>
      </div>
      {state.isShowWelcomeModal && <WelcomeModal />}
    </>
  );
};

const OnboardingWrapper = () => {
  return (
    <OnboardingProvider>
      <Onboarding />
    </OnboardingProvider>
  );
};

export default OnboardingWrapper;
