import cx from 'classnames';
import React from 'react';

import CustomProgress from '../Progress';
import style from './style.module.less';

type BoardListProps = {
  label: string;
  progressPercentage?: number;
  barColor?: string;
};

const BoardList: React.FCWithChild<BoardListProps> = ({
  label,
  progressPercentage,
  barColor,
}) => {
  return (
    <div className={cx(style['board-list'], 'flex')}>
      <div className={cx(style.label, 'w-5/12')}>
        <span>{label}</span>
      </div>
      <div className={cx(style.progress, 'w-5/12')}>
        <CustomProgress
          percent={progressPercentage}
          strokeColor={barColor}
          showInfo={false}
        />
      </div>
      <div className={cx(style.percentage, 'info w-2/12')}>
        <span>{progressPercentage}%</span>
      </div>
    </div>
  );
};

export default BoardList;
