import { Fund, Performance } from '@aminsights/contract';
import {
  calculatePerformanceTableBackgroundColor,
  CALENDAR_YEAR_PERFORMANCE_TO_SHOW,
  isFund,
  LIMIT_FUNDS_FOR_CHARTING,
  TotalReturnPeriod,
} from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import { roundOrEmpty } from '@/utils/roundOrEmpty';

import styles from '../style.module.less';

type WatchlistCalendarPerformanceColumnsProps = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  onSelectAll: (isChecked: boolean) => void;
};

export const WatchlistCalendarPerformanceColumns = ({
  rowSelectionState,
  onSelectAll,
}: WatchlistCalendarPerformanceColumnsProps): Array<IDataTableColumns> => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;

  const columnDefinitions: Array<IDataTableColumns> = [
    {
      title: 'Fund/Investment Trust',
      sortKey: 'fundName',
      idSubText: 'fundName',
      headerCheckbox: (): React.ReactNode => (
        <div className="relative -left-4" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
            className={styles['header-checkbox']}
          />
        </div>
      ),
      render: (item: Fund | Performance): React.ReactNode => {
        return isFund(item) ? (
          <div className="flex items-center">
            <div
              className="relative -left-4"
              onClick={e => e.stopPropagation()}
            >
              <Checkbox
                value={item.shareClassDetails.isin}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                checked={checkedRows.includes(item.shareClassDetails.isin)}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(item.shareClassDetails.isin)
                }
                className={styles['body-checkbox']}
              />
            </div>
            <div className="relative -left-3">
              <FundInvestmentTrustColumn
                data={{
                  id: item.shareClassDetails.isin,
                  name: item.shareClassDetails.code,
                  type: item.legalStructure,
                  assetClassCode: item.assetClassCode,
                  fundId: item.fundId,
                  legalStructure: item.legalStructure,
                }}
              />
            </div>
          </div>
        ) : (
          // empty ant-avatar-sm is just for to mock avatar
          <div className="flex flex-row">
            <div className="hidden mr-4 ant-avatar-sm sm:block" />
            <div className="font-bold table-cell-value text-darkest">
              {item.name}
            </div>
          </div>
        );
      },
      renderType: 'text',
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'YTD',
      idSubText: 'ytd',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturnYtdPct',
          render: (item: Fund | Performance): string => {
            const fundValue = item?.totalReturnYtdPct;
            const value = roundOrEmpty(fundValue, 1, '%');
            return value;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturnYtdMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? item?.calendarYearPerformancesPcl?.find(
                  a => a.type === TotalReturnPeriod.YTD,
                )?.value
              : item?.totalReturnYtdPct;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'calendarYearPerformancesPcl',
                  TotalReturnPeriod.YTD,
                )
              : '';
          },
        },
      ],
    },
    ...[1, 2, 3, 4, 5].map(yearsAgo => {
      const year = (new Date().getFullYear() - yearsAgo).toString();
      const keyReturn = `totalReturn${yearsAgo}calendarYearsAgoPct`;
      const keyPcl = `totalReturn${yearsAgo}calendarYearsAgoCategoryPcl`;
      const returnPeriod = getTotalReturnPeriod(yearsAgo);
      return {
        title: year,
        idSubText: year,
        renderType: 'custom' as const,
        align: 'center',
        children: [
          {
            title: 'Return',
            sortKey: keyReturn,
            render: (item: Fund | Performance): string => {
              const fundValue = isFund(item)
                ? item?.[keyReturn as keyof Fund]
                : item?.[keyReturn as keyof Performance];
              const value = roundOrEmpty(fundValue as any, 1, '%');
              return value;
            },
            renderType: 'number' as const,
            width: 85,
          },
          {
            title: 'Pcl',
            sortKey: keyPcl,
            render: (item: Fund | Performance): string => {
              const fundValue = isFund(item)
                ? item?.calendarYearPerformancesPcl?.find(
                    a => a.type === returnPeriod,
                  )?.value
                : item?.[keyPcl as keyof Performance];
              const value = roundOrEmpty(fundValue, 0);
              return value;
            },
            renderType: 'number' as const,
            width: 85,
            backgroundColor: (item: Fund) => {
              return isFund(item)
                ? calculatePerformanceTableBackgroundColor(
                    item,
                    'calendarYearPerformancesPcl',
                    CALENDAR_YEAR_PERFORMANCE_TO_SHOW[yearsAgo].type,
                  )
                : '';
            },
          },
        ],
      };
    }),
  ];

  function getTotalReturnPeriod(year: number) {
    let result = '';
    switch (year) {
      case 1:
        result = TotalReturnPeriod.ONE_YEAR;
        break;
      case 2:
        result = TotalReturnPeriod.TWO_YEARS;
        break;
      case 3:
        result = TotalReturnPeriod.THREE_YEARS;
        break;
      case 4:
        result = TotalReturnPeriod.FOUR_YEARS;
        break;
      case 5:
        result = TotalReturnPeriod.FIVE_YEARS;
        break;
    }
    return result;
  }

  return columnDefinitions;
};
