// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UqReRUFc3cwqM9rXpq8w {display: flex;height: 8rem;flex-direction: column;align-items: flex-start;justify-content: center;padding-bottom: 0.5rem\n}@media (min-width: 576px) {.UqReRUFc3cwqM9rXpq8w {height: auto;flex-direction: row;align-items: center;justify-content: space-between\n    }\n}@media (min-width: 992px) {.UqReRUFc3cwqM9rXpq8w {height: 8rem;flex-direction: column;align-items: flex-start;justify-content: center\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Dashboard/Charts/style.module.less"],"names":[],"mappings":"AACE,uBAAA,aAAA,CAAA,YAAA,CAAA,sBAAA,CAAA,uBAAA,CAAA,uBAAA,CAAA;AAAA,CACA,2BAAA,uBAAA,YAAA,CAAA,mBAAA,CAAA,mBAAA,CAAA;IAAA;AAAA,CACA,2BAAA,uBAAA,YAAA,CAAA,sBAAA,CAAA,uBAAA,CAAA;IAAA;AAAA","sourcesContent":[".container {\n  @apply flex flex-col justify-center pb-2 h-32 items-start;\n  @apply sm:h-auto sm:flex-row sm:justify-between sm:items-center;\n  @apply lg:items-start lg:flex-col lg:h-32 lg:justify-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UqReRUFc3cwqM9rXpq8w"
};
export default ___CSS_LOADER_EXPORT___;
