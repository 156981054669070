import { DashboardApi, SignificantChangesList } from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import {
  SC_DASHBOARD,
  SIGNIFICANT_CHANGES,
} from '../watchlist-hooks/query-keys';

type UseDashboardSignificantChangesResponse = SignificantChangesList;
type UseDashboardSignificantChangesError = unknown; // If API had error types place here
type UseDashboardSignificantChangesOptions = Omit<
  UseQueryOptions<
    UseDashboardSignificantChangesResponse,
    UseDashboardSignificantChangesError
  >,
  'queryKey' | 'queryFn' | 'initialData'
>;

const useDashboardSignificantChanges = (
  options?: UseDashboardSignificantChangesOptions,
) => {
  return useQuery<
    UseDashboardSignificantChangesResponse,
    UseDashboardSignificantChangesError
  >(
    [SIGNIFICANT_CHANGES, SC_DASHBOARD],
    async () => {
      const api = new DashboardApi(openApiConfig());
      const result = await api.getDashboardSignificantChanges();
      return result.data;
    },
    options,
  );
};

export default useDashboardSignificantChanges;
