export const buckets = [
  {
    label: 'UK Equity',
    value: 'uk-equity',
    count: 3,
  },
  {
    label: 'Europe Eq',
    value: 'europe-eq',
    count: 3,
  },
  {
    label: 'US Equity',
    value: 'us-equity',
    count: 3,
  },
  {
    label: 'Fixed Income',
    value: 'fixed-income',

    count: 3,
  },
  {
    label: 'Alternatives',
    value: 'alternatives',
    count: 3,
  },
];
