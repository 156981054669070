import {
  EMPTY_DATA_POINT,
  getFundOrInvestmentTrustTitle,
} from '@aminsights/shared';

import { IDataTablePortfolioColumns } from '../types';
import { IMarketCapData } from './type';

const getColumns = (
  isInvestmentTrust: boolean,
): IDataTablePortfolioColumns[] => [
  {
    title: 'Market Cap',
    render: (item: IMarketCapData) => {
      return <span className="font-medium text-neutral">{item.label}</span>;
    },
    renderType: 'text',
  },
  {
    title: getFundOrInvestmentTrustTitle(isInvestmentTrust),
    renderType: 'number',
    render: (item: IMarketCapData) => {
      return (
        <span className="text-neutral">
          {!isNaN(Number(item.value))
            ? `${(+item.value).toFixed(1)}%`
            : EMPTY_DATA_POINT}
        </span>
      );
    },
  },
];

export default getColumns;
