import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import DeleteModal from '@/partials/Modal/DeleteModal';

import style from './style.module.less';

interface Props {
  date: Dayjs;
  disabledDates: string[];
  onUpdateDate?: (oldDate: Dayjs, newDate: Dayjs) => boolean;
  onDeleteDate: (date: Dayjs) => Promise<void>;
  disableEidt?: boolean;
}

const PortfolioFundHeaderOptions: React.FCWithChild<Props> = ({
  date,
  onDeleteDate,
  disableEidt,
}) => {
  const [isMeatballVisible, setIsMeatballVisible] = useState(false);

  const containerRef = useRef(null);
  const [isDeleteItemModalOpen, setIsDeletePortfolioModalOpen] =
    useState(false);

  const divRef = useRef<HTMLDivElement>(null);

  const handleRemoveNewBucketModalOpen = () => {
    setIsDeletePortfolioModalOpen(prev => !prev);
  };

  const bucketMenu = (
    <Menu
      className="custom-dropdown-menu"
      items={[
        {
          label: <span style={{ color: '#e64236' }}>Delete</span>,
          key: 'removePortfolioColumn',
          onClick: () => handleRemoveNewBucketModalOpen(),
        },
      ]}
    />
  );

  useEffect(() => {
    if (divRef) {
      //We need to show the MeatBall on hover over the whole header cell
      const tableHeaderNode =
        divRef?.current?.parentNode?.parentNode?.parentNode;
      if (tableHeaderNode) {
        tableHeaderNode.addEventListener(
          'mouseover',
          () => date.isValid() && !disableEidt && setIsMeatballVisible(true),
        );
        tableHeaderNode.addEventListener(
          'mouseleave',
          () => date.isValid() && setIsMeatballVisible(false),
        );
      }
    }
  }, [divRef]);

  return (
    <div ref={divRef}>
      <Dropdown
        overlay={bucketMenu}
        className={cx('cursor-pointer', style['icon-meatball'])}
        placement="bottomLeft"
        trigger={['click']}
      >
        <MeatballMenu
          ref={containerRef}
          visibility={isMeatballVisible ? 'visible' : 'hidden'}
        />
      </Dropdown>
      <DeleteModal
        modalInfo={{
          title: 'Delete table column?',
          description: `This column will be permanently deleted.`,
          btnName: 'Delete',
          succesMessage: `Successfully deleted column`,
          errorMessage: `Column not deleted`,
        }}
        isVisible={isDeleteItemModalOpen}
        toggleModal={handleRemoveNewBucketModalOpen}
        onDeleteClick={() => onDeleteDate(date)}
      />
    </div>
  );
};

export default PortfolioFundHeaderOptions;
