import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT, shortenLongNumber } from '@aminsights/shared';
import getSymbolFromCurrency from 'currency-symbol-map';
import { FC } from 'react';

import useGetCurrencyRates from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';

export const FundSizeColumn: FC<{
  fund: Fund;
}> = ({ fund }) => {
  const { data: currencyData } = useGetCurrencyRates();

  return (
    <div className="text-sm text-neutral">
      {!fund ||
      !fund.fundSize ||
      !fund.fundSize?.value ||
      !currencyData?.currencyCode
        ? EMPTY_DATA_POINT
        : `${getSymbolFromCurrency(
            currencyData.currencyCode,
          )}${shortenLongNumber(fund.fundSize.value)}`}
    </div>
  );
};
