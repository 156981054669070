export enum BenchmarkType {
  MORNINGSTAR_CATEGORY = '5',
  PROSPECTUS_BENCHMARK = '6',
}

export enum MsTimePeriod {
  ONE_YR = 'M12',
  THREE_YRS = 'M36',
  FIVE_YRS = 'M60',
}

export enum RiskType {
  FUNDS = '61',
  INVESTMENT_TRUST = '81',
}
