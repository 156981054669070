import React, { useEffect, useState } from 'react';

import { Table } from '@/components/Table';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import style from './style.module.less';
import { columns, formTrustAndFundBasicData } from './utils';

const FundAndTrustBasic: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const [trustAndFundBasicData, setTrustAndFundBasicData] = useState<
    {
      type: string;
      info: string | number | string[] | void;
    }[]
  >([]);

  useEffect(() => {
    if (state?.fund) {
      setTrustAndFundBasicData(
        formTrustAndFundBasicData(state.fund, !!isInvestmentTrust),
      );
    }
  }, [state?.fund, isInvestmentTrust]);

  return (
    <div className="trust-and-fund-basic">
      <Table
        className={style['trust-and-fund-basic__table']}
        columns={columns}
        data={trustAndFundBasicData}
        paginateTotalCount={0}
        uniqueKey="type"
      />
    </div>
  );
};

export default FundAndTrustBasic;
