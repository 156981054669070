import { SignificantChangesApi } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { SIGNIFICANT_CHANGES } from '../watchlist-hooks/query-keys';

const useGetSignificantChangePerformance = (isin: string, date: string[]) => {
  const significantChangesApi = new SignificantChangesApi(openApiConfig());
  return useQuery([SIGNIFICANT_CHANGES, isin, date], async () => {
    const response =
      await significantChangesApi.getSignificantChangesPerformanceRipsData(
        isin,
        date,
      );
    return response.data;
  });
};

export default useGetSignificantChangePerformance;
