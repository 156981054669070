export const BondStatisticsTypeCodes = [
  {
    typeCode: '1-2',
    definition: 'AAA',
  },
  {
    typeCode: '3-5',
    definition: 'AA',
  },
  {
    typeCode: '6-8',
    definition: 'A',
  },
  {
    typeCode: '9-11',
    definition: 'BBB',
  },
  {
    typeCode: '12-14',
    definition: 'BB',
  },
  {
    typeCode: '15-17',
    definition: 'B',
  },
  {
    typeCode: '18+',
    definition: 'Below B',
  },
];
