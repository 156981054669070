import { FundsApi, ShareClassDetailsResponseData } from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

const useShareClassesByFundId = (
  fundId?: string,
  options?: Omit<
    UseQueryOptions<
      ShareClassDetailsResponseData[] | null | undefined,
      unknown
    >,
    'queryKey' | 'queryFn' | 'intialData'
  >,
) => {
  const fundApi = new FundsApi(openApiConfig());

  const query = useQuery<
    ShareClassDetailsResponseData[] | null | undefined,
    unknown
  >(
    ['shareclasses', fundId],
    async () => {
      if (fundId) {
        const result = await fundApi.getShareClassesByFundId(fundId);
        return result.data.data;
      }
      return null;
    },
    options,
  );
  return query;
};
export default useShareClassesByFundId;
