import { NotificationsApi } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { UNSEEN_NOTIFICATIONS } from './query-keys';

const useGetNotifications = () => {
  const notificationsApi = new NotificationsApi(openApiConfig());
  return useQuery([UNSEEN_NOTIFICATIONS], async () => {
    const response = await notificationsApi.countUnseenNotifications();
    return response.data.count;
  });
};

export default useGetNotifications;
