import { SEARCH_SC_ACTIONS, SearchSCActionTypes, SearchSCState } from './types';

export const searchSCReducer = (
  state: SearchSCState,
  action: SearchSCActionTypes,
): SearchSCState => {
  switch (action.type) {
    case SEARCH_SC_ACTIONS.SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload };
    case SEARCH_SC_ACTIONS.CLEAR:
      return { ...state, searchTerm: undefined };
    default:
      return state;
  }
};
