import { SignificantChangesItem } from '@aminsights/contract';
import { EMPTY_DATA_POINT, getChangeTypeLabel } from '@aminsights/shared';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';

import GetChangeColumn from './GetChangeColumn';
import SignificantChangesColumn from './SignificantChangesFirstColumn';

const significantChangesColumns: IDataTableColumns[] = [
  {
    title: 'Fund/Investment Trust',
    sortKey: 'fundName',
    render: (item: SignificantChangesItem): React.ReactNode => (
      <SignificantChangesColumn data={item} />
    ),
    renderType: 'text',
    isColumnFixed: true,
    loader: (
      <Skeleton
        avatar={{ size: 'small' }}
        title={false}
        paragraph={{ rows: 2 }}
        active
      />
    ),
  },
  {
    title: 'Type',
    render: (item: SignificantChangesItem): React.ReactElement => {
      const changeType = getChangeTypeLabel(item.changeType);

      return (
        <div className="text-sm text-neutral">
          {!item.changeType ? EMPTY_DATA_POINT : `${changeType}`}
        </div>
      );
    },
    renderType: 'text',
    sortKey: 'changeType',
  },
  {
    title: 'Change',
    render: (item: SignificantChangesItem): React.ReactElement => {
      return (
        <div className="text-sm">
          <GetChangeColumn data={item} length={35} />
        </div>
      );
    },
    renderType: 'text',
    sortKey: 'pctChange',
    width: 300,
  },
  {
    title: 'Date',
    render: (item: SignificantChangesItem): React.ReactElement => {
      return (
        <div className="text-sm text-neutral">
          {!item.updatedDate
            ? EMPTY_DATA_POINT
            : `${dayjs(item.updatedDate).format(DISPLAY_DATE_FORMAT) ?? ''}`}
        </div>
      );
    },
    renderType: 'text',
    sortKey: 'updatedDate',
    width: 200,
  },
];

export default significantChangesColumns;
