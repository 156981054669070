import { SignificantChangesItem } from '@aminsights/contract';
import { APP_ROUTE_SIGNIFICANT_CHANGES } from '@aminsights/shared';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { BREAKPOINTS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import useDashboardSignificantChanges from '@/hooks/query-hooks/dashboard-hooks/useDashboardSignificantChanges';
import useGetSignificantChangesThreshold from '@/hooks/query-hooks/significant-changes-hooks/useGetSignificantChangesThreshold';
import SignificantChangesEmptyState from '@/partials/SignificantChanges/SignificantChangesEmptyState';

import SignificantChangesTable from './table';

export enum ViewMode {
  mobile = 'mobile',
  desktop = 'desktop',
}

const EMPTY_SC_MESSAGE = 'There have been no changes on funds yet';

const SignificantChangesWidget: React.FCWithChild<{
  mode: ViewMode;
  onSignificantChangeClicked: (data: SignificantChangesItem) => void;
}> = ({ mode, onSignificantChangeClicked }) => {
  const { data: significantChangesThreshold } =
    useGetSignificantChangesThreshold();

  const { app } = useAppContext();

  const { data, isLoading } = useDashboardSignificantChanges();
  const significantChangesState = data;

  const handleOnRow = (id: string) => {
    const data = significantChangesState?.data.find(sg => sg._id === id);
    if (data) {
      onSignificantChangeClicked(data);
    }
  };

  const desktopMode =
    (app?.windowWidth || 0) >= BREAKPOINTS.MD && mode === ViewMode.desktop;
  const mobileMode =
    (app?.windowWidth || 0) < BREAKPOINTS.MD && mode === ViewMode.mobile;

  return (
    <div data-test-id="dashboardSignificantChangesWidget">
      {desktopMode ? (
        <div className="section-dashboard__card mb-6">
          <div className="flex justify-between p-4">
            <h5>
              <span className="mr-5">Significant Changes</span>{' '}
            </h5>
            {!!significantChangesState?.data?.length && (
              <Button
                size="small"
                type="link"
                className="font-semibold text-[#1890ff] text-[#40a9ff]:hover p-0"
              >
                <Link to={APP_ROUTE_SIGNIFICANT_CHANGES}>View All</Link>
              </Button>
            )}
          </div>
          <SignificantChangesTable
            loading={isLoading}
            loaderSize={3}
            data={significantChangesState?.data ?? []}
            emptyState={
              <SignificantChangesEmptyState
                customMessage={EMPTY_SC_MESSAGE}
                noThresholds={!significantChangesThreshold}
                className="md:py-10"
              />
            }
            onRow={handleOnRow}
          />
        </div>
      ) : (
        mobileMode && (
          <>
            <SignificantChangesTable
              loading={isLoading}
              loaderSize={3}
              data={significantChangesState?.data ?? []}
              emptyState={
                <SignificantChangesEmptyState
                  customMessage={EMPTY_SC_MESSAGE}
                  noThresholds={!significantChangesThreshold}
                  className="py-10 md:py-10"
                />
              }
              onRow={handleOnRow}
            />
            {!!significantChangesState?.data?.length && (
              <Link to={APP_ROUTE_SIGNIFICANT_CHANGES}>
                <button
                  className="border-t border-l-0 border-r-0 border-b-0 border-solid border-grey-light-50 text-center bg-white w-full"
                  type="button"
                >
                  <p className="py-6 font-sm text-primary font-semibold">
                    View All
                  </p>
                </button>
              </Link>
            )}
          </>
        )
      )}
    </div>
  );
};

export default SignificantChangesWidget;
