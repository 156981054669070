import { PortfolioFund } from '@aminsights/contract';
import { buildFundDetailsPath, EMPTY_DATA_POINT } from '@aminsights/shared';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';

import { IDataTableColumns } from '@/components/Table/DataTable';

const PerformanceColumns = (): IDataTableColumns[] => [
  {
    title: 'Fund/Investment Trust',
    sortKey: 'fundName',
    render: (item: PortfolioFund) =>
      !!item.isin && !!item.fundName ? (
        <Link
          onClick={e => e.preventDefault()}
          to={buildFundDetailsPath(item.isin)}
        >
          {item.fundName}
        </Link>
      ) : (
        EMPTY_DATA_POINT
      ),
    renderType: 'text',
    isColumnFixed: true,
    loader: (
      <Skeleton
        avatar={{ size: 'small' }}
        title={false}
        paragraph={{ rows: 2 }}
        active
      />
    ),
  },
  {
    title: 'YTD',
    sortKey: 'totalReturnYtdPct',
    render: (item: PortfolioFund): string => {
      return item?.totalReturnYtdPct
        ? `${Number(item?.totalReturnYtdPct).toFixed(1)}%`
        : EMPTY_DATA_POINT;
    },
    renderType: 'number',
    width: 85,
  },
  {
    title: '3 mos',
    sortKey: 'totalReturn3MonthPct',
    render: (item: PortfolioFund): string => {
      return item?.totalReturn3MonthPct
        ? `${Number(item?.totalReturn3MonthPct).toFixed(1)}%`
        : EMPTY_DATA_POINT;
    },
    renderType: 'number',
    width: 85,
  },
  {
    title: '6 mos',
    sortKey: 'totalReturn6MonthPct',
    render: (item: PortfolioFund): string => {
      return item?.totalReturn6MonthPct
        ? `${Number(item?.totalReturn6MonthPct).toFixed(1)}%`
        : EMPTY_DATA_POINT;
    },
    renderType: 'number',
    width: 85,
  },
  {
    title: '1 yr',
    sortKey: 'totalReturn1YearPct',
    render: (item: PortfolioFund): string => {
      return item?.totalReturn1YearPct
        ? `${Number(item?.totalReturn1YearPct).toFixed(1)}%`
        : EMPTY_DATA_POINT;
    },
    renderType: 'number',
    width: 85,
  },
  {
    title: '3 yrs',
    sortKey: 'totalReturn3YearPct',
    render: (item: PortfolioFund): string => {
      return item?.totalReturn3YearPct
        ? `${Number(item?.totalReturn3YearPct).toFixed(1)}%`
        : EMPTY_DATA_POINT;
    },
    renderType: 'number',
    width: 85,
  },
  {
    title: '5 yrs',
    sortKey: 'totalReturn5YearPct',
    render: (item: PortfolioFund): string => {
      return item?.totalReturn5YearPct
        ? `${Number(item?.totalReturn5YearPct).toFixed(1)}%`
        : EMPTY_DATA_POINT;
    },
    renderType: 'number',
    width: 85,
  },
];

export default PerformanceColumns;
