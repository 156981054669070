import { PortfolioBenchmarkSettingTypeEnum } from '@aminsights/contract';

import { UpsertPortfolioFields, UpsertPortfolioFormFields } from './type';

export const transformPortfolioPayload = (
  values: UpsertPortfolioFormFields,
): UpsertPortfolioFields => {
  const { name, benchmarkSetting } = values;

  return {
    name: name,
    benchmarkSetting: {
      type: benchmarkSetting.type,
      secId:
        benchmarkSetting.type === PortfolioBenchmarkSettingTypeEnum.Regular
          ? benchmarkSetting.id
          : undefined,
      compositeBenchmarkId:
        benchmarkSetting.type === PortfolioBenchmarkSettingTypeEnum.Composite
          ? benchmarkSetting.id
          : undefined,
    },
  };
};
