import { PortfolioPlatforms, PortfoliosApi } from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

export const GET_PORTFOLIO_PLATFORMS = '/portfolio/platforms';

type GetPortfolioPlatformsParams = Parameters<
  PortfoliosApi['getPortfolioPlatforms']
>;

const usePortfolioPlatformsQuery = (
  params?: GetPortfolioPlatformsParams,
  options?: Omit<UseQueryOptions<PortfolioPlatforms>, 'queryKey' | 'queryFn'>,
) => {
  const portfolioApi = new PortfoliosApi(openApiConfig());
  return useQuery<PortfolioPlatforms>(
    [GET_PORTFOLIO_PLATFORMS, params],
    async () => {
      const response = await portfolioApi.getPortfolioPlatforms(
        params?.[0] || '',
      );

      return response.data;
    },
    options,
  );
};

export default usePortfolioPlatformsQuery;
