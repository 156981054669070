import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared';
import { Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';

import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import Button from '@/components/Button';
import ElementWithDataTestId from '@/components/ElementWithDataTestId';
import { STATIC_DATA_ADD_PORTFOLIO_MODAL } from '@/constants/modals-static-data';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { UpsertPortfolioFields } from '@/partials/Forms/Portfolio/type';
import AddPortfolioModal from '@/partials/Modal/AddPortfolioModal';
import PortfolioRouter from '@/router/Portfolio';
import PortfolioSubRouter from '@/router/PortfolioSub';
import { generatePaths, PORTFOLIO_SUB_ROUTES } from '@/router/routes';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';

import BasePageWithMetadata from '../../BasePageWithMetadata';
import Loader from '../../FundAndInvestmentTrust/components/Loader';
import { useProvidePortfolio } from '../useProvidePortfolio';
import { PortfoliosScrollTab } from './components';
import NoPortfolioPage from './NoPortfolioPage';
import style from './style.module.less';

export const PortfolioComponent = () => {
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const { isPortfolioImportTreatmentEnabled } = featureSwitchState;
  const history = useHistory();
  const { portfolioId, tab } = useParams<{
    portfolioId: string;
    tab: string;
  }>();

  const { state, setPortfolio, unmarkAllFundsForDelete, createNewPortfolio } =
    useProvidePortfolio();

  const [portfolioName, setPortfolioName] = useState<string>('');

  useEffect(() => {
    if (
      !!portfolioId &&
      state.portfolios.length !== 0 &&
      portfolioId !== state.currentPortfolioId
    ) {
      const currentPortfolio =
        state?.portfolios?.find(data => data._id === portfolioId) ??
        state?.portfolios[0];
      setPortfolio(currentPortfolio._id);
    }
    if (portfolioId) {
      const currentPortfolio =
        state?.portfolios?.find(data => data._id === portfolioId) ??
        state?.portfolios[0];
      setPortfolioName(currentPortfolio?.name);
    }
  }, [portfolioId, state.portfolios.length]);

  useEffect(() => {
    if (state.markedFundsForDelete.length !== 0) {
      unmarkAllFundsForDelete();
    }
  }, [portfolioId, tab]);
  const [isAddNewPortfolioModalOpen, setIsAddNewPortfolioModalOpen] =
    useState(false);

  const handleAddNewPortfolioModalOpen = () => {
    setIsAddNewPortfolioModalOpen(prev => !prev);
  };

  const onSaveClick = (payload: UpsertPortfolioFields) => {
    return createNewPortfolio({ ...payload, funds: [] });
  };

  const isEmpty = !state.arePortfoliosLoading && state.portfolios.length === 0;

  const portfolioMenu = (
    <Menu
      items={[
        {
          label: <ElementWithDataTestId label="Import portfolio" />,
          key: 'ImportPortfolio',
          onClick: () =>
            history.push(`/${APP_ROUTE_PORTFOLIOS}/import-portfolio`),
        },
      ]}
    />
  );

  return (
    <BasePageWithMetadata
      title={`Portfolio${tab ? ` - ${capitalizeFirstLetter(tab)}` : ''}${
        portfolioName ? ` - ${portfolioName}` : ''
      }`}
    >
      <div className={style['section-portfolio-heading']}>
        <h2 className="text-xl font-bold" data-test-id={'portfolio'}>
          Portfolio
        </h2>
      </div>
      {!isEmpty ? (
        <>
          <div className={style['section-portfolio-tabs']}>
            <div className={style['bucket-tab-wrapper']}>
              <Loader
                loading={state.arePortfoliosLoading}
                component={<PortfoliosScrollTab />}
              />
            </div>
            <div className={style['bucket-btn-wrapper']}>
              <Button
                className={style['portfolio-btn']}
                type="primary"
                onClick={() => {
                  setIsAddNewPortfolioModalOpen(true);
                }}
                data-test-id="portfolioAddButton"
              >
                <AddIcon className="icon text-md" />
                <p className="hidden ml-2 text-sm font-medium md:block">
                  Add Portfolio
                </p>
              </Button>
              {isPortfolioImportTreatmentEnabled && (
                <Dropdown
                  data-test-id="portfolioAddButton"
                  className={style['icon-meatball']}
                  overlay={portfolioMenu}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <MeatballMenu data-test-id="portfolioMeatballMenu" />
                </Dropdown>
              )}
            </div>
          </div>
          <div className={style['section-portfolio-inner']}>
            <PortfolioSubRouter />
          </div>
        </>
      ) : (
        <NoPortfolioPage
          setIsAddNewPortfolioModalOpen={setIsAddNewPortfolioModalOpen}
        />
      )}
      <AddPortfolioModal
        entities={state.portfolios}
        isVisible={isAddNewPortfolioModalOpen}
        toggleModal={handleAddNewPortfolioModalOpen}
        onSaveClick={onSaveClick}
        staticModalData={STATIC_DATA_ADD_PORTFOLIO_MODAL}
      />
    </BasePageWithMetadata>
  );
};

const Portfolio: React.FCWithChild = () => {
  const { fetchPortfolios } = useProvidePortfolio();

  useEffect(() => {
    fetchPortfolios();
  }, []);

  return (
    <section className={style['section-portfolio']}>
      <Switch>
        <Route
          path={[
            ...generatePaths(PORTFOLIO_SUB_ROUTES, APP_ROUTE_PORTFOLIOS),
          ].map(p => {
            return `/${p}`;
          })}
        >
          <PortfolioComponent />
        </Route>
        <PortfolioRouter />
      </Switch>
    </section>
  );
};

export default Portfolio;
