// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VLaeeL7pmCtdy_J6IjMx .lutyvmn78Jmfe1I4ts0l > tr > td {\n  min-height: 56px;\n}\n.aTk4ggw5QIUdiQn3KY7B .ant-table-thead {\n  --tw-bg-opacity: 1;\n  background-color: rgb(247 247 247 / var(--tw-bg-opacity));\n}\n.aTk4ggw5QIUdiQn3KY7B .ant-table-cell:first-child {\n  font-weight: 700;\n  --tw-text-opacity: 1;\n  color: rgb(49 51 65 / var(--tw-text-opacity));\n}\n.aTk4ggw5QIUdiQn3KY7B .ant-table-thead .ant-table-cell {\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  font-weight: 400;\n  --tw-text-opacity: 1;\n  color: rgb(84 85 118 / var(--tw-text-opacity));\n  background-color: #f7f7f7;\n}\n.aTk4ggw5QIUdiQn3KY7B .ant-table-thead tr {\n  border-width: 0px;\n}\n.aTk4ggw5QIUdiQn3KY7B .ant-table-thead .ant-table-cell:first-child::before {\n  display: none;\n}\n.aTk4ggw5QIUdiQn3KY7B .ant-table-cell span {\n  margin-left: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/FundAndInvestmentTrust/components/InfoAndStats/NameList/style.module.less"],"names":[],"mappings":"AAEA;EAEI,gBAAA;AAFJ;AAMM;EAAA,kBAAA;EAAA;AAAA;AAGA;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;AAIA;EAAA,mBAAA;EAAA,sBAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA,8CAAA;EACA;AADA;AAKA;EAAA;AAAA;AAIA;EAAA;AAAA;AAlBJ;EAsBI,iBAAA;AAPN","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.name-list {\n  .ant-table-tbody > tr > td {\n    min-height: 56px;\n  }\n  &__table :global {\n    .ant-table-thead {\n      @apply bg-grey-lighter;\n    }\n    .ant-table-cell:first-child {\n      @apply font-bold text-darkest;\n    }\n\n    .ant-table-thead .ant-table-cell {\n      @apply text-xs font-normal text-neutral py-2;\n      background-color: @bg;\n    }\n\n    .ant-table-thead tr {\n      @apply border-0;\n    }\n\n    .ant-table-thead .ant-table-cell:first-child::before {\n      @apply hidden;\n    }\n\n    .ant-table-cell span {\n      margin-left: 30px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name-list": "VLaeeL7pmCtdy_J6IjMx",
	"ant-table-tbody": "lutyvmn78Jmfe1I4ts0l",
	"name-list__table": "aTk4ggw5QIUdiQn3KY7B"
};
export default ___CSS_LOADER_EXPORT___;
