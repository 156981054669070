export const adjustMarksToAvoidDuplicates = (
  values: number | number[],
  marks: Record<number, string>,
) => {
  if (!Array.isArray(values)) {
    return [values];
  }

  const [min, max] = values;
  if (min === max) {
    const marksArray = Object.keys(marks).map(Number);
    const nearestMin = marksArray.reduce((a, b) => (b < min ? b : a));
    const nearestMax = marksArray.reduce((a, b) => (b > max ? b : a));
    values = [nearestMin, nearestMax];
  }

  return [values[0], values[1]];
};
