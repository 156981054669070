import { EquityStatsDetail } from '@aminsights/contract';
import { EMPTY_DATA_POINT, EquityStatsLabel } from '@aminsights/shared';
import { TableColumnType } from 'antd';
import React from 'react';

import { Table } from '@/components/Table';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import style from './style.module.less';

interface ColumnType {
  info: string;
  type: string;
}

const columns: TableColumnType<ColumnType>[] = [
  {
    dataIndex: 'type',
    title: 'Type',
  },
  {
    dataIndex: 'info',
    title: 'Info',
    align: 'right',
  },
];

const getTableData = (data?: EquityStatsDetail): ColumnType[] => {
  const compute = (value?: number) => {
    return value ? `${value.toFixed(1)}%` : EMPTY_DATA_POINT;
  };

  return [
    {
      type: EquityStatsLabel.THREE_YEAR_EARNINGS_GROWTH,
      info: compute(data?.past3YearEarningsGrowth),
    },
    {
      type: EquityStatsLabel.ROE,
      info: compute(data?.roe),
    },
    {
      type: EquityStatsLabel.ROIC,
      info: compute(data?.roic),
    },
    {
      type: EquityStatsLabel.NET_MARGIN,
      info: compute(data?.netMargin),
    },
  ];
};

const EquityStats: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();

  return (
    <Table
      columns={columns}
      data={getTableData(state?.fund?.equityStats)}
      paginateTotalCount={0}
      uniqueKey="type"
      className={style.table}
    />
  );
};

export default EquityStats;
