import {
  APP_ROUTE_ANNOUNCEMENTS,
  APP_ROUTE_CHARTS,
  APP_ROUTE_DASHBOARD,
  APP_ROUTE_EXPLORE,
  APP_ROUTE_FUND,
  APP_ROUTE_PORTFOLIOS,
  APP_ROUTE_SETTINGS,
  APP_ROUTE_SIGNIFICANT_CHANGES,
  APP_ROUTE_WATCHLIST,
  WATCHLIST_TAB_KEY,
} from '@aminsights/shared';
import React from 'react';

import AllAnnouncements from '@/pages/app/Announcements/All';
import MyListAnnouncements from '@/pages/app/Announcements/MyList';
import AbsoluteChartingToolPage from '@/pages/app/Charts/components/Absolute';
import PremiumDiscountChartingToolPage from '@/pages/app/Charts/components/PremiumDiscount';
import RelativeChartingToolPage from '@/pages/app/Charts/components/Relative';
import RiskReturnChartingToolPage from '@/pages/app/Charts/components/RiskReturn';
import { FundDetailTabs } from '@/pages/app/FundAndInvestmentTrust/components/FundDetailTabs';
import ImportPortfolio from '@/pages/app/Portfolio/components/ImportPortfolio';
import PortfolioPagesWrapper from '@/pages/app/Portfolio/Portfolio/PortfolioPagesWrapper';
import CompositeBenchmarks from '@/pages/app/Settings/CompositeBenchmarks';
import NotificationSettings from '@/pages/app/Settings/Notifications';
import ProfileSettings from '@/pages/app/Settings/Profile';
import SecuritySettings from '@/pages/app/Settings/Security';
import SignificantChangesSettings from '@/pages/app/Settings/SignificantChanges';
import AllSignificantChanges from '@/pages/app/SignificantChanges/All';
import Buckets from '@/pages/app/Watchlist/Buckets';
import ImportFunds from '@/pages/app/Watchlist/Buckets/components/ImportFunds';
import WatchlistTabWrapper from '@/pages/app/Watchlist/Watchlist/WatchlistTabWrapper';
import ChangeBucketOrder from '@/partials/ManageBuckets/ChangeBucketOrder';

export interface IRoute {
  path: string;
  component?: React.ComponentType;
}

export const generatePaths = (routes: IRoute[], root: string) =>
  routes.map(r => (r.path ? `${root}/${r.path}` : root));

export const SETTINGS_ROUTES: IRoute[] = [
  {
    path: 'profile',
    component: ProfileSettings,
  },
  {
    path: 'security',
    component: SecuritySettings,
  },
  {
    path: 'notifications',
    component: NotificationSettings,
  },
  {
    path: 'significant-changes',
    component: SignificantChangesSettings,
  },
  {
    path: 'custom-benchmarks',
    component: CompositeBenchmarks,
  },
];

export enum EChartsRoutes {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
  RISK_RETURN = 'risk-return',
  PREMIUM_DISCOUNT = 'premium-discount',
}

export const CHARTS_ROUTES: IRoute[] = [
  {
    path: EChartsRoutes.ABSOLUTE,
    component: AbsoluteChartingToolPage,
  },
  {
    path: EChartsRoutes.RELATIVE,
    component: RelativeChartingToolPage,
  },
  {
    path: EChartsRoutes.RISK_RETURN,
    component: RiskReturnChartingToolPage,
  },
  {
    path: EChartsRoutes.PREMIUM_DISCOUNT,
    component: PremiumDiscountChartingToolPage,
  },
];

export const ANNOUNCEMENT_ROUTES: IRoute[] = [
  {
    path: 'my-list',
    component: MyListAnnouncements,
  },
  {
    path: 'all',
    component: AllAnnouncements,
  },
];

export const SIGNIFICANT_CHANGES_ROUTES: IRoute[] = [
  {
    path: 'bucket/:slug?',
    component: AllSignificantChanges,
  },
];

export const WATCHLIST_ROUTES: IRoute[] = [
  {
    path: 'manage-buckets',
    component: Buckets,
  },
  {
    path: 'import-funds',
    component: ImportFunds,
  },
  {
    path: 'change-bucket-order',
    component: ChangeBucketOrder,
  },
];

export const WATCHLIST_SUB_ROUTES: IRoute[] = [
  {
    path: 'bucket/:slug?',
    component: WatchlistTabWrapper,
  },
];

export const PORTFOLIO_ROUTES: IRoute[] = [
  {
    path: 'import-portfolio',
    component: ImportPortfolio,
  },
];

export const PORTFOLIO_SUB_ROUTES: IRoute[] = [
  {
    path: ':portfolioId/:tab',
    component: PortfolioPagesWrapper,
  },
];

export const FUND_SUB_ROUTES: IRoute[] = [
  {
    path: ':id/details/:tab',
    component: FundDetailTabs,
  },
  { path: ':id/details', component: FundDetailTabs },
  { path: ':id/', component: FundDetailTabs },
];

export const EXPLORE_SUB_ROUTES: IRoute[] = [
  WATCHLIST_TAB_KEY.ESG,
  WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE,
  WATCHLIST_TAB_KEY.CALENDAR_YEAR_PERFORMANCE,
  WATCHLIST_TAB_KEY.GENDER_PAY_GAP,
  WATCHLIST_TAB_KEY.INCOME,
  WATCHLIST_TAB_KEY.SUMMARY,
  WATCHLIST_TAB_KEY.RISK,
].map(i => ({ path: i }));

export const PATHS_WITH_LAYOUT = [
  'playground',
  APP_ROUTE_DASHBOARD,
  `${APP_ROUTE_FUND}/compare`,
  APP_ROUTE_FUND,
  APP_ROUTE_EXPLORE,
  APP_ROUTE_ANNOUNCEMENTS,
  APP_ROUTE_SIGNIFICANT_CHANGES,
  APP_ROUTE_SETTINGS,
  APP_ROUTE_WATCHLIST,
  APP_ROUTE_PORTFOLIOS,
  APP_ROUTE_CHARTS,
  ...generatePaths(CHARTS_ROUTES, APP_ROUTE_CHARTS),
  ...generatePaths(FUND_SUB_ROUTES, APP_ROUTE_FUND),
  ...generatePaths(ANNOUNCEMENT_ROUTES, APP_ROUTE_ANNOUNCEMENTS),
  ...generatePaths(SETTINGS_ROUTES, APP_ROUTE_SETTINGS),
  ...generatePaths(SIGNIFICANT_CHANGES_ROUTES, APP_ROUTE_SIGNIFICANT_CHANGES),
  ...generatePaths(WATCHLIST_ROUTES, APP_ROUTE_WATCHLIST),
  ...generatePaths(WATCHLIST_SUB_ROUTES, APP_ROUTE_WATCHLIST),
  ...generatePaths(PORTFOLIO_SUB_ROUTES, APP_ROUTE_PORTFOLIOS),
  ...generatePaths(PORTFOLIO_ROUTES, APP_ROUTE_PORTFOLIOS),
  ...generatePaths(EXPLORE_SUB_ROUTES, APP_ROUTE_EXPLORE),
].map(p => `/${p}`);
