import { Material } from '@aminsights/contract';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Divider, Tooltip } from 'antd';
import { FC, useContext, useMemo, useState } from 'react';

import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import DeleteModal from '@/partials/Modal/DeleteModal';
import { validatePermissions } from '@/utils/auth';

interface MaterialListItemProps {
  material: Material;
  onEditMaterial: (material: Material) => void;
  onDeleteMaterial: (material: Material) => void;
}

const MaterialListItem: FC<MaterialListItemProps> = ({
  material,
  onEditMaterial,
  onDeleteMaterial,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;

  const handleToggleDeleteModal = () => {
    setIsDeleteModalOpen(prev => !prev);
  };

  const handleOpenEditModal = () => {
    onEditMaterial(material);
    setIsTooltipOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setIsTooltipOpen(false);
    setIsDeleteModalOpen(true);
  };

  const hasAdminPermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.materials.admin],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const hasWritePermissions = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.materials.write],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const showWriteActions =
    hasAdminPermissions ||
    (hasWritePermissions && material.createdBy === currentUser?.sub);

  return (
    <div className="relative">
      <DeleteModal
        modalInfo={{
          title: 'Delete link?',
          description: 'This action cannot be undone.',
          btnName: 'Delete',
          succesMessage: `Link has been deleted`,
          errorMessage: `Unable to delete link`,
        }}
        width={560}
        isVisible={isDeleteModalOpen}
        toggleModal={handleToggleDeleteModal}
        onDeleteClick={async () => {
          await onDeleteMaterial(material);
        }}
      />

      <Tooltip
        title={
          <div className="flex items-center gap-x-2 w-[480px]">
            <a
              className="text-sm hover:underline hover:text-white w-40 md:w-72 truncate my-1.5"
              href={material.link}
              target="_blank"
              rel="noreferrer"
            >
              {material.link}
            </a>
            {showWriteActions && (
              <div>
                <Divider type="vertical" className="border-white h-5" />
                <Button
                  type="text"
                  className="text-white px-1 md:px-2 hover:text-white"
                  onClick={handleOpenEditModal}
                >
                  Edit
                </Button>
                <Button
                  type="text"
                  className="text-white px-1 md:px-2 hover:text-white"
                  onClick={handleOpenDeleteModal}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        }
        open={isTooltipOpen}
        trigger="click"
        placement="bottomLeft"
        color="transparent"
        showArrow={false}
        overlayClassName="bg-neutral-200 text-white h-12 p-1 md:px-3 rounded-lg"
        overlayStyle={{ maxWidth: '480px' }}
        getPopupContainer={triggerNode =>
          triggerNode.parentElement as HTMLElement
        }
        onOpenChange={visible => {
          if (visible !== isTooltipOpen) setIsTooltipOpen(visible);
        }}
      >
        <Button
          type="link"
          className="p-0 text-link font-normal border-none shadow-none"
          onClick={() => setIsTooltipOpen(true)}
        >
          {material.text}
        </Button>
      </Tooltip>
    </div>
  );
};

export default MaterialListItem;
