import { SignificantChangesItem } from '@aminsights/contract';

import { ChangeTypeEnum } from '../constants';

export const formatTotalChange = (
  significantChangesData: SignificantChangesItem,
) => {
  let result: string;
  if (!significantChangesData.pctChangeAbsolute) {
    return '0';
  }
  switch (significantChangesData.changeType) {
    case ChangeTypeEnum.Ocf:
    case ChangeTypeEnum.PremiumDiscount:
    case ChangeTypeEnum.Yield:
      result = significantChangesData.pctChangeAbsolute.toFixed(1);
      break;
    default:
      result = Math.round(
        significantChangesData.pctChangeAbsolute || 0,
      ).toString();
      break;
  }
  return result;
};
