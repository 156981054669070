import { APP_ROUTE_WATCHLIST } from '@aminsights/shared';
import React from 'react';

import { SubRouter } from './index';
import { WATCHLIST_ROUTES } from './routes';

const WatchlistRouter: React.FCWithChild = () => (
  <SubRouter routes={WATCHLIST_ROUTES} rootPath={APP_ROUTE_WATCHLIST} />
);

export default WatchlistRouter;
