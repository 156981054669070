import { FundsApi, Investor } from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

type UseInvestorsByIsinResponse = Investor[];
type UseInvestorsByIsinError = unknown; // If API had error types place here
type UseInvestorsByIsinOptions = Omit<
  UseQueryOptions<UseInvestorsByIsinResponse, UseInvestorsByIsinError>,
  'queryKey' | 'queryFn' | 'initialData'
>; // Probably won't be used often but its what makes react-query so easy to tweak as needed

export const useInvestorsByShareClasses = (
  isins: string[],
  options?: UseInvestorsByIsinOptions,
) => {
  return useQuery<UseInvestorsByIsinResponse, UseInvestorsByIsinError>(
    ['investors', isins],
    async () => {
      const fundApi = new FundsApi(openApiConfig());

      if (isins.length !== 0) {
        const result = await fundApi.getInvestorsForShareClasses(isins);
        return result.data;
      }
      console.error(
        'Incomming isins array is empty for useInvestorsByShareClasses',
      );
      return [];
    },
    options,
  );
};
