import { showSelectedCachedKey } from './showSelectedCachedValue';

export const updateRangeAfterSubmit = (
  range: (number | string)[],
  setRange: (range: number[]) => void,
  setChange?: (change: boolean) => void,
  marks?: Record<number, string>,
) => {
  if (range && range.length > 0) {
    setChange && setChange(true);
    if (marks) {
      setRange([
        showSelectedCachedKey(range, marks).from || 0,
        showSelectedCachedKey(range, marks).to || 0,
      ]);
    } else {
      const numberArray: number[] = range.map(value =>
        typeof value === 'string' ? parseFloat(value) : value,
      );

      setRange(numberArray);
    }
  }
  return undefined;
};
