// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MwyhARsHTyqsOAn0AgBe .ant-tabs-tab,\n.MwyhARsHTyqsOAn0AgBe .ant-tabs-tab-active {\n  font-size: 0.875rem;\n  line-height: 1rem;\n  --tw-text-opacity: 1;\n  color: rgb(84 85 118 / var(--tw-text-opacity));\n}\n.MwyhARsHTyqsOAn0AgBe .ant-tabs-tab:nth-last-child(2) {\n  margin-right: 40px;\n}\n.MwyhARsHTyqsOAn0AgBe .ant-tabs-tab-active {\n  font-weight: 600;\n}\n.MwyhARsHTyqsOAn0AgBe .ant-tabs-ink-bar {\n  --tw-bg-opacity: 1 !important;\n  background-color: rgb(0 127 255 / var(--tw-bg-opacity)) !important;\n  height: 3px !important;\n}\n.MwyhARsHTyqsOAn0AgBe .ant-tabs-top > .ant-tabs-nav,\n.MwyhARsHTyqsOAn0AgBe .ant-tabs-bottom > .ant-tabs-nav,\n.MwyhARsHTyqsOAn0AgBe .ant-tabs-top > div > .ant-tabs-nav,\n.MwyhARsHTyqsOAn0AgBe .ant-tabs-bottom > div > .ant-tabs-nav {\n  margin-bottom: 0px;\n  height: 2.875rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Tabs/style.module.less"],"names":[],"mappings":"AAIM;;EAAA,mBAAA;EAAA,iBAAA;EAAA,oBAAA;EAAA;AAAA;AAJN;EAOM,kBAAA;AADN;AAIM;EAAA;AAAA;AAGA;EAAA,6BAAA;EAAA,kEAAA;EACA;AADA;AAOA;;;;EAAA,kBAAA;EAAA;AAAA","sourcesContent":[".tabs-wrapper {\n  :global {\n    .ant-tabs-tab,\n    .ant-tabs-tab-active {\n      @apply text-sm text-neutral leading-4;\n    }\n    .ant-tabs-tab:nth-last-child(2) {\n      margin-right: 40px;\n    }\n    .ant-tabs-tab-active {\n      @apply font-semibold;\n    }\n    .ant-tabs-ink-bar {\n      @apply bg-primary !important;\n      height: 3px !important;\n    }\n    .ant-tabs-top > .ant-tabs-nav,\n    .ant-tabs-bottom > .ant-tabs-nav,\n    .ant-tabs-top > div > .ant-tabs-nav,\n    .ant-tabs-bottom > div > .ant-tabs-nav {\n      @apply ~'h-11.5' mb-0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs-wrapper": "MwyhARsHTyqsOAn0AgBe"
};
export default ___CSS_LOADER_EXPORT___;
