import { StringValuesOfEnum } from '@aminsights/shared';
import { useEffect, useState } from 'react';

import tailwindConfig from '@/tailwindWrapper';

export enum EScreenSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
}

const SIZES = Object.values(EScreenSize);
export type Sizes = StringValuesOfEnum<typeof EScreenSize>;

const getWidthFromSize = (size: Exclude<Sizes, 'xs'>) => {
  return Number(
    tailwindConfig.theme.screens[size].slice(
      0,
      tailwindConfig.theme.screens[size].indexOf('px'),
    ),
  );
};

const useScreenWidth = () => {
  const [currWidth, setCurrWidth] = useState(0);

  const isCurrentWidth = (value: Sizes) => {
    const currValIndex = SIZES.indexOf(value);
    const nextValue = SIZES[currValIndex + 1];
    switch (value) {
      case EScreenSize.xs:
        return currWidth < getWidthFromSize(EScreenSize.sm);
      case EScreenSize.sm:
      case EScreenSize.md:
      case EScreenSize.lg:
      case EScreenSize.xl:
        if (nextValue !== EScreenSize.xs) {
          return (
            currWidth >= getWidthFromSize(value) &&
            currWidth < getWidthFromSize(nextValue)
          );
        }
        return false;
      case EScreenSize['2xl']:
      default:
        return currWidth >= getWidthFromSize(EScreenSize['2xl']);
    }
  };

  useEffect(() => {
    setCurrWidth(window.innerWidth);
  }, [window.innerWidth]);

  return { isCurrentWidth, currentWidth: currWidth };
};

export default useScreenWidth;
