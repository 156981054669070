import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { useContext } from 'react';

import { ReactComponent as CheckActive } from '@/assets/svg/icons/icon-check-active.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import useTabsToShowOnWatchlist from '@/hooks/query-hooks/watchlist-hooks/useTabsToShowOnWatchlist';
import { useCurrentBucketId } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';

import { TabContext } from '../Watchlist/WatchlistTabWrapper';
import style from './style.module.less';

const PerfRiskMenu = () => {
  const { activeTab, setActiveTab } = useContext(TabContext);
  const currentBucketId = useCurrentBucketId();
  const { data: tabsToShow } = useTabsToShowOnWatchlist(currentBucketId);

  const formatLabel = (value: string) => {
    if (value === WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE) {
      return 'P&R: Ann Perf';
    }
    if (value === WATCHLIST_TAB_KEY.CALENDAR_YEAR_PERFORMANCE) {
      return 'P&R: Cal Perf';
    }
    if (value === WATCHLIST_TAB_KEY.RISK) {
      return 'P&R: Risk';
    }
    return 'Perf & Risk';
  };

  const menuOptions = [
    {
      value: WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE,
      label: 'Annualised Perf',
    },
    {
      value: WATCHLIST_TAB_KEY.CALENDAR_YEAR_PERFORMANCE,
      label: 'Calendar Year Perf',
    },
    {
      value: WATCHLIST_TAB_KEY.RISK,
      label: 'Risk',
    },
  ].filter(option =>
    tabsToShow?.length ? tabsToShow.includes(option.value) : true,
  );

  if (!menuOptions.length) {
    return null;
  }

  const itemSelected = menuOptions.some(item => item.value === activeTab);

  return (
    <div
      className={cx(
        style['watchlist-dropdown'],
        'watchlist-dropdown',
        itemSelected ? style['active-item'] : '',
      )}
    >
      <Dropdown
        trigger={['hover', 'click']}
        placement="bottomLeft"
        overlay={
          <Menu
            className="min-w-60 p-0"
            items={menuOptions.map(option => {
              const isActive = option.value === activeTab;
              return {
                key: option.value,
                label: (
                  <div
                    className={cx(
                      'flex items-center justify-between',
                      isActive && style['activeLabel'],
                    )}
                    data-test-id={`tabGroupPerfRisk-${option.value}`}
                  >
                    {option.label}{' '}
                    {isActive && <CheckActive className="active-icon" />}
                  </div>
                ),
                onClick: () => setActiveTab(option.value),
              };
            })}
          />
        }
      >
        <div
          className={cx(
            style.tabLabel,
            'flex items-center justify-start gap-x-3',
          )}
        >
          {formatLabel(activeTab)}{' '}
          <NextArrow
            className={cx('icon', style['button-search-dropdown-caret'])}
          />
        </div>
      </Dropdown>
      <span className={style['custom-border']}></span>
    </div>
  );
};

export default PerfRiskMenu;
