// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uSUeEghTqcIF30mqE3Hv {\n  height: 56px;\n}\n.QMvqr0f5x4q4wUuxC_Fl {\n  min-width: 56px;\n}\n.O5cVqqw5OXAwOo7XO8JD {\n  grid-template-columns: -webkit-min-content auto;\n  grid-template-columns: min-content auto;\n}\n@media (min-width: 1280px) {\n  .GoNvT_GMxb7QQjkQSQVf {\n    grid-template-columns: 1fr -webkit-max-content;\n    grid-template-columns: 1fr max-content;\n    grid-gap: 3rem;\n    align-items: center;\n  }\n}\n.G3iGaX4WUxXxO2vgmxqJ {\n  height: 100% !important;\n  width: 100% !important;\n  display: block;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/CorrelationMatrix/style.module.less"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAIA;EACE,eAAA;AAFF;AAKA;EACE,+CAAA;EAAA,uCAAA;AAHF;AAMA;EACE;IACE,8CAAA;IAAA,sCAAA;IACA,cAAA;IACA,mBAAA;EAJF;AACF;AAOA;EACE,uBAAA;EACA,sBAAA;EACA,cAAA;AALF","sourcesContent":["// Ideally we can use tailwind arbitrary values but we can't still we update it\n\n.matrix-item-height {\n  height: 56px;\n}\n\n.matrix-item-width {\n  min-width: 56px;\n}\n\n.matrix-label-grid {\n  grid-template-columns: min-content auto;\n}\n\n@media (min-width: 1280px) {\n  .legend-right {\n    grid-template-columns: 1fr max-content;\n    grid-gap: 3rem;\n    align-items: center;\n  }\n}\n\n.override-skeleton {\n  height: 100% !important;\n  width: 100% !important;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"matrix-item-height": "uSUeEghTqcIF30mqE3Hv",
	"matrix-item-width": "QMvqr0f5x4q4wUuxC_Fl",
	"matrix-label-grid": "O5cVqqw5OXAwOo7XO8JD",
	"legend-right": "GoNvT_GMxb7QQjkQSQVf",
	"override-skeleton": "G3iGaX4WUxXxO2vgmxqJ"
};
export default ___CSS_LOADER_EXPORT___;
