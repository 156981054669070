import { useAuth0 } from '@auth0/auth0-react';
import { Menu } from 'antd';
import React from 'react';

import style from './style.module.less';

const NavbarProfileMenu: React.FCWithChild = () => {
  const { logout } = useAuth0();

  const onClickLogout = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <Menu
      className={style['navbar-profile-menu']}
      overflowedIndicatorPopupClassName="test"
      items={[
        {
          label: 'Logout',
          key: 'logout',
          onClick: () => onClickLogout(),
          // eslint-disable-next-line
          // @ts-ignore
          'data-test-id': 'navLogoutButton',
        },
      ]}
    />
  );
};

export default NavbarProfileMenu;
