import { ResourceUser, ResourceUserRoleEnum } from '@aminsights/contract';

export interface ListItemUser extends ResourceUser {
  id: string;
  name: string;
  email: string;
  pendingInvite?: boolean;
}

export const sortUsersListItem = (users: ListItemUser[]) => {
  return users.sort((a, b) => {
    if (a.role === b.role) {
      return a.name.localeCompare(b.name);
    }
    return a.role === ResourceUserRoleEnum.Editor ? -1 : 1;
  });
};
