// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".obmtoPuVctB6OTxz4FHS span:only-child,\n.obmtoPuVctB6OTxz4FHS span:last-child {\n  border: 0.5px solid #181920;\n}\n.obmtoPuVctB6OTxz4FHS span {\n  border-top: 0.5px solid #181920;\n  border-left: 0.5px solid #181920;\n  border-bottom: 0.5px solid #181920;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Table/Legend/style.module.less"],"names":[],"mappings":"AAAA;;EAEE,2BAAA;AACF;AAEA;EACE,+BAAA;EACA,gCAAA;EACA,kCAAA;AAAF","sourcesContent":[".legend-item-border span:only-child,\n.legend-item-border span:last-child {\n  border: 0.5px solid #181920;\n}\n\n.legend-item-border span {\n  border-top: 0.5px solid #181920;\n  border-left: 0.5px solid #181920;\n  border-bottom: 0.5px solid #181920;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legend-item-border": "obmtoPuVctB6OTxz4FHS"
};
export default ___CSS_LOADER_EXPORT___;
