import { EMPTY_DATA_POINT } from '@aminsights/shared';

const GENDER_MAP: { [key: string]: string } = {
  '0': 'Female',
  '1': 'Male',
};

export const getGender = (genderCode: string | undefined): string => {
  return GENDER_MAP[genderCode ?? ''] ?? EMPTY_DATA_POINT;
};
