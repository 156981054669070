import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { ReactComponent as IconSearch } from '@/assets/svg/icons/icon-search.svg';

import style from './style.module.less';
import useProvideSCSearch from './useProvideSCSearch';

type SearchProps = {
  searchClassName?: string;
  placeholder?: string;
  searchIconClassName?: string;
  isClear?: boolean;
};

const Search: React.FCWithChild<SearchProps> = ({
  searchClassName,
  placeholder = 'Press Enter to search',
  searchIconClassName,
  isClear,
}) => {
  const [term, setTerm] = useState('');

  const { state, setSearchTerm } = useProvideSCSearch();

  useEffect(() => {
    setTerm(state.searchTerm ?? '');
  }, [state.searchTerm]);

  useEffect(() => {
    if (isClear) {
      setTerm('');
      setSearchTerm('');
    }
  }, [isClear]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  };

  const onBlur = (_e: React.ChangeEvent<HTMLInputElement>) => {
    if (state.searchTerm !== term) {
      setSearchTerm(term);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchTerm(term);
    }
  };

  return (
    <div className={cx(style['search-wrapper'])}>
      <input
        className={cx(style['search-dropdown'], searchClassName)}
        type="search"
        value={term}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        id="search-dropdown"
      />
      <IconSearch
        className={cx('icon', style['search-icon'], searchIconClassName)}
      />
    </div>
  );
};

export default Search;
