import { Dayjs } from 'dayjs';

import { MS_DATE_FORMAT, WEEKDAYS } from '../../constants';
import { getAllDatesByDayOfTheWeek } from '../rips/dateUtils';
import {
  equalizeRipsDataArrayWithNaNValues,
  findAndPatchAndTrimRipsDataAnomalies,
  FundModelLike,
  RipsDataLike,
  trimRipsDataByDates,
} from '../rips/ripsDataHelpers';
import { calculateCorrelationMatrixFromRipsData } from './math-utils';

export const correlationMatrix = (
  funds: FundModelLike[],
  ripsDataByIsin: Map<string, RipsDataLike[]>,
  range: {
    from: Dayjs;
    to: Dayjs;
  },
) => {
  const order = funds.map(f => f.shareClassDetails.isin);
  const dayOfTheWeek = range.to.format('dddd').toUpperCase() as WEEKDAYS;

  const datesForMatchingWeekday = getAllDatesByDayOfTheWeek(
    range.from.format(MS_DATE_FORMAT),
    range.to.format(MS_DATE_FORMAT),
    dayOfTheWeek,
  );

  for (const isin of ripsDataByIsin.keys()) {
    if (ripsDataByIsin.get(isin)?.length === 0) {
      throw new Error(`No data found for isin ${isin} in the RIPs data`);
    }
  }

  const ripsDataWithNaNDates = equalizeRipsDataArrayWithNaNValues(
    funds,
    ripsDataByIsin,
    {
      from: range.from,
      to: range.to,
    },
  );

  const ripsDataWithPatchedAnomalies = findAndPatchAndTrimRipsDataAnomalies(
    ripsDataWithNaNDates,
    datesForMatchingWeekday,
  );

  const ripsDataTrimmedByDates = trimRipsDataByDates(
    ripsDataWithPatchedAnomalies,
    datesForMatchingWeekday,
  );

  const matrix = calculateCorrelationMatrixFromRipsData(
    ripsDataTrimmedByDates,
    order,
  );

  const emptyIsinsInMatrixByIndex = findRowIndicesWithNaNValues(matrix);

  const emptyIsins = Array.from(ripsDataWithPatchedAnomalies.keys()).filter(
    (_, index) => emptyIsinsInMatrixByIndex.includes(index),
  );

  const trimmedMatrix = removeRowAndColumnByIndex(
    matrix,
    emptyIsinsInMatrixByIndex,
  );

  return {
    rawMatrix: matrix.map(row => row.map(cell => cell.toString())),
    rawIsinByIndex: order,

    // Trim the data if some funds don't have data for the full period
    matrix: trimmedMatrix,
    emptyIsins,
  };
};

/**
 * Finds the indices of rows in a matrix that contain only NaN values.
 *
 * @param matrix - The input matrix.
 * @returns An array containing the indices of rows with only NaN values.
 */
const findRowIndicesWithNaNValues = (matrix: number[][]): number[] => {
  const emptyRowIndices: number[] = [];

  matrix.forEach((row, index) => {
    if (row.every(cell => isNaN(cell))) {
      emptyRowIndices.push(index);
    }
  });

  return emptyRowIndices;
};

/**
 * Removes rows and columns from a matrix based on the specified indices.
 *
 * @param matrix - The input matrix.
 * @param indices - The indices of rows and columns to remove.
 * @returns A new matrix with the specified rows and columns removed.
 */
const removeRowAndColumnByIndex = (
  matrix: number[][],
  indices: number[],
): number[][] => {
  const output = matrix.map(row => [...row]);

  indices.sort((a, b) => b - a);

  indices.forEach(index => {
    output.splice(index, 1);
  });

  output.forEach(row => {
    indices.forEach(index => {
      row.splice(index, 1);
    });
  });

  return output;
};
