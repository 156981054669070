import { FundBaseShareClassDetails } from '@aminsights/contract';
import {
  LIMIT_FUNDS_FOR_CHARTING,
  LIMIT_FUNDS_PER_BUCKET,
} from '@aminsights/shared';
import { Tooltip } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ChartingToolIcon } from '@/assets/svg/icons/charting-tool.svg';
import { ReactComponent as BinIcon } from '@/assets/svg/icons/icon-bin.svg';
import { ReactComponent as IconEye } from '@/assets/svg/icons/icon-eye-add.svg';
import { ReactComponent as IconScales } from '@/assets/svg/icons/icon-scales.svg';
import { MAX_COMPARE_TOTAL } from '@/constants';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { useAppContext } from '@/context/AppContext';
import CompareFundSelectModal from '@/pages/app/FundAndInvestmentTrust/components/CompareTool/CompareFundSelectModal';
import DeleteModal from '@/partials/Modal/DeleteModal';
import WatchlistModal from '@/partials/Modal/WatchlistModal';
import { toggleJiraButton } from '@/utils/jiraForm';

import CustomToaster from '../../../components/Toast';
import style from './style.module.less';

interface Props {
  count: number;
  isins: string[];
  onClearCheckedRows: (isins: string[]) => void;
  onClickAdd?: (isins: string[]) => void;
  checkedRowsClassDetails?: (FundBaseShareClassDetails | undefined)[];
  checkedRowsClassDetailsLeft?: FundBaseShareClassDetails;
  checkedRowsClassDetailsRight?: FundBaseShareClassDetails;
  dataTableRenderedAt?: DataTableRenderedAt;
  deleteAction?: () => Promise<void>;
}

const FundTablesActionToast: React.FCWithChild<Props> = ({
  count,
  isins,
  onClearCheckedRows,
  onClickAdd,
  checkedRowsClassDetails,
  dataTableRenderedAt,
  deleteAction,
}) => {
  const text = (count ?? 0) > 1 ? 'funds' : 'fund';
  const [isWatchlistModalOpen, setIsWatchlistModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);
  const [isDeleteItemModalOpen, setIsDeleteItemModalOpen] = useState(false);

  const { app } = useAppContext();
  const isMobile = app.windowWidth < 768;

  const filteredCheckedRowsClassDetails = checkedRowsClassDetails?.filter(
    (item): item is FundBaseShareClassDetails => item !== undefined,
  );

  const handleRemoveItemModalOpen = () => {
    setIsDeleteItemModalOpen(prev => !prev);
  };

  const onDeleteItem = (execute: () => Promise<void>) => {
    setIsDeleteItemModalOpen(false);
    return execute();
  };

  const handleWatchlistModalOpen = () => {
    setIsWatchlistModalOpen(prev => !prev);
  };

  useEffect(() => {
    if (isSuccess) {
      onClearCheckedRows([]);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isMobile) toggleJiraButton(false);
    return () => {
      toggleJiraButton(true);
    };
  }, [isMobile]);

  const isCompareDisabled =
    isins.length < 2 || isins.length > MAX_COMPARE_TOTAL;

  const isAddToBucketDisabled = isins.length > LIMIT_FUNDS_PER_BUCKET;
  const isChartingDisabled = isins.length > LIMIT_FUNDS_FOR_CHARTING;
  return (
    <>
      {count && (
        <>
          <CustomToaster
            isCentered
            isVisible={true}
            message={`${count} ${text} selected`}
            closeText="Cancel"
            className={cx('regular', style['funds-table-toast'])}
            type="info"
            actions={[
              dataTableRenderedAt !== DataTableRenderedAt.Watchlist
                ? {
                    actionItem: (
                      <Tooltip
                        title={
                          isAddToBucketDisabled && (
                            <span style={{ fontSize: '10px' }}>
                              Maximum of 11 funds
                            </span>
                          )
                        }
                      >
                        <div
                          className={`flex items-center h-12 ${
                            isAddToBucketDisabled ? 'cursor-not-allowed' : ''
                          }`}
                        >
                          <IconEye
                            className={`icon md:mr-1 ${
                              isAddToBucketDisabled ? 'disabled' : 'text-white'
                            }`}
                            width={15}
                          />
                          <span
                            className={`font-medium text-sm hidden md:inline-block ${
                              isAddToBucketDisabled ? 'disabled' : 'text-white'
                            }`}
                          >
                            Watchlist
                          </span>
                        </div>
                      </Tooltip>
                    ),
                    onClickAction: () => {
                      if (onClickAdd) {
                        onClickAdd(isins);
                        return;
                      }
                      if (!isAddToBucketDisabled) {
                        setIsWatchlistModalOpen(true);
                      }
                    },
                  }
                : null,
              dataTableRenderedAt !== DataTableRenderedAt.ShareClasses
                ? {
                    actionItem: (
                      <Tooltip
                        title={
                          isCompareDisabled && (
                            <span style={{ fontSize: '10px' }}>
                              Maximum of 4 funds
                            </span>
                          )
                        }
                      >
                        <div
                          className={`flex items-center h-12 ${
                            isCompareDisabled ? 'cursor-not-allowed' : ''
                          }`}
                        >
                          <IconScales
                            className={`icon md:mr-1 ${
                              isCompareDisabled && 'disabled'
                            }`}
                            width={15}
                          />

                          <span
                            className={`font-medium text-sm hidden md:inline-block ${
                              isCompareDisabled ? 'disabled' : 'text-white'
                            }`}
                          >
                            Compare
                          </span>
                        </div>
                      </Tooltip>
                    ),
                    onClickAction: () => {
                      if (!isCompareDisabled) {
                        setIsCompareModalOpen(true);
                      }
                    },
                  }
                : null,
              {
                actionItem: (
                  <Tooltip
                    title={
                      isChartingDisabled && (
                        <span style={{ fontSize: '10px' }}>
                          Maximum of 20 funds
                        </span>
                      )
                    }
                  >
                    <div className="flex items-center h-12">
                      <Link
                        to={`/charts/absolute?${isins
                          .map(isin => `isin=${isin}`)
                          .join('&')}`}
                        className={`font-medium text-sm ${
                          isChartingDisabled ? 'disabled' : 'text-white'
                        }`}
                      >
                        <ChartingToolIcon
                          className={`icon md:mr-1 ${
                            isChartingDisabled ? 'disabled' : 'text-white'
                          }`}
                          width={15}
                        />
                        <span
                          className={`font-medium text-sm hidden md:inline-block ${
                            isChartingDisabled ? 'disabled' : 'text-white'
                          }`}
                        >
                          Charting
                        </span>
                      </Link>
                    </div>
                  </Tooltip>
                ),
              },
              {
                actionItem: (
                  <Tooltip title="Delete" placement="top" color="#3E414B">
                    <BinIcon />
                  </Tooltip>
                ),
                onClickAction: () => {
                  setIsDeleteItemModalOpen(true);
                },
              },
            ]}
            onClose={() => {
              if (isDeleteItemModalOpen) {
                setIsDeleteItemModalOpen(false);
              }
              onClearCheckedRows([]);
            }}
          />
          <WatchlistModal
            isins={isins || []}
            isVisible={isWatchlistModalOpen}
            toggleModal={handleWatchlistModalOpen}
            isSuccess={setIsSuccess}
          />
          <CompareFundSelectModal
            isOpen={isCompareModalOpen}
            setIsOpen={setIsCompareModalOpen}
            initialFunds={filteredCheckedRowsClassDetails}
          />
          {deleteAction && (
            <DeleteModal
              modalInfo={{
                title: 'Delete Fund?',
                description: `${count} ${text} will be permanently deleted.`,
                btnName: 'Delete',
                succesMessage: `Successfully deleted row${
                  count > 1 ? 's' : ''
                }`,
                errorMessage: `Row not deleted`,
              }}
              isVisible={isDeleteItemModalOpen}
              toggleModal={handleRemoveItemModalOpen}
              onDeleteClick={() => onDeleteItem(deleteAction)}
            />
          )}
        </>
      )}
    </>
  );
};

export default FundTablesActionToast;
