import WelcomeImage from '@/assets/images/onboarding-screen-1.png';
import PersonaliseImage from '@/assets/images/onboarding-screen-2.png';
import ReceiveImage from '@/assets/images/onboarding-screen-3.png';
import ScreenImage from '@/assets/images/onboarding-screen-4.png';

export interface ISlide {
  step: number;
  banner: string;
  title: string;
  description: string;
}

export const slides: Array<ISlide> = [
  {
    step: 1,
    banner: WelcomeImage,
    title: 'Welcome to AM Insights',
    description:
      'Track your watchlist and get alerted about any significant changes.',
  },
  {
    step: 2,
    banner: PersonaliseImage,
    title: 'Personalise your Dashboard',
    description:
      'Add funds and investment trusts to your watchlist to personalise your dashboard.',
  },
  {
    step: 3,
    banner: ReceiveImage,
    title: 'Receive Tailored Alerts',
    description: 'Get alerted about Significant Changes in your watchlist.',
  },
  {
    step: 4,
    banner: ScreenImage,
    title: 'Screen and Research',
    description:
      'Use search and filters to explore funds/trusts, and review the ESG and gender pay gap data.',
  },
];
