import { NOT_DISCLOSED } from '@/constants';

export const isManagerListLengthMoreThanTwo = (
  managerList: string[],
): boolean => {
  return managerList.length > 2;
};

export const isManagerNotDisclosed = (manager: string): boolean => {
  return manager === NOT_DISCLOSED;
};
