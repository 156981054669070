import { APP_ROUTE_PORTFOLIOS } from '@aminsights/shared';
import { AreaConfig } from '@ant-design/charts';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetPortfolioAbsoluteChartData from '@/hooks/query-hooks/chart-hooks/useGetPortfolioAbsoluteChartData';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { PORTFOLIO_TAB } from '@/pages/app/Portfolio/types';
import RangeDatePicker, {
  parseRangeValues,
  useDatePickerContext,
} from '@/partials/DatePicker';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';

import ChartEmptyState from '../ChartEmptyState';
import BaseChart, { PortfolioChartsPropsV2 } from '../components/BaseChart';
import chartStyles from '../utils/chart.module.less';
import { PerformanceCardDetails } from '../utils/chart-data';
import { calculateStrokeColor } from '../utils/colors';
import { useChartColorsById } from '../utils/useChartColors';

const PortfolioAbsoluteChartV2 = ({ portfolioId }: PortfolioChartsPropsV2) => {
  const { value: datePickerValue, handleChange: handleDatePickerChange } =
    useDatePickerContext();
  const [legendData, setLegendData] = useState<PerformanceCardDetails[]>([]);

  // Contemplating whether to move this out or not
  // eslint-disable-next-line
  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];

  const { data, isLoading } = useGetPortfolioAbsoluteChartData({
    portfolioId,
    period,
  });
  const absoluteData = data?.data || [];
  const legendIds = useMemo(() => {
    const ldClone = data?.legendData?.filter(ld => !ld.isBenchmark);
    return (ldClone || [])
      .sort((a, b) => a?.label?.localeCompare(b?.label || '') || -1)
      .map(ld => ld.id || '');
  }, [data?.legendData]);
  const chartColors = useChartColorsById({
    legend: data?.legendData || [],
    ids: legendIds,
  });

  useEffect(() => {
    if (data?.legendData?.length && chartColors) {
      setLegendData(
        data.legendData.map(f => ({
          ...f,
          tooltip: f.label || '',
          value: f.value || '',
          id: f.id || '',
          isBenchmark: f.isBenchmark || false,
          label: f.label || '',
          isFeatured: f.isFeatured || false,
          color: chartColors[f.id || ''] || '',
        })),
      );
    }
  }, [data, chartColors]);

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: absoluteData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return chartColors[id] || '';
      },
    }),
    [absoluteData, chartColors],
  );
  // if isins are not defined - show loader no matter what
  // this will mean that we are waiting for isins to be defined to be provided from the parent control

  if (!absoluteData.length && !isLoading) {
    return (
      <ChartEmptyState
        subtitle={
          <>
            {'Add funds or edit it '}
            <Link
              style={{ color: '#1890ff' }}
              to={`/${APP_ROUTE_PORTFOLIOS}/${portfolioId}/${PORTFOLIO_TAB.HOLDINGS}`}
            >
              here
            </Link>
          </>
        }
        title="This portfolio is not ready"
        isDataError={!portfolioId}
      />
    );
  }

  return (
    <div className="px-6">
      <div className={chartStyles.header}>
        <h5
          data-test-id="absolutePortfolioChartLabel"
          className="text-sm font-bold text-darkest"
        >
          <Loader width="150px" loading={isLoading} component={<>Absolute</>} />
        </h5>
        <RangeDatePicker
          disabled={isLoading}
          loading={isLoading}
          onChange={handleDatePickerChange}
          value={datePickerValue}
          btnClassName="justify-self-end col-span-2 sm:col-span-1 w-full sm:w-auto"
          dataTestId="absoluteChartDropdown"
        />
      </div>
      {!isLoading && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          dataTestId="absolutePortfolioChart"
          onUpdateLegend={setLegendData}
        />
      )}
      {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
      <div
        data-test-id="absolutePortfolioChartLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .map(legendItem => (
              <ChartsLegendCard
                key={legendItem.id}
                label={legendItem.label}
                idForFundDetailsLink={legendItem.isin}
                id={legendItem.id}
                tooltip={legendItem.tooltip || legendItem.label}
                isIndex={legendItem.isBenchmark}
                color={legendItem.color}
                value={`${legendItem.value}`}
                isActive={legendItem.isActive}
                isFeatured={false}
              />
            ))}
        />
      </div>
    </div>
  );
};

export default PortfolioAbsoluteChartV2;
