import { Fund, Performance } from '@aminsights/contract';

import {
  IAnnualisedPerformanceData,
  ICalendarYearPerformanceData,
} from './types';

/**
 * Maps a Fund into IAnnualisedPerformanceData
 *
 * As of writing this comment it is used in 2 components:
 *
 * In compare tool:
 *   CompareAnnualisedTable.tsx,
 *
 * In fund detail page:
 *   AnnualisedPerformanceTable.tsx,
 */

export const annualisedRowMapper = (
  key: string,
  fund: Fund,
): IAnnualisedPerformanceData => {
  return {
    key,
    name: fund?.fundName,
    ytdReturn: fund?.totalReturnYtdPct,
    ytdPercentile: fund?.totalReturnYtdMorningstarCategoryPcl,
    threeMonthsReturn: fund?.totalReturn3MonthPct,
    threeMonthsPercentile: fund?.totalReturn3MonthMorningstarCategoryPcl,
    sixMonthsReturn: fund?.totalReturn6MonthPct,
    sixMonthsPercentile: fund?.totalReturn6MonthMorningstarCategoryPcl,
    oneYearReturn: fund?.totalReturn1YearPct,
    oneYearPercentile: fund?.totalReturn1YearMorningstarCategoryPcl,
    threeYearsReturn: fund?.totalReturn3YearPct,
    threeYearsPercentile: fund?.totalReturn3YearMorningstarCategoryPcl,
    fiveYearsReturn: fund?.totalReturn5YearPct,
    fiveYearsPercentile: fund?.totalReturn5YearMorningstarCategoryPcl,
    ytdSP: fund?.sharePriceReturnYtdPct,
    threeMonthsSP: fund?.sharePriceReturn3MonthPct,
    sixMonthsSP: fund?.sharePriceReturn6MonthPct,
    oneYearSP: fund?.sharePriceReturn1YearPct,
    threeYearsSP: fund?.sharePriceReturn3YearPct,
    fiveYearsSP: fund?.sharePriceReturn5YearPct,
  };
};

/**
 * Maps a Fund into ICalendarYearPerformanceData
 *
 * As of writing this comment it is used in 2 components:
 *
 * In compare tool:
 *   CompareCalendarYearPerformance.tsx,
 *
 * In fund detail page:
 *   CalendarPerformanceTable.tsx
 */
export const calendarYearPerformanceMapper = (
  key: string,
  fund: Fund,
): ICalendarYearPerformanceData => {
  return {
    key,
    name: fund.fundName,
    totalReturnYtdPct: fund?.totalReturnYtdPct,
    totalReturnYtdMorningstarCategoryPcl:
      fund?.totalReturnYtdMorningstarCategoryPcl,

    totalReturn1calendarYearsAgoPct: fund.totalReturn1calendarYearsAgoPct,
    totalReturn2calendarYearsAgoPct: fund.totalReturn2calendarYearsAgoPct,
    totalReturn3calendarYearsAgoPct: fund.totalReturn3calendarYearsAgoPct,
    totalReturn4calendarYearsAgoPct: fund.totalReturn4calendarYearsAgoPct,
    totalReturn5calendarYearsAgoPct: fund.totalReturn5calendarYearsAgoPct,

    totalReturn1calendarYearsAgoCategoryPcl:
      fund.totalReturn1calendarYearsAgoCategoryPcl,
    totalReturn2calendarYearsAgoCategoryPcl:
      fund.totalReturn2calendarYearsAgoCategoryPcl,
    totalReturn3calendarYearsAgoCategoryPcl:
      fund.totalReturn3calendarYearsAgoCategoryPcl,
    totalReturn4calendarYearsAgoCategoryPcl:
      fund.totalReturn4calendarYearsAgoCategoryPcl,
    totalReturn5calendarYearsAgoCategoryPcl:
      fund.totalReturn5calendarYearsAgoCategoryPcl,

    sharePriceReturnYtdPct: fund.sharePriceReturnYtdPct,
    sharePriceReturn1calendarYearsAgoPct:
      fund.sharePriceReturn1calendarYearsAgoPct,
    sharePriceReturn2calendarYearsAgoPct:
      fund.sharePriceReturn2calendarYearsAgoPct,
    sharePriceReturn3calendarYearsAgoPct:
      fund.sharePriceReturn3calendarYearsAgoPct,
    sharePriceReturn4calendarYearsAgoPct:
      fund.sharePriceReturn4calendarYearsAgoPct,
    sharePriceReturn5calendarYearsAgoPct:
      fund.sharePriceReturn5calendarYearsAgoPct,
  };
};

export const benchmarkAnnualisedMapper = (
  key: string,
  benchmark: Performance,
): IAnnualisedPerformanceData => {
  return {
    key,
    name: benchmark?.name,
    ytdReturn: benchmark?.totalReturnYtdPct,
    threeMonthsReturn: benchmark?.totalReturn3MonthPct,
    sixMonthsReturn: benchmark?.totalReturn6MonthPct,
    oneYearReturn: benchmark?.totalReturn1YearPct,
    threeYearsReturn: benchmark?.totalReturn3YearPct,
    fiveYearsReturn: benchmark?.totalReturn5YearPct,
  };
};

export const benchmarkCalendarYearMapper = (
  key: string,
  benchmark: Performance,
): ICalendarYearPerformanceData => {
  return {
    key,
    name: benchmark.name,
    totalReturnYtdPct: benchmark.totalReturnYtdPct,
    totalReturn1calendarYearsAgoPct: benchmark.totalReturn1calendarYearsAgoPct,
    totalReturn2calendarYearsAgoPct: benchmark.totalReturn2calendarYearsAgoPct,
    totalReturn3calendarYearsAgoPct: benchmark.totalReturn3calendarYearsAgoPct,
    totalReturn4calendarYearsAgoPct: benchmark.totalReturn4calendarYearsAgoPct,
    totalReturn5calendarYearsAgoPct: benchmark.totalReturn5calendarYearsAgoPct,
  };
};
