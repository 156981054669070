import { Stylebox, WatchlistApi } from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { STYLEBOX, WATCHLIST } from './query-keys';

const useWatchlistStyleBox = (
  bucketId: string | null | undefined,
  options?: Omit<
    UseQueryOptions<Stylebox[] | null, unknown>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const watchlistApi = new WatchlistApi(openApiConfig());

  return useQuery<Stylebox[] | null, unknown>(
    [WATCHLIST, STYLEBOX, bucketId],
    async () => {
      if (bucketId) {
        const result = await watchlistApi.getWatchlistStyleBox(bucketId);
        return result.data.data;
      }
      return null;
    },
    options,
  );
};

export default useWatchlistStyleBox;
