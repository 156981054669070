import { Button, Popover } from 'antd';
import { FC } from 'react';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';

import style from './style.module.less';

interface BenchmarkItemActionsProps {
  onClickEditBenchmark: () => void;
  onClickDeleteBenchmark: () => void;
}

const BenchmarkItemActions: FC<BenchmarkItemActionsProps> = ({
  onClickEditBenchmark,
  onClickDeleteBenchmark,
}) => {
  return (
    <div className={style['benchmarks-list__actions']}>
      <Button
        type="link"
        className={style['benchmarks-list__delete']}
        onClick={e => {
          e.stopPropagation();
          onClickDeleteBenchmark();
        }}
        data-test-id="customBenchmarksDeleteButton"
      >
        Delete
      </Button>
      <Button
        type="link"
        className={style['benchmarks-list__edit']}
        onClick={e => {
          e.stopPropagation();
          onClickEditBenchmark();
        }}
        data-test-id="customBenchmarksEditButton"
      >
        Edit
      </Button>
      <Popover
        placement="bottomRight"
        trigger="click"
        getPopupContainer={triggerNode =>
          triggerNode.parentElement as HTMLElement
        }
        className="block md:hidden"
        align={{ offset: [5, -10] }}
        content={
          <div className="w-32 flex flex-col items-start">
            <Button
              type="link"
              className="p-0 text-neutral"
              onClick={e => {
                e.stopPropagation();
                onClickEditBenchmark();
              }}
              data-test-id="customBenchmarksEditButton"
            >
              Edit
            </Button>
            <Button
              type="link"
              className="p-0"
              onClick={e => {
                e.stopPropagation();
                onClickDeleteBenchmark();
              }}
              danger
              data-test-id="customBenchmarksDeleteButton"
            >
              Delete
            </Button>
          </div>
        }
      >
        <MeatballMenu className="p-1 h-6 rounded-full cursor-pointer" />
      </Popover>
    </div>
  );
};

export default BenchmarkItemActions;
