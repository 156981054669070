// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sSvDPk5IAxZWqPTSaILx .ant-modal-content {\n  padding: 0;\n  border-radius: 0.5rem;\n}\n.sSvDPk5IAxZWqPTSaILx .ant-modal-body {\n  padding-left: 2.5rem;\n  padding-right: 2.5rem;\n  padding-top: 2.5rem;\n  padding-bottom: 1rem;\n}\n.sSvDPk5IAxZWqPTSaILx .ant-modal-close {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.sSvDPk5IAxZWqPTSaILx .ant-modal-close-x {\n  display: flex;\n  height: 0.75rem;\n  width: 0.75rem;\n  fill: currentColor;\n  --tw-text-opacity: 1;\n  color: rgb(140 146 177 / var(--tw-text-opacity));\n}\n.sSvDPk5IAxZWqPTSaILx .ant-modal-footer {\n  display: flex;\n  justify-content: space-between;\n  border-width: 0px;\n  padding-left: 2.5rem;\n  padding-right: 2.5rem;\n  padding-bottom: 2.5rem;\n  padding-top: 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/Internal/MaterialFormModal/style.module.less"],"names":[],"mappings":"AAGE;EACE,UAAA;EACA,qBAAA;AAFJ;AAMI;EAAA,oBAAA;EAAA,qBAAA;EAAA,mBAAA;EAAA;AAAA;AAGF;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAJJ;AAOM;EAAA,aAAA;EAAA,eAAA;EAAA,cAAA;EAAA,kBAAA;EAAA,oBAAA;EAAA;AAAA;AAKF;EAAA,aAAA;EAAA,8BAAA;EAAA,iBAAA;EAAA,oBAAA;EAAA,qBAAA;EAAA,sBAAA;EAAA;AAAA","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.modal :global .ant-modal {\n  &-content {\n    padding: 0;\n    @apply rounded-lg;\n  }\n\n  &-body {\n    @apply px-10 pt-10 pb-4;\n  }\n\n  &-close {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    &-x {\n      @apply flex fill-current text-neutral-100 h-3 w-3;\n    }\n  }\n\n  &-footer {\n    @apply flex justify-between px-10 pb-10 pt-0 border-0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "sSvDPk5IAxZWqPTSaILx"
};
export default ___CSS_LOADER_EXPORT___;
