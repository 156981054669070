import { Avatar } from 'antd';
import cx from 'classnames';
import React, { ReactNode } from 'react';

import style from './style.module.less';

type AvatarProps = {
  size?: number | 'large' | 'small';
  type?: string;
  className?: string;
  shape?: 'circle' | 'square';
  color?: string;
  children?: ReactNode;
};

const CustomAvatar: React.FCWithChild<AvatarProps> = ({
  size,
  type = '',
  className = '',
  shape,
  children,
  color = '',
}) => {
  return (
    <Avatar
      className={cx(className, style[type], style[color])}
      size={size}
      shape={shape}
    >
      <span className={style['initial-label']}>{children}</span>
    </Avatar>
  );
};

export default CustomAvatar;
