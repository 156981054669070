// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b0k0OuhPQm7BB_WQMEb9,\n.KAnbA7ODGNDZKTvPzzc7,\n.S8msx2LbffnCU_0Gpxe1 {\n  position: relative;\n}\n.b0k0OuhPQm7BB_WQMEb9:before,\n.KAnbA7ODGNDZKTvPzzc7:before,\n.S8msx2LbffnCU_0Gpxe1:before {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  pointer-events: none;\n}\n.b0k0OuhPQm7BB_WQMEb9:before {\n  border-top: 2px solid #f56a00;\n}\n.KAnbA7ODGNDZKTvPzzc7:before {\n  border-top: 2px solid #0072e6;\n}\n.S8msx2LbffnCU_0Gpxe1:before {\n  border-top: 2px solid #18a515;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Watchlist/WatchlistTables/WatchlistSectors/SectorsEquity/style.module.less"],"names":[],"mappings":"AAAA;;;EAGE,kBAAA;AACF;AACE;;;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,oBAAA;AAGJ;AACA;EACE,6BAAA;AACF;AAEA;EACE,6BAAA;AAAF;AAGA;EACE,6BAAA;AADF","sourcesContent":[".cyclical,\n.sensitive,\n.defensive {\n  position: relative;\n\n  &:before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    pointer-events: none;\n  }\n}\n\n.cyclical:before {\n  border-top: 2px solid #f56a00;\n}\n\n.sensitive:before {\n  border-top: 2px solid #0072e6;\n}\n\n.defensive:before {\n  border-top: 2px solid #18a515;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cyclical": "b0k0OuhPQm7BB_WQMEb9",
	"sensitive": "KAnbA7ODGNDZKTvPzzc7",
	"defensive": "S8msx2LbffnCU_0Gpxe1"
};
export default ___CSS_LOADER_EXPORT___;
