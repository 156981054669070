export const AUTH0_NAMESPACE = 'https://aminsights.com' as const;
export const ROLES_KEY = 'roles' as const;
export const TENANT_ID_KEY = 'tenant_id' as const;
export const PERMISSIONS_KEY = 'permissions' as const;

export const USER_PERMISSIONS = {
  internal: {
    read: 'read:internal',
  },
  materials: {
    write: 'write:materials',
    admin: 'admin:materials',
  },
  comments: {
    write: 'write:comments',
    admin: 'admin:comments',
  },
} as const;

export enum NotificationsTemplateEnum {
  ASSETS_MANAGER,
  FUND_BUYER,
  NO_WEEKLY_UPDATES,
}

export const AUTH_ROLES = [
  {
    name: `FB_ADMIN`,
    notificationsWeeklyTemplate: NotificationsTemplateEnum.FUND_BUYER,
  },
  {
    name: `FB_FUND_RESEARCHER`,
    notificationsWeeklyTemplate: NotificationsTemplateEnum.FUND_BUYER,
  },
  {
    name: `FB_INVESTMENT_MANAGER`,
    notificationsWeeklyTemplate: NotificationsTemplateEnum.FUND_BUYER,
  },
  {
    name: `AM_ADMIN`,
    notificationsWeeklyTemplate: NotificationsTemplateEnum.ASSETS_MANAGER,
  },
  {
    name: `AM_SPECIALIST`,
    notificationsWeeklyTemplate: NotificationsTemplateEnum.ASSETS_MANAGER,
  },
  {
    name: `AM_FUND_SELLER`,
    notificationsWeeklyTemplate: NotificationsTemplateEnum.ASSETS_MANAGER,
  },
];
