// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".adX3eKBZEcejTGSqzyXj .ant-space {\n  float: right;\n}\n.adX3eKBZEcejTGSqzyXj .ant-space .ant-badge-count {\n  box-shadow: none;\n  background-color: #e50e0b;\n  border-radius: 7px;\n  display: inline;\n  padding: 0 5px;\n}\n.adX3eKBZEcejTGSqzyXj .ant-space .ant-badge-count p {\n  font-size: 0.625rem;\n  font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SidebarBadge/style.module.less"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAAA;EAGI,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AAAJ;AAPA;EASM,mBAAA;EACA,gBAAA;AACN","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.sidebar-badge :global .ant-space {\n  float: right;\n  .ant-badge-count {\n    box-shadow: none;\n    background-color: #e50e0b;\n    border-radius: 7px;\n    display: inline;\n    padding: 0 5px;\n    p {\n      font-size: @txt-xxs;\n      font-weight: @weight-bold;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar-badge": "adX3eKBZEcejTGSqzyXj"
};
export default ___CSS_LOADER_EXPORT___;
