import { EMPTY_DATA_POINT } from '@aminsights/shared';

import { IDataTableColumns } from '@/components/Table/DataTable';

export interface ICreditQuality {
  creditQuality: string;
  fund: number;
}

export const columns = (isTrust?: boolean): IDataTableColumns[] => [
  {
    title: 'Credit Quality',
    render: (item: ICreditQuality) => (
      <span className="font-medium text-neutral">{item.creditQuality}</span>
    ),
    renderType: 'text',
    isColumnFixed: true,
  },
  {
    title: isTrust ? 'Trust' : 'Fund',
    render: (item: ICreditQuality) => (
      <span className="text-neutral">
        {!item.fund ? EMPTY_DATA_POINT : `${item.fund.toFixed(1)}%`}
      </span>
    ),
    renderType: 'number',
  },
];
