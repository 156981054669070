export const bucketList = [
  {
    label: 'Blackrock Global European Value',
    value: 'LU1852330817',
    selected: false,
  },
  {
    label: 'Edgewood L Select US Select Growth',
    value: 'LU0952587862',
    selected: false,
  },
  {
    label: 'Schroder Recovery',
    value: 'GB0007893760',
    selected: false,
  },
  {
    label: 'THRG - Blackrock Throgmorton',
    value: 'GB0009697037',
    selected: false,
  },
  {
    label: 'THRG - Blackrock Throgmorton',
    value: 'GB0009697037',
    selected: false,
  },
  {
    label: 'THRG - Blackrock Throgmorton',
    value: 'GB0009697037',
    selected: false,
  },
  {
    label: 'THRG - Blackrock Throgmorton',
    value: 'GB0009697037',
    selected: false,
  },
  {
    label: 'THRG - Blackrock Throgmorton',
    value: 'GB0009697037',
    selected: false,
  },
  {
    label: 'THRG - Blackrock Throgmorton',
    value: 'GB0009697037',
    selected: false,
  },
  {
    label: 'THRG - Blackrock Throgmorton',
    value: 'GB0009697037',
    selected: false,
  },
  {
    label: 'Schroder Recovery',
    value: 'GB0007893760',
    selected: false,
  },
  {
    label: 'Schroder Recovery',
    value: 'GB0007893760',
    selected: false,
  },
];
