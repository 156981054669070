import { SwapOutlined } from '@ant-design/icons';
import { Button, Popover, Switch } from 'antd';
import { useState } from 'react';

import {
  FEATURE_SWITCH_ACTIONS,
  useFeatureSwitchContext,
} from '@/context/FeatureSwitchContext';

export const LocalFeatureSwitch = () => {
  const { dispatch, state } = useFeatureSwitchContext();
  const [open, setOpen] = useState(false);

  const features = Object.keys(state).filter(
    key => key !== 'isInitialised' && key !== 'isClarityEnabled',
  ) as Array<keyof typeof state>;

  const content = (
    <div>
      {features.map(feature => (
        <div
          key={feature}
          className="grid grid-cols-[1fr_auto] items-center mb-2"
        >
          <p>{feature}</p>
          <div className="justify-self-end">
            <Switch
              checked={state[feature]}
              onChange={checked => {
                const newPayload = {
                  ...state,
                  [feature]: checked,
                };
                dispatch({
                  type: FEATURE_SWITCH_ACTIONS.SET_SPLITS,
                  payload: newPayload,
                });
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Popover
      title={
        <span className="whitespace-nowrap">Override split.io switches</span>
      }
      zIndex={1000}
      content={<div className="w-80">{content}</div>}
      trigger="click"
      open={open}
      onOpenChange={setOpen}
    >
      <Button
        className="bottom-20 right-4 fixed z-30 shadow-lg"
        type="primary"
        shape="circle"
        size="large"
        icon={<SwapOutlined />}
      />
    </Popover>
  );
};
