import { Progress } from 'antd';
import React from 'react';

type ProgressProps = {
  percent?: number;
  size?: 'small' | undefined;
  showInfo?: boolean;
  type?: 'circle' | 'dashboard' | 'line' | undefined;
  strokeColor?: string;
  status?: 'success' | 'exception' | 'normal' | 'active' | undefined;
  strokeLinecap?: 'round' | 'square';
  strokeWidth?: number;
  className?: string;
};

const CustomProgress: React.FCWithChild<ProgressProps> = ({
  percent,
  size,
  showInfo,
  type,
  strokeColor,
  status,
  strokeLinecap,
  strokeWidth,
  className,
}) => {
  return (
    <Progress
      className={className}
      percent={percent}
      size={size}
      showInfo={showInfo}
      type={type}
      strokeLinecap={strokeLinecap}
      strokeWidth={strokeWidth}
      strokeColor={strokeColor}
      status={status}
    />
  );
};

export default CustomProgress;
