// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YXENnMtHMDZufqnthdX7 .nzC20Hh8ru7rJT8xbR5T > tr > td {\n    height: 3.5rem\n}\n.YXENnMtHMDZufqnthdX7 h5 {\n    --tw-text-opacity: 1;\n    color: rgb(49 51 65 / var(--tw-text-opacity))\n}\n.YXENnMtHMDZufqnthdX7 p {\n    display: flex;\n    align-items: center;\n    --tw-text-opacity: 1;\n    color: rgb(84 85 118 / var(--tw-text-opacity))\n}\n.YXENnMtHMDZufqnthdX7 p span {\n    --tw-text-opacity: 1;\n    color: rgb(49 51 65 / var(--tw-text-opacity))\n}\n.YXENnMtHMDZufqnthdX7 p .vTJ53CJWmUC22mx4bjuY {\n    font-size: 0.625rem\n}\n.YXENnMtHMDZufqnthdX7 p .VbMM2A3GjG3El8UQpcR5 {\n    --tw-rotate: 180deg;\n    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Dashboard/SignificantChanges/style.module.less"],"names":[],"mappings":"AAEI;IAAA;AAAA;AAGA;IAAA,oBAAA;IAAA;AAAA;AAGA;IAAA,aAAA;IAAA,mBAAA;IAAA,oBAAA;IAAA;AAAA;AAEE;IAAA,oBAAA;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA,mBAAA;IAAA;AAAA","sourcesContent":[".significant-changes-table {\n  .ant-table-tbody > tr > td {\n    @apply h-14;\n  }\n  h5 {\n    @apply text-darkest;\n  }\n  p {\n    @apply flex items-center text-neutral;\n    span {\n      @apply text-darkest;\n    }\n    .icon {\n      @apply text-xxs;\n    }\n    .up {\n      @apply transform rotate-180;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"significant-changes-table": "YXENnMtHMDZufqnthdX7",
	"ant-table-tbody": "nzC20Hh8ru7rJT8xbR5T",
	"icon": "vTJ53CJWmUC22mx4bjuY",
	"up": "VbMM2A3GjG3El8UQpcR5"
};
export default ___CSS_LOADER_EXPORT___;
