import React from 'react';

const Disclaimer: React.FCWithChild = () => {
  const year = new Date().getFullYear();

  return (
    <div
      className="flex flex-col gap-y-2 pb-6 mt-6 px-6 bottom-0 w-full"
      data-test-id="footer"
    >
      <p className="text-xxs leading-4 text-neutral">
        © {year} AM Insights. All Rights Reserved. The information provided on
        this platform is provided on an “as is” basis, and the user assumes the
        entire risk of any use made of this information. Neither AM Insights nor
        any third party involved in or related to the data makes any express or
        implied warranties, representations or guarantees concerning the data,
        and in no event will AM Insights or any third party have any liability
        for any direct, indirect, special, punitive, consequential or any other
        damages (including lost profits) relating to any use of this
        information. The information may not be copied or redistributed except
        as specifically authorised.
      </p>
      <p className="text-xxs leading-4 text-neutral">
        © {year} Morningstar. All Rights Reserved. The information, data,
        analyses and opinions (“Information”) contained herein: (1) include the
        proprietary information of Morningstar and its content providers; (2)
        may not be copied or redistributed except as specifically authorised;
        (3) do not constitute investment advice; (4) are provided solely for
        informational purposes; (5) are not warranted to be complete, accurate
        or timely; and (6) may be drawn from data published on various dates.
        Morningstar is not responsible for any trading decisions, damages or
        other losses related to the Information or its use. Please verify all of
        the Information before using it and don’t make any investment decision
        except upon the advice of a professional financial adviser. Past
        performance is no guarantee of future results. The value and income
        derived from investments may go down as well as up.
      </p>
      <p className="text-xxs leading-4 text-neutral">
        Copyright MSCI {year}. All Rights Reserved. Without prior written
        permission of MSCI, this information and any other MSCI intellectual
        property may only be used for your internal use, may not be reproduced
        or redisseminated in any form and may not be used to create any
        financial instruments or products or any indices. This information is
        provided on an “as is” basis, and the user of this information assumes
        the entire risk of any use made of this information. Neither MSCI nor
        any third party involved in or related to the computing or compiling of
        the data makes any express or implied warranties, representations or
        guarantees concerning the MSCI index-related data, and in no event will
        MSCI or any third party have any liability for any direct, indirect,
        special, punitive, consequential or any other damages (including lost
        profits) relating to any use of this information.
      </p>
    </div>
  );
};

export default Disclaimer;
