import { initialState } from './Context';
import {
  SIGNIFICANT_CHANGES_ACTIONS,
  SignificantChangesActionTypes,
  SignificantChangesState,
} from './types';

export const significantChangesReducer = (
  state: SignificantChangesState,
  action: SignificantChangesActionTypes,
): SignificantChangesState => {
  switch (action.type) {
    case SIGNIFICANT_CHANGES_ACTIONS.SET_SIGNIFICANT_CHANGES_PARAMETERS:
      return {
        ...state,
        significantChangesParameters: {
          ...state.significantChangesParameters,
          ...action.payload,
        },
      };
    case SIGNIFICANT_CHANGES_ACTIONS.CLEAR_FILTER:
      return {
        ...state,
        significantChangesParameters: {
          ...initialState.significantChangesParameters,
        },
      };
    default:
      return state;
  }
};
