import {
  BenchmarkHoldingByTypeResponse,
  BenchmarksApi,
} from '@aminsights/contract';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

const useBenchmarkHoldingsByFundShareClassId = (
  fundShareClassId: string | null | undefined,
  sectors: number[],
  holdings: string[],
  countries: string[],
  options?: Omit<
    UseQueryOptions<BenchmarkHoldingByTypeResponse, unknown>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  return useQuery<BenchmarkHoldingByTypeResponse, unknown>(
    ['benchmark-holdings', fundShareClassId, sectors, holdings, countries],
    async () => {
      const benchmarksApi = new BenchmarksApi(openApiConfig());
      if (
        fundShareClassId &&
        (sectors.length !== 0 ||
          holdings.length !== 0 ||
          countries.length !== 0)
      ) {
        const result = await benchmarksApi.getLatestBenchmarkHoldingsByParams(
          fundShareClassId,
          sectors,
          holdings,
          countries,
        );
        return result.data;
      } else {
        // The first request will not fire on compare tool since on page load we know only fundShareClassId,
        // the rest will be calculated and passsed here.
        // I don't think we should throw at this point like with other useQuery
        return { holdings: [], sectors: [], countries: [] };
      }
    },
    options,
  );
};

export default useBenchmarkHoldingsByFundShareClassId;
