import React, { useEffect, useState } from 'react';

import { APP_ACTIONS, MESSAGE_STATE } from '@/constants';
import { useAppContext } from '@/context/AppContext';

import CustomToaster from '..';

const ErrorToaster: React.FCWithChild = () => {
  const { app, dispatch } = useAppContext();

  let errorMessage: string | undefined = undefined;

  if (app.messageState?.messageState === MESSAGE_STATE.ERROR) {
    errorMessage = app.messageState.message;
  }

  const errorMessageToCopy = `${app?.messageState?.message}`;
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [hasCopiedMessage, setHasCopiedMessage] = useState(false);
  const [timer, setTimer] = useState<undefined | NodeJS.Timeout>(undefined);

  const closeSnackbar = () => {
    if (timer) {
      clearTimeout(timer);
      setTimer(undefined);
    }
    setHasCopiedMessage(false);
    dispatch({
      type: APP_ACTIONS.UNSET_MESSAGE,
    });
    setIsToastVisible(false);
  };

  useEffect(() => {
    if (errorMessage !== '' && !hasCopiedMessage) {
      setIsToastVisible(true);
      if (timer) {
        clearTimeout(timer);
      }
      const timeout = setTimeout(() => {
        closeSnackbar();
      }, 10000);
      setTimer(timeout);
    }
  }, [errorMessage, hasCopiedMessage]);

  const onClickCopy = () => {
    if (errorMessage) {
      navigator.clipboard.writeText(errorMessageToCopy);
    }
    if (navigator.clipboard) {
      setHasCopiedMessage(true);
    }
  };

  return (
    <>
      {app?.messageState?.messageState === MESSAGE_STATE.ERROR && (
        <CustomToaster
          isVisible={isToastVisible}
          message={
            hasCopiedMessage
              ? 'Error message copied to clipboard'
              : errorMessage
          }
          type={`${hasCopiedMessage ? 'success' : 'error'}`}
          className={`${hasCopiedMessage ? 'success' : 'error'}`}
          actions={[
            {
              actionItem: (
                <span className="text-white">
                  {app.messageState.actionName || 'COPY'}
                </span>
              ),
              onClickAction: app.messageState.onClick || onClickCopy,
            },
          ]}
        />
      )}
    </>
  );
};

export default ErrorToaster;
