import './style.less';

import cx from 'classnames';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useCurrentBucketId,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';

import BasePageWithMetadata from '../../BasePageWithMetadata';
import { BucketsScrollTab } from '../../Watchlist/Watchlist/components';
import SignificantChangesWrapper from '../components/SignificantChangesWrapper';
import style from '../style.module.less';

const All: React.FCWithChild = () => {
  const currentWatchlist = useCurrentWatchlist();
  const buckets = currentWatchlist?.data?.buckets;

  const currentBucketId = useCurrentBucketId();
  const { slug } = useParams<{ slug: string }>();

  const isAllBucketsSelected = useMemo(() => {
    return !slug || slug.toLowerCase() === 'all';
  }, [slug]);

  const isinsFromWatchlist = useMemo(() => {
    return buckets
      ?.flatMap(bucket => bucket.funds.map(fundItem => fundItem.isin))
      .filter(isin => isin !== undefined);
  }, [buckets]);

  const currentBucket = buckets?.find(b => b.id === currentBucketId);

  return (
    <BasePageWithMetadata
      title={`Significant Changes${
        currentBucket ? ` - ${currentBucket.name}` : ' - All'
      }`}
    >
      <section className={style['section-significant-changes']}>
        <h2 className="mb-4 text-xl font-bold">Significant Changes</h2>
        <div className={cx('pt-1 mb-6', style['significant-changes-wrapper'])}>
          <div className={style['buckets-wrapper']}>
            <BucketsScrollTab />
          </div>

          <div className={style['section-significant--inner']}>
            <SignificantChangesWrapper
              isins={
                (isAllBucketsSelected
                  ? isinsFromWatchlist
                  : currentBucket?.funds
                      ?.filter(fund => fund.isin !== undefined)
                      .map(fund => fund.isin) || undefined) ?? []
              }
            />
          </div>
        </div>
      </section>
    </BasePageWithMetadata>
  );
};

export default All;
