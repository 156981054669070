import { Alert, Button } from 'antd';
import cx from 'classnames';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';

import style from './style.module.less';

type actionsType = {
  actionItem: string | ReactNode;
  onClickAction?: () => void;
  itemDisabled?: boolean;
} | null;

export type CustomToasterProps = {
  message?: string;
  closeText?: string;
  actions?: Array<actionsType>;
  closable?: boolean;
  className?: 'success' | 'warning' | 'regular' | 'error' | string;
  type?: 'success' | 'warning' | 'info' | 'error';
  isVisible?: boolean;
  isCentered?: boolean;
  onClose?: () => void;
  icon?: ReactElement;
  timeTillClose?: number;
};

const CustomToaster: React.FCWithChild<CustomToasterProps> = ({
  message = '',
  closable = true,
  className = '',
  closeText,
  type,
  onClose,
  isVisible = false,
  isCentered = false,
  icon,
  timeTillClose,
  actions,
}) => {
  const { app, dispatch } = useAppContext();
  const [timer, setTimer] = useState<number | undefined>();

  const onClickClose = () => {
    if (app?.messageState) {
      dispatch({ type: APP_ACTIONS.DROP_MESSAGE });
    }
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (isVisible && app?.messageState?.shouldUseTimer && !!timeTillClose) {
      setTimer(timeTillClose);
    }
  }, [isVisible, timeTillClose, app?.messageState?.shouldUseTimer]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (!!timer) {
      timeout = setTimeout(() => {
        onClickClose();
      }, timer);
    }
    return () => {
      if (!!timer && timeout) {
        clearTimeout(timeout);
        setTimer(undefined);
      }
    };
  }, [timer]);

  return (
    <div
      className={cx(
        style['toast-wrapper'],
        className,
        `${isVisible && style['toast-wrapper--visible']}`,
        `${isCentered && style['toast-wrapper--center']}`,
      )}
    >
      {isVisible && (
        <Alert
          icon={icon}
          showIcon={icon ? true : false}
          message={message?.toString()}
          type={type}
          closable={closable}
          closeIcon={
            closeText && <span className="text-white">{closeText}</span>
          }
          onMouseEnter={() =>
            timeTillClose && app?.messageState?.shouldUseTimer && setTimer(0)
          }
          onMouseLeave={() =>
            timeTillClose && app?.messageState?.shouldUseTimer && setTimer(2000)
          }
          className={cx(
            style['alert-toast-wrapper'],
            `${!type && style.regular}`,
            `${type === 'info' && style.info}`,
            `${type === 'success' && style.success}`,
            `${type === 'warning' && style.warning}`,
            `${type === 'error' && style.error}`,
          )}
          onClose={onClickClose}
          action={actions?.map(item => {
            return (
              <div>
                {!!item?.actionItem && (
                  <Button
                    size="small"
                    type="text"
                    className={cx(
                      `${type === 'error' && style['action-error']}`,
                      'grid content-center justify-items-center text-sm',
                    )}
                    onClick={item.onClickAction}
                    disabled={item.itemDisabled}
                  >
                    {item.actionItem}
                  </Button>
                )}
              </div>
            );
          })}
        />
      )}
    </div>
  );
};

export default CustomToaster;
