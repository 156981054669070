export enum WATCHLIST_TAB_KEY {
  CHARTS = 'charts',
  SUMMARY = 'summary',
  SECTORS_EQ = 'sectors_eq',
  SECTORS_FI = 'sectors_fi',
  MARKET_CAP = 'marketCap',
  GEOGRAPHY_EQ = 'geography_eq',
  GEOGRAPHY_FI = 'geography_fi',
  STYLE = 'style',
  STYLE_EQ = 'style_eq',
  STYLE_FI = 'style_fi',
  CORRELATION_MATRIX = 'correlationMatrix',
  ANNUALISED_PERFORMANCE = 'annualizedPerformance',
  CALENDAR_YEAR_PERFORMANCE = 'calendarYearPerformance',
  RISK = 'risk',
  INCOME = 'income',
  ESG = 'esg',
  GENDER_PAY_GAP = 'genderPayGap',
  CREDIT_QUALITY = 'creditQuality',
  ASSET_ALLOCATION = 'assetAllocation',
  MATURITY = 'maturity',
  EQUITY_STATS = 'equityStats',
  CURRENCY = 'currency',
}
