// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZbBb77cGbnjbyXya5cDZ {\n  -ms-scroll-snap-type: x mandatory;\n      scroll-snap-type: x mandatory;\n}\n.T8OS2lXx5JXALww8Gn7F {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 15px;\n}\n.YUEPPsl5RYvS9htwh5fq {\n  border-radius: 9999px !important;\n  border-width: 0px !important;\n  font-size: 0.75rem !important;\n  line-height: 1rem !important;\n  transition: 0.5s;\n}\n.YUEPPsl5RYvS9htwh5fq:hover {\n  background-color: #1683fc;\n}\n.YUEPPsl5RYvS9htwh5fq:hover .q2ohzo4g73Vy_rVxINWV {\n  color: white;\n  stroke: white;\n}\n.q2ohzo4g73Vy_rVxINWV {\n  border-radius: 9999px;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  stroke-width: 50;\n  stroke: #545675;\n  color: #545675;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/Summary/style.module.less"],"names":[],"mappings":"AAAA;EACE,iCAAA;MAAA,6BAAA;AACF;AAEE;EAAA,aAAA;EAAA,yBAAA;EACA;AADA;AAIA;EAAA,gCAAA;EAAA,4BAAA;EAAA,6BAAA;EAAA,4BAAA;EACA;AADA;AAGF;EACE,yBAAA;AACF;AAFA;EAGI,YAAA;EACA,aAAA;AAEJ;AAGE;EAAA,qBAAA;EAAA,kBAAA;EAAA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA;AAHA","sourcesContent":[".horizontal-scroll-snap {\n  scroll-snap-type: x mandatory;\n}\n.summary-arrows {\n  @apply flex justify-end;\n  margin-bottom: 15px;\n}\n.btn-arrows {\n  @apply rounded-full text-xs border-0 !important;\n  transition: 0.5s;\n}\n.btn-arrows:hover {\n  background-color: #1683fc;\n  .icon-bold {\n    color: white;\n    stroke: white;\n  }\n}\n\n.icon-bold {\n  @apply text-xs rounded-full;\n  stroke-width: 50;\n  stroke: #545675;\n  color: #545675;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontal-scroll-snap": "ZbBb77cGbnjbyXya5cDZ",
	"summary-arrows": "T8OS2lXx5JXALww8Gn7F",
	"btn-arrows": "YUEPPsl5RYvS9htwh5fq",
	"icon-bold": "q2ohzo4g73Vy_rVxINWV"
};
export default ___CSS_LOADER_EXPORT___;
