import { setupWatchlistInterceptor } from '@aminsights/contract';
import { useEffect } from 'react';

import useWatchlists from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';

const WatchlistGuard: React.FCWithChild = ({ children }) => {
  const { isLoading, data } = useWatchlists(); // This needs to be fired because it creates a new watchlist if none

  const currentWatchlist = data?.[0];
  const currentWatchlistId = currentWatchlist?.watchlist._id ?? null;

  useEffect(() => {
    if (!currentWatchlistId) return;
    setupWatchlistInterceptor(() => currentWatchlistId);
  }, [currentWatchlistId]);

  if (!currentWatchlistId || isLoading) {
    return null;
  }

  return <>{children}</>;
};

export default WatchlistGuard;
