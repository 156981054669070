import { SearchApi } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import useDebounce from '@/hooks/useDebounce';
import { openApiConfig } from '@/utils';

const useAutocompleteQuery = (term: string) => {
  const api = new SearchApi(openApiConfig());
  const query = useQuery(
    ['searchAutocomplete', term],
    async () => {
      const response = await api.getSearchAutocomplete(term);
      return response.data;
    },
    {
      keepPreviousData: true, // This is what avoids the loading indicator from appearing when the user types
    },
  );
  return query;
};

const useSearch = () => {
  const [fullTerm, setFullTerm] = useState<string>('');
  const debouncedValue = useDebounce(fullTerm, 300);
  const autoCompleteQuery = useAutocompleteQuery(debouncedValue ?? '');

  return {
    autoCompleteQuery: {
      ...autoCompleteQuery,
      data: autoCompleteQuery.data || [],
      isLoading: fullTerm !== debouncedValue || autoCompleteQuery.isLoading,
    },
    fullTerm,
    setFullTerm,
  };
};

export type UseSearchReturn = ReturnType<typeof useSearch>;
export type AutocompleteQuery = UseSearchReturn['autoCompleteQuery'];
export default useSearch;
