// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IPXazsrdgiC9ylbVkuMU {\n    padding-top: 0.25rem;\n    padding-bottom: 0.25rem\n}\n.IPXazsrdgiC9ylbVkuMU .afWOu8DVqrHpcTnQ6JQb {\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    --tw-text-opacity: 1;\n    color: rgb(84 85 118 / var(--tw-text-opacity))\n}\n.IPXazsrdgiC9ylbVkuMU .gCwLPjJBJHrX0Yb6qEig {\n    display: flex;\n    justify-content: flex-end;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    --tw-text-opacity: 1;\n    color: rgb(84 85 118 / var(--tw-text-opacity))\n}\n", "",{"version":3,"sources":["webpack://./src/components/BoardList/style.module.less"],"names":[],"mappings":"AACE;IAAA,oBAAA;IAAA;AAAA;AAEE;IAAA,mBAAA;IAAA,oBAAA;IAAA,oBAAA;IAAA;AAAA;AAGA;IAAA,aAAA;IAAA,yBAAA;IAAA,mBAAA;IAAA,oBAAA;IAAA,oBAAA;IAAA;AAAA","sourcesContent":[".board-list {\n  @apply py-1;\n  .label {\n    @apply text-neutral text-sm;\n  }\n  .percentage {\n    @apply ~'flex' justify-end text-neutral text-sm;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"board-list": "IPXazsrdgiC9ylbVkuMU",
	"label": "afWOu8DVqrHpcTnQ6JQb",
	"percentage": "gCwLPjJBJHrX0Yb6qEig"
};
export default ___CSS_LOADER_EXPORT___;
