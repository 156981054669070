import { Fund } from '@aminsights/contract';
import { LIMIT_FUNDS_FOR_CHARTING } from '@aminsights/shared';
import { StarFilled } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'antd';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as IconEyeSolid } from '@/assets/svg/icons/icon-eye-solid.svg';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { IShareClassDetailsResponseData } from '@/partials/shareClassTable/data';

import style from './style.module.less';

interface Props {
  fund: Fund | IShareClassDetailsResponseData;
  selectedItems: string[];
  onClick: (items: string[]) => void;
  dataTableRenderedAt?: DataTableRenderedAt;
  showPrimaryShareClassIndicator?: boolean;
}

const isFund = (item: Fund | IShareClassDetailsResponseData): item is Fund => {
  if ('isin' in item) {
    return false;
  }
  return true;
};

const TableCheckbox = ({
  fund,
  selectedItems,
  onClick,
  dataTableRenderedAt,
  showPrimaryShareClassIndicator,
}: Props): JSX.Element => {
  const currentWatchlist = useCurrentWatchlist();
  const isin = isFund(fund) ? fund.shareClassDetails.isin : fund.isin;
  const allFundsInWatchlist =
    currentWatchlist.data?.buckets.flatMap(b => b.funds ?? []) ?? [];
  const isWatching = allFundsInWatchlist.some(f => f.isin === isin);

  const isChecked = selectedItems.includes(`${isin}`);
  const isDisabled =
    selectedItems &&
    selectedItems.length === LIMIT_FUNDS_FOR_CHARTING &&
    !selectedItems.includes(`${isin}`);
  const hasAnotherShareClassInTheCurrentWatchlist =
    !isWatching && allFundsInWatchlist.some(f => f.fundId === fund.fundId);
  const isExplorePage = dataTableRenderedAt === DataTableRenderedAt.Explore;
  return (
    <div
      className={`flex items-center gap-x-2 relative -left-4`}
      onClick={e => e.stopPropagation()}
    >
      <Checkbox
        key={`${isin}`}
        onClick={() => onClick([`${isin}`])}
        checked={isChecked}
        disabled={isDisabled}
        className={
          isExplorePage ? style['body-checkbox'] : style['header-checkbox']
        }
      />
      <div
        className={`flex flex-col gap-y-1 items-center justify-center relative -left-4`}
      >
        {(isWatching || hasAnotherShareClassInTheCurrentWatchlist) &&
          dataTableRenderedAt !== DataTableRenderedAt.Watchlist && (
            <Tooltip
              overlayClassName="cursor-auto"
              title={
                !hasAnotherShareClassInTheCurrentWatchlist
                  ? 'Watching'
                  : 'Another share class is in a bucket'
              }
              placement="bottom"
            >
              <IconEyeSolid
                className={cx('icon text-base', {
                  [style['grey-color']]:
                    hasAnotherShareClassInTheCurrentWatchlist,
                })}
              />
            </Tooltip>
          )}
        {fund.primaryShareClass && showPrimaryShareClassIndicator && (
          <StarFilled
            style={{
              color: '#0072E6',
              opacity: fund?.primaryShareClass ? '1' : '0',
              fontSize: '16px',
            }}
          />
        )}
      </div>
    </div>
  );
};

interface TableCheckboxHeaderProps {
  onClick: (isins: string[]) => void;
  checked?: boolean;
  topISINs: string[];
}

export const TableCheckboxHeader = ({
  onClick,
  topISINs,
  checked,
}: TableCheckboxHeaderProps): JSX.Element => {
  return (
    <div className="relative -left-4" onClick={e => e.stopPropagation()}>
      <Checkbox
        onClick={(event: React.MouseEvent<HTMLInputElement>) => {
          const isChecked = event.currentTarget.checked;
          onClick([]);
          if (isChecked) {
            onClick(topISINs);
          }
          event.stopPropagation();
        }}
        className={style['header-checkbox']}
        checked={checked}
      />
    </div>
  );
};

export default TableCheckbox;
