import { Dropdown, Layout } from 'antd';
import React from 'react';

import { ReactComponent as Logo } from '@/assets/svg/logo.svg';
import { Avatar } from '@/components';

import NavbarProfileMenu from './NavbarProfileMenu';
import style from './style.module.less';

const { Header } = Layout;

const NavbarSimple: React.FCWithChild = () => {
  return (
    <div className={style['simple-navbar-wrapper']}>
      <Header>
        <div className="w-11/12 flex justify-between">
          <div className="flex items-center justify-start">
            <div className={style['navbar-logo']}>
              <Logo />
            </div>
          </div>
          <div className={style['icons-wrapper']}>
            <Dropdown
              overlay={() => <NavbarProfileMenu />}
              trigger={['click']}
              placement="bottomRight"
              className={style['navbar-profile']}
            >
              <div>
                <Avatar type="user-avatar" color="sky-blue">
                  JG
                </Avatar>
              </div>
            </Dropdown>
          </div>
        </div>
      </Header>
    </div>
  );
};

export default NavbarSimple;
