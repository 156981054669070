import {
  Fund,
  FundBroadAssetClass,
  ItemsSectorDetail,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import { isFixedIncomeBroadAssetClass } from '@aminsights/shared';
import React, { useMemo, useState } from 'react';

import SectorEquityLegend from '@/components/SectorEquityLegend';
import { DataTable } from '@/components/Table';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import { generateSectorFundDetailsColumn } from './columns';

//Keep sectorsField in sync with fund metadata model
type SectorsField = 'sectorsEquity' | 'sectorsFixedIncome';
import { sortArray } from '@/utils/array';
import { sectorLegendList } from '@/utils/sectorLegendList';

const formSectorData = (
  fund: Pick<Fund, SectorsField>,
  sectorsField: SectorsField,
): ItemsSectorDetail[] => {
  return [...(fund[sectorsField] ?? [])].sort((a, b) =>
    a.value < b.value ? 1 : -1,
  );
};

interface SectorTableProps {
  isPadi: boolean;
  broadAssetClass: FundBroadAssetClass;
}

const SectorTable: React.FCWithChild<SectorTableProps> = ({
  isPadi,
  broadAssetClass,
}: SectorTableProps) => {
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const { data } = useFundByIsInParam();
  const fund = data?.fund;
  const isFixedIncome = isFixedIncomeBroadAssetClass(broadAssetClass);
  const isEquityBroadAssetClass =
    broadAssetClass === FundBroadAssetClass.Equity;

  const columns = useMemo(() => {
    const filteredColumns = generateSectorFundDetailsColumn(
      !!isInvestmentTrust,
      !isFixedIncome,
      isEquityBroadAssetClass,
      isPadi,
    );
    return filteredColumns.filter(column => {
      if (column.isVisible && fund) {
        return column.isVisible(fund);
      }
      return true;
    });
  }, [fund, isInvestmentTrust]);

  let sectorsField: SectorsField | undefined;
  switch (broadAssetClass) {
    case FundBroadAssetClass.Equity:
      sectorsField = 'sectorsEquity';
      break;
    case FundBroadAssetClass.FixedIncome:
      sectorsField = 'sectorsFixedIncome';
      break;
  }

  if (isPadi) {
    sectorsField = 'sectorsEquity';
  }

  const [sectorDetails, setSectorDetails] = useState<ItemsSectorDetail[]>(
    fund && sectorsField ? formSectorData(fund, sectorsField) : [],
  );

  const onSortHandler = (
    key: keyof ItemsSectorDetail,
    direction: PageQueryParametersSortDirectionEnum,
  ) => {
    if (key.toString() === 'sortByLegend') {
      /* The `type` parameter in the `findSectorIndex` function is a number that
       is used to search for a sector in the `sectorLegendList` array based on the `type` property of
       each sector object. The function will return the index of the sector in the array that matches
       the specified type.
       */
      const findSectorIndex = (type: number): number => {
        return sectorLegendList.findIndex(sector => sector.type.includes(type));
      };

      sectorDetails.sort((a, b) => {
        const indexA = findSectorIndex(a.type);
        const indexB = findSectorIndex(b.type);

        /* This code block is handling the sorting logic for the sector details based on the
        `sortByLegend` key. Here's a breakdown of what each part is doing: */
        if (indexA === indexB) {
          // If types are equal, sort by name
          return a.name.localeCompare(b.name);
        }
        if (direction === PageQueryParametersSortDirectionEnum.Asc) {
          // If types are not equal, sort by type in ascending order
          return indexA - indexB;
        }
        return indexB - indexA;
      });

      setSectorDetails([...sectorDetails]);
    } else {
      const sorted = sortArray(
        sectorDetails ?? [],
        key as keyof ItemsSectorDetail,
        direction,
      );
      setSectorDetails(sorted);
    }
  };

  return (
    <div
      className="bg-white"
      data-test-id={`fundDetailsPortfolioTab${sectorsField}Table`}
    >
      {isEquityBroadAssetClass && !isPadi && (
        <div className="flex items-center justify-end py-2">
          <SectorEquityLegend className="gap-8 pr-8 lg:pr-4" />
        </div>
      )}
      <DataTable
        uniqueKey="name"
        columns={columns}
        data={sectorDetails}
        noDataLabel="No portfolio data provided"
        onSort={(
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => onSortHandler(key as keyof ItemsSectorDetail, direction)}
      />
    </div>
  );
};

export default SectorTable;
