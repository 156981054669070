import dayjs from 'dayjs';

import { CHARTS_YAXIS_SECTION_AMOUNT_MAX } from '@/constants/misc';
import { BaseCardDetails } from '@/partials/LegendCards';

export const DEFAULT_PERIOD = '30D';

export interface PerformanceCardDetails extends BaseCardDetails {
  value: string;
}

export interface PerformanceCardDetailsWithPortfolio
  extends PerformanceCardDetails {
  isPortfolio?: boolean;
}

export const rollbackDayIfWeekend = (date: dayjs.Dayjs) => {
  const dayOfWeek = date.day();

  if (dayOfWeek === 6) {
    return date.subtract(1, 'days');
  }

  if (dayOfWeek === 0) {
    return date.subtract(2, 'days');
  }

  return date;
};

export const calculateStepTickForYAxis = (minMaxValues: {
  min: number;
  max: number;
}) => {
  let step = 1;
  const valueRangeAmount = Math.abs(
    (minMaxValues.max ?? 0) - (minMaxValues.min ?? 0),
  );
  if (valueRangeAmount > CHARTS_YAXIS_SECTION_AMOUNT_MAX) {
    step = Math.round(valueRangeAmount / CHARTS_YAXIS_SECTION_AMOUNT_MAX);
  }
  return step;
};
