import React, { useEffect, useState } from 'react';

import { ReactComponent as AddIcon } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { ReactComponent as IconWarning } from '@/assets/svg/icons/icon-warning.svg';
import Button from '@/components/Button';
import HorizontalScroll from '@/components/HorizontalScroll';
import Popover from '@/components/Popover';
import BucketDefault from '@/partials/Bucket/Default';
import Modal from '@/partials/Modal';

import { buckets } from './data';
import style from './style.module.less';

type CreateBucketsProps = {
  handleBucketLoading: (value: boolean) => void;
};

const CreateBuckets: React.FCWithChild<CreateBucketsProps> = ({
  handleBucketLoading,
}) => {
  const [instructionStep, setInstructionStep] = useState(0);

  const step1 = (
    <div className="popover-wrapper">
      <div className="option">
        <span className="instruction-option">
          Drag funds/trusts into buckets
        </span>
        <div className="image-wrapper">
          <div className="bucket-img">
            <span>Bucket 1</span>
            <img src="/assets/svg/toolTip/bucket-1.svg" alt="bucket-1" />
          </div>
          <div className="bucket-img">
            <span>Bucket 2</span>
            <img src="/assets/svg/toolTip/bucket-2.svg" alt="bucket-2" />
          </div>
        </div>
      </div>
      <div className="option">
        <span className="instruction-option">
          Or move using the action button
        </span>
        <div className="image-wrapper">
          <div className="bucket-img">
            <img src="/assets/svg/toolTip/bucket-big.svg" alt="bucket-big" />
          </div>
        </div>
      </div>
      <div className="footer-control">
        <span className="footer-control__option">
          Step <b>{instructionStep}</b> of <b>3</b>
        </span>
        <Button
          className="steps"
          // type="ghost"
          type="link"
          onClick={() => setInstructionStep(instructionStep + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );

  const step2 = (
    <div className="popover-wrapper">
      <div className="option">
        <span className="instruction-option">
          Prioritize your buckets form left to right
        </span>
        <div className="image-wrapper">
          <div className="bucket-img">
            <span>Bucket 1</span>
            <img
              src="/assets/svg/toolTip/bucket-1-big.svg"
              alt="bucket-1-large-3-lines"
            />
          </div>
          <div className="bucket-img">
            <span>Bucket 2</span>
            <img
              src="/assets/svg/toolTip/bucket-2-big.svg"
              alt="bucket-2-large-2-lines"
            />
          </div>
        </div>
      </div>
      <div className="option">
        <span className="instruction-option">
          Or change bucket order using the action button
        </span>
        <div className="image-wrapper">
          <div className="bucket-img">
            <img
              src="/assets/svg/toolTip/bucket-dropdown-large.svg"
              alt="bucket-dropdown-large"
            />
          </div>
        </div>
      </div>
      <div className="footer-control">
        <span className="footer-control__option">
          Step <b>{instructionStep}</b> of <b>3</b>
        </span>
        <Button
          className="steps"
          // type="ghost"
          onClick={() => setInstructionStep(instructionStep + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );

  const step3 = (
    <div className="popover-wrapper">
      <div className="option">
        <span className="instruction-option">
          View your buckets easily with this option.
        </span>
      </div>
      <div className="footer-control">
        <span className="footer-control__option">
          Step <b>{instructionStep}</b> of <b>3</b>
        </span>
        <Button
          className="steps"
          // type="ghost"
          onClick={() => setInstructionStep(0)}
        >
          Got it
        </Button>
      </div>
    </div>
  );

  const renderBuckets = buckets.map((bucket, index) => (
    <div key={bucket.name} className="mr-4">
      <Popover
        visible={index === 0 && instructionStep === 2}
        placement="right"
        trigger="click"
        color="#3E414B"
        content={step2}
        overlayClassName="pop-over-step-2"
      >
        <div style={{ width: '320px', minHeight: '490px' }}>
          {/* TODO: this component seem to be completely unused and hanging here. Potentiallyeed to cleanup this
          together with packages/frontend/src/pages/onboarding section? */}
          <BucketDefault
            id={''}
            funds={[]}
            order={bucket.order}
            name={bucket.name}
            index={bucket.index}
          />
        </div>
      </Popover>
    </div>
  ));

  const [isLoadingData, setLoadingData] = useState(true);
  useEffect(() => {
    handleBucketLoading(true);
    const timer = setTimeout(() => {
      handleBucketLoading(false);
      setLoadingData(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isLoadingData]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <section className={style['create-buckets']}>
      <Modal
        isVisible={isModalVisible}
        toggleModal={toggleModal}
        className="modal onboarding-modal"
      >
        <div className="clear-funds-wrap">
          <IconWarning className="icon icon-wrarning" />
          <h2 className="font-bold text-xl mb-4">Reset Buckets</h2>
          <p className="mb-5">
            Are you sure you want to start from scratch assigning
            <br />
            your funds/trusts to buckets?
          </p>
          <Button
            className="reset-btn max-w-xs"
            type="primary"
            danger
            onClick={toggleModal}
          >
            Reset buckets
          </Button>
          <Button
            className="cancel-btn max-w-xs"
            type="default"
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      {isLoadingData ? (
        <div className={style['create-buckets__body']}>
          <div className={style['create-buckets__body-heading']}>
            <h2>Generating Buckets</h2>
          </div>
          <div className={style['create-buckets__body-divider']} />
          <div className={style['create-buckets__body-content']}>
            <p>
              Buckets are used to categorise your funds/trusts so you can easily
              view and compare them in AM Insights. Take the time to review and
              edit them if necessary.
            </p>
            <HorizontalScroll style={{ flex: 1 }}>
              {renderBuckets}
            </HorizontalScroll>
          </div>
        </div>
      ) : (
        <div className={style['create-buckets__body']}>
          <div className={style['create-buckets__body-heading']}>
            <h2>Your Buckets (4)</h2>
            <div className={style['create-buckets__body-heading-actions']}>
              <Popover
                visible={instructionStep === 3}
                placement="left"
                trigger="click"
                color="#3E414B"
                content={step3}
                overlayClassName="pop-over-step-3"
              >
                <Button
                  htmlType="button"
                  type="link"
                  className="font-semibold pl-1"
                >
                  View All
                </Button>
              </Popover>
              <Button
                htmlType="button"
                type="primary"
                className="font-semibold"
                icon={<AddIcon className="icon w-2.5 h-2.5 mr-2" />}
              >
                Add Bucket
              </Button>
            </div>
          </div>
          <div className={style['create-buckets__body-divider']} />
          <div className={style['create-buckets__body-content']}>
            <div className={style['header-menu']}>
              You can drag funds/trusts between buckets, and move the order of
              the buckets. Maximum of 11 funds/trusts per bucket.&nbsp;
              <Popover
                visible={instructionStep === 1}
                placement="right"
                trigger="click"
                color="#3E414B"
                content={step1}
                overlayClassName="pop-over-step-1"
              >
                <Button
                  className="start-step"
                  type="link"
                  onClick={() => setInstructionStep(1)}
                >
                  <InfoIcon className="icon icon-info" />
                </Button>
              </Popover>
              <Button
                onClick={toggleModal}
                htmlType="button"
                className="font-regular text-neutral border-none shadow-none"
              >
                &nbsp;<u>Reset bucket</u>&nbsp;
              </Button>
              if you want to start from scratch.
            </div>
            <HorizontalScroll style={{ flex: 1 }}>
              {renderBuckets}
            </HorizontalScroll>
          </div>
        </div>
      )}
    </section>
  );
};

export default CreateBuckets;
