import { APP_ROUTE_ANNOUNCEMENTS } from '@aminsights/shared/dist';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { SubRouter } from './index';
import { ANNOUNCEMENT_ROUTES } from './routes';

const AnnouncementRouter: React.FCWithChild = () => (
  <SubRouter routes={ANNOUNCEMENT_ROUTES} rootPath={APP_ROUTE_ANNOUNCEMENTS}>
    <Route
      exact
      path={`/${APP_ROUTE_ANNOUNCEMENTS}`}
      render={() => <Redirect to={`/${APP_ROUTE_ANNOUNCEMENTS}/my-list`} />}
    />
  </SubRouter>
);

export default AnnouncementRouter;
