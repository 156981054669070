import { DATE_FORMAT_DASHED } from '@aminsights/shared';
import dayjs from 'dayjs';

export const getLatestDateForNewPortfolioColumn = (
  allDates: string[],
  dayJsDate: dayjs.Dayjs,
) => {
  const nextDateAfterLatest = dayjs(allDates[1], DATE_FORMAT_DASHED).add(
    1,
    'day',
  );
  const todayDate = dayjs();
  const defaultDate =
    nextDateAfterLatest.isValid() && todayDate.diff(nextDateAfterLatest) < 0
      ? nextDateAfterLatest
      : dayjs();
  const dateValue = dayJsDate.isValid() ? dayJsDate : defaultDate;

  return dateValue;
};
