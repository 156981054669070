export enum APP_ACTIONS {
  SET_WIDTH = 'app:set-width',
  UNSET_MESSAGE = 'app:unset-message',
  SET_ERROR_MESSAGE = 'app:set-error-message',
  SET_REGULAR_MESSAGE = 'app:set-regular-message',
  SET_SUCCESS_MESSAGE = 'app:set-success-message',
  DROP_MESSAGE = 'app:drop-message',
}

export const MESSAGE_STATE = {
  NONE: 'NONE',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
  REGULAR: 'REGULAR',
} as const;

export type MessageState = keyof typeof MESSAGE_STATE;
