import React, { useEffect, useMemo, useState } from 'react';

import { DataTable } from '@/components';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
  useMultipleFundsByIsins,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import {
  HoldingDetailWithFundDetails,
  holdingsColumns,
} from '@/pages/app/FundAndInvestmentTrust/components/Portfolio/HoldingsTable/columns';

const HoldingsTable: React.FCWithChild = () => {
  const { data, isLoading } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const fund = data?.fund;
  const [holdingIsins, setHoldingIsins] = useState<string[]>([]);
  const { data: multipleFundsData, isLoading: multipleFundsLoading } =
    useMultipleFundsByIsins(holdingIsins);

  useEffect(() => {
    if (fund?.holdingDetail) {
      setHoldingIsins(
        fund.holdingDetail
          .filter(h => h.isin)
          .map(
            i =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              i.isin!,
          ),
      );
    }
  }, [fund]);

  const result: HoldingDetailWithFundDetails[] =
    fund?.holdingDetail?.map(h => {
      const existingFund = multipleFundsData?.find(
        m => m.shareClassDetails.isin === h.isin,
      );
      return { ...h, fund: existingFund };
    }) ?? [];

  const columns = useMemo(() => {
    const hasHoldingDetailsForIsin = multipleFundsData?.length !== 0;
    const filteredColumns = fund
      ? holdingsColumns(
          fund.broadAssetClass,
          hasHoldingDetailsForIsin,
          isInvestmentTrust,
        )
      : [];
    return filteredColumns.filter(column => {
      if (column.isVisible && fund) {
        return column.isVisible(fund);
      }
      return true;
    });
  }, [fund, multipleFundsData, isInvestmentTrust]);

  return (
    <div
      className="bg-white rounded-lg"
      data-test-id="fundDetailsPortfolioTabHoldingsTable"
    >
      <DataTable
        uniqueKey="id"
        columns={columns}
        loading={isLoading || multipleFundsLoading}
        data={result}
        noDataLabel="No portfolio data provided"
      />
    </div>
  );
};

export default HoldingsTable;
