import { PageQueryParametersSortDirectionEnum } from '@aminsights/contract';
import cx from 'classnames';
import * as _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BREAKPOINTS } from '@/constants';
import { LEGEND_TYPE_ENUM } from '@/constants';
import { useAppContext } from '@/context/AppContext';

import { tableLoaderData } from './data';
import style from './style.module.less';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import TableHeaderWithChildren from './TableHeaderWithChildren';
import TableRow from './TableRow';
import TableSubHeader from './TableSubHeader';

export interface IDataTableColumns {
  title: string | React.ReactElement;
  minHeight?: string;
  idSubText?: string;
  headerElements?: (...args: any[]) => React.ReactNode;
  headerCheckbox?: (...args: any[]) => React.ReactNode;
  regenerateColumnsClick?: (currentColumns: IDataTableColumns[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  backgroundColor?: (...args: any[]) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (...args: any[]) => React.ReactNode;
  // sorter is the priority over onSort, if present. It sorts only local data without api requests
  sorter?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    compare(...args: any[]): any;
    multiple?: number;
  };
  sortKey?: string;
  type?: 'action';
  className?: string;
  width?: number;
  align?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: Array<IDataTableColumns>;
  isColumnFixed?: boolean;
  tooltipText?: React.ReactNode;
  defaultSortOrder?: 'descend' | 'ascend';
  loader?: React.ReactNode;
  renderType: 'text' | 'number' | 'custom';
  minWidth?: number;
}

interface Props<T> {
  columns: Array<IDataTableColumns>;
  enableBorders?: boolean;
  disableVerticalRowPadding?: boolean;
  data?: Array<T>;
  footerData?: Array<IDataTableColumns>;
  emptyState?: React.ReactChild | null | boolean;
  loading?: boolean;
  minHeight?: number;
  onSort?: (
    key: string,
    sortDirection: PageQueryParametersSortDirectionEnum,
  ) => void;
  computeRowStyle?: (rowData: T) => React.CSSProperties;
  uniqueKey: string;
  disabled?: boolean;
  className?: string;
  noDataLabel?: string;
  loaderSize?: number;
  children?: React.ReactNode;
  onRow?: (id: string, event: React.MouseEvent<HTMLTableRowElement>) => void;

  tableHeaderClassName?: string;
  tableBodyClassName?: string;
  tableRowItemClassName?: string;
  initialSorting?: {
    sortKey: string;
    sortDirection: PageQueryParametersSortDirectionEnum;
  };
  enableHeaderWrap?: boolean;
  lastElementRef?: (node: HTMLTableSectionElement) => void;
  legendType?: LEGEND_TYPE_ENUM;
}

const getColumnAlignment = (
  renderType: IDataTableColumns['renderType'],
  alignment?: string,
) => {
  if (renderType === 'custom') {
    return alignment;
  }
  return renderType === 'number' ? 'right' : 'left';
};

const Table = <T,>({
  columns,
  data,
  footerData,
  loading,
  minHeight,
  onSort,
  uniqueKey,
  disabled,
  emptyState,
  className,
  noDataLabel = 'No data',
  computeRowStyle,
  loaderSize,
  children,
  onRow,
  enableBorders = false,
  disableVerticalRowPadding = false,
  tableHeaderClassName,
  tableBodyClassName,
  tableRowItemClassName,
  initialSorting,
  enableHeaderWrap = false,
  lastElementRef,
  legendType,
}: Props<T>): React.ReactElement => {
  const { app } = useAppContext();
  const [mapColumns, setMapColumns] = useState<Array<IDataTableColumns>>([]);
  const [footer, setFooter] = useState<Array<IDataTableColumns>>([]);
  const [hasSubHeader, setHasSubHeader] = useState<boolean>(false);

  const [actionColumnIndex, setActionColumnIndex] = useState<number>(-1);
  const [isEndLeft, setIsEndLeft] = useState<boolean>(false);
  const [isEndRight, setIsEndRight] = useState<boolean>(false);
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const fixedColumnRef = useRef<HTMLTableCellElement>(null);
  const [fixedWidth, setFixedWidth] = useState<number>(0);
  const [currentSort, setCurrentSort] = useState<string | undefined>(
    initialSorting ? initialSorting.sortKey : undefined,
  );

  useMemo(() => {
    const index = columns.findIndex(({ type }) => type && type === 'action');
    setActionColumnIndex(index);
  }, [columns]);

  const handleColumns = (width: number) => {
    if (width < BREAKPOINTS.SM && actionColumnIndex >= 0) {
      const newColumns = columns.filter(
        ({ type }) => !type || type !== 'action',
      );
      const actionItem = columns[actionColumnIndex];
      setMapColumns([actionItem, ...newColumns]);
    } else {
      setMapColumns(columns);
    }
  };

  const handleFixedColumn = useCallback(() => {
    if (fixedColumnRef?.current) {
      setFixedWidth(fixedColumnRef.current.clientWidth);
    }
  }, []);

  const handleTableShadow = useCallback(() => {
    if (tableWrapperRef?.current) {
      const { scrollWidth, offsetWidth, scrollLeft } = tableWrapperRef.current;
      setIsEndRight(offsetWidth + scrollLeft >= scrollWidth);
      setIsEndLeft(scrollLeft <= 0);
    }
  }, []);

  const handleScroll = () => {
    handleTableShadow();
  };

  const calculateLeft = () => {
    let left: number | undefined = undefined;
    const windowWidth = app?.windowWidth || window.innerWidth;
    const ACTION_WIDTH_MOBILE = 40;
    const PADDING_LEFT = 20;

    if (windowWidth >= BREAKPOINTS.SM) {
      left = fixedWidth - PADDING_LEFT;
    }

    if (windowWidth < BREAKPOINTS.SM - 1 && actionColumnIndex >= 0) {
      left = fixedWidth + ACTION_WIDTH_MOBILE - PADDING_LEFT;
    }

    if (windowWidth < BREAKPOINTS.SM - 1) {
      left = fixedWidth - PADDING_LEFT;
    }
    return left;
  };

  const getTableBody = useCallback(() => {
    if (data?.length) {
      return (
        <tbody className={tableBodyClassName}>
          {data.map(item => {
            let key = '';
            // You can either pass uniqueKey as a singlekey name 'isin' or a fallback key name 'isin || fundId':
            // If isin is not found on an item, it picks fundId. An important note:
            // 1. It can have multiple ' || 'with spaces around it.
            const uniqueKeys = uniqueKey.split(' || ');
            uniqueKeys.forEach(k => {
              const keyToSearchFor = k.trim();
              const tempValue = _.get(item, keyToSearchFor);
              if (tempValue) {
                key = tempValue;
              }
            });

            return (
              <TableRow
                key={`table-row-${key}`}
                id={`table-row-${key}`}
                uniqueKey={key}
                item={item}
                columns={mapColumns}
                isEndLeft={isEndLeft}
                hasAction={actionColumnIndex >= 0}
                onClick={(event: React.MouseEvent<HTMLTableRowElement>) =>
                  onRow?.(key, event)
                }
                rowStyle={computeRowStyle ? computeRowStyle(item) : undefined}
                disableVerticalRowPadding={disableVerticalRowPadding}
                className={tableRowItemClassName}
              />
            );
          })}
        </tbody>
      );
    }
    return (
      <tbody className={tableBodyClassName}>
        <tr>
          <td className="p-4 text-center" colSpan={columns.length}>
            {emptyState || noDataLabel}
          </td>
        </tr>
      </tbody>
    );
  }, [
    data,
    isEndLeft,
    mapColumns,
    computeRowStyle,
    tableRowItemClassName,
    disableVerticalRowPadding,
    actionColumnIndex,
  ]);

  useEffect(() => {
    if (columns?.length) {
      setHasSubHeader(
        columns.some((item: IDataTableColumns) => item?.children),
      );
    }
  }, [columns]);

  useEffect(() => {
    if (footerData?.length) {
      setFooter(footerData);
    }
  }, [footerData]);

  useEffect(() => {
    const windowWidth = app?.windowWidth || window.innerWidth;
    handleColumns(windowWidth);
    handleFixedColumn();
    handleTableShadow();
  }, [app?.windowWidth, columns]);

  useEffect(() => {
    handleFixedColumn();
  }, [fixedColumnRef?.current?.clientWidth]);

  return (
    <div
      style={{ minHeight: minHeight }}
      className={cx('w-full overflow-hidden relative')}
    >
      <div
        id="data-table"
        ref={tableWrapperRef}
        className={cx('w-full overflow-auto', {
          [style['table-wrapper']]: !!lastElementRef,
          [style['table-wrapper--esg-legend']]:
            legendType === LEGEND_TYPE_ENUM.ESG,
          [style['table-wrapper--extremum-legend']]:
            legendType === LEGEND_TYPE_ENUM.EXTREMUM,
        })}
        onScroll={handleScroll}
        data-test-id="dataTable"
      >
        <table
          className={cx(
            'w-full overflow-auto',
            style['table-custom'],
            className,
            {
              [style.disabled]: disabled,
              [style.bordered]: enableBorders,
            },
          )}
        >
          <thead className={cx('h-8 bg-grey-lighter', tableHeaderClassName)}>
            <tr>
              {mapColumns.map(column =>
                column.headerElements ? (
                  <TableHeaderWithChildren
                    ref={column?.isColumnFixed ? fixedColumnRef : undefined}
                    key={
                      !!column.idSubText
                        ? `table-row-subheader-wrapper-${column.title}-${
                            column.idSubText ?? 'end'
                          }`
                        : `table-row-subheader-wrapper-${column.title}`
                    }
                    title={column.title}
                    isFixed={column?.isColumnFixed}
                    id={column.idSubText}
                    width={column?.width}
                    className={column?.className}
                    align={getColumnAlignment(column.renderType, column.align)}
                    colSpan={column?.children?.length || 1}
                    tooltipText={column?.tooltipText}
                    loading={loading}
                    minWidth={column.minWidth}
                    onClick={() => {
                      if (column.regenerateColumnsClick) {
                        column.regenerateColumnsClick(mapColumns);
                      }
                    }}
                    children={column.headerElements()}
                  />
                ) : (
                  <TableHeader
                    ref={column?.isColumnFixed ? fixedColumnRef : undefined}
                    key={
                      !!column.idSubText
                        ? `table-row-subheader-wrapper-${column.title}-${
                            column.idSubText ?? 'end'
                          }`
                        : `table-row-subheader-wrapper-${column.title}`
                    }
                    isSortable={Boolean(column?.sortKey)}
                    title={column.title}
                    width={column?.width}
                    className={column?.className}
                    align={getColumnAlignment(column.renderType, column.align)}
                    colSpan={column?.children?.length || 1}
                    isFixed={column?.isColumnFixed}
                    type={column?.type}
                    enableWrap={enableHeaderWrap}
                    tooltipText={column?.tooltipText}
                    isEndLeft={isEndLeft}
                    hasAction={false} //  actionColumnIndex >= 0 TODO: add when bucket actions will be implemented
                    onSort={(
                      sortDirection: PageQueryParametersSortDirectionEnum,
                    ) => {
                      if (onSort && Boolean(column?.sortKey)) {
                        onSort(column.sortKey || '', sortDirection);
                        setCurrentSort(column.sortKey);
                      }
                    }}
                    loading={loading}
                    isSorting={currentSort === column.sortKey}
                    sortDirection={
                      column.sortKey === currentSort
                        ? initialSorting?.sortDirection
                        : undefined
                    }
                    minWidth={column?.minWidth}
                    headerCheckbox={
                      column.headerCheckbox && column.headerCheckbox()
                    }
                  />
                ),
              )}
            </tr>
            {hasSubHeader ? (
              <tr className="bg-white">
                {mapColumns.map(column => (
                  <TableSubHeader
                    key={
                      !!column.idSubText
                        ? `table-row-subheader-wrapper-${column.title}-${
                            column.idSubText ?? 'end'
                          }`
                        : `table-row-subheader-wrapper-${column.title}`
                    }
                    title={column.title}
                    items={column?.children}
                    isFixed={column?.isColumnFixed}
                    hasAction={actionColumnIndex >= 0}
                    isEndLeft={isEndLeft}
                    onSort={onSort}
                    currentSort={currentSort}
                    setCurrentSort={setCurrentSort}
                    isParentAction={column?.type === 'action'}
                    loading={loading}
                  />
                ))}
              </tr>
            ) : undefined}
          </thead>
          {loading && (
            <tbody className={tableBodyClassName}>
              {tableLoaderData.slice(0, loaderSize).map(key => (
                <TableRow
                  key={`table-row-loading-${key}`}
                  uniqueKey={`${key}`}
                  item={null}
                  columns={mapColumns}
                  isEndLeft={isEndLeft}
                  loading={loading}
                  hasAction={false}
                  disableVerticalRowPadding={disableVerticalRowPadding}
                />
              ))}
            </tbody>
          )}
          {!loading && getTableBody()}
          {footer && (
            <tfoot>
              <tr>
                {footer.length > 0 &&
                  footer.map(f => {
                    return (
                      <TableFooter
                        key={
                          !!f.idSubText
                            ? `table-row-footer-wrapper-${f.idSubText}`
                            : `table-row-footer-wrapper-${f.title}`
                        }
                        ref={f.isColumnFixed ? fixedColumnRef : undefined}
                        title={f.title}
                        isFixed={f.isColumnFixed}
                        colSpan={0}
                        align={f.align}
                        isEndLeft={false}
                        hasAction={false}
                      />
                    );
                  })}
              </tr>
            </tfoot>
          )}
          {lastElementRef && (
            <tfoot className={style['last-elementRef']} ref={lastElementRef} />
          )}
        </table>
      </div>
      <div className={style['table-shadow']}>
        {!isEndLeft ? (
          <div
            className={style['table-shadow--left']}
            style={{
              left: calculateLeft(),
            }}
          />
        ) : (
          ''
        )}
        {!isEndRight ? <div className={style['table-shadow--right']} /> : ''}
      </div>
      {children}
    </div>
  );
};

export default Table;
