import { setupAuthInterceptor } from '@aminsights/contract';
import { AUTH_ROUTE_ACCESS_DENIED } from '@aminsights/shared';
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import React, { createContext, useEffect, useState } from 'react';

interface IState {
  decodedToken: Record<string, string> & { permissions?: string[] };
}

interface IContext {
  state: IState;
}

export const AxiosAuthContext = createContext<IContext>({
  state: {
    decodedToken: {},
  },
});

const AxiosAuthProvider: React.FCWithChild = ({ children }) => {
  const [isAuthTokenSet, setAuthToken] = useState(false);
  const [decodedToken, setDecodedToken] = useState<Record<string, string>>({});

  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated } =
    useAuth0();
  const getToken = async () => {
    const token = await getAccessTokenSilently().catch(() => {
      const currentURL = new URL(window.location.href);
      const pathname = currentURL.pathname;
      const searchParams = new URLSearchParams(currentURL.search);

      const authError = searchParams.get('error');
      const authErrorDescription = searchParams.get('error_description');
      if (
        authError === 'access_denied' &&
        authErrorDescription === 'missing_tenant'
      ) {
        window.location.href = `${window.location.origin}/${AUTH_ROUTE_ACCESS_DENIED}`;
        return null;
      }

      const searchParamsString = searchParams.toString();
      localStorage.setItem(
        'returnTo',
        searchParamsString ? `${pathname}?${searchParamsString}` : pathname,
      );
      loginWithRedirect();
      return null;
    });
    if (token) {
      setDecodedToken(jwt_decode(token));
      setAuthToken(true);
    }
    return token;
  };
  useEffect(() => {
    getToken().then(_r => {
      setupAuthInterceptor(getToken);
    });
  }, [isAuthenticated]);

  useEffect(() => {
    const handleRedirectCallback = async () => {
      if (isAuthenticated) {
        const returnTo = localStorage.getItem('returnTo');
        if (returnTo) {
          localStorage.removeItem('returnTo');
          window.location.href = returnTo;
        }
      }
    };

    handleRedirectCallback();
  }, [isAuthenticated]);

  return (
    <AxiosAuthContext.Provider value={{ state: { decodedToken } }}>
      {isAuthTokenSet && children}
    </AxiosAuthContext.Provider>
  );
};

export { AxiosAuthProvider };
