import {
  DATE_PERIOD_FILTER,
  DISPLAY_DATE_FORMAT,
  EDateFilterValues,
} from '@aminsights/shared';
import { ScatterConfig } from '@ant-design/charts';
import SkeletonButton from 'antd/es/skeleton/Button';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import { Dropdown } from '@/components';
import useGetPortfolioRiskReturnPlotData from '@/hooks/query-hooks/chart-hooks/useGetPortfolioRiskReturnPlotData';
import { getErrorMessageFromResponse } from '@/hooks/query-hooks/errorHelpers';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { PortfolioChartsPropsV2 } from '@/partials/Charts/components/BaseChart';
import chartStyles from '@/partials/Charts/utils/chart.module.less';
import { useChartColorsById } from '@/partials/Charts/utils/useChartColors';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import ScatterPlotLegendCard from '@/partials/LegendCards/ScatterPlotLegendCard';

import { STROKE_GREY } from '../../Charts/utils/colors';
import BaseScatterPlot from '../components/BaseScatterPlot';
import { ScatterPlotCardDetails } from '../utils/scatter-plot-data';
import {
  PortfolioRiskReturnInvalidWeightingsError,
  PortfolioRiskReturnWeightingAppliedToDateWithNoDataError,
} from './PortfolioRiskReturnErrors';
import style from './style.module.less';

export const allowedPeriodValuesForRiskReturn = [
  EDateFilterValues['1YR'],
  EDateFilterValues['3YR'],
  EDateFilterValues['5YR'],
];

const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
  allowedPeriodValuesForRiskReturn.some(apv => apv === p.value),
);

const PortfolioRiskReturnScatterPlot: React.FCWithChild<
  PortfolioChartsPropsV2
> = ({ portfolioId }) => {
  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();
  const [legendData, setLegendData] = useState<ScatterPlotCardDetails[]>([]);

  const period = useMemo(() => {
    if (allowedPeriodValuesForRiskReturn.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const { data, isLoading, isError, error, isFetching } =
    useGetPortfolioRiskReturnPlotData(
      {
        portfolioId,
        period,
      },
      {
        enabled: !!portfolioId,
      },
    );
  const isInvalid = data?.isValid === false && !(isFetching || isLoading);

  if (isInvalid) {
    // eslint-disable-next-line no-console
    console.log(
      '[Temp log] Portfolio risk return is invalid, here are the issues: ',
      JSON.stringify(data?.issues, null, 2),
    );
  }

  const shouldShowError = isInvalid && portfolioId;
  const isWeightingsNotAvailableForPeriod = data?.issues?.[
    'errorCodes'
  ].includes('WEIGHTINGS_ARE_NOT_AVAILABLE_FOR_PERIOD');

  const weightingAppliedToDateWithNoData = data?.issues?.[
    'errorCodes'
  ].includes('WEIGHTING_APPLIED_TO_DATE_WITH_NO_DATA');

  const scatterPlotData = data?.chartData.data || [];
  const scatterPlotLegendData = data?.chartData.legendData || [];

  const legendIds = useMemo(() => {
    const ldClone = scatterPlotLegendData.filter(ld => !ld.isBenchmark);
    return (ldClone || [])
      .sort((a, b) => a?.label?.localeCompare(b?.label || '') || -1)
      .map(ld => ld.id || '');
  }, [scatterPlotLegendData]);

  const scatterPlotColors = useChartColorsById({
    legend: scatterPlotLegendData,
    ids: legendIds,
  });

  useEffect(() => {
    if (scatterPlotLegendData.length && scatterPlotColors) {
      setLegendData(
        scatterPlotLegendData.map(l => ({
          ...l,
          tooltip: l.label || '',
          standardDeviation: l.xValue || 0,
          totalReturnOverPeriod: l.yValue || 0,
          id: l.id || '',
          isBenchmark: l.isBenchmark || false,
          label: l.label || '',
          isFeatured: l.isFeatured || false,
          color: scatterPlotColors[l.id || ''] || '',
        })),
      );
    } else if (scatterPlotLegendData.length === 0 && legendData.length > 0) {
      setLegendData([]);
    }
  }, [data, scatterPlotColors]);

  const config: ScatterConfig = useMemo(
    () => ({
      className: 'custom-antd-scatter-plot',
      xField: '',
      yField: '',
      data: scatterPlotData.map(s => ({
        ...s,
        color: scatterPlotColors[s.id],
      })),
      colorField: 'color',
      color: data => {
        return data.color;
      },
    }),
    [scatterPlotData, scatterPlotColors],
  );

  const humanizedPeriod = useMemo(
    () => DATE_PERIOD_FILTER.find(fd => fd.value === period)?.label,
    [period],
  );

  const to = data?.queriedDateRange?.to;

  let errorMessage: string | undefined = undefined;
  if (error) {
    errorMessage = getErrorMessageFromResponse(error);
  }

  const hasNoWeightings = data?.issues?.['errorCodes'].includes(
    'PORTFOLIO_HAS_NO_WEIGHTINGS',
  );
  if (hasNoWeightings) {
    return <></>;
  }

  return (
    <div className="px-6">
      <div className={chartStyles.header}>
        <h5
          data-test-id="riskReturnChartLabel"
          className="text-sm font-bold text-darkest"
        >
          <Loader
            width="150px"
            loading={isLoading}
            component={<>Risk/Return</>}
          />
        </h5>
        <div className="w-full col-span-2 justify-self-end sm:col-span-1 sm:w-auto">
          {isLoading ? (
            <SkeletonButton className="w-40" active />
          ) : (
            <Dropdown
              rounded
              label="Select value"
              dataTestId="riskReturnChartDropdown"
              value={period}
              onSelect={(selectedValue: EDateFilterValues) => {
                setPeriod(parseDateValue(selectedValue), selectedValue);
              }}
              disabled={isLoading}
              items={allowedPeriodsForDropdown}
              defaultValue={period}
            />
          )}
          {humanizedPeriod && to && (
            <p className="text-xs md:text-right mt-2">
              <strong>{humanizedPeriod}</strong> as at{' '}
              <i>{dayjs(to).format(DISPLAY_DATE_FORMAT)}</i>
            </p>
          )}
        </div>
      </div>
      <div className="relative">
        {isWeightingsNotAvailableForPeriod && shouldShowError && (
          <PortfolioRiskReturnInvalidWeightingsError
            portfolioId={portfolioId}
            period={humanizedPeriod}
          />
        )}
        {weightingAppliedToDateWithNoData &&
          !isWeightingsNotAvailableForPeriod &&
          shouldShowError && (
            <PortfolioRiskReturnWeightingAppliedToDateWithNoDataError
              portfolioId={portfolioId}
            />
          )}
        <div>
          {!isLoading && (
            <div data-test-id="riskReturnChart">
              <BaseScatterPlot
                config={config}
                strokeColor={STROKE_GREY}
                onUpdateLegend={setLegendData}
              />
            </div>
          )}
          {!isLoading && isError && (
            <div className="text-center">
              <p className="text-xs text-danger">{errorMessage}</p>
            </div>
          )}
          {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
          <div
            data-test-id="riskReturnChartLegend"
            className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
          >
            <Loader
              row={2}
              width="150px"
              loading={isLoading}
              component={legendData
                .filter(f => f.label)
                .map(legendItem => (
                  <ScatterPlotLegendCard
                    key={legendItem.id}
                    label={legendItem.label}
                    tooltip={legendItem.tooltip || legendItem.label}
                    isIndex={legendItem.isBenchmark}
                    color={legendItem.color}
                    isFeatured={false}
                    idForFundDetailsLink={legendItem.isin}
                    isActive={legendItem.isActive}
                    totalReturnOverPeriod={legendItem.totalReturnOverPeriod}
                    standardDeviation={legendItem.standardDeviation}
                  />
                ))}
            />
          </div>
        </div>
      </div>
      {/* Divider is here because it is only on charts page */}
      <div className={style.divider} />
    </div>
  );
};

export default PortfolioRiskReturnScatterPlot;
