export const announcementsData = [
  {
    key: '1',
    investmentTrust: 'INPP - International Public Partnerships',
    announcement: 'Issue of Equity',
    date: '19 May 2021',
    time: '18:29',
  },
  {
    key: '2',
    investmentTrust: 'GRI - Grainger PLC',
    announcement: 'AGM Statement',
    date: '19 May 2021',
    time: '18:29',
  },
  {
    key: '3',
    investmentTrust: 'INPP - International Public Partnerships',
    announcement: 'Dividend Declaration',
    date: '19 May 2021',
    time: '18:29',
  },
  {
    key: '4',
    investmentTrust: 'GRI - Grainger PLC',
    announcement: 'Transaction in Own Shares',
    date: '19 May 2021',
    time: '18:29',
  },
  {
    key: '5',
    investmentTrust: 'INPP - International Public Partnerships',
    announcement: 'Dividend Declaration',
    date: '19 May 2021',
    time: '18:29',
  },
  {
    key: '6',
    investmentTrust: 'GRI - Grainger PLC',
    announcement: 'Transaction in Own Shares',
    date: '19 May 2021',
    time: '18:29',
  },
];

export const dateDropDownData = [
  {
    value: '',
    label: 'Date',
  },
  {
    value: 1,
    label: 'Last 7 days',
  },
  {
    value: 2,
    label: 'Last 30 days',
  },
  {
    value: 4,
    label: 'Last year',
  },
];

export const announcements = [
  {
    label: 'Annual Financial Report',
    checked: false,
  },
  {
    label: 'AGM Statement',
    checked: false,
  },
  {
    label: 'Portfolio Update',
    checked: false,
  },
  {
    label: 'Dividend Declaration',
    checked: false,
  },
  {
    label: 'Factsheet',
    checked: false,
  },
  {
    label: 'Half-year Report',
    checked: false,
  },
  {
    label: 'Issue of Equity',
    checked: false,
  },
  {
    label: 'Transaction in Own Shares',
    checked: false,
  },
  {
    label: 'Total Voting Rights',
    checked: false,
  },
  {
    label: 'Interim Report',
    checked: false,
  },
  {
    label: 'Holding(s) in Company',
    checked: false,
  },
  {
    label: 'Director/PDMR Shareholding',
    checked: false,
  },
];
