import { useEffect, useState } from 'react';

import { Table } from '@/components/Table';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import style from './style.module.less';
import { infoStatsFeesColumns, tansformInfoStatsFeesData } from './utils';

const InfoStatsFees = () => {
  const { data: state } = useFundByIsInParam();
  const [infoStatsFeesData, setInfoStatsFeesData] = useState<
    {
      type: string;
      info: string | number;
    }[]
  >([]);

  useEffect(() => {
    if (state?.fund) {
      setInfoStatsFeesData(tansformInfoStatsFeesData(state.fund));
    }
  }, [state?.fund]);

  return (
    <div className={style['info-stats-fees']}>
      <Table
        columns={infoStatsFeesColumns}
        data={infoStatsFeesData}
        paginateTotalCount={0}
        uniqueKey="type"
      />
    </div>
  );
};

export default InfoStatsFees;
