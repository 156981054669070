import React from 'react';

import { ReactComponent as GraphicSearch } from '@/assets/svg/graphic-search.svg';
import { ReactComponent as EmptyChartIcon } from '@/assets/svg/icons/icon-empty-funds.svg';
import EmptyStateBase from '@/partials/EmptyStateBase';

interface ChartEmptyStateProps {
  subtitle?: React.ReactNode;
  isDataError?: boolean;
  title?: string;
  isDashboardPage?: boolean;
}

const ChartEmptyState: React.FCWithChild<ChartEmptyStateProps> = ({
  subtitle,
  isDataError,
  title,
  isDashboardPage,
}) => {
  const handleReload = () => {
    window.location.reload();
  };

  function setTitle(title?: string) {
    let result = 'No data available';
    if (!isDataError) {
      result = title ?? 'No added funds/trusts';
    }
    return result;
  }

  return (
    <EmptyStateBase
      icon={isDashboardPage ? <GraphicSearch /> : <EmptyChartIcon />}
      title={setTitle(title)}
      subtitle={
        <>
          {isDataError ? (
            <>
              Unable to load data.{' '}
              <button style={{ color: '#1890ff' }} onClick={handleReload}>
                Reload
              </button>
            </>
          ) : (
            <>{subtitle}</>
          )}
        </>
      }
    />
  );
};

export default ChartEmptyState;
