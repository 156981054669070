import React, { useState } from 'react';

import { Toast } from '@/components';

import { useOnboardingContext } from '../context';
import { ONBOARDING_ACTIONS } from '../types';
import style from './style.module.less';
import WatchlistFundsList from './WatchlistFundsList';

const CreateWatchlist: React.FCWithChild = () => {
  const { state, dispatch } = useOnboardingContext();
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);

  const onClickNext = () => {
    dispatch({
      type: ONBOARDING_ACTIONS.SET_CURRENT_STEP,
      payload:
        state.currentStep + 3 /* Replace this back to 1 once step 2 is fixed*/,
    });
  };

  return (
    <div>
      <div className="bg-white md:rounded-lg flex flex-1 flex-wrap">
        <div className={style['watchlist-funds-list']}>
          <WatchlistFundsList
            isShowSearchFunds={state.isShowSearchFundsSection}
          />
        </div>

        {isToastVisible && (
          <Toast
            closable
            message="Your progress has been saved as draft."
            isVisible={isToastVisible}
            onClose={() => setIsToastVisible(false)}
          />
        )}
      </div>
      <div className="flex justify-end mt-6 md:mx-0 mx-6 pb-10">
        <button
          type="button"
          className={style.save}
          onClick={() => setIsToastVisible(true)}
        >
          Save
        </button>
        <button type="button" className={style.next} onClick={onClickNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CreateWatchlist;
