export enum TotalReturnPeriod {
  YTD = 'ytd',
  THREE_MONTHS = 'threeMonths',
  SIX_MONTHS = 'sixMonths',
  ONE_YEAR = 'oneYear',
  TWO_YEARS = 'twoYears',
  THREE_YEARS = 'threeYears',
  FOUR_YEARS = 'fourYears',
  FIVE_YEARS = 'fiveYears',
}

export const CALENDAR_YEAR_PERFORMANCE_TO_SHOW: {
  type: TotalReturnPeriod;
}[] = [
  {
    type: TotalReturnPeriod.YTD,
  },
  {
    type: TotalReturnPeriod.ONE_YEAR,
  },
  {
    type: TotalReturnPeriod.TWO_YEARS,
  },
  {
    type: TotalReturnPeriod.THREE_YEARS,
  },
  {
    type: TotalReturnPeriod.FOUR_YEARS,
  },
  {
    type: TotalReturnPeriod.FIVE_YEARS,
  },
];
