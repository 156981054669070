// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZoUc7sJwg9llX9cjJVGo {\n  color: #6f707a;\n}\n.wa5XGY5WMMvS8JGh_MS6 {\n  fill: rgba(0, 0, 0, 0.54);\n}\n.wa5XGY5WMMvS8JGh_MS6:hover {\n  fill: #007fff;\n}\n.cJTfqGqfWlrCiCQWWfmG {\n  color: #cf3b31;\n}\n.cJTfqGqfWlrCiCQWWfmG:hover {\n  color: #e6685f;\n}\n.cJTfqGqfWlrCiCQWWfmG:focus,\n.cJTfqGqfWlrCiCQWWfmG:active {\n  color: #cf3b31;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/Internal/CommentaryListItem/style.module.less"],"names":[],"mappings":"AAGE;EACE,cAAA;AAFJ;AAKE;EACE,yBAAA;AAHJ;AAKI;EACE,aAAA;AAHN;AAOE;EACE,cAAA;AALJ;AAOI;EACE,cAAA;AALN;AAQI;;EAEE,cAAA;AANN","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.comment-wrapper {\n  &__created-by {\n    color: #6f707a;\n  }\n\n  &__action-icon {\n    fill: rgba(0, 0, 0, 0.54);\n\n    &:hover {\n      fill: #007fff;\n    }\n  }\n\n  &__action-button {\n    color: #cf3b31;\n\n    &:hover {\n      color: #e6685f;\n    }\n\n    &:focus,\n    &:active {\n      color: #cf3b31;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment-wrapper__created-by": "ZoUc7sJwg9llX9cjJVGo",
	"comment-wrapper__action-icon": "wa5XGY5WMMvS8JGh_MS6",
	"comment-wrapper__action-button": "cJTfqGqfWlrCiCQWWfmG"
};
export default ___CSS_LOADER_EXPORT___;
