import { Modal } from 'antd';
import { useState } from 'react';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import useSearchWithPortfolios from '@/hooks/query-hooks/search-hooks/useSearchWithPortfolio';
import useChartTrustFundsFromSearch from '@/pages/app/Charts/utils/useChartFundsFromSearch';
import ExploreSearchV2, {
  renderOptions,
} from '@/partials/ExploreSearchBox/ExploreSearchBoxV2';
import { useSearchBox } from '@/partials/ExploreSearchBox/useExploreSearchbox';

import { useChartingContext } from './ChartingContext';

const ChartSearchBox = () => {
  const searchConfig = useSearchWithPortfolios();
  const chartingCtx = useChartingContext();
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const [maxPortfoliosModalVisible, setMaxPortfoliosModalVisible] =
    useState(false);
  const { setIsins, isins, setPortfolios, portfolios } =
    useChartTrustFundsFromSearch();
  const searchBoxOpts = useSearchBox({
    searchConfig,
    onClose: () => {},
    defaultValue: '',
  });
  const resultOpts = searchBoxOpts.allSuggestions
    .filter(opt => {
      if (opt.isPortfolio) {
        if (!featureSwitchState.portfolioInChartingPageEnabled) {
          return false;
        }
        return !portfolios.includes(opt.id);
      }
      return !isins.includes(opt.id);
    })
    .map(opt =>
      renderOptions({
        value: opt.id,
        title: opt?.shareClassDetails?.code || opt.label,
        highlightTerm: searchConfig.fullTerm,
        isPortfolio: opt.isPortfolio,
        primaryShareClass: opt.primaryShareClass,
      }),
    );
  const totalPortfolios = portfolios?.length || 0;
  const totalItems = (isins?.length || 0) + totalPortfolios;
  const maxPortfoliosReached = totalPortfolios >= 5;

  const handleShowMaxPortfoliosModal = () => {
    setMaxPortfoliosModalVisible(true);
    searchConfig.setFullTerm('');
  };
  return (
    <>
      <ExploreSearchV2
        dataTestId="chartingToolSearch"
        disabled={totalItems >= 20}
        value={searchConfig.fullTerm}
        onChange={searchBoxOpts.onChange}
        loading={searchBoxOpts.isLoading}
        onSelect={id => {
          const optSelected = searchBoxOpts.allSuggestions.find(
            s => s.id === id,
          );
          if (optSelected) {
            if (optSelected.isPortfolio) {
              if (maxPortfoliosReached) {
                handleShowMaxPortfoliosModal();
                return;
              }
              setPortfolios([id]);
            } else {
              setIsins([id]);
            }
            chartingCtx.setRecentId(id);
            searchConfig.setFullTerm('');
          }
        }}
        onKeyEnter={async id => {
          const optSelected = searchBoxOpts.allSuggestions.find(
            s => s.id === id,
          );
          if (!optSelected) {
            const firstItem = searchBoxOpts.allSuggestions[0];
            if (firstItem) {
              if (firstItem.isPortfolio) {
                if (maxPortfoliosReached) {
                  handleShowMaxPortfoliosModal();
                  return;
                }
                setPortfolios([firstItem.id]);
              } else {
                setIsins([firstItem.id]);
              }
              chartingCtx.setRecentId(firstItem.id);
              searchConfig.setFullTerm('');
            }
            return;
          }
          if (optSelected.isPortfolio) {
            if (maxPortfoliosReached) {
              handleShowMaxPortfoliosModal();
              return;
            }
            setPortfolios([optSelected.id]);
          } else {
            setIsins([optSelected.id]);
          }
          chartingCtx.setRecentId(optSelected.id);
          searchConfig.setFullTerm('');
        }}
        onSubmit={searchBoxOpts.onSearchResults}
        options={resultOpts}
      />
      <Modal
        open={maxPortfoliosModalVisible}
        closable={true}
        footer={<></>}
        onCancel={() => setMaxPortfoliosModalVisible(false)}
        className="
          [&_.ant-modal-footer]:hidden [&_.ant-modal-body]:p-10 [&_.ant-modal-content]:rounded-lg
          [&_svg]:text-xl hover:[&_svg]:text-red-500
        "
      >
        <div className="flex flex-col gap-y-4">
          <h2 className="text-xl font-bold text-[#313341]">
            Portfolio limit reached
          </h2>
          <p className="text-sm font-normal text-[#545576] mb-4">
            You can only add up to 5 portfolios here.
            <br />
            <br />
            If you would like to add more, let us know by contacting our support
            team so we can add it to the roadmap.
          </p>
          <div className="justify-end flex py-2.5 px-4">
            <p
              className="text-sm cursor-pointer font-medium text-[#3B3C53] hover:text-red-500"
              onClick={() => setMaxPortfoliosModalVisible(false)}
            >
              Close
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChartSearchBox;
