import {
  HelpSupportApi,
  UnavailableIsinsEmailPayload,
} from '@aminsights/contract';
import { useState } from 'react';

import { openApiConfig } from '@/utils';

const useProvideHelp = () => {
  const api = new HelpSupportApi(openApiConfig());
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');

  const sendUnavailableIsinsEmail = async (
    params: UnavailableIsinsEmailPayload,
  ) => {
    await api.sendUnavailableIsinsEmail(params).then(({ data }) => {
      if (data.code && data.message) {
        if (data.code === 200) {
          setSuccess(data.message);
        } else {
          setError(data.message);
        }
      }
    });
  };

  return {
    success,
    error,
    sendUnavailableIsinsEmail,
    setSuccess,
    setError,
  };
};

export default useProvideHelp;
