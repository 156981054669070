/* eslint-disable no-param-reassign */
import { getAvgQualityDefintion } from '@aminsights/shared';
import { Slider } from 'antd';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import Accordion, { Panel } from '@/components/Accordion';
import { SLIDER_MAX, SLIDER_MIN } from '@/constants';
import useProvideExplore from '@/pages/app/Explore/useProvideExplore';
import { getAvgQualityTypeCode } from '@/pages/app/Explore/utils/getAvgQualityTypeCode';
import ExploreFilterModal from '@/partials/ExploreFilterModal';
import RenderStyleBox from '@/partials/RenderStyleBox';

import { ExploreModalProps } from '../types';
import { adjustMarksToAvoidDuplicates } from '../utils/adjustMarksToAvoidDuplicates';
import { countSelectedFilter } from '../utils/countSelectedFilter';
import { getChangedMarkValues } from '../utils/getChangedMarkValues';
import { getRangeSliderClassName } from '../utils/getRangeSliderClassName';
import { getValueFromMarks } from '../utils/getValueFromMarks';
import { updateRangeAfterSubmit } from '../utils/updateRangeAfterSubmit';
import style from './style.module.less';

const modifiedDurationMarks: Record<number, string> = {
  0: '0',
  20: '2',
  40: '4',
  60: '6',
  80: '8',
  100: '10>',
};

const yieldMaturityMarks: Record<number, string> = {
  0: '0',
  33: '2%',
  66: '4%',
  99: '6%>',
};

const avgCreditMarks: Record<number, string> = {
  0: 'Not Rated',
  13: 'Below B',
  26: 'B',
  39: 'BB',
  52: 'BBB',
  65: 'A',
  78: 'AA',
  91: 'AAA',
};

const YIELD_MATURITY_SLIDER_MAX = 99;
const AVG_CREDIT_SLIDER_MAX = 91;

export const BondsFilterModal: React.FCWithChild<ExploreModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { state, setFilterParameters } = useProvideExplore();

  const [selectedStyle, setSelectedStyle] = useState<number[]>([]);

  const [modifiedDurationRange, setModifiedDurationRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX,
  ]);

  const [yieldMaturityRange, setYieldMaturityRange] = useState<number[]>([
    SLIDER_MIN,
    YIELD_MATURITY_SLIDER_MAX,
  ]);

  const [avgCreditQualityRange, setAvgCreditQualityRange] = useState<number[]>([
    SLIDER_MIN,
    AVG_CREDIT_SLIDER_MAX,
  ]);

  const [modifiedDurationChange, setModifiedDurationChange] =
    useState<boolean>(false);
  const [yieldMaturityChange, setYieldMaturityChange] =
    useState<boolean>(false);
  const [avgCreditQualityChange, setAvgCreditQualityChange] =
    useState<boolean>(false);

  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [disabled, setDisabled] = useState(true);

  const [modifiedDurationRangeMin, modifiedDurationRangeMax] = useMemo(
    () => modifiedDurationRange,
    [modifiedDurationRange],
  );
  const [yieldMaturityRangeMin, yieldMaturityRangeMax] = useMemo(
    () => yieldMaturityRange,
    [yieldMaturityRange],
  );
  const [avgCreditQualityRangeMin, avgCreditQualityRangeMax] = useMemo(
    () => avgCreditQualityRange,
    [avgCreditQualityRange],
  );

  const handleOnClear = () => {
    setSelectedStyle([]);
    setModifiedDurationRange([SLIDER_MIN, SLIDER_MAX]);
    setModifiedDurationChange(false);
    setYieldMaturityRange([SLIDER_MIN, YIELD_MATURITY_SLIDER_MAX]);
    setYieldMaturityChange(false);
    setAvgCreditQualityRange([SLIDER_MIN, AVG_CREDIT_SLIDER_MAX]);
    setAvgCreditQualityChange(false);
  };

  useEffect(() => {
    handleOnClear();
  }, [state.clearState]);

  const handleOnApply = () => {
    const [modifiedDurationMin, modifiedDurationMax] = getValueFromMarks(
      modifiedDurationRange,
      modifiedDurationMarks,
    );

    const [yieldMaturityMin, yieldMaturityMax] = getValueFromMarks(
      yieldMaturityRange,
      yieldMaturityMarks,
    );
    const avgCreditQualityMin =
      getAvgQualityTypeCode(avgCreditMarks[avgCreditQualityRangeMin])?.split(
        '-',
      ) || [];
    const avgCreditQualityMax =
      getAvgQualityTypeCode(avgCreditMarks[avgCreditQualityRangeMax])?.split(
        '-',
      ) || [];

    if (selectedStyle && selectedStyle.length) {
      setFilterParameters({
        bondStyle: selectedStyle,
      });
    }

    if (modifiedDurationChange) {
      setFilterParameters({
        modifiedDurationRange: [
          modifiedDurationMin,
          modifiedDurationMax === 10
            ? Number.MAX_SAFE_INTEGER
            : modifiedDurationMax,
        ],
      });
    }

    if (yieldMaturityChange) {
      setFilterParameters({
        yieldMaturityRange: [
          yieldMaturityMin,
          yieldMaturityMax === 6 ? Number.MAX_SAFE_INTEGER : yieldMaturityMax,
        ],
      });
    }

    if (avgCreditQualityChange) {
      setFilterParameters({
        avgCreditQualityRange: [
          Number(avgCreditQualityMax[0]),
          Number(avgCreditQualityMin[0]) === 18
            ? Number.MAX_SAFE_INTEGER
            : Number(avgCreditQualityMin[1]),
        ],
      });
    }

    if (selectedFilter === 0) {
      setFilterParameters({
        bondStyle: [],
        modifiedDurationRange: [],
        yieldMaturityRange: [],
        avgCreditQualityRange: [],
      });
    }

    onClose();
  };

  useEffect(() => {
    const count = countSelectedFilter([
      selectedStyle.length > 0,
      modifiedDurationChange,
      yieldMaturityChange,
      avgCreditQualityChange,
    ]);

    setSelectedFilter(count);
  }, [
    selectedStyle,
    modifiedDurationChange,
    yieldMaturityChange,
    avgCreditQualityChange,
  ]);

  useEffect(() => {
    const filters = state.filterParameters;

    setDisabled(
      ![
        filters.bondStyle,
        filters.modifiedDurationRange,
        filters.yieldMaturityRange,
        filters.avgCreditQualityRange,
      ].some(i => (Array.isArray(i) ? !!i.length : !!i)),
    );
    updateRangeAfterSubmit(filters.bondStyle || [], setSelectedStyle);
    updateRangeAfterSubmit(
      filters.modifiedDurationRange || [],
      setModifiedDurationRange,
      setModifiedDurationChange,
      modifiedDurationMarks,
    );
    updateRangeAfterSubmit(
      filters.yieldMaturityRange || [],
      setYieldMaturityRange,
      setYieldMaturityChange,
      yieldMaturityMarks,
    );
    updateRangeAfterSubmit(
      filters.avgCreditQualityRange && filters.avgCreditQualityRange.length > 0
        ? [
            isNaN(filters.avgCreditQualityRange[1])
              ? 'Not Rated'
              : getAvgQualityDefintion(filters.avgCreditQualityRange[1]) || '',
            getAvgQualityDefintion(filters.avgCreditQualityRange[0]) || '',
          ]
        : [],
      setAvgCreditQualityRange,
      setAvgCreditQualityChange,
      avgCreditMarks,
    );
  }, [
    state.filterParameters.bondStyle,
    state.filterParameters.modifiedDurationRange,
    state.filterParameters.yieldMaturityRange,
    state.filterParameters.avgCreditQualityRange,
  ]);

  return (
    <ExploreFilterModal
      title="Bonds"
      isVisible={isOpen}
      isDisableApply={disabled && !selectedFilter}
      isDisableClear={!selectedFilter}
      toggleModal={onClose}
      onClear={handleOnClear}
      onApply={handleOnApply}
    >
      <div className="modal-more-filters">
        <Accordion defaultActiveKey="1">
          <Panel header="Style" dataTestId={'bondsLabelStyle'} key="1">
            <>
              <p
                className={cx('font-semibold text-xs', style['y-style-label'])}
              >
                Credit Risk
              </p>
              <div className="flex">
                <RenderStyleBox
                  isFixedIncome
                  isBoxClickable
                  onSelectedBox={(selected: number[]) =>
                    setSelectedStyle(selected)
                  }
                  isClear={selectedStyle && !selectedStyle.length}
                  selectedBox={selectedStyle}
                />
              </div>
              <p className="text-xs font-semibold text-center">Duration</p>
            </>
          </Panel>
          <Panel
            header="Modified Duration"
            dataTestId={'bondsLabelModifiedDuration'}
            key="2"
            extra={
              <span>
                {getChangedMarkValues(
                  modifiedDurationChange,
                  modifiedDurationRange,
                  modifiedDurationMarks,
                )}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'bondsSliderModifiedDuration'}
            >
              <Slider
                className={
                  style[getRangeSliderClassName(modifiedDurationChange)]
                }
                tooltip={{ open: false }}
                range
                step={20}
                max={SLIDER_MAX}
                marks={modifiedDurationMarks}
                value={[modifiedDurationRangeMin, modifiedDurationRangeMax]}
                onChange={value => {
                  setModifiedDurationRange(
                    adjustMarksToAvoidDuplicates(value, modifiedDurationMarks),
                  );
                  setModifiedDurationChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Yield to Maturity"
            dataTestId={'bondsLabelYieldToMaturity'}
            key="3"
            extra={
              <span>
                {getChangedMarkValues(
                  yieldMaturityChange,
                  yieldMaturityRange,
                  yieldMaturityMarks,
                )}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'bondsSliderYieldToMaturity'}
            >
              <Slider
                className={style[getRangeSliderClassName(yieldMaturityChange)]}
                tooltip={{ open: false }}
                range
                step={33}
                max={YIELD_MATURITY_SLIDER_MAX}
                marks={yieldMaturityMarks}
                value={[yieldMaturityRangeMin, yieldMaturityRangeMax]}
                onChange={value => {
                  setYieldMaturityRange(
                    adjustMarksToAvoidDuplicates(value, yieldMaturityMarks),
                  );
                  setYieldMaturityChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Average Credit Quality"
            dataTestId={'bondsLabelAverageCreditQuality'}
            key="4"
            extra={
              <span>
                {getChangedMarkValues(
                  avgCreditQualityChange,
                  avgCreditQualityRange,
                  avgCreditMarks,
                )}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'bondsSliderAverageCreditQuality'}
            >
              <Slider
                className={cx(
                  style[getRangeSliderClassName(avgCreditQualityChange)],
                  style['slider-first-option-long'],
                )}
                tooltip={{ open: false }}
                range
                step={13}
                max={AVG_CREDIT_SLIDER_MAX}
                marks={avgCreditMarks}
                value={[avgCreditQualityRangeMin, avgCreditQualityRangeMax]}
                onChange={value => {
                  setAvgCreditQualityRange(
                    adjustMarksToAvoidDuplicates(value, avgCreditMarks),
                  );
                  setAvgCreditQualityChange(true);
                }}
              />
            </div>
          </Panel>
        </Accordion>
      </div>
    </ExploreFilterModal>
  );
};
