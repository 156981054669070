import { Configuration, ConfigurationParameters } from '@aminsights/contract';

import { getConfigValue } from '@/constants/configs';

export const openApiConfig = (
  params?: ConfigurationParameters,
): Configuration =>
  new Configuration({
    basePath:
      getConfigValue('REACT_APP_API') || 'https://api.aminsights.com/v1',
    ...params,
  });
