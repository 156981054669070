// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yxAyN36PR5mNPvyZsVkA {\n  color: #545576;\n  font-size: 14px;\n  display: block;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Subtitle/style.module.less"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,eAAA;EACA,cAAA;AADF","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.subtitle-font {\n  color: @neutral;\n  font-size: 14px;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle-font": "yxAyN36PR5mNPvyZsVkA"
};
export default ___CSS_LOADER_EXPORT___;
