import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconPrevious } from '@/assets/svg/icons/icon-next-arrow.svg';

interface SubPageLayoutProps {
  previousLabel?: string;
  onClick?: () => void;
}

const SectionBackButton: React.FCWithChild<SubPageLayoutProps> = ({
  previousLabel,
  onClick,
}) => {
  const history = useHistory();
  const handleGoBack = () => history.goBack();
  return (
    <div className="px-5 py-4">
      <div
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            handleGoBack();
          }
        }}
        onKeyDown={() => handleGoBack()}
        role="button"
        tabIndex={0}
        className="inline-flex items-center text-neutral hover:text-primary cursor-pointer"
        data-test-id="backButton"
      >
        <IconPrevious className="icon transform rotate-180 text-xxs mr-1" />
        <span className="text-xs font-semibold mt-0.5">{previousLabel}</span>
      </div>
    </div>
  );
};

export default SectionBackButton;
