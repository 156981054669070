import { Fund, FundManagementFee } from '@aminsights/contract';
import {
  DISPLAY_DATE_FORMAT,
  EMPTY_DATA_POINT,
  shortenLongNumber,
} from '@aminsights/shared';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';

export const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Info',
    dataIndex: 'info',
  },
];

export const formTrustAndFundBasicData = (
  fund: Fund,
  isInvestmentTrust: boolean,
) => {
  const fundBasicData = [
    {
      type: 'Provider name',
      info: fund.providerName,
    },
    {
      type: 'Launch date',
      info: fund.shareClassDetails.inceptionDate
        ? dayjs(fund.shareClassDetails.inceptionDate).format(
            DISPLAY_DATE_FORMAT,
          )
        : EMPTY_DATA_POINT,
    },
    {
      type: 'Benchmark',
      info: fund.primaryBenchmarkFormatted,
    },
    {
      type: 'Base Currency',
      info: fund.currencyCode || EMPTY_DATA_POINT,
    },
    {
      type: 'Cut-off point',
      info: fund.cutOffPoint?.length
        ? fund.cutOffPoint.map(item => item.cutOffTime)
        : EMPTY_DATA_POINT,
    },
  ];

  const trustBasicData = [
    {
      type: 'Latest NAV',
      info: fund.navCumFair
        ? fund.navCumFair.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })
        : EMPTY_DATA_POINT,
    },
    {
      type: 'Gearing',
      info: fund.netGearingCum
        ? `${fund.netGearingCum.toFixed(2)}%`
        : EMPTY_DATA_POINT,
    },
    {
      type: 'Benchmark',
      info: fund.primaryBenchmarkFormatted,
    },
    {
      type: 'Performance fee',
      info: fund.performanceFeeCharged || EMPTY_DATA_POINT,
    },
    {
      type: 'Annual management charge',
      info: getAnnualManagementCharge(
        fund.managementFee,
        fund.currencyCode ? fund.currencyCode : 'GBP',
      ),
    },
    {
      type: 'NAV frequency',
      info: EMPTY_DATA_POINT,
    },
    {
      type: 'Financial year end',
      info: fund.financialYearEnd || EMPTY_DATA_POINT,
    },
    {
      type: 'Launch date',
      info: fund.shareClassDetails.inceptionDate
        ? dayjs(fund.shareClassDetails.inceptionDate).format(
            DISPLAY_DATE_FORMAT,
          )
        : EMPTY_DATA_POINT,
    },
  ];

  const commonTrustAndFundData = [
    {
      type: 'ISIN',
      info: fund.shareClassDetails.isin || EMPTY_DATA_POINT,
    },
    {
      type: 'Domicile',
      info: fund.domicile || EMPTY_DATA_POINT,
    },
  ];
  if (isInvestmentTrust) {
    return [...trustBasicData, ...commonTrustAndFundData];
  }
  return [
    ...fundBasicData.slice(0, 3),
    ...commonTrustAndFundData,
    ...fundBasicData.slice(3),
  ];
};

export const getAnnualManagementCharge = (
  managementFee: FundManagementFee[],
  currencyCode: string,
) => {
  if (!managementFee.length) return EMPTY_DATA_POINT;
  const annualManagementCharge: string[] = [];

  const symbol = currencyCode ? getSymbolFromCurrency(currencyCode) : '£';
  managementFee.map(item => {
    if (item.highBreakpoint && !item.lowBreakpoint) {
      annualManagementCharge.push(
        `${item.value}% < ${symbol}${shortenLongNumber(item.highBreakpoint)}`,
      );
    }

    if (item.highBreakpoint && item.lowBreakpoint) {
      annualManagementCharge.push(
        `${item.value}% ${symbol}${shortenLongNumber(
          item.lowBreakpoint,
        )} - ${symbol}${shortenLongNumber(item.highBreakpoint)}`,
      );
    }

    if (!item.highBreakpoint && !item.lowBreakpoint) {
      annualManagementCharge.push(`${item.value}%`);
    }

    if (item.lowBreakpoint && !item.highBreakpoint) {
      annualManagementCharge.push(
        `${item.value}% > ${symbol}${shortenLongNumber(item.lowBreakpoint)}`,
      );
    }

    return annualManagementCharge;
  });
  return annualManagementCharge.join(', ');
};
