export enum SEARCH_SC_ACTIONS {
  CLEAR = 'sc-search:clear',
  SET_SEARCH_TERM = 'sc-search:set-search-term',
}

export type SearchSCActionTypes =
  | {
      type: SEARCH_SC_ACTIONS.CLEAR;
    }
  | {
      type: SEARCH_SC_ACTIONS.SET_SEARCH_TERM;
      payload: string | undefined;
    };

export type SearchSCState = {
  searchTerm?: string;
};
