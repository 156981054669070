import React from 'react';

export const getMatchScore = (source: string, target: string) => {
  const sourceSplit = source.split(' ');
  const targetSplit = target.split(' ').filter(Boolean);
  return sourceSplit.filter(s => {
    const srcIndex = targetSplit.findIndex(ts =>
      s.toLowerCase().includes(ts.toLowerCase()),
    );
    return srcIndex >= 0;
  })?.length;
};

export function getHighlightedText(
  text: string,
  isin: string,
  highlight: string,
) {
  if (highlight === isin) {
    return <span className="font-bold">{text}</span>;
  }

  const toHighlight = highlight.split(' ').filter(Boolean);
  const target = text.split(' ');

  return (
    <span>
      {target.map(t => {
        const srcIndex = toHighlight.findIndex(th =>
          t.toLowerCase().includes(th.toLowerCase()),
        );
        if (srcIndex >= 0) {
          const startStr = t.indexOf(toHighlight[srcIndex]);
          const endStr = startStr + toHighlight[srcIndex].length;
          return (
            <React.Fragment key={t}>
              {[...t].map((char, i) => (
                <span
                  key={i + char}
                  style={
                    i >= startStr && i <= endStr ? { fontWeight: 'bold' } : {}
                  }
                >
                  {char}
                </span>
              ))}{' '}
            </React.Fragment>
          );
        }
        return `${t} `;
      })}
    </span>
  );
}
