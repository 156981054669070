import { Modal } from 'antd';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as GraphicClose } from '@/assets/svg/icons/icon-close.svg';
import Button from '@/components/Button';

import style from './style.module.less';

type ModalFooterProps = {
  applyText?: string;
  cancelText?: string;
  hideApplyButton?: boolean;
  disableApplyButton?: boolean;
  hideCancelButton?: boolean;
};

type ModalProps = {
  title?: string;
  size?: number;
  isVisible: boolean;
  className?: string;
  footerConfig?: ModalFooterProps;
  onApply?: () => void;
  onCancel?: () => void;
  toggleModal: () => void;
  closeIcon?: React.ReactNode;
  dataTestId?: string;
};

const CustomModal: React.FCWithChild<ModalProps> = ({
  title,
  size,
  children,
  isVisible,
  className,
  footerConfig,
  toggleModal,
  onApply,
  onCancel,
  closeIcon,
  dataTestId,
}) => {
  return (
    <Modal
      data-test-id={`${dataTestId ?? ''}Wrapper`}
      title={<div data-test-id={`${dataTestId ?? ''}Title`}>{title}</div>}
      width={size || 632}
      open={isVisible}
      onCancel={e => {
        toggleModal();
        e.stopPropagation();
      }}
      className={cx(style.modal, className)}
      footer={
        footerConfig
          ? [
              !footerConfig.hideCancelButton && (
                <Button
                  size="large"
                  type="link"
                  className="font-semibold"
                  onClick={() => {
                    if (onCancel) onCancel();
                    toggleModal();
                  }}
                  key="secondary"
                >
                  {footerConfig.cancelText ?? 'Cancel'}
                </Button>
              ),
              !footerConfig.hideApplyButton && (
                <Button
                  data-test-id={`${dataTestId ?? ''}SaveButton`}
                  size="large"
                  type="primary"
                  className="font-semibold"
                  onClick={onApply}
                  disabled={footerConfig.disableApplyButton}
                  key="primary"
                >
                  {footerConfig.applyText ?? 'Apply'}
                </Button>
              ),
            ]
          : null
      }
      closeIcon={
        closeIcon || (
          <GraphicClose data-test-id={`${dataTestId ?? ''}CloseButton`} />
        )
      }
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
