// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k631NLdfBxwhqpO0XsGn {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  row-gap: 0.25rem;\n  padding: 1.25rem;\n  padding-top: 4rem;\n  padding-bottom: 4rem;\n  text-align: center\n}@media (min-width: 768px) {.k631NLdfBxwhqpO0XsGn {\n    padding-top: 8rem;\n    padding-bottom: 8rem\n  }\n}\n.YucNOldReEjxEi5jBQ7S {\n  font-size: 1.125rem;\n  line-height: 1.75rem;\n  font-weight: 600;\n  color: #181920\n}\n.HQcTw4dI0QudArRQA9q0 {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 400;\n  color: #6f707a\n}\n.k631NLdfBxwhqpO0XsGn .XSJ5p6eEyzGO5B2RYb_r {\n  height: 0.75rem;\n  fill: #ffffff;\n  margin-right: 8px\n}\n.k631NLdfBxwhqpO0XsGn .PSPaY2xFV3Rh_RSfu6LQ {\n  height: 2.5rem;\n  border-width: 0px;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 500;\n  width: 152px;\n  background-color: #0072e6\n}\n", "",{"version":3,"sources":["webpack://./src/partials/ManageBuckets/EmptyBucketState/style.module.less"],"names":[],"mappings":"AAGE;EAAA,aAAA;EAAA,WAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,uBAAA;EACA,gBAAA;EAAA,gBAAA;EAAA,iBAAA;EAAA,oBAAA;EAAA;AADA,CACA,2BAAA;IAAA,iBAAA;IAAA;EAAA;AAAA;AAEE;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EACA;AADA;AAIA;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EACA;AADA;AAIA;EAAA,eAAA;EACA,aAAA;EACA;AAFA;AAKA;EAAA,cAAA;EAAA,iBAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EACA,YAAA;EACA;AAFA","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.container {\n  @apply flex flex-col items-center justify-center w-full;\n  @apply gap-y-1 text-center p-5 py-16 md:py-32;\n  &-title {\n    @apply text-lg font-semibold;\n    color: #181920;\n  }\n  &-message {\n    @apply text-sm font-normal;\n    color: #6f707a;\n  }\n  .icon-add {\n    @apply h-3;\n    fill: @white;\n    margin-right: 8px;\n  }\n  .btn-add {\n    @apply font-medium text-sm border-0 h-10;\n    width: 152px;\n    background-color: #0072e6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "k631NLdfBxwhqpO0XsGn",
	"container-title": "YucNOldReEjxEi5jBQ7S",
	"container-message": "HQcTw4dI0QudArRQA9q0",
	"icon-add": "XSJ5p6eEyzGO5B2RYb_r",
	"btn-add": "PSPaY2xFV3Rh_RSfu6LQ"
};
export default ___CSS_LOADER_EXPORT___;
