import { APP_ROUTE_WATCHLIST } from '@aminsights/shared/dist';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { SubRouter } from './index';
import { WATCHLIST_SUB_ROUTES } from './routes';

const WatchlistSubRouter: React.FCWithChild = () => (
  <SubRouter routes={WATCHLIST_SUB_ROUTES} rootPath={APP_ROUTE_WATCHLIST}>
    <Route
      exact
      path={`/${APP_ROUTE_WATCHLIST}`}
      render={() => <Redirect to={`/${APP_ROUTE_WATCHLIST}/bucket/All`} />}
    />
  </SubRouter>
);

export default WatchlistSubRouter;
