import { useSplitTreatments } from '@splitsoftware/splitio-react';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { getConfigValue } from '@/constants';
import {
  TREATMENT_NAME_CLARITY,
  TREATMENT_NAME_CUSTOM_BENCHMARKS,
  TREATMENT_NAME_FUND_DETAILS_ACTIONS,
  TREATMENT_NAME_MSCI_BENCHMARK,
  TREATMENT_NAME_PORTFOLIO,
  TREATMENT_NAME_PORTFOLIO_IMPORT,
  TREATMENT_NAME_PORTFOLIO_IN_CHARTING_PAGE,
  TREATMENT_NAME_PORTFOLIO_PLATFORMS,
  TREATMENT_NAME_PORTFOLIO_RISK_RETURN_CHART,
  TREATMENT_NAME_WEEKLY_UPDATE,
  TREATMENT_SHARE_PORTFOLIO,
  TreatmentValues,
} from '@/constants/split';
import { useScript } from '@/utils/useScript';

export type FeatureSwitchState = {
  isInitialised: boolean;
  isClarityEnabled: boolean;
  isPortfolioTreatmentEnabled: boolean;
  isPortfolioImportTreatmentEnabled: boolean;
  isFundDetailsActionsTreatmentEnabled: boolean;
  isPortfolioPlatformsEnabled: boolean;
  isWeeklyUpdateTreatementEnabled: boolean;
  isMsciBenchmarkEnabled: boolean;
  isSharePortfolioTreatmentEnabled: boolean;
  isCustomBenchmarkEnabled: boolean;
  isPortfolioRiskReturnChartEnabled: boolean;
  portfolioInChartingPageEnabled: boolean;
};

const initialState: FeatureSwitchState = {
  isClarityEnabled: false,
  isInitialised: false,
  isPortfolioTreatmentEnabled: false,
  isPortfolioImportTreatmentEnabled: false,
  isFundDetailsActionsTreatmentEnabled: false,
  isPortfolioPlatformsEnabled: false,
  isWeeklyUpdateTreatementEnabled: false,
  isMsciBenchmarkEnabled: false,
  isSharePortfolioTreatmentEnabled: false,
  isCustomBenchmarkEnabled: false,
  isPortfolioRiskReturnChartEnabled: false,
  portfolioInChartingPageEnabled: false,
};

export enum FEATURE_SWITCH_ACTIONS {
  SET_SPLITS = 'feature-switch:set-splits',
}

type FeatureSwitchDispatchActions = {
  type: FEATURE_SWITCH_ACTIONS;
  payload: FeatureSwitchState;
};

type FeatureSwitchContextProps = {
  state: FeatureSwitchState;
  dispatch: React.Dispatch<FeatureSwitchDispatchActions>;
};

const FeatureSwitchContext = createContext<FeatureSwitchContextProps>({
  state: initialState,
  dispatch: () => ({}),
});

const reducer = (
  state: FeatureSwitchState,
  action: FeatureSwitchDispatchActions,
): FeatureSwitchState => {
  switch (action.type) {
    case FEATURE_SWITCH_ACTIONS.SET_SPLITS: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        isInitialised: true,
      };
    }
    default:
      return state;
  }
};

const FeatureSwitchProvider: React.FCWithChild = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { treatments, isReady } = useSplitTreatments({
    names: [
      TREATMENT_NAME_CLARITY,
      TREATMENT_NAME_PORTFOLIO,
      TREATMENT_NAME_PORTFOLIO_IMPORT,
      TREATMENT_NAME_FUND_DETAILS_ACTIONS,
      TREATMENT_NAME_PORTFOLIO_PLATFORMS,
      TREATMENT_NAME_WEEKLY_UPDATE,
      TREATMENT_NAME_MSCI_BENCHMARK,
      TREATMENT_SHARE_PORTFOLIO,
      TREATMENT_NAME_CUSTOM_BENCHMARKS,
      TREATMENT_NAME_PORTFOLIO_RISK_RETURN_CHART,
      TREATMENT_NAME_PORTFOLIO_IN_CHARTING_PAGE,
    ],
  });

  useEffect(() => {
    if (isReady && !state.isInitialised) {
      const clarityTreatment = treatments[TREATMENT_NAME_CLARITY];
      const portfolioTreatment = treatments[TREATMENT_NAME_PORTFOLIO];
      const portfolioImportTreatment =
        treatments[TREATMENT_NAME_PORTFOLIO_IMPORT];
      const fundDetailsActionsTreatment =
        treatments[TREATMENT_NAME_FUND_DETAILS_ACTIONS];
      const portfolioPlatforms = treatments[TREATMENT_NAME_PORTFOLIO_PLATFORMS];
      const sharePortfolioTreatment = treatments[TREATMENT_SHARE_PORTFOLIO];
      const customBenchmarkTreatment =
        treatments[TREATMENT_NAME_CUSTOM_BENCHMARKS];
      const portfolioRiskReturnChartTreatment =
        treatments[TREATMENT_NAME_PORTFOLIO_RISK_RETURN_CHART];
      const portfolioInChartingPageTreatment =
        treatments[TREATMENT_NAME_PORTFOLIO_IN_CHARTING_PAGE];
      const weeklyUpdateTreatment = treatments[TREATMENT_NAME_WEEKLY_UPDATE];
      const msciBenchmarkTreatment = treatments[TREATMENT_NAME_MSCI_BENCHMARK];

      dispatch({
        type: FEATURE_SWITCH_ACTIONS.SET_SPLITS,
        payload: {
          isInitialised: true,
          isClarityEnabled: clarityTreatment?.treatment === TreatmentValues.On,
          isPortfolioTreatmentEnabled:
            portfolioTreatment?.treatment === TreatmentValues.On,
          isPortfolioImportTreatmentEnabled:
            portfolioImportTreatment?.treatment === TreatmentValues.On,
          isFundDetailsActionsTreatmentEnabled:
            fundDetailsActionsTreatment?.treatment === TreatmentValues.On,
          isPortfolioPlatformsEnabled:
            portfolioPlatforms.treatment === TreatmentValues.On,
          isWeeklyUpdateTreatementEnabled:
            weeklyUpdateTreatment?.treatment === TreatmentValues.On,
          isMsciBenchmarkEnabled:
            msciBenchmarkTreatment?.treatment === TreatmentValues.On,
          isSharePortfolioTreatmentEnabled:
            sharePortfolioTreatment?.treatment === TreatmentValues.On,
          isCustomBenchmarkEnabled:
            customBenchmarkTreatment?.treatment === TreatmentValues.On,
          isPortfolioRiskReturnChartEnabled:
            portfolioRiskReturnChartTreatment?.treatment === TreatmentValues.On,
          portfolioInChartingPageEnabled:
            portfolioInChartingPageTreatment?.treatment === TreatmentValues.On,
        },
      });
    }
  }, [isReady, treatments]);

  useScript(
    `/clarity.${getConfigValue('REACT_APP_NODE_ENV')}.js`,
    'ms_clarity',
    !state.isClarityEnabled,
  );

  return (
    <FeatureSwitchContext.Provider value={{ state, dispatch }}>
      {state.isInitialised ? <>{children}</> : null}
    </FeatureSwitchContext.Provider>
  );
};

export { FeatureSwitchProvider };

export const useFeatureSwitchContext = (): FeatureSwitchContextProps => {
  return useContext(FeatureSwitchContext);
};

export default FeatureSwitchContext;
