import { Badge, Space } from 'antd';
import React from 'react';

import style from './style.module.less';

type SidebarBadgeProps = {
  count?: number | undefined;
};

const SidebarBadge: React.FCWithChild<SidebarBadgeProps> = ({ count }) => {
  return (
    <Space className={style['sidebar-badge']}>
      <Badge count={count} />
    </Space>
  );
};
export default SidebarBadge;
