// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T4nAK0Sc161oAaFKlsYm.ant-select-focused .ant-select-selector,\n.T4nAK0Sc161oAaFKlsYm .ant-select-selector:hover {\n  border: 2px solid #0c65ce !important;\n  box-shadow: unset !important;\n}\n.qzCem5fw4Q4I_1D5gbeI .ant-select-item-option {\n  border-radius: 0.25rem;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 400;\n  --tw-text-opacity: 1;\n  color: rgb(84 85 118 / var(--tw-text-opacity));\n}\n.qzCem5fw4Q4I_1D5gbeI .ant-select-item-option:hover {\n  background-color: #f2fdff;\n}\n.qzCem5fw4Q4I_1D5gbeI .ant-select-item-option-selected {\n  background-color: transparent;\n  font-weight: 400;\n  --tw-text-opacity: 1;\n  color: rgb(0 127 255 / var(--tw-text-opacity));\n}\n.qzCem5fw4Q4I_1D5gbeI .ant-select-item-option-selected:hover {\n  background-color: #f2fdff;\n}\n.qzCem5fw4Q4I_1D5gbeI .bSYCBQOr05ALHTdK07Nt {\n  background-color: #f2fdff;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/User/RoleSelect/style.module.less"],"names":[],"mappings":"AACE;;EAEE,oCAAA;EACA,4BAAA;AAAJ;AAMI;EAAA,sBAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;AAEA;EACE,yBAAA;AAHN;AAQI;EAAA,6BAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;AAEA;EACE,yBAAA;AALN;AARA;EAkBI,yBAAA;AAPJ","sourcesContent":[".role-select {\n  &:global.ant-select-focused .ant-select-selector,\n  & :global .ant-select-selector:hover {\n    border: 2px solid #0c65ce !important;\n    box-shadow: unset !important;\n  }\n}\n\n.role-select-options {\n  & :global .ant-select-item-option {\n    @apply text-sm font-normal text-neutral rounded;\n\n    &:hover {\n      background-color: #f2fdff;\n    }\n  }\n\n  & :global .ant-select-item-option-selected {\n    @apply text-primary bg-transparent font-normal;\n\n    &:hover {\n      background-color: #f2fdff;\n    }\n  }\n\n  .ant-select-item-option-active {\n    background-color: #f2fdff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"role-select": "T4nAK0Sc161oAaFKlsYm",
	"role-select-options": "qzCem5fw4Q4I_1D5gbeI",
	"ant-select-item-option-active": "bSYCBQOr05ALHTdK07Nt"
};
export default ___CSS_LOADER_EXPORT___;
