import { PageQueryParametersSortDirectionEnum } from '@aminsights/contract';
import { Skeleton, Tooltip } from 'antd';
import cx from 'classnames';
import React, { forwardRef, ReactNode, useState } from 'react';

import style from './style.module.less';

export interface HeaderProps {
  title: string | React.ReactElement;
  width?: number;
  isSortable: boolean;
  align?: string;
  colSpan: number;
  isFixed?: boolean;
  type?: string;
  isEndLeft?: boolean;
  hasAction: boolean;
  tooltipText?: React.ReactNode;
  onClick?: () => void;
  onSort?: (sort: PageQueryParametersSortDirectionEnum) => void;
  isSorting?: boolean;
  sortDirection?: PageQueryParametersSortDirectionEnum;
  loading?: boolean;
  subHeader?: boolean;
  enableWrap?: boolean;
  minWidth?: number;
  className?: string;
  headerCheckbox?: ReactNode;
}

const TableHeader = forwardRef<HTMLTableCellElement, HeaderProps>(
  (
    {
      title,
      isSortable,
      width,
      align,
      type,
      colSpan,
      isFixed,
      isEndLeft,
      onClick,
      onSort,
      tooltipText,
      hasAction,
      isSorting,
      sortDirection = PageQueryParametersSortDirectionEnum.Asc,
      loading,
      subHeader,
      enableWrap = false,
      minWidth,
      className,
      headerCheckbox,
    },
    ref,
  ) => {
    const [currentSort, setCurrentSort] =
      useState<PageQueryParametersSortDirectionEnum>(sortDirection);
    const alignment = {
      'justify-end text-right': align === 'right',
      'justify-start text-left': align === 'left',
      'justify-center text-center': align === 'center',
    };
    return (
      <th
        ref={ref}
        className={cx(
          {
            'cursor-pointer': isSortable,
            [style['table-header__action']]: type && type === 'action',
            [style['table-header__fixed']]: isFixed && !isEndLeft,
            [style['table-header__fixed--has-action']]: isFixed && hasAction,
          },
          !loading && className,
        )}
        style={{ width, minWidth }}
        colSpan={colSpan}
        data-test-id={`tableHeader-${title}`}
      >
        <div
          className={cx(
            'flex items-center w-full py-2 px-3 sm:px-4',
            alignment,
          )}
          style={{ width }}
        >
          {!loading && (
            <Tooltip
              placement="bottom"
              title={tooltipText}
              overlayClassName="max-w-60 md:max-w-none"
              overlayInnerStyle={{ borderRadius: 4 }}
              arrowPointAtCenter
            >
              <button
                type="button"
                className={cx('flex items-center', alignment)}
                onClick={() => {
                  const sort =
                    currentSort === 'desc'
                      ? PageQueryParametersSortDirectionEnum.Asc
                      : PageQueryParametersSortDirectionEnum.Desc;
                  setCurrentSort(sort);
                  if (onSort) {
                    onSort(sort);
                  }
                  if (onClick) {
                    onClick();
                  }
                }}
              >
                {headerCheckbox && <div>{headerCheckbox}</div>}
                {subHeader ? (
                  <p
                    className={cx(
                      'font-medium whitespace-nowrap',
                      style['subheader-title'],
                    )}
                  >
                    {title}
                  </p>
                ) : (
                  <p
                    className={cx('text-xs text-neutral font-medium', {
                      'whitespace-wrap': enableWrap,
                      'whitespace-nowrap': !enableWrap,
                    })}
                  >
                    {title}
                  </p>
                )}

                {isSortable ? (
                  <div className={cx('ml-2', style.sort)}>
                    <div
                      className={cx(
                        style['sort-up'],
                        currentSort === 'asc' &&
                          isSorting &&
                          style['sort-up__active'],
                      )}
                    />
                    <div
                      className={cx(
                        style['sort-down'],
                        currentSort === 'desc' &&
                          isSorting &&
                          style['sort-down__active'],
                      )}
                    />
                  </div>
                ) : (
                  ''
                )}
              </button>
            </Tooltip>
          )}
          {loading && title && (
            <Skeleton
              title={false}
              paragraph={{ rows: 1, width: '70px' }}
              active
            />
          )}
        </div>
      </th>
    );
  },
);

export default TableHeader;
