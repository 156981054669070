import {
  GenderPayGapHourlyWageGap,
  GenderPayGapStatsHourlyWageGapStats,
} from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import React, { useMemo } from 'react';

import { DataTable } from '@/components/Table';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import { IGenderPayGapData } from './data';
import style from './style.module.less';

const columns: IDataTableColumns[] = [
  {
    title: 'Type',
    idSubText: 'type',
    render: (item: IGenderPayGapData) => (
      <div className="table-cell-value">{item.type}</div>
    ),
    renderType: 'text',
    isColumnFixed: true,
    width: 200,
  },
  {
    title: '2023',
    idSubText: 'year-0',
    renderType: 'custom',
    align: 'center',
    children: [
      {
        title: 'Provider',
        idSubText: 'provider-0',
        render: (item: IGenderPayGapData) => (
          <div className="table-cell-value">{item.PROVIDER23}</div>
        ),
        renderType: 'number',
        width: 100,
      },
      {
        title: 'Avg.',
        idSubText: 'avg-0',
        render: (item: IGenderPayGapData) => (
          <div className="table-cell-value">{item.AVG23}</div>
        ),
        renderType: 'number',
        width: 100,
      },
    ],
  },
  {
    title: '2022',
    idSubText: 'year-1',
    renderType: 'custom',
    align: 'center',
    children: [
      {
        title: 'Provider',
        idSubText: 'provider-1',
        render: (item: IGenderPayGapData) => (
          <div className="table-cell-value">{item.PROVIDER22}</div>
        ),
        renderType: 'number',
        width: 100,
      },
      {
        title: 'Avg.',
        idSubText: 'avg-1',
        render: (item: IGenderPayGapData) => (
          <div className="table-cell-value">{item.AVG22}</div>
        ),
        renderType: 'number',
        width: 100,
      },
    ],
  },
  {
    title: '2021',
    idSubText: 'year-2',
    renderType: 'custom',
    align: 'center',
    children: [
      {
        title: 'Provider',
        idSubText: 'provider-2',
        render: (item: IGenderPayGapData) => (
          <div className="table-cell-value">{item.PROVIDER21}</div>
        ),
        renderType: 'number',
        width: 100,
      },
      {
        title: 'Avg.',
        idSubText: 'avg-2',
        renderType: 'number',
        render: (item: IGenderPayGapData) => (
          <div className="table-cell-value">{item.AVG21}</div>
        ),
        width: 100,
      },
    ],
  },
];

const getGPGAverage = (
  yearlyStats: GenderPayGapStatsHourlyWageGapStats[] | undefined,
  columnYear: number,
) => {
  if (!yearlyStats) return 'N/A';
  const yearPayGap = yearlyStats.find(bpg => bpg.year === columnYear);
  return yearPayGap?.average
    ? `${yearPayGap.average.toFixed(1)}%`
    : EMPTY_DATA_POINT;
};

const getGPGProvider = (
  yearlyStats: GenderPayGapHourlyWageGap[] | undefined,
  columnYear: number,
) => {
  if (!yearlyStats) return 'N/A';
  const yearPayGap = yearlyStats.find(bpg => bpg.year === columnYear);
  return yearPayGap?.percentage
    ? `${yearPayGap.percentage.toFixed(1)}%`
    : EMPTY_DATA_POINT;
};

const InfoStatsGenderPayGap: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();

  const infoStatsGenderPayGapData: IGenderPayGapData[] = useMemo(() => {
    return [
      {
        type: 'Hourly wage gap',
        PROVIDER23: getGPGProvider(
          state?.fund?.genderPayGap?.hourlyWageGap,
          2023,
        ),
        AVG23: getGPGAverage(
          state?.fund?.genderPayGapStats?.hourlyWageGapStats,
          2023,
        ),
        PROVIDER22: getGPGProvider(
          state?.fund?.genderPayGap?.hourlyWageGap,
          2022,
        ),
        AVG22: getGPGAverage(
          state?.fund?.genderPayGapStats?.hourlyWageGapStats,
          2022,
        ),
        PROVIDER21: getGPGProvider(
          state?.fund?.genderPayGap?.hourlyWageGap,
          2021,
        ),
        AVG21: getGPGAverage(
          state?.fund?.genderPayGapStats?.hourlyWageGapStats,
          2021,
        ),
      },
      {
        type: 'Bonus pay gap',
        PROVIDER23: getGPGProvider(
          state?.fund?.genderPayGap?.bonusPayGap,
          2023,
        ),
        AVG23: getGPGAverage(
          state?.fund?.genderPayGapStats?.bonusPayGapStats,
          2023,
        ),
        PROVIDER22: getGPGProvider(
          state?.fund?.genderPayGap?.bonusPayGap,
          2022,
        ),
        AVG22: getGPGAverage(
          state?.fund?.genderPayGapStats?.bonusPayGapStats,
          2022,
        ),
        PROVIDER21: getGPGProvider(
          state?.fund?.genderPayGap?.bonusPayGap,
          2021,
        ),
        AVG21: getGPGAverage(
          state?.fund?.genderPayGapStats?.bonusPayGapStats,
          2021,
        ),
      },
      {
        type: '% of Women in highest paid jobs',
        PROVIDER23: getGPGProvider(
          state?.fund?.genderPayGap?.womenHighestPaidJobs,
          2023,
        ),
        AVG23: getGPGAverage(
          state?.fund?.genderPayGapStats?.womenHighestPaidJobsStats,
          2023,
        ),
        PROVIDER22: getGPGProvider(
          state?.fund?.genderPayGap?.womenHighestPaidJobs,
          2022,
        ),
        AVG22: getGPGAverage(
          state?.fund?.genderPayGapStats?.womenHighestPaidJobsStats,
          2022,
        ),
        PROVIDER21: getGPGProvider(
          state?.fund?.genderPayGap?.womenHighestPaidJobs,
          2021,
        ),
        AVG21: getGPGAverage(
          state?.fund?.genderPayGapStats?.womenHighestPaidJobsStats,
          2021,
        ),
      },
      {
        type: '% of Women in lowest paid jobs',
        PROVIDER23: getGPGProvider(
          state?.fund?.genderPayGap?.womenLowestPaidJobs,
          2023,
        ),
        AVG23: getGPGAverage(
          state?.fund?.genderPayGapStats?.womenLowestPaidJobsStats,
          2023,
        ),
        PROVIDER22: getGPGProvider(
          state?.fund?.genderPayGap?.womenLowestPaidJobs,
          2022,
        ),
        AVG22: getGPGAverage(
          state?.fund?.genderPayGapStats?.womenLowestPaidJobsStats,
          2022,
        ),
        PROVIDER21: getGPGProvider(
          state?.fund?.genderPayGap?.womenLowestPaidJobs,
          2021,
        ),
        AVG21: getGPGAverage(
          state?.fund?.genderPayGapStats?.womenLowestPaidJobsStats,
          2021,
        ),
      },
    ];
  }, [state?.fund?.genderPayGap, state?.fund?.genderPayGapStats]);

  return (
    <div className={style['info-stats-gpg']}>
      <DataTable
        columns={columns}
        data={infoStatsGenderPayGapData}
        uniqueKey="type"
        className={style['info-stats-gpg__table']}
      />
    </div>
  );
};

export default InfoStatsGenderPayGap;
