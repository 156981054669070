// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yCFozAwGzB_UX2eUJXlK {\n  white-space: nowrap;\n  border-radius: 0.25rem;\n  --tw-bg-opacity: 0.1;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  background-color: #dbf1ea;\n  color: #28b98b;\n  font-weight: 700\n}\n", "",{"version":3,"sources":["webpack://./src/components/Badges/style.module.less"],"names":[],"mappings":"AACE;EAAA,mBAAA;EAAA,sBAAA;EAAA,oBAAA;EAAA,oBAAA;EAAA,qBAAA;EAAA,kBAAA;EAAA,iBAAA;EACA,yBAAA;EACA,cAAA;EACA;AAHA","sourcesContent":[".coming-soon-badge {\n  @apply text-xs px-2 font-medium bg-opacity-10 rounded whitespace-nowrap;\n  background-color: rgb(219, 241, 234);\n  color: rgb(40, 185, 139);\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coming-soon-badge": "yCFozAwGzB_UX2eUJXlK"
};
export default ___CSS_LOADER_EXPORT___;
