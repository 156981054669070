import React from 'react';

import SignificantChangesForm from '@/partials/SignificantChanges';

import style from './style.module.less';

const SignificantChanges: React.FCWithChild = () => (
  <div className={style['section-significant-settings']}>
    <div className={style['section-significant-settings__title']}>
      <h2>Significant Changes</h2>
      <p className="pt-4 pb-6">
        You will be alerted about significant changes in your funds/trusts.
        Customise these alerts by setting the parameters below. <br />
        You can change these later in your Settings.
      </p>
      <hr className="mb-4 block sm:hidden" />
      <div className={style['section-significant-settings__body']}>
        <SignificantChangesForm />
      </div>
    </div>
  </div>
);

export default SignificantChanges;
