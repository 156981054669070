import React, { useState } from 'react';

import { Checkbox } from '@/components';
import Button from '@/components/Button';
import { ExploreSearch } from '@/components/Search';

import { bucketList } from './data';
import style from './style.module.less';

type SearchBucketProps = {
  fundCount: number;
  emitAddToBucket: (value: boolean) => void;
};

const SearchBucket: React.FCWithChild<SearchBucketProps> = ({
  fundCount,
  emitAddToBucket,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filteredData, setFilteredData] = useState<any[]>(bucketList);

  const handleFilter = (event?: React.ChangeEvent<HTMLInputElement>): void => {
    const searchWord = event?.target?.value || '';
    const newFilter = bucketList.filter(value => {
      return value.label.toLowerCase().includes(searchWord.toLowerCase());
    });
    setFilteredData(newFilter);
  };

  const handleNext = () => {
    emitAddToBucket(true);
  };

  return (
    <div className={style['search-bucket']}>
      <div className={style['search-bucket__heading-wrapper']}>
        <h3 className={style['search-bucket__title']}>
          {fundCount} funds to add to a bucket
        </h3>
        <p className={style['search-bucket__description']}>
          You must assign every fund/trust to a bucket.
        </p>
      </div>
      <div className={style['search-bucket__content-wrapper']}>
        <div className={style['search-wrapper']}>
          <p className={style['selected-fund-count']}>
            1 funds/trusts selected
          </p>
          <div className={style['search-container']}>
            <ExploreSearch
              isDropDownItems
              searchClassName={style['search-box']}
              searchIconClassName={style['search-icon']}
              onChange={handleFilter}
              placeholder="Search added funds/trust"
              dataTestId="bucketSearchBox"
            />
          </div>
        </div>

        <div className={style['search-bucket__bucket-list']}>
          {filteredData.map(val => {
            return (
              <Checkbox single onChange={() => null}>
                <div className={style['search-bucket__bucket-list-items']}>
                  <span>{val?.label}</span>
                  <span>{val?.value}</span>
                </div>
              </Checkbox>
            );
          })}
        </div>

        <div className={style['search-bucket__button']}>
          <Button
            htmlType="button"
            type="primary"
            className="font-semibold w-full h-12 sm:h-10"
            onClick={handleNext}
          >
            Next
          </Button>
          <Button
            htmlType="button"
            type="link"
            className="font-semibold w-full h-10 mt-4 sm:hidden"
            onClick={handleNext}
          >
            Save as Draft
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchBucket;
