import './style.less';

import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import DesktopMobileImage from '@/assets/images/ami-bg-desktop-mobile.png';
import { ReactComponent as Logo } from '@/assets/svg/logo.svg';
import Button from '@/components/Button';

const Welcome: React.FCWithChild = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/onboarding');
  };
  return (
    <div className="welcome flex flex-row text-center">
      <div className="container w-full mx-auto md:w-12/12 pt-20 md:pt-12 pb-12 px-4 md:px-12">
        <Link to="/" className="site_logo">
          <Logo className="flex mx-auto md:mx-0" />
        </Link>
        <div className="welcome-wrapper">
          <div className="welcome-text mb-6">
            <h2 className="mb-6 text-2xl font-bold">Hello Jerry,</h2>
            <p className="mb-4">We’re excited to have you here.</p>
            <p className="mb-4">
              We’re now going to help you set up your watchlist to personalise
              your dashboard and alerts.
            </p>
            <p className="mb-4">
              This usually takes 10 minutes and after that you can get started,
              or take our guided tour.
            </p>
          </div>
          <Button
            htmlType="button"
            type="primary"
            size="middle"
            className="w-full font-semibold welcome-button"
            onClick={handleClick}
          >
            Start
          </Button>
          <div className="welcome-image">
            <img src={DesktopMobileImage} alt="ami-desktop-mobile" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
