import React from 'react';

import Risk from './InfoAndStats/Risk';
import Performance from './Performance';

const PerfRisk = () => {
  return (
    <>
      <Performance />
      <div className="px-5">
        <div
          className="bg-white my-4 rounded-lg"
          data-test-id="fundDetailsPerfRiskTabRisk"
        >
          <h2 className="text-sm text-darkest font-bold p-4 pb-0">Risk</h2>
          <Risk />
        </div>
      </div>
    </>
  );
};

export default PerfRisk;
