import { NumberValuesOfEnum } from 'src/ts-utils';

import { GlobalSectorEquity, GlobalSectorFixedIncome } from './fund';

export const SECTORS_EQUITY_TO_SHOW: {
  title: string;
  sectorFullName: keyof typeof GlobalSectorEquity;
  type: NumberValuesOfEnum<typeof GlobalSectorEquity>;
}[] = [
  {
    title: 'B.MAT',
    sectorFullName: 'Basic Materials',
    type: 101,
  },
  {
    title: 'C.CYC',
    sectorFullName: 'Consumer Cyclical',
    type: 102,
  },
  {
    title: 'FIN.',
    sectorFullName: 'Financial Services',
    type: 103,
  },
  {
    title: 'R.ESTATE',
    sectorFullName: 'Real Estate',
    type: 104,
  },
  {
    title: 'COMMS',
    sectorFullName: 'Communication Services',
    type: 308,
  },
  {
    title: 'ENERGY',
    sectorFullName: 'Energy',
    type: 309,
  },
  {
    title: 'IND.',
    sectorFullName: 'Industrials',
    type: 310,
  },
  {
    title: 'TECH',
    sectorFullName: 'Technology',
    type: 311,
  },
  {
    title: 'C.DEF',
    sectorFullName: 'Consumer Defensive',
    type: 205,
  },
  {
    title: 'HEALTH.',
    sectorFullName: 'Health Care',
    type: 206,
  },
  {
    title: 'UTIL.',
    sectorFullName: 'Utilities',
    type: 207,
  },
];

export const SECTORS_FIXED_INCOME_TO_SHOW: {
  title: string;
  sectorFullName: keyof typeof GlobalSectorFixedIncome;
}[] = [
  {
    title: 'Cash & Equiv.',
    sectorFullName: 'Cash & Equivalents',
  },
  {
    title: 'Future/Forward',
    sectorFullName: 'Future/Forward',
  },
  {
    title: 'Corporate Bond',
    sectorFullName: 'Corporate Bond',
  },
  {
    title: 'Government',
    sectorFullName: 'Government',
  },
  {
    title: 'Gov. Related',
    sectorFullName: 'Government Related',
  },
  {
    title: 'Convertible',
    sectorFullName: 'Convertible',
  },
  {
    title: 'Preferred',
    sectorFullName: 'Preferred',
  },
  {
    title: 'Asset Backed',
    sectorFullName: 'Asset Backed',
  },
  {
    title: 'Swap',
    sectorFullName: 'Swap',
  },
  {
    title: 'Agency Mort. Backed',
    sectorFullName: 'Agency Mortgage Backed',
  },
  {
    title: 'Covered Bond',
    sectorFullName: 'Covered Bond',
  },
  {
    title: 'Non-Agency Res. Mort.',
    sectorFullName: 'Non-Agency Residential Mortgage Backed',
  },
  {
    title: 'Bank Loan',
    sectorFullName: 'Bank Loan',
  },
  {
    title: 'Comm. Mort. Backed',
    sectorFullName: 'Commercial Mortgage Backed',
  },
];
