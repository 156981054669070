import { EMPTY_DATA_POINT } from '@aminsights/shared';
import React from 'react';

import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import style from './style.module.less';

const Summary: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();

  return state?.fund?.aiGeneratedDescription ? (
    <div className={style['summary']}>
      <p
        className={style['summary__content']}
        data-test-id="fundDetailsSummaryTabOverviewDescription"
      >
        {state.fund?.aiGeneratedDescription ?? EMPTY_DATA_POINT}
      </p>
      {state.fund?.aiGeneratedDescription && (
        <div className="py-3 bottom-0 w-full">
          <p className="text-xxs text-neutral">
            ChatGPT does not make any warranties about the completeness,
            reliability and accuracy of this information.
          </p>
        </div>
      )}
    </div>
  ) : null;
};

export default Summary;
