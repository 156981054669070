import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconCheck } from '@/assets/svg/icons/icon-check.svg';
import Button from '@/components/Button';

import style from './style.module.less';

const Done: React.FCWithChild = () => {
  const history = useHistory();

  return (
    <div className={style['section-done']}>
      <div className="text-center mt-20 sm:mt-0">
        <IconCheck className={cx('icon', style['icon-check'])} />
        <div className={style['section-done__title']}>
          <h2>You are ready to go</h2>
          <p className="pt-4 pb-6">
            Visit the dashboard to view your funds and trusts in action.
          </p>
        </div>
      </div>
      <div className={style['section-done__buttons']}>
        <Button
          size="large"
          type="primary"
          className="font-semibold w-full mb-4 sm:mb-6 h-12 sm:h-10"
          onClick={() => history.push('/dashboard')}
        >
          Go to Dashboard
        </Button>
        <Button
          size="large"
          type="default"
          className="font-semibold w-full h-12 sm:h-10"
          onClick={() => history.push('/watchlist')}
        >
          View Watchlist
        </Button>
      </div>
    </div>
  );
};

export default Done;
