import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

// This is mostly to indicate via naming that route is public,
// as protected route is using withAuthenticationRequired
const PublicRoute: React.FCWithChild<RouteProps> = ({ children, ...rest }) => {
  return <Route {...rest} render={() => children} />;
};

export default PublicRoute;
