export const TREATMENT_NAME_CLARITY = 'clarity';
export const TREATMENT_NAME_PORTFOLIO = 'portfolio';
export const TREATMENT_NAME_PORTFOLIO_IMPORT = 'portfolio-import';
export const TREATMENT_NAME_FUND_DETAILS_ACTIONS = 'fund-details-actions';
export const TREATMENT_NAME_PORTFOLIO_PLATFORMS = 'portfolio-platforms';
export const TREATMENT_SHARE_PORTFOLIO = 'share-portfolio';
export const TREATMENT_NAME_CUSTOM_BENCHMARKS = 'custom-benchmarks';
export const TREATMENT_NAME_PORTFOLIO_RISK_RETURN_CHART =
  'portfolio-risk-return-chart';
export const TREATMENT_NAME_PORTFOLIO_IN_CHARTING_PAGE =
  'portfolio-in-charting-page';
export const TREATMENT_NAME_WEEKLY_UPDATE = 'weekly-update';
export const TREATMENT_NAME_MSCI_BENCHMARK = 'msci-benchmarks';

export enum TreatmentValues {
  On = 'on',
  Off = 'off',
  Control = 'control',
}
