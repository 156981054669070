import cx from 'classnames';
import React from 'react';

import { ReactComponent as IconCheck } from '@/assets/svg/icons/icon-check.svg';
import { MESSAGE_STATE, MessageState } from '@/constants/app';
import { useAppContext } from '@/context/AppContext';

import CustomToaster, { CustomToasterProps } from '..';

// If we want to add other toaster types, instead of creating new components
// we can just add them here.
const TOASTER_TYPES: Partial<Record<MessageState, CustomToasterProps['type']>> =
  {
    [MESSAGE_STATE.SUCCESS]: 'success',
  };

interface Props {
  className?: string;
}

const RegularToaster: React.FCWithChild<Props> = ({ className }) => {
  const { app } = useAppContext();
  return (
    <>
      {app?.messageState &&
        app?.messageState?.messageState !== MESSAGE_STATE.ERROR && (
          <CustomToaster
            icon={
              app?.messageState?.showSnackbarIcon ? (
                <IconCheck className="h-4 w-4" style={{ fill: 'white' }} />
              ) : (
                <></>
              )
            }
            timeTillClose={5000}
            isVisible={!!app.messageState.message}
            message={app?.messageState?.message}
            closable
            className={cx(
              TOASTER_TYPES[app.messageState.messageState],
              className,
            )}
            type={TOASTER_TYPES[app.messageState.messageState]}
          />
        )}
    </>
  );
};

export default RegularToaster;
