import React from 'react';

import { BREAKPOINTS } from '@/constants';
import { useAppContext } from '@/context/AppContext';

interface IWatchlistFundsList {
  isShowSearchFunds: boolean;
}

const WatchlistFundsList: React.FCWithChild<IWatchlistFundsList> = ({
  isShowSearchFunds,
}) => {
  const { app } = useAppContext();

  if (
    !isShowSearchFunds &&
    (app?.windowWidth || window.innerWidth) < BREAKPOINTS.MD
  ) {
    return null;
  }

  return (
    <div className="w-full h-full pt-4">
      <div className="px-6 md:pl-0 md:pr-6">
        <h1 className="md:pl-6 font-bold text-darkest text-xl mb-2">
          Watchlist
        </h1>
        <p className="md:pl-6 text-neutral text-sm mb-4">
          Review your watchlist before proceeding.
        </p>
        <div className="w-full border-t border-solid border-grey-light-50" />
      </div>
    </div>
  );
};

export default WatchlistFundsList;
