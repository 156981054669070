import {
  Fund,
  PortfolioFundForUpdate,
  PortfolioPayload,
} from '@aminsights/contract';
import { sortFundsByValueForLatestDate } from '@aminsights/shared';
import { isTotalSum100 } from '@aminsights/shared';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as IconWarning } from '@/assets/svg/icons/icon-warning.svg';
import { Button, DataTable } from '@/components';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { UploadModalInfoBase } from '@/pages/app/Watchlist/Buckets/components/ImportFunds/types';
import { UpsertPortfolioFields } from '@/partials/Forms/Portfolio/type';
import {
  emptyPortfolioForm,
  UpsertPortfolioForm,
} from '@/partials/Forms/Portfolio/UpsertPortfolioForm';

import { generatePortfolioHoldingFooter } from '../../../Portfolio/Tabs/PortfolioHoldingsTab/columns/generators';
import { useProvidePortfolio } from '../../../useProvidePortfolio';
import CsvFundsDropzone from '../CsvFundsDropzone';
import {
  IImportedFundsData,
  ImportedPortfolioFundsDataTableColumns,
} from './ImportedPortfolioFundsDataTableColumns';
import style from './style.module.less';

const AssignFundsToPortfolioComponent: React.FCWithChild<{
  importedFundsList: (Partial<Fund> & PortfolioFundForUpdate)[];
  allDates: string[];
  onFundsUploadFailure: (
    failedStatus: UploadModalInfoBase,
    filenames: string[],
  ) => void;
  onFundsUploadSuccess: (
    portfolioFunds: PortfolioFundForUpdate[],
    filenames: string[],
    dates: string[],
  ) => void;
  areISINsUnavailable?: boolean;
  onModalOpen?: (isOpen: boolean) => void;
}> = ({
  importedFundsList,
  allDates,
  areISINsUnavailable,
  onModalOpen,
  onFundsUploadFailure,
  onFundsUploadSuccess,
}) => {
  const { app } = useAppContext();
  const isMobile = app.windowWidth < 820;

  const [currentPortfolio, setCurrentPortfolio] =
    useState<PortfolioPayload>(emptyPortfolioForm);
  const { importPortfolio } = useProvidePortfolio();
  const { dispatch: dispatchApp } = useAppContext();

  const [fundsTableList, setFundsTableList] = useState<IImportedFundsData[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);

  const [formHasErrors, setFormHasErrors] = useState(false);
  const [areFundsValid, setAreFundsValid] = useState(true);
  const isDisabled =
    formHasErrors || !areFundsValid || currentPortfolio.name === '';

  useEffect(() => {
    const importedFunds = importedFundsList;

    let valueForAreAllFundsValid = true;
    if (importedFunds) {
      setIsLoading(true);

      const transformedFunds = importedFunds.map(fund => {
        return {
          key: fund?.shareClassDetails?.isin ?? '',
          fundName: fund.fundName,
          shareClassDetails: fund.shareClassDetails,
          isin: fund?.shareClassDetails?.isin ?? '',
          weightings: fund.weightings,
        };
      });

      //Sort by latest weight
      const sorted = sortFundsByValueForLatestDate(
        transformedFunds,
      ) as IImportedFundsData[];

      allDates.forEach(d => {
        let totalSumForDay = 0;
        sorted.forEach(f => {
          f.weightings.forEach(w => {
            if (w.date === d) {
              totalSumForDay += w.value ?? 0;
            }
          });
        });
        const isValidDate = isTotalSum100(totalSumForDay);
        if (!isValidDate) {
          valueForAreAllFundsValid = false;
        }
        sorted.forEach(f => {
          f.weightings.forEach(w => {
            if (w.date === d) {
              w.isValid = isValidDate;
            }
          });
        });
      });

      setFundsTableList(sorted);

      setCurrentPortfolio({
        ...currentPortfolio,
        funds: sorted.map(fund => ({
          isin: fund.isin,
          weightings: fund.weightings,
        })),
      });

      if (transformedFunds && transformedFunds.length > 0) {
        setIsLoading(false);
      }
    }

    setAreFundsValid(valueForAreAllFundsValid);
  }, [importedFundsList]);

  const onPortfolioFormChange = (values: UpsertPortfolioFields) => {
    setCurrentPortfolio({
      ...currentPortfolio,
      name: values.name,
      benchmarkSetting: values.benchmarkSetting,
    });
  };

  const onSubmit = () => {
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    const { _id, ...portfolioToImport } = currentPortfolio;
    return importPortfolio(portfolioToImport)
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Successfully imported portfolio' },
        });
      })
      .catch(e => {
        const errorMessage =
          e.response?.data?.message ??
          'There was an error importing your portfolio, please reach out to us.';
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: errorMessage,
        });
      });
  };

  const footer = useMemo(() => {
    return generatePortfolioHoldingFooter({
      portfolioFunds: currentPortfolio.funds,
      showActionCells: false,
      enablePadding: false,
    });
  }, [currentPortfolio.funds]);

  const columns = useMemo(() => {
    return ImportedPortfolioFundsDataTableColumns(allDates, isMobile);
  }, [allDates, isMobile]);

  return (
    <div className="flex flex-col lg:flex-row gap-4 lg:gap-0">
      <div className={cx('mr-3', style['imported-funds'])}>
        <h2 className={style['title']}>Portfolio Details</h2>
        <div key={`portfolio-form-import`}>
          {currentPortfolio && (
            <UpsertPortfolioForm
              setIsFormValid={isValid => {
                setFormHasErrors(!isValid);
              }}
              onChange={onPortfolioFormChange}
              portfolio={{
                ...currentPortfolio,
                _id: currentPortfolio._id ?? '',
                benchmarkSetting: currentPortfolio.benchmarkSetting,
              }}
              submitting={false}
              error={null}
            />
          )}
        </div>
      </div>
      <div className={style['imported-funds']}>
        {areISINsUnavailable && (
          <div className={style['warning-alert']}>
            <div className="flex items-center">
              <IconWarning width={18} height={18} fill="#CF3B31" />
              <div className="pl-3 font-semibold">ISIN(s) not found</div>
            </div>
            <div className="flex items-center justify-between">
              <div className={cx(style['description'], 'pl-7')}>
                We found ISIN(s) that are not in our database.
              </div>
              <Button
                type="default"
                className={style['view-details-btn']}
                onClick={() => onModalOpen?.(true)}
              >
                View Details
              </Button>
            </div>
          </div>
        )}

        <div className="flex justify-between">
          <h2 className={style['title']}>Funds</h2>
          <div className={cx(style['reupload-div'], 'flex flex-row')}>
            <CsvFundsDropzone
              onFundsUploadFailure={onFundsUploadFailure}
              onFundsUploadSuccess={onFundsUploadSuccess}
            >
              <button
                className={cx(
                  style['reupload'],
                  'items-center cursor-pointer flex flex-row',
                )}
              >
                <p className="text-xs font-medium">Reupload funds</p>
              </button>
            </CsvFundsDropzone>
          </div>
        </div>

        <div className={style['imported-funds-wrapper']}>
          <DataTable
            uniqueKey="isin"
            loading={isLoading}
            footerData={footer}
            enableHeaderWrap={isMobile}
            columns={columns}
            data={fundsTableList}
          />
        </div>
        <div
          className={cx('flex flex-row justify-end mt-4', style['submit-div'])}
        >
          <Button
            onClick={onSubmit}
            disabled={isDisabled}
            className={cx(style['submit-btn'])}
            type="primary"
            htmlType="submit"
            data-test-id="upsertPortfolioSaveButton"
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AssignFundsToPortfolioComponent;
