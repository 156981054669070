import cx from 'classnames';
import React from 'react';

import style from './style.module.less';

interface EmptyStateBaseProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode;
  title?: string;
  subtitle?: React.ReactNode | string;
}

const EmptyStateBase: React.FCWithChild<EmptyStateBaseProps> = ({
  icon,
  title,
  subtitle,
  ...props
}) => (
  <div {...props} className={cx(style.container, props.className)}>
    {icon}
    <h3 className={cx(style['container-title'])}>{title}</h3>
    <p>{subtitle}</p>
  </div>
);

export default EmptyStateBase;
