export const toCamelCase = (str: string): string => {
  return str
    .split(' ')
    .map((word, index) => {
      if (word === word.toUpperCase()) {
        return word;
      }
      if (index === 0) {
        return word.charAt(0).toLowerCase() + word.slice(1);
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('')
    .replace(/\s+/g, '');
};
