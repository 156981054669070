export enum ActivePassiveFilterType {
  All = 'All',
  Active = 'Active',
  Passive = 'Passive',
}

export enum GenderFilterType {
  Male = '1',
  Female = '0',
}

export enum CurrencyHedgedFilterType {
  Fully_Hedged = '1',
  Partially_Hedged = '2',
  No_Hedging_Information = 'N/A',
}
