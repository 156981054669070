import {
  Fund,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  buildFundDetailsPath,
  getFundShareClassDetailsFromArray,
  isFund,
  LIMIT_FUNDS_FOR_CHARTING,
} from '@aminsights/shared';
import { FC, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import { DataTable, PageResults } from '@/components';
import {
  LEGEND_TYPE_ENUM,
  MAX_COMPARE_TOTAL,
  TABLE_UNIQUE_KEY,
} from '@/constants';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import useBenchmarkBySecId from '@/hooks/query-hooks/watchlist-hooks/useBenchmarkBySecId';
import useWatchlistCalendarYearPerformanceInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistCalendarYearPerformanceInfiniteQuery';
import {
  useCurrentBucket,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import { ExtremumsPerformanceLegend } from '@/partials/ExtremumsPerformanceLegend';
import FundTablesActionToast from '@/partials/Toast/FundTablesActionToast';
import { switchTableRowColor } from '@/utils/generate-color';
import notEmpty from '@/utils/notEmpty';

import {
  flattenInfiniteQueryData,
  getTotalFromPaginatedData,
} from '../../utils';
import { WatchlistCalendarPerformanceColumns } from './WatchlistCalendarPerformanceColumns';

const WatchlistCalendarPerformanceTable: FC<{ isActive: boolean }> = ({
  isActive,
}) => {
  const history = useHistory();
  const currentBucket = useCurrentBucket();
  const [sortKey, setSortKey] = useState('fundName');
  const [sortDirection, setSortDirection] =
    useState<PageQueryParametersSortDirectionEnum>(
      PageQueryParametersSortDirectionEnum.Asc,
    );
  const { data, isLoading, fetchNextPage } =
    useWatchlistCalendarYearPerformanceInfiniteQuery({
      sortKey: sortKey,
      sortDirection: sortDirection,
      bucketId: currentBucket?.id,
    });

  const flattenedData = flattenInfiniteQueryData(data);
  const totalNumberOfItems = getTotalFromPaginatedData(data);

  const { setPage, lastElementRef } = useInfiniteScroll(
    totalNumberOfItems,
    flattenedData,
    ({ page }) => {
      if (page > 1) {
        fetchNextPage({ pageParam: page });
      }
    },
    [currentBucket?.id, sortDirection],
  );

  const isins = useMemo(() => {
    return flattenedData.map(item => {
      if (isFund(item)) {
        return item.shareClassDetails.isin;
      }
    });
  }, [flattenedData]);

  const checkedRowState = useOnCheckedRow<Fund>(isins.length || 0);
  const { checkedRows, setCheckedRows, setIsAllCheckboxChecked } =
    checkedRowState;

  useEffect(() => {
    setCheckedRows([]);
  }, [isActive]);

  const currentWatchlist = useCurrentWatchlist();

  const currentFundsList = useMemo(() => {
    if (currentBucket) {
      return currentBucket.funds;
    }
    return currentWatchlist.data?.buckets.flatMap(b => b.funds) ?? [];
  }, [currentWatchlist.data?.buckets, currentBucket]);

  const columns = WatchlistCalendarPerformanceColumns({
    rowSelectionState: checkedRowState,
    onSelectAll: checked => {
      if (checked) {
        const topIsins = flattenedData
          .slice(0, LIMIT_FUNDS_FOR_CHARTING)
          .map(item => item.shareClassDetails.isin);
        setCheckedRows(topIsins ?? []);
      } else {
        setCheckedRows([]);
      }
    },
  });

  const benchmarkData = useBenchmarkBySecId(currentBucket?.indexSecId);

  const outputItems = [benchmarkData.data?.benchmark, ...flattenedData].filter(
    notEmpty,
  );

  const totalIsinsPortal = document.getElementById('total-isins-portal');

  return (
    <>
      {isActive &&
        totalIsinsPortal &&
        ReactDOM.createPortal(
          <PageResults
            className={'min-w-28'}
            totalCount={totalNumberOfItems ?? 0}
          />,
          totalIsinsPortal,
        )}
      <DataTable
        lastElementRef={lastElementRef}
        uniqueKey={TABLE_UNIQUE_KEY}
        loading={isLoading}
        columns={columns}
        data={outputItems}
        onSort={async (
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => {
          setSortKey(key);
          setSortDirection(direction);
          setPage(1); // Resets the infinite scroll page
        }}
        computeRowStyle={rowData => {
          const isFundRow = isFund(rowData);
          const isFeaturedRow = isFundRow
            ? currentFundsList.find(
                f => rowData.shareClassDetails.isin === f?.isin,
              )?.isFeatured ?? false
            : false;
          return {
            backgroundColor: switchTableRowColor(isFeaturedRow, !isFundRow),
          };
        }}
        onRow={id => history.push(buildFundDetailsPath(id))}
        legendType={LEGEND_TYPE_ENUM.ESG}
      />
      {<ExtremumsPerformanceLegend />}
      {checkedRows.length > 0 && (
        <FundTablesActionToast
          isins={checkedRows}
          count={checkedRows?.length || 0}
          onClearCheckedRows={(isins: string[]) => {
            setCheckedRows(isins);
            setIsAllCheckboxChecked(false);
          }}
          checkedRowsClassDetails={checkedRows
            .slice(0, MAX_COMPARE_TOTAL)
            .map(i => getFundShareClassDetailsFromArray(flattenedData, i))}
          dataTableRenderedAt={DataTableRenderedAt.Watchlist}
        />
      )}
    </>
  );
};

export default WatchlistCalendarPerformanceTable;
