import { EMPTY_DATA_POINT } from '../constants/fund';
import { BroadAssetClassColor } from './colors';

export type BoxLabels = {
  xName: string[];
  yName: string[];
  xLongName?: string[];
  yLongName?: string[];
  outerX: string;
  outerY: string;
};

export type BoxXandYLabelsValuesType = {
  [key: string]: BoxLabels;
};

export const FUND_BOX_X_AND_Y_LABEL_VALUE: BoxXandYLabelsValuesType = {
  EQ: {
    xName: ['Value', 'Blend', 'Growth'],
    yName: ['Large', 'Mid', 'Small'],
    xLongName: ['Value', 'Blend', 'Growth'],
    yLongName: ['Large', 'Mid', 'Small'],
    outerX: 'Size',
    outerY: 'Style',
  },
  FI: {
    xName: ['Short', 'Interm.', 'Long'],
    yName: ['High', 'Med', 'Low'],
    xLongName: ['Short Term', 'Intermediate', 'Long Term'],
    yLongName: ['High Quality', 'Medium Quality', 'Low Quality'],
    outerX: 'Credit Risk',
    outerY: 'Duration',
  },
};

export const getShortHumanReadableStyleboxName = (
  index: number | undefined,
  boxLabels: BoxLabels,
): string => {
  if (!index) {
    return EMPTY_DATA_POINT;
  }
  const yAxis = boxLabels.yName[Math.floor((index - 1) / 3)];
  const xAxis = boxLabels.xName[(index - 1) % 3];
  return `${yAxis} ${xAxis}`;
};

export const getLongHumanReadableStyleboxName = (
  index: number,
  boxLabels: BoxLabels,
): string => {
  const yAxis = boxLabels.yLongName
    ? boxLabels.yLongName[Math.floor((index - 1) / 3)]
    : '';
  const xAxis = boxLabels.xLongName
    ? boxLabels.xLongName[(index - 1) % 3]
    : EMPTY_DATA_POINT;
  return `${yAxis} ${xAxis}`;
};

export const getBackgroundStyleboxColor = (
  isFixedIncome: boolean,
): BroadAssetClassColor => {
  return isFixedIncome
    ? BroadAssetClassColor.FI_HIGHLIGHT
    : BroadAssetClassColor.EQ_HIGHLIGHT;
};

export const getHighlightStyleboxColor = (
  isFixedIncome: boolean,
): BroadAssetClassColor => {
  return isFixedIncome
    ? BroadAssetClassColor.FI_BG
    : BroadAssetClassColor.EQ_BG;
};
