import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { InlineDropdown } from '@/components';

import { DropdownItem } from '../Dropdown/shared';

export interface PaginationProps {
  className?: string;
  style?: React.CSSProperties;
  perPage?: number;
  totalCount: number;
  currentStatePage?: number;
  currentStateSize?: number;
  onPageChange: (currentPage: number, itemsPerPage: number) => void;
}

const TOTAL_LIMIT = 10000;
const Pagination: React.FCWithChild<PaginationProps> = ({
  className,
  style,
  perPage = 20,
  totalCount,
  onPageChange,
  currentStatePage,
  currentStateSize,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(perPage);
  const [pageDropdown, setPageDropdown] = useState<Array<DropdownItem>>([]);
  const [firstItem, setFirstItem] = useState<number>(1);
  const [lastItem, setLastItem] = useState<number>(1);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    onPageChange(currentPage, itemsPerPage);
    handleFirstAndLastItem();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setCurrentPage(currentStatePage || 1);
  }, [currentStatePage]);

  useEffect(() => {
    if (currentStateSize) {
      setItemsPerPage(currentStateSize);
      handleItemsPerPage(currentStateSize);
    }
  }, [currentStateSize]);

  useEffect(() => {
    if (totalCount > 0) {
      const pages = Math.floor(totalCount / itemsPerPage);
      const numberOfPages =
        pages * itemsPerPage < totalCount ? pages + 1 : pages;
      setPageDropdown(
        Array(numberOfPages)
          .fill(0)
          .map((_, index) => ({
            label: String(index + 1),
            value: String(index + 1),
          })),
      );
      handleItemsPerPage(itemsPerPage);
    } else {
      setPageDropdown([]);
    }
  }, [itemsPerPage, totalCount]);

  const handleItemsPerPage = (value: number) => {
    setItemsPerPage(Number(value));
    handleFirstAndLastItem();
  };

  const handleCurrentPage = (value: number) => {
    setCurrentPage(Number(value));
    handleFirstAndLastItem();
  };

  const handleFirstAndLastItem = () => {
    setFirstItem(
      currentPage === 1 ? currentPage : itemsPerPage * (currentPage - 1) + 1,
    );
    setLastItem(Math.min(itemsPerPage * currentPage, totalCount));
  };
  const showPlus = totalCount === TOTAL_LIMIT;

  return totalCount ? (
    <div
      className={cx(
        'flex flex-wrap items-center justify-between sm:justify-start pb-3.5 sm:py-3.5 pagination',
        className,
      )}
      style={style}
    >
      <div className="text-xs text-dark mr-2 sm:mr-5 mt-3.5 sm:mt-0">
        Showing
        <InlineDropdown
          className="mx-1"
          items={[
            { label: 20, value: 20 },
            { label: 50, value: 50 },
            { label: 100, value: 100 },
          ]}
          onSelect={handleItemsPerPage}
        >
          <span className="font-bold">
            {firstItem}-{lastItem}
          </span>
        </InlineDropdown>
        of{' '}
        <span className="font-bold">
          {totalCount}
          {showPlus && '+'}
        </span>{' '}
        items
      </div>
      <div className="flex items-center text-xs text-dark font-regular mt-3.5 mr-4 sm:mt-0 sm:mr-0">
        <span className="mr-1.5">Go to page:</span>
        <InlineDropdown items={pageDropdown} onSelect={handleCurrentPage}>
          <div className="flex border border-solid border-light hover:border-primary rounded">
            <div className="h-6 w-6 flex items-center justify-center ml-2">
              <span>{currentPage}</span>
            </div>
            <div className="h-6 w-6 flex items-center justify-center">
              <NextArrow className="icon text-xs transform rotate-90" />
            </div>
          </div>
        </InlineDropdown>
      </div>
    </div>
  ) : null;
};

export default Pagination;
