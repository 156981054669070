import { getFundOrInvestmentTrustTitle } from '@aminsights/shared';
import React, { useMemo, useState } from 'react';

import Tabs from '@/components/Tabs';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import InvestorsFundTable from './FundsTable';
import InvestorsShareClassTable from './ShareClassesTable';

enum InvestorsTabTitle {
  FUND = 'Fund',
  SHARE_CLASS = 'Share Class',
  TRUST = 'Trust',
}

const Investors: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const isFundOrTrust = getFundOrInvestmentTrustTitle(!!isInvestmentTrust);
  const [activeTab, setActiveTab] = useState(
    isInvestmentTrust ? InvestorsTabTitle.TRUST : InvestorsTabTitle.FUND,
  );
  const INVESTORS_TAB = useMemo(() => {
    const shareClasses =
      state?.sharedClass.map(s => s.shareClassDetails.isin) ?? [];

    const tabs = [
      {
        tab: isFundOrTrust,
        component: () =>
          state?.fund.fundId && <InvestorsFundTable isins={shareClasses} />,
        dataTestId: `fundDetailsInvestorsTab${isFundOrTrust}Tab`,
      },
      ...(isInvestmentTrust
        ? []
        : [
            {
              tab: InvestorsTabTitle.SHARE_CLASS,
              component: () =>
                state?.fund.shareClassDetails.isin && (
                  <InvestorsShareClassTable
                    isin={state?.fund.shareClassDetails.isin}
                  />
                ),
              dataTestId: 'fundDetailsInvestorsTabShareClassTab',
            },
          ]),
    ];
    return tabs;
  }, [state?.fund, state?.sharedClass]);

  return (
    <div className="grid grid-cols-12 gap-4 px-5">
      <div className="col-span-12 overflow-hidden">
        <Tabs
          defaultActiveKey="Holdings"
          activeKey={activeTab}
          onChange={activeTab => {
            setActiveTab(activeTab as InvestorsTabTitle);
          }}
          items={INVESTORS_TAB.map(({ tab, component, dataTestId }) => ({
            label: <div data-test-id={dataTestId}>{tab}</div> || '',
            key: tab || '',
            children: <>{component()}</>,
          }))}
        />
      </div>
    </div>
  );
};

export default Investors;
