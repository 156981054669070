import { APP_ROUTE_CHARTS } from '@aminsights/shared/dist';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { SubRouter } from './index';
import { CHARTS_ROUTES } from './routes';

const ChartingToolRouter: React.FCWithChild = () => (
  <SubRouter routes={CHARTS_ROUTES} rootPath={APP_ROUTE_CHARTS}>
    <Route
      exact
      path={`/${APP_ROUTE_CHARTS}`}
      render={() => <Redirect to={`/${APP_ROUTE_CHARTS}/absolute`} />}
    />
  </SubRouter>
);

export default ChartingToolRouter;
