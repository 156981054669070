import cx from 'classnames';
import React from 'react';

import style from './style.module.less';

export const ComingSoonBadge = () => {
  return (
    <span className={cx(style['coming-soon-badge'], ' py-0.5')}>
      COMING SOON
    </span>
  );
};
