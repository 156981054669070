import React from 'react';

import { getShareActionColor } from '@/constants';

import style from './style.module.less';

interface IESGBadgeProps {
  value?: string;
}

export const ESGBadge: React.FCWithChild<IESGBadgeProps> = ({ value }) => {
  return value ? (
    <span
      className={style.badge}
      style={{
        backgroundColor: getShareActionColor(value),
      }}
    />
  ) : (
    <span>-</span>
  );
};
