import { SignificantChangesThreshold } from '@aminsights/contract';
import { useAuth0 } from '@auth0/auth0-react';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { Button } from '@/components';
import { Dropdown } from '@/components/Dropdown';
import Tooltip from '@/components/Tooltip';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import useGetSignificantChangesThreshold from '@/hooks/query-hooks/significant-changes-hooks/useGetSignificantChangesThreshold';
import useSaveSignificantChangesThreshold from '@/hooks/query-hooks/significant-changes-hooks/useSaveSignificantChangesThreshold';
import { SIGNIFICANT_CHANGES } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import queryClient from '@/queryClient';

import significantValues from './significantValues';
import style from './style.module.less';

const SignificantChanges: React.FCWithChild = () => {
  const { user } = useAuth0();
  const { dispatch: dispatchApp } = useAppContext();

  const { mutateAsync: saveSignificantChangesThreshold, isLoading } =
    useSaveSignificantChangesThreshold({
      onSuccess: () => {
        queryClient.invalidateQueries([SIGNIFICANT_CHANGES]);
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Significant Changes settings updated.' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Something went wrong.',
        });
      },
    });
  const { data: significantChangesThresholdResponse } =
    useGetSignificantChangesThreshold();

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors, isValid },
    setValue,
    reset,
  } = useForm<{ significantChangesThreshold: SignificantChangesThreshold }>({
    mode: 'all',
  });

  const setSignificantChangesThresholdValue = () => {
    if (significantChangesThresholdResponse) {
      setValue(
        'significantChangesThreshold',
        significantChangesThresholdResponse,
      );
    }
  };

  useEffect(setSignificantChangesThresholdValue, [
    significantChangesThresholdResponse,
  ]);

  const onFormSubmit = async (values: {
    significantChangesThreshold: SignificantChangesThreshold;
  }) => {
    if (!user?.sub || !isValid) {
      return;
    }

    await saveSignificantChangesThreshold({
      ...values.significantChangesThreshold,
      userId: user?.sub,
    });
    reset({
      significantChangesThreshold: values.significantChangesThreshold,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col w-full md:w-1/2">
            <div className={cx(style['significant-param'], style.right)}>
              <div className={style['significant-param__wrap']}>
                <p className={style['significant-param--label']}>Fund Size</p>
                <Tooltip
                  title="+/-25% means you will be alerted if Fund Size moves from e.g. £500m to >£625m."
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                >
                  <InfoIcon className={cx('icon', style['icon-info'])} />
                </Tooltip>
              </div>
              <Controller
                key="fundSizeThreshold"
                control={control}
                name="significantChangesThreshold.fundSizeThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Dropdown
                      rounded
                      label="Select value"
                      onSelect={(selectedValue: number) => {
                        onChange(selectedValue);
                      }}
                      items={significantValues.default}
                      defaultValue={value}
                      dataTestId="fundSizeThresholdDropdown"
                    />
                  </>
                )}
              />
            </div>
            <div className={cx(style['significant-param'], style.right)}>
              <div className={style['significant-param__wrap']}>
                <p className={style['significant-param--label']}>OCF</p>
                <Tooltip
                  title="+/-0.5% means you will be alerted if the OCF moved from e.g. 1.1% to >1.6%"
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                >
                  <InfoIcon className={cx('icon', style['icon-info'])} />
                </Tooltip>
              </div>
              <Controller
                key="ocfThreshold"
                control={control}
                name="significantChangesThreshold.ocfThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Dropdown
                      rounded
                      label="Select value"
                      onSelect={(selectedValue: number) => {
                        onChange(selectedValue);
                      }}
                      items={significantValues.ocf}
                      defaultValue={value}
                      dataTestId="ocfThresholdDropdown"
                    />
                  </>
                )}
              />
            </div>
            <div className={cx(style['significant-param'], style.right)}>
              <div className={style['significant-param__wrap']}>
                <p className={style['significant-param--label']}>
                  Premium/Discount
                </p>
                <Tooltip
                  title="+/-4% means you will be alerted if the Premium/Discount moved from e.g. -3% to -7%"
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                >
                  <InfoIcon className={cx('icon', style['icon-info'])} />
                </Tooltip>
              </div>
              <Controller
                key="premiumDiscountThreshold"
                control={control}
                name="significantChangesThreshold.premiumDiscountThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Dropdown
                      rounded
                      label="Select value"
                      onSelect={(selectedValue: number) => {
                        onChange(selectedValue);
                      }}
                      items={significantValues.premiumDiscount}
                      defaultValue={value}
                      dataTestId="premiumDiscountThresholdDropdown"
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/2">
            <div className={cx(style['significant-param'], style.left)}>
              <div className={style['significant-param__wrap']}>
                <p className={style['significant-param--label']}>Holdings</p>
                <Tooltip
                  title="+/-25% means you will be alerted if Holdings change from e.g. 50 to >63."
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                >
                  <InfoIcon className={cx('icon', style['icon-info'])} />
                </Tooltip>
              </div>
              <Controller
                key="holdingsThreshold"
                control={control}
                name="significantChangesThreshold.holdingsThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Dropdown
                      rounded
                      label="Select value"
                      onSelect={(selectedValue: number) => {
                        onChange(selectedValue);
                      }}
                      items={significantValues.default}
                      defaultValue={value}
                      dataTestId="holdingsThresholdDropdown"
                    />
                  </>
                )}
              />
            </div>
            <div className={cx(style['significant-param'], style.left)}>
              <div className={style['significant-param__wrap']}>
                <p className={style['significant-param--label']}>Yield</p>
                <Tooltip
                  title="+/-2% means you will be alerted if the Yield moved from e.g. 3.1% to >5.1%"
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                >
                  <InfoIcon className={cx('icon', style['icon-info'])} />
                </Tooltip>
              </div>
              <Controller
                key="yieldThreshold"
                control={control}
                name="significantChangesThreshold.yieldThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Dropdown
                      rounded
                      label="Select value"
                      onSelect={(selectedValue: number) => {
                        onChange(selectedValue);
                      }}
                      items={significantValues.yield}
                      defaultValue={value}
                      dataTestId="yieldThresholdDropdown"
                    />
                  </>
                )}
              />
            </div>
            <div className={cx(style['significant-param'], style.left)}>
              <div className={style['significant-param__wrap']}>
                <p className={style['significant-param--label']}>
                  Modified Duration
                </p>
                <Tooltip
                  title="+/-25% means you will be alerted if Modified Duration moves from e.g. 2 to > 2.5"
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                >
                  <InfoIcon className={cx('icon', style['icon-info'])} />
                </Tooltip>
              </div>
              <Controller
                key="fundSizeThreshold"
                control={control}
                name="significantChangesThreshold.modifiedDurationThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Dropdown
                      rounded
                      label="Select value"
                      onSelect={(selectedValue: number) => {
                        onChange(selectedValue);
                      }}
                      items={significantValues.default}
                      defaultValue={value}
                      dataTestId="durationThresholdDropdown"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <Button
            disabled={
              !isValid ||
              isLoading ||
              !isDirty ||
              Object.keys(errors).length !== 0
            }
            className={style['submit-btn']}
            type="primary"
            htmlType="submit"
            data-test-id="saveSignificantChangesButton"
          >
            {isLoading ? 'Please wait...' : 'Save Changes'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default SignificantChanges;
