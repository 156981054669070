import { useCallback, useEffect, useRef, useState } from 'react';

import { SIZE_PER_PAGE } from '@/constants';

export const useInfiniteScroll = <T>(
  totalCount: number,
  data: T[],
  setParameters: (params: { page: number }) => void,
  dependanciesForSetParameters: any[] = [],
) => {
  const [page, setPage] = useState(1);

  const observer = useRef<IntersectionObserver | null>(null);
  const setSearchParametersCallback = useCallback(setParameters, [
    ...dependanciesForSetParameters,
  ]);

  const lastElementRef = useCallback(
    (node: HTMLTableSectionElement) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && data.length < totalCount) {
          const isNextPageAlreadyLoaded = data.length >= page * SIZE_PER_PAGE;
          const theNextPageToSet = Math.ceil(data.length / SIZE_PER_PAGE);
          const isFirstApiCallIsMade = totalCount > 0;
          if (isNextPageAlreadyLoaded || !isFirstApiCallIsMade)
            setPage(theNextPageToSet + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [totalCount, data, page],
  );

  useEffect(() => {
    const pageTotal = Math.floor((totalCount || 0) / SIZE_PER_PAGE) + 1;

    const fetchData = () => {
      if (page <= pageTotal && setSearchParametersCallback) {
        setSearchParametersCallback({ page });
      }
    };

    fetchData();
  }, [page, totalCount, setSearchParametersCallback]);

  return { page, setPage, data, lastElementRef };
};

// This is temporary.
// useInfiniteScroll is currently used in multiple places.
// Once we start using react-query in all those other places,
// we can remove the other hook.
export const useInfiniteScrollV2 = (
  hasNext: boolean,
  fetchNext: () => void,
) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLTableSectionElement) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNext) {
          fetchNext();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasNext],
  );

  return { lastElementRef };
};
