// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O1F58ue307yUFdCpCXxF .ant-modal-content {\n    border-radius: 0.5rem\n}\n.O1F58ue307yUFdCpCXxF .ant-modal-body {\n    padding-left: 2.5rem;\n    padding-right: 2.5rem;\n    padding-top: 2.5rem\n}\n.O1F58ue307yUFdCpCXxF .ant-modal-close {\n    right: 1.5rem;\n    top: 1.5rem\n}\n.O1F58ue307yUFdCpCXxF .ant-modal-close-x {\n    display: flex;\n    height: 0.75rem;\n    width: 0.75rem;\n    fill: currentColor;\n    --tw-text-opacity: 1;\n    color: rgb(140 146 177 / var(--tw-text-opacity))\n}\n.O1F58ue307yUFdCpCXxF .ant-modal-footer {\n    display: flex;\n    justify-content: flex-end;\n    column-gap: 1rem;\n    border-width: 0px;\n    padding-left: 2.5rem;\n    padding-right: 2.5rem;\n    padding-bottom: 2.5rem;\n    padding-top: 0px\n}\n", "",{"version":3,"sources":["webpack://./src/components/NotificationDetailModal/style.module.less"],"names":[],"mappings":"AAEI;IAAA;AAAA;AAIA;IAAA,oBAAA;IAAA,qBAAA;IAAA;AAAA;AAIA;IAAA,aAAA;IAAA;AAAA;AAEE;IAAA,aAAA;IAAA,eAAA;IAAA,cAAA;IAAA,kBAAA;IAAA,oBAAA;IAAA;AAAA;AAKF;IAAA,aAAA;IAAA,yBAAA;IAAA,gBAAA;IAAA,iBAAA;IAAA,oBAAA;IAAA,qBAAA;IAAA,sBAAA;IAAA;AAAA","sourcesContent":[".notification-detail-modal :global .ant-modal {\n  &-content {\n    @apply rounded-lg;\n  }\n\n  &-body {\n    @apply px-10 pt-10;\n  }\n\n  &-close {\n    @apply right-6 top-6;\n    &-x {\n      @apply flex fill-current text-neutral-100 h-3 w-3;\n    }\n  }\n\n  &-footer {\n    @apply flex justify-end gap-x-4 px-10 pb-10 pt-0 border-0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification-detail-modal": "O1F58ue307yUFdCpCXxF"
};
export default ___CSS_LOADER_EXPORT___;
