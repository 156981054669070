import { AreaConfig } from '@ant-design/charts';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetRelativeChartData from '@/hooks/query-hooks/chart-hooks/useGetRelativeChartData';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import { parseRangeValues, useDatePickerContext } from '@/partials/DatePicker';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';

import ChartEmptyState from '../ChartEmptyState';
import BaseChart, { BaseChartPropsV2 } from '../components/BaseChart';
import { useDropDownContext } from '../Context';
import { PerformanceCardDetails } from '../utils/chart-data';
import { calculateStrokeColor } from '../utils/colors';
import useChartColors from '../utils/useChartColors';
import style from './style.module.less';

interface RelativeChartV2Props extends BaseChartPropsV2 {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
  scrollable?: boolean;
}

const RelativeChartV2: React.FCWithChild<RelativeChartV2Props> = ({
  showFundLinks = true,
  onRemoveFund,
  isins,
  benchmarkId,
  featuredIsins,
  scrollable,
}) => {
  const { value: investmentTrustFilter } = useDropDownContext();
  const { value: datePickerValue } = useDatePickerContext();
  const [legendData, setLegendData] = useState<PerformanceCardDetails[]>([]);

  // Contemplating whether to move this out or not
  // eslint-disable-next-line
  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];

  const { data, isLoading, isFetching } = useGetRelativeChartData({
    benchmarkId,
    isins,
    period,
    investmentTrustReturn: investmentTrustFilter.relative,
    featuredIsins,
  });
  const relativeData = data?.data || [];
  const chartColors = useChartColors({
    isins,
    legend: data?.legendData || [],
  });

  useEffect(() => {
    if (data?.legendData?.length && chartColors) {
      setLegendData(
        // Seperate into transform func
        data.legendData
          .filter(f => !f.isBenchmark)
          .map(f => ({
            ...f,
            tooltip: f.label || '',
            value: f.value || '',
            id: f.id || '',
            isBenchmark: f.isBenchmark || false,
            label: f.label || '',
            isFeatured: f.isFeatured || false,
            color: chartColors[f.id || ''] || '',
          })),
      );
    }
  }, [data, chartColors]);

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: relativeData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return chartColors[id] || '';
      },
    }),
    [relativeData, chartColors],
  );
  // if isins are not defined - show loader no matter what
  // this will mean that we are waiting for isins to be defined to be provided from the parent control

  if (!relativeData.length && !isLoading && !isFetching) {
    return (
      <ChartEmptyState
        title={'No data available for this fund'}
        isDashboardPage={true}
      />
    );
  }

  return (
    <div>
      {!isLoading && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          enableHoverEffects={
            !!featuredIsins?.length && relativeData.some(d => d.isFeatured)
          }
          dataTestId="relativeChart"
          onUpdateLegend={setLegendData}
          scrollable={scrollable}
        />
      )}
      {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
      <div
        data-test-id="relativeChartLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .map(legendItem => (
              <ChartsLegendCard
                key={legendItem.id}
                label={legendItem.label}
                idForFundDetailsLink={
                  showFundLinks ? legendItem.isin : undefined
                }
                id={legendItem.id}
                tooltip={legendItem.tooltip || legendItem.label}
                isIndex={legendItem.isBenchmark}
                color={legendItem.color}
                value={`${legendItem.value}`}
                isFeatured={
                  !!featuredIsins?.length ? legendItem.isFeatured : false
                }
                isActive={legendItem.isActive}
                onRemove={onRemoveFund}
              />
            ))}
        />
      </div>
      {/* Divider is here because it is only on charts page */}
      <div className={style.divider} />
    </div>
  );
};

export default RelativeChartV2;
