import { Portfolio, PortfolioFund } from '@aminsights/contract';
import { DATE_FORMAT_DASHED } from '@aminsights/shared';
import dayjs from 'dayjs';

import { UpdateWeightingForFundInPortfolioAction } from './types';

export const sortPortolioDates = (a: { date: string }, b: { date: string }) => {
  if (a.date === '') {
    return -1;
  } else if (b.date === '') {
    return 1;
  }
  return (
    dayjs(b.date, DATE_FORMAT_DASHED).valueOf() -
    dayjs(a.date, DATE_FORMAT_DASHED).valueOf()
  );
};

const updatePortfolio = (
  value: number,
  item: PortfolioFund,
  date: string,
  updateWeightingForFundInPortfolio: UpdateWeightingForFundInPortfolioAction,
  currentPortfolio: Portfolio,
) => {
  const weighting = {
    value: +value,
    date,
    isValid: true,
  };

  return updateWeightingForFundInPortfolio(
    currentPortfolio._id ?? '',
    item.isin,
    weighting,
  );
};

export const onEnterKeyDown = (
  value: number,
  item: PortfolioFund,
  date: string,
  updateWeightingForFundInPortfolio: UpdateWeightingForFundInPortfolioAction,
  currentPortfolio: Portfolio,
) => {
  return updatePortfolio(
    value,
    item,
    date,
    updateWeightingForFundInPortfolio,
    currentPortfolio,
  );
};

export const onFocusEvent = (
  value: number,
  item: PortfolioFund,
  date: string,
  updateWeightingForFundInPortfolio: UpdateWeightingForFundInPortfolioAction,
  currentPortfolio: Portfolio,
) => {
  return updatePortfolio(
    value,
    item,
    date,
    updateWeightingForFundInPortfolio,
    currentPortfolio,
  );
};
