import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import ChartEmptyState from '@/partials/Charts/ChartEmptyState';
import RelativeChartTitle from '@/partials/Charts/relative/RelativeChartTitle';
import RelativeChartV2 from '@/partials/Charts/relative/RelativeChartV2';
import RelativeChartV2WithPortfolio from '@/partials/Charts/relative/RelativeChartV2WithPortfolio';

import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';
import { useChartingContext } from './ChartingContext';

const RelativeChartingToolPage = () => {
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const {
    isins,
    benchmark,
    setBenchmark,
    setIsins,
    hasInvestmentTrust,
    portfolios,
    setPortfolios,
  } = useChartTrustFundsFromSearch();

  const chartingCtx = useChartingContext();
  const { data, isLoading } = useBenchmarkOptions();
  const legendData = chartingCtx.chartLegend;
  const chartColors = chartingCtx.chartColors;

  if (!(isins.length || portfolios.length) || !benchmark) {
    const title = !!isins.length
      ? 'Relative performance not available'
      : 'No added funds/trusts/portfolios to chart';
    const subTitle = !!isins.length
      ? 'Select an index'
      : 'Search funds/portfolios to chart';
    return (
      <ChartEmptyState
        title={title}
        subtitle={subTitle}
        isDashboardPage={true}
      />
    );
  }

  const matchingBenchmark = data?.find(b => b.secId === benchmark)?.name;
  const ChartsV2 = featureSwitchState?.portfolioInChartingPageEnabled ? (
    <RelativeChartV2WithPortfolio
      emptyState={{
        title: 'No added funds/trusts to chart',
        subTitle: 'Search funds to chart',
      }}
      onRemoveFund={(id, isBenchmark, isPortfolio) => {
        if (isBenchmark) {
          setBenchmark('');
          return;
        }
        if (isPortfolio) {
          setPortfolios([id], true);
          const targetId = legendData.find(ld => ld.id?.includes(id))?.id || '';
          const targetColor = chartColors[targetId];
          targetColor && chartingCtx.setColorsRemoved(targetColor);
          return;
        }
        const targetId = legendData.find(ld => ld.isin === id)?.id || '';
        const targetColor = chartColors[targetId];
        targetColor && chartingCtx.setColorsRemoved(targetColor);
        setIsins([id], true);
      }}
      showDropdown={true}
      isins={isins}
      benchmarkId={benchmark}
      hasInvestmentTrust={hasInvestmentTrust}
      resetInvReturnFilterOnChange={false}
      portfolios={portfolios}
      chartColors={chartColors}
    />
  ) : (
    <RelativeChartV2
      emptyState={{
        title: 'No added funds/trusts to chart',
        subTitle: 'Search funds to chart',
      }}
      onRemoveFund={(isin, isBenchmark) =>
        isBenchmark ? setBenchmark('') : setIsins([isin], true)
      }
      showDropdown={true}
      isins={isins}
      benchmarkId={benchmark}
      hasInvestmentTrust={hasInvestmentTrust}
      resetInvReturnFilterOnChange={false}
    />
  );
  return (
    <>
      <RelativeChartTitle
        hasInvestmentTrust={hasInvestmentTrust}
        benchmarkName={isLoading ? '' : matchingBenchmark}
        showDropdown={true}
      />
      {ChartsV2}
    </>
  );
};

export default RelativeChartingToolPage;
