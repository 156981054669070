import { PageQueryParametersSortDirectionEnum } from '@aminsights/contract';
import cx from 'classnames';
import React from 'react';

import { IDataTableColumns } from '.';
import style from './style.module.less';
import TableHeader from './TableHeader';

interface SubHeaderProps {
  title?: string | React.ReactNode;
  items?: Array<IDataTableColumns>;
  isFixed?: boolean;
  isEndLeft: boolean;
  hasAction: boolean;
  onSort?: (
    key: string,
    sortDirection: PageQueryParametersSortDirectionEnum,
  ) => void;
  setCurrentSort: React.Dispatch<React.SetStateAction<string | undefined>>;
  currentSort?: string;
  isParentAction?: boolean;
  loading?: boolean;
  enableWrap?: boolean;
}

const TableSubHeader: React.FCWithChild<SubHeaderProps> = ({
  items,
  title,
  isFixed,
  isEndLeft,
  hasAction,
  onSort,
  setCurrentSort,
  currentSort,
  isParentAction,
  loading,
  enableWrap = false,
}) => {
  if (items?.length) {
    return (
      <>
        {items?.map(item => (
          <TableHeader
            key={`table-row-sub-header-${item.title}`}
            isSortable={Boolean(item.sortKey)}
            title={item.title}
            width={item?.width}
            minWidth={item?.minWidth}
            align={item?.align || item.renderType === 'text' ? 'left' : 'right'}
            isEndLeft={isEndLeft}
            colSpan={1}
            enableWrap={enableWrap}
            tooltipText={item?.tooltipText}
            hasAction={hasAction}
            onSort={(sortDirection: PageQueryParametersSortDirectionEnum) => {
              if (onSort) {
                onSort(item.sortKey || '', sortDirection);
                setCurrentSort(item.sortKey);
              }
            }}
            isSorting={currentSort === item.sortKey}
            loading={loading}
            subHeader
          />
        ))}
      </>
    );
  }
  return (
    <th
      aria-label={typeof title == 'string' ? title : undefined}
      className={cx({
        [style['table-sub-header__fixed']]: isFixed && !isEndLeft,
        [style['table-sub-header__fixed--has-action']]: isFixed && hasAction,
        'bg-white sticky left-0': isParentAction,
      })}
    />
  );
};

export default TableSubHeader;
