// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eW3vxS_wVpUB4IjQi0G9 {\n  padding: 15px;\n}\n.eW3vxS_wVpUB4IjQi0G9 .gLBWcOq53Xfkvthph4hQ {\n  margin: 30px 0 30px;\n  border-bottom: 1px dashed #ccc;\n}\n.fCZEUjcmJfCuJsCFmRlC {\n  height: 2.5rem !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Portfolio/Portfolio/components/style.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAFA;EAII,mBAAA;EACA,8BAAA;AACJ;AAIE;EAAA;AAAA","sourcesContent":[".chartsTabContainer {\n  padding: 15px;\n\n  .divider {\n    margin: 30px 0 30px;\n    border-bottom: 1px dashed #ccc;\n  }\n}\n\n.bucket-dropdown {\n  @apply h-10 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartsTabContainer": "eW3vxS_wVpUB4IjQi0G9",
	"divider": "gLBWcOq53Xfkvthph4hQ",
	"bucket-dropdown": "fCZEUjcmJfCuJsCFmRlC"
};
export default ___CSS_LOADER_EXPORT___;
