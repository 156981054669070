export const TOP_COUNTRIES_FOR_WATCHLIST = 15;

export const TOP_CURRENCIES_FOR_WATCHLIST = 10;

export enum FundTitleLabel {
  HOLDINGS = 'Holdings',
  SECTORS = 'Sectors',
  SECTORS_FI = 'Sectors (FI)',
  SECTORS_EQ = 'Sectors (Eq)',
  GEOGRAPHY = 'Geography',
  GEOGRAPHY_EQ = 'Geography (Eq)',
  GEOGRAPHY_FI = 'Geography (FI)',
  STYLE = 'Style',
  MATURITY = 'Maturity',
  CREDIT_QUALITY = 'Credit Quality',
  MARKET_CAP = 'Market Cap',
}
