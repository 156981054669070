import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { ReactNode } from 'react';

import ElementWithDataTestId from '@/components/ElementWithDataTestId';
import CorrelationMatrix from '@/partials/CorrelationMatrix/CorrelationMatrix';

import WatchlistEquityGroup from './Group/Equity';
import WatchlistFixedIncomeGroup from './Group/FixedIncome';
import WatchlistPerfRiskGroup from './Group/PerfRisk';
import { ChartsTab } from './Watchlist/components';
import RenderStyleBox from './Watchlist/RenderStyleBox';
import WatchlistAnnualisedPerformanceTable from './WatchlistTables/WatchlistAnnualisedPerformance/WatchlistAnnualisedPerformanceTable';
import WatchlistAssetAllocationTable from './WatchlistTables/WatchlistAssetAllocation/WatchlistAssetAllocationTable';
import WatchlistCalendarPerformanceTable from './WatchlistTables/WatchlistCalendarPerformance/WatchlistCalendarPerformanceTable';
import WatchlistCreditQualityTable from './WatchlistTables/WatchlistCreditQuality/WatchlistCreditQualityTable';
import WatchlistCurrencyTable from './WatchlistTables/WatchlistCurrency/WatchlistCurrencyTable';
import WatchlistEquityStatsTable from './WatchlistTables/WatchlistEquityStats/WatchlistEquityStatsTable';
import WatchlistESGTable from './WatchlistTables/WatchlistESG/WatchlistESGTable';
import WatchlistGenderPayGapTable from './WatchlistTables/WatchlistGenderPayGap/WatchlistGenderPayGapTable';
import WatchlistGeographyEqTable from './WatchlistTables/WatchlistGeography/GeographyEquity/WatchlistGeographyEqTable';
import WatchlistGeographyFITable from './WatchlistTables/WatchlistGeography/GeographyFixedIncome/WatchlistGeographyFITable';
import WatchlistIncomeTable from './WatchlistTables/WatchlistIncome/WatchlistIncomeTable';
import WatchlistMarketCapTable from './WatchlistTables/WatchlistMarketCap/WatchlistMarketCapTable';
import WatchlistMaturityTable from './WatchlistTables/WatchlistMaturity/WatchlistMaturityTable';
import WatchlistRiskTable from './WatchlistTables/WatchlistRisk/WatchlistRiskTable';
import WatchlistSectorsEqTable from './WatchlistTables/WatchlistSectors/SectorsEquity/WatchlistSectorsEqTable';
import WatchlistSectorsFITable from './WatchlistTables/WatchlistSectors/SectorsFixedIncome/WatchlistSectorsFITable';
import WatchlistSummaryTable from './WatchlistTables/WatchlistSummary/WatchlistSummaryTable';

export enum WATCHLIST_TAB_GROUP {
  EQUITY = 'Equity',
  EQUITY_STYLE = 'Equity Style',
  FIXED_INCOME = 'Fixed Income',
  FIXED_INCOME_STYLE = 'Fixed Income Style',
  PERF_AND_RISK = 'Perf and Risk',
}

interface WatchlistTabItem {
  key: WATCHLIST_TAB_KEY | WATCHLIST_TAB_GROUP;
  label: ReactNode;
  children?: ReactNode;
}

export const watchlistTabGroup = [
  WATCHLIST_TAB_GROUP.EQUITY,
  WATCHLIST_TAB_GROUP.FIXED_INCOME,
  WATCHLIST_TAB_GROUP.PERF_AND_RISK,
];

export const getWatchlistTabItems: (
  activeTab: WATCHLIST_TAB_KEY | WATCHLIST_TAB_GROUP,
) => Array<WatchlistTabItem> = activeTab => [
  {
    className: 'fook',
    label: (
      <ElementWithDataTestId
        className="with-label is-watchlist-single-tab-item"
        id={'tabCharts'}
        label={'Charts'}
      />
    ),
    key: WATCHLIST_TAB_KEY.CHARTS,
    children: <ChartsTab />,
  },
  {
    key: WATCHLIST_TAB_KEY.SUMMARY,
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabSummary"
      >
        Summary
      </span>
    ),
    children: (
      <WatchlistSummaryTable
        isActive={activeTab === WATCHLIST_TAB_KEY.SUMMARY}
      />
    ),
  },
  {
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabAssetAllocation"
      >
        Asset Allocation
      </span>
    ),
    key: WATCHLIST_TAB_KEY.ASSET_ALLOCATION,
    children: (
      <WatchlistAssetAllocationTable
        isActive={activeTab === WATCHLIST_TAB_KEY.ASSET_ALLOCATION}
      />
    ),
  },
  {
    label: (
      <div data-test-id="tabGroupEquity">
        <WatchlistEquityGroup />
      </div>
    ),
    key: WATCHLIST_TAB_GROUP.EQUITY,
  },
  {
    label: (
      <div data-test-id="tabGroupFixedIncome">
        <WatchlistFixedIncomeGroup />
      </div>
    ),
    key: WATCHLIST_TAB_GROUP.FIXED_INCOME,
  },
  {
    key: WATCHLIST_TAB_KEY.CURRENCY,
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabCurrency"
      >
        Currency
      </span>
    ),
    children: (
      <WatchlistCurrencyTable
        isActive={activeTab === WATCHLIST_TAB_KEY.CURRENCY}
      />
    ),
  },
  {
    label: (
      <div data-test-id="tabGroupPerfRisk">
        <WatchlistPerfRiskGroup />
      </div>
    ),
    key: WATCHLIST_TAB_GROUP.PERF_AND_RISK,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabEquityStyle"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.STYLE_EQ,
    children: <RenderStyleBox />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabFixedIncomeStyle"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.STYLE_FI,
    children: <RenderStyleBox />,
  },
  {
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="correlationMatrix"
      >
        Correlation
      </span>
    ),
    key: WATCHLIST_TAB_KEY.CORRELATION_MATRIX,
    children: <CorrelationMatrix />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabSectorsEquityEq"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.SECTORS_EQ,
    children: (
      <WatchlistSectorsEqTable
        isActive={activeTab === WATCHLIST_TAB_KEY.SECTORS_EQ}
      />
    ),
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabGoegraphyEq"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.GEOGRAPHY_EQ,
    children: (
      <WatchlistGeographyEqTable
        isActive={activeTab === WATCHLIST_TAB_KEY.GEOGRAPHY_EQ}
      />
    ),
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabMarketCap"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.MARKET_CAP,
    children: (
      <WatchlistMarketCapTable
        isActive={activeTab === WATCHLIST_TAB_KEY.MARKET_CAP}
      />
    ),
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabEquityStats"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.EQUITY_STATS,
    children: (
      <WatchlistEquityStatsTable
        isActive={activeTab === WATCHLIST_TAB_KEY.EQUITY_STATS}
      />
    ),
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabSectorsEquityFi"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.SECTORS_FI,
    children: (
      <WatchlistSectorsFITable
        isActive={activeTab === WATCHLIST_TAB_KEY.SECTORS_FI}
      />
    ),
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabGeographyFi"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.GEOGRAPHY_FI,
    children: (
      <WatchlistGeographyFITable
        isActive={activeTab === WATCHLIST_TAB_KEY.GEOGRAPHY_FI}
      />
    ),
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabMaturity"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.MATURITY,
    children: (
      <WatchlistMaturityTable
        isActive={activeTab === WATCHLIST_TAB_KEY.MATURITY}
      />
    ),
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabCreditQuality"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.CREDIT_QUALITY,
    children: (
      <WatchlistCreditQualityTable
        isActive={activeTab === WATCHLIST_TAB_KEY.CREDIT_QUALITY}
      />
    ),
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabAnnualisedPerformance"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE,
    children: (
      <WatchlistAnnualisedPerformanceTable
        isActive={activeTab === WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE}
      />
    ),
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabCalendarYearPerformance"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.CALENDAR_YEAR_PERFORMANCE,
    children: (
      <WatchlistCalendarPerformanceTable
        isActive={activeTab === WATCHLIST_TAB_KEY.CALENDAR_YEAR_PERFORMANCE}
      />
    ),
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabRisk"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.RISK,
    children: (
      <WatchlistRiskTable isActive={activeTab === WATCHLIST_TAB_KEY.RISK} />
    ),
  },
  {
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabIncome"
      >
        Income
      </span>
    ),
    key: WATCHLIST_TAB_KEY.INCOME,
    children: (
      <WatchlistIncomeTable isActive={activeTab === WATCHLIST_TAB_KEY.INCOME} />
    ),
  },
  {
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabESG"
      >
        ESG
      </span>
    ),
    key: WATCHLIST_TAB_KEY.ESG,
    children: (
      <WatchlistESGTable isActive={activeTab === WATCHLIST_TAB_KEY.ESG} />
    ),
  },
  {
    label: (
      <span
        className="with-label is-watchlist-single-tab-item"
        data-test-id="tabGenderPayGap"
      >
        Gender Pay Gap
      </span>
    ),
    key: WATCHLIST_TAB_KEY.GENDER_PAY_GAP,
    children: (
      <WatchlistGenderPayGapTable
        isActive={activeTab === WATCHLIST_TAB_KEY.GENDER_PAY_GAP}
      />
    ),
  },
];
