export const showSelectedCachedKey = (
  values: (number | string)[],
  marks: Record<number, string>,
) => {
  if (values && values.length > 0) {
    const [min, max] = values;

    const cleanValue = (value: string) => value.replace(/[<+>%£]/g, '');
    const minKey = Object.keys(marks).find(
      (key: string) => cleanValue(marks[Number(key)]) === String(min),
    );
    let maxKey;
    if (typeof max === 'number' && max >= Number.MAX_SAFE_INTEGER) {
      maxKey = Object.keys(marks).pop();
    } else {
      maxKey = Object.keys(marks).find(
        (key: string) => cleanValue(marks[Number(key)]) === String(max),
      );
    }

    return {
      from: minKey && Number(minKey),
      to: maxKey && Number(maxKey),
    };
  }
  return {
    from: undefined,
    to: undefined,
  };
};
