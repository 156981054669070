import { ChartsV2Api, UnboxedChartDataResponse } from '@aminsights/contract';
import { unboxChartDataItemsToChartDataSeriesV2 } from '@aminsights/shared';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import {
  CHARTS,
  CHARTS_V2_PORTFOLIO_RELATIVE,
} from '../watchlist-hooks/query-keys';

export interface ChartParams {
  portfolioId?: string;
  period?: string[];
}

const useGetPortfolioRelativeChartData = (
  params: ChartParams,
  options?: Omit<
    UseQueryOptions<
      UnboxedChartDataResponse | null,
      unknown,
      UnboxedChartDataResponse
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const { period, portfolioId } = params;
  const chartApi = new ChartsV2Api(openApiConfig());

  const enabled = portfolioId && period?.length === 2;

  return useQuery<
    UnboxedChartDataResponse | null,
    unknown,
    UnboxedChartDataResponse
  >(
    [CHARTS, CHARTS_V2_PORTFOLIO_RELATIVE, params],
    async () => {
      if (!enabled) return null;

      const response = await chartApi.getPortfolioRelativeChartData(
        period,
        portfolioId,
      );
      const unboxedData = unboxChartDataItemsToChartDataSeriesV2(
        response?.data?.data,
      );
      return {
        ...response?.data,
        data: unboxedData,
      };
    },
    {
      ...options,
    },
  );
};

export default useGetPortfolioRelativeChartData;
