import {
  Fund,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  buildFundDetailsPath,
  getFundShareClassDetailsFromArray,
  isFund,
  LIMIT_FUNDS_FOR_CHARTING,
} from '@aminsights/shared';
import { FC, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import { DataTable, PageResults } from '@/components';
import { MAX_COMPARE_TOTAL, TABLE_UNIQUE_KEY } from '@/constants';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import useWatchlistEquityStatsInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistEquityStatsInfiniteQuery';
import { useCurrentBucket } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import FundTablesActionToast from '@/partials/Toast/FundTablesActionToast';
import { switchTableRowColor } from '@/utils/generate-color';

import {
  flattenInfiniteQueryData,
  getTotalFromPaginatedData,
} from '../../utils';
import EquityStatsColumns from './WatchlistEquityStatsColumns';

const WatchlistEquityStatsTable: FC<{ isActive: boolean }> = ({ isActive }) => {
  const history = useHistory();
  const currentBucket = useCurrentBucket();
  const [sortKey, setSortKey] = useState('fundName');
  const [sortDirection, setSortDirection] =
    useState<PageQueryParametersSortDirectionEnum>(
      PageQueryParametersSortDirectionEnum.Asc,
    );
  const { data, isLoading, fetchNextPage } =
    useWatchlistEquityStatsInfiniteQuery({
      sortKey: sortKey,
      sortDirection: sortDirection,
      bucketId: currentBucket?.id,
    });

  const flattenedData = flattenInfiniteQueryData(data);
  const totalNumberOfItems = getTotalFromPaginatedData(data);

  const { setPage, lastElementRef } = useInfiniteScroll(
    totalNumberOfItems,
    flattenedData,
    ({ page }) => {
      if (page > 1) {
        fetchNextPage({ pageParam: page });
      }
    },
    [currentBucket?.id, sortDirection],
  );

  const isins = useMemo(() => {
    return flattenedData.map(item => {
      if (isFund(item)) {
        return item.shareClassDetails.isin;
      }
    });
  }, [flattenedData]);

  const checkedRowState = useOnCheckedRow<Fund>(isins.length || 0);
  const { checkedRows, setCheckedRows, setIsAllCheckboxChecked } =
    checkedRowState;

  useEffect(() => {
    setCheckedRows([]);
  }, [isActive]);

  const columns = EquityStatsColumns({
    rowSelectionState: checkedRowState,
    onSelectAll: checked => {
      if (checked) {
        const topIsins = flattenedData
          .slice(0, LIMIT_FUNDS_FOR_CHARTING)
          .map(item => item.shareClassDetails.isin);
        setCheckedRows(topIsins ?? []);
      } else {
        setCheckedRows([]);
      }
    },
  });

  const totalIsinsPortal = document.getElementById('total-isins-portal');

  return (
    <>
      {isActive &&
        totalIsinsPortal &&
        ReactDOM.createPortal(
          <PageResults
            className={'min-w-28'}
            totalCount={totalNumberOfItems ?? 0}
          />,
          totalIsinsPortal,
        )}
      <DataTable
        lastElementRef={lastElementRef}
        uniqueKey={TABLE_UNIQUE_KEY}
        loading={isLoading}
        columns={columns}
        data={flattenedData}
        onSort={async (
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => {
          setSortKey(key);
          setSortDirection(direction);
          setPage(1); // Resets the infinite scroll page
        }}
        onRow={id => history.push(buildFundDetailsPath(id))}
        computeRowStyle={rowData => {
          const isFundRow = isFund(rowData);
          if (!isFundRow) return {};
          const isFeaturedRow = currentBucket?.funds.find(
            f => rowData.shareClassDetails.isin === f?.isin,
          )?.isFeatured;
          return {
            backgroundColor: switchTableRowColor(!!isFeaturedRow, !isFundRow),
            cursor: 'pointer',
          };
        }}
      />
      {checkedRows.length > 0 && (
        <FundTablesActionToast
          isins={checkedRows}
          count={checkedRows?.length || 0}
          onClearCheckedRows={(isins: string[]) => {
            setCheckedRows(isins);
            setIsAllCheckboxChecked(false);
          }}
          checkedRowsClassDetails={checkedRows
            .slice(0, MAX_COMPARE_TOTAL)
            .map(i => getFundShareClassDetailsFromArray(flattenedData, i))}
          dataTableRenderedAt={DataTableRenderedAt.Watchlist}
        />
      )}
    </>
  );
};

export default WatchlistEquityStatsTable;
