/** These configs are being bundled to the frontend, hence by definition public. Ideally
 * they should be hydrated from on the build time from the appropriate SSM param, since they
 * are ingested there via
 */

interface HostConfigs {
  REACT_APP_NODE_ENV: string;
  REACT_APP_API: string;
  REACT_APP_AUTH0_CUSTOM_DOMAIN: string;
  REACT_APP_AUTH0_AUDIENCE: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_AUTH0_CONNECTION_NAME: string;
  REACT_APP_SPLIT_BROWSER_API_KEY: string;
}

interface HostMapping {
  [key: string]: HostConfigs;
}

export const hostMapping: HostMapping = {
  localhost: {
    REACT_APP_NODE_ENV: 'development',
    REACT_APP_API: 'http://localhost:3300/v1',
    REACT_APP_AUTH0_CUSTOM_DOMAIN: 'aminsights-staging.eu.auth0.com',
    REACT_APP_AUTH0_AUDIENCE: 'localhost:3201',
    REACT_APP_AUTH0_CLIENT_ID: 'dVeBl1QN8p7WyR7KuXyitRVLlMlRe3NV',
    REACT_APP_AUTH0_CONNECTION_NAME:
      'amip-Username-Password-Authentication-dev-7b967df',
    REACT_APP_SPLIT_BROWSER_API_KEY: 'io4droc01aplr8rpppmvd6kca5lapcq292nv',
  },
  'app.staging.aminsights.com': {
    REACT_APP_NODE_ENV: 'staging',
    REACT_APP_API: 'https://api.staging.aminsights.com/v1',
    REACT_APP_AUTH0_CUSTOM_DOMAIN: 'identity.staging.aminsights.com',
    REACT_APP_AUTH0_AUDIENCE: 'https://api.staging.aminsights.com',
    REACT_APP_AUTH0_CLIENT_ID: 'LdhH8zmPOTFRH1CPBrzjhEauuBJK4YWf',
    REACT_APP_AUTH0_CONNECTION_NAME:
      'amip-Username-Password-Authentication-staging-833ab04',
    REACT_APP_SPLIT_BROWSER_API_KEY: 'io4droc01aplr8rpppmvd6kca5lapcq292nv',
  },
  'app.aminsights.com': {
    REACT_APP_NODE_ENV: 'production',
    REACT_APP_API: 'https://api.aminsights.com/v1',
    REACT_APP_AUTH0_CUSTOM_DOMAIN: 'identity.aminsights.com',
    REACT_APP_AUTH0_AUDIENCE: 'https://api.aminsights.com',
    REACT_APP_AUTH0_CLIENT_ID: 'bXEbvvlGqZ1sJIpXTbFHn7Zf9gLE695L',
    REACT_APP_AUTH0_CONNECTION_NAME:
      'amip-Username-Password-Authentication-prod-f19a3dd',
    REACT_APP_SPLIT_BROWSER_API_KEY: 'd1mo915l22051e2e4j332ms4rfhhc66sm6p6',
  },
};

export function getConfigValue(key: keyof HostConfigs) {
  const { hostname } = window.location;
  const config = hostMapping[hostname];
  if (!config) {
    throw new Error(`No config found for hostname: ${hostname}`);
  }
  if (!config[key]) {
    throw new Error(`No config found for key: ${key}`);
  }
  return config[key];
}
