export enum EthnicityType {
  White = 'White',
  Asian = 'Asian',
  Black = 'Black',
  Mixed = 'Mixed',
  Others = 'Others',
  PreferNotToSay = 'Prefer not to say',
}

export enum GenderType {
  Male = 'Male',
  Female = 'Female',
  PreferNotToSay = 'Prefer not to say',
}
