import { RipsReturnType } from '@aminsights/shared';
import { AreaConfig } from '@ant-design/charts';
import React, { useEffect, useMemo, useState } from 'react';

import loaderImage from '@/assets/images/graph-mask.png';
import useGetSharePriceVsNavChartData from '@/hooks/query-hooks/chart-hooks/useGetSharePriceVsNavChartData';
import Loader from '@/pages/app/FundAndInvestmentTrust/components/Loader';
import RangeDatePicker, {
  parseRangeValues,
  useDatePickerContext,
} from '@/partials/DatePicker';
import ChartsLegendCard from '@/partials/LegendCards/ChartsLegendCard';

import BaseChart, { BaseChartPropsV2 } from '../components/BaseChart';
import chartStyles from '../utils/chart.module.less';
import { PerformanceCardDetails } from '../utils/chart-data';
import { calculateStrokeColor } from '../utils/colors';
import { useChartColorsById } from '../utils/useChartColors';

interface SharePriceVsNavChartV2Props extends BaseChartPropsV2 {
  hasInvestmentTrust?: boolean;
  showDropdown?: boolean;
}

const SharePriceVsNavChartV2: React.FCWithChild<
  SharePriceVsNavChartV2Props
> = ({
  chartOnly,
  showFundLinks = true,
  onRemoveFund,
  isins,
  benchmarkId,
  featuredIsins,
}) => {
  const { value: datePickerValue, handleChange: handleDatePickerChange } =
    useDatePickerContext();
  const [legendData, setLegendData] = useState<PerformanceCardDetails[]>([]);

  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];

  const { data, isLoading } = useGetSharePriceVsNavChartData({
    benchmarkId,
    isins,
    period,
    investmentTrustReturn: [
      RipsReturnType.SharePrice,
      RipsReturnType.TotalReturn,
    ],
    featuredIsins,
  });
  const sharePriceVsNavData = data?.data || [];
  const legendIds = useMemo(() => {
    const ldClone = data?.legendData?.filter(ld => !ld.isBenchmark);
    return (ldClone || [])
      .sort((a, b) => a?.label?.localeCompare(b?.label || '') || -1)
      .map(ld => ld.id || '');
  }, [data?.legendData]);
  const chartColors = useChartColorsById({
    ids: legendIds,
    legend: data?.legendData || [],
  });

  useEffect(() => {
    if (data?.legendData?.length && chartColors) {
      setLegendData(
        // Seperate into transform func
        data.legendData.map(f => ({
          ...f,
          tooltip: f.label || '',
          value: f.value || '',
          id: f.id || '',
          isBenchmark: f.isBenchmark || false,
          label: f.label || '',
          isFeatured: f.isFeatured || false,
          color: chartColors[f.id || ''] || '',
        })),
      );
    }
  }, [data, chartColors]);

  const config: AreaConfig = useMemo(
    () => ({
      className: 'custom-antd-chart',
      data: sharePriceVsNavData,
      color: ({ id }) => {
        if (!id) {
          return 'FFF';
        }
        return chartColors[id];
      },
    }),
    [sharePriceVsNavData, chartColors],
  );

  return (
    <div>
      {!chartOnly && (
        <div className={chartStyles.header}>
          <h5
            data-test-id="sharePriceVsNavChartLabel"
            className="text-sm font-bold text-darkest"
          >
            <Loader
              width="150px"
              loading={isLoading}
              component={<>Share Price vs. NAV</>}
            />
          </h5>
          <RangeDatePicker
            disabled={isLoading}
            loading={isLoading}
            onChange={handleDatePickerChange}
            value={datePickerValue}
            btnClassName="justify-self-end col-span-2 sm:col-span-1 w-full sm:w-auto"
            dataTestId="sharePriceVsNavChartDropdown"
          />
        </div>
      )}
      {!isLoading && (
        <BaseChart
          config={config}
          strokeColor={calculateStrokeColor(datePickerValue.mode)}
          enableHoverEffects={
            !!featuredIsins?.length &&
            sharePriceVsNavData.some(d => d.isFeatured)
          }
          dataTestId="sharePriceVsNavChart"
          onUpdateLegend={setLegendData}
        />
      )}
      {isLoading && <img className="w-full mt-5 mb-5" src={loaderImage} />}
      <div
        data-test-id="sharePriceVsNavChartLegend"
        className="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3"
      >
        <Loader
          row={2}
          width="150px"
          loading={isLoading}
          component={legendData
            .filter(f => f.label)
            .map(legendItem => (
              <ChartsLegendCard
                key={legendItem.id}
                label={legendItem.label}
                idForFundDetailsLink={
                  showFundLinks ? legendItem.isin : undefined
                }
                id={legendItem.id}
                tooltip={legendItem.tooltip || legendItem.label}
                isIndex={legendItem.isBenchmark}
                color={legendItem.color}
                value={`${legendItem.value}`}
                isFeatured={
                  !!featuredIsins?.length ? legendItem.isFeatured : false
                }
                isActive={legendItem.isActive}
                onRemove={onRemoveFund}
              />
            ))}
        />
      </div>
    </div>
  );
};

export default SharePriceVsNavChartV2;
