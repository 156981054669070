import './style.less';

import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as GraphicFile } from '@/assets/svg/graphic-file.svg';
import { Subtitle } from '@/components';

interface EmptyTabProps {
  title: string;
  subTitle: string;
  redirectLink: string;
}

export const EmptyTab: React.FCWithChild<EmptyTabProps> = ({
  title,
  subTitle,
  redirectLink,
}) => {
  return (
    <div className="empty-tab empty-tab-wrapper flex items-center justify-center text-center px-5">
      <GraphicFile />
      <h1 className="pt-4">{title}</h1>
      <div>
        <Subtitle>
          {subTitle}{' '}
          <Link style={{ color: '#1890ff' }} to={redirectLink}>
            here
          </Link>
        </Subtitle>
      </div>
    </div>
  );
};
