// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LKMdo4qtEVMsm_YCd5bQ {\n  display: flex;\n  justify-content: center;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n}@media (min-width: 768px) {.LKMdo4qtEVMsm_YCd5bQ {\n    padding-left: 1.5rem;\n    padding-right: 1.5rem;\n  }\n}.LKMdo4qtEVMsm_YCd5bQ {\n  min-height: 512px;\n}\n.bIWfSbSQgq87_JsTiobx {\n  width: 100%;\n  text-align: left;\n}\n@media (min-width: 576px) {.bIWfSbSQgq87_JsTiobx {\n    margin-left: 5rem;\n    margin-right: 5rem;\n    text-align: center;\n  }\n}\n@media (min-width: 768px) {.bIWfSbSQgq87_JsTiobx {\n    margin-left: 11rem;\n    margin-right: 11rem;\n  }\n}\n.LKMdo4qtEVMsm_YCd5bQ h2 {\n  font-size: 1.25rem;\n  line-height: 1.75rem;\n  font-weight: 700;\n  --tw-text-opacity: 1;\n  color: rgb(49 51 65 / var(--tw-text-opacity));\n}\n.LKMdo4qtEVMsm_YCd5bQ p {\n  --tw-text-opacity: 1;\n  color: rgb(84 85 118 / var(--tw-text-opacity));\n}\n.clTAPLVr92vHNEgn3qBn {\n  margin-left: auto;\n  margin-right: auto;\n  max-width: 664px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/onboarding/SignificantChanges/style.module.less"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA,uBAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,iBAAA;EAAA;AAAA,CAAA,2BAAA;IAAA,oBAAA;IAAA;EAAA;AAAA,CADF;EAEE,iBAAA;AACF;AAEI;EAAA,WAAA;EAAA;AAAA;AAAA,2BAAA;IAAA,iBAAA;IAAA,kBAAA;IAAA;EAAA;AAAA;AAAA,2BAAA;IAAA,kBAAA;IAAA;EAAA;AAAA;AAIA;EAAA,kBAAA;EAAA,oBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;AAIA;EAAA,oBAAA;EAAA;AAAA;AAIA;EAAA,iBAAA;EAAA,kBAAA;EACA;AADA","sourcesContent":[".section-significant-settings {\n  @apply flex justify-center px-4 md:px-6 py-4;\n  min-height: 512px;\n\n  &__title {\n    @apply w-full text-left sm:text-center sm:mx-20 md:mx-44;\n  }\n\n  h2 {\n    @apply text-darkest font-bold text-xl;\n  }\n\n  p {\n    @apply text-neutral;\n  }\n\n  &__body {\n    @apply mx-auto;\n    max-width: 664px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-significant-settings": "LKMdo4qtEVMsm_YCd5bQ",
	"section-significant-settings__title": "bIWfSbSQgq87_JsTiobx",
	"section-significant-settings__body": "clTAPLVr92vHNEgn3qBn"
};
export default ___CSS_LOADER_EXPORT___;
