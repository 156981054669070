// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JuRUuGDjD_liLU8eauMA,\n.itlhhTbIAmV3J8Fe4BlP {\n  display: flex;\n  flex-direction: column;\n  padding: 0.5rem;\n  width: 30%;\n}\n.JuRUuGDjD_liLU8eauMA h1,\n.itlhhTbIAmV3J8Fe4BlP h1 {\n  font-size: 18px;\n}\n.JuRUuGDjD_liLU8eauMA h5,\n.itlhhTbIAmV3J8Fe4BlP h5 {\n  font-size: 0.75rem;\n}\n@media (max-width: 500px) {\n  .JuRUuGDjD_liLU8eauMA {\n    align-items: flex-start;\n    width: 30%;\n  }\n  .itlhhTbIAmV3J8Fe4BlP {\n    padding: 15px;\n    width: 35%;\n    align-items: flex-start;\n  }\n}\n.qcpZUnF4rA0lI_M9Fvsl {\n  transform: rotate(180deg) scaleX(-1);\n}\n.xQCQfT7a_rWKF5jzJ5jj {\n  padding-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/SignificantChanges/SignificantChangesContentModal/ModalSectionItem/style.module.less"],"names":[],"mappings":"AAIE;;EAAA,aAAA;EAAA,sBAAA;EAAA,eAAA;EACA;AADA;AAFF;;EAKI,eAAA;AACJ;AANA;;EAQI,kBAAA;AAEJ;AAEA;EAEI;IAAA,uBAAA;IACA;EADA;EAIF;IACE,aAAA;IACA,UAAA;IACA,uBAAA;EADF;AACF;AAIA;EACE,oCAAA;AAFF;AAKA;EACE,iBAAA;AAHF","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.sc-section-modal-wrap,\n.sc-section-modal-wrap-premium-discount {\n  @apply flex flex-col p-2;\n  width: 30%;\n  h1 {\n    font-size: 18px;\n  }\n  h5 {\n    font-size: 0.75rem;\n  }\n}\n\n@media (max-width: 500px) {\n  .sc-section-modal-wrap {\n    @apply items-start;\n    width: 30%;\n  }\n\n  .sc-section-modal-wrap-premium-discount {\n    padding: 15px;\n    width: 35%;\n    @apply items-start;\n  }\n}\n\n.flip-up {\n  transform: rotate(180deg) scaleX(-1);\n}\n\n.trend-down-icon {\n  padding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sc-section-modal-wrap": "JuRUuGDjD_liLU8eauMA",
	"sc-section-modal-wrap-premium-discount": "itlhhTbIAmV3J8Fe4BlP",
	"flip-up": "qcpZUnF4rA0lI_M9Fvsl",
	"trend-down-icon": "xQCQfT7a_rWKF5jzJ5jj"
};
export default ___CSS_LOADER_EXPORT___;
