// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".osSxYjArJcPtsGw5UGKa {\n  padding: 0.5rem 0.8rem !important;\n}\n.C1q2OLNH1bytGvISC4fE {\n  padding: 1.2rem 0.8rem !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Watchlist/WatchlistTables/style.module.less"],"names":[],"mappings":"AAAA;EACE,iCAAA;AACF;AAEA;EACE,iCAAA;AAAF","sourcesContent":[".header-checkbox {\n  padding: 0.5rem 0.8rem !important;\n}\n\n.body-checkbox {\n  padding: 1.2rem 0.8rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-checkbox": "osSxYjArJcPtsGw5UGKa",
	"body-checkbox": "C1q2OLNH1bytGvISC4fE"
};
export default ___CSS_LOADER_EXPORT___;
