import { Input } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as EyeHide } from '@/assets/svg/icons/icon-eye-hide.svg';
import { ReactComponent as EyeVisible } from '@/assets/svg/icons/icon-eye-visible.svg';

import styles from './style.module.less';

type InputProps = {
  id: string;
  type: string;
  label?: string;
  name: string;
  dataTestId?: string;
  value?: string | number | readonly string[];
  style?: React.CSSProperties;
  enableTogglePassword?: boolean;
  onChange?: React.ChangeEventHandler;
  onBlur?: React.FocusEventHandler;
  onKeyUp?: React.KeyboardEventHandler;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
};

const CustomInput: React.FCWithChild<InputProps> = ({
  id,
  dataTestId,
  type,
  label,
  name,
  value,
  style,
  enableTogglePassword = false,
  onChange,
  onBlur,
  onKeyUp,
  error,
  disabled,
  placeholder,
  maxLength,
}) => {
  const [isInputProtected, setInputProtected] = useState(enableTogglePassword);

  return (
    <div
      className={cx(
        styles['custom-input'],
        `${error && styles['custom-input__error']}`,
      )}
    >
      <Input
        id={id}
        value={value}
        data-test-id={dataTestId ?? ''}
        type={isInputProtected ? type : 'text'}
        name={name}
        style={style}
        autoComplete="off"
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
      />
      {label && <label htmlFor={id}>{label}</label>}
      {enableTogglePassword && (
        <button
          tabIndex={-1}
          onClick={() => setInputProtected(!isInputProtected)}
          className={styles['custom-input_wrapper']}
          type="button"
        >
          {isInputProtected ? (
            <EyeHide
              className={cx('icon', styles['custom-input_wrapper--eye'])}
            />
          ) : (
            <EyeVisible
              className={cx('icon', styles['custom-input_wrapper--eye'])}
            />
          )}
        </button>
      )}
      {error && (
        <div className="flex items-center">
          <p className="text-xs mt-0.5 text-red-500">{error}</p>
        </div>
      )}
    </div>
  );
};

export default CustomInput;
