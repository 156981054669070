import { TrustBoardDirectors } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import React from 'react';

import { Table } from '@/components/Table';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import style from './style.module.less';

const columns: IDataTableColumns[] = [
  {
    title: 'Name',
    render: (item: TrustBoardDirectors) => item.fullName ?? EMPTY_DATA_POINT,
    renderType: 'text',
  },
  {
    title: 'Since Year',
    render: (item: TrustBoardDirectors) => item.yearStarted ?? EMPTY_DATA_POINT,
    renderType: 'number',
    width: 95,
    align: 'right',
  },
];

const NameList: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();

  return (
    <div className="name-list">
      <Table
        className={style['name-list__table']}
        columns={columns}
        data={
          state?.fund?.boardDirectors
            ? state.fund?.boardDirectors.filter(
                boardDirector => boardDirector.yearStarted !== 'N/A',
              )
            : []
        }
        paginateTotalCount={0}
        uniqueKey="key"
      />
    </div>
  );
};

export default NameList;
