// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._FgOlXRiY7cUnanQ2QbK {\n  display: flex;\n  flex-direction: column;\n}\n@media (min-width: 768px) {\n  ._FgOlXRiY7cUnanQ2QbK {\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n}\n._FgOlXRiY7cUnanQ2QbK .dropdown {\n  width: 100%;\n  padding-top: 1rem;\n}\n@media (min-width: 768px) {\n  ._FgOlXRiY7cUnanQ2QbK .dropdown {\n    width: auto;\n    padding-top: 0px;\n  }\n}\n.g1ggfsGCw_va9_Waq77f.dropdown-button {\n  width: 100%;\n}\n@media (min-width: 768px) {\n  .g1ggfsGCw_va9_Waq77f.dropdown-button {\n    width: 9.5rem;\n  }\n}\n.gUl4C6HIhkE5P9JzYRxO tbody tr:nth-child(2n + 1) {\n  --tw-bg-opacity: 1;\n  background-color: rgb(254 254 254 / var(--tw-bg-opacity));\n}\n.gUl4C6HIhkE5P9JzYRxO tbody tr:nth-child(2n + 2) {\n  --tw-bg-opacity: 1;\n  background-color: rgb(234 234 234 / var(--tw-bg-opacity));\n}\n.gGp1cBoBPlFeKOiHe3Gv {\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/FundAndInvestmentTrust/components/Performance/style.module.less"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA;AAAA;AAAA;EAAA;IAAA,mBAAA;IAAA,mBAAA;IAAA;EAAA;AAAA;AAGI;EAAA,WAAA;EAAA;AAAA;AAAA;EAAA;IAAA,WAAA;IAAA;EAAA;AAAA;AAQA;EAAA;AAAA;AAAA;EAAA;IAAA;EAAA;AAAA;AAOF;EAAA,kBAAA;EAAA;AAAA;AAGA;EAAA,kBAAA;EAAA;AAAA;AAIJ;EACE,YAAA;AAVF","sourcesContent":[".header {\n  @apply flex flex-col md:flex-row md:items-center md:justify-between;\n  :global {\n    .dropdown {\n      @apply pt-4 w-full md:w-auto md:pt-0;\n    }\n  }\n}\n\n:global {\n  :local(.dropdown) {\n    &.dropdown-button {\n      @apply w-full md:w-38;\n    }\n  }\n}\n\n.performance-table {\n  tbody tr:nth-child(2n + 1) {\n    @apply bg-grey-600;\n  }\n  tbody tr:nth-child(2n + 2) {\n    @apply bg-midLight;\n  }\n}\n\n.chartContainer {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "_FgOlXRiY7cUnanQ2QbK",
	"dropdown": "g1ggfsGCw_va9_Waq77f",
	"performance-table": "gUl4C6HIhkE5P9JzYRxO",
	"chartContainer": "gGp1cBoBPlFeKOiHe3Gv"
};
export default ___CSS_LOADER_EXPORT___;
