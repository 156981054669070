// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JIiBFkS_9XaoYRHo0kIz {\n  padding-top: 1.25rem;\n  padding-left: 1.25rem;\n  padding-right: 1.25rem;\n  padding-bottom: 2.5rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  min-height: 512px\n}\n.JIiBFkS_9XaoYRHo0kIz h5 {\n  --tw-text-opacity: 1;\n  color: rgb(49 51 65 / var(--tw-text-opacity))\n}\n.JIiBFkS_9XaoYRHo0kIz a {\n  font-weight: 700\n}\n.JIiBFkS_9XaoYRHo0kIz p {\n  --tw-text-opacity: 1;\n  color: rgb(84 85 118 / var(--tw-text-opacity))\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Settings/SignificantChanges/style.module.less"],"names":[],"mappings":"AACE;EAAA,oBAAA;EAAA,qBAAA;EAAA,sBAAA;EAAA,sBAAA;EACA,aAAA;EAAA,mBAAA;EAAA,uBAAA;EACA;AAFA;AAKE;EAAA,oBAAA;EAAA;AAAA;AAIA;EAAA;AAAA;AAIA;EAAA,oBAAA;EAAA;AAAA","sourcesContent":[".section-significant-settings {\n  @apply pt-5 pl-5 pr-5 pb-10;\n  @apply flex flex-row justify-center;\n  min-height: 512px;\n\n  h5 {\n    @apply text-darkest;\n  }\n\n  a {\n    @apply font-bold;\n  }\n\n  p {\n    @apply text-neutral;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-significant-settings": "JIiBFkS_9XaoYRHo0kIz"
};
export default ___CSS_LOADER_EXPORT___;
