import {
  WatchlistAnnualisedPerformanceResponse,
  WatchlistApi,
} from '@aminsights/contract';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import { SIZE_PER_PAGE } from '@/constants';
import { openApiConfig } from '@/utils';

import calculateNextPageForInfiniteQuery from '../util';
import { ANNUALISED, WATCHLIST } from './query-keys';
import { WatchlistParams } from './watchlistParams';

const useWatchlistAnnualisedPerformanceInfiniteQuery = (
  params?: WatchlistParams,
  options?: Omit<
    UseInfiniteQueryOptions<WatchlistAnnualisedPerformanceResponse, unknown>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { sortKey, sortDirection, bucketId } = params ?? {};
  const watchlistApi = new WatchlistApi(openApiConfig());

  return useInfiniteQuery<WatchlistAnnualisedPerformanceResponse, unknown>(
    [WATCHLIST, ANNUALISED, { sortKey, sortDirection, bucketId }],
    async ({ pageParam = 1 }) => {
      const response = await watchlistApi.getWatchlistAnnualisedPerformance(
        {
          page: pageParam,
          size: SIZE_PER_PAGE,
          sortBy: sortKey,
          sortDirection: sortDirection,
        },
        bucketId,
      );

      return response.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return calculateNextPageForInfiniteQuery(lastPage.total, pages);
      },
      ...options,
    },
  );
};

export default useWatchlistAnnualisedPerformanceInfiniteQuery;
