import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import clsx from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import Tabs, { TabItemProps } from '@/components/Tabs';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { getGender } from '@/utils/gender';

import NameList from '../InfoAndStats/NameList';
import SummaryStats from '../InfoAndStats/SummaryStats';
import style from './style.module.less';

enum SideTabTitle {
  HOLDINGS = 'Manager',
  BOARD = 'Board',
}

interface ManagerData {
  label: string;
  info: string;
}

const ManagerDetails = ({
  data,
  index,
  total,
}: {
  data: ManagerData[];
  index: number;
  total: number;
}) => {
  return (
    <div className="p-4 bg-white">
      <p className="pb-1 text-xs text-right" style={{ color: '#516285' }}>
        {index + 1} of {total} managers
      </p>
      {data.map((d: { label: string; info: string }) => (
        <div className="flex mb-4" key={d.label}>
          <div className="w-4/12">
            <p className="text-sm font-medium text-darkest">{d.label}</p>
          </div>
          <div className="w-8/12 text-sm font-normal text-neutral">
            <p className="text-justify">{d.info}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const formManagerData = (fund: Fund, isInvestmentTrust: boolean) => {
  const managers =
    fund.managerList.length !== 0 ? fund.managerList : [{ givenName: '-' }];

  const data = managers.map(manager => {
    const givenName = manager.givenName || '';
    const familyName = manager.familyName || '';
    const fullName = `${givenName} ${familyName}`.trim() || null;

    let managementEntityName: string | null | undefined;
    if (fullName === 'Not Disclosed') {
      if (isInvestmentTrust) managementEntityName = fund.providerName;
    } else {
      managementEntityName = fullName;
    }

    const bio =
      fullName !== 'Not Disclosed' ? manager.managerProvidedBiography : null;

    return [
      {
        label: 'Manager',
        info: managementEntityName ?? EMPTY_DATA_POINT,
      },
      {
        label: 'Date started',
        info: manager.startDate
          ? dayjs(manager.startDate).format(DISPLAY_DATE_FORMAT)
          : EMPTY_DATA_POINT,
      },
      {
        label: 'Gender',
        info: getGender(manager.gender),
      },
      {
        label: 'Bio',
        info: bio ?? EMPTY_DATA_POINT,
      },
    ];
  });

  return data;
};

const ManagerTable = () => {
  const [activeTab, setActiveTab] = useState(SideTabTitle.HOLDINGS);
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const [managerData, setManagerData] = useState<ManagerData[][]>([]);
  const [activeManager, setActiveManager] = useState<number>(0);

  const arrowIndex = managerData.length - activeManager;
  const shouldShowArrows =
    managerData.length > 0 && activeTab === SideTabTitle.HOLDINGS;

  const handleLeftArrow = () => {
    setActiveManager(p => p - 1);
  };

  const handleRightArrow = () => {
    setActiveManager(p => p + 1);
  };

  const tabManagerItems = useMemo(() => {
    const tabs: TabItemProps[] = [];
    if (managerData.length > 0) {
      tabs.push({
        key: SideTabTitle.HOLDINGS,
        label: (
          <div data-test-id="fundDetailsSummaryTabManagerTableManagerTab">
            {SideTabTitle.HOLDINGS}
          </div>
        ),
        children: (
          <ManagerDetails
            data={managerData[activeManager]}
            index={activeManager}
            total={managerData.length}
          />
        ),
      });
    }

    if (isInvestmentTrust) {
      tabs.push({
        key: SideTabTitle.BOARD,
        label: (
          <div data-test-id="fundDetailsSummaryTabManagerTableBoardTab">
            {SideTabTitle.BOARD}
          </div>
        ),
        children: (
          <div className="bg-white">
            <SummaryStats />
            <NameList />
          </div>
        ),
      });
    }
    return tabs;
  }, [managerData, activeManager, arrowIndex]);

  useEffect(() => {
    if (state?.fund) {
      setManagerData(formManagerData(state.fund, !!isInvestmentTrust));
    }
  }, [state]);

  return managerData.length > 0 ? (
    <div
      className="lg:mr-2 rounded-lg bg-whitecol-span-12 lg:col-span-4"
      data-test-id="fundDetailsSummaryTabManagerTable"
    >
      <Tabs
        defaultActiveKey={SideTabTitle.HOLDINGS}
        activeKey={activeTab}
        onChange={activeTab => {
          setActiveTab(activeTab as SideTabTitle);
        }}
        className={clsx(style['arrow-buttons'])}
        tabBarExtraContent={
          shouldShowArrows ? (
            <div className={clsx('py-2 flex flex-row gap-1 md:gap-9 md:py-0')}>
              <Button
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={handleLeftArrow}
                disabled={arrowIndex == managerData.length}
                data-test-id="fundDetailsSummaryTabManagerTableLeftArrow"
              />
              <Button
                type="text"
                icon={<ArrowRightOutlined />}
                onClick={handleRightArrow}
                disabled={arrowIndex === 1}
                data-test-id="fundDetailsSummaryTabManagerTableRightArrow"
              />
            </div>
          ) : null
        }
        items={tabManagerItems}
      />
    </div>
  ) : null;
};

export default ManagerTable;
