import { PageQueryParametersSortDirectionEnum } from '@aminsights/contract';
/**
 * Returns sorted array
 * @param {T} data  an array to sort
 * @param {string} key key to sort the array by
 * @param {PageQueryParametersSortDirectionEnum} direction sort type: ascending or descending
 * @returns {T[]} new sorted array
 */
export const sortArray = <T>(
  data: T[],
  key: keyof T,
  direction: PageQueryParametersSortDirectionEnum,
) => {
  const sorted = data
    .sort((a: T, b: T) => {
      const avalue = a[key] ?? '';
      const bvalue = b[key] ?? '';
      const multiplier = direction === 'asc' ? 1 : -1;

      let result = 0;
      if (avalue !== bvalue) {
        result = avalue > bvalue ? 1 : -1;
      }
      return result * multiplier;
      // Please don't remove map, otherwise 'sorted' will refere to
      // the same array => setState hook will not be triggered
    })
    .map(p => p);
  return sorted;
};

/**
 * Returns array of additional items without values to add them to current array.
 * @param {number} currentArrayLength  length of current array
 * @param {number} maxLengthAllowed max length allowed for the final array
 * @param {T[]} itemsToBeAdded array from which we get values to be added to the final array
 * @returns {T[]} new array of items to be added
 */
export const getItemsToFillTheRestOfArray = <T>(
  currentArrayLength: number,
  maxLengthAllowed: number,
  itemsToBeAdded: T[],
) => {
  const result = [];
  if (currentArrayLength < maxLengthAllowed) {
    const itemsToAdd = itemsToBeAdded.slice(
      0,
      (maxLengthAllowed - currentArrayLength) / 2,
    );
    if (itemsToAdd.length > 0) {
      result.push(...itemsToAdd);
    }
  }
  return result;
};
