import {
  WatchlistApi,
  WatchlistFundsWithTotalResponse,
} from '@aminsights/contract';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import { SIZE_PER_PAGE } from '@/constants';
import { openApiConfig } from '@/utils';

import calculateNextPageForInfiniteQuery from '../util';
import { MARKET_CAP, WATCHLIST } from './query-keys';
import { WatchlistParams } from './watchlistParams';

const useWatchlistMarketCapInfiniteQuery = (
  params?: WatchlistParams,
  options?: Omit<
    UseInfiniteQueryOptions<WatchlistFundsWithTotalResponse, unknown>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { sortKey, sortDirection, bucketId } = params ?? {};
  const watchlistApi = new WatchlistApi(openApiConfig());

  return useInfiniteQuery<WatchlistFundsWithTotalResponse, unknown>(
    [WATCHLIST, MARKET_CAP, sortKey, sortDirection, bucketId],
    async ({ pageParam = 1 }) => {
      const response = await watchlistApi.getWatchlistMarketCap(
        {
          page: pageParam,
          size: SIZE_PER_PAGE,
          sortBy: sortKey,
          sortDirection: sortDirection,
        },
        bucketId,
      );
      return response.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return calculateNextPageForInfiniteQuery(lastPage.total, pages);
      },
      ...options,
    },
  );
};

export default useWatchlistMarketCapInfiniteQuery;
