import { SignificantChangesItem } from '@aminsights/contract';

import { ChangeTypeEnum, TrendType } from '../constants';

export const ChangeTypeLabels: {
  [key in ChangeTypeEnum]: string;
} = {
  [ChangeTypeEnum.FundSize]: 'Fund Size',
  [ChangeTypeEnum.Holdings]: 'Holdings',
  [ChangeTypeEnum.Ocf]: 'OCF',
  [ChangeTypeEnum.Yield]: 'Yield',
  [ChangeTypeEnum.PremiumDiscount]: 'Premium/Discount',
  [ChangeTypeEnum.ManagerChange]: 'Manager Change',
  [ChangeTypeEnum.StyleDrift]: 'Style Drift',
  [ChangeTypeEnum.ModifiedDuration]: 'Modified Duration',
};

function isChangeTypeEnum(value: any): value is ChangeTypeEnum {
  return Object.values(ChangeTypeEnum).includes(value);
}

export const getSignificantChangeReadableSummary = (
  significantChange: SignificantChangesItem,
) => {
  if (significantChange.changeType === ChangeTypeEnum.ManagerChange) {
    return 'has had a manager change';
  }
  if (significantChange.changeType === ChangeTypeEnum.StyleDrift) {
    return 'has seen a Style Drift';
  }
  if (isChangeTypeEnum(significantChange.changeType)) {
    return `${ChangeTypeLabels[significantChange.changeType]} went ${
      significantChange.trend === TrendType.Upward ? 'up' : 'down'
    } ${significantChange.pctChangeAbsolute?.toFixed(1)}%`;
  }
  // handle the case where significantChange.changeType is not a valid key
  throw new Error(`Invalid changeType: ${significantChange.changeType}`);
};
