import { Popover } from 'antd';
import type { ComponentProps, FC } from 'react';

interface CustomPopoverProps extends ComponentProps<typeof Popover> {
  title?: string;
  placement?:
    | 'topLeft'
    | 'top'
    | 'topRight'
    | 'leftTop'
    | 'left'
    | 'leftBottom'
    | 'rightTop'
    | 'right'
    | 'rightBottom'
    | 'bottomLeft'
    | 'bottom'
    | 'bottomRight';
  color?: string;
  zIndex?: number;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  visible?: boolean;
  content?: React.ReactElement;
  trigger?: 'hover' | 'focus' | 'click';
  autoAdjustOverflow?: boolean;
  arrowPointAtCenter?: boolean;
  overlayClassName?: string;
}

const CustomPopover: FC<CustomPopoverProps> = ({
  children,
  title,
  placement,
  zIndex,
  color,
  mouseEnterDelay,
  mouseLeaveDelay,
  visible,
  content,
  trigger,
  autoAdjustOverflow,
  arrowPointAtCenter,
  overlayClassName,
  ...props
}) => {
  return (
    <Popover
      title={title}
      placement={placement}
      zIndex={zIndex}
      color={color}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      open={visible}
      content={content}
      trigger={trigger}
      autoAdjustOverflow={autoAdjustOverflow}
      arrowPointAtCenter={arrowPointAtCenter}
      overlayClassName={overlayClassName}
      {...props}
    >
      <div className="wrapper">{children}</div>
    </Popover>
  );
};

export default CustomPopover;
