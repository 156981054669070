import { Drawer } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { ReactComponent as IconCLose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as Arrow } from '@/assets/svg/icons/icon-next-arrow.svg';

import style from './Drawer.module.less';

type DrawerProps = {
  className?: string;
  prevText?: string;
  onPrev?: () => void;
  isVisible: boolean;
};

const CustomDrawer: React.FCWithChild<DrawerProps> = ({
  className,
  children,
  prevText,
  onPrev,
  isVisible = false,
}) => {
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  return (
    <Drawer
      className={cx(className, style.drawer)}
      placement="right"
      onClose={onClose}
      closeIcon={<IconCLose className={cx('icon', style['icon-close'])} />}
      open={visible}
    >
      <button
        className={style['drawer__previous-btn']}
        type="button"
        onClick={onPrev}
      >
        <Arrow className={cx('icon', style['icon-arrow'])} /> {prevText}
      </button>
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
