import cx from 'classnames';
import React from 'react';

import { IDataTableColumns } from '.';
import style from './style.module.less';
import TableRowContent from './TableRowContent';

interface RowProps<T> {
  item?: T;
  columns: Array<IDataTableColumns>;
  isEndLeft?: boolean;
  loading?: boolean;
  hasAction: boolean;
  uniqueKey: string;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  rowStyle?: React.CSSProperties;
  disableVerticalRowPadding?: boolean;
  className?: string;
  id?: string;
}

const TableRow = <T,>({
  item,
  columns,
  isEndLeft,
  hasAction,
  loading,
  onClick,
  uniqueKey,
  rowStyle,
  disableVerticalRowPadding,
  className,
  id,
}: RowProps<T>) => {
  if (!item && !loading) {
    return null;
  }

  return (
    <tr
      data-test-id={id}
      className={cx('relative', style['table-row'], className)}
      style={rowStyle}
      onClick={onClick}
    >
      {columns?.map((column: IDataTableColumns) =>
        column?.children?.length ? (
          column?.children.map(subColumn => (
            <TableRowContent
              key={`table-row-content-column-${uniqueKey}-${
                subColumn.idSubText ?? 'end'
              }`}
              item={item}
              column={subColumn}
              isEndLeft={isEndLeft}
              hasAction={hasAction}
              loading={loading}
              disableVerticalPadding={disableVerticalRowPadding}
            />
          ))
        ) : (
          <TableRowContent
            key={
              !!column.idSubText
                ? `table-row-content-column-${uniqueKey}--${column.title}-${
                    column.idSubText ?? 'end'
                  }`
                : `table-row-content-column-${uniqueKey}--${column.title}`
            }
            loading={loading}
            disableVerticalPadding={disableVerticalRowPadding}
            item={item}
            column={column}
            isEndLeft={isEndLeft}
            hasAction={hasAction}
          />
        ),
      )}
    </tr>
  );
};

export default TableRow;
