import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import dayjs from 'dayjs';

export const announcementsData = [
  {
    key: '1',
    investmentTrust: 'Trust A',
    announcement: 'Issue of Equity',
    date: dayjs().add(-1, 'day').format(DISPLAY_DATE_FORMAT),
    time: '10:55',
    color: '#28B98B',
    sharePrice: {
      value: 171.2,
      trend: 'downward',
    },
  },
  {
    key: '2',
    investmentTrust: 'Trust B',
    announcement: 'AGM Statement',
    date: dayjs().add(-2, 'day').format(DISPLAY_DATE_FORMAT),
    time: '07:29',
    color: '#7869E6',
    sharePrice: {
      value: 295.0,
      trend: 'upward',
    },
  },
  {
    key: '3',
    investmentTrust: 'Trust C',
    announcement: 'Dividend Declaration',
    date: dayjs().add(-3, 'day').format(DISPLAY_DATE_FORMAT),
    time: '15:33',
    color: '#DF77C3',
    sharePrice: {
      value: 172.0,
      trend: 'downward',
    },
  },
  {
    key: '4',
    investmentTrust: 'Trust D',
    announcement: 'Transaction in Own Shares',
    date: dayjs().add(-4, 'day').format(DISPLAY_DATE_FORMAT),
    time: '12:05',
    color: '#8AE5D2',
    sharePrice: {
      value: 1299.5,
      trend: 'downward',
    },
  },
];
