// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aZXe6gxki0RyqZd2QF2w .ant-tabs-nav {\n    margin-bottom: 0px;\n    padding-left: 1rem;\n    padding-right: 1rem\n}\n.D2JBkDDwESDYa0neueLw .ant-modal-body {\n    padding: 0px\n}\n.D2JBkDDwESDYa0neueLw .ant-modal-content .ant-modal-header {\n    border-style: none;\n    --tw-bg-opacity: 1;\n    background-color: rgb(255 255 255 / var(--tw-bg-opacity))\n}\n.D2JBkDDwESDYa0neueLw .ant-modal-content .ant-modal-header .ant-modal-title {\n    font-size: 1.125rem;\n    line-height: 1.75rem;\n    font-weight: 600;\n    --tw-text-opacity: 1;\n    color: rgb(49 51 65 / var(--tw-text-opacity))\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Dashboard/style.module.less"],"names":[],"mappings":"AAGM;IAAA,kBAAA;IAAA,kBAAA;IAAA;AAAA;AAOA;IAAA;AAAA;AAGA;IAAA,kBAAA;IAAA,kBAAA;IAAA;AAAA;AAEE;IAAA,mBAAA;IAAA,oBAAA;IAAA,gBAAA;IAAA,oBAAA;IAAA;AAAA","sourcesContent":[".mobile-tabs {\n  :global {\n    .ant-tabs-nav {\n      @apply px-4 mb-0;\n    }\n  }\n}\n.significant-changes-modal {\n  :global {\n    .ant-modal-body {\n      @apply p-0;\n    }\n    .ant-modal-content .ant-modal-header {\n      @apply bg-white border-none;\n      .ant-modal-title {\n        @apply text-lg text-darkest font-semibold;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile-tabs": "aZXe6gxki0RyqZd2QF2w",
	"significant-changes-modal": "D2JBkDDwESDYa0neueLw"
};
export default ___CSS_LOADER_EXPORT___;
