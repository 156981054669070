import './style.less';

import React from 'react';

import { Table } from '@/components/Table';

const columns = [
  {
    title: 'Investment Trust',
    dataIndex: 'investmentTrust',
    render: (text: string) => (
      <div className="custom-table__first-col">
        <div className="custom-table__indicator" />
        {text}
      </div>
    ),
    sorter: {
      compare: (
        a: { investmentTrust: string },
        b: { investmentTrust: string },
      ) => a.investmentTrust.localeCompare(b.investmentTrust),
      multiple: 3,
    },
  },
  {
    title: 'Announcement',
    dataIndex: 'announcement',
    sorter: {
      compare: (a: { announcement: string }, b: { announcement: string }) =>
        a.announcement.localeCompare(b.announcement),
      multiple: 3,
    },
  },
  {
    title: 'Date',
    dataIndex: 'date',
    sorter: {
      compare: (a: { date: string }, b: { date: string }) =>
        a.date.localeCompare(b.date),
      multiple: 3,
    },
  },
  {
    title: 'Time',
    dataIndex: 'time',
    sorter: {
      compare: (a: { time: string }, b: { time: string }) =>
        a.time.localeCompare(b.time),
      multiple: 3,
    },
  },
];

type AnnouncementsProps = {
  key: string;
  investmentTrust: string;
  announcement: string;
  date: string;
  time: string;
};

type AnnouncementsTableProps = {
  data: AnnouncementsProps[];
};

const AnnouncementsTable: React.FCWithChild<AnnouncementsTableProps> = ({
  data,
}) => {
  return (
    <div className="announcements-table">
      <Table
        uniqueKey="key"
        columns={columns}
        data={data}
        paginateTotalCount={0}
      />
    </div>
  );
};

export default AnnouncementsTable;
