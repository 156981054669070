import {
  Fund,
  PageQueryParametersSortDirectionEnum,
  WatchlistBenchmarkResponse,
} from '@aminsights/contract';
import { isInvestmentTrust } from '@aminsights/shared';
import { FC, useState } from 'react';

import { DataTable } from '@/components';
import { AnnualisedPerformanceColumns } from '@/pages/app/FundAndInvestmentTrust/components/Performance/columns';

import {
  annualisedRowMapper,
  benchmarkAnnualisedMapper,
} from '../Performance/rowMapper';
import { IAnnualisedPerformanceData } from '../Performance/types';
import { compareDataSort } from './utils/compareDataSort';

interface CompareAnnualisedPerformanceProps {
  benchmarkData?: WatchlistBenchmarkResponse;
  loading?: boolean;
  funds?: Fund[];
}

const CompareAnnualisedPerformance: FC<CompareAnnualisedPerformanceProps> = ({
  benchmarkData,
  loading = true,
  funds,
}) => {
  const [sortedData, setSortedData] = useState<IAnnualisedPerformanceData[]>(
    [],
  );
  const isAllInvestmentTrust = !!funds?.every(f =>
    isInvestmentTrust(f.legalStructure),
  );

  const annualisedPerformanceData: Array<IAnnualisedPerformanceData> = (
    funds || []
  ).map(fund => annualisedRowMapper(fund.shareClassDetails.isin, fund));

  const performance = benchmarkData?.benchmark;
  performance &&
    annualisedPerformanceData.push(
      benchmarkAnnualisedMapper('benchmark', performance),
    );

  return (
    <DataTable
      className="xl:table-fixed"
      loading={loading}
      loaderSize={7}
      uniqueKey="key"
      columns={AnnualisedPerformanceColumns(isAllInvestmentTrust, true)}
      data={sortedData.length > 1 ? sortedData : annualisedPerformanceData}
      onSort={async (
        key: string,
        direction: PageQueryParametersSortDirectionEnum,
      ) => {
        setSortedData(
          compareDataSort(annualisedPerformanceData, key, direction),
        );
      }}
    />
  );
};

export default CompareAnnualisedPerformance;
