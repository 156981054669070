import { Pie } from '@ant-design/charts';
import React from 'react';

interface IPieChartData {
  type: string;
  value: number;
}

type PieChartProps = {
  data: Array<IPieChartData>;
  title: string;
  colors: string[];
};

const PieChart: React.FCWithChild<PieChartProps> = ({
  title,
  data,
  colors,
}) => {
  const config = {
    height: 180,
    width: 180,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.75,
    tooltip: undefined,
    pieStyle: { lineWidth: 0 },
    color: colors,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 0,
      },
    },
    statistic: {
      title: undefined,
      content: {
        content: title,
        style: {
          color: '#313341',
          fill: '#545576',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 'medium',
          overflow: 'hidden',
          padding: '0 20px',
          textOverflow: 'ellipsis',
          whiteSpace: 'pre-wrap',
        },
      },
    },
  };
  return <Pie {...config} />;
};

export default PieChart;
