/* eslint-disable no-param-reassign */
import { FundBroadAssetClass } from '@aminsights/contract';
import {
  ActivePassiveFilterType,
  CurrencyHedgedFilterType,
  GenderFilterType,
  LEGAL_STRUCTURE,
  PLATFORMS_FILTERS,
} from '@aminsights/shared';
import { Checkbox, Divider, Slider } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Accordion, { Panel } from '@/components/Accordion';
import { Checkbox as MultiCheckBox } from '@/components/Checkbox';
import { SLIDER_MIN } from '@/constants';
import useProvideExplore from '@/pages/app/Explore/useProvideExplore';
import ExploreFilterModal from '@/partials/ExploreFilterModal';

import { useExploreDefaultFiltersQuery } from '../context';
import { ExploreModalProps } from '../types';
import { adjustMarksToAvoidDuplicates } from '../utils/adjustMarksToAvoidDuplicates';
import { countSelectedFilter } from '../utils/countSelectedFilter';
import { getChangedMarkValues } from '../utils/getChangedMarkValues';
import { getRangeSliderClassName } from '../utils/getRangeSliderClassName';
import { getValueFromMarks } from '../utils/getValueFromMarks';
import { updateRangeAfterSubmit } from '../utils/updateRangeAfterSubmit';
import { Search } from './Search';
import style from './style.module.less';

const SLIDER_STEP = 16;
const SLIDER_MAX_NO_HOLDINGS = 96;
const SLIDER_MAX_YIELD = 96;

const yieldMarks: Record<number, string> = {
  0: '0',
  16: '2%',
  32: '4%',
  48: '6%',
  64: '8%',
  80: '10%',
  96: '12%>',
};

const noOfHoldingMarks: Record<number, string> = {
  0: '0',
  16: '35',
  32: '50',
  48: '75',
  64: '100',
  80: '125',
  96: '150>',
};

export const MoreFiltersModal: React.FCWithChild<ExploreModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { state, setFilterParameters } = useProvideExplore();
  const { data: defaultfiltersOptions } = useExploreDefaultFiltersQuery();

  const [yieldRange, setYieldRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX_YIELD,
  ]);
  const [yieldMin, yieldMax] = yieldRange;

  const [noOfHoldingsRange, setNoOfHoldingsRange] = useState<number[]>([
    SLIDER_MIN,
    SLIDER_MAX_NO_HOLDINGS,
  ]);
  const [noOfHoldingsMin, noOfHoldingsMax] = noOfHoldingsRange;

  const [managementGroupFilter, setManagementGroupFilter] =
    useState<string>('');
  const [providers, setProviders] = useState<
    { label: string; checked: boolean }[]
  >([]);
  const [managementGroup, setManagementGroup] = useState<string[]>();

  const [selectedBroadAssetClasses, setSelectedBroadAssetClasses] = useState<
    FundBroadAssetClass[]
  >([]);
  const [legalStructure, setLegalStructure] = useState<
    LEGAL_STRUCTURE[] | undefined
  >();

  const [domicilesFilter, setDomicilesFilter] = useState<string>('');
  const [domicileList, setDomicileList] = useState<
    { label: string; checked: boolean; value: string }[]
  >([]);
  const [domiciles, setDomiciles] = useState<string[]>();

  const [currenciesFilter, setCurrenciesFilter] = useState<string>('');
  const [currencyList, setCurrencyList] = useState<
    { label: string; checked: boolean; value: string }[]
  >([]);
  const [currencies, setCurrencies] = useState<string[]>();

  const [platformFilter, setPlatformFilter] = useState<string>('');
  const [platformList, setPlatformList] = useState<
    { label: string; checked: boolean; value: string }[]
  >([]);
  const [platforms, setPlatforms] = useState<string[]>();

  const [filterByOldestShareClassOnly, setFilterByOldestShareClassOnly] =
    useState<boolean | undefined>(false);
  const [yieldChange, setYieldChange] = useState(false);
  const [holdingChange, setHoldingChange] = useState(false);
  const [filterByActiveOrPassive, setFilterByActiveOrPassive] = useState<
    string[] | undefined
  >();
  const [genders, setGenders] = useState<string[]>();
  const [currencyHedgedType, setCurrencyHedgedType] = useState<string[]>();

  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [disabled, setDisabled] = useState(true);
  const [managementFilterIsVisible, setManagementFilterIsVisible] =
    useState<boolean>(false);

  const handleOnClear = () => {
    setYieldRange([SLIDER_MIN, SLIDER_MAX_YIELD]);
    setYieldChange(false);
    setNoOfHoldingsRange([SLIDER_MIN, SLIDER_MAX_NO_HOLDINGS]);
    setLegalStructure(undefined);
    setHoldingChange(false);
    setManagementGroupFilter('');
    setManagementGroup([]);
    setDomicilesFilter('');
    setDomiciles([]);
    setCurrenciesFilter('');
    setCurrencies([]);
    setPlatformFilter('');
    setPlatforms([]);
    setSelectedBroadAssetClasses([]);
    setFilterByOldestShareClassOnly(undefined);
    setFilterByActiveOrPassive([]);
    setProviders(
      providers?.map(provider => ({
        label: provider.label,
        checked: false,
      })),
    );
    setDomicileList(
      domicileList?.map(domicile => ({
        label: domicile.label,
        checked: false,
        value: domicile.value,
      })),
    );

    setCurrencyList(
      currencyList?.map(currency => ({
        label: currency.label,
        checked: false,
        value: currency.value,
      })),
    );

    setPlatformList(
      platformList?.map(platform => {
        return {
          label: platform.label,
          checked: false,
          value: platform.value,
        };
      }),
    );

    setGenders([]);
    setCurrencyHedgedType([]);
  };

  useEffect(() => {
    setFilterByOldestShareClassOnly(
      state.filterParameters?.filterByOldestShareClassOnly,
    );
  }, [state.filterParameters?.filterByOldestShareClassOnly]);

  useEffect(() => {
    if (state.clearState) {
      handleOnClear();
    }
  }, [state.clearState]);

  const handleOnApply = () => {
    const [yield12MonthMin, yield12MonthMax] = getValueFromMarks(
      yieldRange,
      yieldMarks,
    );
    const [holdingMin, holdingMax] = getValueFromMarks(
      noOfHoldingsRange,
      noOfHoldingMarks,
    );
    if (yieldChange) {
      setFilterParameters({
        yieldRange: [
          yield12MonthMin,
          yield12MonthMax === 12 ? Number.MAX_SAFE_INTEGER : yield12MonthMax,
        ],
      });
    }
    if (holdingChange) {
      setFilterParameters({
        noOfHoldingsRange: [
          holdingMin,
          holdingMax === 150 ? Number.MAX_SAFE_INTEGER : holdingMax,
        ],
      });
    }

    if (selectedFilter === 0) {
      setFilterParameters({
        yieldRange: [],
        noOfHoldingsRange: [],
        legalStructure: undefined,
      });
    }

    const managementGroupIndices = managementGroup?.map(
      label => `${providers?.findIndex(i => i.label === label)}`,
    );
    setFilterParameters({
      managementGroup: managementGroupIndices,
      broadAssetClasses: selectedBroadAssetClasses,
      legalStructure,
      filterByOldestShareClassOnly,
      indexFund: filterByActiveOrPassive,
      domiciles: domiciles,
      currencies: currencies,
      platforms: platforms,
      genders: genders,
      currencyHedgedType: currencyHedgedType,
    });
    setManagementGroupFilter('');
    setDomicilesFilter('');
    setCurrenciesFilter('');
    setPlatformFilter('');
    onClose();
  };

  const providerNames = useMemo(() => {
    return (
      defaultfiltersOptions.providerNames?.map(
        (provider: { label: string }, idx) => ({
          label: provider.label,
          checked: Boolean(
            state.filterParameters.managementGroup?.includes(`${idx}`),
          ),
          value: `${idx}`,
        }),
      ) || []
    );
  }, [defaultfiltersOptions.providerNames]);

  useEffect(() => {
    if (providerNames.length && !providers?.length) {
      setProviders(providerNames);
    }
  }, [providerNames]);

  const domicileNames = useMemo(() => {
    return (
      defaultfiltersOptions.domiciles?.map((domicile: { label: string }) => ({
        label: domicile.label,
        checked: Boolean(
          state.filterParameters.domiciles?.includes(domicile.label),
        ),
        value: domicile.label,
      })) || []
    );
  }, [defaultfiltersOptions.domiciles]);

  useEffect(() => {
    if (domicileNames.length && !domicileList?.length) {
      setDomicileList(domicileNames);
    }
  }, [domicileNames]);

  const currencyNames = useMemo(() => {
    return (
      defaultfiltersOptions.currencies?.map((currency: { label: string }) => ({
        label: currency.label,
        checked: Boolean(
          state.filterParameters.currencies?.includes(`${currency.label}`),
        ),

        value: currency.label,
      })) || []
    );
  }, [defaultfiltersOptions.currencies]);

  useEffect(() => {
    if (currencyNames.length && !currencyList?.length) {
      setCurrencyList(currencyNames);
    }
  }, [currencyNames]);

  const platformNames = useMemo(() => {
    return (
      defaultfiltersOptions.platforms?.map((platform: { value: string }) => {
        const platformFilter = PLATFORMS_FILTERS.find(
          item => item.key === platform.value,
        );

        return {
          label: platformFilter ? platformFilter.label : '',
          checked: Boolean(
            state.filterParameters.platforms?.includes(`${platform.value}`),
          ),
          value: platform.value,
        };
      }) || []
    );
  }, [defaultfiltersOptions.platforms]);

  useEffect(() => {
    if (platformNames.length && !platformList?.length) {
      setPlatformList(platformNames);
    }
  }, [platformNames]);

  useEffect(() => {
    const provideMap =
      providers
        ?.filter(provider => provider.checked)
        .map(provider => provider.label) || [];

    if (JSON.stringify(provideMap) !== JSON.stringify(managementGroup)) {
      setManagementGroup(provideMap);
    }

    const domicileMap =
      domicileList
        ?.filter(domicile => domicile.checked)
        .map(domicile => domicile.label) || [];

    if (JSON.stringify(domicileMap) !== JSON.stringify(domiciles)) {
      setDomiciles(domicileMap);
    }

    const currencyMap =
      currencyList
        ?.filter(currency => currency.checked)
        .map(currency => currency.label) || [];

    if (JSON.stringify(currencyMap) !== JSON.stringify(currencies)) {
      setCurrencies(currencyMap);
    }

    const platformMap =
      platformList
        ?.filter(platform => platform.checked)
        .map(platform => platform.value) || [];

    if (JSON.stringify(platformMap) !== JSON.stringify(platforms)) {
      setPlatforms(platformMap);
    }

    const count = countSelectedFilter([
      yieldChange,
      holdingChange,
      provideMap.length !== 0,
      selectedBroadAssetClasses.length !== 0,
      legalStructure ? true : false,
      filterByOldestShareClassOnly ?? false,
      filterByActiveOrPassive?.length !== 0,
      !!domiciles?.length,
      !!currencies?.length,
      !!platforms?.length,
      !!genders?.length,
      !!currencyHedgedType?.length,
    ]);

    setSelectedFilter(count);
  }, [
    providers,
    yieldChange,
    holdingChange,
    selectedBroadAssetClasses,
    legalStructure,
    filterByOldestShareClassOnly,
    filterByActiveOrPassive,
    domicileList,
    domiciles,
    currencyList,
    currencies,
    platformList,
    platforms,
    genders,
    currencyHedgedType,
  ]);

  useEffect(() => {
    const filters = state.filterParameters;

    setDisabled(
      ![
        filters.managementGroup,
        filters.domiciles,
        filters.currencies,
        filters.platforms,
        filters.yieldRange,
        filters.noOfHoldingsRange,
        filters.broadAssetClasses,
        filters.legalStructure,
        filters.filterByOldestShareClassOnly,
        filters.indexFund,
      ].some(i => (Array.isArray(i) ? !!i.length : !!i)),
    );

    if (filters?.managementGroup) {
      setManagementGroup(filters.managementGroup);
    }

    if (filters?.domiciles) {
      setDomiciles(filters.domiciles);
    }

    if (filters?.currencies) {
      setCurrencies(filters.currencies);
    }

    if (filters?.platforms) {
      setPlatforms(filters.platforms);
    }

    updateRangeAfterSubmit(
      filters.yieldRange || [],
      setYieldRange,
      setYieldChange,
      yieldMarks,
    );
    updateRangeAfterSubmit(
      filters.noOfHoldingsRange || [],
      setNoOfHoldingsRange,
      setHoldingChange,
      noOfHoldingMarks,
    );

    if (filters?.broadAssetClasses) {
      setSelectedBroadAssetClasses(filters.broadAssetClasses);
    }

    if (filters?.legalStructure) {
      setLegalStructure(filters.legalStructure);
    }

    if (filters?.genders) {
      setGenders(filters.genders);
    }

    if (filters?.currencyHedgedType) {
      setCurrencyHedgedType(filters.currencyHedgedType);
    }

    if (filters?.filterByOldestShareClassOnly) {
      setFilterByOldestShareClassOnly(filters.filterByOldestShareClassOnly);
    }

    if (filters?.indexFund) {
      setFilterByActiveOrPassive(filters.indexFund);
    }
  }, [
    state.filterParameters.managementGroup,
    state.filterParameters.domiciles,
    state.filterParameters.currencies,
    state.filterParameters.platforms,
    state.filterParameters.yieldRange,
    state.filterParameters.noOfHoldingsRange,
    state.filterParameters.broadAssetClasses,
    state.filterParameters.legalStructure,
    state.filterParameters.genders,
    state.filterParameters.currencyHedgedType,
    state.filterParameters.filterByOldestShareClassOnly,
    state.filterParameters.indexFund,
  ]);

  // filter the providers based on the search text
  const searchedProviders =
    providers?.filter(provider =>
      provider.label.toLowerCase().includes(managementGroupFilter),
    ) || [];

  const isEveryProviderInViewChecked = useMemo(
    () => searchedProviders?.every(provider => provider.checked),
    [searchedProviders],
  );

  // filter the domicileList based on the search text
  const searchedDomicile =
    domicileList?.filter(domicile =>
      domicile.label.toLowerCase().includes(domicilesFilter),
    ) || [];

  const isEveryDomicileInViewChecked = useMemo(
    () => searchedDomicile?.every(domicile => domicile.checked),
    [searchedDomicile],
  );

  // filter the currencyList based on the search text
  const searchedCurrency =
    currencyList?.filter(currency =>
      currency.label.toLowerCase().includes(currenciesFilter),
    ) || [];

  const isEveryCurrencyInViewChecked = useMemo(
    () => searchedCurrency?.every(currency => currency.checked),
    [searchedCurrency],
  );

  // filter the platformList based on the search text
  const searchedPlatform = (
    platformList?.filter(platform =>
      platform.label.toLowerCase().includes(platformFilter),
    ) || []
  ).sort((a, b) => a.label.localeCompare(b.label));

  const isEveryPlatformInViewChecked = useMemo(
    () => searchedPlatform?.every(platform => platform.checked),
    [searchedPlatform],
  );

  //scroll to the first checked checkbox on open
  const checkboxWrapperRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null && isOpen && managementFilterIsVisible) {
        setTimeout(() => {
          const firstCheckedCheckbox = node.querySelector(
            'input[type="checkbox"]:checked',
          );
          if (firstCheckedCheckbox) {
            firstCheckedCheckbox.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
        }, 500);
      }
    },
    [managementFilterIsVisible, isOpen],
  );

  const alternativeAssetClasses = [
    FundBroadAssetClass.Alternative,
    FundBroadAssetClass.Commodities,
    FundBroadAssetClass.Convertibles,
  ];

  const addOrRemoveAssetClass = (assetClass: FundBroadAssetClass) => {
    setSelectedBroadAssetClasses(prevState =>
      prevState.includes(assetClass)
        ? prevState.filter(c => c !== assetClass)
        : [...prevState, assetClass],
    );
  };

  /*Note: We subtracted 2 when the user selected the Alternative asset class because it includes 3 distinct asset classes (Alternative, Commodities, Convertibles),
  to accurately reflect the selected count indicator.*/
  const adjustedSelectedBroadAssetClassesCount =
    selectedBroadAssetClasses.includes(FundBroadAssetClass.Alternative)
      ? selectedBroadAssetClasses.length - 2
      : selectedBroadAssetClasses.length;

  return (
    <ExploreFilterModal
      title="More Filters"
      isVisible={isOpen}
      isDisableApply={disabled && !selectedFilter}
      isDisableClear={!selectedFilter}
      toggleModal={() => {
        setManagementGroupFilter('');
        setDomicilesFilter('');
        setCurrenciesFilter('');
        setPlatformFilter('');
        onClose();
      }}
      onClear={handleOnClear}
      onApply={handleOnApply}
    >
      <div data-test-id={'moreFilters'} className="modal-more-filters">
        <Accordion
          defaultActiveKey="1"
          onChange={activeKey => {
            activeKey === '6'
              ? setManagementFilterIsVisible(true)
              : setManagementFilterIsVisible(false);
          }}
        >
          <Panel
            header="Oldest Shareclass"
            dataTestId={'moreFiltersLabelOldestShareclass'}
            key="1"
            extra={
              <>
                <span>{filterByOldestShareClassOnly ? `(1)` : ''}</span>
              </>
            }
          >
            <div className={style['oldest-shareclass-checkbox']}>
              <Checkbox
                checked={filterByOldestShareClassOnly}
                onChange={() =>
                  setFilterByOldestShareClassOnly(!filterByOldestShareClassOnly)
                }
              >
                Filter by oldest shareclass only
              </Checkbox>
            </div>
            <div className={style['oldest-shareclass-subtext']}>
              <p>
                <span className={style['oldest-shareclass-subtext-bold']}>
                  Pro:
                </span>
                <span> Limits results to 1 row per fund</span>
              </p>
              <p>
                <span className={style['oldest-shareclass-subtext-bold']}>
                  Con:
                </span>
                <span> OCF filter only searches oldest shareclasses</span>
              </p>
            </div>
          </Panel>
          <Panel
            header="Number of Holdings"
            dataTestId={'moreFiltersLabelNumberOfHoldings'}
            key="2"
            extra={
              <span>
                {getChangedMarkValues(
                  holdingChange,
                  noOfHoldingsRange,
                  noOfHoldingMarks,
                )}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'moreFiltersSliderNumberOfHoldings'}
            >
              <Slider
                className={style[getRangeSliderClassName(holdingChange)]}
                tooltip={{ open: false }}
                range
                step={16}
                max={96}
                marks={noOfHoldingMarks}
                value={[noOfHoldingsMin, noOfHoldingsMax]}
                onChange={value => {
                  setNoOfHoldingsRange(
                    adjustMarksToAvoidDuplicates(value, noOfHoldingMarks),
                  );
                  setHoldingChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Active/Passive"
            dataTestId={'moreFiltersLabelActive/Passive'}
            key="3"
            extra={
              <>
                <span>
                  {filterByActiveOrPassive?.length
                    ? `(${filterByActiveOrPassive.length})`
                    : ''}
                </span>
              </>
            }
          >
            <div className="flex">
              <MultiCheckBox
                value={filterByActiveOrPassive}
                data={[
                  {
                    label: 'Active',
                    value: ActivePassiveFilterType.Active,
                  },
                  {
                    label: 'Passive',
                    value: ActivePassiveFilterType.Passive,
                  },
                ]}
                onChange={setFilterByActiveOrPassive}
              />
            </div>
          </Panel>
          <Panel
            header="Manager Gender"
            dataTestId={'moreFiltersLabelManagerGender'}
            key="4"
            extra={
              <>
                <span>{genders?.length ? `(${genders.length})` : ''}</span>
              </>
            }
          >
            <MultiCheckBox
              data-test-id="managerGender"
              data={[
                {
                  label: 'Male',
                  value: GenderFilterType.Male,
                },
                {
                  label: 'Female',
                  value: GenderFilterType.Female,
                },
              ]}
              value={genders}
              onChange={setGenders}
            />
          </Panel>
          <Panel
            header="Yield"
            dataTestId={'moreFiltersLabelYield'}
            key="5"
            extra={
              <span>
                {getChangedMarkValues(yieldChange, yieldRange, yieldMarks)}
              </span>
            }
          >
            <div
              className={style['range-slider']}
              data-test-id={'moreFiltersSliderYield'}
            >
              <Slider
                className={style[getRangeSliderClassName(yieldChange)]}
                tooltip={{ open: false }}
                range
                step={SLIDER_STEP}
                max={SLIDER_MAX_YIELD}
                marks={yieldMarks}
                value={[yieldMin, yieldMax]}
                onChange={value => {
                  setYieldRange(
                    adjustMarksToAvoidDuplicates(value, yieldMarks),
                  );
                  setYieldChange(true);
                }}
              />
            </div>
          </Panel>
          <Panel
            header="Management Group"
            dataTestId={'moreFiltersLabelManagementGroup'}
            key="6"
            extra={
              <>
                <span>
                  {managementGroup?.length ? `(${managementGroup.length})` : ''}
                </span>
              </>
            }
          >
            <Search
              onChange={value => {
                setManagementGroupFilter(value?.toLowerCase() || '');
              }}
              input={managementGroupFilter}
            />
            <div className="px-2 py-1">
              <Checkbox
                checked={isEveryProviderInViewChecked}
                className="px-2"
                onChange={() => {
                  const clonedProviders = [...providers];
                  searchedProviders.forEach(item => {
                    const itemIndex = clonedProviders.findIndex(
                      ci => ci.label === item.label,
                    );
                    if (clonedProviders[itemIndex]) {
                      // if all items are checked, uncheck all, else check all
                      clonedProviders[itemIndex].checked =
                        isEveryProviderInViewChecked ? false : true;
                    }
                  });
                  setProviders(clonedProviders);
                }}
              >
                Select all
              </Checkbox>
              <Divider className="m-0" />
            </div>
            <div
              className="px-4 mt-2 overflow-auto max-h-72"
              ref={checkboxWrapperRef}
            >
              {searchedProviders.map(provider => {
                // this unchecks the checkboxes on search
                return (
                  <React.Fragment key={`checkbox-container-${provider.label}`}>
                    <Checkbox
                      value={provider.checked}
                      checked={provider.checked}
                      onChange={event => {
                        const clonedProviders = [...providers];
                        const providerIndex = clonedProviders.findIndex(
                          iProvider => iProvider.label === provider.label,
                        );
                        if (clonedProviders[providerIndex]) {
                          clonedProviders[providerIndex].checked =
                            event.target.checked;
                        }
                        setProviders(clonedProviders);
                      }}
                      key={provider.label}
                    >
                      {provider.label}
                    </Checkbox>
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
          </Panel>
          <Panel
            header="Asset Class"
            dataTestId={'moreFiltersLabelAssetClass'}
            key="7"
            extra={
              <>
                <span>
                  {adjustedSelectedBroadAssetClassesCount > 0
                    ? `(${adjustedSelectedBroadAssetClassesCount})`
                    : ''}
                </span>
              </>
            }
          >
            <div className="flex flex-col">
              <Checkbox
                value={FundBroadAssetClass.Equity}
                checked={selectedBroadAssetClasses.includes(
                  FundBroadAssetClass.Equity,
                )}
                onChange={() =>
                  addOrRemoveAssetClass(FundBroadAssetClass.Equity)
                }
                key={FundBroadAssetClass.Equity}
              >
                Equity
              </Checkbox>
              <Checkbox
                value={FundBroadAssetClass.FixedIncome}
                checked={selectedBroadAssetClasses.includes(
                  FundBroadAssetClass.FixedIncome,
                )}
                onChange={() =>
                  addOrRemoveAssetClass(FundBroadAssetClass.FixedIncome)
                }
                key={FundBroadAssetClass.FixedIncome}
              >
                Fixed Income
              </Checkbox>
              <Checkbox
                value={FundBroadAssetClass.Alternative}
                checked={alternativeAssetClasses.some(assetClass =>
                  selectedBroadAssetClasses.includes(assetClass),
                )}
                onChange={() => {
                  addOrRemoveAssetClass(FundBroadAssetClass.Alternative);
                  addOrRemoveAssetClass(FundBroadAssetClass.Convertibles);
                  addOrRemoveAssetClass(FundBroadAssetClass.Commodities);
                }}
                key={FundBroadAssetClass.Alternative}
              >
                Alternatives
              </Checkbox>
              <Checkbox
                value={FundBroadAssetClass.Property}
                checked={selectedBroadAssetClasses.includes(
                  FundBroadAssetClass.Property,
                )}
                onChange={() =>
                  addOrRemoveAssetClass(FundBroadAssetClass.Property)
                }
                key={FundBroadAssetClass.Property}
              >
                Property
              </Checkbox>
              <Checkbox
                value={FundBroadAssetClass.MoneyMarket}
                checked={selectedBroadAssetClasses.includes(
                  FundBroadAssetClass.MoneyMarket,
                )}
                onChange={() =>
                  addOrRemoveAssetClass(FundBroadAssetClass.MoneyMarket)
                }
                key={FundBroadAssetClass.MoneyMarket}
              >
                Money Market
              </Checkbox>
              <Checkbox
                value={FundBroadAssetClass.Miscellaneous}
                checked={selectedBroadAssetClasses.includes(
                  FundBroadAssetClass.Miscellaneous,
                )}
                onChange={() =>
                  addOrRemoveAssetClass(FundBroadAssetClass.Miscellaneous)
                }
                key={FundBroadAssetClass.Miscellaneous}
              >
                Miscellaneous
              </Checkbox>
              <Checkbox
                value={FundBroadAssetClass.Allocation}
                checked={selectedBroadAssetClasses.includes(
                  FundBroadAssetClass.Allocation,
                )}
                onChange={() =>
                  addOrRemoveAssetClass(FundBroadAssetClass.Allocation)
                }
                key={FundBroadAssetClass.Allocation}
              >
                Multi Asset
              </Checkbox>
            </div>
          </Panel>
          <Panel
            header="Legal Structure"
            dataTestId={'moreFiltersLabelLegalStructure'}
            key="8"
            extra={
              <>
                <span>
                  {legalStructure?.length ? `(${legalStructure.length})` : ''}
                </span>
              </>
            }
          >
            <MultiCheckBox
              data={[
                {
                  label: 'Fund',
                  value: LEGAL_STRUCTURE.FUNDS,
                },
                {
                  label: 'Investment trust',
                  value: LEGAL_STRUCTURE.INVESTMENT_TRUSTS,
                },
                {
                  label: 'ETF',
                  value: LEGAL_STRUCTURE.ETF,
                },
              ]}
              value={legalStructure}
              onChange={setLegalStructure}
            />
          </Panel>
          <Panel
            header="Domicile"
            dataTestId={'moreFiltersLabelDomicile'}
            key="9"
            extra={
              <>
                <span>{domiciles?.length ? `(${domiciles.length})` : ''}</span>
              </>
            }
          >
            <Search
              onChange={value => {
                setDomicilesFilter(value?.toLowerCase() || '');
              }}
              input={domicilesFilter}
            />
            <div className="px-2 py-1">
              <Checkbox
                checked={isEveryDomicileInViewChecked}
                className="px-2"
                onChange={() => {
                  const clonedDomicileList = [...domicileList];
                  searchedDomicile.forEach(item => {
                    const itemIndex = clonedDomicileList.findIndex(
                      domicile => domicile.label === item.label,
                    );
                    if (clonedDomicileList[itemIndex]) {
                      // if all items are checked, uncheck all, else check all
                      clonedDomicileList[itemIndex].checked =
                        isEveryDomicileInViewChecked ? false : true;
                    }
                  });
                  setDomicileList(clonedDomicileList);
                }}
              >
                Select all
              </Checkbox>
              <Divider className="m-0" />
            </div>
            <div
              className="px-4 mt-2 overflow-auto max-h-72"
              ref={checkboxWrapperRef}
            >
              {searchedDomicile.map(domicile => {
                // this unchecks the checkboxes on search
                return (
                  <React.Fragment key={`checkbox-container-${domicile.label}`}>
                    <Checkbox
                      value={domicile.value}
                      checked={domicile.checked}
                      onChange={event => {
                        const clonedDomicileList = [...domicileList];
                        const domicileListIndex = clonedDomicileList.findIndex(
                          iDomicileList =>
                            iDomicileList.value === domicile.value,
                        );
                        if (clonedDomicileList[domicileListIndex]) {
                          clonedDomicileList[domicileListIndex].checked =
                            event.target.checked;
                        }
                        setDomicileList(clonedDomicileList);
                      }}
                      key={domicile.value}
                    >
                      {domicile.label}
                    </Checkbox>
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
          </Panel>
          <Panel
            header="Currency"
            dataTestId={'moreFiltersLabelCurrencies'}
            key="10"
            extra={
              <>
                <span>
                  {currencies?.length ? `(${currencies.length})` : ''}
                </span>
              </>
            }
          >
            <Search
              onChange={value => {
                setCurrenciesFilter(value?.toLowerCase() || '');
              }}
              input={currenciesFilter}
            />
            <div className="px-2 py-1">
              <Checkbox
                checked={isEveryCurrencyInViewChecked}
                className="px-2"
                onChange={() => {
                  const clonedCurrencyList = [...currencyList];
                  searchedCurrency.forEach(item => {
                    const itemIndex = clonedCurrencyList.findIndex(
                      currency => currency.label === item.label,
                    );
                    if (clonedCurrencyList[itemIndex]) {
                      // if all items are checked, uncheck all, else check all
                      clonedCurrencyList[itemIndex].checked =
                        isEveryCurrencyInViewChecked ? false : true;
                    }
                  });
                  setCurrencyList(clonedCurrencyList);
                }}
              >
                Select all
              </Checkbox>
              <Divider className="m-0" />
            </div>
            <div
              className="px-4 mt-2 overflow-auto max-h-72"
              ref={checkboxWrapperRef}
            >
              {searchedCurrency.map(currency => {
                // this unchecks the checkboxes on search
                return (
                  <React.Fragment key={`checkbox-container-${currency.label}`}>
                    <Checkbox
                      value={currency.value}
                      checked={currency.checked}
                      onChange={event => {
                        const clonedCurrencyList = [...currencyList];
                        const currencyListIndex = clonedCurrencyList.findIndex(
                          iCurrencyList =>
                            iCurrencyList.value === currency.value,
                        );
                        if (clonedCurrencyList[currencyListIndex]) {
                          clonedCurrencyList[currencyListIndex].checked =
                            event.target.checked;
                        }
                        setCurrencyList(clonedCurrencyList);
                      }}
                      key={currency.value}
                    >
                      {currency.label}
                    </Checkbox>
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
          </Panel>
          <Panel
            header="Currency Hedged"
            dataTestId={'moreFiltersLabelCurrencyHedged'}
            key="11"
            extra={
              <>
                <span>
                  {currencyHedgedType?.length
                    ? `(${currencyHedgedType.length})`
                    : ''}
                </span>
              </>
            }
          >
            <MultiCheckBox
              data-test-id="currencyHedgedType"
              data={[
                {
                  label: 'Fully Hedged',
                  value: CurrencyHedgedFilterType.Fully_Hedged,
                },
                {
                  label: 'Partial Hedged',
                  value: CurrencyHedgedFilterType.Partially_Hedged,
                },
                {
                  label: 'No Hedged',
                  value: CurrencyHedgedFilterType.No_Hedging_Information,
                },
              ]}
              value={currencyHedgedType}
              onChange={setCurrencyHedgedType}
            />
          </Panel>
          <Panel
            header="Platform Availability"
            dataTestId={'moreFiltersLabelPlatformAvailability'}
            key="12"
            extra={
              <>
                <span>{platforms?.length ? `(${platforms.length})` : ''}</span>
              </>
            }
          >
            <Search
              onChange={value => {
                setPlatformFilter(value?.toLowerCase() || '');
              }}
              input={platformFilter}
            />
            <div className="px-2 py-1">
              <Checkbox
                checked={isEveryPlatformInViewChecked}
                className="px-2"
                onChange={() => {
                  const clonedPlatformList = [...platformList];
                  searchedPlatform.forEach(item => {
                    const itemIndex = clonedPlatformList.findIndex(
                      platform => platform.value === item.value,
                    );
                    if (clonedPlatformList[itemIndex]) {
                      // if all items are checked, uncheck all, else check all
                      clonedPlatformList[itemIndex].checked =
                        isEveryPlatformInViewChecked ? false : true;
                    }
                  });
                  setPlatformList(clonedPlatformList);
                }}
              >
                Select all
              </Checkbox>
              <Divider className="m-0" />
            </div>
            <div
              className="px-4 mt-2 overflow-auto max-h-72"
              ref={checkboxWrapperRef}
            >
              {searchedPlatform.map(platform => {
                // this unchecks the checkboxes on search
                return (
                  <React.Fragment key={`checkbox-container-${platform.value}`}>
                    <Checkbox
                      value={platform.value}
                      checked={platform.checked}
                      onChange={event => {
                        const clonedPlatformList = [...platformList];
                        const platformListIndex = clonedPlatformList.findIndex(
                          iPlatform => iPlatform.value === platform.value,
                        );
                        if (clonedPlatformList[platformListIndex]) {
                          clonedPlatformList[platformListIndex].checked =
                            event.target.checked;
                        }
                        setPlatformList(clonedPlatformList);
                      }}
                      key={platform.value}
                    >
                      {platform.label}
                    </Checkbox>
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
            <div className={style['platform-note-wrapper']}>
              <p className={style['platform-note-title']}>Note:</p>
              <p className={style['platform-note-text']}>
                These checkboxes are AND conditions <br />
                (i.e. a fund must be present on Wealthtime AND Parmenion)
              </p>
            </div>
          </Panel>
        </Accordion>
      </div>
    </ExploreFilterModal>
  );
};
