import {
  Fund,
  PageQueryParametersSortDirectionEnum,
  WatchlistBenchmarkResponse,
} from '@aminsights/contract';
import { isInvestmentTrust } from '@aminsights/shared';
import { FC, useState } from 'react';

import { DataTable } from '@/components';
import { CalendarYearPerformanceColumns } from '@/pages/app/FundAndInvestmentTrust/components/Performance/columns';

import {
  benchmarkCalendarYearMapper,
  calendarYearPerformanceMapper,
} from '../Performance/rowMapper';
import { ICalendarYearPerformanceData } from '../Performance/types';
import { compareDataSort } from './utils/compareDataSort';

interface CompareAnnualisedPerformanceProps {
  benchmarkData?: WatchlistBenchmarkResponse;
  loading?: boolean;
  funds?: Fund[];
}

const CompareCalendarYearPerformance: FC<CompareAnnualisedPerformanceProps> = ({
  benchmarkData,
  loading = true,
  funds,
}) => {
  const [sortedData, setSortedData] = useState<ICalendarYearPerformanceData[]>(
    [],
  );
  const isAllInvestmentTrust = !!funds?.every(f =>
    isInvestmentTrust(f.legalStructure),
  );
  const calendarYearPerformanceData: Array<ICalendarYearPerformanceData> = (
    funds || []
  ).map(fund =>
    calendarYearPerformanceMapper(fund.shareClassDetails.isin, fund),
  );

  const benchmarkPerformance = benchmarkData?.benchmark;
  if (benchmarkPerformance) {
    calendarYearPerformanceData.push(
      benchmarkCalendarYearMapper('benchmark', benchmarkPerformance),
    );
  }

  return (
    <DataTable
      className="xl:table-fixed"
      loading={loading}
      loaderSize={7}
      uniqueKey="key"
      columns={CalendarYearPerformanceColumns(isAllInvestmentTrust, true)}
      data={sortedData.length > 1 ? sortedData : calendarYearPerformanceData}
      onSort={async (
        key: string,
        direction: PageQueryParametersSortDirectionEnum,
      ) => {
        setSortedData(
          compareDataSort(calendarYearPerformanceData, key, direction),
        );
      }}
    />
  );
};

export default CompareCalendarYearPerformance;
