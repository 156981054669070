import { useEffect, useMemo } from 'react';

import useCountUnseenNotifications from './query-hooks/notification-hooks/useCountUnseenNotifications';

const UsePWABadge = () => {
  const { refetch, data } = useCountUnseenNotifications();
  const notifCount = useMemo(() => {
    return data || 0;
  }, [data]);
  useEffect(() => {
    if ('serviceWorker' in navigator && 'SyncManager' in window) {
      navigator.serviceWorker
        .register('/sw.js')
        .then(registration => {
          // eslint-disable-next-line
          console.log(
            'Service Worker registered with scope:',
            registration.scope,
          );
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });

      navigator.serviceWorker.ready.then(registration => {
        if ('sync' in registration) {
          (registration as any).sync
            .register('update-badge')
            .then(() => {
              // eslint-disable-next-line
              console.log('Background Sync registered');
            })
            .catch((error: any) => {
              console.error('Background Sync registration failed:', error);
            });
        }
      });

      navigator.serviceWorker.ready.then(registration => {
        if ('periodicSync' in registration) {
          (registration as any).periodicSync
            .register('periodic-update-badge', {
              minInterval: 1000 * 30, // Minimum interval in milliseconds: 30 sec
            })
            .then(() => {
              // eslint-disable-next-line
              console.log('Periodic Background Sync registered');
            })
            .catch((error: any) => {
              console.error(
                'Periodic Background Sync registration failed:',
                error,
              );
            });
        }
      });
    }
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data.action === 'update-badge') {
          refetch();
        }
      });
    }
  }, []);

  useEffect(() => {
    updateBadge();
  }, [notifCount]);

  const updateBadge = async () => {
    try {
      if ('setAppBadge' in navigator) {
        await navigator.setAppBadge(notifCount);
      } else if ('setExperimentalAppBadge') {
        // eslint-disable-next-line
        // @ts-ignore
        await navigator.setExperimentalAppBadge?.(notifCount);
      } else if ('ExperimentalBadge' in window) {
        // eslint-disable-next-line
        // @ts-ignore
        await window?.ExperimentalBadge?.set?.(notifCount);
      } else {
        console.warn('setAppBadge is not supported in this browser.');
      }
    } catch (error) {
      console.error('Failed to set badge:', error);
    }
  };

  return null;
};

export default UsePWABadge;
