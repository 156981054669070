import {
  PageQueryParametersSortDirectionEnum,
  PortfolioFund,
} from '@aminsights/contract';
import React, { useEffect, useRef, useState } from 'react';

import { DataTable } from '@/components';
import handleOnRow from '@/hooks/handleOnRow';
import { sortArray } from '@/utils/array';

import { useProvidePortfolio } from '../../../useProvidePortfolio';
import EmptyPortfolioTable from '../../EmptyPortfolioTable';
import PerformanceColumns from './columns';

const transformFunds = (funds?: PortfolioFund[]) => {
  return (
    funds
      ?.map(f => f)
      .sort((a, b) => {
        return (b.totalReturnYtdPct || 0) - (a.totalReturnYtdPct || 0);
      }) || []
  );
};

const PortfolioPerformanceTab: React.FCWithChild = () => {
  const { state, addFundSearchBoxToCurrentPortfolio } = useProvidePortfolio();
  const bottomRef = useRef<HTMLDivElement>(null);

  const currentPortfolio = state.portfolios.find(
    p => p._id === state.currentPortfolioId,
  );

  const currentPortfolioFunds =
    currentPortfolio?.funds.filter(f => f.isin !== '') ?? [];
  const [funds, setFunds] = useState(transformFunds(currentPortfolioFunds));

  const clickRow = handleOnRow();

  const handleOnSort = (
    key: keyof PortfolioFund,
    direction: PageQueryParametersSortDirectionEnum,
  ) => {
    const sorted = sortArray(funds, key, direction);
    setFunds(sorted);
  };

  useEffect(() => {
    if (funds.length !== currentPortfolioFunds.length) {
      setFunds(transformFunds(currentPortfolioFunds));
    }
  }, [currentPortfolioFunds.filter(f => f.totalReturnYtdPct).length]);
  const isEmpty = !currentPortfolio || currentPortfolio.funds.length === 0;

  return (
    <>
      {currentPortfolio && !isEmpty ? (
        <>
          <DataTable
            uniqueKey={'isin'}
            loading={
              !state.isPerformanceTabLoading && state.arePortfoliosLoading
            }
            columns={PerformanceColumns()}
            data={funds}
            onRow={(id: string, event: React.MouseEvent<HTMLTableRowElement>) =>
              clickRow(id, event)
            }
            initialSorting={
              funds.length !== 0
                ? {
                    sortDirection: PageQueryParametersSortDirectionEnum.Desc,
                    sortKey: 'totalReturnYtdPct',
                  }
                : undefined
            }
            onSort={(
              key: string,
              direction: PageQueryParametersSortDirectionEnum,
            ) => handleOnSort(key as keyof PortfolioFund, direction)}
          />
          <div ref={bottomRef} />
        </>
      ) : (
        <EmptyPortfolioTable
          currentPortfolioId={state.currentPortfolioId}
          onAddFundClick={addFundSearchBoxToCurrentPortfolio}
        />
      )}
    </>
  );
};

export default PortfolioPerformanceTab;
