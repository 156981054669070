import { ResourceUser } from '@aminsights/contract';

export const splitResourceUserChanges = (
  existingUsers: ResourceUser[],
  newUsers: ResourceUser[],
) => {
  const addedUsers = newUsers.filter(
    u => !existingUsers.find(eu => eu.id === u.id),
  );

  const removedUsers = existingUsers.filter(
    eu => !newUsers.find(u => u.id === eu.id),
  );

  const unchangedUsers = existingUsers.filter(eu =>
    newUsers.find(u => u.id === eu.id),
  );

  return {
    addedUsers,
    removedUsers,
    unchangedUsers,
  };
};
