import {
  ONBOARDING_ACTIONS,
  OnboardingActionTypes,
  OnboardingState,
} from './types';

export const onboardingReducer = (
  state: OnboardingState,
  action: OnboardingActionTypes,
): OnboardingState => {
  switch (action.type) {
    case ONBOARDING_ACTIONS.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case ONBOARDING_ACTIONS.TOGGLE_WELCOME_MODAL:
      return {
        ...state,
        isShowWelcomeModal: action.payload,
      };
    case ONBOARDING_ACTIONS.TOGGLE_SEARCH_FUNDS_SECTION:
      return {
        ...state,
        isShowSearchFundsSection: action.payload,
      };
    default:
      return state;
  }
};
