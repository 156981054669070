import { SignificantChangesApi } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import { SIGNIFICANT_CHANGES } from '../watchlist-hooks/query-keys';

const useGetSignificantChange = (id: string | null) => {
  const significantChangesApi = new SignificantChangesApi(openApiConfig());
  return useQuery([SIGNIFICANT_CHANGES, id], async () => {
    if (!id) {
      return null;
    }
    const response = await significantChangesApi.getSignificantChange(id);
    return response.data;
  });
};

export default useGetSignificantChange;
