// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xybwGGsMoy5i50iBGFxp {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  justify-content: center;\n  background-color: #ffffff;\n  border: none;\n}\n.EZKglYq1cczuGjmTSlqu {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: 2rem;\n  padding-bottom: 2rem;\n  border-radius: 8px;\n}\n.EZKglYq1cczuGjmTSlqu p {\n  margin-top: auto;\n  margin-bottom: auto;\n  padding-left: 1rem;\n}\n.EZKglYq1cczuGjmTSlqu span {\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 20px;\n  text-decoration: underline;\n  color: #0072e6;\n}\n.xybwGGsMoy5i50iBGFxp span {\n  background-color: white;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Portfolio/components/ImportPortfolio/CsvFundsDropzone/style.module.less"],"names":[],"mappings":"AAGE;EAAA,aAAA;EAAA,WAAA;EAAA,sBAAA;EAAA,uBAAA;EACA,yBAAA;EACA;AAFA;AAKE;EAAA,aAAA;EAAA,mBAAA;EAAA,uBAAA;EAAA,iBAAA;EAAA,oBAAA;EACA;AADA;AAGE;EAAA,gBAAA;EAAA,mBAAA;EAAA;AAAA;AAJJ;EAOI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;EACA,cAAA;AAAN;AAhBA;EAoBI,uBAAA;AADJ","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.dragger {\n  @apply flex flex-col justify-center w-full;\n  background-color: @white;\n  border: none;\n\n  &-browse-files {\n    @apply flex justify-center items-center py-8;\n    border-radius: 8px;\n    p {\n      @apply mt-auto mb-auto pl-4;\n    }\n    span {\n      font-weight: 500;\n      font-size: 14px;\n      line-height: 20px;\n      text-decoration: underline;\n      color: #0072e6;\n    }\n  }\n  span {\n    background-color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragger": "xybwGGsMoy5i50iBGFxp",
	"dragger-browse-files": "EZKglYq1cczuGjmTSlqu"
};
export default ___CSS_LOADER_EXPORT___;
