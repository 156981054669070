import { Modal } from 'antd';
import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconCheck } from '@/assets/svg/icons/icon-check-colored.svg';
import Button from '@/components/Button';

import style from './style.module.less';

type ModalProps = {
  isVisible: boolean;
  className?: string;
  onApply?: () => void;
};

const AllFundsAssignedModal: React.FCWithChild<ModalProps> = ({
  isVisible,
  className,
}) => {
  const history = useHistory();
  return (
    <Modal
      width={720}
      open={isVisible}
      className={cx(style.modal, className)}
      closable={false}
      footer={[
        <Button
          size="large"
          type="primary"
          className="font-semibold"
          onClick={() => {
            history.goBack();
          }}
          key="primary"
        >
          View Buckets
        </Button>,
      ]}
    >
      <div className="text-center">
        <IconCheck className="m-auto" width={64} height={64} />
        <div className={style['modal-title']}>
          All funds/trusts has been assigned to buckets
        </div>
        <div className={style['modal-info']}>
          If you want to change anything you can do so in your watchlist.
        </div>
      </div>
    </Modal>
  );
};

export default AllFundsAssignedModal;
