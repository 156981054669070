import React from 'react';

import { toCamelCase } from '@/utils/toCamelCase';

import style from '../style.module.less';

interface IHeadingProps {
  isAllBucketsSelected: boolean;
  bucketName: string | undefined;
  bucketIndex: string | undefined;
}

export const Heading: React.FCWithChild<IHeadingProps> = ({
  isAllBucketsSelected,
  bucketName = '',
  bucketIndex = '',
}) => {
  return (
    <div className={style['watchlist-body__header']}>
      {isAllBucketsSelected ? (
        <h3 data-test-id="allFunds/Trusts" className="title">
          All Funds/Trusts
        </h3>
      ) : (
        <h3
          data-test-id={toCamelCase(`${bucketName}${bucketIndex || ''}`)}
          className="title"
        >
          {bucketName} {bucketIndex ? <span>({bucketIndex})</span> : ''}
        </h3>
      )}
      <div id="scale-toggle-portal"></div>
      <div
        id="dropdown-portal"
        data-test-id="WatchlistRiskTabDatePicker"
        className={style['year-dropdown']}
      ></div>
    </div>
  );
};
