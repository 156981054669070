// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t65IOcM7ldZjXU_M0xMG {\n  grid-template-columns: -webkit-min-content -webkit-min-content;\n  grid-template-columns: min-content min-content;\n  height: 100%;\n}\n.tx5_2vAgR49ctHUp2nRA {\n  background-color: #e6e9ee;\n  height: 36px;\n  aspect-ratio: 1 / 1;\n}\n.GjVJXgF8GTDsNmxF99R7 {\n  margin-top: 1.5rem;\n  width: auto;\n  overflow-y: auto;\n  border-radius: 0.5rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  padding-top: 0.75rem;\n  padding-bottom: 0.75rem;\n  border: 1px solid #adadb3;\n  height: 280px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/FundAndInvestmentTrust/components/style.module.less"],"names":[],"mappings":"AAAA;EAEE,8DAAA;EAAA,8CAAA;EACA,YAAA;AAAF;AAGA;EACE,yBAAA;EACA,YAAA;EACA,mBAAA;AADF;AAKE;EAAA,kBAAA;EAAA,WAAA;EAAA,gBAAA;EAAA,qBAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,uBAAA;EACA,yBAAA;EACA;AAFA","sourcesContent":[".button-container {\n  // It's a little frustrating that I can't use arbitrary values with tailwind\n  grid-template-columns: min-content min-content;\n  height: 100%;\n}\n\n.action {\n  background-color: #e6e9ee;\n  height: 36px;\n  aspect-ratio: 1 / 1;\n}\n\n.bucket-wrapper {\n  @apply mt-6 px-4 py-3 w-auto rounded-lg overflow-y-auto;\n  border: 1px solid #adadb3;\n  height: 280px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-container": "t65IOcM7ldZjXU_M0xMG",
	"action": "tx5_2vAgR49ctHUp2nRA",
	"bucket-wrapper": "GjVJXgF8GTDsNmxF99R7"
};
export default ___CSS_LOADER_EXPORT___;
