import React from 'react';

import SignificantChangesForm from '@/partials/SignificantChanges';

import style from './style.module.less';

const SignificantChanges: React.FCWithChild = () => (
  <div className={style['section-significant-settings']}>
    <div className="md:w-1/2 w-full">
      <h5 data-test-id="significantChangesSectionTitle">Significant Changes</h5>
      <p className="pt-4 pb-6">
        Edit your parameters to be alerted about key changes in your watchlist.
      </p>

      <SignificantChangesForm />
    </div>
  </div>
);

export default SignificantChanges;
