/**
 * Type guards for handling errors and getting useful information from them
 */

import { isAxiosError } from 'axios';

/**
 * Get the error code from the axios error
 */
export const getErrorCodeFromAxiosError = (error: unknown): string => {
  if (isAxiosError(error)) {
    return error.code ?? '';
  }
  return '';
};

export const getErrorMessageFromResponse = (error: unknown): string => {
  if (isAxiosError(error)) {
    const responseObject = (error as any).response;
    const message = (responseObject?.data as any).message;
    return message ?? 'Unknown error';
  }
  return 'Unknown error';
};
