export enum MarketCapLabel {
  GIANT = 'Giant',
  LARGE = 'Large',
  MEDIUM = 'Medium',
  MICRO = 'Micro',
  SMALL = 'Small',
}

export enum MarketCapFullName {
  MarketCapLongPctGiant = 'Market Cap Long Percentage Giant',
  MarketCapLongPctLarge = 'Market Cap Long Percentage Large',
  MarketCapLongPctMedium = 'Market Cap Long Percentage Medium',
  MarketCapLongPctMicro = 'Market Cap Long Percentage Micro',
  MarketCapLongPctSmall = 'Market Cap Long Percentage Small',
}
export enum MarketCapSortKey {
  Giant = 'marketCapLongPctGiant',
  Large = 'marketCapLongPctLarge',
  Medium = 'marketCapLongPctMedium',
  Micro = 'marketCapLongPctMicro',
  Small = 'marketCapLongPctSmall',
}

export const MARKET_CAP_TO_SHOW: {
  title: MarketCapLabel;
  marketCapFullName: MarketCapFullName;
  key: MarketCapSortKey;
}[] = [
  {
    title: MarketCapLabel.GIANT,
    marketCapFullName: MarketCapFullName.MarketCapLongPctGiant,
    key: MarketCapSortKey.Giant,
  },
  {
    title: MarketCapLabel.LARGE,
    marketCapFullName: MarketCapFullName.MarketCapLongPctLarge,
    key: MarketCapSortKey.Large,
  },
  {
    title: MarketCapLabel.MEDIUM,
    marketCapFullName: MarketCapFullName.MarketCapLongPctMedium,
    key: MarketCapSortKey.Medium,
  },
  {
    title: MarketCapLabel.SMALL,
    marketCapFullName: MarketCapFullName.MarketCapLongPctSmall,
    key: MarketCapSortKey.Small,
  },
  {
    title: MarketCapLabel.MICRO,
    marketCapFullName: MarketCapFullName.MarketCapLongPctMicro,
    key: MarketCapSortKey.Micro,
  },
];
