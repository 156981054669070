import React from 'react';

import style from './style.module.less';

export const VerificationSuccess: React.FCWithChild = () => {
  const gotToLogin = () => {
    window.location.href = '/';
  };

  return (
    <div className={style['verification-container']}>
      <div className={style['verification-wrapper']}>
        <button
          type="button"
          onClick={gotToLogin}
          className={style['close-button']}
        >
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.00256 13L13.0026 1M1.00256 1L13.0026 13"
              stroke="#111827"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <h2 className={style['verification-header']}>Email Verified</h2>
        <p className={style['verification-description']}>
          Your email address was successfully verified.
        </p>
        <img
          className={style['mb-12']}
          src="./assets/svg/email-graphic.svg"
          alt="Account Verify success"
        />
        <button
          type="button"
          onClick={gotToLogin}
          className={style['custom-btn-wrapper']}
        >
          View Dashboard
        </button>
      </div>
    </div>
  );
};
