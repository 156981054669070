import { Fund, YesNoOption } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import { ESGBadge } from '@/partials/ESG/ESGBadge';

const WatchlistESGColumns = (): IDataTableColumns[] => [
  {
    title: 'Fund/Investment Trust',
    sortKey: 'shareClassDetails.code',
    idSubText: 'fundName',
    render: (item: Fund): React.ReactNode => (
      <FundInvestmentTrustColumn
        data={{
          id: item.shareClassDetails.isin,
          name: item.shareClassDetails.code,
          type: item.legalStructure,
          assetClassCode: item.assetClassCode,
          fundId: item.fundId,
          legalStructure: item.legalStructure,
        }}
      />
    ),
    renderType: 'text',
    isColumnFixed: true,
    loader: (
      <Skeleton
        avatar={{ size: 'small' }}
        title={false}
        paragraph={{ rows: 2, width: 200 }}
        active
      />
    ),
  },
  {
    title: 'SFDR',
    sortKey: 'sfdrClass',
    idSubText: 'sfdrClass',
    render: (item: Fund) => (
      <div className="table-cell-value">
        {item?.sfdrClass || EMPTY_DATA_POINT}
      </div>
    ),
    renderType: 'text',
    width: 115,
    tooltipText: (
      <span className="text-xs md:whitespace-nowrap">
        Sustainable Finance Disclosure Regulation <br /> <b>Fund</b>
      </span>
    ),
  },
  {
    title: 'TCFD',
    sortKey: 'tcfdDate',
    idSubText: 'tcfdDate',
    render: (item: Fund) => {
      const date = item.tcfdDate;
      return (
        <div className="table-cell-value">
          {date ? dayjs(date).format('MMM YYYY') : EMPTY_DATA_POINT}
        </div>
      );
    },
    renderType: 'text',
    width: 115,
    tooltipText: (
      <span className="text-xs md:whitespace-nowrap">
        Task Force on Climate-Related Financial Disclosures <br />{' '}
        <b>Provider</b>
      </span>
    ),
  },
  {
    title: 'UN PRI',
    sortKey: 'unpriDate',
    idSubText: 'unpriDate',
    render: (item: Fund) => {
      const date = item.unpriDate;
      return (
        <div className="table-cell-value">
          {date ? dayjs(date).format('MMM YYYY') : EMPTY_DATA_POINT}
        </div>
      );
    },
    renderType: 'text',
    width: 115,
    align: 'center',
    tooltipText: (
      <span className="text-xs md:whitespace-nowrap">
        UN Principles for Responsible Investment <br /> <b>Provider</b>
      </span>
    ),
  },
  {
    title: 'Net Zero',
    sortKey: 'netZeroAssetManagers.keyword',
    idSubText: 'netZeroAssetManagers-keyword',
    render: (item: Fund) => {
      return (
        <div className="table-cell-value">
          {item?.netZeroAssetManagers ? YesNoOption.Yes : EMPTY_DATA_POINT}
        </div>
      );
    },
    renderType: 'text',
    width: 115,
    align: 'center',
    tooltipText: (
      <span className="text-xs md:whitespace-nowrap">
        Net Zero Asset Managers <br /> <b>Provider</b>
      </span>
    ),
  },
  {
    title: 'UK SC',
    sortKey: 'stewardshipCode.keyword',
    idSubText: 'stewardshipCode-keyword',
    render: (item: Fund) => {
      return (
        <div className="table-cell-value">
          {item?.stewardshipCode ? YesNoOption.Yes : EMPTY_DATA_POINT}
        </div>
      );
    },
    renderType: 'text',
    width: 115,
    align: 'center',
    tooltipText: (
      <span className="text-xs md:whitespace-nowrap">
        Stewardship Code <br /> <b>Provider</b>
      </span>
    ),
  },
  {
    title: 'F4B',
    sortKey: 'financeForBiodiversityDate',
    idSubText: 'financeForBiodiversityDate',
    render: (item: Fund) => {
      const date = item.financeForBiodiversityDate;
      return (
        <div className="table-cell-value">
          {date ? dayjs(date).format('MMM YYYY') : EMPTY_DATA_POINT}
        </div>
      );
    },
    renderType: 'text',
    width: 115,
    align: 'center',
    tooltipText: (
      <span className="text-xs md:whitespace-nowrap">
        Finance for Biodiversity <br /> <b>Provider</b>
      </span>
    ),
  },
  {
    title: 'ACT',
    sortKey: 'actDate',
    idSubText: 'actDate',
    render: (item: Fund) => {
      const date = item.actDate;
      return (
        <div className="table-cell-value">{date ? date : EMPTY_DATA_POINT}</div>
      );
    },
    renderType: 'text',
    width: 115,
    align: 'center',
    tooltipText: (
      <span className="text-xs md:whitespace-nowrap">
        City Hive ACT <br /> <b>Provider</b>
      </span>
    ),
  },
  {
    title: 'ShareAction 2023 Survey',
    idSubText: 'shareActionSurvey',
    renderType: 'custom',
    align: 'center',
    children: [
      {
        title: 'Ranking',
        sortKey: 'shareActionSurvey.ranking',
        idSubText: 'shareActionSurvey-ranking',
        render: (item: Fund) => {
          const ranking = item?.shareActionSurvey?.ranking;
          return (
            <div className="table-cell-value">
              {ranking ? `${ranking}/75` : EMPTY_DATA_POINT}
            </div>
          );
        },
        renderType: 'text',
        align: 'center',
      },
      {
        title: 'Rating',
        sortKey: 'shareActionSurvey.rating',
        idSubText: 'shareActionSurvey-rating',
        render: (item: Fund) => (
          <div className="table-cell-value">
            {item?.shareActionSurvey?.rating || EMPTY_DATA_POINT}
          </div>
        ),
        renderType: 'text',
        align: 'center',
      },
      {
        title: 'Gov.',
        sortKey: 'shareActionSurvey.responsibleInvestmentGovernance',
        idSubText: 'shareActionSurvey-responsibleInvestmentGovernance',
        render: (item: Fund) => (
          <ESGBadge
            value={item.shareActionSurvey?.responsibleInvestmentGovernance}
          />
        ),
        renderType: 'text',
        width: 72,
        tooltipText: (
          <span className="text-xs md:whitespace-nowrap">Governance</span>
        ),
      },
      {
        title: 'St’ship',
        sortKey: 'shareActionSurvey.stewardship',
        idSubText: 'shareActionSurvey-stewardship',
        align: 'center',
        render: (item: Fund) => (
          <ESGBadge value={item.shareActionSurvey?.stewardship} />
        ),
        renderType: 'text',
        width: 72,
        tooltipText: (
          <span className="text-xs md:whitespace-nowrap">Stewardship</span>
        ),
      },
      {
        title: 'Clim.',
        sortKey: 'shareActionSurvey.climateChange',
        idSubText: 'shareActionSurvey-climateChange',
        align: 'center',
        render: (item: Fund) => (
          <ESGBadge value={item.shareActionSurvey?.climateChange} />
        ),
        renderType: 'text',
        width: 72,
        tooltipText: (
          <span className="text-xs md:whitespace-nowrap">Climate</span>
        ),
      },
      {
        title: 'Biodiv.',
        sortKey: 'shareActionSurvey.biodiversity',
        idSubText: 'shareActionSurvey-biodiversity',
        align: 'center',
        render: (item: Fund) => (
          <ESGBadge value={item.shareActionSurvey?.biodiversity} />
        ),
        renderType: 'text',
        width: 72,
        tooltipText: (
          <span className="text-xs md:whitespace-nowrap">Biodiversity</span>
        ),
      },
      {
        title: 'Social',
        sortKey: 'shareActionSurvey.social',
        idSubText: 'shareActionSurvey-social',
        align: 'center',
        renderType: 'text',
        render: (item: Fund) => (
          <ESGBadge value={item.shareActionSurvey?.social} />
        ),
        width: 72,
        tooltipText: (
          <span className="text-xs md:whitespace-nowrap">Social</span>
        ),
      },
    ],
  },
];

export default WatchlistESGColumns;
