import {
  CompositeBenchmark,
  CompositeBenchmarkItem,
} from '@aminsights/contract';
import { FC, useCallback } from 'react';

import { DataTable, Tooltip } from '@/components';

import BenchmarkItemActions from './BenchmarkItemActions';
import style from './style.module.less';

interface CompositeBenchmarksListProps {
  benchmarks: CompositeBenchmark[];
  isLoading: boolean;
  onClickEditBenchmark: (benchmark: CompositeBenchmark) => void;
  onClickDeleteBenchmark: (benchmark: CompositeBenchmark) => void;
}

const CompositeBenchmarksList: FC<CompositeBenchmarksListProps> = ({
  benchmarks,
  isLoading,
  onClickEditBenchmark,
  onClickDeleteBenchmark,
}) => {
  const renderBenchmarks = useCallback((items: CompositeBenchmarkItem[]) => {
    const firstThreeItems = items.slice(0, 3);
    const moreItems = items.slice(3).length;
    return (
      <div className={style['benchmarks-list__description']}>
        {firstThreeItems.map(item => (
          <div className="flex" key={item.secId}>
            <p className="w-12 text-neutral-700 font-normal">
              {item.weighting}%
            </p>
            <p className="flex-1 text-neutral-700 font-normal">{item.name}</p>
          </div>
        ))}
        {moreItems > 0 ? (
          <span
            className={style['benchmarks-list__more']}
          >{`+${moreItems}`}</span>
        ) : null}
      </div>
    );
  }, []);

  return (
    <DataTable
      uniqueKey="_id"
      columns={[
        {
          title: 'Name',
          render: (item: CompositeBenchmark) => (
            <p className={style['benchmarks-list__name']}>{item.name}</p>
          ),
          minWidth: 100,
          renderType: 'text',
          isColumnFixed: true,
        },
        {
          title: 'Benchmarks',
          render: (item: CompositeBenchmark) => {
            return (
              <Tooltip
                placement="bottom"
                color="#313341"
                align={{ offset: [-20, -20] }}
                title={
                  <div className="w-96">
                    {item.benchmarkItems.map(bi => (
                      <div key={bi.secId} className="flex">
                        <p className="w-10 text-xs">{bi.weighting}%</p>
                        <p className="flex-1 text-xs">{bi.name}</p>
                      </div>
                    ))}
                  </div>
                }
                overlayClassName={style['benchmarks-list__tooltip']}
                arrowPointAtCenter
              >
                {renderBenchmarks(item.benchmarkItems)}
              </Tooltip>
            );
          },
          renderType: 'text',
        },
        {
          title: 'Access',
          render: (item: CompositeBenchmark) => {
            return (
              <p className={style['benchmarks-list__access']}>
                {item.shared ? 'Shared' : 'Only me'}
              </p>
            );
          },
          width: 90,
          renderType: 'text',
        },
        {
          title: '',
          render: (item: CompositeBenchmark) => {
            return (
              <BenchmarkItemActions
                onClickDeleteBenchmark={() => onClickDeleteBenchmark(item)}
                onClickEditBenchmark={() => onClickEditBenchmark(item)}
              />
            );
          },
          renderType: 'text',
        },
      ]}
      data={benchmarks}
      loading={isLoading}
    />
  );
};

export default CompositeBenchmarksList;
