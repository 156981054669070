import { Skeleton } from 'antd';
import React from 'react';

interface LoaderProps {
  loading?: boolean;
  component: React.ReactNode;
  width?: string;
  className?: string;
  row?: number;
}

const Loader: React.FCWithChild<LoaderProps> = ({
  loading,
  component,
  width,
  className,
  row,
}: LoaderProps) => {
  if (loading) {
    return (
      <Skeleton
        active
        title={false}
        className={className}
        paragraph={{ rows: row || 1, width: width || '100%' }}
      />
    );
  }
  return <>{component}</>;
};

export default Loader;
