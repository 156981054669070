// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AKYmv0QnOclfpB8BzvCr {\n  height: 2.5rem;\n  width: 100%;\n  border-radius: 0.25rem;\n  padding-left: 0.75rem;\n  padding-right: 0.75rem;\n  text-align: center;\n  border-color: #ccccd0\n}\n", "",{"version":3,"sources":["webpack://./src/partials/Forms/Input/BenchmarkWeightInput/style.module.less"],"names":[],"mappings":"AAGE;EAAA,cAAA;EAAA,WAAA;EAAA,sBAAA;EAAA,qBAAA;EAAA,sBAAA;EAAA,kBAAA;EACA;AADA","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.weight-input {\n  @apply h-10 w-full rounded px-3 text-center;\n  border-color: @btn-disabled;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"weight-input": "AKYmv0QnOclfpB8BzvCr"
};
export default ___CSS_LOADER_EXPORT___;
