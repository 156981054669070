// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xfvphJqXwYkn2gZ8miPx .g0ZQAXDbXv_LjQxdZRD4 {\n  border-radius: 0.25rem;\n  --tw-bg-opacity: 1;\n  background-color: rgb(247 247 247 / var(--tw-bg-opacity));\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  font-weight: 400;\n  --tw-text-opacity: 1;\n  color: rgb(84 85 118 / var(--tw-text-opacity));\n  padding: 2px 8px\n}\n", "",{"version":3,"sources":["webpack://./src/partials/shareClassTable/style.module.less"],"names":[],"mappings":"AAEI;EAAA,sBAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,oBAAA;EAAA,qBAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA,8CAAA;EACA;AADA","sourcesContent":[".sub-label {\n  .tag {\n    @apply font-normal text-xs text-neutral bg-grey-lighter rounded px-2;\n    padding: 2px 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sub-label": "xfvphJqXwYkn2gZ8miPx",
	"tag": "g0ZQAXDbXv_LjQxdZRD4"
};
export default ___CSS_LOADER_EXPORT___;
