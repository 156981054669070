import { buildFundDetailsPath } from '@aminsights/shared';
import { Skeleton } from 'antd';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { ReactComponent as TrendIcon } from '@/assets/svg/icons/icon-trend-arrow.svg';
import Tooltip from '@/components/Tooltip';

import style from './style.module.less';
import { summaryCardMapping, SummaryCardProps } from './types';

const SummaryCard: React.FCWithChild<SummaryCardProps> = ({
  cardItem,
  loading,
}) => {
  if (!cardItem && !loading) {
    return null;
  }
  return (
    <div className={style.wrapper}>
      <div
        className={cx(
          'p-4 bg-white rounded-lg shadow-card h-full',
          style.container,
        )}
      >
        <div className="flex justify-between">
          <Link
            to={buildFundDetailsPath(cardItem?.fundIsin || '')}
            className="text-sm text-darkest font-bold mb-2 truncate overflow-ellipsis flex-auto"
          >
            <Skeleton
              title={false}
              paragraph={{ rows: 1 }}
              loading={loading}
              active
            >
              {cardItem?.fundName}
            </Skeleton>
          </Link>
          <div className="mb-2">
            <div className="flex justify-end text-sm text-darkest font-medium">
              <Tooltip
                title={
                  cardItem &&
                  (summaryCardMapping.get(cardItem.cardType)?.tooltipMessage ||
                    'Last 30 days')
                }
                placement="bottomLeft"
                color="#3E414B"
              >
                <InfoIcon className="icon text-base" />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div className="w-full text-xs text-normal text-neutral">
            <Skeleton
              title={false}
              paragraph={{ rows: 1 }}
              active
              loading={loading}
            >
              {cardItem && summaryCardMapping.get(cardItem.cardType)?.title}
            </Skeleton>
          </div>
          <div className="flex items-center mt-2">
            {cardItem && (
              <span
                className={cx(style['trend-icon-wrapper'], {
                  [style['trend-icon-success']]: cardItem.value >= 0,
                  [style['trend-icon-danger']]: cardItem.value < 0,
                })}
              >
                <TrendIcon
                  className={cx(
                    'icon text-xl',
                    style['trend-icon'],
                    cardItem.value >= 0 ? style['flip-up'] : '',
                  )}
                />
              </span>
            )}
            <h1 className="text-2xl text-darkest font-bold">
              <Skeleton
                title={false}
                paragraph={{ rows: 1 }}
                active
                loading={loading}
              >
                {cardItem && `${cardItem.value.toFixed(1)}%`}
              </Skeleton>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
