import { Input } from 'antd';

import style from './style.module.less';

interface BenchmarkWeightInputProps {
  value: string | number;
  uniqueKey: string;
  saveState: (value: string) => void;
  disabled?: boolean;
}
const BenchmarkWeightInput: React.FCWithChild<BenchmarkWeightInputProps> = ({
  value,
  saveState,
  uniqueKey,
  disabled,
}) => {
  const checkValue = (value: string) => {
    if (value.indexOf('-') > -1) {
      return false;
    }
    const regex = /^\d{0,3}(\.\d{0,3})?$/;
    if ((regex.test(value) || value === '') && +value >= 0) {
      return true;
    }
    return false;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (checkValue(value)) {
      saveState(value);
    }
  };

  return (
    <Input
      key={`benchmark-weight-${uniqueKey}`}
      type="text"
      inputMode="decimal"
      min={0}
      max={100}
      onChange={handleInputChange}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      style={{ width: '100%' }}
      value={value}
      className={style['weight-input']}
      placeholder="0%"
      disabled={disabled}
      required
      data-test-id={`customBenchmarksModalWeightInput${uniqueKey}`}
    />
  );
};

export default BenchmarkWeightInput;
