import React from 'react';

export enum ONBOARDING_ACTIONS {
  SET_CURRENT_STEP = 'onboarding:set-current-step',
  TOGGLE_WELCOME_MODAL = 'onboarding:toggle-welcome-modal',
  TOGGLE_SEARCH_FUNDS_SECTION = 'onboarding:toggle-search-funds-section',
  // Add to Watchlist actions
}

export type OnboardingActionTypes =
  | { type: ONBOARDING_ACTIONS.SET_CURRENT_STEP; payload: number }
  | { type: ONBOARDING_ACTIONS.TOGGLE_WELCOME_MODAL; payload: boolean }
  | { type: ONBOARDING_ACTIONS.TOGGLE_SEARCH_FUNDS_SECTION; payload: boolean };

export type OnboardingState = {
  isShowWelcomeModal: boolean; // NOTE: first time user
  isShowSearchFundsSection: boolean;
  currentStep: number;
};

export type OnboardingContextProps = {
  state: OnboardingState;
  dispatch: React.Dispatch<OnboardingActionTypes>;
};
